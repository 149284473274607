import { Component, OnInit } from '@angular/core';
import { Message } from 'src/config/constants';

@Component({
  selector: 'app-base-page-welcome',
  templateUrl: './base-page-welcome.component.html',
  styleUrls: ['./base-page-welcome.component.scss'],
})
export class BasePageWelcomeComponent implements OnInit {
  welcomeMsg: string;
  requiredQuesMsg: string;
  readMore = true;
  constructor() {
    // write code here
  }

  ngOnInit(): void {
    this.welcomeMsg = Message.WELCOME_MSG;
    this.requiredQuesMsg = Message.REQUIRED_QUES_MSG;
  }

  linkClicked(): void {
    this.readMore = !this.readMore;
  }
}
