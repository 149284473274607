import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import * as _ from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';
import { Question, GridInstance } from 'src/app/shared/models/casePage.model';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, OwnerInfoConstant, CIVFieldsKeys } from 'src/config/constants';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { DisableStyle } from '@ng/lfg-calendar';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { CivService } from 'src/app/shared/services/civ.service';
import { LfgLoaderService } from '@ng/lfg-loader';
import { TabStatus } from 'src/config/sideNav.config';
import { PAGE_GROUP_NAME } from 'src/config/page.config';

@Component({
  selector: 'app-owner-info',
  templateUrl: './owner-info.component.html',
  styleUrls: ['./owner-info.component.scss']
})
export class OwnerInfoComponent implements OnInit {

  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Input() isWholesaler: boolean;

  questionsData;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  showLoader = true
  ownerInfoData: Question[] = [];
  ownerInfoForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  gridHasNoErrors = false;
  formHasNoErrors = true;
  isGridFormValid = true;
  isGridFormChange = false;
  placeholders = {};
  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Date',
    disableStyle: DisableStyle.locked,
  };
  dateConfigForExpiration = { ...this.dateConfig };
  alldropDownOptions = {};
  setTimeFocus = null;
  gridQuestionsObj;
  isTabVisited: boolean;

  noOfTrustSelected = 0;
  noOfEntitySelected = 0;
  maxNoOfOwner: number;
  ownerTypeName;

  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  multipleTrustError = ErrorMessage.TRUST_OWNER_ERROR;
  multipleEntityError = ErrorMessage.ENTITY_OWNER_ERROR;
  hideAddLink = null;
  additionalOwnerQuestionKey = '/XML/AnswerSet/Party/JointOwner/Indicator'; // '65797#_~_10';
  ownerDisplayGridKeys = '/XML/ApplicationDetail/OwnerSameAsType';
  removeAdditionalOwner = false;
  showOwner = false;
  ownerAnswers = [];
  nameFieldnameKeys = [];
  gridInstances = [];
  displayGrid = false;
  gridValid = true;
  solicitationMsgNotWAorUT = false;
  solicitationMsgWAorUT = false;
  civInput = [];
  hideCIVQuestions = false;
  civQuestionsObj = {};
  noAdditionalOwnerForTrustEntity = false;
  CIVOwnerIdentityType: any;
  CIVIDNumber: any;
  CIVExpirationDate: any;
  CIVStateCountryOfIssuance: any;
  isclearCIVProperties = false;
  isclearCIVOwnerIdentityType = false;
  dlInfo: any = {};
  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private tabDetailsService: TabDetailsService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private userDetailService: UserDetailsService,
    private civService: CivService,
    private loaderService: LfgLoaderService
  ) {
    this.dateConfigForExpiration.minDate = new Date();
    this.dateConfigForExpiration.maxDate = new Date('01/01/2060');
  }

  ngOnInit(): void {
    this.addLoader(true);
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.dlInfo = this.questionsData.dlInfo
      this.buildFormData();
      this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
      this.maxNoOfOwner = +(this.gridInstance[0]?.maxGridIndex);
      setTimeout(() => {
        this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.ownerInfoForm);
      }, 2000)
      this.ownerTypeName = this.getNameFromXmlTag(OwnerInfoConstant.ownerType);
      this.displayConditionalMsg();
      this.defaultPageLoadService.disableFormIfLocked(this.ownerInfoForm, this.disableForm);
      this.defaultPageLoadService?.logPageLoad();
    }
    this.addLoader(false);
  }

  private buildFormData(): any {
    this.gridQuestionsObj = {};
    this.nameFieldnameKeys = [];
    this.ownerAnswers = this.questions.pages[0].insuredAsOwner || [];
    this.gridAnswers = this.questions.pages[0].gridAnswers || [];
    this.gridInstances = this.questions.pages[0].gridInstances;
    for (const gridData of this.gridInstances) {
      this.gridQuestionsObj[gridData.id] = gridData;
      for (const fieldObj of gridData.gridQuestions[0].reflexiveQuestionAL) {
        if (fieldObj && (fieldObj.xmlTag.toLowerCase().indexOf('/xml/client/crmdetails/firstname') >= 0
                        || fieldObj.xmlTag.toLowerCase().indexOf('/xml/client/crmdetails/lastname') >= 0)) {
          this.nameFieldnameKeys.push(fieldObj.name);
        }
      }
      this.civQuestionsObj[gridData.id] = gridData.gridQuestions[0].reflexiveQuestionAL?.filter((element: any) => {
        if (CIVFieldsKeys.indexOf(element.xmlTag) > -1) {
          return element;
        }
      });
    }
    this.ownerInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.ownerInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.ownerInfoData
    );
    this.ownerInfoForm = this.fb.group(form);
    this.defaultInsuredAsOwnerForWholesaler();
    this.valueChanges();
    this.noOwnerCheck();
    this.displayGridCondition();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.ownerInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  onRadioChange(data): void {
    if (data.xmlTag === OwnerInfoConstant.isInsuredOwner) {
      this.displayConditionalMsg();
      this.noOwnerCheck();
    }
    this.updatedAnswersOnHidden(data);
    this.displayGridCondition();
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private defaultInsuredAsOwnerForWholesaler(): void {
    if (this.questionsData.page_group_name === PAGE_GROUP_NAME.WS_OWNER_INFO_PAGE) {
      const insuredAsOwnerfieldId = this.getFieldIdByXmlTag(OwnerInfoConstant.isInsuredOwner);
      if (!this.ownerInfoForm.get(insuredAsOwnerfieldId)?.value) {
        this.ownerInfoForm.get(insuredAsOwnerfieldId)?.setValue('Yes');
        this.displayConditionalMsg();
        this.noOwnerCheck();
      }
    }
  }

  displayConditionalMsg(): void {
    this.hideCIVQuestions = true;
    const isInsuredOwenr = this.ownerInfoForm.value[this.ownerInfoData[0].fieldId];
    if (isInsuredOwenr?.toLowerCase().indexOf('no') >= 0) {
      this.clearCVIProperties();
      setTimeout(() => {
        this.hideCIVQuestions = true;
      }, 200);
      this.solicitationMsgNotWAorUT = false;
      this.solicitationMsgWAorUT = false;
    }
    if (isInsuredOwenr?.toLowerCase().indexOf('yes') >= 0) {
      this.noAdditionalOwnerForTrustEntity = false;
      if (!this.disableForm) {
        this.ownerInfoForm?.get(this.ownerInfoData[1].fieldId).enable();
      }
      this.hideCIVQuestions = false;
      this.solicitationMsgNotWAorUT = false;
      this.solicitationMsgWAorUT = false;
      const insuredState = this.userDetailService.getInsuredState();
      const residentState = this.userDetailService.getResidentState();
      if (insuredState !== residentState) {
        if (residentState !== 'WA' && residentState !== 'UT') {
          this.solicitationMsgNotWAorUT = true;
        }
        if (residentState === 'WA' || residentState === 'UT') {
          this.solicitationMsgWAorUT = true;
        }
      }
    }
  }

  noOwnerCheck(): void {
    const insuredAsOwner = this.ownerInfoForm.value[this.ownerInfoData[0].fieldId] || '';
    if (insuredAsOwner.toLowerCase().indexOf('no') >= 0) {
      // remove the owner from gridAnswer
      const ownerAns = this.removeCIVKeys(this.ownerAnswers[0]);
      const gridAns = this.removeCIVKeys(this.gridAnswers[0]);
      const match = _.isEqual(ownerAns, gridAns);
      if (match) {
        this.gridAnswers.splice(0, 1);
        this.removeEmptyGrids();
      } else {
        this.removeEmptyGrids();
      }
    }
    if (this.hideAddLink) {
      this.removeEmptyGrids();
    }
    if (this.disableForm && insuredAsOwner.toLowerCase().indexOf('yes') >= 0) {
      this.questions.pages[0].questions[0].change = true;
    } else if (this.disableForm && insuredAsOwner.toLowerCase().indexOf('no') >= 0) {
      this.questions.pages[0].questions[0].change = false;
    }
  }

  removeCIVKeys(obj): any {
    let civKeys = [];
    for (const ele of this.civQuestionsObj['OWNER-IND_GRID']) {
      civKeys.push(ele.name);
      if (ele.hasReflexive === "true") {
        for (const ele1 of ele.reflexiveQuestionAL) {
          civKeys.push(ele1.name);
        }
      }
    }
    // hardcode country of citizenship - due to json issue / other key for object comparison
    if (this.isWholesaler) {
      civKeys.push('132738#_~_5_~_TCD_~_2', '132711#_~_0_~_TDT_VNF_~_2', '132721#_~_18', '132724#_~_4_~_STR_~_2');
    } else {
      civKeys.push('118762#_~_5_~_TCD_~_2', '118744#_~_0_~_TDT_VNF_~_2', '118755#_~_18', '118758#_~_4_~_STR_~_2');
    }
    if (obj) {
      const copyOfObj = JSON.parse(JSON.stringify(obj));
      let ind = -1;
      for (const key in copyOfObj) {
        ind++;
        if (civKeys.indexOf(key) > -1) {
          delete copyOfObj[key];
        }
      }
      return copyOfObj;
    }
  }

  removeEmptyGrids(): void {
    // remove empty grids or the grids in which FN LN is empty
    const copyGridAnswers = JSON.parse(JSON.stringify(this.gridAnswers));
    let ind = -1;
    for (const ansObj of copyGridAnswers) {
      ind++;
      for (const element in ansObj) {
        if (this.nameFieldnameKeys.indexOf(element) >= 0 && ansObj[element] === '') {
          this.gridAnswers.splice(ind, 1);
          // break;
        }
      }
    }
    this.questions.pages[0].gridAnswers = this.gridAnswers;
  }

  displayGridCondition(): boolean {
    this.displayGrid = true;
    if (this.gridAnswers && this.gridAnswers.length > 0) {
      this.displayGrid = true;
    }
    if ( this.displayGrid ) {
      return true;
    } else {
      this.clearGrids();
      return false;
    }
  }

  clearGrids(): void {
    // grids are hidden
    this.gridAnswers = [];
    this.gridValid = true;
    this.gridHasNoErrors = true;
  }

  handleSelectionChange(data, ix?): any {
    this.updatedAnswersOnHidden(data);
  }

  updatedAnswersOnHidden(ownerInfoData): void {
    const form = this.ownerInfoForm;
    const value = form.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(ownerInfoData, value);
    if (updatedval) {
      for (const key in updatedval) {
        if (this.gridQuestionsObj.hasOwnProperty(key)) {
            this.clearGrids();
            delete updatedval[key];
        } else {
          form.get(key).markAsUntouched();
        }
      }
      form.patchValue(updatedval);
    }
  }

  private checkForOwnerType(): void {
    this.noOfTrustSelected = 0;
    this.noOfEntitySelected = 0;
    this.gridAnswers.forEach(gridVal => {
      if (gridVal[this.ownerTypeName]?.toLowerCase().indexOf('trust') > -1) {
        this.noOfTrustSelected++;
      }
    });
    this.gridAnswers.forEach(gridVal => {
      if (gridVal[this.ownerTypeName]?.toLowerCase().indexOf('entity') > -1) {
        this.noOfEntitySelected++;
      }
    });
  }

  private checkFormStatus(onload?): any {
    const civInput1 = this.ownerInfoForm.value[this.ownerInfoData[0].fieldId];
    const civInput2 = this.ownerInfoForm.value[this.ownerInfoData[2].fieldId];
    this.civInput = [ civInput1?.toLowerCase(), civInput2?.toLowerCase() ];

    // hiding additional owner link
    const hideLinkAns = this.ownerInfoForm.value[this.ownerInfoData[1].fieldId] || '';
    const insuredAsOwner = this.ownerInfoForm.value[this.ownerInfoData[0].fieldId] || '';
    this.hideAddLink = this.noAdditionalOwnerForTrustEntity ? this.noAdditionalOwnerForTrustEntity : hideLinkAns.toLowerCase().indexOf('yes') === -1;
    this.removeAdditionalOwner = hideLinkAns === '' ? true : this.hideAddLink;
    // showOwner logic
    this.showOwner = (onload && insuredAsOwner === '') ? this.showOwner : insuredAsOwner.toLowerCase().indexOf('yes') >= 0;
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.ownerInfoForm,
      data: null,
      isFormChange: this.ownerInfoForm.dirty || !this.isTabVisited
    };
    for (const data of this.ownerInfoData) {
      componentData.data = data;
      componentData = this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  gridStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.checkForOwnerType();
    this.isGridFormChange = event.isGridFormChange;
    // if 4 other owner grids than insured
    const insuredAsownerOption = this.ownerInfoForm.get(this.ownerInfoData[0].fieldId);
    if (this.gridAnswers && this.gridAnswers.length === 4 && this.civInput[0] === 'no' && !insuredAsownerOption.disabled) {
      insuredAsownerOption.disable();
    } else if (this.gridAnswers && this.gridAnswers.length < 4 && insuredAsownerOption.disabled) {
      if (!this.disableForm) {
        insuredAsownerOption.enable();
      }
    }
    this.emitStatus();
  }

  emitStatus(event?): void {
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    const ownerInfoObj = {
      formValid: this.formValid && this.isGridFormValid && this.noOfTrustSelected < 2 && this.noOfEntitySelected < 2,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'ownerInfoForm',
      gridForm: true,
      isFormChange: this.ownerInfoForm.dirty || this.isGridFormChange || !this.isTabVisited || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.ownerInfoForm),
    };
    this.formStatus.emit(ownerInfoObj);
  }

  clearCVIProperties(notClearCIVOwnerIdentityType?): void {
    this.isclearCIVOwnerIdentityType = notClearCIVOwnerIdentityType ? this.isclearCIVOwnerIdentityType : true;
    this.isclearCIVProperties = true;
    this.CIVOwnerIdentityType = notClearCIVOwnerIdentityType ? this.CIVOwnerIdentityType : null;
    this.CIVIDNumber = null;
    this.CIVExpirationDate = null;
    this.CIVStateCountryOfIssuance = null;
  }

  private getNameFromXmlTag(xmlTag: string): string {
    return this.gridInstance[0].gridQuestions.filter(ques => {
      return ques.xmlTag === xmlTag;
    })[0]?.name;
  }

  checkIfQuestionDisplayedOwner(data): any {
    const answer = this.ownerInfoForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckOwner(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  addCorrectPlaceHolder(data, type): any {
    this.utilsService.updatePolicyRelatedData(data);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  validateOnFocus(data, type): void {
    clearTimeout(this.setTimeFocus);
    this.setTimeFocus = setTimeout(() => {
      if (type === 'in' && this.ownerInfoForm.get(data.fieldId)) {
        this.ownerInfoForm.get(data.fieldId).markAsUntouched();
      } else if (this.ownerInfoForm.get(data.fieldId)) {
        this.ownerInfoForm.get(data.fieldId).markAsTouched();
      }
    }, 10);
  }

  hideAddLinkForTrustEntity(event): void {
    const additionalOwnerQues = this.ownerInfoForm?.get(this.ownerInfoData[1].fieldId);
    if (event) {
      additionalOwnerQues.setValue(this.ownerInfoData[1].questionOption[1].value);
      additionalOwnerQues.disable();
    } else {
      if (!this.disableForm) {
        additionalOwnerQues.enable();
      }
    }
    this.noAdditionalOwnerForTrustEntity = event;
    this.hideAddLink = event;
  }

  addLoader(isAddLoader: boolean): void {
    if (isAddLoader) {
      this.showLoader = true;
      this.loaderService.show();
    } else {
      setTimeout(()=>{
        this.showLoader = false;
        this.loaderService.hide();
      }, 0);
    }
  }

}
