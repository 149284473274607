import { Component, OnInit } from '@angular/core';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-base-page-header',
  templateUrl: './base-page-header.component.html',
  styleUrls: ['./base-page-header.component.scss'],
})
export class BasePageHeaderComponent implements OnInit {
  primaryInsuredName: string;
  productName: string;
  constructor(
    private userService: UserDetailsService,
    public productService: ProductDetailsService
  ) {
    // Add code here
  }

  ngOnInit(): void {
    this.getInsuredAndProductName();
  }

  getInsuredAndProductName(): void {
    this.primaryInsuredName = this.userService.getInsuredName();
    this.productName = this.productService.getProductName();
  }
}
