import { Component, OnInit, Injector, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  numberRangeValidator,
  dropdownValidator,
  numericValidator
} from 'src/app/shared/validators/validator';
import { BenefitTimeOptions, PremiumModeOptions, EndorsementTypeOptions, BenefitPlanOptions, PolicyIllustrationFields, FaceAmtAndBenefitsOptions, ModalPremiumOptions, RateOfReturnOptions, TimePeriodOptions, EndTimePeriodOptions, LTCAccelerationBenefitsOptions, LTCExtensionBenefitsOptions, Products } from 'src/config/constants';

import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { checkForGreaterThanZero } from 'src/app/shared/validators/business-validator';
import { TabStatus } from 'src/config/sideNav.config';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';

@Component({
  selector: 'app-illustration-policy-info',
  templateUrl: './illustration-policy-info.component.html',
  styleUrls: ['./illustration-policy-info.component.scss']
})
export class IllustrationPolicyInfoComponent implements OnInit {

  illustrationForm: FormGroup;
  formBuilder!: FormBuilder
  product: string = ""
  setTimeFocus = null;
  formHasNoErrors = true;
  backdateMonthOptions = []
  benefitTimeValOptions = []
  benefitTimeOptions = BenefitTimeOptions
  premiumModeOptions = PremiumModeOptions
  endorsementTypeOptions = EndorsementTypeOptions
  benefitPlanOptions = BenefitPlanOptions
  faceAmtAndBenefitsOptions = FaceAmtAndBenefitsOptions
  modalPremiumOptions = ModalPremiumOptions
  rateOfReturnOptions = RateOfReturnOptions
  timePeriodOptions = TimePeriodOptions
  endTimePeriodOptions = EndTimePeriodOptions
  accelerationBenefitsOptions = LTCAccelerationBenefitsOptions
  extensionBenefitsOptions = LTCExtensionBenefitsOptions
  products = Products
  illustrationTableConfigs = {};
  tabStatusOnInit: TabStatus;

  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  constructor(private injector: Injector, private productService: ProductDetailsService, private defaultPageLoadService: DefaultPageload,) {
    this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
  }


  ngOnInit(): void {
    this.product = this.productService.getMarketName();
    this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
    this.buildForm();
    setTimeout(() => {
      this.disableFields();
    }, 100)
  }

  buildForm() {
    this.populateDropdownOptions()
    const controls = this.getRelevantControls()
    this.illustrationForm = this.formBuilder.group(controls)
    this.policyInfoValueChanges();
  }

  onRadioChange(e) {
    if (e.target.name === "backdate") {
      e.target.value === "manual" ? this.illustrationForm.get('backdateMonths').enable() : this.illustrationForm.get('backdateMonths').disable();
    }
    this.solveFor(e);
    this.modalPremium(e);
    this.variableRateSelection(e);
  }

  solveFor(e): void {
    if (e.target.name === "solveFor") {
      if (e.target.value === 'premiumFaceAmt') {
        this.illustrationForm.get('faceAmt').enable()
        this.illustrationForm.get('benefitAmt').disable()
        this.illustrationForm.get('benefitTimeDropdown').disable()
        this.illustrationForm.get('benefitTimeVal').disable()
        this.illustrationForm.get('premium').disable()
        this.illustrationForm.get('pmtOption').disable()
      } else if (e.target.value === 'premiumBenefit') {
        this.illustrationForm.get('faceAmt').disable()
        this.illustrationForm.get('benefitAmt').enable()
        this.illustrationForm.get('benefitTimeDropdown').enable()
        this.illustrationForm.get('benefitTimeVal').enable()
        this.illustrationForm.get('premium').disable()
        this.illustrationForm.get('pmtOption').disable()
      } else if (e.target.value === 'faceAmt') {
        this.illustrationForm.get('faceAmt').disable()
        this.illustrationForm.get('benefitAmt').disable()
        this.illustrationForm.get('benefitTimeDropdown').disable()
        this.illustrationForm.get('benefitTimeVal').disable()
        this.illustrationForm.get('premium').enable()
        this.illustrationForm.get('pmtOption').enable()
      }
    }
  }

  modalPremium(e): void {
    if (e.target.name === "modalPremium") {
      if (e.target.value === "fixedSchedule") {
        this.illustrationForm.get('modalPremiumDropdown').enable()
        this.illustrationForm.get('modalPremiumAmt').enable()
        this.illustrationForm.get('modalPremiumTime').enable()
        this.illustrationForm.get('modalPremiumTimeAmt').enable()
      } else {
        this.illustrationForm.get('modalPremiumDropdown').disable()
        this.illustrationForm.get('modalPremiumAmt').disable()
        this.illustrationForm.get('modalPremiumTime').disable()
        this.illustrationForm.get('modalPremiumTimeAmt').disable()
      }
    }
  }

  variableRateSelection(e): void {
    if (e.target.name === "variableRateSelection") {
      if (e.target.value === "fixedRate") {
        this.illustrationForm.get('variableRateDropdown').enable()
        this.illustrationForm.get('variableRate').enable()
      } else {
        this.illustrationForm.get('variableRateDropdown').disable()
        this.illustrationForm.get('variableRate').disable()
      }
    }
  }

  validateOnFocus(formElement, type): any {
    this.setTimeFocus = setTimeout(() => {
      if (type === 'in') {
        this.illustrationForm.get(formElement)?.markAsUntouched();
      } else {
        this.illustrationForm.get(formElement)?.markAsTouched();
      }
    }, 10);
  }

  onCheckboxChange(formElement): void {
    const checked = this.illustrationForm.get(formElement).value;
    console.log(checked);
  }

  checkIfReasonSelectedErrorExists(): void {
    console.log("Reason selected error");
  }

  handleSelectionChange(_formElement, event): void {
    if(_formElement==='modalPremiumTime'){
      if(event.value==='age'){
        this.illustrationForm.get('modalPremiumTimeAmt').clearValidators()
        this.illustrationForm.get('modalPremiumTimeAmt').setValidators([numberRangeValidator(50,75)])
      }else{
        this.illustrationForm.get('modalPremiumTimeAmt').clearValidators()
        this.illustrationForm.get('modalPremiumTimeAmt').setValidators([numberRangeValidator(1,26)])
      }
    }
  }

  private policyInfoValueChanges(): any {
    let setTime = setTimeout(() => {
      this.policyInfoCheckFormStatus();
    }, 200);
    this.illustrationForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.policyInfoCheckFormStatus();
      }, 200);
    });
  }

  private policyInfoCheckFormStatus(): any {
    const obj = {
      formValid: this.illustrationForm.valid,
      formHasNoErrors: this.formHasNoErrors,
      questions: {},
      formId: 'illustrationPolicyInfoForm',
      isFormChange: this.illustrationForm.dirty || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.illustrationForm)
    };
    this.formStatus.emit(obj);
  }

  private getRelevantControls(): any {
    const allControls = {
      solveFor: ['', []],
      faceAmt: ['', [numberRangeValidator(200, 999999999)]],
      benefitAmt: ['', [numberRangeValidator(200, 999999999)]],
      benefitTimeDropdown: [this.benefitTimeOptions[0], []],
      benefitTimeVal: [this.benefitTimeValOptions[0], []],
      premium: ['', [numberRangeValidator(200, 999999999)]],
      pmtOption: ['', [checkForGreaterThanZero()]],
      premiumMode: [this.premiumModeOptions[0], []],
      lumpSum: ['', []],
      allowExchangeToFund: ['', []],
      illustrateExchange: ['', []],
      isMEC: ['', []],
      internalCashVal: ['', []],
      externalCashVal: ['', []],
      endorsementType: [this.endorsementTypeOptions[0], []],
      benefitPlan: [this.benefitPlanOptions[0], [dropdownValidator()]],
      backdate: ['', []],
      backdateMonths: [this.backdateMonthOptions[0], []],
      faceAmtAndBenefits: [this.faceAmtAndBenefitsOptions[0], []],
      modalPremium: ['', []],
      modalPremiumDropdown: [this.modalPremiumOptions[0], []],
      modalPremiumAmt: ['', []],
      modalPremiumTime: [this.timePeriodOptions[0], []],
      modalPremiumTimeAmt: ['', [numberRangeValidator(50,75)]],
      illustrateWithLumpSum: ['', []],
      variableRateSelection: ['', []],
      variableRateDropdown: [this.rateOfReturnOptions[0], []],
      variableRate: ['', [numberRangeValidator(0, 12)]],
      alternateRate: ['', [numberRangeValidator(0, 12)]],
      solveObjective: ['', [checkForGreaterThanZero()]],
      solveObjectiveTime: [this.endTimePeriodOptions[0], []],
      solveObjectiveTimeValue: [0,[numericValidator()]],
      guaranteeUntil: [this.endTimePeriodOptions[0], []],
      guaranteeUntilValue:[0,numericValidator()],
      externalExchange: ['', []],
      internalExchange: ['', []],
      illustrateWithValueProtection: ['', []],
      valueProtectionRiderType: [this.endorsementTypeOptions[0], []],
      illustrateWithLTCAcceleration: ['', []],
      accelerationBenefitPlan: [this.accelerationBenefitsOptions[0], []],
      illustrateWithLTCExtension: ['', []],
      extensionBenefitPlan: [this.extensionBenefitsOptions[0], []],

    }

    let fields = []
    if (this.product === this.products.MGFA || this.product === this.products.MGFA25) {
      fields = PolicyIllustrationFields.MGFA
    }
    else if (this.product === this.products.MGMA22 || this.product === this.products.MGMA24) {
      fields = PolicyIllustrationFields.MGMA22
    }
    else if (this.product === this.products.MGII) {
      fields = PolicyIllustrationFields.MGII
    }
    else if (this.product === this.products.MGMA) {
      fields = PolicyIllustrationFields.MGMA
    }

    let controls = {}
    fields.forEach(e => {
      controls[e] = allControls[e]
    })

    return controls

  }

  populateDropdownOptions(): void {
    for (let i = 0; i < 100; i++) {
      const obj = { 'label': String(i), 'value': String(i) }
      this.benefitTimeValOptions.push(obj)
      this.backdateMonthOptions.push(obj)
    }
  }

  disableFields(): void {
    const fieldsToDisable = ['faceAmt', 'benefitAmt', 'benefitTimeDropdown', 'benefitTimeVal', 'premium', 'pmtOption', 'backdateMonths','modalPremiumDropdown','modalPremiumAmt','modalPremiumTime','modalPremiumTimeAmt','variableRateDropdown','variableRate'];
    fieldsToDisable.forEach(e => {
      const field = this.illustrationForm.get(e)
      if (field) {
        field.disable()
      }
    });
  }

  openModal(): void {
    console.log("opening modal")
  }

}
