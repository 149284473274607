export class EticketDashboardTableConfig {
  tableData = {
    config: {
      tableId: 'eticket-dbResult-table',
      tableClass: 'eticket-dbResult-table',
      sorting: true,
      pagination: true,
      rowsPerPage: 10,
      currentPage: 0,
      defaultSortOrder: 'desc',
      clickSortOrder: 'asc',
      tableFoot: false,
      handleSubDetailRow: false,
      noRecordsText: 'No records found'
    },
    column: [
      {
        displayText: '<span aria-label="No value" class="hiddenTh">checkbox</span>',
        columnKey: 'checkbox',
        columnFlag: 'checkbox',
        isAction: 'noaction',
        pipeName: null,
        sorting: false,
        align: 'center',
        classNameTh: '',
        type: 'htmlText',
        classNameTd: 'lfg-checkbox eticket-dbResult-table__checkbox'
      },
      {
        displayText: 'CLIENT NAME',
        columnKey: 'insuredName',
        columnFlag: 'insuredName',
        pipeName: null,
        type: 'htmlText',
        sorting: true,
        align: 'left',
        isAction: 'noaction',
        classNameTh: 'eticket-dbResult-table-th_clntName',
        classNameTd: 'result-table-td_clntName'
      },
      {
        // displayText: '<i class="far fa-question-circle table-th_status_icon status-help-icon" (click)="openModal($event)"></i> STATUS',
        displayText: 'STATUS',
        columnKey: 'status',
        columnFlag: 'status',
        isAction: 'noaction',
        pipeName: null,
        type: 'htmlText',
        sorting: true,
        sortBy: 'status',
        align: 'left',
        dataType: 'string',
        classNameTh: 'eticket-dbResult-table-th_policy',
        classNameTd: 'result-table-td_policy'
      },
      {
        displayText: 'PRODUCT TYPE',
        columnKey: 'productName',
        columnFlag: 'productName',
        isAction: 'noaction',
        pipeName: null,
        type: 'htmlText',
        sorting: true,
        sortBy: 'productName',
        align: 'left',
        dataType: 'string',
        classNameTh: 'eticket-dbResult-table-th_prod',
        classNameTd: 'result-table-td_prod'
      },
      {
        displayText: 'CASE DESCRIPTION',
        columnKey: 'caseDescription',
        columnFlag: 'caseDescription',
        isAction: 'noaction',
        pipeName: null,
        type: 'htmlText',
        sorting: true,
        align: 'left',
        classNameTh: 'eticket-dbResult-table-th_caseDescription',
        classNameTd: 'result-table-td_caseDescription'
      },
      {
        displayText: 'WRITING AGENT',
        columnKey: 'producerName',
        columnFlag: 'producerName',
        isAction: 'noaction',
        pipeName: null,
        type: 'htmlText',
        sorting: true,
        align: 'left',
        dataType: 'string',
        classNameTh: 'eticket-dbResult-table-th_acct',
        classNameTd: 'result-table-td_acct'
      },
      {
        displayText: 'CASE ID',
        columnKey: 'refCaseId',
        columnFlag: 'refCaseId',
        isAction: 'noaction',
        pipeName: null,
        type: 'htmlText',
        sorting: false,
        align: 'left',
        dataType: 'string',
        classNameTh: 'eticket-dbResult-table-th_acct',
        classNameTd: 'result-table-td_acct'
      },
      {
        displayText: 'CREATED DATE',
        columnKey: 'createdDate',
        columnFlag: 'createdDate',
        isAction: 'noaction',
        pipeName: null,
        type: 'htmlText',
        sorting: false,
        align: 'left',
        dataType: 'string',
        classNameTh: 'eticket-dbResult-table-th_acct',
        classNameTd: 'result-table-td_acct'
      },
    ],
    data: [ ]
  };
}
