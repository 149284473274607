<div *ngIf="showLoader > 0">
  <lfg-loader></lfg-loader>
</div>

<ng-container *ngIf="displayProgressModal">
  <app-progress-overlay [modalStateInput]="displayProgressModal" [progressPercentArray]="progressPercentArray" [messageToshow]="MessageConst.FORM_LOADER_MESSAGE"></app-progress-overlay>
</ng-container>

<section class="case-details-container">

  <div class="dashboard-link">
    <a (click)="redirectToDbpage()" tabindex="0"><i class="fas fa-arrow-left back-icon" aria-hidden="true"></i>DASHBOARD</a>
  </div>

  <div class="case-details-title">
    <h1>Case Details</h1>


    <div class="btn-container">
      <span class="btn-sub-container">
        <button class="btn btn-case" (click)="openCase()" [disabled]="systemMessage?.type === MessageType.OUTAGE">Open Case</button>
      </span>
      <button class="dbtitleBtn--dublicate btn btn--whitebg-border" (click)="openDeleteModalPopup(deleteModal)" [disabled]="systemMessage?.type === MessageType.OUTAGE || !userAccessDetails?.delete || isCaseSubmitted">Delete Case</button>
    </div>
  </div>

  <ng-container>
    <div *ngIf="errorInGeneratingForm"><lfg-alert-message type="error">{{ MessageConst.GENERIC_ERROR }}</lfg-alert-message></div>
    <ng-container>

      <div class="info-section">
        <div class="column-section column-section-first">
          <div class="info-detail"><span class="info-head">Insured Name:</span><span class="font-bold info-ans">{{dataMap.get('name')}}</span></div>
          <div class="info-detail"><span class="info-head">Insured Date of Birth:</span><span class="info-ans">{{dataMap.get('dob')}}</span></div>
          <div class="info-detail"><span class="info-head">Insured Email:</span><span class="info-ans">{{dataMap.get('email') | lowercase}}</span></div>
          <div class="info-detail"><span class="info-head">Phone Number:</span><span class="info-ans" *ngIf="dataMap.get('phone')">{{dataMap.get('phone') | phoneNumber}}</span></div>
        </div>

        <div class="column-section column-section-next" [ngClass]="{'hide' : !show}">
          <div class="info-detail"><span class="info-head">Insured State:</span><span class="info-ans">{{dataMap.get('state')}}</span></div>
          <div class="info-detail address-details"><span class="info-head">Address:</span><span class="info-ans address-details-ans"><span>{{dataMap.get('address1')}}</span><span>{{dataMap.get('address')}}</span></span></div>
        </div>

        <div class="column-section column-section-next" [ngClass]="{'hide' : !show}">
          <div class="info-detail"><span class="info-head">Insured Age:</span><span class="info-ans" *ngIf="dataMap.get('age')">{{dataMap.get('age')}} years</span></div>
          <div class="info-detail"><span class="info-head">Product Name:</span><span class="info-ans">{{dataMap.get('product')}}</span></div>
          <div class="info-detail"><span class="info-head">Face Amount:</span><span class="info-ans">{{dataMap.get('faceamount') | currency}}</span></div>
        </div>

        <span class="show-more-link">
          <a *ngIf="!show" (click)="show = !show"> Show More <i class="fas fa-angle-down" aria-hidden="true"></i></a>
          <a *ngIf="show" (click)="show = !show">Show less <i class="fas fa-angle-up" aria-hidden="true"></i></a>
        </span>
      </div>
    </ng-container>

    <!-- accordion for recipients list -->
    <section class="accordion-section">
      <ng-container>
        <lfg-accordion>
          <lfg-panel title="{{'e-Signer Status (' + (tableData?.data ? tableData.data?.length : '0')  + ')'}}" [opened]="false">
            <section class="accordion-margin">
              <!-- primary table -->
              <lfg-primary-table [tableData]="tableData" id="case-info-dbtable">
                <ng-template #tableCelContentTemplate let-column="column" let-row="row">
                  <div tabindex="0" *ngIf="column.columnFlag === 'name'">{{ row.fullName | titlecase }} </div>
                  <div tabindex="0" *ngIf="column.columnFlag === 'type'">{{ row.displayRole | titlecase}}<span *ngIf="isTrustAvailable && row.displayRole?.toLowerCase().indexOf('owner') > -1">{{" (Trustee)"}}</span></div>
                  <div tabindex="0" *ngIf="column.columnFlag === 'phone'">{{ row.sMSAuthentication?.senderProvidedNumbers?.senderProvidedNumber[0] | phoneNumber}}</div>
                  <div tabindex="0" *ngIf="column.columnFlag === 'email'">{{ row.email | lowercase}}</div>
                  <div tabindex="0" *ngIf="column.columnFlag === 'status'"><span *ngIf="row.displayDocuSignStatus?.toLowerCase() === 'completed'"><i aria-hidden="true" class="fas fa-check-circle complete-icon"></i></span><span class="bold-font" [ngClass]="{'signed-status': row.displayDocuSignStatus?.toLowerCase() === 'completed'}">{{ row.displayDocuSignStatus | titlecase }}<span *ngIf="!row.docusignStatus">Email not sent</span></span></div>
                  <div tabindex="0" *ngIf="column.columnFlag === 'eSignMethod'">{{ (row.eSignatureMethod?.toLowerCase() === 'inperson' ? 'in-person' : row.eSignatureMethod) | titlecase }} </div>
                </ng-template>
              </lfg-primary-table>
            </section>
          </lfg-panel>
        </lfg-accordion>
      </ng-container>
    </section>

    <!-- accordion for activity log -->
    <section class="accordion-section">
      <ng-container>
        <lfg-accordion>
          <lfg-panel title="{{'Activity history (' + (activityTableData?.data ? activityTableData.data?.length : '0')  + ')'}}" [opened]="false">
            <section class="accordion-margin">
              <!-- primary table -->
              <lfg-primary-table [tableData]="activityTableData" id="activity-log-dbtable">
                <ng-template #tableCelContentTemplate let-column="column" let-row="row">
                  <div tabindex="0" *ngIf="column.columnFlag === 'datetime'">{{ row.activityTimeStamp | date:'MM/dd/yyyy hh:mm a' }} </div>
                  <div tabindex="0" *ngIf="column.columnFlag === 'activity'">{{ row.activityDesc}} </div>
                </ng-template>
              </lfg-primary-table>
            </section>
          </lfg-panel>
        </lfg-accordion>
      </ng-container>
    </section>

    <div class="button-container" *ngIf="caseLocked">
      <lfg-left-button-tray>
        <button class="btn" type="button" class="btn btn--whitebg-border review-form-btn" id="viewForm" tabindex="0" aria-label="View Forms" [disabled]="(!userAccessDetails?.editCase)" (click)="generateForms();">VIEW FORMS</button>
      </lfg-left-button-tray>
    </div>

  </ng-container>

</section>

<lfg-modal-popup *ngIf="deleteModal" [modalConfig]="deleteModal" class="modal-popup--sm" (closeModalPopup)="closeDeleteModalPopup(deleteModal)">

  <div modal-header>
    <h1>{{MessageConst.DELETE_CASE_DIALOG_HEADER}}</h1>
  </div>

  <div modal-body class="modal-popup__body--scroll">{{MessageConst.DELETE_ONE_CASE_WARNING_MESSAGE}} <br>
    <strong>{{MessageConst.DELETE_CASE_CONFIRMATION_MESSAGE}}</strong>
  </div>

  <div modal-footer>
    <lfg-left-button-tray>
      <button type="" class='btn' (click)="deleteCase()" lfgDefaultButton>YES, DELETE</button>
      <button type="" class='btn' (click)="closeDeleteModalPopup(deleteModal)" lfgDefaultButton>NO, CANCEL</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>
