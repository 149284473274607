import { Directive, OnInit, ElementRef, HostListener, Input  } from '@angular/core';
import { formatCurrency } from '@angular/common';

@Directive({
  selector: '[appCurrencyFormat]'
})

export class CurrencyFormatDirective implements OnInit {
  private el: any;
  decimalPlaces = 2;
  @Input() allowDecimals = false;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit(): any {
    if (this.el.value) {
      this.el.value = this.transform(this.el.value);
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value): any {
    this.el.value = this.parse(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value): any {
    if (value) {
      this.el.value = this.transform(value);
    }
  }

  parse(value: string): any {
    let tempStr = value.replace('$', '');
    tempStr = tempStr.replace(/,/g, '');
    tempStr = tempStr.replace('.00', '');
    return tempStr;
  }

  transform(value: any): any {

    let isNegative = false;
    if (value < 0) {
      isNegative = true;
    }
    if (isNegative) {
      value = -1 * value;
    }
    const textPatt = new RegExp(/^[0-9.]*$/);
    if (textPatt.test(value)) {
      return isNegative ? '-' +  formatCurrency(value, 'en-US', '$') : formatCurrency(value, 'en-US', '$');
    } else {
      return value;
    }
  }
}
