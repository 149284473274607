import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { AgentAndFirmService } from './agent-and-firm.service';
import { PageService } from './page.service';
import { ProductDetailsService } from './product-details.service';
import { ServiceInvoker } from './service-invoker';
import { TabDetailsService } from './tab-details.service';
import { UserDetailsService } from './user-details.service';
import { UtilsService } from './utils.service';

import {
  CaseStatus, PageStatusConstant, CaseInfoConstant,
  AgentInfoContConstant, AgentInfoConstant, ExcludeTabsFromLock,
  ExcludeTabsFromValidation, OwnerInfoConstant, PrimaryInsuredConstant, AgentReportKeys, PayorOtherPersonConst, PayorOtherEntityConst, PayorOtherAddressConst
} from 'src/config/constants';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { TAB, TabInformation, TabStatus } from 'src/config/sideNav.config';
import {
  Case, GetCaseDataRequestObject, ResponseObject,
  UpdateCaseDataRequestObject, PageJsonObject, PageStatus, PageStatusValue, Question, CaseAndPageStatus, AgentProfile, Recipient, CaseStatus as CaseStatusVal
} from '../models/casePage.model';
import { AppAnalyticsService } from './app-analytics.service';
import { LfgLoaderService } from '@ng/lfg-loader';
import { UpdateCaseService } from './update-case.service';


@Injectable({
  providedIn: 'root'
})
export class DefaultPageload {
  personAddress1FieldName: string;
  personAddress2FieldName: string;
  personAddress3FieldName: string;
  personCityFieldName: string;
  personStateFieldName: string;
  personZipFieldName: string;
  personEmailFieldName: string;

  trustAddress1FieldName: string;
  trustAddress2FieldName: string;
  trustAddress3FieldName: string;
  trustCityFieldName: string;
  trustStateFieldName: string;
  trustZipFieldName: string;

  entityAddress1FieldName: string;
  entityAddress2FieldName: string;
  entityAddress3FieldName: string;
  entityCityFieldName: string;
  entityStateFieldName: string;
  entityZipFieldName: string;
  personSameAddressName: string;
  trustSameAddressName: string;
  entitySameAddressName: string;
  ownerAddress: any;
  ownerAddress2: any;
  ownerAddress3: any;
  ownerCity: any;
  ownerState: any;
  ownerZip: any;
  ownerPhone: any;
  ownerPhoneType: any;
  ownerPhonePin: any;
  ownerEmail: any;
  labelFirstName: any;
  labelLastName: any;
  isSameInsuredAddress: any;
  personPhoneFieldName: string;
  personPhoneTypeFieldName: string;
  personPhoneTypePINFieldName: string;
  entityPhoneFieldName: any;
  trustPhoneFieldName: any;
  trustEmailFieldName: any;
  entityEmailFieldName: any;
  mergedForm: any;
  mergedFormTab: any;
  payorDetails: any = {};

  constructor(
    private serviceInvoker: ServiceInvoker,
    private pageService: PageService,
    private productService: ProductDetailsService,
    private tabDetailsService: TabDetailsService,
    private utilService: UtilsService,
    private userService: UserDetailsService,
    private agentService: AgentAndFirmService,
    private appAnalyticsService: AppAnalyticsService,
    private lfgLoaderService: LfgLoaderService,
    private updateCaseService: UpdateCaseService
  ) { }

  private caseId: string;
  private caseStatus: string;
  showLoader: boolean;

  // store existing insurance company List
  private existingInsuranceCompany: string[] = [];

  createPolicy(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('CREATE_NEW_POLICY', null, { caseId: this.caseId, productType: 'MoneyGuard' });
  }

  getCaseInfoPageQuestions(requestBody: GetCaseDataRequestObject): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService(
      'GET_QUESTIONS',
      null,
      requestBody
    );
  }

  getAvailableProductsCaseInfo(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_AVAILABLE_PRODUCTS');
  }

  generateForm(caseId?: string, formType?: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GENERATE_FORM', null, { caseId: caseId ?? this.caseId, productType: 'MoneyGuard', formType});
  }

  getActiveTabStatus(): TabStatus {
    return this.tabDetailsService.activeTab?.status;
  }

  isTabStatusUpdated(tabStatusOnInit: TabStatus, form: FormGroup): boolean {
    return (tabStatusOnInit.errorExists && form.status === 'VALID')
      || (!tabStatusOnInit.errorExists && form.status === 'INVALID');
  }

  updateCaseDetails(pageData: PageJsonObject, isPageValid: boolean, nextTab: TabInformation, isEmptyPage?: boolean): Observable<any> {
    const caseInfo: Case = {
      status: this.caseStatus,
      nextPageItem: this.pageService.getPageGroupByTab(nextTab),
      caseId: this.caseId,
      additionalInfo: this.updateCaseService.getSavedData()
    };

    const pageStatus = isPageValid ? PageStatusValue.VALID : PageStatusValue.INVALID;
    const payload: UpdateCaseDataRequestObject = {
      caseInfo,
      pageGroup: pageData,
      pageStatus: isEmptyPage ? PageStatusValue.NOTVISITED : pageStatus
    };

    if (pageData.page_group_name === PAGE_GROUP_NAME.POLICY_INFO_PAGE) {
      payload.stateCode = this.userService.getInsuredState();
      payload.mktName = this.productService.getMarketName();
    }

    if (this.caseId) {
      return this.serviceInvoker.invokeService(
        'UPDATE_CASE_DETAILS',
        null,
        payload
      );
    } else {
      return this.serviceInvoker.invokeService(
        'SAVE_CASE_DETAILS',
        null,
        payload
      );
    }
  }

  saveCaseDetails(pageData: PageJsonObject, isPageValid: boolean, isCaseInfoUpdated: boolean): Observable<ResponseObject> {
    let insuredFirstName;
    let insuredLastName;
    let productName;
    pageData?.pages[0]?.questions.forEach((question) => {
      if (question.xmlTag === CaseInfoConstant.firstNameId) {
        insuredFirstName = question.question_answer;
      } else if (question.xmlTag === CaseInfoConstant.lastNameId) {
        insuredLastName = question.question_answer;
      } else if (question.questionText.toLowerCase() === 'display product name') {
        productName = question.question_answer;
        this.productService.setProductName(productName);
        this.productService.setProductCondition();
      }
    });
    const caseId = this.caseId;
    const caseInfo: Case = {
      insuredFirstName,
      insuredLastName,
      status: this.caseStatus,
      productName,
      nextPageItem: PAGE_GROUP_NAME.POLICY_INFO_PAGE,
      caseId,
      additionalInfo: this.updateCaseService.getSavedData()
    };

    const payload: UpdateCaseDataRequestObject = {
      caseInfo,
      pageGroup: pageData,
      pageStatus: isPageValid ? PageStatusValue.VALID : PageStatusValue.INVALID,
      stateCode: this.userService.getInsuredState(),
      mktName: this.productService.getMarketName()
    };

     // in case of product change
     if (isCaseInfoUpdated && caseId) {
      payload.isProductUpdated = true;
      payload.stateCode = this.userService.getInsuredState();
      payload.productType = this.productService.getProductType();
      payload.insuredAge = this.userService.getInsuredAge()?.toString();
    }

    if (caseId) {
      return this.serviceInvoker.invokeService(
        'UPDATE_CASE_DETAILS',
        null,
        payload
      );
    } else {
      return this.serviceInvoker.invokeService(
        'SAVE_CASE_DETAILS',
        null,
        payload
      );
    }
  }

  addEmptyPage(pageName: string): Observable<ResponseObject> {
    const pageData = { page_group_name: pageName };
    return this.updateCaseDetails(pageData, false,
      this.tabDetailsService.getTabInfoByTabName(this.pageService.getTabByPageGroupName(pageName)), true);
  }

  updateEmptyPageStatus(pageName: string, isPageValid: boolean): Observable<ResponseObject> {
    const pageData = { page_group_name: pageName };
    return this.updateCaseDetails(pageData, isPageValid,
      this.tabDetailsService.getTabInfoByTabName(this.pageService.getTabByPageGroupName(pageName)), false);
  }

  deletePages(allListOfPages: string[]): Observable<ResponseObject> {
    const listOfPages = Array.from(new Set(allListOfPages));
    if (listOfPages?.length > 0) {
      const payload = {
        caseId: this.caseId,
        pagesToDelete: listOfPages
      };
      return this.serviceInvoker.invokeService('DELETE_PAGE_GROUP', null, payload);
    }
  }

  getSignersInfo(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_SIGNERS_LIST', null, { caseId: this.caseId });
  }

  validateAgent(payload: any): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_AGENT_VALIDITY', null, payload);
  }

  validatCaseManagerEmail(payload: any): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_CASE_MANAGER_EMAIL_VALIDITY', null, payload);
  }

  getPackageDetails(caseId?: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_PACKAGE_HISTORY', null, { caseId: caseId ?? this.caseId, productType: 'MoneyGuard' });
  }

  getCaseId(): string {
    return this.caseId;
  }

  setCaseId(caseId: string): void {
    this.caseId = caseId;
  }

  lockCase(): Observable<ResponseObject> {
    return this.updateCaseStatus(true);
  }

  unlockCase(): Observable<ResponseObject> {
    this.clearCachedMergedForm()
    return this.updateCaseStatus(false);
  }

  saveConsent(signerGuid: string, isConsented: boolean, envelopeId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('SAVE_CONSENT', null, { signerGuid, isConsented, caseId: this.caseId, envelopeId, productType: 'MoneyGuard' });
  }

  private updateCaseStatus(isLocked: boolean): Observable<ResponseObject> {
    let caseDetails;
    if (isLocked) {
      caseDetails = {
        caseId: this.caseId,
        mktName: this.productService.getMarketName()
      };
    }
    return this.serviceInvoker.invokeService('UPDATE_CASE_STATUS', null, { caseIds: [this.caseId], lockFlag: isLocked, productType: 'MoneyGuard', caseDetails });
  }

  getPageStatus(caseId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_PAGE_STATUS', null, { caseId });
  }

  getActivityLog(caseId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_ACTIVITY_LOG', { caseId });
  }

  updateTabInfoForExistingCase(pageAndCaseStatus: CaseAndPageStatus): void {
    const isCaseLocked = pageAndCaseStatus.caseStatus.isLocked;
    const isPolicyCreated = pageAndCaseStatus.caseStatus.status === CaseStatus.CASE_SUBMITTED;
    pageAndCaseStatus.pageStatusList?.forEach(pageStatus => {
      const pageStatusVal = this.getTabStatusByPageStatus(pageStatus, isCaseLocked, isPolicyCreated);
      if (this.pageService.getTabByPageGroupName(pageStatus.pageGroupName) === TAB.CASE_INFO) {
        this.tabDetailsService.updateTabList(TAB.CASE_INFO, true, pageStatusVal);
      }
    });
  }

  updateTabInfoForProductRelatedTab(pageAndCaseStatus: CaseAndPageStatus): void {
    const isCaseLocked = pageAndCaseStatus.caseStatus.isLocked;
    const isPolicyCreated = pageAndCaseStatus.caseStatus.status === CaseStatus.CASE_SUBMITTED;
    pageAndCaseStatus.pageStatusList?.forEach(pageStatus => {
      const pageStatusVal = this.getTabStatusByPageStatus(pageStatus, isCaseLocked, isPolicyCreated);
      this.updateTabStatusIfCannotBeLocked(pageStatus, pageStatusVal, isCaseLocked, isPolicyCreated);
      const tab = this.pageService.getTabByPageGroupName(pageStatus.pageGroupName);
      if (tab !== TAB.CASE_INFO) {
        this.tabDetailsService.updateTabList(tab, true, pageStatusVal);
      }
    });
  }

  lockOtherTabs(pageAndCaseStatus: CaseAndPageStatus): void {
    const isCaseLocked = pageAndCaseStatus.caseStatus.isLocked;
    const isPolicyCreated = pageAndCaseStatus.caseStatus.status === CaseStatus.CASE_SUBMITTED;
    this.tabDetailsService.updateTabList(TAB.APPLICATION_SUMMARY, true,
      { isLocked: isCaseLocked || isPolicyCreated, errorExists: false, visited: true });
    this.tabDetailsService.updateTabList(TAB.VALIDATE_AND_LOCK, true, { isLocked: isPolicyCreated, errorExists: false, visited: true });
  }

  private updateTabStatusIfCannotBeLocked(
    pageStatus: PageStatus, pageStatusVal: TabStatus, isCaseLocked: boolean,
    isPolicyCreated: boolean): void {
    if (isPolicyCreated) {
      pageStatusVal.isLocked = true;
    } else if (isCaseLocked && ExcludeTabsFromLock.includes(this.pageService.getTabByPageGroupName(pageStatus.pageGroupName))) {
      pageStatusVal.isLocked = false;
    }
  }

  isAllPageValid(): boolean {
    return this.tabDetailsService.isAllTabsValid(ExcludeTabsFromValidation);
  }

  private getTabStatusByPageStatus(pageStatus: PageStatus, isCaseLocked: boolean, isPolicyCreated: boolean): TabStatus {
    const pageStatusVal: TabStatus = {
      visited: this.isTabVisited(pageStatus),
      errorExists: pageStatus?.pageStatus?.toLowerCase() === PageStatusConstant.INVALID,
      isLocked: isCaseLocked || isPolicyCreated || pageStatus?.pageStatus?.toLowerCase() === PageStatusConstant.LOCKED,
    };
    return pageStatusVal;
  }

  updateFormErrors(form: FormGroup): boolean {
    if (this.tabDetailsService.getActiveTab()?.status?.visited) {
      form.markAllAsTouched();
      return true;
    }
    return false;
  }

  disableFormIfLocked(form: FormGroup, isLocked: boolean): void {
    setTimeout(() => {
      if (isLocked) {
        form.disable();
      }
    }, 400);
  }

  getFieldIdByTag(xmlTag: string): string {
    return this.utilService.getFieldIdByTag(xmlTag, this.pageService.getPageGroupByTab(this.tabDetailsService.activeTab));
  }

  updateOtherInformation(pageGroupData: PageJsonObject): void {
    this.updateOwnerInfoPage(pageGroupData);
    this.updatePrimaryInfo(pageGroupData);
    this.updateBillingInfoPage(pageGroupData);
  }

  getAgentProfile(): AgentProfile {
    return this.agentService.getAgentProfile();
  }

  resendEmail(envelopeId: string, signerListToUpdate: any[]): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('RESEND_EMAIL', null, { caseId: this.caseId, envelopeId, signers: signerListToUpdate, productType: 'MoneyGuard' });
  }

  filterResultsBasedOnBusinessRules(pageGroupName: string, pageGroup: PageJsonObject): void {
    if ((pageGroupName === PAGE_GROUP_NAME.AGENT_INFORMATION || pageGroupName === PAGE_GROUP_NAME.AGENT_INFO_CONTINUED)
      && !this.agentService.getAgentProfile().allStateFlag) {
      const filteredQuesList = this.removeAllStateQuestions(pageGroup);
      pageGroup.pages[0].questions = filteredQuesList;
    } else if (pageGroupName === PAGE_GROUP_NAME.AGENT_INFO_CONTINUED && this.agentService.getAgentProfile().allStateFlag) {
      const filteredQuesList = this.removeNonAllStateQuestions(pageGroup);
      pageGroup.pages[0].questions = filteredQuesList;
    }
  }

  updateQuestionMap(pageGroupJson: PageJsonObject): void {
    this.utilService.updateQuestionMap(pageGroupJson?.pages[0]?.questions, pageGroupJson?.page_group_name);
    if (pageGroupJson?.pages[0]?.gridInstances && pageGroupJson?.pages[0]?.gridInstances?.length > 0) {
      this.utilService.updateQuestionMap(pageGroupJson.pages[0].gridInstances[0]?.gridQuestions, pageGroupJson.page_group_name);
    }
  }

  updateAgentInformation(pageGroupData: PageJsonObject): void {
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.AGENT_INFORMATION ||
      pageGroupData.page_group_name === PAGE_GROUP_NAME.AGENT_REPORT_PAGE) {
        let agentFirstName, agentLastName, writingAgent;
      pageGroupData.pages[0].questions.forEach((question) => {
        if (question.xmlTag === AgentInfoConstant.agentEmailQuesId && question.question_answer) {
          this.agentService.setAgentPrimaryEmail(question.question_answer);
        } else if (question.xmlTag === AgentReportKeys.caseManagerEmailNotAllState && question.question_answer) {
          this.agentService.setCaseManagerEmail(question.question_answer);
        } else if (question.xmlTag === AgentInfoConstant.agentSSNQuesId && question.question_answer) {
          this.agentService.setAgentLast4DigitSSN(question.question_answer);
        } 
      });
      pageGroupData.pages[0].gridInstances[0]?.gridQuestions?.forEach(gridQues => {
        if (gridQues.xmlTag === AgentInfoConstant.agentFirstNameQuesId) {
          agentFirstName = gridQues.name;
        } else if (gridQues.xmlTag === AgentInfoConstant.agentLastNameQuesId) {
          agentLastName = gridQues.name;
        } else if (gridQues.xmlTag === AgentInfoConstant.isWritingAgentQuesId) {
          writingAgent = gridQues.name;
        }
      });
      pageGroupData.pages[0].gridAnswers?.forEach(gridAnsBlock => {
        if(gridAnsBlock[writingAgent] === 'Yes{47}Yes') {
          this.agentService.setAgentFirstName(gridAnsBlock[agentFirstName]);
          this.agentService.setAgentLastName(gridAnsBlock[agentLastName]);
        }
      });
    }
  }

  getRecipientsList(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_RECIPIENT', { caseId: this.caseId });
  }

  createEnvelope(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('CREATE_ENVELOPE', null, { caseId: this.caseId, productType: 'MoneyGuard' });
  }

  getTransactionStatus(transactionId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_TRANSACTION_STATUS', { transactionId }, { caseId: this.caseId });
  }

  voidEnvelope(envelopeId: string, reason: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('VOID_ENVELOPE', null, { caseId: this.caseId, envelopeId, reason, productType: 'MoneyGuard' });
  }

  saveRecipientInfo(recipientList: Recipient[], agentSignerInfo: Recipient): Observable<ResponseObject> {
    const signerSaveList = [];
    recipientList.forEach((recipient) => {
      signerSaveList.push({ id: recipient.id, eSignatureMethod: recipient.eSignatureMethod });
    });
    if (agentSignerInfo) {
      signerSaveList.push({ id: agentSignerInfo.id, eSignatureMethod: agentSignerInfo.eSignatureMethod });
    }

    console.log('signerSaveList', signerSaveList)
    return this.serviceInvoker.invokeService('SAVE_RECIPIENT', { caseId: this.caseId }, signerSaveList);
  }

  updateGridSequenceForGridData(pageGroup: any): void {
    let ind = pageGroup.page_group_name === PAGE_GROUP_NAME.AGENT_INFORMATION ? 2 : 1;
    const gridInstanceList = pageGroup?.pages[0]?.gridInstances;
    if (gridInstanceList?.length > 0) {
      const seqQuesName = gridInstanceList[0].gridQuestions.filter(ques => {
        return ques.xmlTag === OwnerInfoConstant.seqNumber || ques.xmlTag === AgentInfoConstant.seqNumber
          || ques.xmlTag === AgentInfoContConstant.seqNumber;
      })[0]?.name;
      if (seqQuesName) {
        pageGroup?.pages[0]?.gridAnswers.forEach(ansObj => {
          ansObj[seqQuesName] = (ind++).toString();
        });
      }
    }
  }

  private removeAllStateQuestions(pageGroup: PageJsonObject): Question[] {
    return pageGroup.pages[0]?.questions.filter(ques => {
      return ques.notes !== 'ITES_FILTER_ALL_STATE';
    });
  }

  private removeNonAllStateQuestions(pageGroup: PageJsonObject): Question[] {
    return pageGroup.pages[0]?.questions.filter(ques => {
      return ques.notes !== 'ITES_FILTER_NON_ALL_STATE';
    });
  }

  updateBillingInfoPage(pageGroupData: PageJsonObject): void {
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.BILLING_INFO_PAGE) {
      this.payorDetails = {};
      const question = pageGroupData.pages[0]?.questions.filter(ques => {
        if (ques.xmlTag == '/XML/Client/AppDetails/MGPayorSameAsType') {
          return ques;
        }
      });
      if (question[0].question_answer?.toLowerCase()?.indexOf('other') > -1) {
        this.otherPayorType(question);
      }
    }
  }

  otherPayorType(question): void {
    let otherPayorType = '';
    otherPayorType = question[0]?.reflexiveQuestionAL[0]?.question_answer;
    const nestedQuestions = question[0]?.reflexiveQuestionAL[0];
    if (otherPayorType?.toLowerCase()?.indexOf('person') > -1) {
      this.setOtherPersonPayor(nestedQuestions?.reflexiveQuestionAL);
    } else if (otherPayorType?.toLowerCase()?.indexOf('entity') > -1) {
      this.setOtherEntityPayor(nestedQuestions?.reflexiveQuestionAL);
    }
    this.setOtherPayorAddress(question[0]?.reflexiveQuestionAL[1]);
    this.setPayorInOwnerDetails();
  }

  setPayorInOwnerDetails(): void {
    let ownerDetails: any = this.userService.getOwnerDetails();
    ownerDetails = ownerDetails == undefined ? [] : ownerDetails;
    let ownerDetailsWithAddress: any = this.userService.getOwnerDetailsWithAddress();
    ownerDetailsWithAddress = ownerDetailsWithAddress == undefined ? [] : ownerDetailsWithAddress;
    const payorDetails = {
      OwnerIndex: '',
      ownerType: '',
      firstName: this.payorDetails.firstName,
      lastName: this.payorDetails.lastName,
      entityName: this.payorDetails.entityName,
      address: {
        streetAddress: this.payorDetails.address,
        streetAddress2: this.payorDetails.address2,
        streetAddress3: this.payorDetails.address3,
        city: this.payorDetails.city,
        state: this.payorDetails.state,
        zip: this.payorDetails.zip,
      },
      phone: this.payorDetails.phoneNumber,
      phoneType: this.payorDetails.phoneType,
      phonePin: this.payorDetails.phonePin,
      email: this.payorDetails.email,
    };
    ownerDetails.push({});
    ownerDetailsWithAddress.push(payorDetails);
    this.userService.setOwnerDetails(ownerDetails, ownerDetailsWithAddress);
    console.log(payorDetails);
  }

  setOtherPersonPayor(questions): void {
    questions.forEach((question) => {
      if (question.xmlTag === PayorOtherPersonConst.firstName) {
        this.payorDetails.firstName = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherPersonConst.lastName && question.optionChoice.indexOf('Person') > -1) {
        this.payorDetails.lastName = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherPersonConst.email) {
        this.payorDetails.email = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherPersonConst.phoneNumber) {
        this.payorDetails.phoneNumber = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherPersonConst.phoneType) {
        this.payorDetails.phoneType = this.questionAnswer(question);
        this.payorDetails.phonePin = this.questionAnswer(question.reflexiveQuestionAL[0])
      }
    });
  }

  setOtherEntityPayor(questions): void {
    questions.forEach((question) => {
      if (question.xmlTag === PayorOtherEntityConst.name) {
        this.payorDetails.entityName = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherEntityConst.email) {
        this.payorDetails.email = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherEntityConst.phoneNumber) {
        this.payorDetails.phoneNumber = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherEntityConst.phoneType) {
        this.payorDetails.phoneType = this.questionAnswer(question);
        this.payorDetails.phonePin = this.questionAnswer(question.reflexiveQuestionAL[0])
      }
    });
  }

  setOtherPayorAddress(questions): void {
    const addressSameAsOwner = this.questionAnswer(questions);
    if (addressSameAsOwner?.toLowerCase().indexOf('yes') > -1) {
      const insuredAddress: any = this.userService.getInsuredAddress();
      this.payorDetails.address = insuredAddress?.address1;
      this.payorDetails.address2 = insuredAddress?.address2;
      this.payorDetails.address3 = insuredAddress?.address3;
      this.payorDetails.city = insuredAddress?.city;
      this.payorDetails.state = insuredAddress?.state;
      this.payorDetails.zip = insuredAddress?.zipCode;
    } else {
      this.setPayorAddress(questions?.reflexiveQuestionAL);
    }
  }

  setPayorAddress(questions): void {
    questions.forEach((question) => {
      if (question.xmlTag === PayorOtherAddressConst.address) {
        this.payorDetails.address = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherAddressConst.address2) {
        this.payorDetails.address2 = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherAddressConst.address3) {
        this.payorDetails.address3 = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherAddressConst.city) {
        this.payorDetails.city = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherAddressConst.state) {
        this.payorDetails.state = this.questionAnswer(question);
      } else if (question.xmlTag === PayorOtherAddressConst.zip) {
        this.payorDetails.zip = this.questionAnswer(question);
      }
    });
  }

  private updateOwnerInfoPage(pageGroupData: PageJsonObject): void {
    const ownerDetailObj = [];
    const ownerDetailObjWithAddr = [];
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.OWNER_INFO_PAGE) {
      const ownerGridQuestions = pageGroupData?.pages[0].gridInstances[0].gridQuestions[0].reflexiveQuestionAL;
      const ownerGridQuestionsTrust = pageGroupData?.pages[0].gridInstances[1].gridQuestions[0].reflexiveQuestionAL;
      const ownerGridQuestionsEntity = pageGroupData?.pages[0].gridInstances[2].gridQuestions[0].reflexiveQuestionAL;

      let firstNameField = this.utilService.getFieldId(ownerGridQuestions, OwnerInfoConstant.OWNER_FIRST_NAME);
          firstNameField = this.getNameFromFieldId(firstNameField);
      let lastNameField = this.utilService.getFieldId(ownerGridQuestions, OwnerInfoConstant.OWNER_LAST_NAME);
          lastNameField = this.getNameFromFieldId(lastNameField);
      let trustNameField = this.utilService.getFieldId(ownerGridQuestionsTrust, OwnerInfoConstant.OWNER_LAST_NAME, 'trust');
          trustNameField = this.getNameFromFieldId(trustNameField);
      let entityNameField = this.utilService.getFieldId(ownerGridQuestionsEntity, OwnerInfoConstant.OWNER_LAST_NAME, 'entity');
          entityNameField = this.getNameFromFieldId(entityNameField);
      let ownerTypeNameField = this.utilService.getFieldId(pageGroupData?.pages[0].gridInstances[0].gridQuestions, OwnerInfoConstant.ownerType);
          ownerTypeNameField = this.getNameFromFieldId(ownerTypeNameField);

      this.getOwnerDetails(ownerGridQuestions);
      this.getOwnerDetails(ownerGridQuestionsTrust);
      this.getOwnerDetails(ownerGridQuestionsEntity);

      pageGroupData?.pages[0]?.gridAnswers.forEach((ans, i) => {

        // Get Same as Insured Address value
        this.checkSameAsInsAddress(ans, this.personSameAddressName, this.trustSameAddressName, this.entitySameAddressName);

        // if address type is 'same as insured' get data from insured info else owner info
        if (this.isSameInsuredAddress?.toLowerCase().indexOf('insured') > -1) {
          this.setDetailsForSameAsOwnerAddress(ans, firstNameField, lastNameField, ownerTypeNameField);
        } else {
          // GET Owner Address DeTails
          this.setOwnerAddress(ans, ownerTypeNameField, firstNameField, lastNameField, trustNameField);
        }
        const paramObj = {
          ownerTypeNameFieldVal: ownerTypeNameField,
          firstNameFieldVal: firstNameField,
          lastNameFieldVal: lastNameField,
          trustNameFieldVal: trustNameField,
          entityNameFieldVal: entityNameField
        }
        this.setObjectDetails(ans, ownerDetailObj, ownerDetailObjWithAddr, i, paramObj);
      });
      console.log(ownerDetailObj);
      console.log(ownerDetailObjWithAddr);
      this.userService.setOwnerDetails(ownerDetailObj, ownerDetailObjWithAddr);
      this.setInsuredDetailsInOwner();
    }
  }

  setObjectDetails(ans, ownerDetailObj, ownerDetailObjWithAddr, i, paramObj): void {
    const personOwnerType = ans[paramObj.ownerTypeNameFieldVal].toLowerCase().indexOf('person') > -1;
    if (this.checkFirstLastNamePresent()) {
      const objInDetails = {
        OwnerIndex: i + 1,
        ownerType: ans[paramObj.ownerTypeNameFieldVal],
        firstName: ans[paramObj.firstNameFieldVal],
        lastName: ans[paramObj.lastNameFieldVal],
        trustName: ans[paramObj.trustNameFieldVal],
        entityName: ans[paramObj.entityNameFieldVal],
        address: {
          streetAddress: this.ownerAddress,
          streetAddress2: this.ownerAddress2,
          streetAddress3: this.ownerAddress3,
          city: this.ownerCity,
          state: this.ownerState,
          zip: this.ownerZip,
        },
        phone: personOwnerType ? this.ownerPhone : '',
        email: personOwnerType ? this.ownerEmail : '',
        phoneType: personOwnerType ? this.ownerPhoneType : '',
        phonePin: personOwnerType? this.ownerPhonePin : '',
        isSameAsInsuredAddress: this.isSameInsuredAddress,
        value: 'Owner-' + (i + 1) + ans[paramObj.ownerTypeNameFieldVal],
        label: this.labelFirstName + ' ' + this.labelLastName,
      };
      const obj = {
        checked: false,
        label: this.labelFirstName + ' ' + this.labelLastName,
        value: 'Owner-' + (i + 1) + ans[paramObj.ownerTypeNameFieldVal],
      };
      ownerDetailObj.push(obj);
      ownerDetailObjWithAddr.push(objInDetails);
    }
  }

  setInsuredDetailsInOwner(): void {
    const ownerDetails: any = this.userService.getOwnerDetails();
    const ownerDetailsWithAddress: any = this.userService.getOwnerDetailsWithAddress();
    const insuredAddress: any = this.userService.getInsuredAddress();
    const insuredDetails = {
      OwnerIndex: '',
      ownerType: '',
      firstName: this.userService.getInsuredFirstName(),
      lastName: this.userService.getInsuredLastName(),
      trustName: null,
      entityName: null,
      entityFirstName: null,
      entityLastName: null,
      address: {
        streetAddress: insuredAddress?.address1,
        streetAddress2: insuredAddress?.address2,
        streetAddress3: insuredAddress?.address3,
        city: insuredAddress?.city,
        state: insuredAddress?.state,
        zip: insuredAddress?.zipCode,
      },
      phone: this.userService.getInsuredPhoneNo(),
      phoneType: this.userService.getInsuredPhoneType(),
      phonePin: this.userService.getInsuredPhonePIN(),
      email: this.userService.getInsuredEmail(),
      isSameAsInsuredAddress: null,
      value: '',
      label: null,
    };
    ownerDetails.push({});
    ownerDetailsWithAddress.push(insuredDetails);
    this.userService.setOwnerDetails(ownerDetails, ownerDetailsWithAddress);
  }

  setDetailsForSameAsOwnerAddress(ans, firstNameField, lastNameField, ownerTypeNameField): any {
    const insuredAddress: any = this.userService.getInsuredAddress();
    this.ownerAddress = insuredAddress?.address1;
    this.ownerAddress2 = insuredAddress?.address2;
    this.ownerAddress3 = insuredAddress?.address3;
    this.ownerCity = insuredAddress?.city;
    this.ownerState = insuredAddress?.state;
    this.ownerZip = insuredAddress?.zipCode;
    const insuredFN = this.userService.getInsuredFirstName();
    const insuredLN = this.userService.getInsuredLastName();
    if (insuredFN === ans[firstNameField] && insuredLN === ans[lastNameField]) {
      this.ownerPhone = this.userService.getInsuredPhoneNo();
      this.ownerEmail = this.userService.getInsuredEmail();
      this.ownerPhoneType = this.userService.getInsuredPhoneType();
      this.ownerPhonePin = this.userService.getInsuredPhonePIN();
    } else if (ans[ownerTypeNameField].toLowerCase().indexOf('person') > -1) {
      this.ownerPhone = ans[this.personPhoneFieldName];
      this.ownerEmail = ans[this.personEmailFieldName];
      this.ownerPhoneType = ans[this.personPhoneTypeFieldName];
      this.ownerPhonePin = ans[this.personPhoneTypePINFieldName];
    }
  }

  setOwnerAddress(ans, ownerTypeNameField, firstNameField, lastNameField, trustNameField): any {
    if (ans[ownerTypeNameField].toLowerCase().indexOf('person') > -1) {
      this.ownerAddress = ans[this.getNameFromFieldId(this.personAddress1FieldName)];
      this.ownerAddress2 = ans[this.getNameFromFieldId(this.personAddress2FieldName)];
      this.ownerAddress3 = ans[this.getNameFromFieldId(this.personAddress3FieldName)];
      this.ownerCity = ans[this.getNameFromFieldId(this.personCityFieldName)];
      this.ownerState = ans[this.getNameFromFieldId(this.personStateFieldName)];
      this.ownerZip = ans[this.getNameFromFieldId(this.personZipFieldName)];
      this.ownerPhone = ans[this.personPhoneFieldName];
      this.ownerEmail = ans[this.personEmailFieldName];
      this.ownerPhoneType = ans[this.personPhoneTypeFieldName];
      this.ownerPhonePin = ans[this.personPhoneTypePINFieldName];
      // Get first last name for dropdown label
      this.labelFirstName = ans[firstNameField];
      this.labelLastName = ans[lastNameField];
    } else if (ans[ownerTypeNameField].toLowerCase().indexOf('trust') > -1) {
      this.ownerAddress = ans[this.getNameFromFieldId(this.trustAddress1FieldName)];
      this.ownerAddress2 = ans[this.getNameFromFieldId(this.trustAddress2FieldName)];
      this.ownerAddress3 = ans[this.getNameFromFieldId(this.trustAddress3FieldName)];
      this.ownerCity = ans[this.getNameFromFieldId(this.trustCityFieldName)];
      this.ownerState = ans[this.getNameFromFieldId(this.trustStateFieldName)];
      this.ownerZip = ans[this.getNameFromFieldId(this.trustZipFieldName)];
      // Get first last name for dropdown label
      const trustName = this.getTrustName(ans, trustNameField);
      this.labelFirstName = trustName ? trustName[0] : '';
      this.labelLastName = this.getLastName(trustName);
    } else {
      this.ownerAddress = ans[this.getNameFromFieldId(this.entityAddress1FieldName)];
      this.ownerAddress2 = ans[this.getNameFromFieldId(this.entityAddress2FieldName)];
      this.ownerAddress3 = ans[this.getNameFromFieldId(this.entityAddress3FieldName)];
      this.ownerCity = ans[this.getNameFromFieldId(this.entityCityFieldName)];
      this.ownerState = ans[this.getNameFromFieldId(this.entityStateFieldName)];
      this.ownerZip = ans[this.getNameFromFieldId(this.entityZipFieldName)];
    }
  }

  getAddress(address1: string, address2: string, address3: string): string {
    const addrs1 = address1 || '';
    const addrs2 = address2 ? ` ${address2}` : '';
    const addrs3 = address3 ? ` ${address3}` : '';
    return addrs1 + addrs2 + addrs3;
  }

  getNameFromFieldId(fieldId: string): string {
    return fieldId?.split('_~_')?.splice(1, fieldId?.length-1)?.join('_~_');
  }

  private getOwnerDetails(ownerGridQuestions): void {
    ownerGridQuestions.forEach(ques => {
      this.getPhoneNumberFieldIdName(ques);
      this.getEmailFieldIdName(ques);
      if (this.checkOwnerAddressSameAs(ques)) {
        this.getOwnerAddrWhenAddressIsSameAs(ques);
      }
      // GET Owners Address same As Field name
      if ((ques.xmlTag === OwnerInfoConstant.OWNER_ADDRESS_SAME_AS)) {
        this.getOwnerAddressSameAsFieldName(ques);
      }
    });
  }

  private getPhoneNumberFieldIdName(question): void {
    if (this.getOptionChoiceType(question, 'person')) {
      if (question.questionText === OwnerInfoConstant.primaryPhoneQues) {
        this.personPhoneFieldName = question.name;
      }
      if (question.xmlTag === OwnerInfoConstant.primaryTypePhoneQues) {
        this.personPhoneTypeFieldName = question.name;
        this.personPhoneTypePINFieldName = question?.reflexiveQuestionAL[0]?.name;
      }
      if (question.xmlTag === OwnerInfoConstant.OWNER_EMAIL) {
        this.personEmailFieldName = question.name;
      }
    } else if (this.getOptionChoiceType(question, 'trust') && question.xmlTag === OwnerInfoConstant.ENTITY_BUISNESS_PHONE &&
      question.questionText?.indexOf(OwnerInfoConstant.entityPhoneText) > -1) {
      this.trustPhoneFieldName = question.name;
    } else if (this.getOptionChoiceType(question, 'entity') && question.xmlTag === OwnerInfoConstant.ENTITY_BUISNESS_PHONE &&
      question.questionText?.indexOf(OwnerInfoConstant.entityPhoneText) > -1) {
      this.entityPhoneFieldName = question.name;
    }
  }

  private getEmailFieldIdName(question): void {
    if (this.getOptionChoiceType(question, 'trust') && question.xmlTag === OwnerInfoConstant.TRUST_EMAIL &&
    question.questionText?.indexOf(OwnerInfoConstant.entityEmailText) > -1) {
    this.trustEmailFieldName = question.name;
    } else if (this.getOptionChoiceType(question, 'entity') && question.xmlTag === OwnerInfoConstant.TRUST_EMAIL &&
    question.questionText?.indexOf(OwnerInfoConstant.entityEmailText) > -1) {
    this.entityEmailFieldName = question.name;
    }
  }

  private getLastName(trustName): void {
    return ((trustName?.length) > 0 ? trustName[trustName.length - 1] : '');
  }

  private checkFirstLastNamePresent(): boolean {
    return (this.labelFirstName !== '' || this.labelLastName !== '');
  }

  private updatePrimaryInfo(pageGroupData: PageJsonObject): void {
    let streetAddress1;
    let streetAddress2;
    let streetAddress3;
    let city;
    let state;
    let zip;
    let country;
    let phoneNo;
    let email;
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE) {
      pageGroupData.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === PrimaryInsuredConstant.addr1XmlTag) {
          streetAddress1 = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.addr2XmlTag) {
          streetAddress2 = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.addr3XmlTag) {
          streetAddress3 = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.cityXmlTag) {
          city = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.stateXmlTag) {
          state = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.zipXmlTag) {
          zip = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.countryXmlTag) {
          country = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.phoneNoXmlTag) {
          phoneNo = this.questionAnswer(question);
          this.userService.setInsuredPhoneNo(phoneNo);
        } else if (question.xmlTag === PrimaryInsuredConstant.phoneTypeXmlTag) {
          this.setPhoneType(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.emailXmlTag) {
          email = this.questionAnswer(question);
          this.userService.setInsuredEmail(email);
        }
      });
      this.userService.setInsuredAddress(streetAddress1, streetAddress2, streetAddress3, city, state, country, zip);
    }
  }

  setPhoneType(question): void {
    let phoneType;
    let phonePIN;
    phoneType = this.questionAnswer(question);
    this.userService.setInsuredPhoneType(phoneType);
    if (question?.reflexiveQuestionAL[0]?.xmlTag === PrimaryInsuredConstant.phonePINXmlTag) {
      phonePIN = this.questionAnswer(question?.reflexiveQuestionAL[0]);
      this.userService.setInsuredPhonePIN(phonePIN);
    }
  }

  private questionAnswer(question): any {
    return (question.question_answer !== '') ? question.question_answer : null;
  }

  getOwnerAddressSameAsFieldName(ques): void {
    // GET Owners Address same As Field name
    if (this.getOptionChoiceType(ques, 'person')) {
      this.personSameAddressName = ques.name;
    } else if (this.getOptionChoiceType(ques, 'trust')) {
      this.trustSameAddressName = ques.name;
    } else if (this.getOptionChoiceType(ques, 'entity')) {
      this.entitySameAddressName = ques.name;
    }
  }

  private checkSameAsInsAddress(ans, personSameAddressName, trustSameAddressName, entitySameAddressName): void {
    if (ans[personSameAddressName]) {
      this.isSameInsuredAddress = this.getIsSameInsuredAddress(ans, personSameAddressName, 'insured');
    } else if (ans[trustSameAddressName]) {
      this.isSameInsuredAddress = this.getIsSameInsuredAddress(ans, trustSameAddressName, 'insured');
    } else if (ans[entitySameAddressName]) {
      this.isSameInsuredAddress = this.getIsSameInsuredAddress(ans, entitySameAddressName, 'insured');
    } else {
      this.isSameInsuredAddress = null;
    }
  }

  private getTrustName(ans, trustNameField): any {
    return (ans[trustNameField] !== '') ? ans[trustNameField]?.split(' ') : null;
  }

  private getIsSameInsuredAddress(ans, typeSameAddressName, type): any {
    return (ans[typeSameAddressName]?.toLowerCase().indexOf(type) > -1) ? ans[typeSameAddressName] : null;
  }

  getOwnerAddrWhenAddressIsSameAs(ques): void {
    if (this.getOptionChoiceType(ques, 'person')) {
      this.personAddress1FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS1);
      this.personAddress2FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS2);
      this.personAddress3FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS3);
      this.personCityFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_CITY);
      this.personStateFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
      this.personZipFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ZIP);
    } else if (this.getOptionChoiceType(ques, 'trust')) {
      this.trustAddress1FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS1);
      this.trustAddress2FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS2);
      this.trustAddress3FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS3);
      this.trustCityFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_CITY);
      this.trustStateFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
      this.trustZipFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ZIP);
    } else if (this.getOptionChoiceType(ques, 'entity')) {
      this.entityAddress1FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS1);
      this.entityAddress2FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS2);
      this.entityAddress3FieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS3);
      this.entityCityFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_CITY);
      this.entityStateFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
      this.entityZipFieldName = this.utilService.getFieldId(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ZIP);
    }
  }

  private checkOwnerAddressSameAs(ques): boolean {
    return (ques.hasReflexive && (ques.xmlTag === OwnerInfoConstant.OWNER_ADDRESS_SAME_AS));
  }

  private getOptionChoiceType(ques, type): boolean {
    return (ques.optionChoice.toLowerCase().indexOf(type) > -1);
  }

  private isTabVisited(pageStatus: PageStatus): boolean {
    return pageStatus && (pageStatus.pageStatus !== PageStatusValue.NOTVISITED);
  }

  private getCompanyList(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_COMPANY_LIST', null);
  }

  fetchCompanyNames(): void {
    if (this.existingInsuranceCompany?.length === 0) {
      this.getCompanyList().subscribe(res => {
        if (res.data.length > 0) {
          this.existingInsuranceCompany = res.data;
        }
      });
    }
  }

  getExistingInsuranceCompanyList(): string[] {
    return this.existingInsuranceCompany;
  }

  updateTabStatusBasedOnValueFetchedFromService(pageAndCaseStatus: CaseAndPageStatus): void {
    const isCaseLocked = pageAndCaseStatus.caseStatus.isLocked;
    const isPolicyCreated = pageAndCaseStatus.caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED;
    pageAndCaseStatus.pageStatusList?.forEach(pageStatus => {
      const pageStatusVal = this.getTabStatusByPageStatus(pageStatus, isCaseLocked, isPolicyCreated);
      this.updateTabLockStatus(pageStatus, pageStatusVal, isCaseLocked, isPolicyCreated);
      const tab = this.pageService.getTabByPageGroupName(pageStatus.pageGroupName);
      if (tab !== TAB.CASE_INFO && tab !== TAB.ELECTRONIC_SIGNATURE) {
        this.tabDetailsService.updateTabList(tab, true, pageStatusVal);
      }
      if (pageStatus.pageGroupName === PAGE_GROUP_NAME.SIGNATURE_METHOD && pageAndCaseStatus.caseStatus.status === CaseStatus.LOCKED_READY_TO_SIGN) {
        this.tabDetailsService.updateTabList(TAB.SIGNATURE_METHOD, true, pageStatusVal);
      }
      if (pageStatus.pageGroupName === PAGE_GROUP_NAME.SIGNATURE_METHOD && (pageAndCaseStatus.caseStatus.status === CaseStatus.CUSTOMER_DECLINE_TO_ESIGN || pageAndCaseStatus.caseStatus.status === CaseStatus.PRINCIPAL_AGENT_DECLINED)) {
        this.tabDetailsService.updateTabList(TAB.SIGNATURE_METHOD, true, pageStatusVal);
        this.tabDetailsService.updateTabList(TAB.E_SIGNATURE_INSTRUCTION, true, pageStatusVal);
        this.tabDetailsService.updateTabList(TAB.ELECTRONIC_SIGNATURE, true, pageStatusVal);
      }
      if (pageStatus.pageGroupName === PAGE_GROUP_NAME.SIGNATURE_METHOD && (pageAndCaseStatus.caseStatus.status === CaseStatus.AWAITING_CUSTOMER_ESIGN || pageAndCaseStatus.caseStatus.status === CaseStatus.AWAITING_AGENT_ESIGN)) {
        this.showPageLoader(true);
        this.triggerGetRecipientsList(pageStatusVal);
      }
      if (tab === TAB.CONTINGENT_BENEFICIARY_MG) {
        this.tabDetailsService.updateTabList(TAB.PRIMARY_BENEFICIARY_MG, true, pageStatusVal);
      }
    });
  }

  triggerGetRecipientsList(pageStatusVal): void {
    this.getRecipientsList().subscribe(response => {
      if (response?.data) {
        const signerList = response.data;
        const hasRemoteSigners = this.filterSignersBySigningMethod(signerList, 'remote');
        console.log('hasRemoteSigners', hasRemoteSigners);
        if (hasRemoteSigners.length > 0) {
          this.tabDetailsService.updateTabList(TAB.E_SIGNATURE_INSTRUCTION, true, pageStatusVal)
          this.tabDetailsService.updateTabList(TAB.ELECTRONIC_SIGNATURE, true, pageStatusVal);
        }
      }
      this.showPageLoader(false);
    });
  }

  private filterSignersBySigningMethod(signerList: Recipient[], signingMethod: string): Recipient[] {
    return signerList.filter(signer => {
      return signer.eSignatureMethod === signingMethod && signer.roleName !== 'Agent';
    });
  }

  addAdditionalTabsIfPageLockedOrPolicyCreated(casePageStatusRes: CaseAndPageStatus): void {
    if (casePageStatusRes.caseStatus.isLocked || this.isAllPageValid()) {
      this.lockAdditionalTabs(casePageStatusRes);
    }
  }

  private lockAdditionalTabs(pageAndCaseStatus: CaseAndPageStatus): void {
    const isCaseLocked = pageAndCaseStatus.caseStatus.isLocked;
    const isPolicyCreated = pageAndCaseStatus.caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED;
    this.tabDetailsService.updateTabList(TAB.APPLICATION_SUMMARY, true,
      { isLocked: isCaseLocked || isPolicyCreated, errorExists: false, visited: true });
    this.tabDetailsService.updateTabList(TAB.VALIDATE_AND_LOCK, true, { isLocked: isPolicyCreated, errorExists: false, visited: true });
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      if (!this.showLoader) {
        this.showLoader = true;
        this.lfgLoaderService.show();
      }
    } else {
      this.lfgLoaderService.hide();
      this.showLoader = false;
    }
  }

  private updateTabLockStatus(
    pageStatus: PageStatus, pageStatusVal: TabStatus, isCaseLocked: boolean,
    isPolicyCreated: boolean): void {
    const excludeFromLock = [TAB.REVIEW_AND_SUBMIT];
    if (isPolicyCreated) {
      pageStatusVal.isLocked = true;
    } else if (isCaseLocked && ExcludeTabsFromLock.includes(this.pageService.getTabByPageGroupName(pageStatus.pageGroupName))) {
      pageStatusVal.isLocked = false;
    }
    if (excludeFromLock.includes(this.pageService.getTabByPageGroupName(pageStatus.pageGroupName))) {
      pageStatusVal.isLocked = false;
    }
  }

  updatePageStatusForESignature(casePageStatusRes: CaseAndPageStatus): void {
    // case statuses after envelope created
    const envelopeCreatedStatuses = [CaseStatus.AWAITING_CUSTOMER_ESIGN, CaseStatus.AWAITING_AGENT_ESIGN,
    CaseStatus.APPLICATION_ESUBMITTED, CaseStatus.READY_TO_SUBMIT];
    if (casePageStatusRes.caseStatus?.status && envelopeCreatedStatuses.includes(casePageStatusRes.caseStatus.status)) {
      this.tabDetailsService.updateTabLockStatus(TAB.SIGNATURE_METHOD, true);
      // this.tabDetailsService.updateTabLockStatus(TAB.AGENT_INSTRUCTION, true);
      // if e signature exists and envelope is created then lock e signature instruction as well
      if (this.tabDetailsService.isTabActive(TAB.E_SIGNATURE_INSTRUCTION)) {
        this.tabDetailsService.updateTabLockStatus(TAB.E_SIGNATURE_INSTRUCTION, true);
      }
      if (this.tabDetailsService.isTabActive(TAB.ELECTRONIC_SIGNATURE)) {
        this.tabDetailsService.updateTabLockStatus(TAB.E_SIGNATURE_INSTRUCTION, true);
        this.tabDetailsService.updateTabStatusMap(TAB.ELECTRONIC_SIGNATURE, true);
      }
    }
  }

  goToLastAccessedTab(casePageStatus: CaseAndPageStatus): void {
    const lastVisitedPage = casePageStatus.caseStatus?.lastVisitedPage;
    const lastVisitedTabByCaseStatus = this.getLastVisistedPageBasedOnCaseStatus(lastVisitedPage, casePageStatus.caseStatus);
    if (lastVisitedPage || lastVisitedTabByCaseStatus) {
      const tabSelected = lastVisitedTabByCaseStatus ?? this.pageService.getTabByPageGroupName(lastVisitedPage, casePageStatus);
      if (this.tabDetailsService.isTabActive(tabSelected)) {
        this.tabDetailsService.selectTab(this.tabDetailsService.getTabByTabName(tabSelected));
      }
    }
  }

   /**
   * this method is added to get the last visited page from case id as Validate and lock,
   * thank you page are not getting saved as other pages
   * @param caseStatus casestatus
   * @returns the last visited tab name if applicable
   */

   private getLastVisistedPageBasedOnCaseStatus(lastVisitedPage, caseStatus: CaseStatusVal): string {
    let lastVisitedTab;
    if (caseStatus.status === CaseStatus.LOCKED_READY_TO_SIGN &&
      this.tabDetailsService.getTabsAvailableAfter(TAB.VALIDATE_AND_LOCK)?.length === 0) {
      lastVisitedTab = TAB.VALIDATE_AND_LOCK;
    } else if (caseStatus.status === CaseStatus.READY_TO_SUBMIT) {
      lastVisitedTab = TAB.REVIEW_AND_SUBMIT;
    } else if (caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED) {
      lastVisitedTab = TAB.REVIEW_AND_SUBMIT;
    } else if (this.tabDetailsService.isTabActive(TAB.SIGNATURE_METHOD) && lastVisitedPage === PAGE_GROUP_NAME.SIGNATURE_METHOD && (caseStatus?.status === CaseStatus.CUSTOMER_DECLINE_TO_ESIGN || caseStatus?.status === CaseStatus.PRINCIPAL_AGENT_DECLINED)) {
      lastVisitedTab = TAB.SIGNATURE_METHOD;
    } else if (this.tabDetailsService.isTabActive(TAB.ELECTRONIC_SIGNATURE) && lastVisitedPage === PAGE_GROUP_NAME.SIGNATURE_METHOD && (caseStatus?.status === CaseStatus.AWAITING_CUSTOMER_ESIGN || caseStatus?.status === CaseStatus.AWAITING_AGENT_ESIGN)) {
      lastVisitedTab = TAB.ELECTRONIC_SIGNATURE;
    }
    return lastVisitedTab;
  }

  cacheMergedForm(data){
    this.mergedForm = data
  }

  getCachedMergedForm(){
    return this.mergedForm
  }

  clearCachedMergedForm(){
    this.mergedFormTab = null
    this.mergedForm = null
  }

  updateMergedFormsTab(tab){
    this.mergedFormTab = tab
  }

  getMergedFormsTab(){
    return this.mergedFormTab
  }

  logPageLoad(): void {
    this.appAnalyticsService.logPageView(this.tabDetailsService.activeTab?.name);
  }

  logButtonClick(eventName: string): void {
    this.appAnalyticsService.logButtonEvents(eventName, this.tabDetailsService.activeTab?.analyticsPageName);
  }

  logLinkClick(eventName: string): void {
    this.appAnalyticsService.logLinkEvents(eventName, this.tabDetailsService.activeTab?.analyticsPageName);
  }

}
