<section class="wet-signature-collection-page page-content-container">

    <div class="questions--container">
        <h1>
            Wet Signature Collection Information
        </h1>

        <lfg-alert-message type="error" *ngIf="errorOccurred">{{CONST.GENERIC_ERROR}}</lfg-alert-message>

        <div class="nested-questions">
            <div class="questions-header">
                <div class="info-text" [innerHTML]="messages.FILE_LIST_TEXT_START"></div>
                <div class="info-text">
                    <ul>
                        <li *ngFor="let fileName of displayWetformList">{{fileName}}</li>
                    </ul>
                </div>
                <div class="info-text" [innerHTML]="messages.FILE_LIST_TEXT_END"></div>
                <div class="info-text" [innerHTML]="messages.MAIN_TEXT_1"></div>
                <div class="info-text" [innerHTML]="messages.MAIN_TEXT_2"></div>
                <div class="info-text" [innerHTML]="messages.MAIN_TEXT_3"></div>
            </div>
        </div>

        <section class="button-container">
            <lfg-left-button-tray>
                <button lfgDefaultButton type="button" class="wetSignForm" id="wetSignForm" tabindex="0"
                    aria-label="Forms for Wet Signature" (click)="wetSignFormClick()">Form(s) for Wet Signature</button>
            </lfg-left-button-tray>
        </section>
    </div>
</section>


<ng-container *ngIf="displayProgressModal">
    <app-progress-overlay [modalStateInput]="displayProgressModal" [messageToshow]="CONST.FORM_LOADER_MESSAGE"
        [progressPercentArray]="progressPercentArray" [showActionButton]="true" (openPdfForm)="openPdfForm($event)"
        [formAvailable]="formAvailable" [showDoNotCloseBrowserMessage]="false" (closeProgressModal)="closeProgressModal($event)">
    </app-progress-overlay>
</ng-container>