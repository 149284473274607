<section class="existing-insurance-page page-content-container">

  <div class="questions--container">
    <h1>
        Existing Insurance
    </h1>
    <div class="loader-container" *ngIf="showLoader">
      <lfg-loader></lfg-loader>
    </div>

    <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isGridFormValid || this.hasError)" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="existingInsuranceInfoForm" id="existingInsuranceInfoForm" method="post" *ngIf="existingInsuranceInfoData && existingInsuranceInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of existingInsuranceInfoData | dataFilter: null: null: questionsData?.page_group_name: existingInsuranceInfoForm; let ind = index;" >

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Alert' ">
            <p>{{ data.questionText }}</p>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required == 'true'">

              <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag !== EI_CONSTANT.otherInsPolicyInForceQuesID"
            [ngClass]="{'radio--err': (existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio"
                  formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}"
                  (change)="onRadioChange(data)"/>
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio"
                  formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}"
                  (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

              <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid) }">
            </lfg-dropdown>
            <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please select a valid option"></lfg-error>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Alert'">
            <lfg-alert-message type="warning">{{ data.questionText }}</lfg-alert-message>
          </div>

          <div class="section-divider" *ngIf="data.xmlTag !== EI_CONSTANT.salesMaterialsInd"></div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && existingInsuranceInfoForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data)}">
            <ng-container *ngFor="let dataChildL1 of data.reflexiveQuestionAL; index as dataChildL1ix">
              <ng-container *ngIf="nestedQuestionCheckExistingIns(data, dataChildL1, parentAnswer)">

                <!-- Questions headers -->
                <div class="questions-header" *ngIf="dataChildL1.controlTypeDesc == appConfig.fieldTypes.HDR">
                  <h2>{{ dataChildL1.questionText }}</h2>
                </div>

                <!-- Form element for text  -->
                <div class="form--control form-control-medium" *ngIf="dataChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT">
                  <lfg-input-wrap>
                    <input lfgInput placeholder="{{ addCorrectPlaceHolder(dataChildL1, 'placeHolder') }}" name="{{ displayTextLabel(dataChildL1) }}" aria-live="off" type='text' formControlName='{{ dataChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="dataChildL1.maxLength" [required]="dataChildL1.required == 'true'">

                    <lfg-error *ngIf="existingInsuranceInfoForm.get(dataChildL1.fieldId).touched && existingInsuranceInfoForm.get(dataChildL1.fieldId).invalid" message="Please enter a valid {{dataChildL1.questionText | lowercase}}"></lfg-error>
                  </lfg-input-wrap>
                </div>

              </ng-container>
            </ng-container>
          </div>



        </ng-container>

        <div *ngIf="gridInstance?.length > 0 && hasExistingInsurance">
          <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [isRevisit]="isTabVisited"
          [addAccordionText]="'Add a policy'" [maxGridAvailable]="maxNoOfInsurance" [accordionHeader]="'Existing Insurance'" [type]="'POLICY'" [name]="'Inforce'"
          [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm"></app-grid-structure>
        </div>

        <ng-container class="form-control-parent" *ngFor="let data of existingInsuranceInfoData; let ind = index;" >

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag === EI_CONSTANT.otherInsPolicyInForceQuesID"
            [ngClass]="{'radio--err': (existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio"
                  formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}"
                  (change)="onRadioChange(data)"/>
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio"
                  formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}"
                  (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag === EI_CONSTANT.otherInsPolicyInForceQuesID"></div>

        </ng-container>

      </form>
    </section>
  </div>
</section>
