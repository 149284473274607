<div *ngIf="showLoader" >
    <lfg-loader></lfg-loader>
</div>
<section *ngIf="tableDataLoaded" class="page-content-container form-selection-container">
    <div class="questions--container">
        <h1>Form Selections</h1>
    </div>
    <ng-container>
        <div *ngIf="errorInFormSelection"><lfg-alert-message type="error">{{ MessageConst.GENERIC_ERROR }}</lfg-alert-message></div>
    </ng-container>
    <div class="result-table-header" id="table-header-top">
        <div class="result-table__export">
            <p>{{MessageConst.NO_OF_FORMS}}: {{ tableData.data.length }}</p>
        </div>
        <div class="result-table__export show-form">
            <div class="lfg-checkbox">
                <input type="checkbox" [value]="optionalFormCheckbox" id="optionalForm" name="optionalForm"
                    (change)="selectOptionalCheckbox($event)">
                <label for="optionalForm" class="caseCheckBoxLabel">
                    <p>{{MessageConst.SHOW_OPTIONAL_FORMS}}</p>
                </label>
            </div>
            <ul class="result-table__export--list" [ngClass]="{ 'link-is-disabled': tableData.data.length === 0 }">
                <li>
                    <form [formGroup]="dashboardDDForm">
                        <span for="viewRecordsDDown" class="pad-right-10">Show: </span>
                        <lfg-dropdown id="viewRecordsDDown" formControlName="viewRecordsDDown" ngDefaultControl
                            modifierClass="lfg-dropdown--no-float no-placeholder" [options]="dropDownList"
                            (selectionChanged)="handleSelectionChange($event)">
                        </lfg-dropdown>
                    </form>
                </li>
            </ul>
        </div>
    </div>
    <div class="result-table-section" *ngIf="tableData.data.length !== 0">
        <lfg-primary-table [tableData]="tableData" (tableSortTriggered)="sortTableData($event)" id="case-info-dbtable">
            <ng-template #tableCelContentTemplate let-column="column" let-row="row">
                <div *ngIf="column.columnFlag === 'checkbox'">
                    <div class="lfg-checkbox">
                        <input type="checkbox" [value]="row.checkbox" [id]="'checkbox_'+row.orderSeq" [name]="row.orderSeq"
                            (change)="selectCaseCheckbox($event, row)" [checked]="row.required?.toLowerCase() === 'yes' ? true : row.checkbox"
                            [disabled]="row.required?.toLowerCase() === 'yes'">
                        <label [for]="'checkbox_'+row.orderSeq" class="caseCheckBoxLabel">
                            <span>none</span>
                        </label>
                    </div>
                </div>
                <div tabindex="0" *ngIf="column.columnFlag === 'formName'">
                    {{ row.formName }}
                </div>
                <div tabindex="0" *ngIf="column.columnFlag === 'formNumber'">
                    {{ row.formNumber }}
                </div>
                <div tabindex="0" *ngIf="column.columnFlag === 'required'">
                    {{ row.required?.toLowerCase() === 'yes' ? 'Yes' : 'No' }}
                </div>
                <div tabindex="0" *ngIf="column.columnFlag === 'formOrigin'">
                    {{ row.formOrigin }}
                </div>
            </ng-template>
        </lfg-primary-table>
        <lfg-left-button-tray>
            <button lfgDefaultButton type="submit" class="reviewAndPrint" id="reviewAndPrint" tabindex="0" aria-label="Review and Print all forms" (click)="reviewAndPrint(); focusOff($event);">REVIEW AND PRINT ALL FORMS</button>
        </lfg-left-button-tray>
    </div>
</section>

<ng-container *ngIf="displayProgressModal">
    <app-progress-overlay [modalStateInput]="displayProgressModal" [messageToshow]="MessageConst.FORM_LOADER_MESSAGE"
    [progressPercentArray]="progressPercentArray" [showActionButton]="true" (openPdfForm)="openPdfForm($event)"
    [formAvailable]="formAvailable" [showDoNotCloseBrowserMessage]="false" (closeProgressModal)="closeProgressModal($event)">
    </app-progress-overlay>
</ng-container>

<ng-container *ngIf="showLoader">
    <app-progress-overlay [modalStateInput]="showLoader" [progressPercentArray]="pageLoadPercentageArray" [showActionButton]="false" [showDoNotCloseBrowserMessage]="false" [messageToshow]="MessageConst.DO_NOT_CLOSE_LOADER_MESSAGE">
    </app-progress-overlay>
</ng-container>