import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumeric]'
})
export class OnlyNumericDirective {

  decimalPlaces = '2';
  @Input() allowDecimals = false;

  constructor(private elemRef: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event): void {
    const e = event as KeyboardEvent;

    const allowedKeys = ['Delete', 'Backspace', 'Tab', 'Esc', 'Enter'];
    if (this.allowDecimals === true && this.decimalPlaces) {
      allowedKeys.push('.', '>');
    }
    if (allowedKeys.indexOf(e.key) !== -1 ||
      // Allow: Ctrl+A
      (e.key === 'a' && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.key === 'c' && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.key === 'x' && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.key === 'End' || e.key === 'Home' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    const allowedNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if ((e.shiftKey || (allowedNumbers.indexOf(e.key) === -1) )) {
      e.preventDefault();
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): void {
    const e = event;
    const fieldValue = this.elemRef.nativeElement.value;
    const eventKeyCode = e.key;

    if (this.allowDecimals !== true || this.decimalPlaces === null) {
      return;
    }

    let currentCursorPos = -1;
    if (typeof this.elemRef.nativeElement.selectionStart === 'number') {
      currentCursorPos = this.elemRef.nativeElement.selectionStart;
    }

    if (eventKeyCode === '.' && (fieldValue.length === 0 || currentCursorPos === 0)) {
      e.preventDefault();
    }

    const dotLength: number = fieldValue.replace(/[^\.]/g, '').length;
    // If user has not entered a dot(.) fieldValue.split('.')[1] will be undefined
    const decimalLength = fieldValue.split('.')[1] ? fieldValue.split('.')[1].length : 0;

    // (this.DecimalPlaces - 1) because we don't get decimalLength including currently pressed character
    // currentCursorPos > fieldValue.indexOf('.') because we must allow user's to enter value before dot(.)
    // Checking Backspace etc.. keys because firefox doesn't pressing them while chrome does by default
    if (dotLength > 1 || (dotLength === 1 && eventKeyCode === '.') || (decimalLength > (parseInt(this.decimalPlaces, 10) - 1) &&
      currentCursorPos > fieldValue.indexOf('.')) && ['Backspace', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) === -1) {
      e.preventDefault();
    }
  }

  // tslint:disable-next-line: variable-name
  @HostListener('keyup', ['$event']) onKeyUp(_event): void {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    if (isAndroid) {
      const fieldValue = this.elemRef.nativeElement.value;
      // added '-' to support ssn field as this directive used in ssn field only
      if (fieldValue && fieldValue.length > 0 && fieldValue.match(/[^0-9-]/)) {
        this.elemRef.nativeElement.value = fieldValue.replace(/[^0-9-]/g, '');
        return;
      }
    }
  }

}
