<div *ngIf="isWholesaler" class="case-id-container">
  Case ID: {{refCaseId}}
</div>
<div class="product-container">
  <div>
    <p>Primary insured: <strong>{{primaryInsuredName | titlecase }}</strong></p>
  </div>
  <div class="divider">
    |
  </div>
  <div>
    <p>Product: <strong>{{productName}}</strong></p>
  </div>
  <ng-container *ngIf="isWholesaler">
    <div class="divider">
      |
    </div>
    <div>
      <p>State: <strong>{{state}}</strong></p>
    </div>
    <div class="divider">
      |
    </div>
    <div>
      <p>Specified face amount: <strong>{{faceAmount | currency }}</strong></p>
    </div>
  </ng-container>
</div>
