<div class="desktopSideNav--view">
  <div class="progressBar-tabs__header">
    SECTIONS
  </div>
  <nav class="base-nav" aria-label="Side navigation">
    <ng-container *ngFor="let tab of tabInfoList; let i=index;">
      <button class="base-nav__item" [disabled]="noGreenCardholder" (click)="changeTab(tab)" *ngIf="tab.isDefault || tab.isEnable" [ngClass]="{'selectedTab': tab?.name == activeTab?.name }" [attr.aria-label]="ariaLabel(tab)">
        <div class="link-container" [ngClass]="{'background-disable': (!tab.status.visited || tab.status.isLocked) && tab?.name != activeTab?.name,
        'completed-tab': !tab.status.isLocked && tab?.name != activeTab?.name && tab.status.visited && !tab.status.errorExists,
        'error-tab': !tab.status.isLocked && tab?.name != activeTab?.name && tab.status.visited && tab.status.errorExists}">
          <span class="base-nav__row">

            <span aria-hidden="true" class="button-container">
              <span *ngIf="tab.status.isLocked">
                <i class="fas fa-lock side-icon lock-icon" aria-hidden="true"></i>
              </span>

              <span *ngIf="!tab.status.isLocked">
                <span *ngIf="tab?.name != activeTab?.name">
                  <i *ngIf="!tab.status.visited" class="fas fa-chevron-circle-right side-icon upcoming-icon" aria-hidden="true"></i>
                  <span *ngIf="tab.status.visited">
                    <i *ngIf="!tab.status.errorExists" class="fas fa-check-circle side-icon complete-icon" aria-hidden="true"></i>
                    <i *ngIf="tab.status.errorExists" class="fas fa-exclamation-triangle side-icon need-attention-icon" aria-hidden="true"></i>
                  </span>
                </span>
              </span>
              <span *ngIf="tab.status.isLocked">
                <strong>{{ tab?.name }}</strong>
              </span>
              <span *ngIf="!tab.status.isLocked">
                {{ tab?.name }}
              </span>
            </span>
            <span class="element-for-screen-reader">{{ ariaLabel(tab) }}</span>
            <i *ngIf="tab?.name !== activeTab?.name && !tab.status.isLocked && tab.status.visited" class="fas fa-angle-right current-icon" aria-hidden="true"></i>
          </span>
        </div>
      </button>
    </ng-container>

  </nav>
</div>
<button class="sideBar-nav__header" tabindex="0">
  <a (click)="triggerShow()">
    <i class="far fa-list-alt" aria-hidden="true"></i> SECTIONS
  </a>
  <a (click)="getHelpData()" >
    <span class="help-icon"><i class="fas fa-question-circle help-icon-image"></i>Help</span>
  </a>
  <div class="help-wrapper" id="wrapperdiv" aria-live="assertive" *ngIf="displayHelpData">
    <app-help-hint (closeSlide)="closeSlide($event)" [activeTab]="activeTab?.name" [ngClass]="closeHelpHint ? 'help-slide-right' : 'help-slide-left'"></app-help-hint>
  </div>
</button>
<lfg-overlay class="lfg-overlay-lyc" [showOverlay]="showOverlay" dialogAriaLabelledBy="dialog_label" dialogAriaDescdBy="dialog_desc" firstFocusElId="btn_viewContri">
  <div class="sidebar-slider" [class.sidebar-slide-in]="showOverlay">
    <div class="sidebar-content">
      <div class="mobileSideNav--view">
        <button class="sideBar-navMobile__header" tabindex="0">
          <span><i class="far fa-list-alt" aria-hidden="true"></i> SECTIONS</span>
          <i (click)="closeOverlay()" class="fa" aria-hidden="true">&#xf00d;</i>
        </button>
        <nav class="mobile-nav" aria-label="Side navigation">
          <ng-container *ngFor="let tab of tabInfoList; let i=index;">
            <button class="mobile-nav__item" [disabled]="noGreenCardholder" (click)="changeTab(tab)" tabindex="0" *ngIf="tab.isDefault || tab.isEnable" [ngClass]="{'selectedTab': tab?.name == activeTab?.name}">
              <div class="link-container" [ngClass]="{'background-disable': (!tab.status.visited || tab.status.isLocked) && tab?.name != activeTab?.name,
              'completed-tab': !tab.status.isLocked && tab?.name != activeTab?.name && tab.status.visited && !tab.status.errorExists,
              'error-tab': !tab.status.isLocked && tab?.name != activeTab?.name && tab.status.visited && tab.status.errorExists}">
                <span class="button-container">
                  <span *ngIf="tab.status.isLocked">
                    <i class="fas fa-lock side-icon lock-icon" aria-hidden="true"></i>
                  </span>

                  <span *ngIf="!tab.status.isLocked">
                    <span *ngIf="tab?.name != activeTab?.name">
                      <i *ngIf="!tab.status.visited" class="fas fa-chevron-circle-right side-icon upcoming-icon" aria-hidden="true"></i>
                      <span *ngIf="tab.status.visited">
                        <i *ngIf="!tab.status.errorExists" class="fas fa-check-circle side-icon complete-icon" aria-hidden="true"></i>
                        <i *ngIf="tab.status.errorExists" aria-hidden="true" class="fas fa-exclamation-triangle side-icon need-attention-icon"></i>
                      </span>
                    </span>
                  </span>
                  <span *ngIf="!tab.status.isLocked">
                    {{ tab?.name }}
                  </span>
                  <span *ngIf="tab.status.isLocked">
                    <strong>{{ tab?.name }}</strong>
                  </span>
                  <i *ngIf="tab?.name !== activeTab?.name && !tab.status.isLocked && tab.status.visited" class="fas fa-angle-right current-icon" aria-hidden="true"></i>
                </span>
              </div>
            </button>
          </ng-container>
        </nav>
        <div class="mobile-nav__btmcntr">
          <p>Status : <strong>{{displayCaseStatus}}</strong></p>
          <p>Date modified : <strong>{{dateModified | date: 'MM/dd/yyyy'}}</strong></p>
          <lfg-left-button-tray>
            <button class="btn btn--whitebg-border" type="button" id="saveAndExit" tabindex="0" aria-label="Save and exit">Save and exit</button>
          </lfg-left-button-tray>
        </div>
      </div>
    </div>
  </div>
</lfg-overlay>
