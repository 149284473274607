import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { TAB, TabInformation } from 'src/config/sideNav.config';
import { ResponseObject, DeleteCaseIdsObject, CaseAndPageStatus } from '../models/casePage.model';
import { ServiceInvoker } from './service-invoker';
import { CaseStatus } from 'src/config/constants';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  TabPageMap: Map<string, string> = new Map([
    [TAB.CASE_INFO, PAGE_GROUP_NAME.CASE_INFO_PAGE],
    // dont change sequence
    [TAB.WET_SIGNATURE_INSTRUCTION, PAGE_GROUP_NAME.SIGNATURE_METHOD],
    [TAB.E_SIGNATURE_INSTRUCTION, PAGE_GROUP_NAME.SIGNATURE_METHOD],

    [TAB.POLICY_INFO_MG, PAGE_GROUP_NAME.POLICY_INFO_PAGE],
    [TAB.PRIMARY_INSURED_MG, PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE],
    [TAB.OWNER_INFO, PAGE_GROUP_NAME.OWNER_INFO_PAGE],
    [TAB.PRIMARY_BENEFICIARY_MG, PAGE_GROUP_NAME.BENEFICIARY_INFO_PAGE],
    [TAB.CONTINGENT_BENEFICIARY_MG, PAGE_GROUP_NAME.BENEFICIARY_INFO_PAGE],
    [TAB.EXISTING_INS_MG, PAGE_GROUP_NAME.EXISTING_INSURANCE],
    [TAB.DCA_PREMIUM_ALLOCATION_MG, PAGE_GROUP_NAME.DCA_PREMIUM_ALLOCATION],
    [TAB.WET_SIGN_COLLECTION_INFO_MG, PAGE_GROUP_NAME.WET_SIGN_COLLECTION_INFO_PAGE],
    [TAB.PREMIUM_INFO_MG, PAGE_GROUP_NAME.BILLING_INFO_PAGE],
    [TAB.UNINTENDED_LAPSE_MG, PAGE_GROUP_NAME.THIRD_PARTY_DESIGNEE_PAGE],
    [TAB.TEMPORARY_LIFE_MG, PAGE_GROUP_NAME.TEMPORARY_LIFE_INSURANCE_PAGE],
    [TAB.LIFE_SUITABILITY_MG, PAGE_GROUP_NAME.LIFE_SUITABILITY_PAGE],
    [TAB.LTC_REPLACEMENT_MG, PAGE_GROUP_NAME.LTC_REPLACEMENT_PAGE],
    [TAB.LTC_SUITABILITY_MG, PAGE_GROUP_NAME.LTC_SUITABILITY_PAGE],
    [TAB.ILLUSTRATION_COMPLIANCE, PAGE_GROUP_NAME.ILLUSTRATION_COMPLIANCE_PAGE],
    [TAB.AGENT_INFO, PAGE_GROUP_NAME.AGENT_INFORMATION],
    [TAB.AGENT_REPORT_MG, PAGE_GROUP_NAME.AGENT_REPORT_PAGE],
    [TAB.APPLICATION_SUMMARY, PAGE_GROUP_NAME.SUMMARY_INFO],
    [TAB.VALIDATE_AND_LOCK, PAGE_GROUP_NAME.VALIDATE_AND_LOCK],
    [TAB.SIGNATURE_METHOD, PAGE_GROUP_NAME.SIGNATURE_METHOD],
    [TAB.ELECTRONIC_SIGNATURE, PAGE_GROUP_NAME.ELECTRONIC_SIGNATURE],

    [TAB.CONSENT_PAGE_INSURED_OWNER, PAGE_GROUP_NAME.CONSENT_PAGE_INSURED_OWNER],
    [TAB.CONSENT_PAGE_INSURED_B, PAGE_GROUP_NAME.CONSENT_PAGE_INSURED_B],
    [TAB.CONSENT_PAGE_ADDITIONAL_INSURED, PAGE_GROUP_NAME.CONSENT_PAGE_ADDITIONAL_INSURED],
    [TAB.CONSENT_PAGE_AGENT, PAGE_GROUP_NAME.CONSENT_PAGE_AGENT],
    [TAB.CONSENT_PAGE_PI, PAGE_GROUP_NAME.CONSENT_PAGE_PI],
    [TAB.CONSENT_PAGE_OWNER, PAGE_GROUP_NAME.CONSENT_PAGE_OWNER],
    [TAB.CONSENT_PAGE_COOWNER, PAGE_GROUP_NAME.CONSENT_PAGE_COOWNER],
    [TAB.CONSENT_PAGE_COOWNER2, PAGE_GROUP_NAME.CONSENT_PAGE_COOWNER2],
    [TAB.CONSENT_PAGE_COOWNER3, PAGE_GROUP_NAME.CONSENT_PAGE_COOWNER3],
    [TAB.CONSENT_PAGE_PAYOR, PAGE_GROUP_NAME.CONSENT_PAGE_PAYOR],
    [TAB.CONSENT_PAGE_PRINCIPAL, PAGE_GROUP_NAME.CONSENT_PAGE_PRINCIPAL],
    [TAB.REVIEW_AND_SUBMIT, PAGE_GROUP_NAME.REVIEW_AND_SUBMIT],
  ]);

  PageTabMap: Map<string, string> = new Map<string, string>();

  constructor(private serviceInvoker: ServiceInvoker) {
    this.TabPageMap.forEach((pageName: string, tabName: string) => {
      this.PageTabMap.set(pageName, tabName);
    });
  }

  getPageGroupByTabByTabName(tabName: string): string {
    const tabInfo = {
      name: tabName,
      isDefault: false,
      isEnable: false,
      status: undefined,
    };
    return this.getPageGroupByTab(tabInfo);
  }

  getPageGroupByTab(tab: TabInformation): string {
    return this.TabPageMap.get(tab?.name);
  }

  getTabByPageGroupName(pageGroupName: string, casePageStatus?: CaseAndPageStatus): string {
    if (pageGroupName === PAGE_GROUP_NAME.SIGNATURE_METHOD && casePageStatus?.caseStatus?.status === CaseStatus.LOCKED_READY_TO_SIGN) {
        return TAB.SIGNATURE_METHOD
      }
      if (pageGroupName === PAGE_GROUP_NAME.SIGNATURE_METHOD && (casePageStatus?.caseStatus?.status === CaseStatus.AWAITING_CUSTOMER_ESIGN || casePageStatus?.caseStatus?.status === CaseStatus.AWAITING_AGENT_ESIGN)) {
        return TAB.ELECTRONIC_SIGNATURE
      }
    return this.PageTabMap.get(pageGroupName);
  }

  getTableData(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_CASES');
  }

  deleteCases(deleteCaseIds: string[]): Observable<ResponseObject> {
    const payload: DeleteCaseIdsObject = {
      caseIds: deleteCaseIds
    };
    return this.serviceInvoker.invokeService('DELETE_CASES', null, payload);
  }


  cloneCase(caseId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('CLONE_CASE', {caseId});
  }
}
