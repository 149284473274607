<section class="esignature-instruction page-content-container">
  <div class="questions--container">
    <h1>
      eSignature Instructions
    </h1>
  </div>

  <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
  <div *ngIf="envelopeCreated"><lfg-alert-message type="success">{{ ELECTRONIC_SIGN_CONST.Packet_Success_Msg }}</lfg-alert-message></div>
  <div *ngIf="errorOccuredWhileCreateEnvelope || errorOccuredToGetTransactionId"><lfg-alert-message type="error">{{ Message.GENERIC_ERROR }}</lfg-alert-message></div>

  <div class="instructions-text">
    <p>{{SignerMessage.Signers_List_Message}}</p>
  </div>

  <!-- element for signers -->
  <div class="info-section">
    <div class="column-section" *ngFor="let signer of remoteSignerList; let ind = index;">
      <div class="signer-info">
        <p class="font-bold">{{signer.fullName | titlecase}}</p>
        <p>{{signer.displayRole | titlecase}}</p>
      </div>
    </div>
  </div>

  <div class="instructions-text">
    <p [innerHTML]="SignerMessage.ESignature_Instructions_Message"></p>
  </div>

  <!-- Agent info section-->
  <section class="nested-questions">
    <form class="form-group" [formGroup]="eSignatureInstructionForm" id="eSignatureInstructionForm" method="post" *ngIf="eSignatureInstructionData && eSignatureInstructionData.length> 0">
      <ng-container class="form-control-parent" *ngFor="let data of eSignatureInstructionData;">

        <!-- element for label-->
        <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Label'">
          <h2>{{ data.questionText }}</h2>
        </div>

        <!-- element for text-->
        <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText != 'Label' && data.questionText.indexOf('The eSignature process requires') == -1 &&  data.questionText.indexOf('Upon careful review of all') == -1 && data.questionText.indexOf('eSignature emails will be') == -1">
          <span [innerHTML]="data.questionText"></span>
        </div>

        <!-- Form element for Agent last 4 digit SSN -->
        <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT">
          <lfg-input-wrap>
            <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appOnlyNumeric mask="0000" [maxlength]="data.maxLength" [required]="data.required == 'true'">
            <lfg-error *ngIf="eSignatureInstructionForm.get(data.fieldId).touched && eSignatureInstructionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, eSignatureInstructionForm.get(data.fieldId)) }}"></lfg-error>
          </lfg-input-wrap>
        </div>

        <!-- Form element for email  -->
        <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
          <lfg-input-wrap>
            <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (blur)="data.xmlTag == '/XML/MGeAPP/SignatureMethod/AgentEmail' ? addValidators() : ''" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">
            <lfg-error *ngIf="eSignatureInstructionForm.get(data.fieldId).touched && eSignatureInstructionForm.get(data.fieldId).invalid && !eSignatureInstructionForm.get(data.fieldId).errors.confirmEmailError" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            <lfg-error *ngIf="eSignatureInstructionForm.get(data.fieldId).touched && eSignatureInstructionForm.get(data.fieldId).invalid && eSignatureInstructionForm.get(data.fieldId).errors.confirmEmailError" message="{{eSignatureInstructionForm.get(data.fieldId).errors.confirmEmailError}}"></lfg-error>
          </lfg-input-wrap>
        </div>

      </ng-container>
    </form>
  </section>

</section>
