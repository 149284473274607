import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class NumberFormatPipe implements PipeTransform {
  transform(phone: string): string {
    if (!phone) {
      return '';
    } else {
      if (phone.trim().length !== 10) {
        return phone;
      } else {
        phone =
          '(' +
          phone.substring(0, 3) +
          ') ' +
          phone.substring(3, 6) +
          '-' +
          phone.substring(6);
        return phone;
      }
    }
  }
}
