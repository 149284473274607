<app-header *ngIf="userRole === ROLE_TYPE.WHOLESALER && !isHub"></app-header>
<app-base-page-welcome [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER"></app-base-page-welcome>
<div class="base-link-block">
  <a (click)="rdToeTicketDashboard()">
    <i class="far fa-arrow-alt-circle-right"></i>
    <span>Dashboard</span>
  </a>
</div>
<ng-container *ngIf="activeTab?.name !== TAB.CASE_INFO && activeTab?.name !== TAB.CASE_INFO_WS">
  <app-base-page-header [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER"></app-base-page-header>
</ng-container>
<section class="main-container">

  <aside role="Sidebar Navigation" class="progressBar-tabs__container">
    <app-side-nav [noGreenCardholder]="noGreenCardholder" [userRole]="userRole"></app-side-nav>
  </aside>
  <section class="progressBar-content">
    <div class="progressBar-content__header">
      APPLICATION
      <a (click)="getHelpData()">
        <span class="help-icon"><i class="fas fa-question-circle help-icon-image"></i>Help</span>
      </a>
      <div class="help-wrapper" id="wrapperdiv" aria-live="assertive" *ngIf="displayHelpData">
        <app-help-hint (closeSlide)="closeSlide($event)" [activeTab]="activeTab?.name" [userRole]="userRole" [ngClass]="closeHelpHint ? 'help-slide-right' : 'help-slide-left'"></app-help-hint>
      </div>
    </div>
    <div *ngIf="showLoader" >
      <lfg-loader></lfg-loader>
    </div>
    <ng-container *ngIf="displayProgressModal">
      <app-progress-overlay [modalStateInput]="displayProgressModal" [messageToshow]="Message.POLICY_SUBMISSION_LOADER_MESSAGE"></app-progress-overlay>
    </ng-container>
    <ng-container *ngIf="displayProgressModalForESign">
      <app-progress-overlay [modalStateInput]="displayProgressModalForESign" [messageToshow]="isAllSignerRemote ? Message.ENVELOPE_CREATION_LOADER_MESSAGE : Message.BOTH_ENVELOPE_CREATION_LOADER_MESSAGE" [hasProgressPercentage]="true" [progressPercent]="progressPercentage"></app-progress-overlay>
    </ng-container>

    <lfg-modal-popup *ngIf="areYouSureModalConfig" [modalConfig]="areYouSureModalConfig" (closeModalPopup)="updateAreYouSureModalPopup(false)">
      <div modal-header>
        <h1>Are you sure?</h1>
      </div>
      <div modal-body>
        Unlocking the page will delete all completed signatures and start the process again.
      </div>
      <div modal-footer>
        <lfg-left-button-tray>
          <button class='btn' lfgDefaultButton aria-label="Unlock page" (click)="unlockPageAndVoidEnvelope()">YES</button>
          <button class='btn' lfgDefaultButton aria-label="Cancel Unlock" (click)="updateAreYouSureModalPopup(false)">CANCEL</button>
        </lfg-left-button-tray>
      </div>
    </lfg-modal-popup>

    <ng-container *ngIf="systemMessage?.type === MessageType.OUTAGE">
      <div class="application-unavailable-container">
        <lfg-alert-message type="error">{{systemMessage?.message}}</lfg-alert-message>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab && systemMessage?.type !== MessageType.OUTAGE">
      <div class="progressBar-content__pageContainer">
        <div class="progressBar-content__subheader">
          <div class="page-wrapper">
            <ng-container *ngIf="activeTab?.name === TAB.CASE_INFO && eticketQuestions.get(activeTab?.name)">
              <app-case-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)"  (caseInfoUpdated)="caseInfoUpdated($event)" (caseInfoLoaded)="caseInfoLoaded($event)" [disableForm]="checkIfActiveTabLocked()"></app-case-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.POLICY_INFO_MG && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.PRIMARY_INSURED_MG && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (checkForWetSignature)="checkForWetSignatureTab($event)"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.OWNER_INFO && eticketQuestions.get(activeTab?.name)">
              <app-owner-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-owner-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.PRIMARY_BENEFICIARY_MG && eticketQuestions.get(activeTab?.name)">
              <app-beneficiary-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" type="Primary"></app-beneficiary-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONTINGENT_BENEFICIARY_MG && eticketQuestions.get(activeTab?.name)">
              <app-beneficiary-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" type="Contingent"></app-beneficiary-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.PREMIUM_INFO_MG && eticketQuestions.get(activeTab?.name)">
              <app-billing-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" type="Billing"></app-billing-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.PAYOR_INFO_MG && eticketQuestions.get(activeTab?.name)">
              <app-billing-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" type="Payor"></app-billing-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.EXISTING_INS_MG && eticketQuestions.get(activeTab?.name)">
              <app-existing-insurance [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (is1035Exchange)="checkForWetSignatureTab($event)"></app-existing-insurance>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.DCA_PREMIUM_ALLOCATION_MG && eticketQuestions.get(activeTab?.name)">
              <app-dca-allocation [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-dca-allocation>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.WET_SIGN_COLLECTION_INFO_MG">
              <app-wet-signature-collection-info (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-wet-signature-collection-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.UNINTENDED_LAPSE_MG && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (checkForWetSignature)="checkForWetSignatureTab($event)"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.TEMPORARY_LIFE_MG && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.LIFE_SUITABILITY_MG && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.LTC_REPLACEMENT_MG && eticketQuestions.get(activeTab?.name)">
              <app-ltc-replacement [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (isLongTermCareInforce)="checkForWetSignatureTab($event)"></app-ltc-replacement>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.LTC_SUITABILITY_MG && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.ILLUSTRATION">
              <app-illustration-page (formStatus)="formStatus($event)" (tabChange)="updateIllustrationTab($event)"></app-illustration-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.ILLUSTRATION_COMPLIANCE && eticketQuestions.get(activeTab?.name)">
              <app-illustration-compliance [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-illustration-compliance>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.AGENT_INFO && eticketQuestions.get(activeTab?.name)">
              <app-agent-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-agent-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.AGENT_REPORT_MG && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.APPLICATION_SUMMARY && eticketQuestions.get(activeTab?.name)">
              <app-application-summary [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)"></app-application-summary>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.VALIDATE_AND_LOCK && eticketQuestions.get(activeTab.name)">
              <app-validate-and-lock  [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" (proceedToSigning)="proceedToSign($event)" [disableForm]="checkIfActiveTabLocked()"(deleteTabs)="deleteTabsAfterUnlock($event)" (recipientList)="recipientListUpdated($event)" (caseUnlocked)="caseUnlocked($event)" [userAccessDetails]="userAccessDetails" (lockStatusUpdated)="lockStatusUpdated($event)"></app-validate-and-lock>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.SIGNATURE_METHOD && eticketQuestions.get(activeTab?.name) && signerList?.length > 0">
              <app-signature-method [questions]="eticketQuestions.get(activeTab?.name)"  [signerList]="signerList" (formStatus)="formStatus($event)" (signatureMethodUpdated)="signatureMethodUpdated($event)" [disableForm]="checkIfActiveTabLocked()"></app-signature-method>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.WET_SIGNATURE_INSTRUCTION && eticketQuestions.get(activeTab?.name) && signerList?.length > 0">
              <app-wet-signature [questions]="eticketQuestions.get(activeTab?.name)" [userAccessDetails]="userAccessDetails"></app-wet-signature>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.E_SIGNATURE_INSTRUCTION && eticketQuestions.get(activeTab?.name) && signerList.length > 0">
              <app-esignature-instruction [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signerList]="signerList" [errorOccuredToGetTransactionId]="errorOccuredGettingTransactionIdRemote" [errorOccuredWhileCreateEnvelope]="errorOccuredCreateEnvelopeRemote" [envelopeCreated]="envelopeCreatedRemote" [disableForm]="checkIfActiveTabLocked()"></app-esignature-instruction>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_PI && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_INSURED_OWNER && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_INSURED_B && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_ADDITIONAL_INSURED && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_OWNER && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_COOWNER && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_AGENT && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.ELECTRONIC_SIGNATURE">
              <app-electronic-signature [disableForm]="checkIfActiveTabLocked()" (activePacketUpdated)="activePacketUpdated($event)" (packetCancelled)="packetCancelled($event)"></app-electronic-signature>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_COOWNER2 && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_COOWNER3 && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_PAYOR && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONSENT_PAGE_PRINCIPAL && eticketQuestions.get(activeTab?.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.REVIEW_AND_SUBMIT">
              <app-thankyou-page [thankyouPageType]="'reviewAndSubmit'" (policySubmitted)="policySubmitted($event)" [isPolicySubmitted]="isPolicySubmitted" [userAccessDetails]="userAccessDetails"></app-thankyou-page>
            </ng-container>

            <!-- wholesaler tabs -->

            <ng-container *ngIf="activeTab?.name === TAB.CASE_INFO_WS && eticketQuestions.get(activeTab?.name)">
              <app-case-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" (caseInfoUpdated)="caseInfoUpdated($event)" (caseInfoLoaded)="caseInfoLoaded($event)" [disableForm]="checkIfActiveTabLocked()"></app-case-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.AGENT_INFO_WS && eticketQuestions.get(activeTab?.name)">
              <app-agent-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER"></app-agent-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.COVERAGE_SUMMARY_WS && wholeSalerSummaryData">
              <app-application-summary [wholeSalerSummaryData]="wholeSalerSummaryData" (formStatus)="formStatus($event)" [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER" [disableForm]="checkIfActiveTabLocked()"></app-application-summary>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.POLICY_PROJECTION_WS && eticketQuestions.get(activeTab?.name)">
              <app-policy-projection [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (enableNextButton)="policyIllustrationNext($event)" (wholeSalerCaseUnlocked)="wholeSalerCaseUnlocked($event)"></app-policy-projection>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.PRIMARY_INSURED_WS && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (checkForWetSignature)="checkForWetSignatureTab($event)"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.OWNER_INFO_WS && eticketQuestions.get(activeTab?.name)">
              <app-owner-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER"></app-owner-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.PRIMARY_BENEFICIARY_WS && eticketQuestions.get(activeTab?.name)">
              <app-beneficiary-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" type="Primary" [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER"></app-beneficiary-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.CONTINGENT_BENEFICIARY_WS && eticketQuestions.get(activeTab?.name)">
              <app-beneficiary-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" type="Contingent" [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER"></app-beneficiary-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.LTC_REPLACEMENT_WS && eticketQuestions.get(activeTab?.name)">
              <app-ltc-replacement [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (isLongTermCareInforce)="checkForWetSignatureTab($event)" [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER"></app-ltc-replacement>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.EXISTING_INS_WS && eticketQuestions.get(activeTab?.name)">
              <app-existing-insurance [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (is1035Exchange)="checkForWetSignatureTab($event)" [isWholesaler]="userRole === ROLE_TYPE.WHOLESALER"></app-existing-insurance>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.UNINTENDED_LAPSE_WS && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (checkForWetSignature)="checkForWetSignatureTab($event)"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.POLICY_INFO_WS && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.PREMIUM_INFO_WS && eticketQuestions.get(activeTab?.name)">
              <app-billing-info [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" type="Billing"></app-billing-info>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.AGENT_REPORT_WS && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.DCA_PREMIUM_ALLOCATION_WS && eticketQuestions.get(activeTab?.name)">
              <app-dca-allocation [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-dca-allocation>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.LIFE_SUITABILITY_WS && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.LTC_SUITABILITY_WS && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.TEMPORARY_LIFE_WS && eticketQuestions.get(activeTab?.name)">
              <app-page-question [questions]="eticketQuestions.get(activeTab?.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-page-question>
            </ng-container>
            <ng-container *ngIf="activeTab?.name === TAB.FORM_SELECTION_WS">
              <app-form-selection></app-form-selection>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="(eticketQuestions.get(activeTab.name) || activeTab?.name == TAB.WET_SIGN_COLLECTION_INFO_MG || activeTab?.name == TAB.COVERAGE_SUMMARY_WS) && activeTab.name !== TAB.VALIDATE_AND_LOCK">
        <section class="button-container" *ngIf="activeTab?.name === TAB.CASE_INFO || activeTab?.name === TAB.CASE_INFO_WS; else buttonTray">
          <div class="caseInfo--continue__btncntr">
            <lfg-left-button-tray>
              <button lfgDefaultButton type="submit" class="continue" id="continue" tabindex="0" aria-label="Continue" [disabled]="buttonStatus.nextDisable || checkIfActiveTabLocked()" (click)="continue(); focusOff($event);">CONTINUE</button>
            </lfg-left-button-tray>
          </div>
        </section>
        <ng-template #buttonTray>
          <section class="button-container" *ngIf="activeTab?.name !== TAB.VOICE_CONFIRM_THANK_YOU">
            <lfg-left-button-tray *ngIf="!buttonStatus.backDisable">
              <button class="btn" [ngClass]="{'btn--whitebg-border': !checkIfActiveTabLocked() && !noGreenCardholder}" type="button" id="back" tabindex="0" aria-label="Back" [disabled]="buttonStatus.backDisable || checkIfActiveTabLocked() || noGreenCardholder" (click)="previousTab()">Back</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="activeTab?.name !== TAB.VOICE_TERM_OF_USE && activeTab?.name !== TAB.POLICY_PROJECTION_WS && !TABS_ALWAYS_IN_GOOD_ORDER.includes(activeTab?.name) && activeTab?.name !== TAB.WET_SIGNATURE_INSTRUCTION">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Next" [disabled]="buttonStatus.nextDisable || checkIfActiveTabLocked()" (click)="nextTab(); focusOff($event);">NEXT</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="activeTab?.name == TAB.ELECTRONIC_SIGNATURE">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Next" (click)="nextTab(); focusOff($event);" [disabled]="!checkIfReviewAndSubmitAvailable() || checkIfActiveTabLocked()">NEXT</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="activeTab?.name == TAB.POLICY_PROJECTION_WS">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Next" (click)="nextTabForIllustration(); focusOff($event);" [disabled]="!enableNextButtonIllustration">NEXT</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="activeTab?.name == TAB.VOICE_TERM_OF_USE">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Submit" [disabled]="buttonStatus.nextDisable || checkIfActiveTabLocked()" (click)="nextTab(); focusOff($event);">SUBMIT</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="(activeTab?.name == TAB.SIGNATURE_METHOD || activeTab?.name == TAB.E_SIGNATURE_INSTRUCTION ) && checkIfActiveTabLocked()">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Unlock page" (click)="updateAreYouSureModalPopup(true); focusOff($event);" [disabled]="isPolicySubmitted || signingCompleted || !userAccessDetails.caseRelatedAccess.unlockCase">UNLOCK</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray  *ngIf="!tabsAfterLock.includes(activeTab.name) && activeTab?.name != TAB.POLICY_PROJECTION_WS">
              <button lfgLinkButton type="button" [disabled]="noGreenCardholder || checkIfActiveTabLocked() || !userAccessDetails?.editCase"  id="saveAndExit" tabindex="0" aria-label="Save and exit" (click)="saveAndExit()">Save &amp; exit</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray  *ngIf="!tabsAfterLock.includes(activeTab.name) && activeTab?.name == TAB.POLICY_PROJECTION_WS">
              <button lfgLinkButton type="button" [disabled]="isWholesalerCaseSubmitted()"  id="saveAndExit" tabindex="0" aria-label="Save and exit" (click)="saveAndExit()">Save &amp; exit</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray  *ngIf="tabsAfterLock.includes(activeTab.name)">
              <button lfgLinkButton type="button" [disabled]="noGreenCardholder || !userAccessDetails?.editCase || userAccessDetails.readOnlyPostLock"  id="saveAndExit" tabindex="0" aria-label="Save and exit" (click)="saveAndExit()">Save &amp; exit</button>
            </lfg-left-button-tray>
          </section>
          <section class="button-container" *ngIf="activeTab?.name == TAB.VOICE_CONFIRM_THANK_YOU">
            <lfg-left-button-tray>
              <button lfgDefaultButton type="button" id="close" tabindex="0" aria-label="Close" (click)="close()">Close</button>
            </lfg-left-button-tray>
          </section>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showConfirmationModal">
      <app-confirmation-modal (redirectionConfirmation)="confirmationModalResponse($event)"></app-confirmation-modal>
    </ng-container>
  </section>
</section>
<section class="lcn disclaimer-text">
  <p>{{ lcnNumber }}</p>
</section>
<app-footer *ngIf="userRole === ROLE_TYPE.WHOLESALER && !isHub"></app-footer>
