import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'life-eapp-ui';

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.error$.subscribe((_error) => {
      this.authService.loginWithRedirect();
    });
  }
}
