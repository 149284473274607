import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const CALL_TYPE = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  GETRESOURCE: 'GETRESOURCE',
  POSTWITHHEADER: 'POSTWITHHEADER',
  PATCH: 'PATCH',
};

const BASE_URL_ETICKET = environment.baseUrlETicket;
const BASE_URL_ETICKET_DOCS = environment.baseUrlETicketDocs;
const BASE_URL_ETICKET_INTERFACE = environment.baseUrlETicketInterface;

export const SERVICE_CONFIGURATION = {
  GET_QUESTIONS: {
    URL: BASE_URL_ETICKET + '/getQuestions',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  SAVE_CASE_DETAILS: {
    URL: BASE_URL_ETICKET + '/cases',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  UPDATE_CASE_DETAILS: {
    URL: BASE_URL_ETICKET + '/cases',
    CALL_TYPE: CALL_TYPE.PUT,
    HAS_PATH_PARAMS: false,
  },
  DELETE_CASES: {
    URL: BASE_URL_ETICKET + '/cases',
    CALL_TYPE: CALL_TYPE.DELETE,
    HAS_PATH_PARAMS: false,
  },
  GET_CASES: {
    URL: BASE_URL_ETICKET + '/cases',
    CALL_TYPE: CALL_TYPE.GET,
    HAS_PATH_PARAMS: false,
  },
  GET_FIRM_LIST: {
    URL: BASE_URL_ETICKET_INTERFACE + '/getFirmList',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GET_COMPANY_LIST: {
    URL: BASE_URL_ETICKET_INTERFACE + '/companies',
    CALL_TYPE: CALL_TYPE.GET,
    HAS_PATH_PARAMS: false,
  },
  GET_AGENT_DETAILS: {
    URL: BASE_URL_ETICKET_INTERFACE + '/agentProfile',
    CALL_TYPE: CALL_TYPE.GET,
    HAS_PATH_PARAMS: false,
  },
  GET_AVAILABLE_PRODUCTS: {
    URL:
      BASE_URL_ETICKET_INTERFACE + '/products?stateCode=${stateCode}&productType=${productType}',
    CALL_TYPE: CALL_TYPE.GET,
    HAS_PATH_PARAMS: true,
  },
  GET_PAGE_STATUS: {
    URL: BASE_URL_ETICKET + '/getPageStatus',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GET_FILE_DETAILS: {
    URL: BASE_URL_ETICKET_DOCS + '/getFile',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  REMOVE_FILE: {
    URL: BASE_URL_ETICKET_DOCS + '/removeFile',
    CALL_TYPE: CALL_TYPE.DELETE,
    HAS_PATH_PARAMS: false,
  },
  UPLOAD_FILE: {
    URL: BASE_URL_ETICKET_DOCS + '/uploadFile',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  UPDATE_CASE_STATUS: {
    URL: BASE_URL_ETICKET + '/cases/lock',
    CALL_TYPE: CALL_TYPE.PUT,
    HAS_PATH_PARAMS: false,
  },
  DELETE_PAGE_GROUP: {
    URL: BASE_URL_ETICKET + '/cases/pages',
    CALL_TYPE: CALL_TYPE.DELETE,
    HAS_PATH_PARAMS: false,
  },
  SAVE_PACKAGE_DETAILS: {
    URL: BASE_URL_ETICKET + '/savePackageDetails',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GET_PACKAGE_HISTORY: {
    URL: BASE_URL_ETICKET_INTERFACE + '/getSignersForCase',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GET_SIGNERS_LIST: {
    URL: BASE_URL_ETICKET + '/getSignersInfo',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GET_AGENT_VALIDITY: {
    URL: BASE_URL_ETICKET_INTERFACE + '/validateAgents',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  CREATE_NEW_POLICY: {
    URL: BASE_URL_ETICKET_INTERFACE + '/createPolicy',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GET_CASE_MANAGER_EMAIL_VALIDITY: {
    URL: BASE_URL_ETICKET + '/agent/email',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GENERATE_FORM: {
    URL: BASE_URL_ETICKET_INTERFACE + '/generateMergedForms',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  SAVE_RECIPIENT: {
    URL: BASE_URL_ETICKET_INTERFACE + '/cases/${caseId}/recipients',
    CALL_TYPE: CALL_TYPE.PATCH,
    HAS_PATH_PARAMS: true,
  },
  GET_RECIPIENT: {
    URL: BASE_URL_ETICKET_INTERFACE + '/cases/${caseId}/recipients',
    CALL_TYPE: CALL_TYPE.GET,
    HAS_PATH_PARAMS: true,
  },
  CREATE_ENVELOPE: {
    URL: BASE_URL_ETICKET_INTERFACE + '/createEnvelope',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GET_TRANSACTION_STATUS: {
    URL: BASE_URL_ETICKET_INTERFACE + '/transactions/${transactionId}',
    CALL_TYPE: CALL_TYPE.GET,
    HAS_PATH_PARAMS: true,
  },
  VOID_ENVELOPE: {
    URL: BASE_URL_ETICKET_INTERFACE + '/voidEnvelope',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  RESEND_EMAIL: {
    URL: BASE_URL_ETICKET_INTERFACE + '/resendEmail',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  SAVE_CONSENT: {
    URL: BASE_URL_ETICKET_INTERFACE + '/saveConsent',
    CALL_TYPE: CALL_TYPE.POST,
    HAS_PATH_PARAMS: false,
  },
  GET_ACTIVITY_LOG: {
    URL: BASE_URL_ETICKET_INTERFACE + '/activities/${caseId}',
    CALL_TYPE: CALL_TYPE.GET,
    HAS_PATH_PARAMS: true,
  },
  CLONE_CASE: {
    URL: BASE_URL_ETICKET + '/cases/${caseId}/clone',
    CALL_TYPE: CALL_TYPE.GET,
    HAS_PATH_PARAMS: true,
  }
};

@Injectable()
export class ServiceConfig {
  constructor() {
    // This is intentional
  }

  getServiceConfig(
    serviceName: keyof typeof SERVICE_CONFIGURATION
  ): RequestConfig {
    return SERVICE_CONFIGURATION[serviceName];
  }
}

export class RequestConfig {
  URL: string;
  CALL_TYPE: string;
  HAS_PATH_PARAMS: boolean;
}
