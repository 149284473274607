import { Component, HostListener, OnInit, Input} from '@angular/core';
import { AppAnalyticsService } from 'src/app/shared/services/app-analytics.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { TabInformation } from 'src/config/sideNav.config';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {

  @Input() noGreenCardholder: boolean;
  @Input() userRole: string;
  tabInfoList: TabInformation[];
  activeTab: TabInformation;
  displayCaseStatus = 'Started';
  dateModified = new Date();
  showOverlay: boolean;
  displayHelpData = false;
  closeHelpHint = true;
  constructor(private tabDetailsService: TabDetailsService, private appAnalyticsService: AppAnalyticsService) { }


  ngOnInit(): void {
    this.tabDetailsService.tabInfoSubject.subscribe((tabInfoList) => {
      this.tabInfoList = tabInfoList;
    });

    this.tabDetailsService.activeTabSubject.subscribe((activeTab) => {
      this.activeTab = activeTab;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): any {
    const windowInnerWidth = event.target.innerWidth;
    if (windowInnerWidth > 767) {
      this.showOverlay = false;
    }
  }

  closeOverlay(): any {
    this.showOverlay = false;
  }

  triggerShow(): any {
    /** Dont show if already shown */
    // tslint:disable-next-line:no-conditional-assignment
    if (this.showOverlay === !this.showOverlay) {
      return false;
    }
    this.showOverlay = true;
  }

  changeTab(tab: TabInformation): void {
    this.tabDetailsService.selectTab(tab);
    this.showOverlay = false;
    this.closeSlide(false);
    this.appAnalyticsService.logLinkEvents(tab.name, tab.analyticsPageName);
  }
  
  ariaLabel(tab): any {
    if (this.activeTab?.name === tab?.name) {
      return tab?.name + ' active';
    } else {
      if (tab?.status.visited) {
        return tab?.status.errorExists ? tab.name + ' not completed' : tab?.name + ' completed';
      } else {
        return tab?.name + ' not visited';
      }
    }
  }

  getHelpData(): void {
    this.displayHelpData = true;
    this.closeHelpHint = false;
  }

  closeSlide(closeSlide: boolean): void {
    if (closeSlide) {
      this.closeHelpHint = true;
      setTimeout(() => {
        this.displayHelpData = false;
      }, 500);
    } else {
      this.closeHelpHint = true;
      this.displayHelpData = false;
    }
  }
}
