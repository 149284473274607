import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalSize } from '@ng/lfg-modal-popup';
import { Question, Recipient } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, ESignature, Message, SignatureMethodOptions, SigningMethodConst, wetSignatureForms } from 'src/config/constants';
import { SignerInfoTableConfig } from './SignerInfoTableConfig';
import { ClipboardService } from '@ng/lfg-common-utilities';

@Component({
  selector: 'app-signature-method',
  templateUrl: './signature-method.component.html',
  styleUrls: ['./signature-method.component.scss'],
})
export class SignatureMethodComponent implements OnInit, OnChanges {
  @Input() disableForm: boolean;
  questionsData;
  @Input() signerList: Recipient[];
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() signatureMethodUpdated: EventEmitter<any> = new EventEmitter();

  tableData;

  signatureMethodData: any[] = [];
  signatureMethodForm: FormGroup;
  esignMethodForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  isTrustAvailable = false;
  isSignerFromMT = false;
  isInsuredStateMT = false;

  isTabVisited: boolean;
  voiceSignInfo = [];
  electronicSignInfo = [];
  signMethod: string;
  alldropDownOptions = {};
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  modalConfig: any;
  MESSAGES = ESignature;
  SignMethodConst = SigningMethodConst;
  CommMessage = Message;

  eSignFormCreated = false;
  signatureMethodOptions = SignatureMethodOptions;
  wetFormsUploaded = {};
  displayWetformList = [];


  constructor(
    private fb: FormBuilder,
    private appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private defaultPageLoadService: DefaultPageload,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private userService: UserDetailsService,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit(): void {
    this.destroyESignForm();
    if (this.questionsData) {
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(
        this.signatureMethodForm
      );
      this.updateESignFieldAsTouched(false);
      this.loadSignerInfoToTable();
    }
    this.modalConfig = {
      header: true,
      state: false,
      footer: true,
      size: ModalSize.large,
    };
    this.defaultPageLoadService.disableFormIfLocked(this.esignMethodForm, this.disableForm);
    this.defaultPageLoadService.disableFormIfLocked(this.signatureMethodForm, this.disableForm);
    this.defaultPageLoadService.logPageLoad();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableForm?.previousValue === true && changes.disableForm?.currentValue === false) {
      this.enableForms();
    }
  }

  private buildFormData(): any {
    this.signatureMethodData = [];
    this.wetFormsUploaded = this.questionsData.wetFormsUploaded;
    this.createWetFormList();
    this.filterQuestions();
    this.getSignatureInfoFromQuestions();
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.signatureMethodData
    );
    const form = this.formgeneratorService.createFormControls(
      this.signatureMethodData
    );
    this.signatureMethodForm = this.fb.group(form);
    this.updateSignatureMethod();
    this.disableOtherCheckBox();
    this.checkIsSignerResidesInMT();
    this.eInterviewOptionCheck();
    this.valueChanges();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.signatureMethodForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
        this.signatureMethodUpdated.emit({ signatureMethod: this.signMethod, isChanged: this.disableForm ? 'no' : 'yes' });
      }, 200);
    });
  }

  eInterviewOptionCheck(): void {
    const fieldId = this.utilsService.getFieldByXmlTag(this.signatureMethodData, '/XML/MGeAPP/ApplicationDetails/InterviewType');
    const drpdwnOptions = this.alldropDownOptions[fieldId];
    if (this.userService.getInsuredState() === 'VI') {
      drpdwnOptions.splice(0,1);
    }
    this.alldropDownOptions[fieldId] = drpdwnOptions;
  }

  private getSignatureInfoFromQuestions(): void {
    this.signatureMethodData.forEach((ques) => {
      ques.reflexiveQuestionAL.forEach(reflexQues1 => {
        if (reflexQues1.notes === 'voice_sign_info') {
          this.voiceSignInfo.push(reflexQues1.questionText);
        } else if (reflexQues1.notes === 'electronic_sign_info') {
          this.electronicSignInfo.push(reflexQues1.questionText);
        }
      });
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.signatureMethodForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckSignMethodInfo(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent?.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData.optionChoice === 'Yes{47}Yes') {
        return parent === true;
      } else if (childData.optionChoice === 'No{47}No') {
        return parent === false;
      }
    } else {
      return parent === childData.optionChoice;
    }
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid && ((this.signMethod === 'electronic' && this.esignMethodForm ? this.esignMethodForm?.valid : true) || this.signMethod === 'wet'),
      formHasNoErrors: this.formHasNoErrors,
      form: this.signatureMethodForm,
      data: null,
    };
    for (const data of this.signatureMethodData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: (this.formValid && this.signMethod) || this.disableForm,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'signatureMethodForm',
      isFormChange: this.signatureMethodForm.dirty || this.esignMethodForm?.dirty || !this.isTabVisited,
      isSignatureFormChange: this.signatureMethodForm.dirty || this.esignMethodForm?.dirty,
      signatureFormData: this.esignMethodForm
    };
    this.formStatus.emit(obj);
  }

  private updatedAnswersOnHidden(data): any {
    const value = this.signatureMethodForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.signatureMethodForm.get(key)?.markAsUntouched();
      }
      this.signatureMethodForm.patchValue(updatedval);
    }
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  onCheckboxChange(data): any {
    if (data.xmlTag === '/XML/MGeAPP/ApplicationDetails/POSWetSignInd' && this.signatureMethodForm.get(data.fieldId)?.value) {
      this.clipboardService.set('POSWetSignInd', true);
      this.updateSelectedMethod(data, false);
    }
    if (data.xmlTag === '/XML/MGeAPP/ApplicationDetails/POSWetSignInd' && !this.signatureMethodForm.get(data.fieldId)?.value) {
      this.clipboardService.set('POSWetSignInd', undefined);
      this.updateSelectedMethod(data, false);
    }
    this.updatedAnswersOnHidden(data);
    if (data.xmlTag === '/XML/MGeAPP/ApplicationDetails/POSeSignInd'  && this.signatureMethodForm.get(data.fieldId)?.value) {
      this.clipboardService.set('POSeSignInd', true);
      this.updateSelectedMethod(data, false);
    }
    if (data.xmlTag === '/XML/MGeAPP/ApplicationDetails/POSeSignInd'  && !this.signatureMethodForm.get(data.fieldId)?.value) {
      this.clipboardService.set('POSeSignInd', undefined);
      this.updateSelectedMethod(data, false);
      this.destroyESignForm();
    }
    this.disableOtherCheckBox();
  }

  public updateSignatureMethod(): void {
    this.signatureMethodData.forEach(ques => {
      if (ques.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX && ques.question_answer) {
        this.updateSelectedMethod(ques, false);
      }
    });
  }

  private updateSelectedMethod(data: Question, isCreatedOnChange: boolean): void {
    const isChecked = this.signatureMethodForm.get(data.fieldId)?.value
    if (data.questionText.toLowerCase().indexOf('wet signature') > -1){
      if (isChecked && this.signMethod != 'wet'){
        this.signMethod = 'wet';
        this.destroyESignForm();
      } else if (!isChecked && this.signMethod === 'wet'){
        this.signMethod = '';
      }
    } else if (data.questionText.toLowerCase().indexOf('electronic signature') > -1){
      if (isChecked && this.signMethod != 'electronic'){
        this.signMethod = 'electronic';
        this.createESignForm(isCreatedOnChange);
      } else if (!isChecked && this.signMethod === 'electronic'){
        this.signMethod = '';
        this.destroyESignForm();
      }
    }

    this.signatureMethodUpdated.emit({
      signatureMethod: this.signMethod,
      isChanged: isCreatedOnChange && !this.disableForm ? 'yes' : 'no'
    });
  }

  disableOtherCheckBox(): void {
    this.signatureMethodData.forEach(ques => {
      if ((this.signMethod === 'wet' && ques.questionText.toLowerCase().indexOf('electronic signature') > -1)
        || (this.signMethod === 'electronic' && ques.questionText.toLowerCase().indexOf('wet signature') > -1)) {
        this.signatureMethodForm.get(ques.fieldId).setValue('');
        this.updatedAnswersOnHidden(ques);
        this.updateSelectedMethod(ques, false);
        this.signatureMethodForm.get(ques.fieldId).disable();
      } else if (!this.signMethod && (ques.questionText.toLowerCase().indexOf('electronic signature') > -1
        || ques.questionText.toLowerCase().indexOf('wet signature') > -1)) {
        this.signatureMethodForm.get(ques.fieldId).enable();
      }
    });
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  private filterQuestions(): void {
    if (this.userService.getIsTeleAppSelected() === true || this.userService.getIsChildRiderSelected()) {
      this.updateQuestionRelatedToTeleApp(true);
    } else if (this.userService.getIsTeleAppSelected() === false) {
      this.updateQuestionRelatedToTeleApp(false);
    }
  }

  private updateQuestionRelatedToTeleApp(showQuestion: boolean): void {
    this.signatureMethodData.forEach(ques => {
      if (ques.subText === 'FOR_TELEAPP' || ques.notes === 'FOR_TELEAPP') {
        ques.display = showQuestion;
        if (!showQuestion) {
          ques.question_answer = '';
        }
      }
    });
  }

  openModal(): void {
    this.modalConfig.state = true;
  }

  closeModalPopup(): any {
    this.modalConfig.state = false;
  }

  private createESignForm(isCreatedOnChange: boolean): void {
    const formControl = {};
    for (let i = 0; i < this.signerList.length; i++) {
      formControl[this.signerList[i].roleName + `${i}`] = isCreatedOnChange ? '' : [this.signerList[i].eSignatureMethod];
    }
    this.esignMethodForm = this.fb.group(formControl);
    this.updateESignFieldAsTouched(isCreatedOnChange);
    this.checksignatureFormStatus();
    this.eSignFormCreated = true;
  }

  private checksignatureFormStatus(): void {
    let timeout = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.esignMethodForm?.valueChanges.subscribe((_val) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.checkFormStatus();
        this.signatureMethodUpdated.emit({ signatureMethod: this.signMethod, isChanged: this.disableForm ? 'no' : 'yes' });
      }, 200);
    });
  }

  private updateESignFieldAsTouched(isCreatedOnChange: boolean): void {
   if (this.isTabVisited && !isCreatedOnChange) {
    console.log('this.esignMethodForm', this.esignMethodForm)
      this.esignMethodForm?.markAllAsTouched();
   }
  }

  private destroyESignForm(): void {
    this.esignMethodForm?.reset();
    this.esignMethodForm = undefined;
    this.eSignFormCreated = false;
  }

  private enableForms(): void {
    setTimeout(() => {
      this.signatureMethodForm?.enable();
      this.esignMethodForm?.enable();
      this.disableOtherCheckBox();
      this.checksignatureFormStatus();
      this.valueChanges();
    }, 300);
  }

  private checkIsSignerResidesInMT(): void {
    this.isInsuredStateMT = this.userService.getInsuredState()?.toLowerCase() === 'mt';
    this.isSignerFromMT = this.signerList.filter(signer => {
      return signer.address?.state?.toLowerCase() === 'mt';
    })?.length > 0;
  }

  private loadSignerInfoToTable(): void {
    this.tableData = new SignerInfoTableConfig().tableData;
    this.tableData.data = this.signerList;
  }

  handleSelectionChange(data, ix?): any {
    this.updatedAnswersOnHidden(data);
  }

  onRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  createWetFormList(): void {
    this.displayWetformList = [];
    for (const form in this.wetFormsUploaded) {
      if (!this.wetFormsUploaded[form]) {
        if (form.indexOf('Exchange') > -1) {
          this.displayWetformList.push(wetSignatureForms[0]);
        }
        if (form.indexOf('Protection') > -1) {
          this.displayWetformList.push(wetSignatureForms[1]);
        }
        if (form.indexOf('Notice') > -1) {
          this.displayWetformList.push(wetSignatureForms[2]);
        }
      }
    }
    this.setWetSignatureReflexive();
  }

  setWetSignatureReflexive(): void {
    this.signatureMethodData = this.questionsData.pages[0].questions;
    this.signatureMethodData.forEach(quest => {
      if (quest.xmlTag === '/XML/MGeAPP/ApplicationDetails/POSWetSignInd') {
        quest.hasReflexive = this.displayWetformList?.length > 0 ? 'true' : 'false';
      }
    });
  }

  getDynamicFormList(): any {
    const txt = 'The information provided for this proposed policy will require the following form(s): <br>$list to be wet signed.';
    const formListTxt = '<ul>$form</ul>'
    let forms = '';
    if (this.displayWetformList?.length > 0) {
      for (const form of this.displayWetformList) {
        forms = forms + '<li>' + form + '</li>';
      }
      return txt.replace('$list', formListTxt.replace('$form', forms));
    }
    return '';
  }
}
