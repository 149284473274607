import { Component, OnInit } from '@angular/core';
import { FormSelectionTableConfig } from './form-selection-table-config';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Message } from 'src/config/constants';
import { LfgLoaderService } from '@ng/lfg-loader';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-form-selection',
  templateUrl: './form-selection.component.html',
  styleUrls: ['./form-selection.component.scss']
})
export class FormSelectionComponent implements OnInit {

  tableData;
  allTableData;
  tableDataLoaded = false;
  dropDownList = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
  ];
  dashboardDDForm: FormGroup;
  MessageConst = Message;
  optionalFormCheckbox: boolean = false;
  showLoader = false;
  errorInFormSelection = false;
  formAvailable: boolean;
  displayProgressModal: boolean;
  baseData: string = "";

  progressPercentArray = [1, 1, 5, 5, 5, 10, 10, 10, 15, 15, 15, 20, 20, 20,
    25, 31, 31, 31, 31, 36, 36, 42, 42, 54, 62, 62, 62, 67, 78,
    78, 85, 85, 85, 85, 90, 90, 90, 98];
  pageLoadPercentageArray = [1, 5, 5, 10, 15, 20,
    25, 31, 36, 36, 42, 54, 62, 62, 67, 78, 85, 85, 90, 98];

  constructor(
    private pageService: DefaultPageload,
    private formBuilder: FormBuilder,
    private loaderService: LfgLoaderService,
    private utilsService: UtilsService
  ) {
  }
  ngOnInit(): any {
    this.tableData = new FormSelectionTableConfig().tableData;
    this.allTableData = JSON.parse(JSON.stringify(this.tableData));
    this.getFormSelectionData();
    const formGroupControls: any = {
      viewRecordsDDown: [this.dropDownList[0], []],
    };
    this.dashboardDDForm = this.formBuilder.group(formGroupControls);
  }

  selectOptionalCheckbox() {
    this.optionalFormCheckbox = !this.optionalFormCheckbox;
    this.tableData.data = (this.optionalFormCheckbox) ? this.allTableData.data.filter(item => (item.required?.toLowerCase() !== 'yes')) : this.allTableData.data;
    this.tableData.config.currentPage = 0;
  }

  selectCaseCheckbox(event, row): void {
    row.checkbox = event.target.checked ? true : false;
  }

  sortTableData(event): any {
    this.tableData.data.sort((obj1, obj2) => {
      if (event.order === true) {
        return obj1[event.columnkey] < obj2[event.columnkey] ? -1 : 1;
      } else {
        return obj1[event.columnkey] < obj2[event.columnkey] ? 1 : -1;
      }
    });
  }

  handleSelectionChange(selectedOption): any {
    this.dashboardDDForm?.markAsUntouched();
    this.tableData.config.rowsPerPage = selectedOption.value;
    this.tableData.config.currentPage = 0;
  }

  private getFormSelectionData(): void {
    this.showLoader = true;
    this.errorInFormSelection = false;
    this.pageService.getFormSelectionTableData().subscribe((res) => {
      this.showLoader = false;
      if (res.data) {
        this.tableData.data = res.data;
        this.tableDataLoaded = true;
        this.tableData.config.currentPage = 0;
        this.allTableData.data = res.data;
        this.sortTableData({columnkey: 'orderSeq', order: true});
      } else {
        this.errorInFormSelection = true;
      }
    }, (_err) => {
      this.errorInFormSelection = true;
    }).add(() => {
      this.tableDataLoaded = true;
      this.showLoader = false;
    });
  }

  reviewAndPrint(): void {
    const requiredFormsName = this.allTableData.data.filter(forms => forms.required?.toLowerCase() === 'yes').map(forms => forms.formNumber);
    const optionalSelectedFormsName = this.allTableData.data.filter(forms => forms.checkbox).map(forms => forms.formNumber);
    const selectedForms = [...requiredFormsName, ...optionalSelectedFormsName];
    this.formAvailable = false;
    this.errorInFormSelection = false;
    this.displayProgressModal = true;
    this.pageService.formGeneration(this.pageService.getCaseId(), selectedForms).subscribe((res: any)=>{
      if (res.responseStatus === "SUCCESS"){
        this.formAvailable = true;
        this.baseData = res.data;
      } else {
        this.formAvailable = false;
        this.displayProgressModal = false;
        this.baseData = "";
        this.errorInFormSelection = true;
        console.log(res.error?.errorMsg);
      }
    }, (_err) => {
        this.formAvailable = false;
        this.displayProgressModal = false;
        this.baseData = "";
        this.errorInFormSelection = true;
    })
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  openPdfForm(event: any): void {
    if (event === 'yes' && this.baseData != "") {
      this.displayProgressModal = false;
      this.utilsService.getPdf(this.baseData, "generated_form", false);
    }
  }

  closeProgressModal(_event: any): void {
    this.formAvailable = false;
    this.displayProgressModal = false;
    this.errorInFormSelection = false;
  }
}
