import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';

import { httpInterceptorProviders } from './shared/interceptors/barrel-interceptors.constants';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LfgCommonUtilitiesModule } from '@ng/lfg-common-utilities';
import { AppRoutingModule } from './app-routing.module';
import { LfgLoaderModule, LfgLoaderService } from '@ng/lfg-loader';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    LfgCommonUtilitiesModule.forRoot(),
    LfgLoaderModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        allowedList: [
          {
            uri: env.serviceUrls.apiUrl,
            tokenOptions: {
              audience: env.auth.audience,
              scope: 'profile openid',
            },
          },
        ],
      },
    })
  ],
  providers: [LfgLoaderService, httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
