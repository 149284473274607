import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LfgLoaderService } from '@ng/lfg-loader';
import { AppAnalyticsService } from '../shared/services/app-analytics.service';
import { AgentAndFirmService } from '../shared/services/agent-and-firm.service';
import { take } from 'rxjs/operators';
import { ModalSize } from '@ng/lfg-modal-popup';
import { MessageService } from '../shared/services/message.service';
import { MessageType } from '../shared/models/casePage.model';
import { Message } from 'src/config/constants';
import { AppStateService } from '../shared/services/app-state.service';
import { DelegationService } from '../shared/services/delegation.service';
import { FunctionalityConfig, ROLE_TYPE } from 'src/config/delegationFunctionality.config';
import { AuthService } from '@auth0/auth0-angular';
import { ClipboardService } from '@ng/lfg-common-utilities';
import { environment } from 'src/environments/environment';
import { HelpInfoData } from 'src/config/helpInfo.config';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit {

  systemMessage: any;
  MessageType = MessageType;

  questions: any;
  searchValue: any;
  showLoader = 0;
  modalConfig: any;
  helpInfo: any;
  agentName: string;
  saveExitSucessMsg: string;
  isConfirmSaveAndExit: boolean;
  userAccessDetails: FunctionalityConfig;
  Message = Message;
  userRole: string;
  isHub = true;
  ROLE_TYPE = ROLE_TYPE;

  isRedirectRequired = false;
  redirectUrl = null;
  isTableContentLoaded = false;

  constructor(
    private router: Router,
    private lfgLoaderService: LfgLoaderService,
    private appAnalyticsService: AppAnalyticsService,
    private agentService: AgentAndFirmService,
    public helpInfoData: HelpInfoData,
    private messageService: MessageService,
    private appStateService: AppStateService,
    private delegationService: DelegationService,
    private authService: AuthService,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit(): any {
    this.showPageLoader(true);
    this.subscribeForMessage();
    this.fetchAgentDataAndlogPageAnalytics();
    this.initAppState();
    this.isHub = environment.isHub;
  }

  openHelpHintModal(): void {
    this.modalConfig.state = true;
  }

  closeHelpHintModalPopup(): any {
    this.modalConfig.state = false;
  }

  tableLoaded(isContentLoaded: boolean): void {
    this.showPageLoader(!isContentLoaded)
    this.isTableContentLoaded = isContentLoaded;
    this.checkAndRedirect();
  }

  rdToNewCasepge(): any {
    this.appAnalyticsService.logLinkEvents('new case', 'eticket - dashboard');
    this.router.navigate(['/case']);
  }

  eticketDashSearch($event): any {
    this.isConfirmSaveAndExit = false;
    this.appStateService.setSaveConfirmationMsgFlag(false);
    this.searchValue = $event;
  }

  clearSearch($event): any {
    this.isConfirmSaveAndExit = false;
    this.appStateService.setSaveConfirmationMsgFlag(false);
    if ($event === 'clear') {
      this.searchValue = {};
    } else {
      this.searchValue = null;
    }
  }

  private fetchAgentDataAndlogPageAnalytics(): void {
    this.subscribeForProfileChange();
    this.agentService.fetchAgentProfile();
  }

  private subscribeForProfileChange(): void {
    this.agentService.fetchAgentProfileCompletionSubj.pipe(take(1)).subscribe(res => {
      if (res === 'success') {
        this.agentName = this.agentService.agentProfile?.firstName?.trim() + ' ' + this.agentService.agentProfile?.lastName?.trim();
        this.updateAccessDetails();
        this.appAnalyticsService.logPageView('dashboard');
        this.getHelpData();
        // checkForWholesaler
        this.redirectForWholesaler();
      } else if (res === '500') {
        this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
      } else if (res === '403') {
        this.messageService.setMessage(Message.UNAUTHORIZED_USER_ERROR, MessageType.OUTAGE);
      }
    });
  }

  private redirectForWholesaler(): void {
    if (this.delegationService.getRoleForUser() === ROLE_TYPE.WHOLESALER && !this.clipboardService.get('firstLoadSuccessful') && !this.isHub) {
      this.authService.idTokenClaims$.pipe(take(1)).subscribe((token) => {
        if (token && token.__raw) {
          this.showPageLoader(true);
          this.agentService.getRedirectUrl(token.__raw).subscribe(res => {
            if (res.responseStatus === 'SUCCESS' && res.data) {
              this.isRedirectRequired = true;
              this.redirectUrl = res.data;
              this.checkAndRedirect();
            }
          }).add(() => {
            this.showPageLoader(false);
          });
        }
      });
    }
  }

  private checkAndRedirect(): void {
    if (this.isTableContentLoaded && this.isRedirectRequired) {
      this.isRedirectRequired = false;
      this.clipboardService.set('firstLoadSuccessful', true);
      this.redirectToLandingPage(this.redirectUrl);
    }
  }

  private updateAccessDetails(): void {
    this.userAccessDetails = this.delegationService.getAccessConfigForUser();
    this.userRole = this.delegationService.getRoleForUser();
  }

  private subscribeForMessage(): void {
    this.messageService.getMessageSubscription().subscribe(message => {
      if (message) {
        this.systemMessage = message;
      }
    });
  }

  private initAppState(): void {
    this.appStateService.getSaveConfirmationMsgFlag().subscribe((saveAndExitInd) => {
      this.isConfirmSaveAndExit = saveAndExitInd;
    });
  }

  private redirectToLandingPage(redirectUrl: any) {
    if (redirectUrl?.indexOf('?') > -1) {
      let caseId, refCaseId;
      const url = redirectUrl.split('?')[0];
      const queryParams = (redirectUrl.split('?')[1]).split('&');
      queryParams.forEach((qp, index) => {
        index == 0 ? caseId = qp.split('=')[1] : refCaseId = qp.split('=')[1];
      });
      this.router.navigate(['/' + url], { queryParams: { caseId, refCaseId } });
    } else {
      this.router.navigate(['/' + redirectUrl]);
    }
  }

  private getHelpData(): void {
    this.modalConfig = {
      header: true,
      state: false,
      footer: true,
      size: ModalSize.small,
    };

    this.helpInfo = this.helpInfoData.tabSpecificHelpInfo.filter(
      info => info.tabName === 'Welcome'
    );
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      this.showLoader++;
      if (this.showLoader === 1) {
        this.lfgLoaderService.show();
      }
    } else {
      this.showLoader--;
      if (this.showLoader === 0) {
        this.lfgLoaderService.hide();
      }
    }
  }
}
