import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LfgLoaderService } from '@ng/lfg-loader';

import { GridInstance, PageJsonObject, Question, } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, ExistingInsuranceConstant, ExistingInsuranceMessage } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';


@Component({
  selector: 'app-existing-insurance',
  templateUrl: './existing-insurance.component.html',
  styleUrls: ['./existing-insurance.component.scss'],
})
export class ExistingInsuranceComponent implements OnInit {
  @Input() disableForm: boolean;
  @Input() isWholesaler: boolean;
  questionsData: PageJsonObject;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() is1035Exchange: EventEmitter<string> = new EventEmitter();

  // variable to save ques answer for checking other conditions
  inforceInsQues: string;
  financeInsQues: string;
  replacementInsQues1: string;
  replacementInsQues2: string;
  existingInsuranceInfoData: Question[] = [];
  existingInsuranceInfoForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  gridHasNoErrors = false;
  formHasNoErrors = true;
  isGridFormValid = true;
  isGridFormChange = false;
  placeholders = {};
  alldropDownOptions = {};
  setTimeFocus = null;
  gridQuestionsObj;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  existingInsError = ExistingInsuranceMessage.INSURANCE_ERROR_MESSAGE;
  EI_CONSTANT = ExistingInsuranceConstant;
  hasExistingInsurance = false;
  hasError = false;
  otherInsPolicyQuestion = {};
  maxNoOfInsurance: number;
  showLoader = true;
  tabStatusOnInit: TabStatus;


  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private loaderService: LfgLoaderService,
    private userService: UserDetailsService
  ) { }

  ngOnInit(): void {
    this.addLoader(true);
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
      this.maxNoOfInsurance = +(this.gridInstance[0]?.maxGridIndex);
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.existingInsuranceInfoForm);
      this.defaultPageLoadService.disableFormIfLocked(this.existingInsuranceInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
    this.addLoader(false);
  }

  private buildFormData(): any {
    this.existingInsuranceInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.existingInsuranceInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.existingInsuranceInfoData
    );
    this.existingInsuranceInfoForm = this.fb.group(form);
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.updateBusinessRule();
      this.checkFormStatus();
    }, 200);
    this.existingInsuranceInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.updateBusinessRule();
        this.checkFormStatus();
      }, 200);
    });
  }

  private updateBusinessRule(): void {
    this.updateAnswerValues();
    this.checkForExistingInsurance();
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.existingInsuranceInfoForm,
      data: null,
    };
    for (const data of this.existingInsuranceInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  onRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  handleSelectionChange(data, ix?): any {
    const formotivId = this.displayIdCreation(data);
    this.commonService.triggerChangeCustom(
      'dropdown',
      ix,
      formotivId,
      'insuredInfoForm',
      data
    );
    this.updatedAnswersOnHidden(data);
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.existingInsuranceInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.existingInsuranceInfoForm.get(key)?.markAsUntouched();
      }
      this.existingInsuranceInfoForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.existingInsuranceInfoForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckExistingIns(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data): any {
    return this.utilsService.getInlineErrorMessage(data);
  }

  gridformStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.isGridFormChange = event.isGridFormChange;
    this.emitStatus();
  }

  emitStatus(): void {
    this.isGridFormValid = this.hasExistingInsurance ? this.isGridFormValid : true;
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    const existingInsuranceInfoObj = {
      formValid: this.formValid && this.isGridFormValid && !this.hasError,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'existingInsuranceInfoForm',
      gridForm: true,
      isFormChange: this.existingInsuranceInfoForm.dirty || this.isGridFormChange || !this.isTabVisited || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.existingInsuranceInfoForm),
    };
    this.emitForWetSignatureCheck();
    this.formStatus.emit(existingInsuranceInfoObj);
  }

  private checkForExistingInsurance(): void {
    this.hasExistingInsurance = this.replacementInsQues1?.toLocaleLowerCase().indexOf('yes') > -1
                                || this.replacementInsQues2?.toLocaleLowerCase().indexOf('yes') > -1
    if (!this.hasExistingInsurance) {
      this.gridAnswers = [];
    }
  }

  private emitForWetSignatureCheck(): void {
    let is1035 = false;
    this.gridAnswers.forEach(ans => {
      is1035 = is1035 || ans[this.getNameByXmlTag(ExistingInsuranceConstant.exchange1035Ind)]?.toLowerCase()?.indexOf('yes') > -1;
    });
    this.userService.setIs1035(is1035);
    this.is1035Exchange.emit(is1035 ? 'yes' : 'no');
  }

  private getNameByXmlTag(xmlTag: string): string {
    return this.utilsService.getNameByXmlTag(this.gridInstance[0].gridQuestions, xmlTag);
  }

  private getValueById(id: string): string {
    return this.existingInsuranceInfoForm.get(this.defaultPageLoadService.getFieldIdByTag(id))?.value?.toLowerCase();
  }

  private updateDisplayForQues(shouldDisplay: boolean, xmlTag?: string, fieldId?: string): void {
    this.existingInsuranceInfoData.forEach(ques => {
      if (ques.xmlTag === xmlTag || ques.fieldId === fieldId) {
        ques.required = shouldDisplay ? 'true' : 'false';
        ques.display = shouldDisplay;
      }
    });
  }

  private updateAnswerValues(): void {
    this.replacementInsQues1 = this.getValueById(ExistingInsuranceConstant.replacementkey1);
    this.replacementInsQues2 = this.getValueById(ExistingInsuranceConstant.replacementkey2);
  }

  addLoader(isAddLoader: boolean): void {
    if (isAddLoader) {
      this.showLoader = true;
      this.loaderService.show();
    } else {
      setTimeout(()=>{
        this.showLoader = false;
        this.loaderService.hide();
      }, 0);
    }
  }
}
