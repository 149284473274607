export class SummaryTableConfig {
    tableData = {
      config: {
        tableId: 'eticket-dbResult-table',
        tableClass: 'eticket-dbResult-table',
        sorting: false,
        pagination: true,
        rowsPerPage: 10,
        currentPage: 0,
        tableFoot: false,
        defaultSortOrder: 'desc',
        clickSortOrder: 'asc',
        handleSubDetailRow: false,
        noRecordsText: 'No records found'
      },
      column: [
        {
          displayText: 'NAME',
          columnKey: 'agentName',
          columnFlag: 'name',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        },
        {
          displayText: 'PHONE',
          columnKey: 'agentPhone',
          columnFlag: 'phone',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        },
        {
          displayText: 'EMAIL',
          columnKey: 'agentEmail',
          columnFlag: 'email',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        },
        {
          displayText: 'PERCENT SPLIT %',
          columnKey: 'agentPercent',
          columnFlag: 'percent',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        },
      ],
      data: [ ]
    };
  }
