<section class="summary-info-page page-content-container">
  <div class="questions--container">
    <h1>Coverage Summary</h1>
  </div>
  <ng-container *ngIf="!isWholesaler">

    <!-- Proposed insured information -->
    <div class="questions-header">
      <h2>{{ 'PROPOSED INSURED INFORMATION' | uppercase }}</h2>
    </div>

    <div class="info-section">
      <div class="column-section">
        <ng-container *ngFor="let data of dataMap.get('col1') ">
          <!-- show name -->
          <div *ngIf="data.subText == 'Name'">
            <strong>{{data?.question_answer | titlecase}}</strong>
          </div>

          <!-- show address -->
          <div *ngIf="data.subText == 'Address1'"> {{ data?.question_answer !== 'N/A' ? (data?.question_answer |
            titlecase) : 'N/A'}} </div>
          <div *ngIf="data.subText == 'Address2'"> {{data?.question_answer | titlecase}} </div>
          <div *ngIf="data.subText == 'Address3'"> {{data?.question_answer}} </div>

          <!-- show other informations -->
          <div *ngIf="data.subText == 'Info'">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer}}</strong>
          </div>

        </ng-container>
      </div>

      <div class="column-section column-section-next">
        <ng-container *ngFor="let data of dataMap.get('col2')">
          <!-- show age details -->
          <div *ngIf="data.subText == 'Age'">
            <span [innerHTML]="data.question_answer"></span>
          </div>

          <div *ngIf="data.subText == 'Info'">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer}}</strong>
          </div>

        </ng-container>
      </div>
    </div>

    <div class="section-divider"></div>

    <!-- Contact information -->
    <div class="questions-header">
      <h2>{{ 'CONTACT INFORMATION' | uppercase }}</h2>
    </div>

    <div class="info-section">
      <div class="column-section">
        <ng-container *ngFor="let data of dataMap.get('col3')">
          <div *ngIf="data.subText == 'Info' && data.questionText.indexOf('Phone') === -1">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer}}</strong>
          </div>
          <div *ngIf="data.subText == 'Info' && data.questionText.indexOf('Phone') > -1">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer | phoneNumber }}</strong>
          </div>
        </ng-container>
      </div>

      <div class="column-section column-section-next">
        <ng-container *ngFor="let data of dataMap.get('col4')">
          <div *ngIf="data.subText == 'Info'">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer}}</strong>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="section-divider"></div>

    <!-- Contract information -->
    <div class="questions-header">
      <h2>{{ 'CONTRACT INFORMATION' | uppercase }}</h2>
    </div>

    <div class="info-section">
      <div class="column-section">
        <ng-container *ngFor="let data of dataMap.get('col5')">
          <div *ngIf="data.subText == 'Info' && data.questionText?.indexOf('Amount of Insurance') === -1">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer}}</strong>
          </div>
          <div *ngIf="data.subText == 'Info' && data.questionText?.indexOf('Amount of Insurance') > -1">
            {{data.questionText}}&nbsp;<strong>{{ data.question_answer != 'N/A' ? (data.question_answer | currency) :
              data.question_answer}}</strong>
          </div>
        </ng-container>
      </div>

      <div class="column-section column-section-next">
        <ng-container *ngFor="let data of dataMap.get('col6')">
          <div *ngIf="data.subText == 'Info' && data.questionText?.indexOf('Modal Amount') === -1">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer}}</strong>
          </div>
          <div *ngIf="data.subText == 'Info' && data.questionText?.indexOf('Modal Amount') > -1">
            {{data.questionText}}&nbsp;<strong>{{ data.question_answer != 'N/A' ? (data.question_answer | currency) :
              data.question_answer}}</strong>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="section-divider"></div>

    <!-- Agent information -->
    <div class="questions-header">
      <h2>{{ 'AGENT INFORMATION' | uppercase }}</h2>
    </div>

    <lfg-primary-table [tableData]="tableData" id="case-info-dbtable" *ngIf="tableDataLoaded">
      <ng-template #tableCelContentTemplate let-column="column" let-row="row">
        <div tabindex="0" *ngIf="column.columnFlag === 'name'">
          {{ row.agentName }}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'phone'">
          {{ row.agentPhone | phoneNumber}}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'email'">
          {{ row.agentEmail }}
        </div>
      </ng-template>
    </lfg-primary-table>
  </ng-container>

  <ng-container *ngIf="isWholesaler">
    <div class="case-info-section">
      <div class="accordian-ws-section" (click)="toggleAccordionStatus('caseInfo')">
        <span class="accrd-header">
          Case Information
        </span>
        <span class="icon-container">
          <i class="fa arrow-button"
            [ngClass]="{'fa-chevron-down' : openAccordion !== 'caseInfo' , 'fa-chevron-up' : openAccordion === 'caseInfo'}"
            aria-label="Case information" tabindex="0"></i>
        </span>
      </div>
      <div *ngIf="openAccordion === 'caseInfo'" class="accordion-content">
        <div class="form-control-parent">
          <div class="column">
            <ng-container *ngFor="let caseData of summaryDataCaseInfoWS.questions; let i = index;">
              <div *ngIf="i <= summaryDataCaseInfoWS.questions.length/2">
                <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: caseData}"></ng-container>
              </div>
            </ng-container>
          </div>
          <div class="column">
            <ng-container *ngFor="let caseData of summaryDataCaseInfoWS.questions; let i = index;">
              <div *ngIf="i > summaryDataCaseInfoWS.questions.length/2">
                <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: caseData}"></ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="agent-info-section">
      <div class="accordian-ws-section" (click)="toggleAccordionStatus('agentInfo')">
        <span class="accrd-header">
          Agent Information
        </span>
        <span class="icon-container">
          <i class="fa arrow-button"
            [ngClass]="{'fa-chevron-down' : openAccordion !== 'agentInfo' , 'fa-chevron-up' : openAccordion === 'agentInfo'}"
            aria-label="Agent information" tabindex="0"></i>
        </span>
      </div>
      <div *ngIf="openAccordion === 'agentInfo'" class="accordion-content">
        <div class="form-control-parent">
          <div class="column">
            <ng-container *ngFor="let agentInfo of summaryDataAgentInfoWS.questions; let i = index;">
              <div *ngIf="i <= summaryDataAgentInfoWS.questions.length/2">
                <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: agentInfo}"></ng-container>
              </div>
            </ng-container>
          </div>
          <div class="column">
            <ng-container *ngFor="let agentInfo of summaryDataAgentInfoWS.questions; let i = index;">
              <div *ngIf="i > summaryDataAgentInfoWS.questions.length/2">
                <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: agentInfo}"></ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="summaryAgentInfoGridDataWS.length !== 0">
          <div class="agent-table-ws">
            <h4>
              Additional Agent Information
            </h4>
          <lfg-primary-table [tableData]="tableData" id="case-info-dbtable" *ngIf="tableDataLoaded">
            <ng-template #tableCelContentTemplate let-column="column" let-row="row">
              <div tabindex="0" *ngIf="column.columnFlag === 'name'">
                {{ row.agentName }}
              </div>
              <div tabindex="0" *ngIf="column.columnFlag === 'phone'">
                {{ row.agentPhone | phoneNumber}}
              </div>
              <div tabindex="0" *ngIf="column.columnFlag === 'email'">
                {{ row.agentEmail }}
              </div>
              <div tabindex="0" *ngIf="column.columnFlag === 'percent'">
                {{ row.agentPercent }}%
              </div>
            </ng-template>
          </lfg-primary-table>
        </div>
        </ng-container>
      </div>
    </div>

    <div class="policy-projection-section">
      <div class="accordian-ws-section" (click)="toggleAccordionStatus('policyInfo')">
        <span class="accrd-header">
          Policy Projection
        </span>
        <span class="icon-container">
          <i class="fa arrow-button"
            [ngClass]="{'fa-chevron-down' : openAccordion !== 'policyInfo' , 'fa-chevron-up' : openAccordion === 'policyInfo'}"
            aria-label="Policy Projection" tabindex="0"></i>
        </span>
      </div>
      <div *ngIf="openAccordion === 'policyInfo'" class="accordion-content-policy-projection">
        <div class="form-control-full-width">
          <ng-container *ngFor="let poicyProjectionData of summaryDataPolicyProjectionInfoWS.questions; let i = index;">
            <div *ngIf="poicyProjectionData.controlTypeDesc == appConfig.fieldTypes.HDR && poicyProjectionData.subText == 'header'" class="section-divider-accord-policy-projection"><strong>{{poicyProjectionData.questionText | uppercase}}</strong></div>
            <div *ngIf="poicyProjectionData.controlTypeDesc !== appConfig.fieldTypes.HDR">
              <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: poicyProjectionData}"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="existing-insurance-section">
      <div class="accordian-ws-section" (click)="toggleAccordionStatus('existingInfo')">
        <span class="accrd-header">
          Existing Insurance
        </span>
        <span class="icon-container">
          <i class="fa arrow-button"
            [ngClass]="{'fa-chevron-down' : openAccordion !== 'existingInfo' , 'fa-chevron-up' : openAccordion === 'existingInfo'}"
            aria-label="Policy Projection" tabindex="0"></i>
        </span>
      </div>
      <div *ngIf="openAccordion === 'existingInfo'" class="accordion-content">
        <div class="form-control-full-width">
          <ng-container *ngFor="let existingIns of summaryDataExistingInsuranceWS.questions; let i = index;">
            <div *ngIf="existingIns.controlTypeDesc !== appConfig.fieldTypes.HDR">
              <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: existingIns}"></ng-container>
            </div>
            <div *ngIf="existingIns.controlTypeDesc !== appConfig.fieldTypes.HDR" class="section-divider-accord"></div>
          </ng-container>
        </div>
        <ng-container *ngIf="summaryExistingInsuranceGridDataWS">
          <div class="form-control-parent form-grid-section" *ngFor="let gridData of summaryExistingInsuranceGridDataWS">
            <div class="column">
              <ng-container *ngFor="let ques of gridData; let i = index;">
                <div *ngIf="i <= gridData.length/2">
                  <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: ques}"></ng-container>
                </div>
              </ng-container>
            </div>
            <div class="column">
              <ng-container *ngFor="let ques of gridData; let i = index;">
                <div *ngIf="i > gridData.length/2">
                  <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: ques}"></ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="owner-info-section">
      <div class="accordian-ws-section" (click)="toggleAccordionStatus('ownerInfo')">
        <span class="accrd-header">
          Owner Information
        </span>
        <span class="icon-container">
          <i class="fa arrow-button" [ngClass]="{'fa-chevron-down' : openAccordion !== 'ownerInfo' , 'fa-chevron-up' : openAccordion === 'ownerInfo'}" aria-label="Owner Information" tabindex="0"></i>
        </span>
      </div>
      <div *ngIf="openAccordion === 'ownerInfo'" class="accordion-content">
        <div class="form-control-full-width">
          <ng-container *ngFor="let ownerQues of summaryDataOwnerInfoWS.questions; let i = index;">
            <div *ngIf="ownerQues.controlTypeDesc !== appConfig.fieldTypes.HDR">
              <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: ownerQues}"></ng-container>
            </div>
            <div *ngIf="ownerQues.controlTypeDesc !== appConfig.fieldTypes.HDR" class="section-divider-accord"></div>
          </ng-container>
        </div>
        <ng-container *ngIf="summaryOwnerGridDataWS">
          <div class="form-control-parent form-grid-section" *ngFor="let gridData of summaryOwnerGridDataWS">
            <div class="column">
              <ng-container *ngFor="let ques of gridData; let i = index;">
                <div *ngIf="i <= gridData.length/2">
                  <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: ques}"></ng-container>
                </div>
              </ng-container>
            </div>
            <div class="column">
              <ng-container *ngFor="let ques of gridData; let i = index;">
                <div *ngIf="i > gridData.length/2">
                  <ng-container *ngTemplateOutlet="reusableBlock; context: {$implicit: ques}"></ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container> 
      </div>
    </div>
  </ng-container>

  <ng-template #reusableBlock let-data>
    <span>{{data.questionText?.includes(':') ? data.questionText.replace(':','') : data.questionText }}: &nbsp;</span>
    <span *ngIf="data.controlTypeDesc === appConfig.fieldTypes.EMAIL" class="word-break-all"><strong>{{data.question_answer}}</strong></span>
    <span *ngIf="data.controlTypeDesc === appConfig.fieldTypes.DOB"><strong>{{data.question_answer ? (data.question_answer | date: 'MM/dd/yyyy') : ''}}</strong></span>
    <span *ngIf="data.notes === 'ITES_FORMAT_AS_CURRENCY' || data.notes === 'ITES_CURRENCY_USD'"><strong>{{data.question_answer ? (data.question_answer | currency) : '' }}</strong></span>
    <span *ngIf="(data.controlTypeDesc === appConfig.fieldTypes.CHECKBOX || data.controlTypeDesc === appConfig.fieldTypes.SELECT || data.controlTypeDesc === appConfig.fieldTypes.RADIO)"><strong>{{(data.question_answer?.includes('}') ? data.question_answer.split('}')[1] : data.question_answer) | capitalizeFirstLetter }}</strong></span>
    <span *ngIf="data.controlTypeDesc === appConfig.fieldTypes.PHONE"><strong>{{data.question_answer ? (data.question_answer | phoneNumber) : '' }}</strong></span>
    <span *ngIf="(data.controlTypeDesc === appConfig.fieldTypes.TEXT || data.controlTypeDesc === appConfig.fieldTypes.TEXTAREA) && data.notes !== 'ITES_FORMAT_AS_CURRENCY' && data.notes !== 'ITES_CURRENCY_USD'" class="word-break-all"><strong>{{data.question_answer }}</strong></span>
  </ng-template>
</section>