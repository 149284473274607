import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MessageService {

  messageSubject = new BehaviorSubject(null);
  constructor() {
    // write code here
  }


  setMessage(message: string, type: string): void {
    const newMessage = { message, type };
    this.messageSubject.next(newMessage);
  }

  getMessageSubscription(): Observable<any> {
    return this.messageSubject.asObservable();
  }

}
