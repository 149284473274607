<footer class="global-footer-container footer-section-container">
  <div class="footer-container">
    <section>
      <section class="footer-social">
        <ul role="navigation" class="footer-social-menu">
          <li class="footer-social-menu__item"><a aria-label="Facebook" class="footer-social-menu__item-link"
              target="_blank" href="https://www.facebook.com/lincolnfinancialgroup" data-event-page-id="1533244617711"
              data-event-action="link" data-event-name="socialshare" data-event-type="flink"
              data-event-assetid="1523284430262">
              <i class="fab fa-facebook-square footer-social-menu__item-icon"></i></a>
          </li>
          <li class="footer-social-menu__item"><a aria-label="X" class="footer-social-menu__item-link" target="_blank"
              href="https://x.com/LincolnFncl" data-event-page-id="1533244617711" data-event-action="link"
              data-event-name="socialshare" data-event-type="flink" data-event-assetid="1523284445708"><i
                class="fab fa-twitter-square footer-social-menu__item-icon"></i></a>
          </li>
          <li class="footer-social-menu__item"><a aria-label="Youtube" class="footer-social-menu__item-link"
              target="_blank" href="http://www.youtube.com/lincolnfinancialgrp" data-event-page-id="1533244617711"
              data-event-action="link" data-event-name="socialshare" data-event-type="flink"
              data-event-assetid="1523284445752"><i class="fab fa-youtube-square footer-social-menu__item-icon"></i></a>
          </li>
          <li class="footer-social-menu__item"><a aria-label="Linkedin" class="footer-social-menu__item-link"
              target="_blank"
              href="https://www.linkedin.com/company/lincoln-financial-group?trk=tyah&amp;trkInfo=tas%3Alincoln+financial%2Cidx%3A3-1-3"
              data-event-page-id="1533244617711" data-event-action="link" data-event-name="socialshare"
              data-event-type="flink" data-event-assetid="1523284445798"><i
                class="fab fa-linkedin footer-social-menu__item-icon"></i></a>
          </li>
          <li class="footer-social-menu__item"><a aria-label="Instagram" class="footer-social-menu__item-link"
              target="_blank" href="https://www.instagram.com/lincolnfingroup" data-event-page-id="1533244617711"
              data-event-action="link" data-event-name="socialshare" data-event-type="flink"
              data-event-assetid="1523284445871"><i class="fab fa-instagram footer-social-menu__item-icon"></i></a>
          </li>
        </ul>
      </section>
      <div class="footer-section">
        <div class="footer-logo-image">
          <img src="assets/images/lfg-footer-logo.svg" alt="Lincoln Financial Group Footer Logo" class="footer-img" />
        </div>
        <div class="footer-description">
          <section class="footer-links">
            <ul>
              <li><a href='https://www.lfg.com/public/aboutus/companyoverview' target="_blank"
                  rel="noopener noreferrer">{{Const.FOOTER_LI_1}}</a></li>
              <li><a href='https://www.lfg.com/privacy' target="_blank"
                  rel="noopener noreferrer">{{Const.FOOTER_LI_2}}</a></li>
              <li><a href='https://www.lfg.com/legal' target="_blank"
                  rel="noopener noreferrer">{{Const.FOOTER_LI_3}}</a></li>
              <li><a href='https://www.lincolnfinancial.com/public/general/glossary' target="_blank"
                  rel="noopener noreferrer">{{Const.FOOTER_LI_4}}</a></li>
              <li><a href='https://www.lincolnfinancial.com/public/general/support/reportfraud/form' target="_blank"
                  rel="noopener noreferrer">{{Const.FOOTER_LI_5}}</a></li>
            </ul>
          </section>
          <section class="footer-disclaimer">
            <p>{{Const.FOOTER_INFO_1}}</p>
            <p>{{Const.FOOTER_INFO_2}}</p>
          </section>
        </div>
      </div>
      <div class="footer-lcn-number">
        <p>LCN 2821771-111219</p>
      </div>
    </section>
  </div>
</footer>