<div *ngIf="showLoader > 0">
  <lfg-loader></lfg-loader>
</div>

<div class="grid-structure-page">
  <span aria-live="assertive">
    <div *ngIf="shareEqually && beneShareEquallyRuleErr && !validPercentage && beneGridCount === 3" class="padbot20">
      <lfg-alert-message type="error" message="{{ BENE_ERR.beneShareEquallyRuleErrForThreeGrid }}"></lfg-alert-message>
    </div>
  </span>

  <span aria-live="assertive">
    <div *ngIf="shareEqually && beneShareEquallyRuleErr && !validPercentage && beneGridCount === 6" class="padbot20">
      <lfg-alert-message type="error" message="{{ BENE_ERR.beneShareEquallyRuleErrForSixGrid }}"></lfg-alert-message>
    </div>
  </span>

  <span aria-live="assertive">
    <div *ngIf="shareEqually && beneShareEquallyRuleErr && !validPercentage && beneGridCount === 7" class="padbot20">
      <lfg-alert-message type="error" message="{{ BENE_ERR.beneShareEquallyRuleErrForSevenGrid }}"></lfg-alert-message>
    </div>
  </span>

  <span aria-live="assertive">
    <div *ngIf="shareEqually && beneShareEquallyRuleErr && beneGridCount === 8" class="padbot20">
      <lfg-alert-message type="error" message="{{ BENE_ERR.beneShareEquallyRuleErrForEightGrid }}"></lfg-alert-message>
    </div>
  </span>

  <span aria-live="assertive">
    <div *ngIf="shareEqually && beneShareEquallyRuleErr && !validPercentage && beneGridCount === 9" class="padbot20">
      <lfg-alert-message type="error" message="{{ BENE_ERR.beneShareEquallyRuleErrForNineGrid }}"></lfg-alert-message>
    </div>
  </span>
  <form [formGroup]="mainFormGroup" id="mainFormGroup" method="post" *ngIf="mainFormGroup">
    <div class="percentage-container" *ngIf="name === 'Primary' || name === 'Contingent'" tabindex="0">
      <div class="total-percentage">
        TOTAL SHARED PERCENTAGE
        <span aria-live="off" [ngClass]="{'percent-error': (!validPercentage)}">{{ totalPercentage + "%" }}</span>
      </div>
      <div class="percent-error" *ngIf="!validPercentage">Must equal 100%</div>
    </div>
    <lfg-accordion (stateChange)="accordionStatus($event)" [autoScroll]="true">
      <lfg-panel [title]="accordionHeader" name="accordion" *ngFor="let grid of gridSequences; let i = index;" [formArrayName]="grid.gridId" class="{{grid.gridId + '-' + i}}"
      [opened]="openAccordian[i]" >

        <span class="accordion__sub-title-hook" *ngIf="type == 'POLICY' || type == 'LTC'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [grid]="grid" [gridSequences]="gridSequences" [index]="i" [type]="'POLICY_DESKTOP'" (removeGrid)="removeGrid($event)" [name]="getNameForAccordion(grid)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [grid]="grid" [gridSequences]="gridSequences" [index]="i" [type]="'POLICY_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [name]="getNameForAccordion(grid)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [grid]="grid" [gridSequences]="gridSequences" [index]="i" [type]="'POLICY_MOBILE_INFO'" [name]="getNameForAccordion(grid)" [disableForm]="disableForm"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'AGENT'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'AGENT_DESKTOP'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm" [validPercentage]="validPercentage" [percentage]="gridPercentageStrings[grid.gridUniqueId]" [isWholesaler]="isWholesaler"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'AGENT_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm" [validPercentage]="validPercentage" [percentage]="gridPercentageStrings[grid.gridUniqueId]" [isWholesaler]="isWholesaler"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'AGENT_MOBILE_INFO'" [disableForm]="disableForm" [validPercentage]="validPercentage" [percentage]="gridPercentageStrings[grid.gridUniqueId]" [isWholesaler]="isWholesaler"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'INSURANCE'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'INSURANCE_DESKTOP'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'INSURANCE_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'INSURANCE_MOBILE_INFO'" [disableForm]="disableForm"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'OWNER'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'OWNER_DESKTOP'" (removeGrid)="removeGrid($event)" [showOwner]="showOwner" [disableForm]="disableForm" [name]="getNameForAccordion(grid)"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'OWNER_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [showOwner]="showOwner" [disableForm]="disableForm" [name]="getNameForAccordion(grid)"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'OWNER_MOBILE_INFO'" [showOwner]="showOwner" [disableForm]="disableForm" [name]="getNameForAccordion(grid)"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'BENE'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'BENE_DESKTOP'" (removeGrid)="removeGrid($event)" [disableDelete]="disableDeleteIcon(i)" [disableForm]="disableForm" [name]="getNameForAccordion(grid)" [percentage]="gridPercentageStrings[grid.gridUniqueId]" [validPercentage]="validPercentage"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'BENE_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [disableDelete]="disableDeleteIcon(i)" [disableForm]="disableForm" [name]="getNameForAccordion(grid)" [percentage]="gridPercentageStrings[grid.gridUniqueId]" [validPercentage]="validPercentage"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid] = "gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'BENE_MOBILE_INFO'" [disableDelete]="disableDeleteIcon(i)" [disableForm]="disableForm" [name]="getNameForAccordion(grid)" [percentage]="gridPercentageStrings[grid.gridUniqueId]" [validPercentage]="validPercentage"></app-grid-header>
          </div>
        </span>

        <div [formGroupName]="grid.gridIndex" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">

          <ng-container class="form-control-parent" *ngFor="let data of gridQuestionsObj[grid.gridId].gridQuestions  | dataFilter: (companySelected[grid.gridIndex]): type: null: gridForm; let ind = index;">

            <!-- Questions headers -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.questionText != 'Search'">
              <h2 class="grid-ques" [ngClass]="{'remove-margin-bottom' : data.subText }">{{ data.questionText }}</h2>
              <p class="ques-content" *ngIf="data.subText">{{ data.subText }}</p>
            </div>

            <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
            </div>

            <!-- element to switch controls to new line -->
            <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((data.questionText).toLowerCase()) !== -1">
            </div>

            <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
            <div class="questionText" *ngIf="addCorrectPlaceHolder(data, 'display')">
              <span [innerHTML]="addCorrectPlaceHolder(data, 'question')"></span>
            </div>

            <!-- Form element for text  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes !== 'ITES_FORMAT_AS_ZIPCODE' && data.notes !== 'ITES_FORMAT_AS_CURRENCY' && data.notes !== 'ITES_FORMAT_AS_PERCENTAGE' && data.notes !== 'INTERNAL_OR_EXTERNAL'"
            [ngClass]="{'form-control-medium': data.questionText === 'Address/Street'}">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur  appNoSpecialSymbols [attr.customAttr]="data.answerTextType === 'Numeric only' ? appConfig.customAttr['Numeric'] : 'text'" [maxlength]="data.maxLength >= 999 ? 30 : data.maxLength" [required]="data.required == 'true'"
                (input)="(type === 'AGENT' && data.xmlTag !== AGENTCONST.agentPostalCodeXML) ? checkIfSSNIsValid(gridForm, grid.gridUniqueId, i) : null">
                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for zip  -->
            <div class="form--control" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes === 'ITES_FORMAT_AS_ZIPCODE'">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" mask='00000' id="{{data.fieldId}}"
                  placeholder="{{ utilsService.getCorrectContent(data, 'placeHolder') }}" appNoSpecialSymbols
                  type='text' [formControl]='gridForm.get(data.fieldId)'
                  autocomplete="off" appValidateOnBlur value="" maxlength='5'
                  [ngClass]="{'inp-txt--err': (gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid) }"
                  [required]="data.required === 'true'">

                <lfg-error
                  *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid"
                  message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for Drop Down -->
            <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag != AGENTCONST.secAgentFirmId && data.xmlTag != AGENTCONST.secAgentBGAId && data.xmlTag != CONST.policyBeingQuesId && data.xmlTag != CONST.lapseCompanyName" [ngClass]="{'form-control-medium':  data.xmlTag == ltcReplacementConstants.ltcHealthinsCompanyName || data.xmlTag == ltcReplacementConstants.inforcePendingCompanyName || data.xmlTag == ltcReplacementConstants.ltcReplacementCompanyName || data.xmlTag == ltcReplacementConstants.terminatedpoliciesCompanyName}">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}"
              [options]="(name === 'Owner' && i > 0) ?  updatedOnwerTypeDropdown : alldropDownOptions[data.fieldId]"
              placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, gridForm, grid, $event, i)" [required]="data.required == 'true'">
              </lfg-dropdown>
              <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
            </div>

             <!-- Form element for Search -->
            <div class="form--control form-dropDown form-control-medium" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag == CONST.lapseCompanyName"
                [ngClass]="{'disable': (gridForm.get(data.fieldId).disabled) }">
                <lfg-autocomplete formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" [optionsList]="alldropDownOptions[data.fieldId]" [startsWith]="true" [comboBox]="true" lfgValidateOnBlur lfgAutocompleteAllowedChars="^[a-zA-Z ']+$"
                (noRecordsFound)="noRecordsFound($event, gridForm, data.fieldId )" (noRecordsSelected)="noRecordsSelected($event, gridForm, data.fieldId)" placeholder="{{ utilsService.getCorrectContent(data, 'placeHolder') }}" [required]="data.required == 'true'" (inputBoxBlur)="onblurAutoComplete($event, gridForm, data, data.reflexiveQuestionAL, grid)" maxlength="30"
                [class.lfg-auto-complete--err]="(gridForm.controls[data.fieldId].touched && gridForm.controls[data.fieldId].invalid) || searchFieldError[data.fieldId]">
                </lfg-autocomplete>

                <lfg-error *ngIf="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid) || searchFieldError['data.fieldId']" message="Please enter a valid company name."></lfg-error>
            </div>

            <!-- Form element for Policy being Drop Down -->
            <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag == CONST.policyBeingQuesId">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="policyBeingDropdown" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, gridForm, grid, $event, i); policyBeingUpdated(gridForm.get(data.fieldId), gridForm)" [required]="data.required == 'true'">
              </lfg-dropdown>
              <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
            </div>

            <!-- Form element for currency  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_CURRENCY'">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="data.required == 'true'">

                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- percentage control -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_PERCENTAGE'">
              <lfg-input-wrap>
                <input aria-live="off" lfgInput placeholder="{{ utilsService.getCorrectContent(data, 'placeHolder') }}" appNoSpecialSymbols
                  customAttr="numbersWithPeriod" type='text' [formControl]='gridForm.get(data.fieldId)' appPercentageFormat
                  formatType='percentage' autocomplete="off" appValidateOnBlur value="" maxlength='3'
                  [ngClass]="{'inp-txt--err': ((gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid) || (type==='AGENT' && !validPercentage)) }"
                  [required]="data.required == 'true'" (input)="type === 'BENE' || type === 'AGENT' ? updatePercentageFromInput(grid.gridUniqueId,gridForm.get(data.fieldId)) : false">
                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid && !(type==='AGENT' && !validPercentage)" message="{{ utilsService.getInlineErrorMessage(data) }}"></lfg-error>
                <lfg-error *ngIf="gridForm.get(data.fieldId).errors?.decimalError" message="Your shared percentages need to be whole numbers. Please correct before moving forward."></lfg-error>
                <lfg-error *ngIf="(type==='AGENT' && !validPercentage) && !gridForm.get(data.fieldId)?.errors?.decimalError" message="Split must be equal to 100%."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for radio question -->
            <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO">
              <span class="lfg-radio-section"
              [ngClass]="{'radio--err': (gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid)}">
                <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
                <div class="radio-btn-rect radio-button-reflexive">
                  <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}_{{i}}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data, grid.gridId, grid.gridIndex, gridForm)"/>
                  <label for="yes_{{ data.fieldId }}_{{i}}">{{ data.questionOption[0].description }}</label>
                  <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}_{{i}}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}"  (change)="onRadioChange(data, grid.gridId, grid.gridIndex, gridForm)"/>
                  <label for="no_{{ data.fieldId }}_{{i}}">{{ data.questionOption[1].description }}</label>
                </div>
              </span>
              <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
            </div>

             <!-- Form element for firm Drop Down -->
            <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && (data.xmlTag == AGENTCONST.secAgentFirmId || data.xmlTag == AGENTCONST.secAgentBGAId)">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="firmOptions[grid.gridUniqueId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, gridForm, grid, $event, i)" [required]="data.required == 'true'"  [ngClass]="!enableFirmDropDown[grid.gridUniqueId] || disableForm ? 'lfg-dropdown--disabled' : ''">
              </lfg-dropdown>
              <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid && enableFirmDropDown[grid.gridUniqueId]" message="{{ getErrorMsg(data) }}"></lfg-error>
            </div>

            <div class="section-divider section-divider-agent" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && (data.xmlTag == AGENTCONST.secAgentFirmId || data.xmlTag == AGENTCONST.secAgentBGAId)">
            </div>

            <!-- Form Element for date  -->
            <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
              <lfg-calendar (focusin)="validateOnFocus(data, 'in', gridForm)" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out', gridForm)" lfgCheckValidDate [formControl]="gridForm.get(data.fieldId)" [config]="utilsService.getCalendarConfig(data, dateConfig)" [class.calendar--err]="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
                  <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-calendar>
            </div>

            <!-- Form element for search Button -->
            <div class="form--control form--length-btn search-btn" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText === 'Search'">
              <button id="{{displayIdCreation(data)}}_{{ind}}" (click)="loadFirmDetails(gridForm, grid.gridUniqueId, i); focusOff($event);" class="btn focusOffBtn" [disabled]="!agentSSNValid[grid.gridUniqueId] || disableForm">{{data.questionText}}</button>
            </div>

            <!-- Form element for alphanumeric  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for SSN -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='gridForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="data.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId, i)">
                <lfg-error *ngIf="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid) && ( gridForm.get(data.fieldId).errors == null || (gridForm.get(data.fieldId).errors != null && !gridForm.get(data.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>
              </lfg-input-wrap>
            </div>

             <!-- Form element for email -->
            <div class="form--control form-control-medium" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">

                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for phone number -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
                <lfg-error *ngIf="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for check box  -->
            <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
              <div class="lfg-checkbox">
              <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}"
                  formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data, grid.gridId, grid.gridIndex)">
              <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</label>
              </div>
            </div>

            <!-- Question Info -->
            <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Nestion question Level 1 -->
            <div *ngIf="data.hasReflexive == 'true' && gridForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(gridForm, data) && (data.xmlTag === ExistingInsuranceConstant?.contractToBeReplacedCollAssign ? this.is1035[i] : true)  }">

              <ng-container *ngFor="let gridInfoChild1 of data.reflexiveQuestionAL | dataFilter: (companySelected[grid.gridIndex]): type: questionsData?.page_group_name: gridForm: corpState; index as gridInfoChild1ix">

                <ng-container *ngIf="nestedQuestionCheckgridInfo(data, gridInfoChild1, parentAnswer, i)">

                  <!-- Questions headers -->
                  <div class="questions-header" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && (gridInfoChild1.questionText != 'Line' && gridInfoChild1.subText != 'Information') && (gridInfoChild1.questionText.indexOf('Attach Customer Identity Verification') < 0 || gridInfoChild1.questionText.indexOf('Attach Customer Identity Verification') > -1 && checkCIVFunc(grid))">
                    <h2 class="grid-ques" *ngIf="gridInfoChild1.questionText.indexOf('Attach Customer Identity Verification') < 0">{{ gridInfoChild1.questionText }}</h2>
                    <h2 class="grid-ques"
                    *ngIf="gridInfoChild1.questionText.indexOf('Attach Customer Identity Verification') > -1 && checkCIVFunc(grid)">{{ gridInfoChild1.questionText }}</h2>
                  </div>

                  <div class="section-divider" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild1.questionText == 'Line'">
                  </div>

                  <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                  <div class="questionText" *ngIf="(addCorrectPlaceHolder(gridInfoChild1, 'display') || gridInfoChild1.questionText?.indexOf('Primary Agent\'s Email') > -1) && (CIVFieldsKeys.indexOf(gridInfoChild1.xmlTag) === -1 || (CIVFieldsKeys.indexOf(gridInfoChild1.xmlTag) > -1) && checkCIVFunc(grid))">
                    <span [innerHTML]="addCorrectPlaceHolder(gridInfoChild1, 'question')" *ngIf="CIVFieldsKeys.indexOf(gridInfoChild1.xmlTag) === -1"></span>
                    <span *ngIf="(CIVFieldsKeys.indexOf(gridInfoChild1.xmlTag) > -1) && checkCIVFunc(grid)"
                    [innerHTML]="addCorrectPlaceHolder(gridInfoChild1, 'question')"></span>
                  </div>

                  <!-- Question Info -->
                  <div class="question-info" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild1.subText == 'Information'">
                    <lfg-alert-message type="info">{{ gridInfoChild1.questionText }}</lfg-alert-message>
                  </div>

                  <!-- USA address -->
                  <div class="questions-header" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                    <h2>{{ gridInfoChild1.questionText }}</h2>
                  </div>

                  <!-- Form element for checkbox  -->
                  <div class="form--control form--control__full-width" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
                    <div class="lfg-checkbox">
                    <input id="{{ gridInfoChild1.fieldId }}" type="checkbox" value="checked" name="{{ gridInfoChild1.fieldId }}"
                        formControlName='{{ gridInfoChild1.fieldId }}' [required]="gridInfoChild1.required == 'true'" (change)="onCheckboxChange(gridInfoChild1, grid.gridId, grid.gridIndex)">
                    <label class="wrap wrap-check" for="{{ gridInfoChild1.fieldId }}">{{gridInfoChild1.questionText}}</label>
                    </div>
                  </div>

                  <!-- Form element for text  -->
                  <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild1.notes !== 'ITES_FORMAT_AS_ZIPCODE' && gridInfoChild1.notes !== 'ITES_FORMAT_AS_PERCENTAGE' && gridInfoChild1.notes !== 'ITES_FORMAT_AS_CURRENCY'" >
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols
                      [attr.customAttr]="gridInfoChild1.answerTextType === 'Numeric only' ? appConfig.customAttr['Numeric'] : 'text'"
                      [maxlength]="gridInfoChild1.maxLength >= 999 ? 30 : gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">
                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for zip  -->
                  <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc === appConfig.fieldTypes.TEXT && gridInfoChild1.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" mask='00000-0000' id="{{gridInfoChild1.fieldId}}"
                        placeholder="{{ utilsService.getCorrectContent(gridInfoChild1, 'placeHolder') }}" appNoSpecialSymbols
                        type='text' [formControl]='gridForm.get(gridInfoChild1.fieldId)'
                        autocomplete="off" appValidateOnBlur value="" maxlength='10'
                        [ngClass]="{'inp-txt--err': (gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid) }"
                        [required]="gridInfoChild1.required === 'true'">

                      <lfg-error
                        *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid"
                        message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for currency  -->
                  <div class="form--control" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild1.notes == 'ITES_FORMAT_AS_CURRENCY'">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild1)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="gridInfoChild1.required == 'true'">

                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1, gridForm.get(gridInfoChild1.fieldId)) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for alphanumeric  -->
                  <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">

                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for radio question -->
                  <div class="lfg-customRadio-field radio-btn-custom" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.RADIO">
                    <span class="lfg-radio-section"
                    [ngClass]="{'radio--err': (gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)}">
                      <p class="question-radio_text">{{ gridInfoChild1.questionText + (gridInfoChild1.required == 'true' ? '' : ' (optional)') }}</p>
                      <div class="radio-btn-rect radio-button-reflexive">
                        <input [attr.aria-label]="gridInfoChild1.questionOption[0].description" tabindex="0" id="yes_{{ gridInfoChild1.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild1.fieldId }}" name="{{ gridInfoChild1.fieldId }}" value="{{ gridInfoChild1.questionOption[0].value }}" required="{{gridInfoChild1.required}}" (change)="onRadioChange(gridInfoChild1, grid.gridId, grid.gridIndex)"/>
                        <label for="yes_{{ gridInfoChild1.fieldId }}_{{i}}">{{ gridInfoChild1.questionOption[0].description }}</label>
                        <input [attr.aria-label]="gridInfoChild1.questionOption[1].description" tabindex="0" id="no_{{ gridInfoChild1.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild1.fieldId }}" name="{{ gridInfoChild1.fieldId }}" value="{{ gridInfoChild1.questionOption[1].value }}" required="{{gridInfoChild1.required}}" (change)="onRadioChange(gridInfoChild1, grid.gridId, grid.gridIndex)"/>
                        <label for="no_{{ gridInfoChild1.fieldId }}_{{i}}">{{ gridInfoChild1.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild1, gridForm.get(gridInfoChild1.fieldId)) }}"></lfg-error>
                  </div>

                  <!-- Form element for Drop Down -->
                  <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.SELECT && CIVFieldsKeys.indexOf(gridInfoChild1.xmlTag) === -1">
                    <lfg-dropdown formControlName="{{ gridInfoChild1.fieldId }}" id="{{displayIdCreation(gridInfoChild1)}}_{{gridInfoChild1ix}}" [options]="alldropDownOptions[gridInfoChild1.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild1, gridForm, grid, $event, i)" [required]="gridInfoChild1.required == 'true'"
                    [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)}">
                    </lfg-dropdown>
                    <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                  </div>


                  <!-- Form element for Drop Down -->
                  <div *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.SELECT && (CIVFieldsKeys.indexOf(gridInfoChild1.xmlTag) > -1)">
                    <div class="form--control form-dropDown form-control-medium" *ngIf="checkCIVFunc(grid)">
                      <lfg-dropdown formControlName="{{ gridInfoChild1.fieldId }}" id="{{displayIdCreation(gridInfoChild1)}}_{{gridInfoChild1ix}}" [options]="alldropDownOptions[gridInfoChild1.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild1, gridForm, grid, $event, i)" [required]="gridInfoChild1.required == 'true'"
                      [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)}">
                      </lfg-dropdown>
                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                    </div>
                  </div>

                  <!-- Form element for SSN -->
                  <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.SSN">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='gridForm.get(gridInfoChild1.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="gridInfoChild1.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId)">
                      <lfg-error *ngIf="(gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid) && ( gridForm.get(gridInfoChild1.fieldId).errors == null || (gridForm.get(gridInfoChild1.fieldId).errors != null && !gridForm.get(gridInfoChild1.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>
                    </lfg-input-wrap>
                  </div>

                   <!-- Form element for TIN -->
                   <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TIN">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" mask='AA-AAA0000' [formControl]='gridForm.get(gridInfoChild1.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="gridInfoChild1.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId)">
                      <lfg-error *ngIf="(gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid) && ( gridForm.get(gridInfoChild1.fieldId).errors == null || (gridForm.get(gridInfoChild1.fieldId).errors != null && !gridForm.get(gridInfoChild1.fieldId).errors.notUnique))" message="Please enter a valid TIN."></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <div *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.PHONE && gridInfoChild1.xmlTag == AGENTCONST.phoneNumber">
                    <lfg-alert-message type="info">{{ phoneInfo }}</lfg-alert-message>
                  </div>

                  <!-- Form element for phone number -->
                  <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.PHONE">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="gridInfoChild1.required == 'true'">
                      <lfg-error *ngIf="(gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for email  -->
                  <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                    <lfg-input-wrap>
                      <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild1) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="gridInfoChild1.required == 'true'" (blur)="type === 'AGENT' ? setAgentEmail(gridInfoChild1, gridForm) : false">

                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form Element for date  -->
                  <div class="form--control lfg-cal form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.DOB">
                    <lfg-calendar (focusin)="validateOnFocus(gridInfoChild1, 'in', gridForm)" id="{{gridInfoChild1.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(gridInfoChild1, 'out', gridForm)" lfgCheckValidDate [formControl]="gridForm.get(gridInfoChild1.fieldId)" [config]="utilsService.getCalendarConfig(gridInfoChild1, dateConfig)" [class.calendar--err]="(gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)" [required]="gridInfoChild1.required == 'true'">
                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                    </lfg-calendar>
                  </div>

                  <!-- percentage control -->
                  <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild1.notes == 'ITES_FORMAT_AS_PERCENTAGE'">
                    <lfg-input-wrap>
                      <input aria-live="off" lfgInput placeholder="{{ utilsService.getCorrectContent(gridInfoChild1, 'placeHolder') }}" appNoSpecialSymbols
                        customAttr="numbersWithPeriod" type='text' [formControl]='gridForm.get(gridInfoChild1.fieldId)' appPercentageFormat
                        formatType='percentage' autocomplete="off" appValidateOnBlur value="" maxlength='3'
                        [ngClass]="{'inp-txt--err': (gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid) }"
                        [required]="gridInfoChild1.required == 'true'" (input)="type === 'BENE' || type === 'Agent' ? updatePercentageFromInput(grid.gridUniqueId,gridForm.get(gridInfoChild1.fieldId)) : false">
                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid && !gridForm.get(gridInfoChild1.fieldId).errors.decimalError" message="{{ utilsService.getInlineErrorMessage(gridInfoChild1) }}"></lfg-error>
                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).errors?.decimalError" message="Your shared percentages need to be whole numbers. Please correct before moving forward."></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Nestion question Level 2 -->
                  <div *ngIf="gridInfoChild1.hasReflexive == 'true' && gridForm.get(gridInfoChild1.fieldId).value as parentAnswer">
                    <ng-container *ngFor="let gridInfoChild2 of gridInfoChild1.reflexiveQuestionAL; index as gridInfoChild2ix">
                      <ng-container *ngIf="nestedQuestionCheckgridInfo(gridInfoChild1, gridInfoChild2, parentAnswer, i)">

                        <!-- Questions headers -->
                        <div class="questions-header" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.HDR && (gridInfoChild2.questionText != 'Line' && gridInfoChild2.subText != 'Information')">
                          <h2 class="grid-ques">{{ gridInfoChild2.questionText }}</h2>
                        </div>

                        <div class="section-divider" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild2.questionText == 'Line'">
                        </div>

                        <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                        <div class="questionText" *ngIf="addCorrectPlaceHolder(gridInfoChild2, 'display')">
                          <span [innerHTML]="addCorrectPlaceHolder(gridInfoChild2, 'question')"></span>
                        </div>

                        <!-- Question Info -->
                        <div class="question-info" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild2.subText == 'Information'">
                          <lfg-alert-message type="info">{{ gridInfoChild2.questionText }}</lfg-alert-message>
                        </div>

                        <!-- USA address -->
                        <div class="questions-header" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                          <h2>{{ gridInfoChild2.questionText }}</h2>
                        </div>


                        <!-- Form element for Drop Down -->
                        <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.SELECT ">
                          <lfg-dropdown formControlName="{{ gridInfoChild2.fieldId }}" id="{{displayIdCreation(gridInfoChild2)}}_{{gridInfoChild2ix}}" [options]="alldropDownOptions[gridInfoChild2.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild2, gridForm, grid, $event, i)" [required]="gridInfoChild2.required == 'true'"
                          [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid)}">
                          </lfg-dropdown>
                          <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                        </div>

                        <lfg-alert-message type="info" *ngIf="(type == 'OWNER' && gridInfoChild2.xmlTag === '/XML/Client/CRMDetails/Address/State' && getStateValidationMsg(gridForm, gridInfoChild2, false))">State of Solicitation does not match Owner state. A Lincoln representative will contact you for clarification.</lfg-alert-message>

                        <lfg-alert-message type="info" *ngIf="(type == 'OWNER' && gridInfoChild2.xmlTag === '/XML/Client/CRMDetails/Address/State' && getStateValidationMsg(gridForm, gridInfoChild2, true))">State of Solicitation does not match Insured state.  Cross border sales are not allowed in UT and WA.  If owner/applicant resides in UT or WA, the policy must be sitused there.  A Lincoln representative will contact you for clarification.</lfg-alert-message>

                        <!-- Form element for text  -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild2.notes !== 'ITES_FORMAT_AS_ZIPCODE' && gridInfoChild2.notes !== 'ITES_FORMAT_AS_CURRENCY' && gridInfoChild2.notes !== 'ITES_FORMAT_AS_PERCENTAGE'">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild2) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" type='text'
                            formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="gridInfoChild2.answerTextType === 'Numeric only' ? appConfig.customAttr['Numeric'] : 'text'"
                            [maxlength]="gridInfoChild2.maxLength >= 999 ? 30 : gridInfoChild2.maxLength" [required]="gridInfoChild2.required == 'true'"
                            (input)="i === 0 && gridInfoChild2.xmlTag === OWNERCONST.DriverLicenseNum && checkLicense()">
                            <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for zip  -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc === appConfig.fieldTypes.TEXT && gridInfoChild2.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" mask='00000' id="{{gridInfoChild2.fieldId}}"
                              placeholder="{{ utilsService.getCorrectContent(gridInfoChild2, 'placeHolder') }}" appNoSpecialSymbols
                              type='text' [formControl]='gridForm.get(gridInfoChild2.fieldId)'
                              autocomplete="off" appValidateOnBlur value="" maxlength='5'
                              [ngClass]="{'inp-txt--err': (gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid) }"
                              [required]="gridInfoChild2.required === 'true'">

                            <lfg-error
                              *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid"
                              message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for currency  -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild2.notes == 'ITES_FORMAT_AS_CURRENCY'">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild2)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]='gridInfoChild2.maxLength' [required]="gridInfoChild2.required == 'true'">

                            <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2, gridForm.get(gridInfoChild2.fieldId)) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>


                         <!-- Form element for checkbox  -->
                        <div class="form--control form--control__full-width" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
                          <div class="lfg-checkbox">
                          <input id="{{ gridInfoChild2.fieldId }}" type="checkbox" value="checked" name="{{ gridInfoChild2.fieldId }}"
                              formControlName='{{ gridInfoChild2.fieldId }}' [required]="gridInfoChild2.required == 'true'" (change)="onCheckboxChange(gridInfoChild2, grid.gridId, grid.gridIndex)">
                          <label class="wrap wrap-check" for="{{ gridInfoChild2.fieldId }}">{{gridInfoChild2.questionText}}</label>
                          </div>
                        </div>


                        <!-- Form element for alphanumeric  -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild2) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="gridInfoChild2.maxLength" [required]="gridInfoChild2.required == 'true'">

                            <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for radio question -->
                        <div class="lfg-customRadio-field radio-btn-custom" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.RADIO"
                        [ngClass]="{'question-radio--medium': gridInfoChild2.xmlTag == PrimaryInsuredConstant.phonePINXmlTag}">
                          <span class="lfg-radio-section"
                          [ngClass]="{'radio--err': (gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid)}">
                            <p class="question-radio_text">{{ gridInfoChild2.questionText + (gridInfoChild2.required == 'true' ? '' : ' (optional)') }}</p>
                            <div class="radio-btn-rect radio-button-reflexive">
                              <input [attr.aria-label]="gridInfoChild2.questionOption[0].description" tabindex="0" id="yes_{{ gridInfoChild2.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild2.fieldId }}" name="{{ gridInfoChild2.fieldId }}" value="{{ gridInfoChild2.questionOption[0].value }}" required="{{gridInfoChild2.required}}" (change)="onRadioChange(gridInfoChild2, grid.gridId, grid.gridIndex)"/>
                              <label for="yes_{{ gridInfoChild2.fieldId }}_{{i}}">{{ gridInfoChild2.questionOption[0].description }}</label>
                              <input [attr.aria-label]="gridInfoChild2.questionOption[1].description" tabindex="0" id="no_{{ gridInfoChild2.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild2.fieldId }}" name="{{ gridInfoChild2.fieldId }}" value="{{ gridInfoChild2.questionOption[1].value }}" required="{{gridInfoChild2.required}}" (change)="onRadioChange(gridInfoChild2, grid.gridId, grid.gridIndex)"/>
                              <label for="no_{{ gridInfoChild2.fieldId }}_{{i}}">{{ gridInfoChild2.questionOption[1].description }}</label>
                            </div>
                          </span>
                          <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                        </div>

                        <!-- Form element for phone number -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.PHONE">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="gridInfoChild2.required == 'true'">
                            <lfg-error *ngIf="(gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid)" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for email  -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                          <lfg-input-wrap>
                            <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild2) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="gridInfoChild2.required == 'true'">

                            <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form Element for date  -->
                        <div class="form--control lfg-cal form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.DOB">
                          <lfg-calendar (focusin)="validateOnFocus(gridInfoChild2, 'in', gridForm)" id="{{gridInfoChild2.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(gridInfoChild2, 'out', gridForm)" lfgCheckValidDate [formControl]="gridForm.get(gridInfoChild2.fieldId)"
                          [config]="(gridInfoChild2.questionText.indexOf('Expiration Date') >= 0) ? utilsService.getCalendarConfig(gridInfoChild2, dateConfigForExpiration) :  utilsService.getCalendarConfig(gridInfoChild2, dateConfig)"
                          [class.calendar--err]="(gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid)" [required]="gridInfoChild2.required == 'true'">
                              <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                          </lfg-calendar>
                        </div>

                        <!-- percentage control -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild2.notes == 'ITES_FORMAT_AS_PERCENTAGE'">
                          <lfg-input-wrap>
                            <input aria-live="off" lfgInput placeholder="{{ utilsService.getCorrectContent(gridInfoChild2, 'placeHolder') }}" appNoSpecialSymbols customAttr="numbersWithPeriod" type='text' [formControl]='gridForm.get(gridInfoChild2.fieldId)' appPercentageFormat formatType='percentage' autocomplete="off" appValidateOnBlur value="" maxlength='3' [ngClass]="{'inp-txt--err': (gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid) }" [required]="gridInfoChild2.required == 'true'" (input)="calculatePercentage()">
                            <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid && !gridForm.get(gridInfoChild2.fieldId).errors.decimalError" message="{{ utilsService.getInlineErrorMessage(gridInfoChild2) }}"></lfg-error>
                            <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid && gridForm.get(gridInfoChild2.fieldId).errors.decimalError" message="Your shared percentages need to be whole numbers. Please correct before moving forward."></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for SSN -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.SSN">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild2) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='gridForm.get(gridInfoChild2.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="gridInfoChild2.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId)">
                            <lfg-error *ngIf="(gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid) && ( gridForm.get(gridInfoChild2.fieldId).errors == null || (gridForm.get(gridInfoChild2.fieldId).errors != null && !gridForm.get(gridInfoChild2.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for TIN -->
                        <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.TIN">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild2) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" mask='AA-AAA0000' [formControl]='gridForm.get(gridInfoChild2.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="gridInfoChild2.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId)">
                            <lfg-error *ngIf="(gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid) && ( gridForm.get(gridInfoChild2.fieldId).errors == null || (gridForm.get(gridInfoChild2.fieldId).errors != null && !gridForm.get(gridInfoChild2.fieldId).errors.notUnique))" message="Please enter a valid TIN."></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <lfg-alert-message type="error" *ngIf="i === 0 && showOwner && gridInfoChild2.xmlTag === OWNERCONST.DriverLicenseState && driverLicenseMatchErr">
                          {{OWNER_ERR.DRIVER_LICENSE_MATCH_ERR[0]}} <a (click)="navToProposedInsured()">{{OWNER_ERR.DRIVER_LICENSE_MATCH_ERR[1]}}</a> {{OWNER_ERR.DRIVER_LICENSE_MATCH_ERR[2]}}
                        </lfg-alert-message>

                        <!-- Nestion question Level 3 -->
                        <div *ngIf="gridInfoChild2.hasReflexive == 'true' && gridForm.get(gridInfoChild2.fieldId).value as parentAnswer">
                          <ng-container *ngFor="let gridInfoChild3 of gridInfoChild2.reflexiveQuestionAL; index as gridInfoChild3ix">
                            <ng-container *ngIf="nestedQuestionCheckgridInfo(gridInfoChild2, gridInfoChild3, parentAnswer, i)">

                              <!-- Questions headers -->
                              <div class="questions-header" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.HDR && (gridInfoChild3.questionText != 'Line' && gridInfoChild3.subText != 'Information')">
                                <h2 class="grid-ques">{{ gridInfoChild3.questionText }}</h2>
                              </div>

                              <div class="section-divider" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild3.questionText == 'Line'">
                              </div>

                              <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                              <div class="questionText" *ngIf="addCorrectPlaceHolder(gridInfoChild3, 'display')">
                                <span [innerHTML]="addCorrectPlaceHolder(gridInfoChild3, 'question')"></span>
                              </div>

                              <!-- Question Info -->
                              <div class="question-info" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild3.subText == 'Information'">
                                <lfg-alert-message type="info">{{ gridInfoChild3.questionText }}</lfg-alert-message>
                              </div>

                              <!-- USA address -->
                              <div class="questions-header" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                                <h2>{{ gridInfoChild3.questionText }}</h2>
                              </div>

                              <!-- Form element for text  -->
                              <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild3.notes !== 'ITES_FORMAT_AS_ZIPCODE' && gridInfoChild3.notes !== 'ITES_FORMAT_AS_CURRENCY'">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild3) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="gridInfoChild3.answerTextType === 'Numeric only' ? appConfig.customAttr['Numeric'] : 'text'" [maxlength]="gridInfoChild3.maxLength >= 999 ? 30 : gridInfoChild3.maxLength" [required]="gridInfoChild3.required == 'true'">
                                  <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for zip  -->
                              <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc === appConfig.fieldTypes.TEXT && gridInfoChild3.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" mask='00000' id="{{gridInfoChild3.fieldId}}"
                                    placeholder="{{ utilsService.getCorrectContent(gridInfoChild3, 'placeHolder') }}" appNoSpecialSymbols
                                    type='text' [formControl]='gridForm.get(gridInfoChild3.fieldId)'
                                    autocomplete="off" appValidateOnBlur value="" maxlength='5'
                                    [ngClass]="{'inp-txt--err': (gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid) }"
                                    [required]="gridInfoChild3.required === 'true'">

                                  <lfg-error
                                    *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid"
                                    message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for alphanumeric  -->
                              <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild3) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="gridInfoChild3.maxLength" [required]="gridInfoChild3.required == 'true'">

                                  <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>


                              <!-- Form element for currency  -->
                              <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild3.notes == 'ITES_FORMAT_AS_CURRENCY'">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild3)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="gridInfoChild3.required == 'true'">

                                  <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3, gridForm.get(gridInfoChild3.fieldId)) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for radio question -->
                              <div class="lfg-customRadio-field radio-btn-custom" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.RADIO">
                                <span class="lfg-radio-section"
                                [ngClass]="{'radio--err': (gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid)}">
                                  <p class="question-radio_text">{{ gridInfoChild3.questionText + (gridInfoChild3.required == 'true' ? '' : ' (optional)') }}</p>
                                  <div class="radio-btn-rect radio-button-reflexive">
                                    <input [attr.aria-label]="gridInfoChild3.questionOption[0].description" tabindex="0" id="yes_{{ gridInfoChild3.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild3.fieldId }}" name="{{ gridInfoChild3.fieldId }}" value="{{ gridInfoChild3.questionOption[0].value }}" required="{{gridInfoChild3.required}}" (change)="onRadioChange(gridInfoChild3, grid.gridId, grid.gridIndex)"/>
                                    <label for="yes_{{ gridInfoChild3.fieldId }}_{{i}}">{{ gridInfoChild3.questionOption[0].description }}</label>
                                    <input [attr.aria-label]="gridInfoChild3.questionOption[1].description" tabindex="0" id="no_{{ gridInfoChild3.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild3.fieldId }}" name="{{ gridInfoChild3.fieldId }}" value="{{ gridInfoChild3.questionOption[1].value }}" required="{{gridInfoChild3.required}}" (change)="onRadioChange(gridInfoChild3, grid.gridId, grid.gridIndex)"/>
                                    <label for="no_{{ gridInfoChild3.fieldId }}_{{i}}">{{ gridInfoChild3.questionOption[1].description }}</label>
                                  </div>
                                </span>
                                <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                              </div>

                              <!-- Form element for phone number -->
                              <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.PHONE">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="gridInfoChild3.required == 'true'">
                                  <lfg-error *ngIf="(gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid)" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for email  -->
                              <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                                <lfg-input-wrap>
                                  <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild3) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="gridInfoChild3.required == 'true'">

                                  <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form Element for date  -->
                              <div class="form--control lfg-cal form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.DOB">
                                <lfg-calendar (focusin)="validateOnFocus(gridInfoChild3, 'in', gridForm)" id="{{gridInfoChild3.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(gridInfoChild3, 'out', gridForm)" lfgCheckValidDate [formControl]="gridForm.get(gridInfoChild3.fieldId)" [config]="utilsService.getCalendarConfig(gridInfoChild3, dateConfig)" [class.calendar--err]="(gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid)" [required]="gridInfoChild3.required == 'true'">
                                    <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                </lfg-calendar>
                              </div>

                              <!-- Form element for Drop Down -->
                              <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.SELECT">
                                <lfg-dropdown formControlName="{{ gridInfoChild3.fieldId }}" id="{{displayIdCreation(gridInfoChild3)}}_{{gridInfoChild3ix}}" [options]="alldropDownOptions[gridInfoChild3.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild3, gridForm, grid, $event, i)" [required]="gridInfoChild3.required == 'true'"
                                [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid)}">
                                </lfg-dropdown>
                                <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                              </div>

                              <!-- Form element for SSN -->
                              <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.SSN">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild3) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='gridForm.get(gridInfoChild3.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="gridInfoChild3.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId)">
                                  <lfg-error *ngIf="(gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid) && ( gridForm.get(gridInfoChild3.fieldId).errors == null || (gridForm.get(gridInfoChild3.fieldId).errors != null && !gridForm.get(gridInfoChild3.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for TIN -->
                              <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.TIN">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild3) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" mask='AA-AAA0000' [formControl]='gridForm.get(gridInfoChild3.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="gridInfoChild3.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId)">
                                  <lfg-error *ngIf="(gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid) && ( gridForm.get(gridInfoChild3.fieldId).errors == null || (gridForm.get(gridInfoChild3.fieldId).errors != null && !gridForm.get(gridInfoChild3.fieldId).errors.notUnique))" message="Please enter a valid TIN."></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Nestion question Level 4 -->
                              <div *ngIf="gridInfoChild3.hasReflexive == 'true' && gridForm.get(gridInfoChild3.fieldId).value as parentAnswer">
                                <ng-container *ngFor="let gridInfoChild4 of gridInfoChild3.reflexiveQuestionAL; index as gridInfoChild4ix">
                                  <ng-container *ngIf="nestedQuestionCheckgridInfo(gridInfoChild3, gridInfoChild4, parentAnswer, i)">

                                    <!-- Questions headers -->
                                    <div class="questions-header" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.HDR && (gridInfoChild4.questionText != 'Line' && gridInfoChild4.subText != 'Information')">
                                      <h2 class="grid-ques">{{ gridInfoChild4.questionText }}</h2>
                                    </div>

                                    <div class="section-divider" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild4.questionText == 'Line'">
                                    </div>

                                    <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                                    <div class="questionText" *ngIf="addCorrectPlaceHolder(gridInfoChild4, 'display')">
                                      <span [innerHTML]="addCorrectPlaceHolder(gridInfoChild4, 'question')"></span>
                                    </div>

                                    <!-- Question Info -->
                                    <div class="question-info" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild4.subText == 'Information'">
                                      <lfg-alert-message type="info">{{ gridInfoChild4.questionText }}</lfg-alert-message>
                                    </div>

                                    <!-- USA address -->
                                    <div class="questions-header" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                                      <h2>{{ gridInfoChild4.questionText }}</h2>
                                    </div>

                                    <!-- Form element for text  -->
                                    <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild4.notes !== 'ITES_FORMAT_AS_ZIPCODE' && gridInfoChild4.notes !== 'ITES_FORMAT_AS_CURRENCY'">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild4) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="gridInfoChild4.answerTextType === 'Numeric only' ? appConfig.customAttr['Numeric'] : 'text'" [maxlength]="gridInfoChild4.maxLength >= 999 ? 30 : gridInfoChild4.maxLength" [required]="gridInfoChild4.required == 'true'">
                                        <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for zip  -->
                                    <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc === appConfig.fieldTypes.TEXT && gridInfoChild4.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" mask='00000' id="{{gridInfoChild4.fieldId}}"
                                          placeholder="{{ utilsService.getCorrectContent(gridInfoChild4, 'placeHolder') }}" appNoSpecialSymbols
                                          type='text' [formControl]='gridForm.get(gridInfoChild4.fieldId)'
                                          autocomplete="off" appValidateOnBlur value="" maxlength='5'
                                          [ngClass]="{'inp-txt--err': (gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid) }"
                                          [required]="gridInfoChild4.required === 'true'">

                                        <lfg-error
                                          *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid"
                                          message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for alphanumeric  -->
                                    <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild4) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="gridInfoChild4.maxLength" [required]="gridInfoChild4.required == 'true'">

                                        <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>


                                    <!-- Form element for currency  -->
                                    <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild4.notes == 'ITES_FORMAT_AS_CURRENCY'">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild4)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="gridInfoChild4.required == 'true'">

                                        <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4, gridForm.get(gridInfoChild4.fieldId)) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for radio question -->
                                    <div class="lfg-customRadio-field radio-btn-custom" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.RADIO">
                                      <span class="lfg-radio-section"
                                      [ngClass]="{'radio--err': (gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid)}">
                                        <p class="question-radio_text">{{ gridInfoChild4.questionText + (gridInfoChild4.required == 'true' ? '' : ' (optional)') }}</p>
                                        <div class="radio-btn-rect radio-button-reflexive">
                                          <input [attr.aria-label]="gridInfoChild4.questionOption[0].description" tabindex="0" id="yes_{{ gridInfoChild4.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild4.fieldId }}" name="{{ gridInfoChild4.fieldId }}" value="{{ gridInfoChild4.questionOption[0].value }}" required="{{gridInfoChild4.required}}" (change)="onRadioChange(gridInfoChild4, grid.gridId, grid.gridIndex)"/>
                                          <label for="yes_{{ gridInfoChild4.fieldId }}_{{i}}">{{ gridInfoChild4.questionOption[0].description }}</label>
                                          <input [attr.aria-label]="gridInfoChild4.questionOption[1].description" tabindex="0" id="no_{{ gridInfoChild4.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild4.fieldId }}" name="{{ gridInfoChild4.fieldId }}" value="{{ gridInfoChild4.questionOption[1].value }}" required="{{gridInfoChild4.required}}" (change)="onRadioChange(gridInfoChild4, grid.gridId, grid.gridIndex)"/>
                                          <label for="no_{{ gridInfoChild4.fieldId }}_{{i}}">{{ gridInfoChild4.questionOption[1].description }}</label>
                                        </div>
                                      </span>
                                      <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                    </div>

                                    <!-- Form element for phone number -->
                                    <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.PHONE">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="gridInfoChild4.required == 'true'">
                                        <lfg-error *ngIf="(gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid)" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for email  -->
                                    <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                                      <lfg-input-wrap>
                                        <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild4) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="gridInfoChild4.required == 'true'">

                                        <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for Drop Down -->
                                    <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.SELECT">
                                      <lfg-dropdown formControlName="{{ gridInfoChild4.fieldId }}" id="{{displayIdCreation(gridInfoChild4)}}_{{gridInfoChild4ix}}" [options]="alldropDownOptions[gridInfoChild4.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild4, gridForm, grid, $event, i)" [required]="gridInfoChild4.required == 'true'"
                                      [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid)}">
                                      </lfg-dropdown>
                                      <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                    </div>

                                    <!-- Form Element for date  -->
                                    <div class="form--control lfg-cal form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.DOB">
                                      <lfg-calendar (focusin)="validateOnFocus(gridInfoChild4, 'in', gridForm)" id="{{gridInfoChild4.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(gridInfoChild4, 'out', gridForm)" lfgCheckValidDate [formControl]="gridForm.get(gridInfoChild4.fieldId)" [config]="utilsService.getCalendarConfig(gridInfoChild4, dateConfig)" [class.calendar--err]="(gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid)" [required]="gridInfoChild4.required == 'true'">
                                          <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                      </lfg-calendar>
                                    </div>

                                    <!-- Form element for SSN -->
                                    <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.SSN">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild4) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='gridForm.get(gridInfoChild4.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="gridInfoChild4.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId)">
                                        <lfg-error *ngIf="(gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid) && ( gridForm.get(gridInfoChild4.fieldId).errors == null || (gridForm.get(gridInfoChild4.fieldId).errors != null && !gridForm.get(gridInfoChild4.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for TIN -->
                                    <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.TIN">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild4) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" mask='AA-AAA0000' [formControl]='gridForm.get(gridInfoChild4.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="gridInfoChild4.required == 'true'" (input)="checkIfSSNIsValid(gridForm, grid.gridUniqueId)">
                                        <lfg-error *ngIf="(gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid) && ( gridForm.get(gridInfoChild4.fieldId).errors == null || (gridForm.get(gridInfoChild4.fieldId).errors != null && !gridForm.get(gridInfoChild4.fieldId).errors.notUnique))" message="Please enter a valid TIN."></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                  </ng-container>
                                </ng-container>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>

                      </ng-container>
                    </ng-container>
                  </div>

                </ng-container>
              </ng-container>
            </div>

          </ng-container>
        </div>
      </lfg-panel>
    </lfg-accordion>
  </form>

  <div class="grid-footer">
    <div class="add-grid" *ngIf="addAccordionText && maxGridAvailable > gridSequences.length && !hideAddLink && !removeAdditionalOwner">
      <a (click)="createNewGrid(true)" [ngClass]="{'btn--link-disabled': disableForm}" tabindex="0">
        <i class="fas fa-plus-circle add-icon" aria-hidden="true"></i>{{addAccordionText}}</a>
    </div>
    <div class="add-grid" *ngIf="type === 'LTC' && gridInstances[0]?.gridName === LTC_REPLACEMENT_CONST.ltcOrHealthInsGridName && gridSequences.length === 1">
      <a (click)="displayRemovePolicyWarning(true)" [ngClass]="{'btn--link-disabled': disableForm}" tabindex="0">
        <i class="fas fa-trash-alt delete-can" aria-hidden="true"></i></a>
    </div>
  </div>
  
  <lfg-alert-message type="warning" *ngIf="removePolicyWarning">
    {{LTC_REPLACEMENT_MSG.REMOVE_POLICY_ERROR[0]}} <a (click)="navToExistingIns()">{{LTC_REPLACEMENT_MSG.REMOVE_POLICY_ERROR[1]}}</a> {{LTC_REPLACEMENT_MSG.REMOVE_POLICY_ERROR[2]}}
  </lfg-alert-message>

</div>
