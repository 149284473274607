import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Question } from 'src/app/shared/models/casePage.model';
import { ApplicationConfig } from 'src/config/app.config';
import { AgentValidationMessage } from 'src/config/constants';

@Component({
  selector: 'app-agent-validation-popup',
  templateUrl: './agent-validation-popup.component.html',
  styleUrls: ['./agent-validation-popup.component.scss'],
})
export class AgentValidationPopupComponent implements OnInit {
  modalAction;
  @Input()
  set action(actionData: any) {
    this.modalAction = actionData;
  }

  get action(): any {
    return this.modalAction;
  }

  @Output() updateModalAction = new EventEmitter<any>();
  @Input() noOfFailedAgents: number;
  @Input() message: string;
  @Input() additionalText: string;
  @Input() okButtonMessage: string;
  @Input() questions: Question[];

  modalConfig: any;
  AgentValMsg = AgentValidationMessage;

  constructor(
    public appConfig: ApplicationConfig,
  ) {}

  ngOnInit(): void {
    this.modalConfig = {
      header: true,
      state: true,
      footer: true,
    };
  }

  closeModalPopup(action?: string): any {
    this.modalConfig.state = false;
    const emitFlag = action === 'close' ? false : true;
    this.updateModalAction.emit(emitFlag);
  }
}
