import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductCodes, SideNavConfig, TAB, TabInformation, TabStatus } from 'src/config/sideNav.config';
import { ProductDetailsService } from './product-details.service';
import { UserDetailsService } from './user-details.service';
import { TABS_AFTER_LOCK_SCREEN } from 'src/config/constants';
import { DelegationService } from './delegation.service';

@Injectable({
  providedIn: 'root',
})
export class TabDetailsService {
  tabInfoList: TabInformation[];
  tabInfoSubject = new BehaviorSubject(new Array<TabInformation>());
  activeTab: TabInformation;
  previousTab: TabInformation;
  nextTab: TabInformation;
  activeTabSubject = new BehaviorSubject(new TabInformation());
  activeNavIndex: any;
  tabStatusMap: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    private sideNavConfig: SideNavConfig,
    private productService: ProductDetailsService,
    private userService: UserDetailsService,
    private delegationService: DelegationService) {
    this.initializeTabService();
  }

  updateTabList(tabName: string, isEnable?: boolean, status?: TabStatus): void {
    this.tabInfoList.forEach((tab) => {
      if (tabName === tab.name) {
        tab.isEnable = isEnable ?? tab.isEnable;
        if (status) {
          tab.status.errorExists = status.errorExists ?? tab.status.errorExists;
          tab.status.visited = status.visited ?? tab.status.visited;
          tab.status.isLocked = status.isLocked;
        }
      }
    });
    this.tabInfoSubject.next(this.tabInfoList);
    this.updateTabNavigation();
  }

  lockAllTabs(shouldLock: boolean, excludeList: string[]): void {
    if ((shouldLock && this.checkTabsReadyToLock(excludeList)) || !shouldLock) {
      this.tabInfoList.forEach((tab) => {
        if (!excludeList.includes(tab.name)) {
          tab.status.isLocked = shouldLock;
        }
      });
      this.tabInfoSubject.next(this.tabInfoList);
    } else {
      console.log('Error exists, cannot lock tabs');
    }

  }

  isTabsLocked(): boolean {
    let allTabLocked = true;
    this.tabInfoList.forEach((tab) => {
      if (tab.isEnable && !tab.status.isLocked) {
        allTabLocked = false;
      }
    });
    return allTabLocked;
  }

  hasNextTab(): boolean {
    return this.nextTab !== undefined ? true : false;
  }

  hasPreviousTab(): boolean {
    return this.previousTab !== undefined ? true : false;
  }

  getNextTab(): TabInformation {
    return this.nextTab;
  }

  next(): void {
    this.updateTabIcon();
    this.previousTab = this.activeTab;
    this.activeTab = this.nextTab;
    this.updateNextTab();
    this.updateActiveTab();
  }

  previous(): void {
    this.updateTabIcon();
    this.nextTab = this.activeTab;
    this.activeTab = this.previousTab;
    this.updatePreviousTab();
    this.updateActiveTab();
  }

  selectTab(tab: TabInformation): void {
    this.updateTabIcon();
    this.activeTab = tab;
    this.updateActiveTab();
    this.updateTabNavigation();
  }

  getActiveTab(): TabInformation {
    return this.activeTab;
  }

  updateTabStatusMap(tabName: string, isValid: boolean): void {
    this.tabStatusMap.set(tabName, isValid);
  }

  updateTabLockStatus(tabname: string, isLock: boolean): void {
    this.updateTabList(tabname, true, { isLocked: isLock, visited: true, errorExists: false });
    if (!isLock) {
      this.updateActiveTab();
    }
  }

  getTabInfoByTabName(tabName: string): TabInformation {
    const tabInfo = this.tabInfoList.filter((tab) => {
      return tab.name === tabName;
    });
    if (tabInfo) {
      return tabInfo[0];
    }
    return null;
  }

  getTabByTabName(tabName: string): TabInformation {
    return this.tabInfoList.filter(tab => {
      return tab.name === tabName;
    })[0];
  }

  hasConsentTab(): boolean {
    return this.tabInfoList.filter((tab) => {
      return tab.name?.toLowerCase().indexOf('term of use -') > -1 && tab.isEnable;
    }).length > 0;
  }

  isTabActive(tabName: string): boolean {
    return this.tabInfoList.filter(tab => {
      return tab.name === tabName && tab.isEnable;
    })?.length > 0;
  }

  getTabsLockStatus(excludeList): boolean {
    const lockedTabs = this.tabInfoList.filter((tab) => {
      return (!excludeList.includes(tab.name) && !tab.status.isLocked && tab.isEnable);
    });
    return lockedTabs.length === 0;
  }

  isAllTabsValid(excludeTabs?: string[]): boolean {
    const invalidTabs = this.tabInfoList.filter((tab) => {
      return tab.isEnable
        && ((tab.status.visited && tab.status.errorExists) || !tab.status.visited)
        && !excludeTabs?.includes(tab.name)
        && !tab.status.isLocked;
    });
    return invalidTabs.length === 0;
  }

  initializeTabService(): void {
    this.tabStatusMap.clear();
    this.tabInfoList = JSON.parse(JSON.stringify(this.sideNavConfig.tabInfoList));
    this.tabInfoSubject.next(this.tabInfoList);
    this.initializeTabNavigation();
  }

  private updateTabNavigation(): void {
    this.updatePreviousTab();
    this.updateNextTab();
  }

  private updateActiveTab(): void {
    this.activeTabSubject.next(this.activeTab);
  }

  private checkTabsReadyToLock(excludeList: string[]): boolean {
    let readyToLock = true;
    this.tabInfoList.forEach((tab) => {
      if (!tab.status.isLocked && (tab.isEnable && ((tab.status.visited && tab.status.errorExists) || !tab.status.visited)
        && !excludeList.includes(tab.name))) {
        readyToLock = false;
      }
    });
    return readyToLock;
  }

  getTabListDifference(prevTabList: TabInformation[], currTabList: TabInformation[]): TabInformation[] {
    const tabDiff = [];
    prevTabList.forEach(tab => {
      currTabList.forEach(currTab => {
        if (tab.name === currTab.name && tab.isEnable && !currTab.isEnable) {
          tabDiff.push(tab);
        }
      });
    });
    return tabDiff;
  }

  checkForTabApplicableAfterProductUpdate(): TabInformation[] {
    const selectedProductType = this.productService.getProductType();
    const selectedProductCode = this.productService.getProductName()?.toLowerCase();
    const selectedState = this.userService.getInsuredState();
    const tempTabList = JSON.parse(JSON.stringify(this.sideNavConfig.tabInfoList));
    tempTabList.forEach((tab) => {
      if ((tab.displayConditionBasedOnProduct?.includes(selectedProductType?.toLowerCase()) ||
        tab.displayConditionBasedOnProductCode?.includes(selectedProductCode)) &&
        (!tab.includeState || tab.includeState.includes(selectedState)) &&
        (!tab.excludeState || !tab.excludeState.includes(selectedState))) {
        tab.isEnable = true;
      }
      if ((tab.name === TAB.LIFE_SUITABILITY_MG || tab.name === TAB.DCA_PREMIUM_ALLOCATION_MG)) {
        if (selectedProductCode?.indexOf(ProductCodes.MGMA22) === -1 && selectedProductCode?.indexOf(ProductCodes.MGMA) === -1
        && selectedProductCode?.indexOf(ProductCodes.MGMA24) === -1) {
          tab.isEnable = false;
        } else {
          tab.isEnable = true;
        }
      }
    });
    return tempTabList;
  }

  private updateNextTab(): void {
    let isUpdated = false;
    this.tabInfoList.slice(this.getActiveTabIndex() + 1).forEach((tab) => {
      if (!isUpdated && tab.isEnable) {
        this.nextTab = tab;
        isUpdated = true;
      }
    });
    if (!isUpdated) {
      this.nextTab = undefined;
    }
  }

  private updatePreviousTab(): void {
    let isUpdated = false;
    for (let index = this.getActiveTabIndex() - 1; index >= 0; index--) {
      if (this.tabInfoList[index].isEnable) {
        this.previousTab = this.tabInfoList[index];
        isUpdated = true;
        break;
      }
    }
    if (!isUpdated) {
      this.previousTab = undefined;
    }
  }

  private getActiveTabIndex(): number {
    return this.tabInfoList.findIndex(this.activeNavIndex);
  }

  private initializeTabNavigation(): void {
    this.activeTab = this.tabInfoList[0];
    this.activeNavIndex = (element) => element.name === this.activeTab?.name;
    this.updateActiveTab();
    this.updateNextTab();
  }

  updateTabListbyConfig(): void {
    const selectedProductType = this.productService.getProductType()?.toLowerCase();
    const selectedProductCode = this.productService.getProductName()?.toLowerCase();
    const selectedState = this.userService.getInsuredState();
    const insuredAge = this.userService.getInsuredAge();
    this.tabInfoList.forEach((tab) => {
      if ((tab.displayConditionBasedOnProduct?.includes(selectedProductType) )) {
        tab.isDefault = true;
        tab.isEnable = true;
      }
      if (tab.name === TAB.LIFE_SUITABILITY_MG && (selectedProductCode?.indexOf(ProductCodes.MGMA22) === -1 && selectedProductCode?.indexOf(ProductCodes.MGMA24) === -1 && selectedProductCode?.indexOf(ProductCodes.MGMA) === -1)) {
        tab.isDefault = false;
        tab.isEnable = false;
      }
      if (tab.name === TAB.DCA_PREMIUM_ALLOCATION_MG && (selectedProductCode?.indexOf(ProductCodes.MGMA22) === -1 && selectedProductCode?.indexOf(ProductCodes.MGMA24) === -1 && selectedProductCode?.indexOf(ProductCodes.MGMA) === -1)) {
        tab.isDefault = false;
        tab.isEnable = false;
      }
      if (tab.name === TAB.TEMPORARY_LIFE_MG && ((selectedState?.indexOf('KS') > -1 || selectedState?.indexOf('MT') > -1) || insuredAge > 70)) {
        tab.isDefault = false;
        tab.isEnable = false;
      }
      if (tab.name === TAB.WET_SIGN_COLLECTION_INFO_MG && selectedState?.indexOf('CA') > -1) {
        tab.isEnable = true;
      }
    });
  }

  private updateTabIcon(): void {
    const accessConfig = this.delegationService.getAccessConfigForUser();
    const isEditAllowedForCase = accessConfig?.editCase;
    const isEditAllowedForCurrentTab = (TABS_AFTER_LOCK_SCREEN.includes(this.activeTab.name) && !accessConfig?.readOnlyPostLock)
      || (!TABS_AFTER_LOCK_SCREEN.includes(this.activeTab.name));
    if (this.activeTab && isEditAllowedForCase && isEditAllowedForCurrentTab) {
      const formValid = this.tabStatusMap.get(this.activeTab.name);
      const tabLockStatus = !formValid ? false : this.activeTab.status.isLocked;
      this.updateTabList(this.activeTab.name, undefined, { errorExists: !formValid, visited: true, isLocked: tabLockStatus });
    }
  }

  getTabsAvailableAfter(tabname: string): TabInformation[] {
    const currTabIndex = this.tabInfoList.findIndex(tab => tab.name === tabname);
    return this.tabInfoList.filter((tab, index) => {
      return index > currTabIndex && tab.isEnable;
    });
  }


  isConsentTab(tabName: string): boolean {
    return tabName?.toLowerCase().indexOf('term of use -') > -1
  }

}
