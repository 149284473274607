import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { formatCurrency } from '@angular/common';

import { ClipboardService } from '@ng/lfg-common-utilities';
import { LfgLoaderService } from '@ng/lfg-loader';
import { DisableStyle } from '@ng/lfg-calendar';

import { GridInstance, Question, QuestionOption } from 'src/app/shared/models/casePage.model';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { CivService } from 'src/app/shared/services/civ.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import {
  AgentInfoConstant, ExistingInsuranceConstant, PrimaryInsuredConstant, ownerFieldDisableKeys, BeneficiaryErrors, CIVFieldsKeys,
  ExistingInsuranceInternalReplacementQ, InternalReplacementCompanies, LtcReplacementConstants, FirmDataFields, OwnerInfoConstant,OwnerErrors, LTCReplacementMessages,
  Message
} from 'src/config/constants';
import { TAB } from 'src/config/sideNav.config';
import * as _ from 'lodash';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';


@Component({
  selector: 'app-grid-structure',
  templateUrl: './grid-structure.component.html',
  styleUrls: ['./grid-structure.component.scss'],
})
export class GridStructureComponent implements OnInit, OnChanges {
  @Input() disableForm: boolean;
  gridList: number[] = [];

  @Input() gridInstances: GridInstance[];
  @Input() gridAnswers: any[];
  @Input() maxGridAvailable = 10;
  @Input() accordionHeader: string;
  @Input() addAccordionText: string;
  @Input() type: string;
  @Input() isRevisit = false;
  @Input() existingInsAnswers: {};
  @Input() hideAddLink = false;
  @Input() removeAdditionalOwner = false;
  @Input() name = 'Owner';
  @Input() ownerAnswers: any;
  @Input() showOwner: boolean;
  @Input() shareEqually = false;
  @Input() civInput;
  @Input() beneLoadFlag: boolean;
  @Input() insuredDLInfo: any = {}
  @Input() isWholesaler: boolean;
  @Output() gridStatus: EventEmitter<any> = new EventEmitter();
  @Output() hideAddLinkForTrustEntity: EventEmitter<any> = new EventEmitter();
  CIVOwnerIdentityType;
  CIVIDNumber;
  CIVExpirationDate;
  CIVStateCountryOfIssuance;

  mainFormGroup: FormGroup;
  gridQuestionsObj: any = {};
  formValid: boolean;
  formHasNoErrors: boolean;
  openAccordian = {};
  gridSequences: any = [];
  gridStatusVal = {};
  placeholders = {};
  alldropDownOptions = {};
  setTimeFocus = null;
  agentSSNValid = {};
  enableFirmDropDown = {};
  policyBeingDropdown = [];
  policyOptional = false;
  showLoader = 0;
  previousSSN = {};
  previousLastName = {};
  firmOptions = {};
  selectedFirm = {};
  selectedOtherFirmName = {};

  selectedBga = {};
  selectedOtherBgaName = {};

  index = 0;
  typeKeys = [];
  nameFieldnameKeys = [];
  ownerFieldDisableKeys = [];
  ownerDisableXMLTag = ownerFieldDisableKeys;
  percentKeys = [];
  beneShareEquallyRuleErr = false;
  beneGridCount;
  beneShareEquallyRuleErrGridCount8 = true;
  totalPercentage = 0;
  validPercentage = true;
  maxGrids = 0;
  nameKeys: any = {};
  testamentaryQNameKeys = [];
  conditionalQuestForTrust: any = [];
  CIVFieldsKeys = CIVFieldsKeys;
  displayCIVFields = false;
  sharePercentageNameKey = [];
  updatedOnwerTypeDropdown = [];
  hideAddLkForTrustEntity = false
  civQuestionsObj: any = {};
  insuredSameQuestionsObj: any = {};
  pushFieldToNewLine = ['company name', 'address/street', 'city', 'face amount', 'product name', 'email for replacing insured'];
  writingAgentGridIdx: number = -1;
  driverLicenseMatchErr: boolean = false;
  removePolicyWarning: boolean = false;

  // Agent page related ids and name
  agentFirmQuesName;
  agentOtherFirmQuesName;
  agentBGAQuesName;
  agentOtherBGAQuesName;
  agentBGAQuesFieldId;
  agentOtherBGAQuesFieldId;
  agentFirmQuesFieldId;
  agentOtherFirmQuesFieldId;
  agentSSNQuesName;
  agentLastNameQuesName;

  readonly CONST = ExistingInsuranceConstant;
  readonly AGENTCONST = AgentInfoConstant;
  readonly BENE_ERR = BeneficiaryErrors;
  readonly OWNERCONST = OwnerInfoConstant;
  readonly OWNER_ERR = OwnerErrors;
  readonly LTC_REPLACEMENT_MSG = LTCReplacementMessages;
  readonly LTC_REPLACEMENT_CONST = LtcReplacementConstants;
  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Date',
    disableStyle: DisableStyle.locked,
  };
  dateConfigForExpiration = { ...this.dateConfig };
  companySelected = [];
  ownerPINPreferenceObj: any = {};
  searchFieldError: any= {};
  is1035 = [];
  ltcReplacementConstants = LtcReplacementConstants;
  agentNpn = {}
  addressFieldNameKeys = []
  gridPercentages = {}
  gridPercentageStrings = {}
  corpState = '';
  readonly PrimaryInsuredConstant = PrimaryInsuredConstant;
  phoneInfo = Message.PHONE_INFO;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private agentService: AgentAndFirmService,
    private userService: UserDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private loaderService: LfgLoaderService,
    private userDetailService: UserDetailsService,
    private clipboardService: ClipboardService,
    private civService: CivService,
    private tabService: TabDetailsService
  ) {
    this.dateConfigForExpiration.minDate = new Date();
    this.dateConfigForExpiration.maxDate = new Date('01/01/2060');
  }

  ngOnInit(): void {
    if (!this.gridInstances) {
      return;
    }
    this.companySelected = this.clipboardService.get('companySelected') || [];
    this.is1035 = this.clipboardService.get('is1035') || [];
    this.getKeysBasedonType()
    if (this.type === 'BENE') {
      for (const gridData of this.gridInstances) {
        if (gridData && gridData.id.toLowerCase().indexOf('trust') >= 0) {
          this.getConditionalTrustQuestionsForBene(gridData);
        }
        this.sharePercentageNameKey.push(gridData.gridQuestions[0]?.reflexiveQuestionAL[0]?.name)
      }
      this.showOwnersFirst();
    }
    this.initializeGridInstance();
    this.markAllFieldAsTouched();
    this.valueChanges();
    this.onInitChecks();
  }

  onInitChecks(): void {
    if (this.name === 'Owner') {
      this.showOwner = (this.gridAnswers && this.gridAnswers.length < 4) ? this.showOwner : false;
      this.checkForBonafideQuestion();
      this.checkLicense()
    }
    if(this.name === "Agent") {
      this.checkForWritingAgent();
      this.sharePercentageNameKey.push(this.utilsService.getFieldByXmlTag(this.gridInstances[0].gridQuestions,AgentInfoConstant.splitXmlTag))
    }
    this.controlPercentageFields();
    if (this.civInput && this.civInput[0] === 'yes' && this.hideAddLink) {
      this.removeAdditionalOwnerOrBeneGrids();
    }
    this.onloadEmitHideForTrustEntityOwner();
    setTimeout(() => {
      this.defaultPageLoadService.disableFormIfLocked(this.mainFormGroup, this.disableForm);
    }, 600);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.hideAddLink && changes.hideAddLink.previousValue !== changes.hideAddLink.currentValue) {
      this.hideAddLink = changes.hideAddLink.currentValue;
      this.shareEqually = this.hideAddLink;
      if (this.hideAddLink) {
        this.removeAdditionalOwnerOrBeneGrids();
      }
      this.controlPercentageFields();
    }
    if (changes?.shareEqually && changes.shareEqually.previousValue !== changes.shareEqually.currentValue) {
      this.shareEqually = changes.shareEqually.currentValue;
      this.controlPercentageFields();
    }
    this.civInputChanges(changes);
  }

  civInputChanges(changes): void {
    if (changes?.civInput && this.name === 'Owner') {
      this.civInput = changes.civInput.currentValue;
      setTimeout(() => {
        if (this.civInput.length > 0) {
          this.civCheck();
          this.checkLicense()
        }
      }, 200);
    }
  }

  civCheck(): void {
    this.gridSequences.forEach((obj, i) => {
      let civObj;
      let gridFormGroup;
      const gridControl = this.mainFormGroup.get(obj.gridId) as FormArray;
      gridFormGroup = gridControl.controls[obj.gridIndex] as FormGroup;
      const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
      civObj = gridData[0]?.reflexiveQuestionAL?.filter((el: any) => {
        if (CIVFieldsKeys.indexOf(el.xmlTag) > -1) {
          return el;
        }
      });
      if (!this.disableForm) {
        gridFormGroup.get(civObj[0]?.fieldId)?.enable();
      }
      civObj[0]?.reflexiveQuestionAL?.forEach(element => {
        if (!this.disableForm) {
          gridFormGroup.get(element?.fieldId)?.enable();
        }
      });
      if (!this.checkCIVFunc(obj)) {
        gridFormGroup.get(civObj[0]?.fieldId)?.setValue('');
        this.updatedAnswersOnHidden(civObj[0], obj.gridId, obj.gridIndex);
        civObj[0]?.reflexiveQuestionAL?.forEach(element => {
          gridFormGroup.get(element?.fieldId)?.setValue('');
          gridFormGroup.get(element?.fieldId).setErrors(null);
          gridFormGroup.get(element?.fieldId).markAsUntouched();
        });
      }
    });
  }

  removeAdditionalOwnerOrBeneGrids(): void {
    const ownersLen = this.ownerAnswers && this.ownerAnswers.length > 0 ? this.ownerAnswers.length : 1;
    const minGridsToShow = this.showOwner ? ownersLen : 1;
    while (this.gridSequences.length > minGridsToShow) {
      const obj = this.gridSequences.pop();
      const removeGridEvent =  { obj: obj, index: obj.gridIndex, deletedSequence: false };
      this.removeGrid(removeGridEvent);

    }
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  onRadioChange(data: Question, gridId: string, gridIndex: number, gridForm?: any): any {
    this.updatedAnswersOnHidden(data, gridId, gridIndex);
    if (data?.xmlTag === ExistingInsuranceConstant.exchange1035Ind) {
      this.is1035[gridIndex] = gridForm.get(data.fieldId)?.value?.split('{')[0] === 'yes';
      this.clipboardService.set('is1035', this.is1035)
    } else if(data?.xmlTag === AgentInfoConstant.isWritingAgentQuesId) {
      const isWritingAgent = gridForm.get(data.fieldId)?.value?.split('{')[0] === 'Yes';
      this.setWritingAgent(isWritingAgent,gridIndex)
    }
  }

  addCorrectPlaceHolder(data, type): any {
    this.utilsService.updatePolicyRelatedData(data, this.type);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  getErrorMsg(data): any {
    return this.utilsService.getInlineErrorMessage(data);
  }

  removeGrid(event: any, i?: any): void {
    if (this.is1035) {
      this.is1035?.splice(event?.index, 1);
    }
    if (event?.deletedSequence) {
      this.gridSequences.splice(event?.index, 1);
    }
    const control = this.mainFormGroup.get(event?.obj?.gridId) as FormArray;
    control?.removeAt(event?.obj?.gridIndex);
    this.updateGridSequence(event?.obj);
    if (this.name === 'Primary' || this.name === 'Contingent' || this.name === 'Agent') {
      this.controlPercentageFields();
    }
    if(event?.index===this.writingAgentGridIdx){
      this.setWritingAgent(false)
    }
    if (this.gridSequences && this.gridSequences.length < 1 && this.type !== "AGENT") {
      this.createNewGrid();
    }
    setTimeout(() => {
      this.civCheck();
      this.checkFormStatus(true);
    }, 150);
  }

  createNewGrid(isButtonClick?: boolean): void {
    const id = this.gridInstances[0].id;
    this.addGrid(id);
    const formArray = this.mainFormGroup.get(id) as FormArray;
    const gridUniqueId = new Date().toString() + this.index.toString();
    this.index++;
    const obj = {
      gridIndex: formArray.length - 1,
      gridId: id,
      formLoadCompleted: false,
      gridUniqueId,
    };
    this.gridSequences.push(obj);
    if (this.name === 'Primary' || this.name === 'Contingent' || this.name === 'Agent') {
      this.controlPercentageFields();
    }
    if(this.name === "Agent"){
      const hasWritingAgent = this.writingAgentGridIdx !== -1
      this.setWritingAgent(hasWritingAgent,this.writingAgentGridIdx)
    }
    if (isButtonClick) {
      this.defaultPageLoadService.logButtonClick(this.addAccordionText);
    }
    this.displayRemovePolicyWarning(false);
  }

  private checkIfDataAvailable(): void {
    if (this.gridAnswers && this.gridAnswers.length > 0) {
      if (this.showOwner && this.ownerAnswers && this.ownerAnswers.length > 0) {
        if (this.name === 'Owner') {
          // fix for owner only. Need to make generic fix
          this.prePopulateOwner();
        } else if (this.name === 'Primary') {
          this.checkIfDataAvailableForPrimaryBene();
        }
      } else {
        // remove owner from gridAnswer
        this.removeGridAnswer();
      }
      this.prepopulateGrids(this.gridAnswers);
      this.enableDisableOwners();
    } else if (this.showOwner && this.ownerAnswers && this.ownerAnswers.length > 0) {
      this.prepopulateGrids(this.ownerAnswers);
      this.enableDisableOwners();
    } else {
      this.createNewGrid();
      this.updateGridSequenceValue();
    }
  }

  checkIfDataAvailableForPrimaryBene(): void {
    let match = false;
    for (const ownerAnsObj of this.ownerAnswers) {
      for (const gridAnsObj of this.gridAnswers) {
        const isTrustGridTestamentary = this.checkGridIsTrustWithTestamentary(gridAnsObj);
        const isOwnerGridTrust = this.checkGridIsTrustWithTestamentary(ownerAnsObj);
        const gridAnsObjWithoutPercentage = this.getObjWithoutPercentage(gridAnsObj);
        if (isOwnerGridTrust && isTrustGridTestamentary) {
          const updatedOwnerObject = this.getUpdatedObject(ownerAnsObj);
          const updatedGridObject = this.getUpdatedObject(gridAnsObj);
          match = _.isEqual(updatedOwnerObject, updatedGridObject);
        } else {
          match = _.isEqual(ownerAnsObj, gridAnsObjWithoutPercentage);
        }
        if (match) {
          break;
        }
      }
      this.noMatch(match, ownerAnsObj);
    }
  }

  noMatch(match, ownerAnsObj): void {
    if (!match) {
      const updatedOwnerObj = this.checkIfOwnerDataUpdated(ownerAnsObj, this.gridAnswers);
      if (updatedOwnerObj === null) {
        this.prepopulateGrids([ownerAnsObj]);
      } else {
        this.prepopulateGrids([updatedOwnerObj]);
      }
    }
  }

  setOwnerPinPreferenceValue(): void {
    if (this.gridAnswers[0]) {
      const ownerPINPreference = this.gridAnswers[0][this.ownerPINPreferenceObj?.name];
      this.clipboardService.set('ownerPINPreference', ownerPINPreference);
    }
  }

  prePopulateOwner(): void {
    const match = _.isEqual(this.ownerAnswers[0], this.gridAnswers[0]);
    if (!match) {
      const ownerCitizenName = this.isWholesaler ? OwnerInfoConstant.wsOwnerCitizenName : OwnerInfoConstant.ownerCitizenName;
      const ownerSSNName = this.isWholesaler ? OwnerInfoConstant.wsOwnerSSNName : OwnerInfoConstant.ownerSSNName;
      this.ownerAnswers[0][ownerCitizenName] = this.gridAnswers[0][ownerCitizenName];
      const ownerSSN = this.ownerAnswers[0][ownerSSNName]
      const gridOwnerSSN = this.gridAnswers[0][ownerSSNName]
      if (ownerSSN == gridOwnerSSN) {
        const ownerWithCIV = JSON.parse(JSON.stringify(this.ownerAnswers));
        for (const obj of this.civQuestionsObj['OWNER-IND_GRID']) {
          ownerWithCIV[0][obj.name] = this.gridAnswers[0][obj.name];
          for (const child of obj.reflexiveQuestionAL) {
            ownerWithCIV[0][child.name] = this.gridAnswers[0][child.name];
          }
        }
        this.prepopulateGrids(ownerWithCIV);
      } else {
        this.prepopulateGrids(this.ownerAnswers);
      }
      let grdInd = -1;
      for (const gridAnsObj of this.gridAnswers) {
        grdInd++;
        this.checkIfInsuredDataUpdated(this.ownerAnswers[0], gridAnsObj, grdInd);
      }
    }
  }

  removeGridAnswer(): void {
    this.gridAnswers = _.differenceWith(this.gridAnswers, this.ownerAnswers, _.isEqual);
    if (this.gridAnswers && this.gridAnswers.length === 0 ) {
      if (this.name !== 'Owner') {
        this.createNewGrid();
      }
    }
  }

  checkIfInsuredDataUpdated(ownerAnswers, gridAnswers, gridInd): void {
    if (gridAnswers?.hasOwnProperty(this.typeKeys[0].name)
        && gridAnswers[this.typeKeys[0].name].toLowerCase().indexOf('person') >= 0) {
      const ownerObject = this.getOwnerName(ownerAnswers, this.nameFieldnameKeys);
      const gridOwnerObject = this.getOwnerName(gridAnswers, this.nameFieldnameKeys);
      const match = _.isEqual(ownerObject, gridOwnerObject);
      if (match) {
        // remove old owner object from the gridAnswer
        // this.gridAnswers.shift();
        this.gridAnswers.splice(gridInd, 1);
      }
    }
  }

  getOwnerName(answers, nameFieldnameKeys): any {
    const nameObject = {}; let nameIndex = 0;
    for (const ownerKey in answers) {
      if (ownerKey) {
        nameIndex++;
        if (nameFieldnameKeys.indexOf(ownerKey) >= 0) {
          nameObject[ownerKey] = answers[ownerKey]?.toLowerCase();
        }
      }
    }
    return nameObject;
  }

  private valueChanges(): void {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.mainFormGroup.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.updateGridSequenceValue();
        this.checkFormStatus(true);
      }, 200);
    });
  }

  private checkFormStatus(formUpdated?: boolean): void {
    this.formValid = true;
    if(this.name === "Agent"){
      this.formValid = this.writingAgentGridIdx !== -1
    }
    if(this.name === "Owner"){
      this.formValid = !this.driverLicenseMatchErr
    }
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.mainFormGroup,
      data: {},
      gridData: {},
      gridDataWithNameTag: {},
    };
    for (const data of this.gridSequences) {
      if (!componentData.gridData.hasOwnProperty(data.gridId)) {
        componentData.form = this.mainFormGroup;
        componentData = this.formgeneratorService.checkGridStatusAndGetGridData(
          componentData,
          data.gridId,
          this.gridQuestionsObj,
          this.name
        );
        this.formValid = componentData.formValid;
        this.formHasNoErrors = componentData.formHasNoErrors;
      }
    }
    const gridAnswers = [];
    for (const data of this.gridSequences) {
      let ans = componentData.gridDataWithNameTag[data.gridId][data.gridIndex]
      ans = this.stripPercentageFields(ans)
      gridAnswers.push(ans);
      this.checkIfFormIsValid(data);
      this.updatedIntExtIndForExistingIns(gridAnswers, data);
    }
    if (this.gridSequences?.length == 1) {
      this.openLastGrid(0);
    }
    const obj = {
      formValid: this.formValid && this.checkIfPercentagesValid(),
      formHasNoErrors: this.formHasNoErrors,
      gridId: this.getGridId(this.gridSequences),
      gridAnswers,
      formId: 'mainFormGroup',
      isGridFormChange: this.mainFormGroup?.dirty || (!!formUpdated),
    };
    this.gridStatus.emit(obj);
  }

  updatedIntExtIndForExistingIns(gridAnswers, obj): void {
    if (this.name !== 'Inforce') {
      return;
    }
    const grid = this.gridQuestionsObj[obj.gridId].gridQuestions;
    const question = grid.filter(data => data.xmlTag === ExistingInsuranceConstant.replacementType);
    const ans = InternalReplacementCompanies?.indexOf(this.companySelected[obj.gridIndex]) > -1 ? 'Internal{47}Internal' : 'External{47}External';
    if (question) {
      gridAnswers[obj.gridIndex][question[0]?.name] = ans;
    }
  }

  checkIfPercentagesValid(): any {
    if (this.name === 'Primary' || this.name === 'Contingent' || this.name === "Agent") {
      return this.validPercentage;
    } else {
      return true;
    }
  }

  getGridId(gridSequences: any): any {
    for (const data of gridSequences) {
      return data.gridId;
    }
  }

  validateOnFocus(
    gridStructureData,
    gridStructureType,
    gridStructureFormGroup?
  ): void {
    clearTimeout(this.setTimeFocus);
    this.setTimeFocus = setTimeout(() => {
      const form = gridStructureFormGroup || this.mainFormGroup;
      if (gridStructureType === 'in') {
        form.get(gridStructureData?.fieldId)?.markAsUntouched();
      } else {
        form.get(gridStructureData?.fieldId)?.markAsTouched();
      }
    }, 10);
  }

  private addGrid(gridId: string, gridInd?:number): void {
    const gridFormGroup = this.formgeneratorService.createGrid(
      gridId,
      this.gridQuestionsObj
    );
    const control = this.mainFormGroup.get(gridId) as FormArray;
    const newControls = this.fb.group(gridFormGroup);
    control.push(newControls);
    let i = this.gridSequences.length;
    if (gridInd !== undefined && gridInd !== null){
      i = gridInd;
    }
    this.openLastGrid(i);
    setTimeout(() => {
      this.civCheck();
    }, 150)
  }

  private openLastGrid(i: number): void {
    this.openAccordian = {};
    this.openAccordian[i] = true;
  }

  private updateGridSequence(deletedObj): void {
    for (const obj of this.gridSequences) {
      if (
        obj.gridId === deletedObj?.gridId &&
        obj.gridIndex > deletedObj.gridIndex
      ) {
        obj.gridIndex -= 1;
      }
    }
  }

  onloadEmitHideForTrustEntityOwner(): void {
    if (this.name === 'Owner' && !this.showOwner) {
      const obj = this.gridSequences[0];
      const formArray =  this.mainFormGroup.get(obj.gridId) as FormArray;
      const formGroup = formArray.at(obj.gridIndex);
      const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
      const key = this.utilsService.getFieldId(gridData, '/XML/Client/CRMDetails/ClientType');
      const ownerTypeVal = formGroup?.get(key)?.value;
      this.emitHideForTrustEntityOwner(ownerTypeVal?.label);
    }
  }

  emitHideForTrustEntityOwner(option): void {
    if (option === 'Trust' || option === 'Entity') {
      this.hideAddLkForTrustEntity = true;
      this.hideAddLinkForTrustEntity.emit(true);
    } else {
      this.hideAddLkForTrustEntity = false;
      this.hideAddLinkForTrustEntity.emit(false);
    }
  }

  handleSelectionChange(data, gridForm?, obj?, event?, i?, ix?) {
    this.updatedAnswersOnHidden(data, obj.gridId, i);
    if (obj && event && data?.xmlTag === '/XML/Client/CRMDetails/ClientType') {
      const oldObj = JSON.parse(JSON.stringify(obj));
      const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
      const key = this.utilsService.getFieldId(gridData, '/XML/Client/CRMDetails/ClientType');
      if (event.label === 'Person') {
        this.addGrid(this.gridInstances[0].id, i);
        obj.gridId = this.gridInstances[0].id;
        const newArray = this.mainFormGroup.get(obj.gridId) as FormArray;
        obj.gridIndex = newArray.length - 1;
        newArray.at(obj.gridIndex).get(key).setValue(event);
        const removeGridEvent =  { obj: oldObj, index: oldObj.gridIndex, deletedSequence: false };
        this.removeGrid(removeGridEvent);
      } else if (event.label === 'Trust') {
        this.addGrid(this.gridInstances[1].id, i);
        obj.gridId = this.gridInstances[1].id;
        const newArray = this.mainFormGroup.get(obj.gridId) as FormArray;
        obj.gridIndex = newArray.length - 1;
        newArray.at(obj.gridIndex).get(key).setValue(event);
        const removeGridEvent =  { obj: oldObj, index: oldObj.gridIndex, deletedSequence: false };
        this.removeGrid(removeGridEvent);
      } else if (event.label === 'Entity') {
        this.addGrid(this.gridInstances[2].id, i);
        obj.gridId = this.gridInstances[2].id;
        const newArray = this.mainFormGroup.get(obj.gridId) as FormArray;
        obj.gridIndex = newArray.length - 1;
        newArray.at(obj.gridIndex).get(key).setValue(event);
        const removeGridEvent =  { obj: oldObj, index: oldObj.gridIndex, deletedSequence: false };
        this.removeGrid(removeGridEvent);
      } else if (event.label === 'Freeform') {
        this.addGrid(this.gridInstances[3].id, i);
        obj.gridId = this.gridInstances[3].id;
        const newArray = this.mainFormGroup.get(obj.gridId) as FormArray;
        obj.gridIndex = newArray.length - 1;
        newArray.at(obj.gridIndex).get(key).setValue(event);
        const removeGridEvent =  { obj: oldObj, index: oldObj.gridIndex, deletedSequence: false };
        this.removeGrid(removeGridEvent);
      }
      if ((i === 0 && this.name === 'Owner') && (event.label === 'Person' || event.label === 'Trust' || event.label === 'Entity')) {
        const firstDropdonValue = gridForm.get(data.fieldId).value;
        this.updatedOnwerTypeDropdown = [];
        this.updatedOnwerTypeDropdown.push(firstDropdonValue);
        this.emitHideForTrustEntityOwner(event.label);
      }
    }
    this.additionalChecksOnHandleSelection(data, obj);
  }

  additionalChecksOnHandleSelection(data, obj): void {
    if (data?.xmlTag === OwnerInfoConstant.OWNER_ADDRESS_SAME_AS || data?.xmlTag === OwnerInfoConstant.OWNER_STATE ) {
      this.checkForBonafideQuestion();
    }
    if ((data?.xmlTag === OwnerInfoConstant.DriverLicenseState || data?.xmlTag === OwnerInfoConstant.IdentityType) && this.gridInstances[0]?.id === obj.gridId) {
      this.checkLicense()
    }
  }

  setDefaultDropdownValues(): void {
    let obj: any = {};
    obj = this.gridSequences[0];
    let sequenceIndex = 0;
    if (this.updatedOnwerTypeDropdown[0]?.value.indexOf('Trust') > -1) {
      sequenceIndex = 1;
    } else if (this.updatedOnwerTypeDropdown[0]?.value.indexOf('Entity') > -1) {
      sequenceIndex = 2;
    }
    const oldObj = JSON.parse(JSON.stringify(obj));
    const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
    const key = this.utilsService.getFieldId(gridData, '/XML/Client/CRMDetails/ClientType');
    this.addGrid(this.gridInstances[sequenceIndex].id, 0);
    this.gridSequences.push(obj);
    obj.gridId = this.gridInstances[sequenceIndex].id;
    const newArray = this.mainFormGroup.get(obj.gridId) as FormArray;
    obj.gridIndex = newArray.length - 1;
    newArray.at(obj.gridIndex).get(key).setValue(this.updatedOnwerTypeDropdown[0]);
    const removeGridEvent =  { obj: oldObj, index: oldObj.gridIndex, deletedSequence: true };
    this.removeGrid(removeGridEvent);
  }

  clearAnswersForTrust(gridForm, questObj) {
    const ft = this.appConfig.fieldTypes;
    const nonFormElements = [ft.HDR, ft.GRID, ft.USAADDRESS];
    if (questObj?.fieldId && nonFormElements.indexOf(questObj.controlTypeDesc) === -1) {
      gridForm.get(questObj?.fieldId).setValue('');
      gridForm.get(questObj?.fieldId).markAsUntouched();
      gridForm?.controls[questObj?.fieldId].setErrors(null);
    }
    if ( questObj && questObj.hasReflexive === 'true' ) {
      questObj?.reflexiveQuestionAL.forEach((childData) => {
        this.clearAnswersForTrust(gridForm, childData);
      });
    }
  }

  private prepopulateGrids(savedAnswers: any[]): void {
    for (const savedAnswer of savedAnswers) {
      let id = null;
      if (this.name !== 'Inforce' && this.name != 'LTC' && this.name != 'Agent') {
        // tslint:disable-next-line:max-line-length
        if (savedAnswer.hasOwnProperty(this.typeKeys[1].name) && savedAnswer[this.typeKeys[1].name] && savedAnswer[this.typeKeys[1].name].toLowerCase().indexOf('trust') >= 0) {
          id = this.gridInstances[1].id;
        // tslint:disable-next-line:max-line-length
        } else if (savedAnswer.hasOwnProperty(this.typeKeys[2].name) && savedAnswer[this.typeKeys[2].name] && savedAnswer[this.typeKeys[2].name].toLowerCase().indexOf('entity') >= 0) {
          id = this.gridInstances[2].id;
        // tslint:disable-next-line:max-line-length
        } else if (this.name !== 'Owner' && savedAnswer.hasOwnProperty(this.typeKeys[3].name) && savedAnswer[this.typeKeys[3].name] && savedAnswer[this.typeKeys[3].name].toLowerCase().indexOf('freeform') >= 0) {
          id = this.gridInstances[3].id;
        } else {
          id = this.gridInstances[0].id;
        }
      } else {
        id = this.gridInstances[0].id;
      }
      const gridUniqueId = new Date().toString() + this.index.toString();
      this.index++;
      this.setSelectedFirm(savedAnswer, gridUniqueId);
      const updatedAnswerObject =
        this.formgeneratorService.updateGridAnswerArray(
          id,
          this.gridQuestionsObj,
          savedAnswer
        );
      const gridFormGroup = this.formgeneratorService.createGrid(
        id,
        this.gridQuestionsObj,
        updatedAnswerObject
      );
      const formArray = this.mainFormGroup.get(id) as FormArray;
      const newControls = this.fb.group(gridFormGroup);

      this.updateFirmRelatedDetails(newControls, gridUniqueId, true);
      formArray.push(newControls);
      const obj = {
        gridIndex: formArray.length - 1,
        gridId: id,
        formLoadCompleted: true,
        gridUniqueId,
      };
      this.gridSequences.push(obj);
      this.openLastGrid(savedAnswers?.length - 1);
    }

    if(this.hideAddLink){
      this.removeAdditionalOwnerOrBeneGrids()
    }
  }

  private updateGridSequenceValue(): void {
    const obj = this.gridSequences[this.gridSequences.length - 1];
    if (obj) {
      obj.formLoadCompleted = true;
      this.gridSequences[this.gridSequences.length - 1] = obj;
    }
  }

  private initializeGridInstance(): void {
    for (const gridData of this.gridInstances) {
      this.gridQuestionsObj[gridData.id] = gridData;
      const typeObjData = gridData.gridQuestions[0];
      if (typeObjData) {
        this.typeKeys.push( {fieldId: typeObjData?.fieldId, name: typeObjData.name} );
      }
      this.setNameAndOwnerKeys(gridData);
      this.civQuestionsObj[gridData.id] = gridData.gridQuestions[0].reflexiveQuestionAL?.filter((element: any) => {
        if (CIVFieldsKeys.indexOf(element.xmlTag) > -1) {
          return element;
        }
      });
      this.insuredSameQuestionsObj[gridData.id] = gridData.gridQuestions[0].reflexiveQuestionAL?.filter((element: any) => {
        if (element.xmlTag === OwnerInfoConstant.OWNER_ADDRESS_SAME_AS) {
          return element;
        }
      });
      const gridDropDownOption = this.formgeneratorService.getdropdownOptions(
        gridData.gridQuestions
      );
      this.alldropDownOptions = {
        ...this.alldropDownOptions,
        ...gridDropDownOption,
      };
    }
    this.formgeneratorService.setGridQuestionsData(this.gridQuestionsObj);
    this.initializeQuestionNamesAndId();
    const form = this.loadGriddata();
    this.mainFormGroup = this.fb.group(form);
    this.checkIfDataAvailable();
    this.valueChanges();
    console.log('populate owner called ################# ');
  }

  setNameAndOwnerKeys(gridData): void {
    gridData.gridQuestions[0]?.reflexiveQuestionAL.forEach((fieldObj) => {
      if (fieldObj && (fieldObj.xmlTag.toLowerCase().indexOf((OwnerInfoConstant.OWNER_FIRST_NAME)?.toLowerCase()) >= 0
                      || fieldObj.xmlTag.toLowerCase().indexOf((OwnerInfoConstant.OWNER_LAST_NAME)?.toLowerCase()) >= 0)) {
        this.nameFieldnameKeys.push(fieldObj.name);
      }
      this.ownerFieldDisableKeys.push(gridData.gridQuestions[0]?.fieldId);
      if (fieldObj && this.ownerDisableXMLTag.indexOf(fieldObj.xmlTag) >= 0) {
        this.ownerFieldDisableKeys.push(fieldObj?.fieldId);
      }
      if (fieldObj.xmlTag === OwnerInfoConstant.primaryTypePhoneQues) {
        this.ownerPINPreferenceObj = fieldObj.reflexiveQuestionAL[0];
      }
      if (fieldObj.xmlTag === this.appConfig.beneKeys.addressSameAsKey){
        fieldObj.reflexiveQuestionAL.forEach(ques=>{
          this.addressFieldNameKeys.push(ques.name)
        })
      }
    });
  }

  loadGriddata(): any {
    const form = {};
    form[this.gridInstances[0]?.id] = this.fb.array([]);
    if (this.name !== 'Inforce' && this.name !== 'Pending') {
      form[this.gridInstances[1]?.id] = this.fb.array([]);
      form[this.gridInstances[2]?.id] = this.fb.array([]);
    }
    if (this.name === 'Primary' || this.name === 'Contingent') {
      form[this.gridInstances[3]?.id] = this.fb.array([]);
    }
    return form;
  }


  checkIfSSNIsValid(gridForm: FormGroup, gridUniqueId: string, gridInd?, onload?: boolean): void {
    if (this.type === 'AGENT') {
      const secAgentSSNFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId);
      const agentLastNameFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentLastNameQuesId);
      if ((this.previousSSN[gridUniqueId] !== gridForm.get(secAgentSSNFieldId)?.value || this.previousLastName[gridUniqueId] !== gridForm.get(agentLastNameFieldId)?.value) && !onload) {
        for (const xmlTag of FirmDataFields) {
          const fieldId = this.getFieldIdByXmlTag(xmlTag);
          gridForm.get(fieldId)?.setValue('');
        }
        this.resetFirmList(gridForm, gridUniqueId)
      }
      if (gridForm.get(secAgentSSNFieldId)?.status === 'VALID'
        && gridForm.get(secAgentSSNFieldId)?.value !== ''
        && gridForm.get(agentLastNameFieldId)?.value !== ''
        ) {
        this.agentSSNValid[gridUniqueId] = true;
      }
    }
  }

  onCheckboxChange(data: Question, gridId: string, gridIndex: number): any {
    this.updatedAnswersOnHidden(data, gridId, gridIndex);
  }

  updatedAnswersOnHidden(data: Question, gridId: string, gridIndex: number): any {
    const value = this.mainFormGroup.getRawValue();
    const control = 'controls';
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.mainFormGroup.get(gridId)[control][gridIndex]?.get(key)?.markAsUntouched();
      }
      this.mainFormGroup.get(gridId)[control][gridIndex]?.patchValue(updatedval);
    }
  }

  loadFirmDetails(gridForm: FormGroup, gridUniqueId: string, gridInd?): void {
    gridForm.get(this.agentOtherFirmQuesFieldId)?.reset();
    gridForm.get(this.agentOtherBGAQuesFieldId)?.reset();
    this.selectedOtherFirmName[gridUniqueId] = "";
    this.selectedOtherBgaName[gridUniqueId] = "";
    this.getFirmDropdownOptions(gridForm, gridUniqueId, gridInd);
    this.defaultPageLoadService.logButtonClick('search');
  }


  private getFirmDropdownOptions(gridForm: FormGroup, gridUniqueId: string, gridInd?): void {
    this.showPageLoader(true);
    const value = 'Other';
    const description = 'Other';
    const label = 'Other';
    const quesOptions: QuestionOption[] = [];
    const secAgentSSNFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId);
    const ssnVal = gridForm.get(secAgentSSNFieldId)?.value;
    const agentLastNameFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentLastNameQuesId)
    const lastNameVal = gridForm.get(agentLastNameFieldId)?.value;
    this.agentService
      .getFirmList(ssnVal,lastNameVal)
      .subscribe(
        (response) => {
          this.agentService.createDropDownFromFirmList(response, quesOptions);
          const npn = response?.data?.agentNpn
          this.agentService.saveAgentNpnResp(ssnVal, npn);
          this.previousSSN[gridUniqueId] = ssnVal;
          this.previousLastName[gridUniqueId] = lastNameVal;
        },
        (_error) => {
          console.log('error occured');
        }
      )
      .add(() => {
        quesOptions.push({ value, description, label });
        this.firmOptions[gridUniqueId] =
          this.utilsService.formatOptions(quesOptions);
        this.agentService.saveFirmResponse(ssnVal+":"+lastNameVal, this.firmOptions[gridUniqueId]);
        if(this.selectedFirm[gridUniqueId]){
          this.updateFirmRelatedDetails(gridForm, gridUniqueId)
        }
        this.enableFirmDropDown[gridUniqueId] = true;
        this.setDefaultAnswerForFirmAndBGADropdown(gridForm, gridUniqueId);
        this.showPageLoader(false);
      });
  }

  private setDefaultAnswerForFirmAndBGADropdown(gridForm: FormGroup, gridUniqueId: string) {
    if (this.firmOptions[gridUniqueId]?.length === 1 || this.firmOptions[gridUniqueId]?.length === 2) {
      gridForm.get(this.agentFirmQuesFieldId)?.setValue(this.firmOptions[gridUniqueId][0]);
      gridForm.get(this.agentBGAQuesFieldId)?.setValue(this.firmOptions[gridUniqueId][0]);
    } else {
      gridForm.get(this.agentFirmQuesFieldId)?.reset();
      gridForm.get(this.agentBGAQuesFieldId)?.reset();
    }
  }

  private resetFirmList(gridForm: FormGroup, gridUniqueId: string): void {
    gridForm.get(this.agentFirmQuesFieldId)?.reset();
    gridForm.get(this.agentBGAQuesFieldId)?.reset();
    gridForm.get(this.agentOtherFirmQuesFieldId)?.reset();
    gridForm.get(this.agentOtherBGAQuesFieldId)?.reset();
    this.agentSSNValid[gridUniqueId] = false;
    this.firmOptions[gridUniqueId] = [];
    this.enableFirmDropDown[gridUniqueId] = false;
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  nestedQuestionCheckgridInfo(data, childData, parent, i?): any {
    if (data?.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData?.optionChoice;
    } else if (data?.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData?.optionChoice?.toLowerCase().indexOf('yes') > -1) {
        return parent === true;
      } else if (childData?.optionChoice?.toLowerCase().indexOf('no') > -1) {
        return parent === false;
      }
    } else {
      if (data?.xmlTag === ExistingInsuranceConstant.contractToBeReplacedCollAssign && !this.is1035[i]) {
        return false;
      }
      return parent === childData?.optionChoice;
    }
  }

  checkIfQuestionDisplayed(gridForm: FormGroup, data): any {
    const answer = gridForm.get(data?.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  private updateFirmRelatedDetails(formControl: FormGroup, gridUniqueId: string, onload?: boolean): void {
    this.checkIfSSNIsValid(formControl, gridUniqueId, 0, onload);
    this.firmOptions[gridUniqueId] = this.agentService.getFirmResponse(formControl.get(
      this.getFieldIdByXmlTag(AgentInfoConstant.ssnXmlTag))?.value + ':' +
      formControl.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentLastNameQuesId))?.value);
    if (this.firmOptions[gridUniqueId]?.length > 0) {
      this.enableFirmDropDown[gridUniqueId] = true;
      formControl.get(this.agentFirmQuesFieldId)?.setValue(
        this.updateFirmDropdownSelectedAnswer(gridUniqueId, this.selectedFirm[gridUniqueId]));
      formControl.get(this.agentBGAQuesFieldId)?.setValue(
        this.updateFirmDropdownSelectedAnswer(gridUniqueId, this.selectedBga[gridUniqueId]));
      formControl.get(this.agentOtherFirmQuesFieldId)?.setValue(this.selectedOtherFirmName[gridUniqueId]);
      formControl.get(this.agentOtherBGAQuesFieldId)?.setValue(this.selectedOtherBgaName[gridUniqueId]);
    }
  }

  private setSelectedFirm(savedAnswer: any, gridUniqueId: string): void {
    if (this.type === 'AGENT') {
      if (this.agentFirmQuesName && savedAnswer[this.agentFirmQuesName]) {
        this.selectedFirm[gridUniqueId] = savedAnswer[this.agentFirmQuesName];
        this.previousSSN[gridUniqueId] = savedAnswer[this.agentSSNQuesName];
        this.previousLastName[gridUniqueId] = savedAnswer[this.agentLastNameQuesName];
        if (this.agentOtherFirmQuesName && savedAnswer[this.agentOtherFirmQuesName]) {
          this.selectedOtherFirmName[gridUniqueId] = savedAnswer[this.agentOtherFirmQuesName];
        }
        if (this.agentBGAQuesName && savedAnswer[this.agentBGAQuesName]) {
          this.selectedBga[gridUniqueId] = savedAnswer[this.agentBGAQuesName];
          if (this.agentOtherBGAQuesName && savedAnswer[this.agentOtherBGAQuesName]) {
            this.selectedOtherBgaName[gridUniqueId] = savedAnswer[this.agentOtherBGAQuesName];
          }
        }
      }
    }
  } 

  private updateFirmDropdownSelectedAnswer(gridUniqueId: string, selectedFirmForGrid: string): any {
    let selectedOption;
    this.firmOptions[gridUniqueId].forEach((option) => {
      if (selectedFirmForGrid?.toLowerCase() === option.value?.toLowerCase() || selectedFirmForGrid === this.getFirmIdFromVal(option.value)) {
        selectedOption = option;
      }
    });
    return selectedOption;
  }

  private getFirmIdFromVal(value: string) {
    return value ? value.substring(0, value.indexOf('{')): '';
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  markAllFieldAsTouched(): void {
    if (this.isRevisit) {
      this.companySelected = this.clipboardService.get('companySelected') || [];
      this.mainFormGroup.markAllAsTouched();
    }
  }

  private checkIfFormIsValid(gridData): any {
    const formGrid = ((this.mainFormGroup.get(gridData.gridId) as FormArray)?.at(gridData.gridIndex)) as FormGroup;
    let sequenceIndex = 0;
    if (gridData?.gridId.indexOf('TRUST_GRID') > -1) {
      sequenceIndex = 1;
    } else if (gridData?.gridId.indexOf('CORPORATION_GRID') > -1) {
      sequenceIndex = 2;
    } else if (gridData?.gridId.indexOf('FREEFORM_GRID') > -1) {
      sequenceIndex = 3;
    }
    const questData = this.gridInstances[sequenceIndex].gridQuestions;
    const componentData = {
      formValid: true
    };
    if(this.type === "OWNER" && this.driverLicenseMatchErr && gridData.gridIndex === 0){
      componentData.formValid = false;
    }
    questData.forEach(ques => {
      this.updateFormStatus(componentData, ques, formGrid);
    });
    this.gridStatusVal[gridData.gridUniqueId] = componentData.formValid;
  }

  private updateFormStatus(componentData: any, data: any, form: FormGroup, parentAnswer?: any): any {
    let inputVisible = true;
    if (typeof parentAnswer !== 'undefined') {
      const optionChoice = data.optionChoice || ''
      inputVisible = optionChoice.indexOf(parentAnswer) >= 0;
    }
    if (inputVisible && form?.controls[data?.fieldId]?.status === 'INVALID') {
      componentData.formValid = false;
    }
    if (this.type === 'AGENT' && !this.validPercentage){
      componentData.formValid = false;
    }
    if (inputVisible && data.hasReflexive === 'true') {
      data?.reflexiveQuestionAL.forEach((childData) => {
        const answer = this.getAnswer(form, data);
        componentData = this.updateFormStatus(componentData, childData, form, answer);
      });
    }
    return componentData;
  }

  private getAnswer(form: FormGroup, data: Question): string {
    let answer = form.get(data?.fieldId)?.value;
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT && answer) {
      answer = answer.value;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      answer = answer === true ? 'Yes{47}Yes' : 'No{47}No';
    }
    return answer;
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService?.getFieldIdByTag(xmlTag);
  }

  getKeysBasedonType(): void {
    if (this.name === 'Primary') {
      this.nameKeys = this.appConfig.primaryNameKeys;
      this.maxGrids = this.appConfig.maxGrids.primaryBene;
      this.percentKeys = this.appConfig.primaryPercentKeys;
    } else if (this.name === 'Contingent') {
      this.nameKeys = this.appConfig.contingentNameKeys;
      this.maxGrids = this.appConfig.maxGrids.contigentBene;
      this.percentKeys = this.appConfig.contingentPercentKeys;
    } else if (this.name === 'Inforce') {
      this.nameKeys = this.appConfig.inforceKeys;
      this.maxGrids = this.appConfig.maxGrids.inforceIns;
    } else if (this.name === 'Owner') {
      this.nameKeys = this.appConfig.ownerNameKeys;
      this.maxGrids = this.appConfig.maxGrids.owners;
    }
  }


  controlAgentPercentField(obj): void {
    const formArray =  this.mainFormGroup.get(obj.gridId) as FormArray;
    const form = formArray.at(obj.gridIndex);
    const splitControl = form.get(this.getFieldIdByXmlTag(AgentInfoConstant.splitXmlTag));

    let percentage = splitControl?.value?.replace('%','') || 0
    if (this.gridSequences.length ===1) {
      percentage = 100
      splitControl?.disable();
    } else if (splitControl?.disabled && !this.disableForm) {
      splitControl?.enable();
    }


    this.gridPercentages[obj.gridUniqueId] = percentage;
    const percentString = this.getPercentageString(percentage);
    this.gridPercentageStrings[obj.gridUniqueId] = percentString

    splitControl?.setValue(percentString)
  }

  getNameForAccordion(obj): string {
    const formArray = this.mainFormGroup.get(obj.gridId) as FormArray;
    const formGroup = formArray.at(obj.gridIndex);
    const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
    if (obj.gridId.indexOf('TRUST') >= 0) {
      const trustKey = this.utilsService.getFieldId(gridData, this.nameKeys.trust);
      return formGroup.get(trustKey)?.value || '';
    } else if (obj.gridId.indexOf('CORP') >= 0) {
      const corpKey = this.utilsService.getFieldId(gridData, this.nameKeys.corp);
      return formGroup.get(corpKey)?.value || '';
    } else if (obj.gridId.indexOf('EXISTING_INSURANCE') >= 0) {
      const val = this.getNameforInforce(gridData, formGroup);
      return val || '';
    } else {
      const personfnKey = this.utilsService.getFieldId(gridData, this.nameKeys.person?.fn);
      const personmnKey = this.utilsService.getFieldId(gridData, this.nameKeys.person?.mn);
      const personlnKey = this.utilsService.getFieldId(gridData, this.nameKeys.person?.ln);
      const fn = formGroup.get(personfnKey)?.value || '';
      const mn = formGroup.get(personmnKey)?.value || '';
      const ln = formGroup.get(personlnKey)?.value || '';
      const mi = mn ? mn.charAt(0) + '. ' : '';
      return fn + ' ' + mi + ln;
    }
  }

  getNameforInforce(gridData, formGroup): string {
    let val = '';
    const companyNameKey = this.utilsService.getFieldId(gridData, this.nameKeys.companyName);
    const otherCompanyNameKey = this.utilsService.getFieldId(gridData, this.nameKeys.otherCompanyName);
    const faceAmountKey = this.utilsService.getFieldId(gridData, this.nameKeys.faceAmount);
    if (formGroup.get(companyNameKey).value && formGroup.get(companyNameKey).value.label === 'Company Not Listed') {
      val = formGroup.get(otherCompanyNameKey).value ? formGroup.get(otherCompanyNameKey).value : '';
    } else {
      val = formGroup.get(companyNameKey).value ? formGroup.get(companyNameKey).value.label : '';
    }
    // tslint:disable-next-line:max-line-length
    let faceAmount = formGroup.get(faceAmountKey).value ? formatCurrency(formGroup.get(faceAmountKey).value, 'en-US', '$') : '';
    const extCompany = val || '';
    faceAmount = faceAmount ? ' - ' + faceAmount : '';
    val = extCompany + faceAmount;
    return val;
  }

  getConditionalTrustQuestionsForBene(gridData): void {
    let relationshipQuest = false;
    this.conditionalQuestForTrust = [];
    const questionsArray = gridData.gridQuestions[0]?.reflexiveQuestionAL;
    for (const quest of questionsArray) {
      if (!relationshipQuest) {
        this.testamentaryQNameKeys.push(quest.name);
      }
      if (quest && quest.xmlTag === this.appConfig.testamentaryQuestionKey) {
        relationshipQuest = true;
        continue;
      }
      if (relationshipQuest) {
        this.conditionalQuestForTrust.push(quest);
      }
    }
  }

  accordionStatus(event: any) {
    const className = event?.elRef.nativeElement.className?.split(' ');
    for (const element of className) {
        if (/\d/.test(element)) {
          this.openAccordian[element?.slice(-1)] = true;
        }
    }
  }

  checkCIVFunc(obj): boolean {
    let flag = false;
    if(this.civInput){
      flag = this.civInput[1] === 'no' ? true : false;
    }
    this.displayCIVFields = flag;
    return flag;
  }

  getUpdatedObject(ansObj) {
    const newObj = {};
    for (const key in ansObj) {
      if (key && this.testamentaryQNameKeys.indexOf(key) >= 0) {
        if (this.sharePercentageNameKey.indexOf(key) >= 0) {
          newObj[key] = '';
        } else {
          newObj[key] = ansObj[key];
        }
      }
    }
    return newObj;
  }

  checkGridIsTrustWithTestamentary(gridAnsObj) {
    let trustGridTestamentary = false;
    for (const key in gridAnsObj) {
      if (key && gridAnsObj[key] != null && gridAnsObj[key].toLowerCase().indexOf('testamentary') >= 0) {
        trustGridTestamentary = true;
        break;
      }
    }
    return trustGridTestamentary;
  }

  enableDisableOwners() {
    if (this.showOwner && this.ownerAnswers) {
      this.disableOwnerForms(this.ownerAnswers);
    }
  }

  getObjWithoutPercentage(gridAnsObj) {
    const updatedObj = JSON.parse(JSON.stringify(gridAnsObj));
    for (const nameKeyForPercentage of this.sharePercentageNameKey) {
      if (gridAnsObj?.hasOwnProperty(nameKeyForPercentage)) {
        updatedObj[nameKeyForPercentage] = '';
      }
    }
    return updatedObj;
  }

  getObjWithUpdatedPercentage(newOwnerObj, gridAnsObj) {
    for (const nameKeyForPercentage of this.sharePercentageNameKey) {
      if (gridAnsObj?.hasOwnProperty(nameKeyForPercentage)) {
        newOwnerObj[nameKeyForPercentage] = gridAnsObj[nameKeyForPercentage];
      }
    }
    return newOwnerObj;
  }

  checkIfOwnerDataUpdated(ownerAnswers, gridAnswersArry) {
    const ownerObject = this.utilsService.getOwnerName(ownerAnswers, this.nameFieldnameKeys);
    let newOwnerObj = null;
    let gridInd = -1;
    for (const gridAnsObj of this.gridAnswers) {
      gridInd++;
      const gridOwnerObject = this.utilsService.getOwnerName(gridAnsObj, this.nameFieldnameKeys);
      const match = _.isEqual(ownerObject, gridOwnerObject);
      if (match) {
        // get percentage value from gridAns and update it in owner obj then remove obj from gridAnswers
        newOwnerObj = JSON.parse(JSON.stringify(ownerAnswers));
        newOwnerObj = this.getObjWithUpdatedPercentage(newOwnerObj, gridAnsObj);
        this.gridAnswers.splice(gridInd, 1);
        break;
      }
    }
    return newOwnerObj;
  }

  disableOwnerForms(answers, enableForm?) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < answers.length; i++) {
      const obj = this.gridSequences[i];
      const formArray =  this.mainFormGroup.get(obj.gridId) as FormArray;
      const formGroup = formArray.at(obj.gridIndex);
      if (enableForm) {
        formGroup.enable();
      } else if (this.name === 'Owner' && this.showOwner && !this.disableForm) {
        setTimeout(() => {
          formGroup.disable();
          this.civCheck();
          this.disableOwnerFields(formGroup);
        }, 200);
      } else if (this.name !== 'Owner') {
        this.disableBeneForm(formGroup);
      }
    }
  }

  disableOwnerFields(formGroup): void {
    for (const fieldId of this.ownerFieldDisableKeys) {
      if (formGroup?.get(fieldId)) {
        if (fieldId?.indexOf('CountryofCitizenship') > -1) {
          this.isInsuredUSCitizenCheck(formGroup, fieldId);
        }
      }
    }
  }

  isInsuredUSCitizenCheck(formGroup, fieldId): void {
    const isInsuredUSCitizen = this.userDetailService.getIsInsuredUSCitizen();
    if (isInsuredUSCitizen === 'Yes') {
      formGroup.get(fieldId)?.setValue({ label: 'United States of America', value: 'USA{47}United States of America'});
    } else if (!this.disableForm) {
      formGroup.get(fieldId)?.enable();
    }
  }

  disableBeneForm(formGroup: any): void {
    setTimeout(() => {
      if (this.type === 'BENE') {
        const gridData = this.gridQuestionsObj['PRIMARY-BENEFICIARY-CORPORATION_GRID']?.gridQuestions[0]?.reflexiveQuestionAL;
        const secondBeneInd  = this.utilsService.getFieldByXmlTag(gridData, '/XML/Client/AppDetails/SecondBeneInd');
        formGroup?.get(secondBeneInd)?.setValue('Yes{47}Yes');

        const secondBeneIndObj = gridData.filter(data => data.xmlTag === '/XML/Client/AppDetails/SecondBeneInd');
        const thirdBeneIndObj = secondBeneIndObj[0].reflexiveQuestionAL.filter(data => data.xmlTag === '/XML/Client/AppDetails/ThirdBeneInd');
        const thirdBeneIndFirstName = thirdBeneIndObj[0].reflexiveQuestionAL.filter(data => data.xmlTag === '/XML/Client/AppDetails/TrusteeBusinessFirstName3');
        if (this.ownerAnswers[0][thirdBeneIndFirstName[0].name] && this.ownerAnswers[0][thirdBeneIndFirstName] != '') {
          formGroup?.get(thirdBeneIndObj[0].fieldId)?.setValue('Yes{47}Yes');
        } else {
          formGroup?.get(thirdBeneIndObj[0].fieldId)?.setValue('No{47}No');
        }
      }
      formGroup.disable();
      if(this.gridSequences.length>1 && (this.beneShareEquallyRuleErr || !this.shareEqually)){
        this.gridSequences.forEach((obj)=>{
          const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
          const key = this.utilsService.getFieldId(gridData, this.percentKeys[0]);
          formGroup.get(key).enable();
        })
      }
    }, 50);
  }

  getStateValidationMsg(gridForm: any, gridData: any, wautStateFlag): any {
    if (wautStateFlag) {
      return ((this.userDetailService.getInsuredState() != (gridForm.get(gridData?.fieldId).value).value?.split('{')[0]) &&
      ((gridForm.get(gridData?.fieldId).value).value?.split('{')[0].indexOf('WA') > -1 || (gridForm.get(gridData?.fieldId).value).value?.split('{')[0].indexOf('UT') > -1));
    }
    return ((this.userDetailService.getInsuredState() != (gridForm.get(gridData?.fieldId).value).value?.split('{')[0]) &&
    ((gridForm.get(gridData?.fieldId).value).value?.split('{')[0].indexOf('WA') == -1 &&  (gridForm.get(gridData?.fieldId).value).value?.split('{')[0].indexOf('UT') == -1));
  }

  populateCIVInfo(): void {
    if (!(this.checkCIVFunc(this.gridSequences[0]) && this.showOwner)) {
      return;
    }

    this.getDriverLicenceFromProposedInfo();
    this.CIVOwnerIdentityType = this.civService.getCIVOwnerIdentityType();

    if (!this.CIVOwnerIdentityType) {
      return;
    }

    this.CIVIDNumber = this.civService.getCIVIDNumber();
    this.CIVExpirationDate = this.utilsService.formatDateToMMDDYYYY(this.civService.getCIVExpirationDate());
    this.CIVStateCountryOfIssuance = this.civService.getCIVStateCountryOfIssuance();

    const gridIDObj = this.civService.getGridIDObj(this.civQuestionsObj, this.CIVOwnerIdentityType);
    const gridExpirationDateObj = this.civService.getGridExpirationDateObj(this.civQuestionsObj, this.CIVOwnerIdentityType);
    const expObject = [];
    if (gridExpirationDateObj.length > 1) {
      let ind = 2;
      if (this.CIVOwnerIdentityType?.label?.indexOf('Government') > -1) {
        ind = 0;
      } else if (this.CIVOwnerIdentityType?.label?.indexOf('Passport') > -1) {
        ind = 1;
      }
      expObject.push(gridExpirationDateObj[ind]);
    }
    const gridDriverLicenseStateObj = this.civService.getGridDriverLicenseStateObj(this.civQuestionsObj, this.CIVOwnerIdentityType);

    const id = this.gridInstances[0]?.id;
    const control = 'controls';
    const form: FormGroup = this.mainFormGroup?.controls[id]?.[control][0];

    form.get(this.getFieldIdByXmlTag(CIVFieldsKeys[0]))?.setValue(this.CIVOwnerIdentityType);
    form.get(gridIDObj[0].fieldId)?.setValue(this.CIVIDNumber);
    form.get(expObject[0].fieldId)?.setValue(this.CIVExpirationDate);
    form.get(gridDriverLicenseStateObj[0].fieldId)?.setValue(this.CIVStateCountryOfIssuance);

    setTimeout(() => {
      form.get(this.getFieldIdByXmlTag(CIVFieldsKeys[0])).disable();
      form.get(gridIDObj[0].fieldId).disable();
      form.get(gridExpirationDateObj[0].fieldId).disable();
      form.get(gridDriverLicenseStateObj[0].fieldId).disable();
    }, 250);
  }

  getDriverLicenceFromProposedInfo(): void {
    const gridDLObj = this.civService.getGridDLObj(this.civQuestionsObj);
    const gridDLStateObj = this.civService.getGridDLStateObj(this.civQuestionsObj);

    const dlNumber = this.ownerAnswers[0][gridDLObj[0].name];
    const dlState = this.ownerAnswers[0][gridDLStateObj[0].name];
    if (!dlNumber || !dlState) {
      return;
    }
    const stateList = gridDLStateObj[0].questionOption;
    const state = stateList.filter(stateEle => stateEle.value === dlState);
    if (dlNumber) {
      this.CIVOwnerIdentityType = { value: 'Drivers License{47}Drivers License', label: 'Drivers License' };
      this.civService.setCIVOwnerIdentityType(this.CIVOwnerIdentityType, false);
      this.CIVIDNumber = dlNumber;
      this.civService.setCIVIDNumber(this.CIVIDNumber, false);
    }
    if (dlState) {
      this.CIVOwnerIdentityType = { value: 'Drivers License{47}Drivers License', label: 'Drivers License' };
      this.civService.setCIVOwnerIdentityType(this.CIVOwnerIdentityType, false);
      this.CIVStateCountryOfIssuance = {value: state[0].value, label: state[0].description};
      this.civService.setCIVStateCountryOfIssuance(this.CIVStateCountryOfIssuance, false);
    }
  }

  disableDeleteIcon(index): any {
    return (this.gridSequences.length <= 1) || (this.showOwner && this.ownerAnswers && index < this.ownerAnswers.length);
  }

  resetExistingInsuranceFormFields(data, gridForm, obj, _event): void {
    if (data.xmlTag === this.nameKeys.companyName) {
      this.companySelected[obj.gridIndex] = gridForm.controls[data.fieldId]?.value?.label;
      this.clipboardService.set('companySelected', this.companySelected);
      const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
      ExistingInsuranceInternalReplacementQ.forEach(value => {
        if (InternalReplacementCompanies.indexOf(this.companySelected[obj.gridIndex]) === -1) {
          const key = this.utilsService.getFieldId(gridData, value);
          gridForm.get(key)?.setValue('');
          gridForm.get(key)?.setErrors(null);
          gridForm.get(key)?.markAsUntouched();
        }
      });
    }
  }

  noRecordsFound(event, gridForm, fieldID): void {
    setTimeout(() => {
      const answer = 'Company Not Listed';
      if (event) {
        let obj = {};
        obj = { label: answer, value: 'Company Not Listed{47}Company Not Listed', attr: answer };
        gridForm.controls[fieldID]?.setValue(obj);
      }
    }, 1);
  }

  noRecordsSelected(event, gridForm, fieldID): void {
    const answer = gridForm.controls[fieldID].value;
    if (answer && answer.length < 0) {
      this.searchFieldError[fieldID] = event;
    }
  }

  onblurAutoComplete(val, gridForm, data, childData, obj?): void {
    setTimeout(() => {
      if (val) {
        const serarchValue = gridForm.controls[data.fieldId].value;
        let isSearchFound = true;
        if (serarchValue) {
           isSearchFound = this.alldropDownOptions[data.fieldId].some(val => val === serarchValue);
        }
        if (!isSearchFound){
          const answer = 'Company Not Listed';
          let obj = {};
          obj = { label: answer, value: 'Company Not Listed{47}Company Not Listed', attr: answer };
          gridForm.controls[data.fieldId]?.setValue(obj);
        } else {
          const otherInsuranceCompanyContol = gridForm.get(childData[0]?.fieldId);
          const otherInsuranceCompanyValue = otherInsuranceCompanyContol?.value;
          if (otherInsuranceCompanyValue) {
            otherInsuranceCompanyContol.setValue('');
            otherInsuranceCompanyContol.setErrors(null);
            otherInsuranceCompanyContol.markAsUntouched();
          }
        }
        const companyName = gridForm.controls[data.fieldId].value;
        this.companySelected[obj.gridIndex] = companyName.value?.split('{')[0];
        this.clipboardService.set('companySelected', this.companySelected);
      }
      if (val && this.searchFieldError[data.fieldId]) {
        gridForm.controls[data.fieldId].setErrors({ searchErr: true });
      }
      this.resetExistingInsuranceFormFields(data, gridForm, obj, val);
    }, 1);
  }

  setWritingAgent(isWritingAgent, gridIndex?) {
    this.writingAgentGridIdx = isWritingAgent ? gridIndex : -1
    this.gridSequences.forEach(obj => {
      const gridControl = this.mainFormGroup.get(obj.gridId) as FormArray;
      const gridFormGroup = gridControl.controls[obj.gridIndex] as FormGroup;
      const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
      const writingAgentFieldId = this.utilsService.getFieldByXmlTag(gridData, AgentInfoConstant.isWritingAgentQuesId);
      const radioControl = gridFormGroup.get(writingAgentFieldId);
      if (isWritingAgent && obj.gridIndex != gridIndex) {
        radioControl.setValue('No{47}No');
        radioControl.disable();
      } else if (!this.disableForm) {
        this.isWholesaler ? radioControl.disable() : radioControl.enable();
      }
    })
  }

  setWritingAgentForWholesaler(formControl: any, gridIndex: any) {
    if (this.isWholesaler) {
      formControl.setValue(gridIndex === 0 ? "Yes{47}Yes" : "No{47}No");
      formControl.disable();
    }
  }

  checkForWritingAgent() {
    this.gridSequences.forEach(obj => {
      const gridControl = this.mainFormGroup.get(obj.gridId) as FormArray;
      const gridFormGroup = gridControl.controls[obj.gridIndex] as FormGroup;
      const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
      const writingAgentFieldId = this.utilsService.getFieldByXmlTag(gridData, AgentInfoConstant.isWritingAgentQuesId);
      const radioControl = gridFormGroup.get(writingAgentFieldId);
      this.setWritingAgentForWholesaler(radioControl, obj.gridIndex);
      const isWritingAgent = radioControl?.value?.split('{')[0] === 'Yes';
      if (isWritingAgent) {
        this.setWritingAgent(true, obj.gridIndex)
      }
    })
  }

  checkForBonafideQuestion(): void {
    this.gridSequences.forEach(obj => {
      if (obj.gridId.indexOf('CORP') > -1) {
        const gridControl = this.mainFormGroup.get(obj.gridId) as FormArray;
        const gridFormGroup = gridControl.controls[obj.gridIndex] as FormGroup;
        const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
        const ownerAddressSameAsFieldId = this.utilsService.getFieldId(gridData[0]?.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS_SAME_AS);
        const ownerAddressSameAsValue = gridFormGroup.get(ownerAddressSameAsFieldId)?.value?.value?.split('{')[0];
        if (ownerAddressSameAsValue?.toLowerCase()?.indexOf('insured') > -1) {
          this.corpState = this.userDetailService.getResidentState();
        } else {
          const ownerStateFieldId = this.utilsService.getFieldId(gridData[0]?.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
          this.corpState = gridFormGroup.get(ownerStateFieldId)?.value?.value?.split('{')[0];
        }
      }
    });
  }

  showOwnersFirst(): void {
    let newGridAns = []
    this.gridAnswers.forEach(ans => {
      if (_.findIndex(this.ownerAnswers, e => this.compareGrids(e, ans)) === -1) {
        newGridAns.push(ans)
      } else {
        newGridAns.unshift(ans)
      }
    })
    this.gridAnswers = newGridAns
  }

  compareGrids(grid1, grid2): any {
    grid1 = this.getObjForComparison(grid1)
    grid2 = this.getObjForComparison(grid2)
    return _.isEqual(grid1, grid2)
  }

  getObjForComparison(gridAnsObj): void {
    const updatedObj = JSON.parse(JSON.stringify(gridAnsObj));
    for (const nameKeyForPercentage of this.sharePercentageNameKey) {
      if (gridAnsObj?.hasOwnProperty(nameKeyForPercentage)) {
        delete updatedObj[nameKeyForPercentage]
      }
    }
    for (const addressKey of this.addressFieldNameKeys){
      if (gridAnsObj?.hasOwnProperty(addressKey)) {
        delete updatedObj[addressKey]
      }
    }
    return updatedObj;
  }

  stripPercentageFields(gridAnsObj) {
    for (const nameKeyForPercentage of this.sharePercentageNameKey) {
      if (gridAnsObj?.hasOwnProperty(nameKeyForPercentage) && gridAnsObj[nameKeyForPercentage].indexOf('%')) {
        gridAnsObj[nameKeyForPercentage] = gridAnsObj[nameKeyForPercentage].replace('%','')
      }
    }
    return gridAnsObj
  }

  private controlPercentageFields(): void {
    this.gridPercentages = {};
    this.gridPercentageStrings = {};
    for (const obj of this.gridSequences) {
      if (this.name === 'Primary' || this.name === 'Contingent') {
        this.controlBenePercentageField(obj);
      } else if (this.name === 'Agent'){
        this.controlAgentPercentField(obj);
      }
    }
    this.getTotalPercentage();
  }

  private controlBenePercentageField(obj): void {
    const formArray =  this.mainFormGroup.get(obj.gridId) as FormArray;
    const form = formArray.at(obj.gridIndex);
    const gridData = this.gridQuestionsObj[obj.gridId].gridQuestions;
    const key = this.utilsService.getFieldId(gridData, this.percentKeys[0]);
    const gridCount = this.gridSequences.length;
    this.beneShareEquallyRuleErr = false;
    this.beneGridCount = gridCount;
    let percentage = null;

    if (this.shareEqually || this.hideAddLink || gridCount === 1){
      form.get(key).disable();
      percentage = 100 / gridCount;
      if(100 % gridCount != 0 && !this.disableForm){
        form.get(key).enable()
        this.beneShareEquallyRuleErr = true;
        percentage = percentage.toFixed(2);
      }
    } else {
      percentage = form.get(key)?.value?.replace('%','');
      if(!this.disableForm){
        form.get(key).enable();
      }
    }

    if(this.beneLoadFlag){
      const storedPercent = form.get(key)?.value?.replace('%','');
      if(storedPercent && storedPercent != "0"){
        percentage = storedPercent
      }
    }

    percentage = Number(percentage);

    this.gridPercentages[obj.gridUniqueId] = percentage;
    const percentString = this.getPercentageString(percentage);
    this.gridPercentageStrings[obj.gridUniqueId] = percentString

    form.get(key).setValue(percentString);

    if (percentString.indexOf('.') !== -1) {
      form.get(key).setErrors({decimalError: true});
    }
  }

  updatePercentageFromInput(gridUniqueId,formControl){
    this.gridPercentages[gridUniqueId] = formControl.value;
    this.gridPercentageStrings[gridUniqueId] = this.getPercentageString(formControl.value);
    this.getTotalPercentage()
    if (formControl.value.indexOf('.') !== -1) {
      formControl.setErrors({decimalError: true});
    }
  }

  private getPercentageString(percent): string {
    if(percent != null){
      return percent.toString() + '%';
    }else{
      return '';
    }
  }

  private getTotalPercentage(): void {
    let total = 0;
    let hasDecimals = false;
    Object.values(this.gridPercentages).forEach((percent:number|null)=>{
      total += Number(percent);
      if(percent % 1 > 0){
        hasDecimals = true;
      }
    })

    this.validPercentage = total === 100 && !hasDecimals;
    this.totalPercentage = Number(total.toFixed(2));
  }

  setAgentEmail(data, form): void {
    this.agentService.setAgentPrimaryEmail(form?.get(data.fieldId)?.value);
  }

  checkLicense(): void {
    if (!this.showOwner || ( this.civInput.length > 1 && this.civInput[1] === "yes" )){
      this.driverLicenseMatchErr = false;
      return;
    }

    const gridId = this.gridSequences[0]?.gridId;
    const control = 'controls';
    const gridForm: FormGroup = this.mainFormGroup?.controls[gridId]?.[control][0];

    const idTypeField = this.utilsService.getFieldId(this.gridQuestionsObj[gridId].gridQuestions, OwnerInfoConstant.IdentityType)
    const idTypeVal = gridForm.get(idTypeField).value.value

    if (idTypeVal != 'Drivers License{47}Drivers License'){
      this.driverLicenseMatchErr = false;
      return
    }

    const DLNumFieldId = this.utilsService.getFieldId(this.gridQuestionsObj[gridId].gridQuestions, OwnerInfoConstant.DriverLicenseNum)
    const DLStateFieldId = this.utilsService.getFieldId(this.gridQuestionsObj[gridId].gridQuestions, OwnerInfoConstant.DriverLicenseState)

    const driverLicenseNum = gridForm.get(DLNumFieldId).value
    const driverLicenseState = gridForm.get(DLStateFieldId).value.value

    if(!driverLicenseNum || !driverLicenseState){
      this.driverLicenseMatchErr = false;
      return;
    }

    if (driverLicenseNum === this.insuredDLInfo.number && driverLicenseState === this.insuredDLInfo.state){
      this.driverLicenseMatchErr = false
    } else {
      this.driverLicenseMatchErr = true
    }
  }

  navToProposedInsured(): void {
    const tabConfig = this.isWholesaler ? this.tabService.getTabInfoByTabName(TAB.PRIMARY_INSURED_WS) : this.tabService.getTabByTabName(TAB.PRIMARY_INSURED_MG);
    this.tabService.selectTab(tabConfig);
  }

  navToExistingIns(): void {
    const tabConfig = this.isWholesaler ? this.tabService.getTabInfoByTabName(TAB.EXISTING_INS_WS) : this.tabService.getTabByTabName(TAB.EXISTING_INS_MG);
    this.tabService.selectTab(tabConfig);
  }

  displayRemovePolicyWarning(display: boolean): void {
    this.removePolicyWarning = display;
  }
    
  private initializeQuestionNamesAndId() {
    if (this.type === 'AGENT') {
      this.agentBGAQuesName = this.getNameByXmlTag(AgentInfoConstant.secAgentBGAId);
      this.agentOtherBGAQuesName = this.getNameByXmlTag(AgentInfoConstant.secAgentOtherBGAId);
      this.agentOtherBGAQuesFieldId = this.getFieldByXmlTag(AgentInfoConstant.secAgentOtherBGAId);
      this.agentBGAQuesFieldId = this.getFieldByXmlTag(AgentInfoConstant.secAgentBGAId);
      this.agentFirmQuesFieldId = this.getFieldByXmlTag(AgentInfoConstant.secAgentFirmId);
      this.agentFirmQuesName = this.getNameByXmlTag(AgentInfoConstant.secAgentFirmId);
      this.agentOtherFirmQuesName = this.getNameByXmlTag(AgentInfoConstant.agentOtherFirm);
      this.agentOtherFirmQuesFieldId = this.getFieldByXmlTag(AgentInfoConstant.agentOtherFirm);
      this.agentSSNQuesName = this.getNameByXmlTag(AgentInfoConstant.ssnXmlTag);
      this.agentLastNameQuesName = this.getNameByXmlTag(AgentInfoConstant.agentLastNameQuesId);
    }
  }

  private getFieldByXmlTag(xmlTag: string) {
    return this.utilsService.getFieldByXmlTag(this.gridInstances[0]?.gridQuestions, xmlTag)
  }

  private getNameByXmlTag(xmlTag: string) {
    return this.utilsService.getNameByXmlTag(this.gridInstances[0]?.gridQuestions, xmlTag)
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      this.showLoader++;
      if (this.showLoader === 1) {
        this.loaderService.show();
      }
    } else {
      this.showLoader--;
      if (this.showLoader === 0) {
        this.loaderService.hide();
      }
    }
  }
}
