<section class="thank-you-page">

  <ng-container *ngIf="displayProgressOverlay">
    <app-progress-overlay [modalStateInput]="displayProgressOverlay" [messageToshow]="CONST.POLICY_SUBMISSION_LOADER_MESSAGE"></app-progress-overlay>
  </ng-container>

  <!-- element for success-icon -->
  <div class="success-icon" role="img" aria-label="action completed icon">
  </div>

  <!-- element for header and info -->
  <div class="info--container">

    <div *ngIf="thankyouPageType === 'consumerThankyouPage'">
      <h1 class="header-msg">{{messages.CONSUMER_HEADER}}</h1>
      <h2 class="info-text">{{messages.INFO_TEXT_1}}</h2>
      <h2 class="info-text">{{messages.INFO_TEXT_2}}</h2>
      <h2 class="info-text thankyou-info">{{messages.THANK_YOU}}</h2>
    </div>

    <div *ngIf="thankyouPageType === 'agentThankyouPage'">
      <h1 class="header-msg">{{messages.AGENT_HEADER}}</h1>
      <h2 class="info-text">{{messages.INFO_TEXT_1}}</h2>
      <h2 class="info-text submit-msg font-bold">{{messages.INFO_TEXT_2}}</h2>
      <h2 class="info-text thankyou-info font-bold">{{messages.THANK_YOU}}</h2>
    </div>

    <div *ngIf="thankyouPageType === 'declineToSign'">
      <h1 class="header-msg">{{messages.DECLINE_TO_SIGN_HEADER}}</h1>
      <h2 class="info-text">{{messages.INFO_TEXT_1}}</h2>
      <h2 class="info-text">{{messages.INFO_TEXT_2}}</h2>
      <h2 class="info-text thankyou-info">{{messages.INFO_TEXT_3}}</h2>
    </div>

    <div *ngIf="thankyouPageType === 'reviewAndSubmit'">
      <!-- show review and submit -->
      <ng-container *ngIf="!isPolicySubmitted">
        <h1 class="header-msg">{{messages.PAGE_HEADER}}</h1>
        <div *ngIf="errorInPolicyCreation" class="error-container">
          <lfg-alert-message type="error">{{CONST.GENERIC_ERROR}}</lfg-alert-message>
        </div>
        <h2 class="info-text">{{messages.INFO_TEXT_1}}</h2>
        <h2 class="info-text submit-msg font-bold">{{messages.INFO_TEXT_2}}</h2>
        <h2 class="info-text thankyou-info font-bold">{{messages.THANK_YOU}}</h2>
        <div class="page-btn">
          <button type="button" aria-label="Submit case" lfgDefaultButton (click)="submitCase()" [disabled]="!userAccessDetails?.submit">
            Submit Case
          </button>
        </div>
      </ng-container>

      <!-- show thank you if policy submitted -->
      <ng-container *ngIf="isPolicySubmitted">
        <h1 class="header-msg">{{submitMessages.AGENT_HEADER}}</h1>
        <h2 class="info-text">{{submitMessages.INFO_TEXT_1}}</h2>
        <h2 class="info-text submit-msg font-bold">{{submitMessages.INFO_TEXT_2}}</h2>
        <h2 class="info-text thankyou-info font-bold">{{submitMessages.THANK_YOU}}</h2>
        <div class="page-btn">
          <button type="button" aria-label="Close" lfgDefaultButton (click)="close()">
            Close
          </button>
        </div>
      </ng-container>

    </div>

  </div>

</section>
