// eTicket UI Application Confirguration Details will follow here
import { Injectable } from '@angular/core';

@Injectable()
export class ApplicationConfig {
  readonly fieldTypes = {
    HDR: 'None',
    TEXT: 'Text',
    CURRENCY: 'Currency',
    SSN: 'SSN',
    RADIO: 'Radio Button',
    DOB: 'Date',
    CHECKBOX: 'Checkbox',
    SELECT: 'Drop-down',
    NONEDITABLE: 'Noneditable',
    SEARCH: 'Search',
    EMAIL: 'Email',
    ZIP: 'Zip',
    ALPHANUMERIC: 'Alphanumeric',
    PHONE: 'Phone',
    USAADDRESS: 'USA Address',
    GRID: 'Grid',
    TIN: 'Text - Formatted Box',
    YESORNO: 'Yes/No',
    TEXTAREA: 'Text Area',
    HEIGHT: 'Height',
  };

  readonly errorMessages = {
    COVERAGE_AMOUNT_ERROR: 'Amount is not within limits for coverage selected',
    MIN_AMOUNT_ERROR: 'Minimum amount is $1.00',
    ABD_MIN_AMOUNT_ERROR: 'Minimum amount is $1,000.',
    ABD_MAX_AMOUNT_ERROR: 'Maximum amount is $500,000.',
    BASIC_DOB_ERROR: 'Age is not within limits for coverage',
    TERM_AGE_ERROR: 'Age is not within limits for selected duration and rate class.',
    NON_TERM_AGE_ERROR: 'Age is not within limits for selected rate class.',
    BER_ERROR: 'The Business EXEC Enhanced Surrender Value Rider requires that one of the Owners be a Corporation.',
    INCREASE_BY_PREMIUM_ERROR: 'Increase by Premium is not allowed with selected Riders.',
    LTC_AMOUNT_ERROR: 'LTC Specified Amount is outside rider guidelines.',
    RIDER_NOT_AVAILABLE_ERROR: 'Rider is not available because Insured(s) age is over 75.',
    ESVR_ERROR: 'The Individual EXEC Enhanced Surrender Value Rider requires that one of the Owners be an Individual or Trust and that no Owners are a Corporation.',
    DBO_ERROR: 'Increase by Premium is not allowed with selected Riders.',
    STATE_ERROR: 'State must be same as contract state.',
    ALL_STATE_AGENT_ERROR_MESSAGE: 'The Allstate Agent ID must be between 5 and 10 characters.',
    I_ATTEST_ERROR_MESSAGE: 'The Agent and Case Manager emails can only be the same if you attest that you do not have a case manager by selecting the I Attest button.',
    KNOW_ENGLISH_ERROR_MESSAGE: 'Important: If the client does not read or speak English, an electronic ticket cannot be completed and submitted to Lincoln. Please work with your wholesaling team for an alternative solution.',
    I_ATTEST_ALL_STATE_ERROR_MESSAGE: 'The Agent and In Office Support/Staff emails can only be the same if you attest that you do not have an In Office Support/Staff by selecting the I Attest button.'
  };

  readonly customAttr = {
    Text: 'text',
    Currency: 'currency',
    Alphanumeric: 'alphaNumeric',
    Email: 'email',
    Numeric: 'numbersOnly',
  };

  readonly caseInfoDisableFieldKey = [
    '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
  ];

  readonly primaryInsuredInfoDisableFieldKey = [
    '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
    '/XML/Client/CRMDetails/FirstName',
    '/XML/Client/CRMDetails/MiddleName',
    '/XML/Client/CRMDetails/LastName',
    '/XML/Client/CRMDetails/Suffix',
    '/XML/Client/CRMDetails/DOB',
    '/XML/Client/CRMDetails/Gender'
  ];

  // primary keys
  readonly primaryBeneDisplayGridKeys = [
    '/XML/ApplicationDetail/OwnerSameAsBene',
    '/XML/ApplicationDetail/AdditionalBeneInd'
  ];
  readonly benePrimaryTypeKeys = [
    '/XML/Client/CRMDetails/ClientType'
  ];

  readonly primaryPercentKeys = [
    '/XML/Client/AppDetails/PctSplit'
  ];

  readonly ownerNameKeys = {
    trust: '/XML/Client/CRMDetails/LastName',
    corp: '/XML/Client/CRMDetails/LastName',
    person: {
      fn: '/XML/Client/CRMDetails/FirstName',
      mn: '/XML/Client/CRMDetails/MiddleName',
      ln: '/XML/Client/CRMDetails/LastName',
    }
  };

  readonly beneKeys = {
    primaryShareEqually: '/XML/ApplicationDetail/BeneShareEqually',
    primaryHideLink: '/XML/ApplicationDetail/AdditionalBeneInd',
    contingentShareEqually: '/XML/ApplicationDetail/ContBeneShareEqually',
    contingentHideLink: '/XML/ApplicationDetail/AdditionalContingentBeneficiariesInd',
    ownerSameAsBene: '/XML/ApplicationDetail/OwnerSameAsBeneValue',
    addressSameAsKey: '/XML/Client/AppDetails/BeneAddressSameAs'
  };

  readonly primaryNameKeys = {
    trust: '/XML/Client/CRMDetails/LastName',
    corp: '/XML/Client/CRMDetails/LastName',
    person: {
      fn: '/XML/Client/CRMDetails/FirstName',
      mn: '/XML/Client/CRMDetails/MiddleName',
      ln: '/XML/Client/CRMDetails/LastName',
    },
    freeform: '/XML/Client/CRMDetails/LastName',
  };
  readonly testamentaryQuestionKey = '/XML/Client/AppDetails/RelationshipToInsuredTrust';

  // contingent keys
  readonly contingentBeneDisplayGridKeys = [
    '/XML/ApplicationDetail/ContingentBeneInd'
  ];
  readonly beneContingentTypeKeys = [
    '/XML/Client/CRMDetails/ClientType'
  ];

  readonly contingentPercentKeys = [
    '/XML/Client/AppDetails/PctSplit'
  ];

  readonly contingentNameKeys = {
    trust: '/XML/Client/CRMDetails/LastName',
    corp: '/XML/Client/CRMDetails/LastName',
    person: {
      fn: '/XML/Client/CRMDetails/FirstName',
      mn: '/XML/Client/CRMDetails/MiddleName',
      ln: '/XML/Client/CRMDetails/LastName',
    },
    freeform: '/XML/Client/CRMDetails/LastName',
  };

  readonly beneRelationshipKeys = [
    '/XML/Client/AppDetails/RelationshipToInsuredOwner',
    '/XML/Client/AppDetails/RelationshipToInsuredTrust',
    '/XML/Client/AppDetails/RelationshipToInsuredEntity'
  ];

  readonly inforceKeys = {
    companyName: '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/LapseCompanyName',
    otherCompanyName: '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/CompanyNotListedName',
    faceAmount: '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/LapsePolicyAmount'
  };

  readonly maxGrids = {
    owners: 4,
    primaryBene: 10,
    contigentBene: 10,
    inforceIns: 10,
    agent: 4
  };

  readonly inlineErrorWords = ['what', 'when', 'how', 'was', 'please', 'enter', 'by', 'who', 'will', 'where', 'provide',
                               'details', 'other', 'select', 'type', 'the existing policy or contract', 'amount of years for the surrender'];

  readonly dropdownWithCheckboxConfig = {
    allSelect: false,
    allSelectProp: {
      label: 'All service levels',
      value: 'All service levels',
      checked: false
    },
    filter: false,
    placeHolderText: 'Please select an option'
  };



}
