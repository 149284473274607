import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-illustration-page',
  templateUrl: './illustration-page.component.html',
  styleUrls: ['./illustration-page.component.scss']
})
export class IllustrationPageComponent implements OnInit {

  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() tabChange: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.handleTabChange(0);
  }

  emitFormStatus(obj){
    this.formStatus.emit(obj);
  }

  handleTabChange(idx): void {
    const tabs = ['basic','policy'];
    this.tabChange.emit(tabs[idx]);
  }

}
