<section class="dca-allocation-page page-content-container">

  <div class="questions--container">
    <h1>
      Fund Allocation
    </h1>
    <div class="loader-container" *ngIf="showLoader">
      <lfg-loader></lfg-loader>
    </div>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <lfg-alert-message *ngIf="totalFundPercentage != 100 && totalFundPercentage != 0"
      type="error">{{fundError}}</lfg-alert-message>
    <lfg-alert-message *ngIf="tier3PercentageError" type="error">In order to maintain the Value Protection Rider, for
      any allocations elected to the Tier 3 classified funds, at least 20% must be allocated to the Tier 1 classified
      funds.</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="fundAllocationForm" id="fundAllocationForm" method="post"
        *ngIf="fundAllocationData && fundAllocationData.length > 0">

        <ng-container class="form-control-parent" *ngFor="let data of fundAllocationData; let ind = index;">

          <div class="questions-header"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.questionText.split(' ')[0] != 'Tier' && data.questionText.indexOf('Dollar Cost Averaging') === -1 && data.questionText.indexOf('Transfer Authorization') === -1 && data.notes !== 'SEPARATE_CHECKBOX'"
            [innerHTML]="data.questionText">
          </div>

          <!-- Funds table -->
          <div class="funds-table-container" *ngIf="ind == 1">
            <div class="total-percent">
              <span>TOTAL: <span
                  [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
              <span class="color-blue" *ngIf="!expandTiers" (click)="expandCollapseTiers()">Expand all tiers</span>
              <span class="color-blue" *ngIf="expandTiers" (click)="expandCollapseTiers()">Collapse all tiers</span>
            </div>
          </div>
          <div class="funds-table funds-table-subheader"
            *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-subheader'">
            <div class="table-column">{{data.questionText | uppercase}} </div>
          </div>
          <div class="funds-table funds-table-subheader fund-table-label"
            *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-label'">
            <div class="table-column">{{data.questionText}} </div>
          </div>
        </ng-container>

        <lfg-accordion [autoScroll]="true">
          <lfg-panel style="display: block" [opened]="status[1]" (click)="toggleAccordian(!status[1] ? false : true, 1, $event)" class="" title="Tier 1">
            <span class="accordion__sub-title-hook" [attr.tabindex]="(i > 0) ? 0 : -1">
              <span *ngIf="!status[1]" class="funds-table-container">
                <span class="tier-percentage">
                 <span>{{getTier1Percentage()}}%</span>
                </span>
              </span>
              <span role="button" class="chevron-icon chevron-icon--color" aria-label="chevron">
                <i *ngIf="!status[1]" class="fas fa-chevron-down" aria-hidden="true"></i>
              </span>
              <span role="button" class="chevron-icon chevron-icon--color" aria-label="chevron">
                <i *ngIf="status[1]" class="fas fa-chevron-up" aria-hidden="true"></i>
              </span>
            </span>
            <ng-container class="form-control-parent" *ngFor="let data of fundAllocationData; let ind = index;">
              <ng-container *ngIf="data.controlTypeDesc === 'Fund Allocation' && data.notes?.indexOf('Tier 1') > - 1">

                <div class="funds-table-container" *ngIf="data.questionText === 'Tier 1'">
                  <div class="funds-table funds-table-header">
                    <div class="table-column col-1">FUNDS</div>
                    <div class="table-column col-2">INPUT %</div>
                  </div>
                </div>
                <div class="funds-table funds-table-row">
                  <div class="table-column col-1 funds-table-subheader fund-table-label">{{data.questionText}} </div>
                </div>
                <div class="funds-table funds-table-row" *ngFor="let option of data.questionOption">
                  <div class="table-column col-1">{{option.description}} </div>
                  <div class="table-column col-2">
                    <label for="{{option.fundcode}}" id="fund-field-label-{{option.fundcode}}">
                      <span class="visually-hidden">Label for the fund input</span>
                      <input lfgInput name="{{ displayTextLabel(data) }}" aria-live="off" id="{{option.fundcode}}" type='text'
                        formControlName='{{ option.fundcode }}' autocomplete="off"
                        (input)="(option.fundcode == '105' || option.fundcode == '876') ? checkAccountToDCAfrom() : null"
                        appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3"
                        [required]="data.required == 'true'">
                    </label>
                  </div>
                </div>

                <div class="funds-table funds-table-row table-tier-percentage" *ngIf="data.questionText !== 'Tier 1'">
                  <div class="table-column col-1 total-percent">
                    <span>Total </span>
                  </div>
                  <div class="table-column col-2 total-percent">
                    <span><strong>{{getTier1Percentage()}}%</strong></span>
                  </div>
                </div>

              </ng-container>
            </ng-container>
          </lfg-panel>

          <lfg-panel style="display: block" [opened]="status[2]" (click)="toggleAccordian(!status[2] ? false : true, 2, $event)" class="" title="Tier 2">
            <span class="accordion__sub-title-hook" [attr.tabindex]="(i > 0) ? 0 : -1">
              <span *ngIf="!status[2]" class="funds-table-container">
                <span class="tier-percentage">
                 <span>{{getTier2Percentage()}}%</span>
                </span>
              </span>
              <span role="button" class="chevron-icon chevron-icon--color" aria-label="chevron">
                <i *ngIf="!status[2]" class="fas fa-chevron-down" aria-hidden="true"></i>
              </span>
              <span role="button" class="chevron-icon chevron-icon--color" aria-label="chevron">
                <i *ngIf="status[2]" class="fas fa-chevron-up" aria-hidden="true"></i>
              </span>
            </span>
            <ng-container class="form-control-parent" *ngFor="let data of fundAllocationData; let ind = index;">
              <ng-container
                *ngIf="data.questionText === 'Tier 2' || (data.controlTypeDesc === 'Fund Allocation' && data.notes?.indexOf('Tier 2') > - 1 && data.questionText != 'Tier 1')">
                <div class="funds-table-container" *ngIf="data.questionText === 'Tier 2'">
                  <div class="funds-table funds-table-header">
                    <div class="table-column col-1">FUNDS</div>
                    <div class="table-column col-2">INPUT %</div>
                  </div>
                </div>
                <div class="funds-table funds-table-row">
                  <div class="table-column col-1 funds-table-subheader fund-table-label">{{data.questionText}} </div>
                </div>
                <div class="funds-table funds-table-row" *ngFor="let option of data.questionOption">
                  <div class="table-column col-1">{{option.description}} </div>
                  <div class="table-column col-2">
                    <label for="{{option.fundcode}}" id="fund-field-label-{{option.fundcode}}">
                      <span class="visually-hidden">Label for the fund input</span>
                      <input lfgInput name="{{ displayTextLabel(data) }}" aria-live="off" id="{{option.fundcode}}"
                      type='text' formControlName='{{ option.fundcode }}' autocomplete="off"
                      (input)="(option.fundcode == '105' || option.fundcode == '876') ? checkAccountToDCAfrom() : null"
                      appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3"
                      [required]="data.required == 'true'">
                    </label>
                  </div>
                </div>

                <div class="funds-table funds-table-row table-tier-percentage" *ngIf="data.notes === 'Tier 2 - C'">
                  <div class="table-column col-1 total-percent">
                    <span>Total </span>
                  </div>
                  <div class="table-column col-2 total-percent">
                    <span><strong>{{getTier2Percentage()}}%</strong></span>
                  </div>
                </div>

              </ng-container>
            </ng-container>
          </lfg-panel>

          <lfg-panel style="display: block" [opened]="status[3]" (click)="toggleAccordian(!status[3] ? false : true, 3, $event)" class="" title="Tier 3">
            <span class="accordion__sub-title-hook" [attr.tabindex]="(i > 0) ? 0 : -1">
              <span *ngIf="!status[3]" class="funds-table-container">
                <span class="tier-percentage">
                 <span>{{getTier3Percentage()}}%</span>
                </span>
              </span>
              <span role="button" class="chevron-icon chevron-icon--color" aria-label="chevron">
                <i *ngIf="!status[3]" class="fas fa-chevron-down" aria-hidden="true"></i>
              </span>
              <span role="button" class="chevron-icon chevron-icon--color" aria-label="chevron">
                <i *ngIf="status[3]" class="fas fa-chevron-up" aria-hidden="true"></i>
              </span>
            </span>
            <ng-container class="form-control-parent" *ngFor="let data of fundAllocationData; let ind = index;">
              <ng-container
                *ngIf="data.questionText === 'Tier 3' || (data.controlTypeDesc === 'Fund Allocation' && data.notes?.indexOf('Tier 3') > - 1)  && data.questionText != 'Tier 1'">
                <div class="funds-table-container" *ngIf="data.questionText === 'Tier 3'">
                  <div class="funds-table funds-table-header">
                    <div class="table-column col-1">FUNDS</div>
                    <div class="table-column col-2">INPUT %</div>
                  </div>
                </div>
                <div class="funds-table funds-table-row">
                  <div class="table-column col-1 funds-table-subheader fund-table-label">{{data.questionText}} </div>
                </div>
                <div class="funds-table funds-table-row" *ngFor="let option of data.questionOption">
                  <div class="table-column col-1">{{option.description}} </div>
                  <div class="table-column col-2">
                    <label for="{{option.fundcode}}" id="fund-field-label-{{option.fundcode}}">
                      <span class="visually-hidden">Label for the fund input</span>
                      <input lfgInput name="{{ displayTextLabel(data) }}" aria-live="off" id="{{option.fundcode}}"
                      type='text' formControlName='{{ option.fundcode }}' autocomplete="off"
                      (input)="(option.fundcode == '105' || option.fundcode == '876') ? checkAccountToDCAfrom() : null"
                      appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3"
                      [required]="data.required == 'true'">
                    </label>
                  </div>
                </div>

                <div class="funds-table funds-table-row table-tier-percentage" *ngIf="data.notes === 'Tier 3 - E'">
                  <div class="table-column col-1 total-percent">
                    <span>Total </span>
                  </div>
                  <div class="table-column col-2 total-percent">
                    <span><strong>{{getTier3Percentage()}}%</strong></span>
                  </div>
                </div>

              </ng-container>
            </ng-container>
          </lfg-panel>

        </lfg-accordion>

          <ng-container class="form-control-parent" *ngFor="let data of fundAllocationData; let ind = index;">

            <div class="total-percent percent-bottom" *ngIf="data.xmlTag == '/XML/FundHolder/DCAFundSelect' && ind == 13">
                <span>TOTAL: <span
                    [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
                <span class="color-blue" *ngIf="!expandTiers" (click)="expandCollapseTiers()">Expand all tiers</span>
                <span class="color-blue" *ngIf="expandTiers" (click)="expandCollapseTiers()">Collapse all tiers</span>
            </div>

            <div class="section-header"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Header'">
              <div>
                <p><i class="fas fa-list-alt" aria-hidden="true"></i><span>{{ data.questionText | uppercase}}</span></p>
              </div>
              <div class="btn-reset-container"> <a class="btn-link btn-reset"
                  [ngClass]="{'btn--link-disabled': disableForm}" id="resetBtn" type="button" (click)="resetForm()">
                  <i class="fas fa-undo reset-icon" aria-hidden="true"></i> Reset all selections </a></div>
            </div>

            <div class="section-divider"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
            </div>

            <div class="questions-header"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.questionText.split(' ')[0] != 'Tier' && data.questionText.indexOf('Important limitation') === -1 && data.questionText.indexOf('Select Premium Allocation and a Percentage') === -1 && data.notes !== 'SEPARATE_CHECKBOX'"
              [innerHTML]="data.questionText">
            </div>

            <!-- Form element for text  -->
            <div class="form--control" aria-live="assertive"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('FundSelect') == -1)">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addFundCorrectPlaceHolder(data, 'placeHolder') }}"
                  name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}'
                  autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols
                  [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength"
                  [required]="data.required == 'true'">
                <lfg-error
                  *ngIf="fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid"
                  message="{{ getErrorMsg(data, fundAllocationForm.get(data.fieldId)) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for radio question -->
            <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO">
              <span class="lfg-radio-section"
              [ngClass]="{'radio--err': (fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid)}">
                <p class="question-radio_text">{{ data.questionText }}</p>
                <div class="radio-btn-rect custom-radio-button">
                  <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}"
                    type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}"
                    value="{{ data.questionOption[0].value }}" required="{{data.required}}"
                    (change)="onFundRadioChange(data)" />
                  <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                  <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}"
                    type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}"
                    value="{{ data.questionOption[1].value }}" required="{{data.required}}"
                    (change)="onFundRadioChange(data)">
                  <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                </div>
              </span>
              <lfg-error
                *ngIf="fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid "
                message="{{ getErrorMsg(data, fundAllocationForm.get(data.fieldId)) }}"></lfg-error>
            </div>

            <!-- Form element for Drop Down -->
            <div class="form--control form-dropDown" aria-live="assertive"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}"
                [options]="alldropDownOptions[data.fieldId]"
                placeholder="{{ addFundCorrectPlaceHolder(data, 'placeHolder') }}"
                (selectionChanged)="handleFundSelectionChange(data, ind)" [required]="data.required == 'true'"
                [ngClass]="{'dropdown--err':(fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid)}">
              </lfg-dropdown>
              <lfg-error
                *ngIf="fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid"
                message="Please select a valid option"></lfg-error>
            </div>

            <!-- Form element for check box  -->
            <div class="form--control form--control__full-width"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.notes !== 'SEPARATE_CHECKBOX'">
              <label class="wrap wrap-check">{{data.questionText }}</label>
            </div>
            <div class="form--control form--control__full-width"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.notes !== 'SEPARATE_CHECKBOX'">
              <div class="lfg-checkbox">
                <input id="{{ data.questionOption[0].description }}" type="checkbox" value="checked"
                  name="{{ data.questionOption[0].description }}" formControlName='{{ data.fieldId }}'
                  [required]="data.required == 'true'"
                  (change)="onCheckboxChange(data, data.questionOption[0].description)" #myselfEle>
                <label class="wrap wrap-check"
                  for="{{ data.questionOption[0].description }}">{{data.questionOption[0].description}}
                  &nbsp;&nbsp;&nbsp;</label>
                <input id="{{ data.questionOption[1].description }}" type="checkbox" value="checked"
                  name="{{ data.questionOption[1].description }}" formControlName='{{ data.fieldId }}'
                  [required]="data.required == 'true'"
                  (change)="onCheckboxChange(data, data.questionOption[1].description)" #registered>
                <label class="wrap wrap-check" [ngStyle]="{'margin-top': '10px'}"
                  for="{{ data.questionOption[1].description }}">{{data.questionOption[1].description}}</label>
              </div>
            </div>

            <!-- Form element for separate checkboxes -->

            <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.notes == 'SEPARATE_CHECKBOX'">
              <label class="wrap wrap-check">{{data.questionText }}</label>
            </div>

            <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX  && data.notes == 'SEPARATE_CHECKBOX'">
              <div class="lfg-checkbox">
                <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data, $event)">
                <label class="wrap wrap-check" for="{{ data.fieldId }}" >{{data.questionText}}</label>
              </div>
            </div>

            <!-- Question Info -->
            <div class="question-info" aria-live="assertive"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Question Alert -->
            <div class="question-info" aria-live="assertive"
              *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Alert'">
              <lfg-alert-message type="error">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Nestion question Level 1 -->
            <div *ngIf="data.hasReflexive == 'true' && fundAllocationForm.get(data.fieldId).value as parentAnswer"
              [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
              <ng-container
                *ngFor="let fundAllocationChild1 of data.reflexiveQuestionAL; index as fundAllocationChild1ix">
                <ng-container *ngIf="nestedQuestionCheckFundInfo(data, fundAllocationChild1, parentAnswer)">

                  <!-- Question Info -->
                  <div class="question-info" aria-live="assertive"
                    *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.HDR && fundAllocationChild1.subText == 'Information'">
                    <lfg-alert-message type="info">{{ fundAllocationChild1.questionText }}</lfg-alert-message>
                  </div>

                  <!-- Question alert -->
                  <div class="question-info" aria-live="assertive"
                    *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.HDR && fundAllocationChild1.subText == 'Alert'">
                    <lfg-alert-message type="warning">{{ fundAllocationChild1.questionText }}</lfg-alert-message>
                  </div>

                  <!-- Form element for text  -->
                  <div class="form--control" aria-live="assertive"
                    *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.TEXT">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(fundAllocationChild1) }}"
                        placeholder="{{ addFundCorrectPlaceHolder(fundAllocationChild1, 'placeHolder') }}" type='text'
                        formControlName='{{ fundAllocationChild1.fieldId }}' autocomplete="off" name=""
                        appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly"
                        [maxlength]="fundAllocationChild1.maxLength"
                        [required]="fundAllocationChild1.required == 'true'">
                      <lfg-error
                        *ngIf="fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).errors?.percentError"
                        message="Split must be equal to 100%"></lfg-error>
                      <lfg-error
                        *ngIf="fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).errors?.textError"
                        message="Please enter a valid {{fundAllocationChild1.questionText | lowercase}}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for Drop Down -->
                  <div class="form--control form-dropDown" aria-live="assertive"
                    *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.SELECT "
                    [ngClass]="{'drop-down-medium': fundAllocationChild1.questionText.length > 20}">
                    <lfg-dropdown formControlName="{{ fundAllocationChild1.fieldId }}"
                      id="{{displayIdCreation(fundAllocationChild1)}}_{{fundAllocationChild1ix}}"
                      [options]="alldropDownOptions[fundAllocationChild1.fieldId]"
                      placeholder="{{ addFundCorrectPlaceHolder(fundAllocationChild1, 'placeHolder') }}"
                      (selectionChanged)="handleFundSelectionChange(fundAllocationChild1, fundAllocationChild1ix)"
                      [required]="fundAllocationChild1.required == 'true'"
                      [ngClass]="{'dropdown--err':(fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).invalid)}">
                    </lfg-dropdown>
                    <lfg-error
                      *ngIf="fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).invalid"
                      message="Please select a valid option"></lfg-error>
                  </div>

                  <!-- Form element for radio question -->
                  <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom"
                    *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.RADIO">
                    <span class="lfg-radio-section"
                    [ngClass]="{'radio--err': (fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).invalid)}">
                      <p class="question-radio_text">{{ fundAllocationChild1.questionText }}</p>
                      <div class="radio-btn-rect custom-radio-button">
                        <input [attr.aria-label]="fundAllocationChild1.questionOption[0].description" tabindex="0"
                          id="yes_{{ fundAllocationChild1.fieldId }}" type="radio"
                          formControlName="{{ fundAllocationChild1.fieldId }}" name="{{ fundAllocationChild1.fieldId }}"
                          value="{{ fundAllocationChild1.questionOption[0].value }}"
                          required="{{fundAllocationChild1.required}}"
                          (change)="onFundRadioChange(fundAllocationChild1)" />
                        <label for="yes_{{ fundAllocationChild1.fieldId }}">{{
                          fundAllocationChild1.questionOption[0].description }}</label>
                        <input [attr.aria-label]="fundAllocationChild1.questionOption[1].description" tabindex="0"
                          id="no_{{ fundAllocationChild1.fieldId }}" type="radio"
                          formControlName="{{ fundAllocationChild1.fieldId }}" name="{{ fundAllocationChild1.fieldId }}"
                          value="{{ fundAllocationChild1.questionOption[1].value }}"
                          required="{{fundAllocationChild1.required}}"
                          (change)="onFundRadioChange(fundAllocationChild1)">
                        <label for="no_{{ fundAllocationChild1.fieldId }}">{{
                          fundAllocationChild1.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error
                      *ngIf="fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).invalid "
                      message="{{ getErrorMsg(fundAllocationChild1, fundAllocationForm.get(fundAllocationChild1.fieldId)) }}"></lfg-error>
                  </div>

                  <lfg-alert-message
                    *ngIf="fundAllocationChild1.xmlTag == '/XML/ApplicationDetail/DCAFromFund' && accountToDCAfromError"
                    type="error">DCA from and to the same Account is not allowed.</lfg-alert-message>

                  <!-- Nestion question Level 2 -->
                  <div
                    *ngIf="fundAllocationChild1.hasReflexive == 'true' && fundAllocationForm.get(fundAllocationChild1.fieldId).value as parentAnswer"
                    class="nested-ques-level">
                    <ng-container
                      *ngFor="let fundAllocationChild2 of fundAllocationChild1.reflexiveQuestionAL; index as fundAllocationChild2ix">
                      <ng-container
                        *ngIf="nestedQuestionCheckFundInfo(fundAllocationChild1, fundAllocationChild2, parentAnswer)">

                        <!-- Form element for Drop Down -->
                        <div class="form--control form-dropDown" aria-live="assertive"
                          *ngIf="fundAllocationChild2.controlTypeDesc == appConfig.fieldTypes.SELECT "
                          [ngClass]="{'drop-down-medium': fundAllocationChild2.questionText.length > 24}">
                          <lfg-dropdown formControlName="{{ fundAllocationChild2.fieldId }}"
                            id="{{displayIdCreation(fundAllocationChild2)}}_{{fundAllocationChild2ix}}"
                            [options]="alldropDownOptions[fundAllocationChild2.fieldId]"
                            placeholder="{{ addFundCorrectPlaceHolder(fundAllocationChild2, 'placeHolder') }}"
                            (selectionChanged)="handleFundSelectionChange(fundAllocationChild2, fundAllocationChild2ix)"
                            [required]="fundAllocationChild2.required == 'true'"
                            [ngClass]="{'dropdown--err':(fundAllocationForm.get(fundAllocationChild2.fieldId).touched && fundAllocationForm.get(fundAllocationChild2.fieldId).invalid)}">
                          </lfg-dropdown>
                          <lfg-error
                            *ngIf="fundAllocationForm.get(fundAllocationChild2.fieldId).touched && fundAllocationForm.get(fundAllocationChild2.fieldId).invalid"
                            message="Please select a valid option"></lfg-error>
                        </div>

                      </ng-container>
                    </ng-container>
                  </div>

                </ng-container>
              </ng-container>
            </div>
            <div class="section-divider" *ngIf="data.xmlTag == '/XML/ApplicationDetail/DCAIndicator'"></div>
          </ng-container>

      </form>
    </section>
  </div>
</section>
