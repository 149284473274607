import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { AgentThankYou, CaseCompletionThankYou, ConsumerThankYou, DeclineToSign, Message, ReviewAndSubmit } from 'src/config/constants';
import { FunctionalityConfig } from 'src/config/delegationFunctionality.config';

@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.scss']
})
export class ThankyouPageComponent implements OnInit {

  @Input() thankyouPageType: string;
  @Input() isPolicySubmitted: boolean;
  @Input() userAccessDetails: FunctionalityConfig;
  @Output() policySubmitted: EventEmitter<any> = new EventEmitter();

  errorInPolicyCreation = false;
  displayProgressOverlay = false;
  CONST = Message;
  windowRef: any = window;
  submitMessages = CaseCompletionThankYou;

  messages: any;
  constructor(private pageLoadService: DefaultPageload, private router: Router) {
    // add code here
  }

  ngOnInit(): void {
    this.checkThankyouPageType();
    this.pageLoadService.logPageLoad();
  }

  private checkThankyouPageType(): void {
    switch (this.thankyouPageType) {
      case 'agentThankyouPage':
        this.messages = AgentThankYou;
        break;
      case 'consumerThankyouPage':
        this.messages = ConsumerThankYou;
        break;
      case 'declineToSign':
        this.messages = DeclineToSign;
        break;
      case 'reviewAndSubmit':
        this.messages = ReviewAndSubmit;
        break;
    }
  }

  submitCase(): void {
    this.displayProgressOverlay = true;
    this.errorInPolicyCreation = false;
    this.pageLoadService.createPolicy().subscribe((response) => {
      if (response.responseStatus === 'SUCCESS' && response.data) {
        this.policySubmitted.emit('yes');
        this.isPolicySubmitted = true;
      } else {
        this.errorInPolicyCreation = true;
      }
    }, (_err) => {
      this.errorInPolicyCreation = true;
    }).add(() => {
      this.displayProgressOverlay = false;
    });
  }

  close(): any {
    const url = window.location.href;
    const replaceStr = url?.split('#')[1];
    const eticketURL = url?.replace('lincolnlifeeappportal', 'lincolnlifeeticketportal').replace(replaceStr, '/dashboard');
    this.windowRef.location.href = encodeURI(eticketURL);
  }

}
