 <!-- Main content -->
 <main role="main" class="no-margin-top">
  <!-- Breadcrumbs -->
  <!-- Banner Component -->
   <div class="u-global-container">
    <article class="main-content main-content--full" data-event-type="mclink">
      <section class="content-area">
        <router-outlet></router-outlet>
      </section>
    </article>
  </div>
</main>
