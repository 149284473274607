<section class="illustration-page page-content-container">
  <h1>Create Illustration</h1>
  <lfg-tabs (selectionChange)="handleTabChange($event.tabIndex)">
    <tab>
      <tab-title>Basic</tab-title>
      <tab-content>
        <ng-container class="form-control-parent">
          <app-illustration-basic-info (formStatus)="emitFormStatus($event)"></app-illustration-basic-info>
        </ng-container>
      </tab-content>
    </tab>
    <tab>
      <tab-title>Policy</tab-title>
      <tab-content>
        <app-illustration-policy-info (formStatus)="emitFormStatus($event)"></app-illustration-policy-info>
      </tab-content>
    </tab>
  </lfg-tabs>
</section>
