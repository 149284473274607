import { TAB } from './sideNav.config';

export const CaseInfoConstant = {
  stateQuesid: '/XML/ApplicationDetail/ContractState',
  productTypeQuestId:
    '/XML/ApplicationDetail/ProductType',
  ageQuestId:
    '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
  dobQuestionId: '/XML/Client/CRMDetails/DOB',
  productCodeQuesId: '/XML/Product/ProductID',
  firstNameId: '/XML/Client/CRMDetails/FirstName',
  lastNameId: '/XML/Client/CRMDetails/LastName',
  middleNameId: '/XML/Client/CRMDetails/MiddleName',
  suffixId: '/XML/Client/CRMDetails/Suffix',
  genderId: '/XML/Client/CRMDetails/Gender',
  stateId: '/XML/ApplicationDetail/ContractState',
  addressId: '/XML/Client/CRMDetails/Address/Address1',
};

export const PrimaryInsuredConstant = {
  ageQuestId:
    '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
  dobQuestionId: '/XML/Client/CRMDetails/DOB',
  firstNameId: '/XML/Client/CRMDetails/FirstName',
  lastNameId: '/XML/Client/CRMDetails/LastName',
  middleNameId: '/XML/Client/CRMDetails/MiddleName',
  suffixId: '/XML/Client/CRMDetails/Suffix',
  genderId: '/XML/Client/CRMDetails/Gender',
  addressState: '/XML/Client/CRMDetails/Address/State',
  countryOfBirthQuesId: '/XML/Client/CRMDetails/PlaceOfBirth',
  stateOfBirthQuesId: '/XML/Client/CRMDetails/StateOfBirth',
  addrTypeXmlTag: '/XML/Client/CRMDetails/Address/AddressType',
  addr1XmlTag: '/XML/Client/CRMDetails/Address/Address1',
  addr2XmlTag: '/XML/Client/CRMDetails/Address/Address2',
  addr3XmlTag: '/XML/Client/CRMDetails/Address/Address3',
  cityXmlTag: '/XML/Client/CRMDetails/Address/City',
  stateXmlTag: '/XML/Client/CRMDetails/Address/State',
  zipXmlTag: '/XML/Client/CRMDetails/Address/PostalCode',
  countryXmlTag: '/XML/Client/CRMDetails/Address/Country',
  phoneNoXmlTag: '/XML/Client/CRMDetails/HomePhone',
  phoneTypeXmlTag: '/XML/Client/CRMDetails/PrimaryPhoneType',
  phonePINXmlTag: '/XML/Client/CRMDetails/PinPreference',
  emailXmlTag: '/XML/Client/AppDetails/Email',
  driverLicenseState: '/XML/Client/AppDetails/DriverLicenseState',
  driverLicenseNum: '/XML/Client/AppDetails/DriverLicenseNum',
  USCitizenInd: '/XML/Client/CRMDetails/USCitizenInd',
  greenCardInd: '/XML/Client/CRMDetails/GreenCardInd',
  mailingAddressInd: '/XML/Client/CRMDetails/MailingAddress/MailingAddressInd',
  mailingAddressState: '/XML/Client/CRMDetails/MailingAddress/State'
};

export const ProposedInsuredBConstant = {
  dobQuestionId: '/XML/Client/CRMDetails/DOB',
  ageQuestId: '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
  genderQuestionId: '/XML/Client/CRMDetails/Gender',
  primaryPhoneTypeQuestionId: '/XML/Client/CRMDetails/PrimaryPhoneType',
  secondaryPhoneTypeQuestionId: '/XML/Client/CRMDetails/SecondaryPhoneType',
  drivingLicenseNoneCheckedQuestionId: '/XML/Client/AppDetails/DriverLicenseNoneChecked',
  USCitizenIndQuestionId: '/XML/Client/CRMDetails/USCitizenInd',
  driverLicenseNumberQuestionId: '/XML/Client/AppDetails/DriverLicenseNum',
  stateOfBirthQuestionId: '/XML/Client/CRMDetails/StateOfBirth'
};

export const ExistingInsuranceInternalReplacementQ = [
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/PolicyOwnerName',
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/JointPolicyOwnerName',
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/CurrentPremiumAmt',
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/PaymentMode',
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/CashSurrValue',
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/PaidUpValue',
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/DividendValue',
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/SourceofFunding',
  '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/MGMA_VariableInd'
];

export const InternalReplacementCompanies = [
  'Aetna',
  'Alexander Hamilton',
  'American Guardian',
  'Chubb',
  'Cigna',
  'Connecticut General',
  'First Penn Pacific',
  'Guarantee Life',
  'Indianapolis Life',
  'Jefferson Pilot',
  'Jefferson Standard',
  'Kentucky Central Life',
  'Lincoln Financial Group',
  'Lincoln Life',
  'Lincoln National',
  'Lincoln NY',
  'Pilot Life',
  'Sovereign Life',
  'Westfield Life'
]

export const AgentReportKeys = {
  caseManagerFirstName: '/XML/Client/AgentDetails/CaseManagerFirstName',
  caseManagerLastName: '/XML/Client/AgentDetails/CaseManagerLastName',
  caseManagerPhoneNumber: '/XML/Client/AgentDetails/CaseManagerPhoneNumber',
  doYouAttestInd: '/XML/Client/AgentDetails/DoYouAttestInd',
  caseManagerEmailNotAllState: '/XML/Client/AgentDetails/CaseManagerEmail',
  caseManagerEmailAllState: '/XML/Client/AgentDetails/AgentInfoCont/CaseManagerEmail'
}

export const AgentReportMsges = {
  sameAsPrimaryAgentEmail: "The Primary Agent Email address provided is the same as the Case Manager's email address.  "
}
export const AgentReportKeysIfAllSate = [
  '/XML/Client/AgentDetails/AgentInfoCont/CaseManagerEmail',
  '/XML/Client/AgentDetails/DoYouAttestInd',
  '/XML/Client/AgentDetails/CaseManagerFirstName',
  '/XML/Client/AgentDetails/CaseManagerLastName',
  '/XML/Client/AgentDetails/CaseManagerPhoneNumber'
]

export const AgentReportQuestionTextsIfAllSate = [
  'The In Office Support/Staff Email address provided below will receive case statuses, follow-up information and notification indicating the electronic policy is ready for client delivery.  This email address will be the main point of contact.'
]

export const AgentReportQuestionTextsIfNotAllSate = [
  'The Case Manager Email address provided below will receive case statuses, follow-up information and notification indicating the electronic policy is ready for client delivery.  This email address will be the main point of contact.  If applicable, this would be your back-office or broker dealer.',
  "The Primary Agent Email address provided is the same as the Case Manager's email address."
]

export const AgentReportKeysIfNotAllSate = [
  '/XML/Client/AgentDetails/CaseManagerEmail',
  '/XML/Client/AgentDetails/DoYouAttestInd',
  '/XML/Client/AgentDetails/CaseManagerFirstName',
  '/XML/Client/AgentDetails/CaseManagerLastName',
  '/XML/Client/AgentDetails/CaseManagerPhoneNumber'
]

export const LtcSuitabilityRequredChidTags = [
'/XML/LTCWorksheet/SourceOfPremium',
'/XML/LTCWorksheet/AffordPolicyIfPartnerDiesFirst',
'/XML/LTCWorksheet/AnnualIncome',
'/XML/LTCWorksheet/IncomeChange',
'/XML/LTCWorksheet/ChangeInIncomeAffectPremiumPayments',
'/XML/MGeAPP/LTCWorksheet/InflationProtectionInd',
'/XML/LTCWorksheet/AssetsWorth',
'/XML/LTCWorksheet/AssetsChange'
]

export const ltcSuitabilityConstants = {
  agtAcknowledgement: '/XML/MGeAPP/LTCWorksheet/AgtAcknowledgement',
  financialSituation: '/XML/LTCWorksheet/FinancialSituationDescribedInd',
  inflationProtectionInd: '/XML/MGeAPP/LTCWorksheet/InflationProtectionInd',
  sourceOfPremium: '/XML/LTCWorksheet/SourceOfPremium',
  futureSourceOfPremium: '/XML/LTCWorksheet/FutureSourceOfPremium',
  suitabilityInd: '/XML/MGeAPP/LTCWorksheet/SuitabilityInd',
  financialSituationDescribed: '/XML/ApplicationDetail/FinancialSituationDescribed',
  declineDisclosureInd: '/XML/MGeAPP/LTCWorksheet/DeclineDisclosureInd'
}

export const LtcSuitabilityMsges = {
  suitabilityIndMsg: 'You have selected a response indicating that you do not consider this solution suitable for the client. If correct, an additional form will be required prior to the interview or issue. Please review your response (and/or this solution) before selecting Submit.'
}

export const LtcReplacementConstants = {
  ltcHealthinsCompanyName: '/XML/mg_eapp/ltc/ltc_or_healthins_grid/companyName',
  inforcePendingCompanyName: '/XML/mg_eapp/ltc/inforce_or_pending/companyName',
  ltcReplacementCompanyName: '/XML/mg_eapp/ltc/ltc_replacement/companyName',
  terminatedpoliciesCompanyName: '/XML/mg_eapp/ltc/terminatedpolicies/companyName',
  yearOfIssue: '/XML/mg_eapp/ltc/ltc_or_healthins_grid/yearOfIssue', 
  ltcOrHealthInsGridName: 'Long-Term Care or Health Insurance'
}

export const LTCReplacementMessages = {
  REMOVE_POLICY_ERROR: [
    "At least one policy is required to be entered. If you wish to change your answer, please click",
    "here",
    "and change your answer to NO for the question regarding you selling the client long term care insurance within the last 5 years that is no longer in force."
  ]
}

export const ExistingInsuranceConstant = {
  lapseCompanyName: '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/LapseCompanyName',
  replacementkey1: '/XML/ApplicationDetail/PolLapseOrChangeInInsuranceIndicator',
  replacementkey2: '/XML/ApplicationDetail/ReduceBenefitsBorrowFundsIndicator',
  otherInsPolicyInForceQuesID: '/XML/ApplicationDetail/ExistingInsuranceOrAnnuityContracts',
  exchange1035Ind: '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/Exchange1035Ind',
  contractToBeReplacedCollAssign: '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/ContractToBeReplacedCollAssignInd',
  replacementType: '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/ReplacementType',
  salesMaterialsInd: '/XML/mg_eapp/ExistingIns_SalesMaterialsInd'
};

export const OwnerInfoConstant = {
  isInsuredOwner: '/XML/ApplicationDetail/OwnerSameAsType',
  civQuestion: '/XML/MGeAPP/ApplicationDetails/CIVAgreementInd',
  // used for grid header
  ownerFirstName: '/XML/Client/CRMDetails/FirstName_~_106082#_~_7_~_STR_~_1',
  ownerLastName: '/XML/Client/CRMDetails/LastName_~_106084#_~_7_~_STR_~_1',
  entityName: '/XML/Client/CRMDetails/LastName_~_106156#_~_7_~_STR_~_1',
  trustName: '/XML/Client/CRMDetails/LastName_~_106101#_~_7_~_STR_~_1',
  ownerType: '/XML/Client/CRMDetails/ClientType',
  seqNumber: '/XML/Client/AppDetails/SeqNumber',
  OWNER_FIRST_NAME: '/XML/Client/CRMDetails/FirstName',
  OWNER_LAST_NAME: '/XML/Client/CRMDetails/LastName',
  OWNER_ADDRESS1: '/XML/Client/CRMDetails/Address/Address1',
  OWNER_ADDRESS2: '/XML/Client/CRMDetails/Address/Address2',
  OWNER_ADDRESS3: '/XML/Client/CRMDetails/Address/Address3',
  OWNER_CITY: '/XML/Client/CRMDetails/Address/City',
  OWNER_STATE: '/XML/Client/CRMDetails/Address/State',
  OWNER_ZIP: '/XML/Client/CRMDetails/Address/PostalCode',
  OWNER_EMAIL: '/XML/Client/AppDetails/Email',
  OWNER_ADDRESS_SAME_AS: '/XML/Client/AppDetails/OwnerAddressSameAs',
  ENTITY_BUISNESS_FIRSTNAMR: '/XML/Client/AppDetails/TrusteeBusinessFirstName1',
  ENTITY_BUISNESS_LASTNAME: '/XML/Client/AppDetails/TrusteeBusinessLastName1',
  primaryPhoneQues: 'What is the owner\'s primary phone number?',
  primaryTypePhoneQues: '/XML/Client/CRMDetails/PrimaryPhoneType',
  primaryPhonePINQues: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderPinPref',
  ENTITY_BUISNESS_PHONE: '/XML/Client/AppDetails/TrusteeBusinessHomePhone1',
  entityPhoneText: 'primary phone number',
  entityEmailText: ' email address',
  DriverLicenseNum: '/XML/Client/AppDetails/DriverLicenseNum',
  DriverLicenseState: '/XML/Client/AppDetails/DriverLicenseState',
  GovtPhotoID: '/XML/Client/AppDetails/GovtPhotoID',
  OwnerPassPortNumber: '/XML/Client/AppDetails/OwnerPassPortNumber',
  OwnerCountryStateOfIssuanceExpirationDate: '/XML/Client/AppDetails/OwnerCountryStateOfIssuanceExpirationDate',
  DriverLicenseExpirationDate: '/XML/Client/AppDetails/OwnerCountryStateOfIssuanceExpirationDate',
  OwnerCountryStateofIssuance: '/XML/Client/AppDetails/OwnerCountryStateofIssuance',
  CIVPassportCountryOfIssuance: '/XML/Client/AppDetails/CIVPassportCountryOfIssuance',
  TRUST_PHONE: '/XML/Client/AppDetails/TrusteeBusinessHomePhone1',
  TRUST_EMAIL: '/XML/Client/AppDetails/TrusteeBusinessEmailAddress1',
  IdentityType: '/XML/Client/AppDetails/CIVOwnerIdentityType',
  ownerCitizenName: '118762#_~_5_~_TCD_~_2',
  ownerSSNName: '118753#_~_12_~_STR_~_2'
};

export const ownerFieldDisableKeys = [
  '/XML/Client/CRMDetails/ClientType',
  '/XML/Client/CRMDetails/FirstName',
  '/XML/Client/CRMDetails/MiddleName',
  '/XML/Client/CRMDetails/LastName',
  '/XML/Client/CRMDetails/Suffix',
  '/XML/Client/AppDetails/RelationshipToInsuredOwner',
  '/XML/Client/AppDetails/RelationshipOther',
  '/XML/Client/CRMDetails/DOB',
  '/XML/Client/CRMDetails/TaxID',
  '/XML/Client/AppDetails/OwnerAddressSameAs',
  '/XML/Client/CRMDetails/HomePhone',
  '/XML/Client/CRMDetails/PrimaryPhoneType',
  '/XML/Client/CRMDetails/MobilePhone',
  '/XML/Client/AppDetails/Email',
  '/XML/Client/CRMDetails/CountryofCitizenship',
  '/XML/Client/CRMDetails/SecondaryPhoneType',
  '/XML/Client/CRMDetails/PinPreference',
  '/XML/Client/AppDetails/CIVOwnerIdentityType'
]

export const PayorOtherPersonConst = {
  firstName: '/XML/Client/CRMDetails/FirstName',
  lastName: '/XML/Client/CRMDetails/LastName',
  email: '/XML/MGeAPP/PayorInformation/IndEmail',
  phoneNumber: '/XML/MGeAPP/PayorInformation/IndPhoneNbr',
  phoneType: '/XML/MGeAPP/PayorInformation/IndPhoneType',
  phonePin: '/XML/MGeAPP/PayorInformation/IndPinPref'
}

export const PayorOtherEntityConst = {
  name: '/XML/Client/CRMDetails/LastName',
  email: '/XML/MGeAPP/PayorInformation/EntityEmail',
  phoneNumber: '/XML/MGeAPP/PayorInformation/EntityPhoneNbr',
  phoneType: '/XML/MGeAPP/PayorInformation/EntityPhoneType',
  phonePin: '/XML/MGeAPP/PayorInformation/EntityPinPref'
}

export const PayorOtherAddressConst = {
  address: '/XML/Client/CRMDetails/Address/Address1',
  address2: '/XML/Client/CRMDetails/Address/Address2',
  address3: '/XML/Client/CRMDetails/Address/Address3',
  city: '/XML/Client/CRMDetails/Address/City',
  state: '/XML/Client/CRMDetails/Address/State',
  zip: '/XML/Client/CRMDetails/Address/PostalCode'
}

export const  TIAeftFieldsConst = [
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountholderNameSameAs',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountholderAddress1',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountholderCity',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountholderState',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountholderZip',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountholderPhone',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountholderEmail',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACBankOrCreditUnionName',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACBankRoutingMICRNum',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountNumber',
  '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountType',
  ''
]

export const FundAllocationConst = {
  automaticRebalancing: '/XML/ApplicationDetail/AutoRebalancingInd',
  fundQuesXmlTag: '/XML/FundSelect/FundAllocation',
  // dca fund related tags
  dcaFundQuesXmlTag: '/XML/DCAFundSelect/FundAllocation',
  dollarCostAverage: '/XML/ApplicationDetail/DCAIndicator',
  accountToDCA: '/XML/ApplicationDetail/DCAFromFund',
  dcaPeriod: '/XML/ApplicationDetail/DCAPeriod',
  dcaTransferOption: '/XML/ApplicationDetail/DCATransferOption',
  // maturing index fund related tags
  maturingIndexFundXmlTag: '/XML/MaturingFund/FundAllocation',
  MaturingIndexIndQues: '/XML/ApplicationDetail/MaturingIndexAllocationInd',
  // transfer allocation charges
  fundCodeQuesId: '/XML/ClientDirectedMonthlyDeductions/FundSelect/FundCode',
  deductFundCheckboxXml: '/XML/ClientDirectedMonthlyDeductionsInd'
};

export const DcaAllocationConstants = [
  '/XML/ApplicationDetail/DCAIndicator',
  '/XML/ApplicationDetail/DCAFromFund',
  '/XML/ApplicationDetail/DCAPeriod',
  '/XML/ApplicationDetail/DCATransferOption',
  '/XML/ApplicationDetail/TelephoneTransfersRep',
  '/XML/ApplicationDetail/TelephoneTransfersOwner',
  '/XML/ApplicationDetail/InternetTransfersOwner',
  '/XML/ApplicationDetail/InternetTransfersRep'
]

export const LifeSutabilityZeroCheckKeys = [
  '/XML/ApplicationDetail/VULSuppEarnedIncome',
  '/XML/ApplicationDetail/VULSuppUnearnedIncome',
  '/XML/ApplicationDetail/VULSuppAssets',
  '/XML/ApplicationDetail/VULSuppLiabilities',
  '/XML/ApplicationDetail/VULSuppNetWorth'
]

export const policyInfoConstant = {
  ltcBenefitsRider: '/XML/MGeAPP/Riders/LTCBenefitsRider',
  ltcBenefitsRiderDuration: '/XML/MGeAPP/Riders/LTCBenefitsRiderDuration',
  ltcAcceleratedBenefits: '/XML/MGeAPP/Riders/LTCAcceleratedBenefits',
  ltcAcceleratedBenefitsDuration:'/XML/MGeAPP/Riders/LTCAcceleratedBenefitsDuration',
  valueProtectionRider: '/XML/MGeAPP/Riders/ValueProtectionRider',
  ltcABRTotalMonthly: '/XML/MGeAPP/Riders/LTCABRTotalMonthly',
  ltcABRBaseMonthlyRider: '/XML/MGeAPP/Riders/LTCABRBaseMonthlyRider',
  ltcEBRBaseMonthlyRider: '/XML/MGeAPP/Riders/LTCEBRBaseMonthlyRider',
  ltcEBRBaseTotalMonthly: '/XML/MGeAPP/Riders/LTCEBRBaseTotalMonthly',
  totalAnnualRiderCharges: '/XML/MGeAPP/Riders/TotalAnnualRiderCharges',
  benefitTransferRider: '/XML/MGeAPP/Riders/BenefitTransferRider',
  flexibleCareCashAmendment: '/XML/MGeAPP/Riders/FlexibleCareCashAmendment',
  livingWellEndorsement: '/XML/MGeAPP/Riders/LivingWellEndorsement',
  terminalIllnessRider: '/XML/MGeAPP/Riders/TerminalIllnessRider',
  valueProtectionEndorsementType: '/XML/MGeAPP/Riders/ValueProtectionEndorsementType',
  ltcExtensionBenefitsRider: '/XML/MGeAPP/Riders/LTCExtensionBenefitsRider',
  ltcBRInflationProtection: '/XML/MGeAPP/Riders/LTCBRInflationProtection',
  ltcABRBaseMonthlyInflation: '/XML/MGeAPP/Riders/LTCABRBaseMonthlyInflation',
  ltcEBRBaseMonthlyInflation: '/XML/MGeAPP/Riders/LTCEBRBaseMonthlyInflation'
}

export const policyInfoMsg = {
  DELEGATE_INFO: `Your agency has opted to use Lincoln's On Behalf Of functionality. You agree that you will provide the answers to the online questions to complete the required electronic ticket on behalf of the licensed life insurance producer. You also agree that any item of information or question from the Applicant which requires the act or advice of a licensed life insurance producer will be referred to the licensed life insurance producer for action before the electronic ticket is completed. You agree that any form requiring a licensed life insurance producer's signature will be routed to the licensed life insurance producer for their signature.`,
};


export const AgentInfoConstant = {
  // for primary agent
  seqNumber: '/XML/Client/AgentDetails/AdditionalAgent/SeqNumber',
  isMoreAgentQuesId: '/XML/Client/AgentDetails/MoreThanOneAgent',
  splitMainAgentQuesId: '/XML/Agent/AgentPercentage',
  agentSSNQuesId: '/XML/Agent/CRMDetails/TaxID',
  firmNameQuesId: '/XML/ApplicationDetail/Agent/CompanyName',
  agentNPNQuesId: '/XML/Agent/NPN',
  agentFirstNameQuesId: '/XML/Agent/CRMDetails/FirstName',
  agentLastNameQuesId: '/XML/Agent/CRMDetails/LastName',
  agentEmailQuesId: '/XML/ApplicationDetail/PrimaryAgentEmail',
  agentNumberQuesId: '/XML/Client/AgentDetails/AgentNumber',
  allStateAgentIdXmlTag: '/XML/Client/AgentDetails/AllStateAgentID',
  emailInfoFieldId: '/XML/ApplicationDetail/PrimaryAgentEmail_~_26143#16_~_30_~_STR_~_2',
  doYouattestTag: '/XML/Client/AgentDetails/DoYouAttestInd',
  clientAccountNoXmlTag: '/XML/Client/AgentDetails/BrokerDealerOwnerAccountNo',
  otherFirmXmlTag:'/XML/Client/AgentDetails/OtherFirm',
  registeredPrincipalTag:'/XML/Client/AgentDetails/AgentInfoCont/RegisteredPrincipalInd',
  isWritingAgentQuesId:'/XML/Agent/WritingAgentIndicator',
  agentPostalCodeXML: '/XML/Agent/AgentOldCode',

  // for additional agents
  secAgentSSNQuesId: '/XML/Client/AgentDetails/AdditionalAgent/SSN',
  secAgentFirmId: '/XML/Agent/NameOfAffiliatedAgency',
  secAgentFirstName: '/XML/Client/AgentDetails/AdditionalAgent/AgentFirstName',
  secAgentLastName: '/XML/Client/AgentDetails/AdditionalAgent/AgentLastName',
  secAgentNPN: '/XML/Agent/NPN',

  agentOtherFirm:'/XML/Agent/OtherNameOfAffiliatedAgency',
  firmIdXmlTag: '/XML/Agent/NameOfAffiliatedAgency',
  ssnXmlTag: '/XML/Agent/CRMDetails/TaxID',
  splitXmlTag:'/XML/Agent/AgentPercentage',
  duplicateSSNErrorCode: 'E203',


  // for grid header
  secAgentFirstNameQuesId: '/XML/Agent/CRMDetails/FirstName_~_26144#1_~_7_~_STR_~_1',
  secAgentLastNameQuesId: '/XML/Agent/CRMDetails/LastName_~_26144#2_~_7_~_STR_~_1',
  secAgentNoQuesId: '/XML/Client/AgentDetails/AdditionalAgent/AgentNumber_~_26144#8_~_7_~_STR_~_1',
  //secAgentNoQuesIdNotReq: '/XML/Client/AgentDetails/AdditionalAgent/AgentNumber_~_22117#4_~_7_~_STR_~_1',
  secSplitQuesId: '/XML/Agent/AgentPercentage_~_26144#9_~_7_~_STR_~_1',

  // to get grid answer
  agentFirmQuesName: '26144#7_~_5_~_STR_~_1',
  secAgentSSNQuesName: '26144#3_~_12_~_STR_~_1',
  secOtherFirmNameQuesName: '122271#_~_7_~_STR_~_1',
  secAgentNumber: '26144#8_~_7_~_STR_~_1',
  secAgentLastNameId: '26144#2_~_7_~_STR_~_1',
  secOtherFirmNameQuesId: '/XML/Agent/OtherNameOfAffiliatedAgency_~_122271#_~_7_~_STR_~_1',
  secSplitQuesName: '26144#9_~_7_~_STR_~_1',
  firmDropDownQuesId: '/XML/Agent/NameOfAffiliatedAgency_~_26144#7_~_5_~_STR_~_1'
};

export const FirmDataFields = [
  '/XML/Agent/NameOfAffiliatedAgency',
  '/XML/Agent/OtherNameOfAffiliatedAgency',
  '/XML/Agent/NPN',
  '/XML/Agent/AgentOldCode'
];

export const AgentInfoContConstant = {
  seqNumber: '/XML/Client/AgentDetails/AgentInfoCont/BusinessInsurance/SeqNumber',
  knowEnglishQuesId: '/XML/ApplicationDetail/AgentReportKnowEnglishInd',
  caseManagerEmailId: '/XML/Client/AgentDetails/AgentInfoCont/CaseManagerEmail',
  caseManagerDoYouAttestQuesId: '/XML/Client/AgentDetails/AgentInfoCont/DoYouAttestInd',
  caseManagerInfoQuesId: '_~_22418#5_~_0_~_TDT_VNF_~_RB_NVF',
  officeStaffEmailId: '/XML/Client/AgentDetails/AgentInfoCont/CaseManagerEmail',
  officeStaffDoYouAttestQuesId: '/XML/Client/AgentDetails/AgentInfoCont/DoYouAttestInd',
  officeStaffInfoQuesId: '_~_22418#7_~_0_~_TDT_VNF_~_RB_NVF',
  nameOFBusinessQuesId: '/XML/Client/AgentDetails/AgentInfoCont/BusinessInsurance/PartnerName_~_22419#2_~_7_~_STR_~_1',
  eInterviewQuesId: '/XML/Client/AgentDetails/AgentInfoCont/InterviewType',
  childRiderInfoId: '_~_22418#12_~_0_~_TDT_VNF_~_RB_NVF',
  existingInsuranceInfo: '/XML/Client/AgentDetails/AgentInfoCont/ReplacementInd',
  replacementInfo: '/XML/Client/AgentDetails/AgentInfoCont/ReplacementIndB',
  purposeOfInsuranceMA: '/XML/Client/AgentDetails/AgentInfoCont/PurposeOfInsuranceMA',
  purposeOfInsuranceNonMA: '/XML/Client/AgentDetails/AgentInfoCont/PurposeOfInsurance',
  businessLineFieldId: '_~_22418#56_~_0_~_TDT_VNF_~_RB_NVF',
  businessInsuranceTitleId: '/XML/Client/AgentDetails/AgentInfoCont/BusinessInsurance/PartnerTitle_~_22419#3_~_7_~_STR_~_1',
};

export const TrustInfoConstant = {
  state: '/XML/Client/AppDetails/TrusteeInfo/Address/State',
  phoneType: '/XML/Client/AppDetails/TrusteeInfo/PhoneType1',
  trustLastName: '/XML/Client/AppDetails/TrusteeInfo/LastName_~_22928#3_~_7_~_STR_~_1',
  trustFirstName: '/XML/Client/AppDetails/TrusteeInfo/FirstName_~_22928#2_~_7_~_STR_~_1',
  TRUST_FIRST_NAME: '/XML/Client/AppDetails/TrusteeInfo/FirstName',
  TRUST_LAST_NAME: '/XML/Client/AppDetails/TrusteeInfo/LastName',
};

export const SummaryConstant = {
  agentFirstName: '26144#1_~_7_~_STR_~_1',
  agentLastName: '26144#2_~_7_~_STR_~_1',
  agentEmail: '124056#_~_30_~_STR_~_1',
  agentPhone: '124053#_~_13_~_STR_~_1'
};

export const EFTConstant = {
  AccountHolderName: '/XML/Client/CRMDetails/TempInsurancePaymentProvider',
  firstNameXML: '/XML/Client/CRMDetails/EftAccountholderFirstName',
  lastNameXML: '/XML/Client/CRMDetails/EftAccountholderLastName',
  addressXML: '/XML/Client/CRMDetails/Address/Address1',
  EFTEntityNameXML: '/XML/Client/CRMDetails/EftAccountholderEntityName',
  cityXML: '/XML/Client/CRMDetails/Address/City',
  stateXML: '/XML/Client/CRMDetails/Address/State',
  zipcodeXML: '/XML/Client/CRMDetails/Address/PostalCode',
  emailAddressXML: '/XML/Client/AppDetails/Email',
  primaryPhoneNumberXML: '/XML/Client/CRMDetails/HomePhone',
  primaryPhoneTypeXML: '/XML/Client/CRMDetails/PrimaryPhoneType',
  bankOrCreditUnionNameXML: '/XML/Client/Banking/EftBankUnionName',
  routingNumberXML: '/XML/Client/Banking/EftRoutingNumber',
  accountNumberXML: '/XML/Client/Banking/EftAccountNumber',
  accountTypeXML: '/XML/Client/Banking/EftAccountType'
};

export const AppConstant = {
  space: 'Space',
};

export const PageStatusConstant = {
  VALID: 'valid',
  INVALID: 'invalid',
  LOCKED: 'locked',
};

export const CaseStatus = {
  STARTED: 'Started',
  LOCKED_READY_TO_SIGN: 'Locked - Ready to Sign',
  AWAITING_CUSTOMER_ESIGN: 'Awaiting Consumer e-Signature',
  ESIGN_LINK_EXPIRE: 'e-Signature Link Expired',
  CUSTOMER_DECLINE_TO_ESIGN: 'Consumer Declined to e-Sign',
  AWAITING_AGENT_ESIGN: 'Awaiting Agent e-Signature',
  AGENCY_DECLINE_TO_ESUBMIT: 'Agency Declined to e-Submit',
  AWAITING_AGENCY_APPROVAL: 'Awaiting Agency Approval',
  ESUBMIT_WITH_WET_SIGN: 'e-Submitted with Wet Signatures',
  ABANDONED: 'Abandoned',
  APPLICATION_ESUBMITTED: 'Application e-Submitted',
  CONFIRMATION_SENT: 'e-Submitted - Confirm. Sent',
  SYNC_TO_ESUBMIT: 'Sync to e-Submit',
  PENDING_PRINCIPAL_AGENT_APPROVAL: 'Pending Principal Agent Approval',
  PRINCIPAL_AGENT_DECLINED: 'Declined by Principal Agent',
  CASE_SUBMITTED: 'Case Submitted',
  READY_TO_SUBMIT: 'Ready to Submit',
};

export const StatusHelpConst = [
  {
    status: CaseStatus.STARTED,
    description:
      'An application has been started. Additional required information must be collected before the application can be signed and submitted to the carrier.',
  },
  {
    status: CaseStatus.LOCKED_READY_TO_SIGN,
    description:
      'All required information has been collected. The application has been locked and it is ready to be signed. A signature method must now be selected.',
  },
  {
    status: CaseStatus.AWAITING_CUSTOMER_ESIGN,
    description:
      'One or more consumer e-Signatures are outstanding and required.',
  },
  {
    status: CaseStatus.ESIGN_LINK_EXPIRE,
    description:
      'The e-Signature process is time sensitive, and one or more e-Signature links have expired. New emails should be sent to those individuals whose links have expired.',
  },
  {
    status: CaseStatus.CUSTOMER_DECLINE_TO_ESIGN,
    description:
      'One or more consumers have declined to e-Sign. The client should be contacted to determine why the e-Signature process was terminated.',
  },
  {
    status: CaseStatus.AWAITING_AGENT_ESIGN,
    description:
      'All client e-Signatures have been collected, the application is now ready for the agent to sign.',
  },
  {
    status: CaseStatus.AGENCY_DECLINE_TO_ESUBMIT,
    description:
      'The case manager has declined to e-Submit the application. For more information, contact the case manager directly.',
  },
  {
    status: CaseStatus.AWAITING_AGENCY_APPROVAL,
    description:
      'All required client and agent signatures have been collected. The application has been sent to the agency for review and approval.',
  },
  {
    status: CaseStatus.ESUBMIT_WITH_WET_SIGN,
    description:
      'The application has been electronically submitted to the carrier without signatures. The application must be printed and wet signatures must be collected from all required parties.',
  },
  {
    status: CaseStatus.ABANDONED,
    description:
      'The case has remained inactive for an extended period of time; it has been auto submitted to the carrier. For additional information please contact the carrier.',
  },
  {
    status: CaseStatus.APPLICATION_ESUBMITTED,
    description:
      'The e-Submission process is complete and the application has been electronically submitted for processing. For additional information,',
  },
  {
    status: CaseStatus.CONFIRMATION_SENT,
    description:
      'The e-Submission process is complete and the application has been electronically submitted for processing. Confirmation emails with secured access to the completed and e-Signed application packages have been sent to all applicable parties.',
  },
  {
    status: CaseStatus.SYNC_TO_ESUBMIT,
    description:
      'An application has been started. Additional required information must be collected before the application can be signed and submitted to the carrier.',
  },
  {
    status: CaseStatus.PENDING_PRINCIPAL_AGENT_APPROVAL,
    description:
      'This case is awaiting review and approval by the Principal Approver. For more information on this case, please contact your administrator or the carrier directly.',
  },
  {
    status: CaseStatus.PRINCIPAL_AGENT_DECLINED,
    description:
      'The Principal Approver has declined to approve and e-Submit this case. For more information, please contact your administrator directly.',
  },
];

export const Message = {
  APPLICATION_DOWN: 'The Lincoln eSubmission application is currently unavailable. We are working to resolve this issue and apologize for any inconvenience. Please try again later.',
  ONLY_5_ALLOWED: 'You have selected the max of 5. Please deselect to continue.',
  ACTION_REQ_CASES_LEAVING_SOON: 'Action required: {$noOfCases} incomplete cases are leaving soon.',
  NO_OF_RECORD_MATCHING: 'Number of records matching criteria ',
  POLICY_SUBMISSION_HEADER: 'We\'re currently processing your request.',
  POLICY_SUBMISSION_BODY: 'Once the request is completed a confirmation will display.',
  GENERIC_ERROR: 'We are unable to process your request at this time, please try again later.',
  WELCOME_MSG: 'Welcome to Lincoln\'s eSubmission Portal for life insurance and MoneyGuard® solutions. You can save your progress and return at any time. If you have questions during the eSubmission process, please select the help link to access frequently asked questions.',
  REQUIRED_QUES_MSG: 'All questions are required unless marked optional. Please answer all required questions on each page in order to proceed.',
  FILE_INFO: 'PDF format only. Upload file size limit: 30MB.',
  FILE_FORMAT_ERR: 'The file format is invalid. Only PDF files are accepted.',
  FILE_UPLOAD_ERR: 'File upload failed. Please try again.',
  FORM_LOADER_MESSAGE: 'Once the process is completed you will be able to review and download the forms.',
  DELETE_CASE_WARNING_MESSAGE: 'You are about to delete the case(s). Cases once deleted cannot be retrieved.',
  DELETE_CASE_CONFIRMATION_MESSAGE: 'Do you want to delete this case?',
  DELETE_CASE_DIALOG_HEADER: 'Delete Case',
  DELETE_CASE_STATUS_DIALOG_HEADER: 'Delete Case Status',
  DELETE_CASE_ERROR: 'Error while deleting cases. Please contact support team',
  FAILED_TO_DELETE_CASE_MESSAGE: '{noOfCase} case(s) failed to delete.',
  SUCCESSFULLY_DELETE_CASE_MESSAGE: '{noOfCase} case(s) deleted successfully. ',
  MODAL_PREMIUM_ERROR: 'The Minimum allowable amount for the Modal Premium Amount is ${minimum} if the Premium Mode is {mode}.',
  RIDER_MIN_AMOUNT_ERROR: 'Minimum amount is $100,000.',
  OTHER_RIDER_MAX_AMOUNT_ERROR: 'Maximum amount is the lesser of ${{minAmount}} (Face Amount + Primary Insured Term Rider amount) and $500,000.',
  PRIMARY_RIDER_MAX_AMOUNT_ERROR: 'Maximum amount is the lesser of (face amount) and $1,000,000.',
  OTHER_RIDER_DURATION_ERROR: 'Primary Insured age plus rider duration cannot exceed {{age}}.',
  PRIMARY_RIDER_DURATION_ERROR: 'Primary Insured age plus rider duration cannot exceed 80 years old.',
  PRIMARY_RIDER_DURATION_ERROR_WA: 'Primary Insured age cannot exceed {{age}} years old for {{duration}} year duration.',
  MIN_AMOUNT_ERROR: 'Minimum amount is ${{minAmount}}.',
  MAX_AMOUNT_ERROR: 'Maximum amount is ${{maxAmount}}.',
  LTC_AMOUNT_ERROR: 'LTC Specified Amount is outside rider guidelines.',
  WEIVER_AMOUNT_ERROR: 'Amount must be between ${{minAmount}} and ${{maxAmount}}.',
  DCA_RELATED_INFO: 'DCA from and to the same Account is not allowed. Please remove an initial Premium Payment from the money market Sub-Account or Fixed Account.',
  BUSINESS_SURRENDER_RIDER_ERROR: 'The Business EXEC Enhanced Surrender Value Rider requires that one of the Owners be a Corporation.',
  INDIVIDUAL_SURRENDER_RIDER_ERROR: 'The Individual EXEC Enhanced Surrender Value Rider requires that one of the Owners be an Individual or Trust and that no Owners are a Corporation.',
  DOB_AND_DBQ_MESSAGE: 'The Death Benefit Option, Increase by Premium, is not allowed with the Death Benefit Qualification of CVAT.',
  DIFF_OWNER_STATE_INFO: 'State of Solicitation does not match Owner state. A Lincoln representative will contact you for clarification prior to beginning the TeleApp interview.',
  DIFF_OWNER_STATE_INFO_WA: 'State of Solicitation does not match Owner state. Cross border sales are not allowed in UT and WA. If owner/applicant resides in UT or WA, the policy must be sitused there. A Lincoln representative will contact you for clarification.',
  VUL_DCA_ERROR: 'DCA from and to the same Account is not allowed.',
  ASSETEDGE_FIXED_ACCOUNT_ERROR: 'An initial premium must be made to the Fixed Account.',
  ASSETEDGE_GOVT_ERROR: 'An initial premium must be made to the Government Money Market Sub-Account.',
  MAX_FUND_ERROR: 'Only 5 funds can be selected.',
  ADVANCE_PREMIUM_AMOUNT_ERROR: 'Please enter an advance premium payment amount greater than $0.',
  FUND_MISSING_ERROR: 'Select one or more funds on the Fund Allocation page.',
  UNAUTHORIZED_USER_ERROR: 'You currently do not have permission to access the Lincoln eSubmission application on behalf of your Producer. Please contact your registered Producer and have them provide you with access through their Access & Permissions settings.',
  DIFF_PROPOSED_INSURED_STATE_INFO: 'State of Solicitation does not match Insured information. A Lincoln representative will contact you for clarification prior to beginning the TeleApp Interview.',
  ENVELOPE_CREATION_LOADER_MESSAGE: 'Once the process is completed an email will be sent to the signers. The agent will be notified when it\'s their turn to complete the signing process.',
  BOTH_ENVELOPE_CREATION_LOADER_MESSAGE: 'Once the process is completed, in-person signers will have the ability to review all forms for accuracy and apply their eSignature. Remote signers will be notified when it\'s their turn to sign the document.',
  POLICY_SUBMISSION_LOADER_MESSAGE: 'Once the request is completed a confirmation will display.',
  DO_NOT_CLOSE_MESSAGE: 'Please do not close this window or click the back button until the process is completed or the case has been submitted.',
  SIGNER_STATE_MT_MESSAGE: 'The state of situs selected is not MT but the proposed insured or owner resides in MT, or the entity-owner has a location in MT; no policy activity including signing of forms can be done in MT.',
  SAVE_TO_NBIT_FAILED: 'We apologize for the inconvenience; an issue has occurred with your application. Your information has been saved but we are currently unable to move forward with the signature process for your application. The appropriate support staff have been notified. If you would like, please contact 877-546-2647 for further assistance.',
  TIA_EFT_MSG: 'The same EFT account previously provided for monthly premium payments will be used for this TIA payment.'
};

export const ErrorMessage = {
  MGMA22_UNAVAILABLE: 'Lincoln MoneyGuard Market Advantage 2022 is no longer available for the selected state. Please select a different product.',
  UNANSWERED_QUES_ERROR: 'You have unanswered questions. All questions are required before completing the Application.',
  TRUST_OWNER_ERROR: 'More than one trust is not allowed to add as owner. Please remove multiple trusts.',
  FUND_PERCENTAGE_ERROR: 'Total should be equal to 100%.',
  DCA_FUND_ERROR: 'An allocation is needed in the Fixed Account and/or the Government Money Market Fund if Dollar Cost Averaging is selected.',
  ENTITY_OWNER_ERROR: 'More than one entity is not allowed to add as owner. Please remove multiple entities.',
  GREEN_CARD_HOLDER_ERROR: 'This product is only available to U.S. Citizens or Green Card holders.'
};

export const AgentValidationMessage = {
  ERROR_OCCURRED: 'Error occured. Please try after sometime.',
  VALIDATION_PENDING: 'Agent validation is not completed.',
  ALL_AGENTS_VALID: 'Agent(s) validated successfully.',
  DEFAULT_FAILED_MESSAGE: 'We are unable to confirm the appointment for <AGENTNAME> in the selected state <STATENAME>. Lincoln Financial Group will contact your licensing representative to complete the appointment process once the application is submitted. The producer not being appointed at the time of solicitation may delay the Underwriting of this policy and require resubmission.',
  PA_FAILED_MESSAGE: 'We are unable to confirm the appointment for <AGENTNAME> in the selected state <STATENAME>.',
  PA_FAILED_MESSAGE_ADDITIONAL_TXT: '<b>Additional action is required to satisfy this appointment requirement.</b> Please contact 877-533-0114 if you are an agent or with an MGA. Once your appointment has been completed you may return to this application via the dashboard and continue the application.',
  DEFAULT_FAILED_MESSAGE_ADDITIONAL_TXT_OK_PA: 'Select OK to close the modal.', 
  DEFAULT_FAILED_MESSAGE_ADDITIONAL_TXT_OK: 'You may continue with the application at this time by selecting the "OK" button.',
  DUPLICATE_SSN: 'Multiple agents exist with the same SSN. Each Agent should have a different SSN.'
};

export const ExistingInsuranceMessage = {
  INSURANCE_ERROR_MESSAGE: 'Please review the answer for the existing (inforce) life insurance or annuity contracts question due to a discrepancy with the answer(s) provided for replacing or borrowing funds questions.',
};

export const ValidateAndLockMessage = {
  VALID_TABS_SCREEN_HEADING: 'Your application is in Good Order!',
  INVALID_TABS_SCREEN_HEADING: 'One or more screens are not in good order.',
  UNLOCK_SCREEN_HEADING: 'Your application has been locked!',
  LOCK_SCREEN_MESSAGE: `<div class='message-head'>Please follow next steps below to complete your submission.</div><ol><li>To <strong>edit</strong> the application before locking, click on the screen name to the left of the navigation tree. Then return to the Validation and Lock Data screen.</li><li>To <strong>Lock</strong> and proceed with the electronic signature process, select the blue Lock Application button below.</li></ol><div class='message-foot'><strong>Note:</strong> No changes can be made without unlocking the application. Any signatures collected prior to unlocking this case will be lost and will need to be recollected from all signing parties again.</div>`,
  LOCK_SCREEN_NOTES: `<strong>Note:</strong> If you need to edit the application after it is locked, you may do so by coming back to this <strong>Validate and Lock Data</strong> screen located on the left navigation tree.`,
  ERROR_SCREEN_WARNING_MESSAGE: 'Your application is incomplete and cannot be locked at this time. Select the sections that have exclamation points to make the appropriate corrections before locking the application.',
  UNLOCK_SCREEN_MESSAGE: `<div class='message-head'>Please follow next steps below to complete your submission.</div><div class='message-body'>To proceed with the electronic signature process, select the blue Proceed to Electronic Signature Process button below.</div><div class='message-foot'></div>Then select Next.<div class='message-foot'><strong>Note:</strong> No changes can be made without unlocking the application. Any signatures collected prior to unlocking this case will be lost and will need to be recollected from all signing parties again.</div>`,
  UNLOCK_MESSAGE: 'Unlock Application Data and Cancel Signature Process',
  UNLOCK_ARE_YOU_SURE_MSG: 'Unlocking the case will void all active envelopes that have been sent for eSignature and you will be required to restart the eSignature process.',
  ARE_YOU_SURE: 'Are you sure?',
  DELEGATE_INFO: `You have completed the question-and-answer portion of the On Behalf Of functionality. Please notify the license life insurance producer for review and completion of the electronic application.`,
};

export const voiceConfirmationMessage = {
  YOUR_SIGNATURE_MSG: 'Your signature will be included on all presale forms as part of the Producer Acknowledgement process.',
  ESIGN_APP_MSG: 'You will be provided with a link to eSign the application prior to final policy delivery.',
  THANK_YOU: 'Thank you!',
  SUCCESS_MSG: 'Your request was submitted successfully.'
};

export const VoiceTermOfUse = {
  TERM_OF_USE: `<u>CONDITIONS OF USE</u><br/>
  By using this Web site in relation to an application for insurance with The Lincoln National Life Insurance Company, hereinafter collectively referred to as "the Company", you agree with the following Terms and Conditions Of Use ("Terms") without limitation or qualification. Please read these Conditions carefully before using this Web site. If you do not agree with these Terms, you are not granted permission to use this Web site and must exit this Web site immediately. The Company may revise these Terms at any time by updating this posting. You are bound by any such revisions and should therefore periodically visit this page to review the current Terms governing this Web site.<br/><br/>
  <u>DISCLAIMER</u><br/>
  TO THE FULLEST EXTENT PERMISSIBLE, THE MATERIALS ON THIS WEB SITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, AND THE COMPANY, AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (WHICH SHALL INCLUDE CAREER AGENTS AND BROKERS) (COLLECTIVELY "REPRESENTATIVES") AND SUPPLIERS DISCLAIM ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS WEB SITE, OR THE SERVER THAT MAKES IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THIS WEB SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. THE INFORMATION AND DESCRIPTIONS CONTAINED HEREIN ARE NOT NECESSARILY INTENDED TO BE COMPLETE DESCRIPTIONS OF ALL TERMS, EXCLUSIONS AND CONDITIONS APPLICABLE TO THE PRODUCTS AND SERVICES, BUT ARE PROVIDED SOLELY FOR GENERAL INFORMATIONAL PURPOSES; PLEASE REFER TO THE ACTUAL POLICY OR THE RELEVANT PRODUCT OR SERVICE AGREEMENT; THIS WEB SITE MAY BE LINKED TO OTHER WEB SITES WHICH ARE NOT MAINTAINED BY THE COMPANY. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, ARE RESPONSIBLE FOR THE CONTENT OF THOSE WEB SITES. THE INCLUSION OF ANY LINK TO SUCH WEB SITES DOES NOT IMPLY APPROVAL OF, OR ENDORSEMENT BY, THE COMPANY, OR ANY OF ITS REPRESENTATIVES OR SUPPLIERS, OF THE WEB SITES OR THE CONTENT THEREOF.<br/><br/>
  <u>LIMITATION OF LIABILITY</u><br/>
  While the Company and its Representatives or suppliers use reasonable efforts to include accurate and up-to-date information on this Web site, errors or omissions may occur. Under no circumstances shall the Company, or its Representatives or suppliers, be liable to you for any direct, incidental, consequential, indirect, or punitive damages that result from the use of, or the inability to use, the materials on this Web site, even if advised of the possibility of such damages.<br/><br/>
  <u>JURISDICTION</u><br/>
  Unless otherwise expressly set forth herein, the Company and its Representatives make no representation that materials on this Web site are appropriate or available for use in any location. Those who choose to access this Web site do so at their own initiative. The offer to sell or buy a product is specifically limited to the jurisdiction(s) in which the Company and its Representatives have the authority to offer the insurance products described in this Web site. Some products and services may not be available in all jurisdictions.<br/><br/>
  <u>TRADEMARKS AND COPYRIGHTS</u><br/>
  All trademarks, service marks, trade names, logos, icons and images are proprietary to the Company or its Representatives or suppliers. Nothing contained on this Web site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the foregoing displayed on this Web site without the written permission of the Company, its Representatives or suppliers, as applicable. Your use of any of the foregoing displayed on this Web site, or any other content on this Web site, except as provided herein, is strictly prohibited and may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.<br/><br/>
  <u>SOFTWARE LICENSES</u><br/>
  You acknowledge that any software which may be available or provided to you on this Web site may contain technology that is subject to strict controls pursuant to export control laws and regulations of the United States of America and other countries and jurisdictions. You hereby agree that you will not transfer or export such software in violation of such applicable export laws and regulations. Neither the Company, nor its Representatives or suppliers, authorize the downloading or exportation of any software or technical data from this Web site to any jurisdiction prohibited by such export controls laws and regulations.<br/><br/>`,

  PRODUCER_ACKNOWLEDGEMENT: `By clicking the Producer Acknowledgement button below, I state the following: (1) I am (or I am an authorized delegate of) a duly licensed and appointed life insurance producer in the state in which the Application was solicited and in the state in which the policy, if one is issued, will be delivered, (2) the plan and amount of insurance identified is suitable in view of the owner ́s insurance needs and financial objectives, (3) the information provided is complete, accurate and correctly recorded, and (4) all forms required to be delivered at the time of solicitation have been delivered and all other required forms have been or will be provided to the Applicant.<br/><br/>
  I authorize The Lincoln National Life Insurance Company (Lincoln), to obtain such administrative information as may be necessary to complete any life insurance application resulting from this lead submission, provided, however, that any item of information or question from the Applicant requiring the act or advice of a licensed life insurance producer will be referred to me for action before the application can be completed. I further authorize Lincoln to affix my signature to any life insurance application resulting from this form and to any other forms related to such application requiring my signature.<br/><br/>
  I will personally review the application created from this data and administrative information provided by the Applicant and contact him or her concerning any incomplete or inconsistent information and I will not deliver the policy unless I have completed my review and am satisfied that the policy, application and any other forms related to such application, if any, are complete and accurate.<br/><br/>
  I hereby agree, for myself, my heirs, assigns, administrators, executors and successors in interest, to completely release and fully indemnify and hold harmless Lincoln, its officers and directors, agents, successors, heirs and assigns, from any losses, damages, liabilities, or expenses which may arise from Lincoln ́s acceptance and use of my signature on a life insurance application or related form.<br/><br/>
  1. How long have you known the Proposed Insured?<br/>
  2. Are you related to the Proposed Insured? • Y • N<br/>
  3. Does the Proposed Insured and Owner(s) read and understand the English language? • Y • N<br/>
  If "No," how was the application completed?<br/>
  4. Purpose of Insurance:_________________<br/>
  5. Is the Proposed Insured using income from their spouse/domestic partner to financially justify the coverage applied? • Y • N<br/>
  If "Yes", provide the following information for the spouse/domestic partner:<br/>
  (a) Income: _________________<br/>
  (b) Life Insurance (In-force plus any additional to be placed): _________________<br/>
  6. Answer only if the Proposed Insured participates in aviation activities. If underwriting results in a higher premium, indicate which of the following is preferred (check one):<br/>
  • Pay the extra premium for coverage if death results from a covered aviation activity<br/>
  • Aviation Exclusion Rider (not available in all states, and subject to underwriter discretion)<br/><br/>
  I further certify that:<br/><br/>
  I have reviewed with the Proposed Insured each question on the application. For those questions asked by me, the answers have been recorded exactly as stated. For any answers provided by the Proposed Insured during a telephone or online interview and recorded by a third party, I have confirmed with the Proposed Insured that those answers as contained on the application were accurately recorded. I know of nothing affecting the insurability of the Proposed Insured which is not fully recorded in this application;<br/><br/>
  If I become aware of a change in the health or habits of the Proposed Insured occurring after the date of the application but before policy delivery, I will inform The Lincoln National Life Insurance Company (Lincoln) of the change and agree to withhold the policy delivery until instructed by Lincoln;<br/><br/>
  For application states other than MA, I have provided the Applicant and the Proposed Insured with a current copy of Lincoln ́s Important Notice as well as Lincoln ́s Privacy Practices. If the application state is MA, I have provided the Important Notice of Insurance Information Practices as well as Lincoln ́s Privacy Practices Notice;<br/><br/>
  I have verified all life insurance coverage in force, or in the process of being applied for, on the Proposed Insured, including any coverage that has been sold or is in the process of being sold to a life settlement, viatical or other secondary market provider;<br/><br/>
  I have not been involved in any recommendation regarding the possible sale or assignment of this policy to a life settlement, viatical or other secondary market provider;<br/><br/>
  To the best of my knowledge, the source of funding for this policy does not include (1) a non-recourse premium financing loan; or (2) any arrangement, other than a premium financing loan, which involves any person or entity with an interest in the potential earnings based on the provision of funding for the policy;<br/><br/>
  I have asked my client if there is any intention to replace, surrender, borrow against, sell or use any portion of any existing life insurance policy or annuity to finance any portion of the policy being applied for and know of no other replacement than that indicated above. If a replacement is intended, I have given the appropriate replacement forms to the client at the time of solicitation/application;<br/><br/>
  I have obtained sufficient information about the Applicant and the Proposed Insured to mitigate risks associated with money laundering, terrorist activity/funding, and to avoid doing business with a sanctioned individual or resident of a sanctioned country;<br/><br/>
  I have reviewed and I understand Lincoln Financial Group ́s Position Regarding Marijuana-Related Businesses as published in form GB10877;<br/><br/>
  All of the above statements and answers to questions provided in the Producer ́s Attestation, in the connection with this application, are true and accurate.<br/><br/>
  I acknowledge that clicking the Signature Submit button below constitutes my signature on this form and has the same effect as if I personally signed the form.<br/><br/>`
};

export const ESignature = {
  EmailTemplate: `<div class='email-example'>
  <b>From:</b><br/>
  <b>Subject: </b>Action required on your application for a policy with The Lincoln National Life Insurance Company - DO NOT REPLY<br/>
  <div class='divider'></div>
  <div class='email-body'>
  <p>Hello <b>Signing Party</b> (Signature Role),</p>
  <p>Your Application is ready for your review. [You should have received a PIN code in a text message referencing Lincoln Financial Group. This PIN will be needed to access your ticket.] Please click the button below to be directed to your online application.</p>
  <p>Once you have reviewed all forms for accuracy, you may apply your eSignature by following the instructions on the screens.</p>
  <p>If you have any questions, please do not hesitate to contact me at test@lfg.com.</p>
  <p>Thank you for allowing me to handle your financial needs.</p>
  <p>[Optional wording based on the remote signature method selected.]</p>
  </div>
  <div class='divider'></div>
  <div class='consent-container'>
  <p><b>Consent for Electronic Transmissions</b></p>
  <p>If you consent, the Lincoln National Life Insurance Company (the "Company") at LincolnFinancial.com, will transmit documents to you related to your application and policy by electronic means, to the extent that electronic transmission is consistent with applicable state and federal law. Any document that we send by electronic means, which complies with applicable law, will have the same force and effect as if that document was sent in paper format.</p>
  <p>This Consent covers all electronic documents and communications as related to an application for life insurance coverage through the Company, which includes, but is not limited to, applications, supplements, administrative forms and any policy-related correspondence. This Consent also covers all electronic documents and communications as related to a life insurance policy issued to you by the Company, which includes, but is not limited to the policy and policy delivery notices.</p>
  <p>In order to successfully receive electronic transmissions using this web site, it is recommended that your electronic device supports Windows® 8 or above, or Macintosh OSX; Adobe Acrobat Reader; has browser settings such as Microsoft Edge (for Windows, Mac and iOS), Google® Chrome® (Windows only), Apple Safari (For Mac And iOS), or Mozilla Firefox (Windows Or Mac)); a valid email address and security settings that allow per session cookies. The Company recommends that you keep electronic copies or print a copy of your documents.</p>
  <p>The Company will only transmit documents to you electronically if you consent. Such consent is voluntary. You are not required to consent to electronic transmissions if you prefer not to do so. If the policyholder or insured or owner has permitted electronic transmissions in the past, that authorization does not obligate the same procedure regarding this policy as well. If you choose not to opt-in to receive your documents electronically, please contact your agent or the Company by any of the methods shown below.</p>
  <p>If you consent to receive documents electronically, the Company will provide you with a paper copy of any document sent electronically upon request. The Company will not charge a fee for this service.</p>
  <p>If you decide that you do not want to receive documents electronically you are unable to apply for the Lincoln TermAccel® product, which is available through an electronic process only.</p>
  <p>If your email account changes, we suggest that you contact your agent or the Company so that the Company’s contact information for you remains current and accurate. You may contact us via the Company web page as shown above, or by our toll free telephone number 1-800-487-1485, or by submitting a written request via paper mail with sufficient postage to the Company at 100 N. Green Street, Greensboro, NC 27401.</p>
  <p>If you consent to the terms outlined above for electronic transmissions, click on the Button marked "I consent."</p></div></div>`,
  SignatureListHeading: 'Please specify the signature location of all signing parties.',
};

export const SignatureMethodOptions = [
  {
    description: 'In-Person',
    value: 'inperson'
  },
  {
    description: 'Remote using 4-digit SSN/PIN',
    value: 'remote'
  }
];

export const ElectronicSignatures = {
  Mail_Sent: 'Your request was completed successfully. An email notification will be sent to the appropriate signers.',
  Instructional_Info_Text: 'The "Send Emails" option will be enabled when a signer declines to sign, or the agent cancels the packet. When the "Send Email" option is selected, a new email with electric signature instructions will be sent to each signer. The agent email will be sent when all additional signers complete the signing process.',
  Required_Message: 'To update the Email address and/or Cell Phone Number below, you will need to unlock the application and make the appropriate change and then relock the application to send the Emails or send Emails/text PIN codes.',
  View_Invitation_Link: 'View eSignature invitation',
  Packet_Success_Msg: 'Packet sent to all signers successfully.',
  Packet_Success_Msg_2: 'Your request was submitted successfully.',
  Packet_Exist_Message: 'The packet submission history section allows you to track when forms were submitted and completed by each signer. You can resend an email or cancel a packet.',
  No_Packet_Exist: 'No packet history to show.',
  Next_Button_Click_Message: 'Please select the "Next" button to continue.',
  Resend_packet_heading: 'Resend packet',
  Resend_packet_info_text: 'Select recipient(s) below and click resend. If a recipient\'s email has changed, enter the new email address in the space provided before resending the email.',
  Cancel_modal_heading: 'Are you sure?',
  Cancel_modal_info_1: 'Are you sure you want to cancel the packet?',
  Cancel_modal_info_2: 'By canceling the packet, the signing process will end and the remaining signers will not be allowed to sign the forms that are in the packet.'

};

export const ESignatureInstructions = {
  Signers_List_Message: 'eSignature emails will be sent to the signing parties below:',
  EmailError: 'Email address doesn\'t match',
  ESignature_Instructions_Message: 'The eSignature process requires each eSigner to review the application online and agree to the series of disclosure and disclaimer statements, insert the city, where he/she is located and apply this as his/her signature.<br/><br/>Upon careful review of all information, each eSigner will be instructed to click a of "I Agree" statements.<br/><br/>This will serve as his/her electronic signature. A secure process has been put in place to ensure his/her personal information and the signature process is confidential and secure.'
};

export const TABS_RELATED_TO_ELECTRONIC_SIGNATURE = [
  TAB.ELECTRONIC_SIGNATURE,
  TAB.WET_SIGNATURE_INSTRUCTION,
  // TAB.E_SIGNATURE_INSTRUCTION
];

export const TABS_ALWAYS_IN_GOOD_ORDER = [
  TAB.ELECTRONIC_SIGNATURE,
  TAB.REVIEW_AND_SUBMIT,
];

export const TABS_EXCLUDED_FROM_GET_QUESTIONS = [
  TAB.ELECTRONIC_SIGNATURE,
  TAB.ILLUSTRATION,
  TAB.REVIEW_AND_SUBMIT,
  TAB.WET_SIGN_COLLECTION_INFO_MG
];

export const BeneficiaryErrors = {
  beneShareEquallyRuleErrForThreeGrid: 'Although you have chosen to do an even shared percentage for your beneficiaries, you have decided to add three beneficiaries. Your shared percentage needs to be a whole number and needs to equal 100%. Please either update your shared percentage manually or add/remove a beneficiary.',
  beneShareEquallyRuleErrForSixGrid: 'Although you have chosen to do an even shared percentage for your beneficiaries, you have decided to add six beneficiaries. Your shared percentage needs to be a whole number and needs to equal 100%. Please either update your shared percentage manually or add/remove a beneficiary.',
  beneShareEquallyRuleErrForSevenGrid: 'Although you have chosen to do an even shared percentage for your beneficiaries, you have decided to add seven beneficiaries. Your shared percentage needs to be a whole number and needs to equal 100%. Please either update your shared percentage manually or add/remove a beneficiary.',
  beneShareEquallyRuleErrForEightGrid: 'Although you have chosen to do an even shared percentage for your beneficiaries, you have decided to add eight beneficiaries. Your shared percentage needs to be a whole number and needs to equal 100%. Please either update your shared percentage manually or add/remove a beneficiary.',
  beneShareEquallyRuleErrForNineGrid: 'Although you have chosen to do an even shared percentage for your beneficiaries, you have decided to add nine beneficiaries. Your shared percentage needs to be a whole number and needs to equal 100%. Please either update your shared percentage manually or add/remove a beneficiary.',
  noOwner: 'You are listed as the only owner for this policy. You cannot be the primary beneficiary. Please change the answer to this question and provide the required information for a primary beneficiary.'
}

export const ExcludeTabsFromLock = [
  TAB.VALIDATE_AND_LOCK,
  TAB.SIGNATURE_METHOD,
  TAB.E_SIGNATURE_INSTRUCTION,
  TAB.ELECTRONIC_SIGNATURE,
  TAB.CONSENT_PAGE_AGENT,
  TAB.CONSENT_PAGE_PI,
  TAB.CONSENT_PAGE_INSURED_OWNER,
  TAB.CONSENT_PAGE_INSURED_B,
  TAB.CONSENT_PAGE_ADDITIONAL_INSURED,
  TAB.CONSENT_PAGE_COOWNER,
  TAB.CONSENT_PAGE_COOWNER2,
  TAB.CONSENT_PAGE_COOWNER3,
  TAB.CONSENT_PAGE_OWNER,
  TAB.REVIEW_AND_SUBMIT,
  TAB.WET_SIGNATURE_INSTRUCTION,
  TAB.CONSENT_PAGE_PRINCIPAL,
  TAB.CONSENT_PAGE_PAYOR
];

export const ExcludeTabsFromValidation = [
  ...ExcludeTabsFromLock,
  TAB.APPLICATION_SUMMARY,
];

export const QuestionTextIncludes = [
  'because', 'how'
];


export const CIVFieldsKeys = [
  '/XML/Client/AppDetails/CIVOwnerIdentityType',
  '/XML/Client/CRMDetails/TrustVerificationDoc',
  '/XML/Client/AppDetails/MGLegalEntityVerification'
];

export const CIVDropDownFieldsKeys = [
  '/XML/Client/AppDetails/CIVOwnerIdentityType',
  '/XML/Client/AppDetails/DriverLicenseState',
  '/XML/Client/AppDetails/CIVPassportCountryOfIssuance'
];


export const TemporaryLifeInsuranceConstants = {
  PACBankRoutingMICRNum: '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACBankRoutingMICRNum',
  PACAccountNumber: '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountNumber',
  methodOfPayment: '/XML/MGeAPP/TIA/ApplicationDetail/PaymentInformation/PaymentMode',
  accountHolder: '/XML/MGeAPP/TIA/ApplicationDetail/PaymentFrequencyPACAccountholderNameSameAs'
}

export const StateOptions = [
  { value: 'none', label: 'None' },
  { value: 'AL{47}AL', label: 'Alabama' },
  { value: 'AK{47}AK', label: 'Alaska' },
  { value: 'AS{47}AS', label: 'American Samoa' },
  { value: 'AZ{47}AZ', label: 'Arizona' },
  { value: 'AR{47}AR', label: 'Arkansas' },
  { value: 'CA{47}CA', label: 'California' },
  { value: 'CO{47}CO', label: 'Colorado' },
  { value: 'CT{47}CT', label: 'Connecticut' },
  { value: 'DE{47}DE', label: 'Delaware' },
  { value: 'DC{47}DC', label: 'District of Columbia' },
  { value: 'FS{47}FS', label: 'Federated States Of Micronesia' },
  { value: 'FL{47}FL', label: 'Florida' },
  { value: 'GA{47}GA', label: 'Georgia' },
  { value: 'GU{47}GU', label: 'Guam' },
  { value: 'HI{47}HI', label: 'Hawaii' },
  { value: 'ID{47}ID', label: 'Idaho' },
  { value: 'IL{47}IL', label: 'Illinois' },
  { value: 'IN{47}IN', label: 'Indiana' },
  { value: 'IA{47}IA', label: 'Iowa' },
  { value: 'KS{47}KS', label: 'Kansas' },
  { value: 'KY{47}KY', label: 'Kentucky' },
  { value: 'LA{47}LA', label: 'Louisiana' },
  { value: 'ME{47}ME', label: 'Maine' },
  { value: 'MH{47}MH', label: 'Marshall Islands' },
  { value: 'MD{47}MD', label: 'Maryland' },
  { value: 'MA{47}MA', label: 'Massachusetts' },
  { value: 'MI{47}MI', label: 'Michigan' },
  { value: 'MN{47}MN', label: 'Minnesota' },
  { value: 'MS{47}MS', label: 'Mississippi' },
  { value: 'MO{47}MO', label: 'Missouri' },
  { value: 'MT{47}MT', label: 'Montana' },
  { value: 'NE{47}NE', label: 'Nebraska' },
  { value: 'NV{47}NV', label: 'Nevada' },
  { value: 'NH{47}NH', label: 'New Hampshire' },
  { value: 'NJ{47}NJ', label: 'New Jersey' },
  { value: 'NM{47}NM', label: 'New Mexico' },
  { value: 'NY{47}NY', label: 'New York' },
  { value: 'NC{47}NC', label: 'North Carolina' },
  { value: 'ND{47}ND', label: 'North Dakota' },
  { value: 'MP{47}MP', label: 'Northern Mariana Islands' },
  { value: 'OH{47}OH', label: 'Ohio' },
  { value: 'OK{47}OK', label: 'Oklahoma' },
  { value: 'OR{47}OR', label: 'Oregon' },
  { value: 'OT{47}OT', label: 'OT' },
  { value: 'PW{47}PW', label: 'Palau Island' },
  { value: 'PA{47}PA', label: 'Pennsylvania' },
  { value: 'PR{47}PR', label: 'Puerto Rico' },
  { value: 'RI{47}RI', label: 'Rhode Island' },
  { value: 'SC{47}SC', label: 'South Carolina' },
  { value: 'SD{47}SD', label: 'South Dakota' },
  { value: 'TN{47}TN', label: 'Tennessee' },
  { value: 'TX{47}TX', label: 'Texas' },
  { value: 'S_OTHER{47}S_OTHER', label: 'Other' },
  { value: 'UT{47}UT', label: 'Utah' },
  { value: 'VT{47}VT', label: 'Vermont' },
  { value: 'VI{47}VI', label: 'Virgin Islands' },
  { value: 'VA{47}VA', label: 'Virginia' },
  { value: 'WA{47}WA', label: 'Washington' },
  { value: 'WV{47}WV', label: 'West Virginia' },
  { value: 'WI{47}WI', label: 'Wisconsin' },
  { value: 'WY{47}WY', label: 'Wyoming' },
  { value: 'AB{47}AB', label: 'Alberta' },
  { value: 'BC{47}BC', label: 'British Columbia' },
  { value: 'MB{47}MB', label: 'Manitoba' },
  { value: 'NB{47}NB', label: 'New Brunswick' },
  { value: 'NF{47}NF', label: 'Newfoundland' },
  { value: 'NT{47}NT', label: 'Northwest Territories' },
  { value: 'NS{47}NS', label: 'Nova Scotia' },
  { value: 'ON{47}ON', label: 'Ontario' },
  { value: 'PE{47}PE', label: 'Prince Edward Island' },
  { value: 'PQ{47}PQ', label: 'Quebec' },
  { value: 'SK{47}SK', label: 'Saskatchewan' },
  { value: 'YT{47}YT', label: 'Yukon Territories' },
  { value: 'ACT{47}ACT', label: 'Australian Capital Territory' },
  { value: 'NSW{47}NSW', label: 'New South Wales' },
  { value: 'NT{47}NT', label: 'Northern Territory' },
  { value: 'QL{47}QL', label: 'Queensland' },
  { value: 'SA{47}SA', label: 'South Australia' },
  { value: 'TAS{47}TAS', label: 'Tasmania' },
  { value: 'VIC{47}VIC', label: 'Victoria' },
  { value: 'WA{47}WA', label: 'Western Australia' }
]

export const billingConstants = {
  allowableRangeYearInfo_MGFA: "The allowable range for Flexible Premiums for the MoneyGuard Fixed Advantage Product ranges from 2 years to 10 years and is dependent on the Issue Age of the Proposed Insured and case design. Please refer to your Client's valid illustration to determine the exact Flex Premium Years allowable.",
  allowableRangeYearInfo_MGMA: "The allowable range for Flexible Premiums for the MoneyGuard Market Advantage Product ranges from 2 years to 40 years and is dependent on the Issue Age of the Proposed Insured and case design. Please refer to your Client's valid illustration to determine the exact Flex Premium Years allowable.",
  premiumModeSingle: 'Single Premium',
  premiumModeMonthly: 'EFT - Monthly'
}

export const minPremiumAmmountForModeConfig = new Map<string, string>([
  ["annual", "200"],
  ["semi-annual", "100"],
  ["quarterly", "50"],
  ["eft - monthly", "15"]
]);

export const premiumAndBillingKeys = {
  PremiumMode: '/XML/ApplicationDetail/PaymentFrequencyType',
  SelectedPremium: '/XML/ApplicationDetail/SelectedPremium',
  paymentLumpSumAmount: '/XML/ApplicationDetail/PaymentLumpSumAmount',
  eft: '/XML/ApplicationDetail/PaymentFrequencyPACInd',
  accountHolder: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderNameSameAs',
  mecInd: '/XML/ApplicationDetail/MECInd',
  premiumChangeInd: '/XML/ApplicationDetail/PremiumChange/PremiumChangeInd',
  numberYearsPayable: '/XML/ApplicationDetail/NumberYearsPayable',
  payorSameAsType: '/XML/Client/AppDetails/MGPayorSameAsType'
}

export const premiumAndBillingConditinalMsgs = {
  minAllowableAmount: 'the minimum allowable amount for the premium',
  maxAllowableAmount: 'the maximum allowable amount for the premium',
  maxAllowableLumpSumAmount: 'the maximum allowable amount for the lump sum',
  isMoreThanOnePolicy: 'if there is more than one policy on the same bank draft, the home office',
  allowableYearsPayable: 'the allowable years payable value must be between'
}

export const EFTKeys = {
  streetAddress: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderAddress1',
  streetAddress2: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderAddress2',
  streetAddress3: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderAddress3',
  city: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderCity',
  state: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderState',
  zip: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderZip',
  phone: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderPhone',
  email: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderEmail',
  phoneType: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderPhoneType',
  phonePin: '/XML/ApplicationDetail/PaymentFrequencyPACAccountholderPinPref'
}

export const Products = {
  MGFA:'MGFA',
  MGFA25:'MGFA24',
  MGMA22:'MGMA22',
  MGMA24:'MGMA24',
  MGII:'MGII17',
  MGMA:'MGMA'
}

export const BenefitTimeOptions = [
  {
    value: 'year',
    label: 'Year',
  },
  {
    value: 'age',
    label: 'Age',
  },
];

export const PremiumModeOptions = [
  {
    value: 'annually',
    label: 'Annually',
  },
  {
    value: 'semiannually',
    label: 'Semi-Annually',
  },
  {
    value: 'quarterly',
    label: 'Quarterly',
  },
  {
    value: 'monthly',
    label: 'Monthly (EFT)',
  },
];

export const EndorsementTypeOptions = [
  {
    value: 'basic',
    label: 'Basic',
  },
  {
    value: 'vested',
    label: 'Vested',
  },
];

export const BenefitPlanOptions = [
  {
    value: 'none',
    label: '*Please select*',
  },
  {
    value: '3yrs0inf',
    label: '3 Years, No Inflation',
  },
  {
    value: '3years3inf',
    label: '3 Years, 3% Compound Inflation',
  },
  {
    value: '3years5inf',
    label: '3 Years, 5% Compound Inflation',
  },
  {
    value: '4years0inf',
    label: '4 Years, No Inflation',
  },
  {
    value: '4years3inf',
    label: '4 Years, 3% Compound Inflation',
  },
  {
    value: '4years5inf',
    label: '4 Years, 5% Compound Inflation',
  },
  {
    value: '5years0inf',
    label: '5 Years, No Inflation',
  },
  {
    value: '5years3inf',
    label: '5 Years, 3% Compound Inflation',
  },
  {
    value: '5years5inf',
    label: '5 Years, 5% Compound Inflation',
  },
  {
    value: '6years0inf',
    label: '6 Years, No Inflation',
  },
  {
    value: '6years3inf',
    label: '6 Years, 3% Compound Inflation',
  },
  {
    value: '6years5inf',
    label: '6 Years, 5% Compound Inflation',
  },
];

export const UnderwritingClassOptions = [
  {
    value: 'none',
    label: '*Please Select*',
  },
  {
    value: 'couple',
    label: 'Couples discount',
  },
  {
    value: 'standard',
    label: 'Standard',
  },
];

export const TimePeriodOptions = [
  {
    value: 'age',
    label: 'Age',
  },
  {
    value: 'year',
    label: 'Year',
  },
];

export const InflationElectionOptions = [
  {
    value: 'market',
    label: 'Market Driven',
  },
  {
    value: 'compound',
    label: '5% Compound',
  },
];

export const BasicIllustrationFields = {
  MGFA:['firstName','lastName', 'DOB', 'age', 'gender', 'underwritingClass', 'revisedProjection', 'costDisclosure', 'printIRR', 'printSignature', 'altAge', 'additionalCostsState', 'alternateCost', 'alternateCostAmt', 'fundBTR', 'fundBTRAmt', 'fundBTRAge', 'btrDropdown',],
  MGMA22:[ 'firstName', 'lastName', 'DOB', 'age', 'gender', 'underwritingClass', 'backdate', 'backdateMonths', 'incPremium', 'inflationElection', 'revisedNAIC', 'altAge', 'additionalCostsState', 'alternateCost', 'alternateCostAmt', 'fundBTR', 'fundBTRAmt', 'fundBTRAge', 'btrDropdown', ],
  MGII:[ 'firstName', 'lastName', 'DOB', 'age', 'gender', 'underwritingClass', 'revisedProjection', 'costDisclosure', 'printIRR', 'printSignature', 'altAge', 'additionalCostsState', 'alternateCost', 'alternateCostAmt',],
  MGMA:[ 'firstName', 'lastName', 'DOB', 'age', 'gender', 'underwritingClass', 'backdate', 'backdateMonths', 'incPremium', 'inflationElection', 'revisedNAIC', 'altAge', 'additionalCostsState', 'alternateCost', 'alternateCostAmt'],
}

export const PolicyIllustrationFields = {
  MGFA:[ 'solveFor', 'faceAmt', 'benefitAmt', 'benefitTimeDropdown', 'benefitTimeVal', 'premium', 'pmtOption', 'premiumMode', 'lumpSum', 'allowExchangeToFund', 'illustrateExchange', 'isMEC', 'internalCashVal', 'externalCashVal', 'endorsementType', 'benefitPlan', 'backdate', 'backdateMonths', ],
  MGMA22:['premiumMode','faceAmtAndBenefits','premiumMode','modalPremium','modalPremiumDropdown','modalPremiumAmt','modalPremiumTime','modalPremiumTimeAmt','allowExchangeToFund','illustrateWithLumpSum','variableRateSelection','variableRateDropdown','variableRate','alternateRate','solveObjective','solveObjectiveTime','solveObjectiveTimeValue','guaranteeUntil','guaranteeUntilTime','guaranteeUntilValue','externalExchange','internalExchange'],
  MGII:[ 'solveFor', 'faceAmt', 'benefitAmt', 'benefitTimeDropdown', 'benefitTimeVal', 'premium', 'pmtOption', 'premiumMode', 'lumpSum', 'allowExchangeToFund', 'illustrateExchange', 'isMEC', 'internalCashVal', 'externalCashVal', 'illustrateWithValueProtection', 'valueProtectionRiderType', 'illustrateWithLTCAcceleration','accelerationBenefitPlan','illustrateWithLTCExtension','extensionBenefitPlan','backdate','backdateMonths' ],
  MGMA:['premiumMode','faceAmtAndBenefits','premiumMode','modalPremium','modalPremiumDropdown','modalPremiumAmt','modalPremiumTime','modalPremiumTimeAmt','illustrateWithLumpSum','allowExchangeToFund','variableRateSelection','variableRateDropdown','variableRate','alternateRate','solveObjective','solveObjectiveTime','solveObjectiveTimeValue','guaranteeUntil','guaranteeUntilTime','guaranteeUntilValue','externalExchange','internalExchange'],
}

export const FaceAmtAndBenefitsOptions = [
  {
    value: 'minMax',
    label: 'Minimize Face / Max Premium Limits',
  },
  {
    value: 'knownPremium',
    label: 'Known Premium / Optimize Overall Benefits',
  },
  {
    value: 'knownFace',
    label: 'Known Face Amount'
  },
  {
    value: 'knownBaseMaxMonthly',
    label: 'Known Base LTC Maximum Monthly'
  },
  {
    value: 'knownBaseTotalBenefits',
    label: 'Known Base LTC Total Benefits',
  },
  {
    value: 'solveForCV',
    label: 'Solve For Future CV'
  },
  {
    value: 'solveForDB',
    label: 'Solve For Future DB'
  },
  {
    value: 'solveForTotalBenefits',
    label: 'Solve For LTC Total Benefits'
  },
  {
    value: 'solveForMaxMonthly',
    label: 'Solve For LTC Max Monthly'
  }
]

export const ModalPremiumOptions = [
  {
    value:'maxGrowth',
    label: 'Maximum LTC Growth Potential with Guarantee',
  },
  {
    value: 'maxPremiums',
    label: 'Maximum Premiums Required with Guarantee'
  },
  {
    value: 'knownPremium',
    label: 'Known Premium Amount'
  },
  {
    value: 'solveForCV',
    label: 'solve For Future CV'
  },
  {
    value: 'solveForDB',
    label: 'Solve For Future DB'
  },
  {
    value: 'solveForTotalBenefits',
    label: 'Solve For LTC Total Benefits'
  },
  {
    value: 'solveForMaxMonthly',
    label: 'Solve For LTC Max Monthly'
  }
]

export const RateOfReturnOptions = [
  {
    value: 'knownAmount',
    label: 'Known Amount'
  },
  {
    value: 'solveForCV',
    label: 'Solve For Future CV'
  },
  {
    value:'solveForDB',
    label: 'Solve For Future DB'
  },
  {
    value: 'solveForTotalBenefits',
    label: 'Solve For LTC Total Benefits'
  },
  {
    value: 'solveForMaxMonthly',
    label: 'Solve For LTC Max Monthly'
  }

]

export const EndTimePeriodOptions = [
  {
    value:'year',
    label:'Year'
  },
  {
    value:'age',
    label:'Age'
  },
  {
    value:'maturity',
    label:'Maturity'
  }
]

export const LTCAccelerationBenefitsOptions = [
  {
    value:'2yr',
    label:'2 Year Level'
  },
  {
    value:'2yrInc3',
    label:'2 Year Compound Increasing 3%'
  },
  {
    value:'2yrInc5',
    label:'2 Year Compound Increasing 5%'
  },
  {
    value:'3yr',
    label:'3 Year'
  },
  {
    value:'3yrInc3',
    label:'3 Year Compound Increasing 3%'
  },
  {
    value:'3yrInc5',
    label:'3 Year Compound Increasing 5%'
  },
]

export const LTCExtensionBenefitsOptions = [
  {
    value:'2yr',
    label:'2 Year Level'
  },
  {
    value:'2yrInc3',
    label:'2 Year Compound Increasing 3%'
  },
  {
    value:'2yrInc5',
    label:'2 Year Compound Increasing 5%'
  },
  {
    value:'4yr',
    label:'4 Year'
  },
  {
    value:'4yrInc3',
    label:'4 Year Compound Increasing 3%'
  },
  {
    value:'4yrInc5',
    label:'4 Year Compound Increasing 5%'
  },
]

export const MECOptions = [
  {
    value:'MEC',
    label:'MEC'
  },
  {
    value:'nonMEC',
    label:'Non-MEC'
  },
]

export const KeysForIllustrationConditionals = {
  marriedCivilUnion: '/XML/Client/CRMDetails/MarriedCivilUnion_~_24034#17_~_4_~_STR_~_1',
  replaceMedicalHealthLTCIndicator2:'/XML/ApplicationDetail/ReplaceMedicalHealthLTCIndicator2',
  protectionAgainstUnIntendedLapse: '/XML/ApplicationDetail/ProtectionAgainstUnintendedLapseInd'
}

export const SigningMethodConst = {
  SigningState: '/XML/ApplicationDetail/ContractState',
  situsMsg: 'The state of situs selected is not MT but'
};

export const wetSignatureForms = [
  'Wet Signed Exchange Agreement/Absolute Assignment for Life Insurance (1035)',
  'Wet Signed Protection Against Unintended Lapse',
  'Wet Signed Notice To Applicant Regarding Replacement of Accident and Sickness or Long-Term Care Insurance (LTC Replacement)'
];

export const OPTIONAL_TABS = [
  TAB.OWNER_INFO,
];

export const eSignatureInstructionConst = {
  confirmEmailTag: '/XML/ApplicationDetail/EticketES/ConfirmEmailID'
};

export const ESignatureEmailTemplate = {
  EmailTemplate: `<p class="font-bold email-head">From:</p>
  <p>Action required on your application for a policy with The Lincoln National Life Insurance Company - DO NOT REPLY</p>
  <p class="font-bold email-head">Email Message:</p>
  <p>Hello Signing Party (Signature Role),<br/>
  Your Application is ready for your review. [You should have received a PIN code in a text message referencing Lincoln Financial Group. This PIN will be needed to access your ticket.] Please click the button below to be directed to your online application.
  Once you have reviewed all forms for accuracy, you may apply your eSignature by following the instructions on the screens.</p>
  <p>If you have any questions, please do not hesitate to contact me at {agentEmail}.</p>
  <p>Thank you for allowing me to handle your financial needs.</p>
  <p>[Optional wording based on the remote signature method selected.]</p>
  <div class="section-divider"></div>
  <p class="font-bold email-head">Consent for Electronic Transmissions</p>
  <p>If you consent, the Lincoln National Life Insurance Company (the "Company") at LincolnFinancial.com, will transmit documents to you related to your application and policy by electronic means, to the extent that electronic transmission is consistent with applicable state and federal law. Any document that we send by electronic means, which complies with applicable law, will have the same force and effect as if that document was sent in paper format.</p>
  <p>This Consent covers all electronic documents and communications as related to an application for life insurance coverage through the Company, which includes, but is not limited to, applications, supplements, administrative forms, 1099's, in-force illustrations, sales ledgers, prospectuses, prospectus supplements, annual and semiannual reports, confirmation statements, annual or quarterly statements, Statement of Additional Information, billing notices, billing-push notifications, and any policyrelated correspondence. This Consent also covers all electronic documents and communications as related to a life insurance policy issued to you by the Company, which includes, but is not limited to the policy and policy delivery notices.</p>
  <p>The Company may communicate your document's availability by email, text or other electronic means or may attach your documents within the communication itself.</p>
  <p>In order to successfully receive electronic transmissions using this web site, it is recommended that your electronic device supports Windows® 8 or above, or Macintosh OSX; Adobe Acrobat Reader; has browser settings such as Microsoft Edge (for Windows, Mac and iOS), Google® Chrome® (Windows only), Apple Safari (For Mac And iOS), or Mozilla Firefox (Windows Or Mac)); a valid email address and security settings that allow per session cookies. The Company recommends that you keep electronic copies or print a copy of your documents.</p>
  <p>The Company will only transmit documents to you electronically if you consent. Such consent is voluntary. You are not required to consent to electronic transmissions if you prefer not to do so. If the policyholder or insured or owner has permitted electronic transmissions in the past, that authorization does not obligate the same procedure regarding this policy as well. If you choose not to opt-in to receive your documents electronically, please contact your agent or the Company by any of the methods shown below.</p>
  <p>If you consent to receive documents electronically, the Company will provide you with a paper copy of any document sent electronically upon request. The Company will not charge a fee for this service.</p>
  <p>If you decide that you do not want to receive documents electronically you are unable to apply for this product, which is available through an electronic process only.</p>
  <p>If your email account, or phone number for text messaging changes, we suggest that you contact your agent or the Company so that the Company's contact information for you remains current and accurate. You may contact us via the Company web page as shown above, or by our toll free telephone number 1-800-487-1485, or by submitting a written request via paper mail with sufficient postage to the Company at 100 N. Greene Street, Greensboro, NC 27401.</p>
  <p>If you consent to the terms outlined above for electronic transmissions, click on the button marked "I consent."</p>
  <p>For purposes of receiving electronically transmitted documents from The Lincoln National Life Insurance Company, your email address is set forth below.</p>`
};

export const AgentThankYou = {
  AGENT_HEADER: 'Thank you',
  INFO_TEXT_1:
    'Thank you for completing the signing process. The signing process is completed for all signers.',
  INFO_TEXT_2: 'Select NEXT to review and submit your case.',
  THANK_YOU: 'Thank you!',
};

export const CaseCompletionThankYou = {
  AGENT_HEADER: 'Thank you',
  INFO_TEXT_1:
    'Your request was submitted successfully.',
  INFO_TEXT_2: 'Once reviewed, an email notification will be sent to the applicant to start the next step.',
  THANK_YOU: 'Thank you!',
};

export const ConsumerThankYou = {
  CONSUMER_HEADER: 'Thank you',
  INFO_TEXT_1:
    'Your signing process was completed successfully.',
  INFO_TEXT_2: 'Once the signing process is completed for all signers, the insured will be sent an email to start the next step.',
  THANK_YOU: 'Thank you!',
};

export const ReviewAndSubmit = {
  PAGE_HEADER: 'Review and Submit',
  INFO_TEXT_1: 'Thank you for completing the signing process. The signing process is completed for all signers.',
  INFO_TEXT_2: 'Select SUBMIT to submit your case to Lincoln Financial. Once the case has been submitted an email will be sent to the insured to start the next step.',
  THANK_YOU: 'Thank you!'
};

export const DeclineToSign = {
  DECLINE_TO_SIGN_HEADER: 'Decline e-Signature Confirmation',
  INFO_TEXT_1: 'The eSignature process for this application has been declined and canceled.',
  INFO_TEXT_2: 'Please contact your representative with any questions or concerns regarding your application.',
  INFO_TEXT_3: 'Please close the window/browser.'
};


export const ConsentConstant = {
  SAFARI_USER_SETTING_MSG_1: 'Before selecting the "Start eSignature" option please verify the setting instructions below.',
  SAFARI_USER_SETTING_MSG_2: 'From the Safari menu, choose browser settings, and select privacy.',
  SAFARI_USER_SETTING_MSG_3: 'Ensure the Prevent Cross-site Tracking option is not selected.',
  START_SIGNATURE_INFO: 'When ready to proceed and electronically sign your forms, click the "Start eSignature" button below.',
  TERM_OF_USE: `<strong>TERMS OF USE</strong><br/><br/>
    <span class='term-heading'>CONDITIONS OF USE</span><br/>
    By using this Web site in relation to an application for insurance with The Lincoln National Life Insurance Company, hereinafter collectively referred to as "the Company", you agree with the following Terms and Conditions Of Use ("Terms") without limitation or qualification. Please read these Conditions carefully before using this Web site. If you do not agree with these Terms, you are not granted permission to use this Web site and must exit this Web site immediately. The Company may revise these Terms at any time by updating this posting. You are bound by any such revisions and should therefore periodically visit this page to review the current Terms governing this Web site.<br/><br/>
    <span class='term-heading'>DISCLAIMER</span><br/>
    TO THE FULLEST EXTENT PERMISSIBLE, THE MATERIALS ON THIS WEB SITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, AND THE COMPANY, AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (WHICH SHALL INCLUDE CAREER AGENTS AND BROKERS) (COLLECTIVELY "REPRESENTATIVES") AND SUPPLIERS DISCLAIM ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS WEB SITE, OR THE SERVER THAT MAKES IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THIS WEB SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. THE INFORMATION AND DESCRIPTIONS CONTAINED HEREIN ARE NOT NECESSARILY INTENDED TO BE COMPLETE DESCRIPTIONS OF ALL TERMS, EXCLUSIONS AND CONDITIONS APPLICABLE TO THE PRODUCTS AND SERVICES, BUT ARE PROVIDED SOLELY FOR GENERAL INFORMATIONAL PURPOSES; PLEASE REFER TO THE ACTUAL POLICY OR THE RELEVANT PRODUCT OR SERVICE AGREEMENT; THIS WEB SITE MAY BE LINKED TO OTHER WEB SITES WHICH ARE NOT MAINTAINED BY THE COMPANY. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, ARE RESPONSIBLE FOR THE CONTENT OF THOSE WEB SITES. THE INCLUSION OF ANY LINK TO SUCH WEB SITES DOES NOT IMPLY APPROVAL OF, OR ENDORSEMENT BY, THE COMPANY, OR ANY OF ITS REPRESENTATIVES OR SUPPLIERS, OF THE WEB SITES OR THE CONTENT THEREOF.<br/><br/>
    <span class='term-heading'>LIMITATION OF LIABILITY</span><br/>
    While the Company and its Representatives or suppliers use reasonable efforts to include accurate and up-to-date information on this Web site, errors or omissions may occur. Under no circumstances shall the Company, or its Representatives or suppliers, be liable to you for any direct, incidental, consequential, indirect, or punitive damages that result from the use of, or the inability to use, the materials on this Web site, even if advised of the possibility of such damages.<br/><br/>
    <span class='term-heading'>JURISDICTION</span><br/>
    Unless otherwise expressly set forth herein, the Company and its Representatives make no representation that materials on this Web site are appropriate or available for use in any location. Those who choose to access this Web site do so at their own initiative. The offer to sell or buy a product is specifically limited to the jurisdiction(s) in which the Company and its Representatives have the authority to offer the insurance products described in this Web site. Some products and services may not be available in all jurisdictions.<br/><br/>
    <span class='term-heading'>TRADEMARKS AND COPYRIGHTS</span><br/>
    All trademarks, service marks, trade names, logos, icons and images are proprietary to the Company or its Representatives or suppliers. Nothing contained on this Web site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the foregoing displayed on this Web site without the written permission of the Company, its Representatives or suppliers, as applicable. Your use of any of the foregoing displayed on this Web site, or any other content on this Web site, except as provided herein, is strictly prohibited and may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.<br/><br/>
    <span class='term-heading'>SOFTWARE LICENSES</span><br/>
    You acknowledge that any software which may be available or provided to you on this Web site may contain technology that is subject to strict controls pursuant to export control laws and regulations of the United States of America and other countries and jurisdictions. You hereby agree that you will not transfer or export such software in violation of such applicable export laws and regulations. Neither the Company, nor its Representatives or suppliers, authorize the downloading or exportation of any software or technical data from this Web site to any jurisdiction prohibited by such export controls laws and regulations.<br/><br/>`,
  CONSENT_EXCEPT_TERM_WEALTH: `If you consent, The Lincoln National Life Insurance Company (the "Company") at www.LFG.com, will transmit documents to you
    related to your application and policy by electronic means, to the extent that electronic transmission is consistent with applicable
    state and federal law. Any document that we send by electronic means, which complies with applicable law, will have the same
    force and effect as if that document was sent in paper format.<br><br>
    This Consent covers all electronic documents and communications as related to an application for life insurance coverage
    through the Company, which includes, but is not limited to, applications, supplements, administrative forms and any policy-related
    correspondence. This Consent also covers all electronic documents and communications as related to a life insurance policy
    issued to you by the Company, which includes, but is not limited to the policy and policy delivery notices.<br><br>
    In order to successfully receive electronic transmissions using this web site, it is recommended that your electronic device
    supports Windows® 8 or above, or Macintosh OSX; Adobe Acrobat Reader; has browser settings such as Microsoft Edge (for
    Windows, Mac and iOS), Google® Chrome® (Windows only), Apple Safari (for Mac and iOS), or Mozilla Firefox (Windows or Mac);
    a valid email address and security settings that allow per session cookies. The Company recommends that you keep electronic
    copies or print a copy of your documents.<br><br>
    The Company will only transmit documents to you electronically if you consent. Such consent is voluntary. You are not required
    to consent to electronic transmissions if you prefer not to do so. If the policyholder or insured or owner has permitted electronic
    transmissions in the past, that authorization does not obligate the same procedure regarding this policy as well. If you choose not
    to consent to receive your documents electronically, please contact your agent or the Company.<br><br>
    If you consent to receive documents electronically, the Company will provide you with a paper copy of any document sent
    electronically upon request. The Company will not charge a fee for this service.<br><br>
    If you want to revoke the consent to receive the documents noted above electronically and want to receive all future documents
    via paper mail, you may notify the Company by any one of the methods shown below; otherwise the consent will continue until
    revoked.<br><br>
    If your email account changes, we suggest that you contact your agent or the Company so that the Company's contact information
    for you remains current and accurate. You may contact us via the Company web page as shown above, or by our toll free
    telephone number 1-800-487-1485, or by submitting a written request via paper mail with sufficient postage to the Company at
    100 N. Green Street, Greensboro, NC 27401.<br><br>
    If you consent to the terms outlined above for electronic transmissions, click on the button marked "I consent." <br><br>`,

  CONSENT_FOR_TERM_WEALTH: `If you consent, The Lincoln National Life Insurance Company (the "Company") at LincolnFinancial.com, will transmit documents
    to you related to your application and policy by electronic means, to the extent that electronic transmission is consistent with
    applicable state and federal law. Any document that we send by electronic means, which complies with applicable law, will have
    the same force and effect as if that document was sent in paper format.<br><br>
    This Consent covers all electronic documents and communications as related to an application for life insurance coverage
    through the Company, which includes, but is not limited to, applications, supplements, administrative forms, 1099's, in-force
    illustrations, sales ledgers, prospectuses, prospectus supplements, annual and semiannual reports, confirmation statements,
    annual or quarterly statements, Statement of Additional Information, billing notices, billing-push notifications, and any policy-related correspondence. This Consent also covers all electronic documents and communications as related to a life insurance
    policy issued to you by the Company, which includes, but is not limited to the policy and policy delivery notices.<br><br>
    The Company may communicate your document\'s availability by email, text or other electronic means or may attach your
    documents within the communication itself.<br><br>
    In order to successfully receive electronic transmissions using this web site, it is recommended that your electronic device
    supports Windows® 8 or above, or Macintosh OSX; Adobe Acrobat Reader; has browser settings such as Microsoft Edge (for
    Windows, Mac and iOS), Google® Chrome® (Windows only), Apple Safari (for Mac and iOS), or Mozilla Firefox (Windows or Mac);
    a valid email address and security settings that allow per session cookies. The Company recommends that you keep electronic
    copies or print a copy of your documents.<br><br>
    The Company will only transmit documents to you electronically if you consent. Such consent is voluntary. You are not required
    to consent to electronic transmissions if you prefer not to do so. If the policyholder or insured or owner has permitted electronic
    transmissions in the past, that authorization does not obligate the same procedure regarding this policy as well. If you choose not
    to opt-in to receive your documents electronically, please contact your agent or the Company by any of the methods shown below.<br><br>
    If you consent to receive documents electronically, the Company will provide you with a paper copy of any document sent
    electronically upon request. The Company will not charge a fee for this service.
    <br><br>
    If you decide that you do not want to receive documents electronically you are unable to apply for this product, which is available
    through an electronic process only.<br><br>
    If your email account, or phone number for text messaging changes, we suggest that you contact your agent or the Company so
    that the Company's contact information for you remains current and accurate. You may contact us via the Company web page
    as shown above, or by our toll free telephone number 1-800-487-1485, or by submitting a written request via paper mail with
    sufficient postage to the Company at 100 N. Greene Street, Greensboro, NC 27401.<br><br>
    If you consent to the terms outlined above for electronic transmissions, click on the button marked "I consent."<br>`,

  CONSENT_INSTRUCTION: `<ol><li>Please review your forms in their entirety for accuracy and to make sure you completely understand and agree with the information provided.</li><li>If you need to change or update any information or if you have questions, please contact your producer before proceeding.</li>
    <li>The Company will only transmit documents to you electronically if you consent. The receipt of your electronically signed documents by the Company will demonstrate that you can access this application in the electronic form provided to you by the Company website.</li>
    <li>I will read all documents that are to be transmitted and electronically signed. I will select the 'I will review' checkbox and 'I Agree' button if I consent to this process. Otherwise I will select the 'I Decline' button.</li></ol>`,

  CONSENT_INFO: `To begin the electronic process, please read the <strong>Consent for Electronic Transmissions</strong> and indicate below if you agree or decline to the terms. We recommend you print and retain a copy of these documents for future reference.`,
  CONSENT_INFO_2: `After reading the document by scrolling to the bottom, please check the corresponding box indicating you have read the document. Then select either "I Agree" or "I Decline". Please scroll to the bottom of each document to access the check boxes.`,
  TERM_OF_USE_TITLE: 'TERMS AND CONDITIONS:',
  CONSENT_ESIGN_TITLE: 'CONSENT FOR ELECTRONIC TRANSMISSIONS:',
  WELCOME_MSG: 'Welcome, <strong>{name}</strong>, {email},',
  INFO_TEXT_1: 'Please do not close this window or click the back button on your browser until the eSignature process is complete or case has been submitted. After you have completed the eSignature process you will be able to view your completed application and save/print a copy.',
  DECLINE_MODAL_HEADING: 'Signature Process Declined',
  DECLINE_INFO_LABEL: 'If you wish to apply electronically please indicate that you have read the Terms of Use and Consent to do Business Electronically.',
  DECLINE_INFO: `By clicking the "Yes, Cancel eSignature Process" button below, you will exit out of the eSignature process. Your agent will be able to address the concerns you may have.`,
  DECLINE_INFO2: `By clicking the "No, Resume eSignature Process" button below, you will be returned to the eSignature process.`,
  DECLINE_CONFIRMATION_MSG: 'Do you wish to decline and cancel the electronic signature process?',
  CONFIRM_BUTTON: 'Yes, Cancel eSignature Process',
  DECLINE_BUTTON: 'No, Resume eSignature Process'
};

export const RedirectConfirmationMessages = {
  MODAL_HEADING: 'Are you sure?',
  INFO_TEXT_1: 'You have unsaved information.',
  INFO_TEXT_2: 'You will lose any changes you\'ve made on this page.',
  INFO_TEXT_3: 'To save updated information, select the Cancel option. Select the Save & Exit option to access the dashboard.'
};

export const LCN = {
  number: 'LCN-6200871-010524'
}

export const OwnerErrors = {
  DRIVER_LICENSE_MATCH_ERR: [
    'The Drivers License or Issue State you have entered does not match what was provided for the Proposed Insured on the',
    'Proposed Insured Information',
    'Screen. Please correct this before moving forward.'
  ]
}

export const IllustrationKeys = {
  WetFormKeys: {
    wetExchange:"/XML/Client/CRMDetails/AdditionalInfo/AttachWetExchangeInd",
    wetProtection:'/XML/Client/CRMDetails/AdditionalInfo/AttachWetProtectionInd',
    wetNotice:'/XML/Client/CRMDetails/AdditionalInfo/AttachWetNoticeInd'
  },
  AttachIllustrationKey: '/XML/Client/CRMDetails/AdditionalInfo/IllustrationInd',
  CustomerIdentityVerificationKey: '/XML/Client/CRMDetails/AdditionalInfo/AttachCIVInd',
  IllustrationMatchesKey: '/XML/AnswerSet/SignedIllustrationMatches',
}

export const IllustrationMessages = {
  AttachIllustrationText: 'Additional Attachments',
  ValidIllustrationMsgText: 'A valid illustration is required to be submitted to Lincoln Financial Group',
  VoiceSignatureDisclosureTemplate: `
    <p>
    The Company allows completion of the MoneyGuard Medical Application Part II, Long-Term Care Application Part III and Continuation of Details supplements with you over the telephone and offers you the ability to sign these MoneyGuard Application supplements using the telephonic process.<br/><br/>
    A legal copy of the MoneyGuard Medical Application Part II, MoneyGuard Long-Term Care Application Part III, MoneyGuard Medical Long-Term Care Supplement and Continuation of Details Supplements will be provided to you as part of your issued policy.<br/><br/>
    This disclosure covers all voice communications arising from the completion of the Life MoneyGuard Medical Application Part II, MoneyGuard Long Term Care Application Part III, MoneyGuard Medical Long-Term Care Supplement and Continuation of Details and is limited to this transaction. If you agree to sign the MoneyGuard Application Supplements using voice signature, the Company may have the right to deny benefits or rescind coverage under the policy and riders if the answers on the MoneyGuard Application Supplements were incorrect or untrue any false statements were made with actual intent to deceive or they materially affected the Company's acceptance of the risk.<br/><br/>
    If you agree to sign the MoneyGuard Application Supplements using voice signature, by checking Yes below, you will have an additional opportunity during the telephone call to reject the use of voice signature, and will be provided the unsigned completed MoneyGuard Application Supplements for your signature.<br/><br/>
    </p>`,
  WetFormInfo: "The wet signed forms should be printed, signed, scanned, and uploaded here to prevent delays in processing. Otherwise, the signed forms will need to be promptly submitted directly to Lincoln prior to the issue of the  proposed policy."
}


export const TABS_AFTER_LOCK_SCREEN = [
  TAB.SIGNATURE_METHOD,
  TAB.E_SIGNATURE_INSTRUCTION,
  TAB.ELECTRONIC_SIGNATURE,
  TAB.CONSENT_PAGE_AGENT,
  TAB.CONSENT_PAGE_PI,
  TAB.CONSENT_PAGE_INSURED_OWNER,
  TAB.CONSENT_PAGE_INSURED_B,
  TAB.CONSENT_PAGE_ADDITIONAL_INSURED,
  TAB.CONSENT_PAGE_COOWNER,
  TAB.CONSENT_PAGE_COOWNER2,
  TAB.CONSENT_PAGE_COOWNER3,
  TAB.CONSENT_PAGE_OWNER,
  TAB.WET_SIGNATURE_INSTRUCTION,
  TAB.CONSENT_PAGE_PRINCIPAL,
  TAB.CONSENT_PAGE_PAYOR,
  TAB.REVIEW_AND_SUBMIT
];

export const WET_SIGN_COLLECTION_INFO_SCREEN = {
  FILE_LIST_TEXT_START: `The information provided for this proposed policy will require the following form(s):`,
  FILE_1: 'Exchange Agreement/Absolute Assignment for Life Insurance',
  FILE_2: 'Notice To Applicant Regarding Replacement of Accident and Sickness or Long-Term Care Insurance (LTC Replacement)',
  FILE_3: 'Protection Against Unintended Lapse',
  FILE_LIST_TEXT_END: 'to be wet signed.',
  MAIN_TEXT_1: 'The forms can be printed for signature by selecting the Form(s) for Wet Signature button. Otherwise, they will be provided prior to the start of the eSignature collection process.',
  MAIN_TEXT_2: 'If wet signatures for the form(s) has/have already been obtained, the wet signed forms can be uploaded by using the available PDF attachment buttons on the Additional Screen or after submitting the proposed policy to Lincoln by mail or fax.',
  MAIN_TEXT_3: 'To prevent delays in processing, please work with your Financial Representative/Agent to provide the needed wet signatures and send the form(s) to Lincoln in the manner that is most convenient and expedient for your situation.'
};

export const MGMA22_AVAILABLE_STATES = ['IN', 'MT'];

export const COMMUNITY_STATES = ['AZ', 'CA', 'ID', 'LA', 'NV', 'NM', 'TX', 'WA', 'WI'];
