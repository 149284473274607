import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { PageJsonObject, Question } from 'src/app/shared/models/casePage.model';
import { SummaryTableConfig } from './summary-table-config';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { ApplicationConfig } from 'src/config/app.config';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { AgentInfoConstant, SummaryConstant } from 'src/config/constants';


@Component({
  selector: 'app-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.scss'],
})
export class ApplicationSummaryComponent implements OnInit {

  @Input() questions: PageJsonObject;

  @Input() isWholesaler: boolean;

  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  @Input() wholeSalerSummaryData: PageJsonObject[];

  tableData;
  tableDataLoaded = false;
  summaryData: Question[] = [];
  dataMap: Map<string, Question[]> = new Map<string, Question[]>();

  openAccordion: string | null = 'caseInfo';
  summaryDataCaseInfoWS: any;
  summaryDataAgentInfoWS: any;
  summaryAgentInfoGridDataWS: any;
  summaryDataPolicyProjectionInfoWS: any;
  summaryDataPolicyProjectionInfoGridWS: any;
  summaryDataExistingInsuranceWS: any;
  summaryExistingInsuranceGridDataWS: any;
  summaryDataOwnerInfoWS: any;
  summaryOwnerGridDataWS: any;

  constructor(private utilsService: UtilsService, private defaultPageLoadService: DefaultPageload, private productService: ProductDetailsService,
    public appConfig: ApplicationConfig) { }

  ngOnInit(): void {
    this.tableData = new SummaryTableConfig().tableData;
    this.dataMap.clear();
    this.updateFormStatus();
    if (!this.isWholesaler) {
      this.reArrangeData();
      this.getAgentInformation();
    } else {
      this.initializeWholeSalerSummary();
    }
    this.defaultPageLoadService.logPageLoad();
  }

  private initializeWholeSalerSummary(): void {
    this.wholeSalerSummaryData.forEach((pageJson) => {
      switch (pageJson.page_group_name) {
        case PAGE_GROUP_NAME.WS_CASE_INFO:
          this.initializeDataForCaseInfo(pageJson);
          break;
        case PAGE_GROUP_NAME.WS_AGENT_INFO:
          this.initializeDataForAgentInfo(pageJson);
          break;
        case PAGE_GROUP_NAME.WS_POLICY_PROJECTION:
          this.initializeDataForPolicyProjection(pageJson);
          break;
        case PAGE_GROUP_NAME.WS_EXISTING_INSURANCE:
          this.initializeDataForExistingIns(pageJson);
          break;
        case PAGE_GROUP_NAME.WS_OWNER_INFO_PAGE:
          this.initializeDataForOwner(pageJson);
          break;
      }
    });
  }

  private reArrangeData(): void {
    this.summaryData = this.questions?.pages[0]?.questions;
    this.summaryData?.forEach((ques) => {
      if (ques.notes && ques.notes.indexOf('col') > -1) {
        if (this.dataMap.get(ques.notes)) {
          this.setData(ques);
        } else {
          ques.question_answer = this.getAnswer(ques.subText, ques.question_answer);
          this.dataMap.set(ques.notes, [ques]);
        }
      }
    });
  }

  setData(ques): void {
    if (!this.checkIfQuestionPresent(ques.notes, ques.subText)) {
      ques.question_answer = this.getAnswer(ques.subText, ques.question_answer);
      if (ques.xmlTag === '/XML/Client/AppDetails/DriverLicenseState') {
        ques.question_answer = this.updateNotApplicableAnswer(ques.question_answer);
      }
      if (ques.xmlTag === '/XML/Client/CRMDetails/StateOfBirth') {
        ques.question_answer = this.updateNotApplicableAnswerStateOfBirth(ques.question_answer);
      }
      this.dataMap.get(ques.notes).push(ques);
    }
  }

  updateNotApplicableAnswer(quesAnswer): string {
    let ansValue = '';
    this.summaryData.forEach((questObj) => {
      if ((questObj.question_answer === '' || questObj.question_answer === 'No') && questObj.xmlTag === '/XML/Client/AppDetails/DriverLicenseNoneChecked') {
        ansValue = 'N/A';
      }
      if (questObj.question_answer !== '' && questObj.question_answer !== 'No' && (questObj.xmlTag === '/XML/Client/AppDetails/DriverLicenseNoneChecked')) {
        ansValue = quesAnswer;
      }
    });
    return ansValue;
  }

  updateNotApplicableAnswerStateOfBirth(quesAnswer): string {
    let ansValue = '';
    this.summaryData.forEach((questObj) => {
      if ((questObj.question_answer === '') && questObj.xmlTag === '/XML/Client/CRMDetails/StateOfBirth') {
        ansValue = 'N/A';
      }
      if (questObj.question_answer !== '' && (questObj.xmlTag === '/XML/Client/CRMDetails/StateOfBirth')) {
        ansValue = quesAnswer;
      }
    });
    return ansValue;
  }

  private getAnswer(type: string, quesAns: string): string {
    if (type?.toLowerCase() === 'name') {
      return this.getInsuredName();
    } else if (type?.toLowerCase() === 'address2') {
      return this.getInsuredAddress2();
    } else if (type?.toLowerCase() === 'address3') {
      return this.getInsuredAddress3();
    } else if (type?.toLowerCase() === 'age') {
      return this.getInsuredAgeDetails();
    } else {
      return quesAns || 'N/A';
    }
  }

  private checkIfQuestionPresent(colNo: string, type: string): boolean {
    const questionAdded = this.dataMap.get(colNo).filter((ques) => {
      return ques.subText?.toLowerCase() === type.toLowerCase() &&
        ques.subText?.toLowerCase() !== 'info';
    });
    return questionAdded.length > 0;
  }

  private getInsuredName(): string {
    let name = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'name') {
        name = name + ques.question_answer + ' ';
      }
    });
    return name || 'N/A';
  }

  private getInsuredAddress2(): string {
    let address = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'address2') {
        address = address + ques.question_answer + ' ';
      }
    });
    return address;
  }

  private getInsuredAddress3(): string {
    let address = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'address3') {
        if (ques.questionText === 'Zip') {
          ques.question_answer = (ques.question_answer?.length === 9)
            ? ques.question_answer?.substring(0, 5) + '-' + ques.question_answer?.substring(5, 9)
            : ques.question_answer;
        }
        address = address + (ques.questionText === 'State' ? ques.question_answer : this.utilsService.toTitleCase(ques.question_answer)) + ' ';
        if (ques.questionText?.toLowerCase() === 'city' && ques.question_answer) {
          address = address.trim() + ', ';
        }
      }
    });
    return address;
  }

  private getInsuredAgeDetails(): string {
    let ageDetails = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'age') {
        ques.question_answer = ques.questionText?.toLowerCase().indexOf('date of birth') > -1
          ? this.utilsService.formatDateToMMDDYYYY(ques.question_answer)
          : ques.question_answer;
        ageDetails = ageDetails + ques.questionText + ' ' + '<strong>' + ques.question_answer + '</strong>' + ' | ';
      }
    });
    return ageDetails ? ageDetails?.substring(0, ageDetails.lastIndexOf(' | ')) : 'N/A';
  }

  private updateFormStatus(): void {
    const summaryInfoObj = {
      formValid: true,
      formHasNoErrors: true,
      questions: this.questions,
      formId: 'summaryInfo',
      gridForm: false,
      isFormChange: false,
    };
    this.formStatus.emit(summaryInfoObj);
  }

  private getAgentInformation(): void {
    const agentInfo = this.geAgentInfo();
    if (agentInfo && agentInfo.length > 0) {
      this.tableData?.data?.push(...agentInfo);
    }
    this.tableDataLoaded = true;
  }

  private geAgentInfo(): any[] {
    const agentList = [];
    let agentEmail = 'N/A';
    let agentPhone = 'N/A';
    const gridAnswer = this.questions?.pages[0]?.gridAnswers;
    gridAnswer?.forEach((ans) => {
      const agentName = (ans[SummaryConstant.agentFirstName] + ' ' + ans[SummaryConstant.agentLastName])?.trim();
      agentEmail = ans[SummaryConstant.agentEmail] || 'N/A';
      agentPhone = ans[SummaryConstant.agentPhone] || 'N/A';
      if (agentName) {
        agentList.push({ agentName, agentEmail, agentPhone });
      }
    });
    return agentList;
  }

  getagentInfoObj(agentName, agentEmail, agentPhone): any {
    if (agentName.trim().length > 0 || agentPhone.trim().length > 0 || agentEmail.trim().length > 0) {
      return { agentName, agentEmail, agentPhone };
    }
  }

  toggleAccordionStatus(section: string): void {
    if (this.openAccordion === section) {
      this.openAccordion = null;
    }
    else {
      this.openAccordion = section;
    }

  }

  private initializeDataForAgentInfo(pageJson: PageJsonObject): void {
    this.summaryDataAgentInfoWS = pageJson.pages[0];
    const questionWithReflexive = [];
    this.getAllReflexiveQuestions(this.summaryDataAgentInfoWS.questions, questionWithReflexive, undefined, false);
    this.summaryDataAgentInfoWS.questions = questionWithReflexive;
    this.updateQuestionAnswerForName(this.summaryDataAgentInfoWS?.questions);

    this.summaryAgentInfoGridDataWS = this.getGridValueMap(pageJson);
    this.summaryDataAgentInfoWS.questions = this.summaryDataAgentInfoWS.questions.concat(this.summaryAgentInfoGridDataWS[0]);
    this.loadAdditionalAgentData();
  }

  private loadAdditionalAgentData(): void {
    const agentList = [];
    this.summaryAgentInfoGridDataWS.forEach(additionalAgentInfo => {
      let agentPercent, agentName;
      additionalAgentInfo?.forEach(ques => {
        if (ques.xmlTag === AgentInfoConstant.splitMainAgentQuesId) {
          agentPercent = ques.question_answer || 'N/A';
        } else if (ques.xmlTag === AgentInfoConstant.agentFirstNameQuesId) {
          agentName = ques.question_answer || 'N/A';
        }
      });
      agentList.push({ agentName, agentEmail: 'N/A', agentPhone: 'N/A', agentPercent });
    });
    agentList.splice(0, 1);
    if (agentList?.length > 0) {
      this.tableData?.data?.push(...agentList);
    }
    this.tableDataLoaded = true;
  }

  private initializeDataForCaseInfo(pageJson: PageJsonObject): void {
    this.summaryDataCaseInfoWS = pageJson.pages[0];
    const questionWithReflexive = [];
    this.getAllReflexiveQuestions(this.summaryDataCaseInfoWS.questions, questionWithReflexive, undefined, false);
    this.summaryDataCaseInfoWS.questions = questionWithReflexive;
    this.updateQuestionAnswerForName(this.summaryDataCaseInfoWS?.questions);
    this.updateQuestionAnswerForProduct(this.summaryDataCaseInfoWS?.questions);
  }

  private initializeDataForExistingIns(pageJson: PageJsonObject): void {
    this.summaryDataExistingInsuranceWS = pageJson.pages[0];
    this.summaryExistingInsuranceGridDataWS = this.getGridValueMap(pageJson);
  }

  private initializeDataForOwner(pageJson: PageJsonObject): void {
    this.summaryDataOwnerInfoWS = pageJson.pages[0];
    this.summaryOwnerGridDataWS = this.getGridValueMap(pageJson);
  }

  private initializeDataForPolicyProjection(pageJson: PageJsonObject): void {
    this.summaryDataPolicyProjectionInfoWS = pageJson.pages[0];
    const questionWithReflexive = [];
    this.getAllReflexiveQuestions(this.summaryDataPolicyProjectionInfoWS.questions, questionWithReflexive, undefined, true);
    this.summaryDataPolicyProjectionInfoWS.questions = questionWithReflexive;
    this.summaryDataPolicyProjectionInfoGridWS = this.getGridValueMap(pageJson);
  }

  private getGridValueMap(pageJson: PageJsonObject): any[] {
    let gridAnswerList = pageJson.pages[0]?.gridAnswers;
    const gridArrayListWithAnswer = []
    gridAnswerList?.forEach((gridAnswer) => {
      let gridQuestionList = [];
      this.getGridQuestionList(pageJson.pages[0]?.gridInstances[0]?.gridQuestions, gridQuestionList, gridAnswer);
      this.updateQuestionAnswerForName(gridQuestionList);
      gridArrayListWithAnswer.push(gridQuestionList);
    });
    return gridArrayListWithAnswer;
  }

  private getAllReflexiveQuestions(questionList: any[], questionListWithReflexive: Question[], parentQuesAnswer?: string, retainHDRQues?: boolean): void {
    questionList?.forEach(ques => {
      if ((!ques?.optionChoice || (ques.optionChoice && parentQuesAnswer === ques.optionChoice)) && this.isQuestionFilterPass(ques, retainHDRQues)) {
        if (ques.controlTypeDesc === this.appConfig.fieldTypes.DOB) {
          const date = ques.question_answer;
          ques.question_answer = (date ? new Date(parseInt(date.slice(0, 4)), parseInt(date.slice(4, 6)) - 1, parseInt(date.slice(6, 8))) : '').toString();
        } else if (ques.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
          this.getDataFromOptions(ques);
        }
        questionListWithReflexive.push({ ...ques });
      }
      if (ques.hasReflexive && ques.reflexiveQuestionAL?.length > 0) {
        this.getAllReflexiveQuestions(ques.reflexiveQuestionAL, questionListWithReflexive, ques.question_answer);
      }
    });
  }

  private getGridQuestionList(gridQuestions: any[], gridQuestionList: Question[], gridAnswer: Map<string, string>, parentQuesAnswer?: string): void {
    gridQuestions?.forEach(ques => {
      if ((!ques?.optionChoice || (ques.optionChoice && parentQuesAnswer === ques.optionChoice)) && this.isQuestionFilterPass(ques)) {
        if (ques.controlTypeDesc === this.appConfig.fieldTypes.DOB) {
          const date = gridAnswer[ques.name];
          ques.question_answer = date ? new Date(date.slice(0, 4), parseInt(date.slice(4, 6)) - 1, date.slice(6, 8)) : '';
        } else if (ques.controlTypeDesc === this.appConfig.fieldTypes.SELECT || ques.controlTypeDesc === this.appConfig.fieldTypes.RADIO || ques.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
          this.getDataFromOptions(ques, gridAnswer[ques.name]);
        } else {
          ques.question_answer = gridAnswer[ques.name];
        }
        gridQuestionList.push({ ...ques });
      }
      if (ques.hasReflexive && ques.reflexiveQuestionAL?.length > 0) {
        this.getGridQuestionList(ques.reflexiveQuestionAL, gridQuestionList, gridAnswer, gridAnswer[ques.name]);
      }
    });
  }

  private isQuestionFilterPass(ques: Question, retainHDRQues?: boolean): boolean {
    return (retainHDRQues || ques.controlTypeDesc !== this.appConfig.fieldTypes.HDR) && ques.controlTypeDesc !== this.appConfig.fieldTypes.SSN &&
      ques.questionText !== 'DO NOT DISPLAY' && ques.controlTypeDesc !== this.appConfig.fieldTypes.USAADDRESS;
  }

  private updateQuestionAnswerForProduct(quesList: Question[]): void {
    quesList.forEach(ques => {
      if (ques.questionText?.toLowerCase()?.indexOf('selected product') > -1) {
        ques.question_answer = this.productService.getProductName();
      }
    });
  }

  private updateQuestionAnswerForName(gridQuestionList: Question[]): void {
    let firstNameInd, lastNameInd, middleNameInd, suffixInd, firstName, middleName, lastName, suffix;
    gridQuestionList.forEach((question, i) => {
      if (question.questionText?.toLowerCase().indexOf('first name') > -1) {
        firstNameInd = i;
        firstName = question.question_answer;
      } else if (question.questionText?.toLowerCase().indexOf('middle name') > -1 || question.questionText?.toLowerCase().indexOf('middle initial') > -1) {
        middleNameInd = i;
        middleName = question.question_answer;
      } else if (question.questionText?.toLowerCase().indexOf('last name') > -1) {
        lastNameInd = i;
        lastName = question.question_answer;
      } else if (question.questionText?.toLowerCase().indexOf('suffix') > -1) {
        suffixInd = i;
        suffix = question.question_answer;
      }
    });
    if (firstNameInd != undefined) {
      gridQuestionList[firstNameInd].questionText = gridQuestionList[firstNameInd]?.questionText?.replace("First", "Full");
      gridQuestionList[firstNameInd].question_answer = this.getFullName(firstName, middleName, lastName, suffix);
    }
    if (middleNameInd) {
      gridQuestionList.splice(middleNameInd, 1);
      lastNameInd--;
      suffixInd--;
    }
    if (lastNameInd) {
      gridQuestionList.splice(lastNameInd, 1);
      suffixInd--;
    }
    if (suffixInd) {
      gridQuestionList.splice(suffixInd, 1);
    }
  }

  private getFullName(firstName: string, middleName: string, lastName: string, suffix: string): string {
    return (firstName ? firstName + ' ' : '') + (middleName ? middleName + ' ' : '') + (lastName ? lastName + ' ' : '') + (suffix ? suffix + ' ' : '')
  }

  private getDataFromOptions(ques: Question, answer?: string): void {
    if (ques.question_answer || answer) {
      ques.question_answer = ques.questionOption?.filter((option) => {
        return ques.question_answer === option.value || answer === option.value;
      })[0]?.description;
    }
  }
}
