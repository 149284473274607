import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Message } from 'src/config/constants';

@Component({
  selector: 'app-base-page-welcome',
  templateUrl: './base-page-welcome.component.html',
  styleUrls: ['./base-page-welcome.component.scss'],
})
export class BasePageWelcomeComponent implements OnInit, OnChanges {

  @Input()
  isWholesaler: boolean;
  continueCaseMsg: string;
  welcomeMsg: string;
  requiredQuesMsg: string;
  readMore = true;
  illustrationHeaderText = 'MoneyGuard Wholesaler App Fulfillment Tool';
  constructor() {
    // write code here
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isWholesaler?.currentValue != changes?.isWholesaler.previousValue) {
      this.welcomeMsg = this.isWholesaler ? Message.WELCOME_MSG_WHOLESALER : Message.WELCOME_MSG;
    }
  }

  ngOnInit(): void {
    this.welcomeMsg = this.isWholesaler ? Message.WELCOME_MSG_WHOLESALER : Message.WELCOME_MSG;
    this.requiredQuesMsg = Message.REQUIRED_QUES_MSG;
    this.continueCaseMsg = Message.CASE_TO_BE_ADDED_MSG;
  }

  linkClicked(): void {
    this.readMore = !this.readMore;
  }
}
