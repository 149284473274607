<section class="agent-Info-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
        Agent Information
        <span [ngClass]="{'invalid-split':totalSplitPercentage!==100}" class="split-percent">
          <strong>Total Split:
            <span>{{totalSplitPercentage}}%</span></strong>
        </span>
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <lfg-alert-message *ngIf="duplicateSSNExists" type="error">{{AgentValMsg.DUPLICATE_SSN}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="agentInfoForm" id="agentInfoForm" method="post" *ngIf="agentInfoData && agentInfoData.length > 0">

        <div class="loader-container" *ngIf="showLoader > 0">
          <lfg-loader></lfg-loader>
        </div>

        <ng-container class="form-control-parent" *ngFor="let data of agentInfoData; let ind = index;" >

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Button' && data.questionText != 'Search for Firms'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <!-- show question if text is long-->
          <div class="questionText" *ngIf="addAgentCorrectPlaceHolder(data, 'display')">
            <span [innerHTML]="addAgentCorrectPlaceHolder(data, 'question')"></span>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- element to switch controls to new line -->
          <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((data.questionText).toLowerCase()) !== -1">
          </div>

          <!-- USA address -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
            <h2>{{ data.questionText }}</h2>
          </div>

          <!-- Form element for percentage  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes === 'ITES_FORMAT_AS_PERCENTAGE'">
            <lfg-input-wrap>
              <input [ngClass]="{'inp-txt--err': (agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid) }" lfgInput placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols appPercentageFormat [maxlength]="data.maxLength" [required]="data.required == 'true'" customAttr="numbersWithPeriodPercent">
              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).errors?.percentError" message="Split must be equal to 100%."></lfg-error>
              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes !== 'ITES_FORMAT_AS_ZIPCODE' && data.notes !== 'ITES_FORMAT_AS_CURRENCY' && data.notes !== 'ITES_FORMAT_AS_PERCENTAGE'">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" customAttr="text" appValidateOnBlur appNoSpecialSymbols [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for SSN -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='agentInfoForm.get(data.fieldId)' autocomplete="off" appNoSpecialSymbols customAttr="numbersWithHyphen" lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="data.required == 'true'" (input)="checkIfSSNIsValidAndResetValues()">

              <lfg-error *ngIf="(agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid) && ( agentInfoForm.get(data.fieldId).errors == null || (agentInfoForm.get(data.fieldId).errors != null && !agentInfoForm.get(data.fieldId).errors.notUnique))" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO"
            [ngClass]="{'radio--err': (agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
              <div class="radio-btn-rect custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onAgentRadioChange(data)"/>
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onAgentRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag != AgentConst.firmNameQuesId">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleAgentSelectionChange(data, ind)" [required]="data.required == 'true'"
            [ngClass]="{'dropdown--err':(agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid)}">
            </lfg-dropdown>
            <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <!-- Form element for Firmlist Drop Down -->
          <div class="form--control form-dropDown" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag == AgentConst.firmNameQuesId">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="firmOptions" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleAgentSelectionChange(data, ind)" [required]="data.required == 'true'"
              [ngClass]="disableFirmDropDown ? 'lfg-dropdown--disabled' : ''">
            </lfg-dropdown>
            <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid && !disableFirmDropDown" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <!-- Form element for phone number -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
              <lfg-error *ngIf="(agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for email  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">

              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">
              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for zip  -->
          <div class="form--control" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes === 'ITES_FORMAT_AS_ZIPCODE'">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" mask='00000' id="{{data.fieldId}}"
                placeholder="{{ utilsService.getCorrectContent(data, 'placeHolder') }}" appNoSpecialSymbols
                type='text' [formControl]='agentInfoForm.get(data.fieldId)'
                autocomplete="off" appValidateOnBlur value="" maxlength='5'
                [ngClass]="{'inp-txt--err': (agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid) }"
                [required]="data.required === 'true'">

              <lfg-error
                *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid"
                message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Question Info -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Form element for search Button -->
          <div class="form--control form--length-btn search-btn" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText === 'Search for Firms'">
            <button id="{{displayIdCreation(data)}}_{{ind}}" (click)="loadFirmDetails(); focusOff($event);" class="btn focusOffBtn" [disabled]="!agentSSNValid || disableForm">{{data.questionText}}</button>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && agentInfoForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
            <ng-container *ngFor="let agentInfoChild1 of data.reflexiveQuestionAL; index as agentInfoChild1ix">
              <ng-container *ngIf="nestedQuestionCheckAgentInfo(data, agentInfoChild1, parentAnswer)">

                <!-- show question if text is long-->
                <div class="questionText" *ngIf="addAgentCorrectPlaceHolder(agentInfoChild1, 'display')">
                  <span [innerHTML]="addAgentCorrectPlaceHolder(agentInfoChild1, 'question')"></span>
                </div>

                <!-- Form element for text  -->
                <div class="form--control" aria-live="assertive" *ngIf="agentInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && agentInfoChild1.xmlTag != AgentConst.splitMainAgentQuesId">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(agentInfoChild1) }}" placeholder="{{ addAgentCorrectPlaceHolder(agentInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ agentInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="agentInfoChild1.maxLength" [required]="agentInfoChild1.required == 'true'">
                    <lfg-error *ngIf="agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).invalid"  message="{{ getErrorMsg(agentInfoChild1, agentInfoForm.get(agentInfoChild1.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for email  -->
                <div class="form--control" aria-live="assertive" *ngIf="agentInfoChild1.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                  <lfg-input-wrap>
                    <input lfgInput placeholder="{{ addAgentCorrectPlaceHolder(agentInfoChild1, 'placeHolder') }}" name="{{ displayTextLabel(agentInfoChild1) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ agentInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="agentInfoChild1.required == 'true'">

                    <lfg-error *ngIf="agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).invalid" message="{{ getErrorMsg(agentInfoChild1, agentInfoForm.get(agentInfoChild1.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for phone number -->
                <div class="form--control" aria-live="assertive" *ngIf="agentInfoChild1.controlTypeDesc == appConfig.fieldTypes.PHONE">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" placeholder="{{ addAgentCorrectPlaceHolder(agentInfoChild1, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ agentInfoChild1.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="agentInfoChild1.required == 'true'">
                    <lfg-error *ngIf="(agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).invalid)" message="{{ getErrorMsg(agentInfoChild1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

              </ng-container>
            </ng-container>
          </div>

        </ng-container>

        <div *ngIf="firmUpdatedForSSN.length === ssnListFromGridAns.length">
          <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)"
          [addAccordionText]="'Add Agent'" [maxGridAvailable]="maxNoOfAgent" [accordionHeader]="'Agent'" [type]="'AGENT'" [isRevisit]="isTabVisited" 
          [disableForm]="disableForm" [name]="'Agent'"></app-grid-structure>
        </div>

        <div></div>

         <!-- Form element for validate Button -->
         <div class="form--control validate-btn form--control__full-width">
          <button id="validate-agent-btn" (click)="validateAgents($event); focusOff($event);" class="btn focusOffBtn" aria-label="Validate License and Appointment" [disabled]="!isAgentFormValid || disableForm">Validate License and Appointment</button>
        </div>

      </form>
    </section>
  </div>
</section>

<app-agent-validation-popup  *ngIf="action == 'openModal'" [questions]="agentFormQuestionData" [noOfFailedAgents]="noOfFailedAgents" [message]="validationPopupConfig.main" [action]="action" (updateModalAction)="updateModalAction($event)" [additionalText]="validationPopupConfig.additional" [okButtonMessage]="validationPopupConfig.okButtonMessage"></app-agent-validation-popup>
