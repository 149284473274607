import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DelegationService } from 'src/app/shared/services/delegation.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { AgentInfoConstant, AgentInfoContConstant, ExistingInsuranceConstant, OwnerInfoConstant, WSAgentInfoConstant } from 'src/config/constants';
import { ACCESS_TYPE } from 'src/config/delegationFunctionality.config';

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss'],
})
export class GridHeaderComponent implements OnInit {
  @Input() disableForm: boolean;
  @Input() gridForm: FormGroup;
  @Input() grid;
  @Input() gridSequences: any[];
  @Input() index: number;
  @Input() type: string;
  @Input() isGridValid = false;
  @Input() name: string;
  @Input() percentage: string;
  @Input() validPercentage: boolean;
  @Input() showOwner: boolean;
  @Input() disableDelete: boolean;
  @Input() isWholesaler: boolean;
  @Output() removeGrid: EventEmitter<any> = new EventEmitter();

  isStateDE = false;

  readonly EI_CONST = ExistingInsuranceConstant;
  AI_CONST;
  readonly AIC_CONST = AgentInfoContConstant;
  readonly OWNER_CONST = OwnerInfoConstant;

  readAccessOnly = false;

  constructor(private userService: UserDetailsService, private delegateService: DelegationService) {
    // write code here
  }

  ngOnInit(): void {
    this.isStateDE = this.userService.getInsuredState() === 'DE';
    this.readAccessOnly = this.delegateService.getUserAccessibilityConfig()?.accessType === ACCESS_TYPE.READONLY;
    this.AI_CONST = this.isWholesaler ? WSAgentInfoConstant : AgentInfoConstant;
  }

  removeGridInstance(obj: any, index?: number, deletedSequence?: boolean): void {
    const removeGridEvent = { obj, index, deletedSequence };
    this.removeGrid.emit(removeGridEvent);
  }

  getValue(value: string): string {
    return value?.substring(0, value.indexOf('{'));
  }
}
