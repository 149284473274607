import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ApplicationConfig } from 'src/config/app.config';
import { AgentInfoConstant, AgentInfoContConstant } from 'src/config/constants';
import { Question } from '../models/casePage.model';
import { WindowRefService } from '@ng/lfg-common-utilities';


@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  formData: Map<string, string> = new Map<string, string>();

  // below methods are for pdf generation and showing base64 as pdf
  async getPdf(basedata: string, name: string, downloadDoc: boolean): Promise<void> {
    try {
      const data = this.createPdfFromBase64(basedata);
      let link;
      if (downloadDoc) {
        link = this.createDownloadLink(name, data);
      }
      const newWindow = this.windowRef.getWindow();
      newWindow.open('', name);
      newWindow.open(data, name);
      this.closeResources(link, data, newWindow);
    } catch (error) {
      console.log(error);
    }
  }

  savePdfData(caseId: string, basedata: string): void {
    this.formData.set(caseId, basedata);
  }

  clearPdfData(): void {
    this.formData.clear();
  }

  getPdfData(caseId: string): string {
    return this.formData.get(caseId);
  }

  // for deleting old pdf data when unlocking the case
  deletePdfData(caseId: string): void {
    this.formData.delete(caseId);
  } 

  private createDownloadLink(name: string, data: any): any {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    return link;
  }

  private closeResources(link: any, data: any, newWindow: any): void {
    setTimeout(() => {
      newWindow.URL.revokeObjectURL(data);
      window.URL.revokeObjectURL(data);
      link?.remove();
    }, 900000);
  }

  answerTypeMap = {
    'Numeric only': 'numbersOnly',
    Alphanumeric: 'alphaNumeric',
  };
  questionIdAndTagMap: Map<string, string> = new Map<string, string>();
  constructor(
    private appConfig: ApplicationConfig,
    private windowRef: WindowRefService
  ) { }

  formatNamesforSpace(val): any {
    if (!val || typeof(val) !== 'string') {
      return val;
    }
    val?.trim();
    let formattedName = '';
    const keys = val.split(' ');
    keys.forEach((key) => {
      if (key.trim() !== '') {
        formattedName = formattedName + ' ' + key;
      }
    });
    formattedName = formattedName.replace(/%/g, '');
    return formattedName?.trim();
  }

  formatDateToYYYYMMDD(val): any {
    if (val && val?.length > 8) {
      val = val.replaceAll('/', '');
    }
    if (val && val?.length === 8) {
      return val.slice(4, 8) + val.slice(0, 2) + val.slice(2, 4);
    }
    return val;
  }

  formatDateToMMDDYYYY(val): any {
    if (val && val?.length === 8) {
      return val.slice(4, 6) + '/' + val.slice(6, 8) + '/' + val.slice(0, 4);
    }
    return val;
  }

  getCorrectContent(data, type): any {
    const ft = this.appConfig.fieldTypes;
    const nonCheckControls = [ft.HDR, ft.RADIO, ft.NONEDITABLE, ft.USAADDRESS, ft.CHECKBOX];
    if (nonCheckControls.indexOf(data.controlTypeDesc) >= 0) {
      return false;
    }
    const subtext = data.subText ? ' ' + data.subText : '';
    let questionText = data.questionText + subtext;
    let separate = false;
    if (questionText.length >= 28) {
      separate = true;
    }
    questionText = questionText + (data.required === 'true' ? '' : ' (optional)');
    return this.returnContentBasedOnType(type, data, separate, questionText);
  }

  private returnContentBasedOnType(type, data, separate, questionText): any {
    if (type === 'placeHolder') {
      return this.returnContentBasedOnControlType(data, separate, questionText);
    } else if (type === 'question') {
      return questionText;
    } else {
      return separate;
    }
  }

  private returnContentBasedOnControlType(
    data: any,
    separate: any,
    questionText: any
  ): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return separate ? 'Select' : questionText;
    } else {
      const questionTemp = questionText
        ? questionText.replace(/<I>/g, '').replace(/<\/I>/g, '')
        : questionText;
      return separate ? 'Please provide details' : questionTemp;
    }
  }

  // pass format = true if phone to be formmated as (111) 111-1234 or 1 (987) 654-1234
  phoneNumberFormat(value, format): any {
    if (value !== null && value !== undefined) {
      value = value.split(' ').join('');
      value = value.split('(').join('');
      value = value.split(')').join('');
      value = value.split('-').join('');
      if (!format) {
        return value;
      }
      if (value.length === 10) {
        value =
          value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
      } else if (value.length === 11) {
        value =
          value.slice(0, 1) +
          ' (' +
          value.slice(1, 4) +
          ') ' +
          value.slice(4, 7) +
          '-' +
          value.slice(7);
      }
    }
    return value;
  }

  formatOptions(options): any {
    const newOptions = [];
    for (const option of options) {
      const obj = {
        label: option.description,
        value: option.value,
        checked: false,
      };
      newOptions.push(obj);
    }
    return newOptions;
  }

  getInlineErrorMessage(data: Question, formControl?: FormControl): any {
    if (!data) { return; }
    if (data?.controlTypeDesc === this.appConfig.fieldTypes.CURRENCY
      || (data?.controlTypeDesc === this.appConfig.fieldTypes.TEXT && data.notes?.indexOf('ITES_CURRENCY_USD') > -1)) {
      return this.getErrorMessageForCurrency(data, formControl);
    } else if (
      data?.controlTypeDesc === this.appConfig.fieldTypes.SELECT ||
      data?.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX
    ) {
      return this.getErrorMessageForDropdown(data, formControl);
    } else if (data?.controlTypeDesc === this.appConfig.fieldTypes.RADIO) {
      return this.getErrorMessageForRadio(formControl, data);
    } else if (data?.controlTypeDesc === this.appConfig.fieldTypes.HDR) {
      return '';
    } else if (data?.controlTypeDesc === this.appConfig.fieldTypes.TEXT) {
      return this.getErrorMessageForText(data, formControl);
    }
    else {
      const errorMsg = 'Please enter a valid ';
      return this.getInlineErrorsWords(data, errorMsg);
    }
  }

  private getErrorMessageForRadio(formControl: FormControl, data: Question): any {
    const errorMsg = 'Please select a valid option.';
    if (formControl?.errors?.attestError) {
      if (data.xmlTag === AgentInfoConstant.doYouattestTag ||
        data.xmlTag === AgentInfoContConstant.caseManagerDoYouAttestQuesId) {
        return this.appConfig.errorMessages.I_ATTEST_ERROR_MESSAGE;
      } else if (data.xmlTag === AgentInfoContConstant.officeStaffDoYouAttestQuesId) {
        return this.appConfig.errorMessages.I_ATTEST_ALL_STATE_ERROR_MESSAGE;
      } else if (data.xmlTag === AgentInfoContConstant.knowEnglishQuesId) {
        return this.appConfig.errorMessages.KNOW_ENGLISH_ERROR_MESSAGE;
      }
    }
    return errorMsg;
  }

  private getErrorMessageForText(data: Question, formControl: FormControl): any {
    let errorMsg = 'Please enter a valid ';
    if (formControl?.errors?.noOfDigitError) {
      return this.appConfig.errorMessages.ALL_STATE_AGENT_ERROR_MESSAGE;
    }

    return this.getInlineErrorsWords(data, errorMsg);
  }

  getInlineErrorsWords(data: Question, errorMsg: string): any {
    const inlineErrorWords = this.appConfig.inlineErrorWords;
    let matchFound = false;
    for (const key of inlineErrorWords) {
      if (data.questionText && data.questionText.toLowerCase().indexOf(key) >= 0) {
        matchFound = true;
        break;
      }
    }
    return matchFound ? `${errorMsg}response.` : `${errorMsg + data.questionText.toLowerCase().replace(':', '')}.`;
  }

  getErrorMessageForCurrency(
    data: Question,
    formControl?: FormControl
  ): string {
    const errorMsg =
      'Please enter a valid ';
    if (formControl?.errors?.maxAmountError || formControl?.errors?.faceAmountError) {
      return this.appConfig.errorMessages.COVERAGE_AMOUNT_ERROR;
    } else if (formControl?.errors?.minAmountError) {
      return this.appConfig.errorMessages.MIN_AMOUNT_ERROR;
    }
    return this.getInlineErrorsWords(data, errorMsg);;
  }

  getErrorMessageForDropdown(
    _data: Question,
    formControl?: FormControl
  ): string {
    const errorMsg = 'Please select a valid option.';
    if (formControl?.errors?.dboError) {
      return this.appConfig.errorMessages.DBO_ERROR;
    }
    if (formControl?.errors?.stateError) {
      return this.appConfig.errorMessages.STATE_ERROR;
    }
    if (formControl?.errors?.termAgeError) {
      return this.appConfig.errorMessages.TERM_AGE_ERROR;
    }
    if (formControl?.errors?.nonTermAgeError) {
      return this.appConfig.errorMessages.NON_TERM_AGE_ERROR;
    }
    return errorMsg;
  }

  getDropDownCheckboxValue(answerString, questionOption): any {
    const answerToreturn = [];
    // if answerString in null or blank then set all option checked false
    if (!answerString || answerString === '') {
      questionOption.forEach((element) => {
        const obj = {
          label: element.description,
          value: element.value,
          checked: false,
        };
        answerToreturn.push(obj);
      });
      return [answerToreturn];
    }
    // if answers already available then upate all the option based on answer, answered one will be marked checked
    const answerArray = answerString.split(';');
    questionOption.forEach((element) => {
      const option = answerArray.filter((el) => el === element.value);
      if (option.length > 0) {
        const obj = {
          label: element.description,
          value: element.value,
          checked: true,
        };
        answerToreturn.push(obj);
      } else {
        const obj = {
          label: element.description,
          value: element.value,
          checked: false,
        };
        answerToreturn.push(obj);
      }
    });
    return [answerToreturn];
  }

  getFieldId(data: any, key: any, type?: string): any {
    let field: any;
    for (let element of data){
      if (element.xmlTag === key) {
        return element.fieldId;
      }
      if (element.hasReflexive === 'true') {
        const childData = element.reflexiveQuestionAL;
        const fieldId = this.getFieldId(childData,key)
        if (fieldId){
          return fieldId;
        }
      }
    }
    return field
  }

  getNameByXmlTag(data: Question[], xmlTag: string): string {
    const quest = data.find(ques => {
      if (ques.xmlTag === xmlTag) {
        return ques;
      }
    });
    return quest ? quest.name : null;
  }

  getFieldByXmlTag(data: Question[], xmlTag: string): string {
    const quest = data.find(ques => {
      if (ques.xmlTag === xmlTag) {
        return ques;
      }
    });
    return quest ? quest.fieldId : null;
  }

  getFieldIdForAll(data, key): any {
    const fieldData = [];
    data.filter((element1) => {
      this.updateFieldData(element1, key, fieldData);
      if (element1.hasReflexive === 'true') {
        const childData = element1.reflexiveQuestionAL;
        childData.filter((element2) => {
          this.updateFieldData(element2, key, fieldData);
          if (element2.hasReflexive === 'true') {
            const childData1 = element2.reflexiveQuestionAL;
            childData1.filter((element3) => {
              this.updateFieldData(element3, key, fieldData);
            });
          }
        });
      }
    });
    return fieldData && fieldData.length === 1
      ? fieldData[0].fieldId
      : fieldData;
  }

  clearAllRootQuestionAnswer(data): any {
    for (const item of data) {
      if (item.question_answer && item.question_answer !== null) {
        item.question_answer = null;
      }
      if (item.hasReflexive === 'true') {
        this.clearAllRootQuestionAnswer(item.reflexiveQuestionAL);
      }
    }
  }

  createObjforDropdown(val): any {
    if (val) {
      return {
        label: val,
        value: val,
      };
    } else {
      return null;
    }
  }

  /**
   * common method to show error onload
   * @param componentForm input form
   */
  showErrorIfAnyOnLoad(componentForm): any {
    if (componentForm && componentForm.invalid) {
      Object.keys(componentForm.controls).forEach((ele) => {
        if (
          componentForm.controls[ele].value &&
          componentForm.controls[ele].invalid
        ) {
          componentForm.controls[ele]?.markAsTouched();
        }
      });
    }
  }

  getCodeFromValue(value: string): string {
    return value?.split('{')[0];
  }

  getAnswerMatchingWithDropDownOption(answerString, questionOption): any {
    if (!answerString || answerString === '') {
      return '';
    }
    let matchingAns = '';
    const option = questionOption.find(
      (el) =>
        el.value.indexOf(answerString) >= 0 ||
        el.description.indexOf(answerString) >= 0
    );
    if (option) {
      matchingAns = option.value;
    }
    return matchingAns;
  }

  private updateFieldData(element: any, key: any, fieldData: any[]): void {
    if (element.fieldId === key + '_~_' + element.name) {
      fieldData.push(element);
    }
  }

  public getAnswerType(key: string): string {
    return this.answerTypeMap[key] ? this.answerTypeMap[key] : 'textOnly';
  }

  public getCamelCase(val: string): string {
    if (val) {
      return val.charAt(0)?.toUpperCase() + val.substring(1)?.toLowerCase();
    }
    return '';
  }

  public updateQuestionMap(quesList: any[], pageGroupName: string): void {
    quesList?.forEach(ques => {
      if (ques.xmlTag && ques.xmlTag !== '') {
        this.questionIdAndTagMap.set(pageGroupName + ':' + ques.xmlTag, ques.fieldId);
      }
      if (ques.reflexiveQuestionAL && ques.reflexiveQuestionAL.length > 0) {
        this.updateQuestionMap(ques.reflexiveQuestionAL, pageGroupName);
      }
    });
  }

  public clearQuestionMap(): void {
    this.questionIdAndTagMap.clear();
  }

  public getFieldIdByTag(xmlTag: string, pageGroupName: string): string {
    return this.questionIdAndTagMap.get(pageGroupName + ':' + xmlTag);
  }

  public toBase64(file: File): Promise<any> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<any>((resolve) => {
      reader.onload = () => {
        let generated64 = reader.result.toString();
        generated64 = generated64.split(',').pop();
        resolve(generated64);
      };
    });
  }

  public createPdfFromBase64(baseData: string): any {
    const binary = atob(baseData.replace(/\s/g, ''));
    const view = new Uint8Array(new ArrayBuffer(binary.length));
    for (let i = 0; i < binary.length; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], {
      type: 'application/pdf',
    });
    return window.URL.createObjectURL(blob);
  }

  public openPdf(basedata: string, tabName?: string, existingTab?: any): any {
    try {
      if(existingTab && !existingTab.closed){
        existingTab.focus();
        return existingTab
      }else{
        const data = this.createPdfFromBase64(basedata);
        let tab = window.open(data, '_blank');
        setTimeout(()=>{
          tab.document.title = tabName || ''
        },500)

        return tab
      }
    } catch (error) {
      console.log(error);
    }
  }

  public updatePolicyRelatedData(data: Question, page?: string): void {
    if (data.questionText?.toLowerCase() === 'street address' || data.questionText?.toLowerCase().indexOf('city') > -1
        || data.questionText?.toLowerCase().indexOf('state') > -1 || data.questionText?.toLowerCase().indexOf('zip') > -1) {
      data.required = 'true';
    }
    if (page !== 'BENE' && (data.questionText?.toLowerCase().indexOf('address type') > -1 || data.questionText?.toLowerCase().indexOf('country') > -1)) {
      data.required = 'true';
    }
  }

  getCalendarConfig(data: any, dateConfig: any): any {
    const config = dateConfig;
    const placeHolder: any = this.getCorrectContent(data, 'placeHolder');
    config.placeholder = placeHolder;
    config.id = data.fieldId;
    config.defaultDate = data.question_answer ? new Date(this.formatDateToMMDDYYYY(data.question_answer)) : null;
    return config;
  }

  getOwnerName(answers, nameFieldnameKeys): any {
    const nameObject = {}; let nameIndex = 0;
    for (const ownerKey in answers) {
      if (ownerKey) {
        nameIndex++;
        if (nameFieldnameKeys.indexOf(ownerKey) >= 0) {
          nameObject[ownerKey] = answers[ownerKey];
        }
      }
    }
    return nameObject;
  }

  toTitleCase(str: string): string {
    return str?.toLowerCase().split(' ').map((word) => (word.charAt(0).toUpperCase() + word.slice(1))).join(' ');
  }

  public isSafariBrowser(): boolean {
    const userAgent = navigator.userAgent?.toLowerCase();
    return (!(/(edg)/.test(userAgent)) && !(/(firefox)/.test(userAgent))
      && !(/(chrome)/.test(userAgent)) && /(safari)/.test(userAgent) && /(mac)/.test(userAgent));
  }

  public calculateAge(dob: string): number {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  }

}
