import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaseDetailsComponent } from './casedetailpage/case-details.component';
import { DocusignThankYouComponent } from './shared/components/docusign-thank-you/docusign-thank-you.component';
import { BasePageComponent } from './case/base-page/base-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RedirectConfirmationGuard } from './shared/guard/redirect-confirmation.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    component: DashboardComponent
  },
  {
    path: 'case/summary/:caseId',
    pathMatch: 'full',
    component: CaseDetailsComponent
  },
  {
    path: 'case',
    pathMatch: 'full',
    component: BasePageComponent,
    canDeactivate: [RedirectConfirmationGuard]
  },
  {
    path: 'thankyou',
    pathMatch: 'full',
    component: DocusignThankYouComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
