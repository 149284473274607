export const PAGE_GROUP_NAME = {
  CASE_INFO_PAGE: 'LFG - eTicket - Case Information',
  ATTESTATION_PAGE: 'LFG - eTicket - Attestation by Product Info',
  COVERAGE_INFO_PAGE: 'LFG - eTicket - Term Coverage Info',
  RIDERS_INFO_PAGE: 'LFG - eTicket - Rider Info',
  AGENT_INFO_CONTINUED: 'LFG - eTicket - Agent Information Continued',
  ADDITIONAL_INFO: 'LFG - eTicket - Additional Information',
  VALIDATE_AND_LOCK: 'LFG - eTicket - Validate And Lock',
  VOICE_TERM_OF_USE: 'LFG - eTicket - Voice Term of Use',
  ELECTRONIC_FUND_TRANSFER: 'LFG - eTicket - EFT Info',
  AGENT_INSTRUCTION: 'LFG - eTicket - Agent Instruction',
  FUND_ALLOCATION: 'LFG - eTicket - Fund Allocation',
  DOLLAR_COST_AVERAGING: 'LFG - eTicket - DCA Funds',
  MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION: 'LFG - eTicket - Maturing Funds',
  TRANSFER_ALLOCATION_CHARGES_AUTHORIZATION: 'LFG - eTicket - Trans Alloc Charges Auth',
  SUITABILITY_REVIEW: 'LFG - eTicket - Suitability Review',
  TRUST_INFO_PAGE: 'LFG - eTicket - Trust Information',
  PROPOSED_INSURED_B: 'LFG - eTicket - Proposed Insured B Info',
  WA_REPLACEMENTS: 'LFG - eTicket - WA Replacement',
  VOICE_CONFIRM_THANK_YOU: 'LFG - eTicket - Voice thank you',

  WET_SIGN_COLLECTION_INFO_PAGE: 'LFG - MGeAPP - Wet Signature Collection Information',

  AGENT_REPORT_PAGE: 'LFG - MGeAPP - Agent Report',
  PRIMARY_INSURED_PAGE: 'LFG - MGeAPP - Insured Info',
  OWNER_INFO_PAGE: 'LFG - MGeAPP - Owner Info',
  BENEFICIARY_INFO_PAGE: 'LFG - MGeAPP - Beneficiary',
  EXISTING_INSURANCE: 'LFG - MGeAPP - Existing Insurance',
  THIRD_PARTY_DESIGNEE_PAGE: 'LFG - MGeAPP - Third Party Designee',
  TEMPORARY_LIFE_INSURANCE_PAGE: 'LFG - MGeAPP - Temporary Insurance Agreement',
  BILLING_INFO_PAGE: 'LFG - MGeAPP - Billing',
  LIFE_SUITABILITY_PAGE: 'LFG - MGeAPP - Life Insurance Suitability Supplement',
  LTC_SUITABILITY_PAGE: 'LFG - MGeAPP - LTC Suitability',
  LTC_REPLACEMENT_PAGE: 'LFG - MGeAPP -  LTC - Replacement',
  ILLUSTRATION_COMPLIANCE_PAGE: 'LFG - MGeAPP - Illustration Compliance',
  DCA_PREMIUM_ALLOCATION: 'LFG - MGeAPP - Premium Allocations',
  POLICY_INFO_PAGE: 'LFG - MGeAPP - Policy Information',
  AGENT_INFORMATION: 'LFG - MGeAPP - Agent Information',
  CONSENT_PAGE_INSURED_OWNER: 'LFG - eTicket - Consent Insured Owner',
  CONSENT_PAGE_AGENT: 'LFG - eTicket - Consent Agent',
  CONSENT_PAGE_PI: 'LFG - eTicket - Consent Primary Insured',
  CONSENT_PAGE_OWNER: 'LFG - eTicket - Consent Owner',
  CONSENT_PAGE_COOWNER: 'LFG - eTicket - Consent Co-Owner',
  CONSENT_PAGE_COOWNER2: 'LFG - eTicket - Consent Co-Owner 2',
  CONSENT_PAGE_COOWNER3: 'LFG - eTicket - Consent Co-Owner 3',
  CONSENT_PAGE_INSURED_B: 'LFG - eTicket - Consent Proposed B',
  CONSENT_PAGE_ADDITIONAL_INSURED: 'LFG - eTicket - Consent Additional Insured',
  CONSENT_PAGE_PRINCIPAL: 'LFG - MGeAPP - Principal',
  CONSENT_PAGE_PAYOR: 'LFG - MGeAPP - Consent Payor',
  SUMMARY_INFO: 'LFG - MGeAPP - Application Summary',
  SIGNATURE_METHOD: 'LFG - MGeAPP - Signature Method',
  E_SIGNATURE_INSTRUCTION: 'LFG - MGeAPP - Signature Method',
  ELECTRONIC_SIGNATURE: 'LFG - MGeAPP - Signature Method',
  REVIEW_AND_SUBMIT: 'LFG - eTicket - Review and submit',

  // wholesaler page config
  WS_CASE_INFO: 'LFG - Wholesaler - Case Information',
  WS_AGENT_INFO: 'LFG - Wholesaler - Agent Information',
  WS_POLICY_PROJECTION: 'LFG - Wholesaler - Policy Projection',
  WS_PRIMARY_INSURED_PAGE: 'LFG - Wholesaler - Insured Info',
  WS_OWNER_INFO_PAGE: 'LFG - Wholesaler - Owner Info',
  WS_BENEFICIARY_INFO_PAGE: 'LFG - Wholesaler - Beneficiary',
  WS_EXISTING_INSURANCE: 'LFG - Wholesaler - Existing Insurance',
  WS_LTC_REPLACEMENT_PAGE: 'LFG - Wholesaler -  LTC - Replacement',
  WS_THIRD_PARTY_DESIGNEE_PAGE: 'LFG - Wholesaler - Third Party Designee',
  WS_BILLING_INFO_PAGE: 'LFG - Wholesaler - Billing',
  WS_DCA_PREMIUM_ALLOCATION: 'LFG - Wholesaler - Premium Allocations',
  WS_POLICY_INFO_PAGE: 'LFG - Wholesaler - Policy Information',
  WS_LIFE_SUITABILITY_PAGE: 'LFG - Wholesaler - Life Insurance Suitability Supplement',
  WS_LTC_SUITABILITY_PAGE: 'LFG - Wholesaler - LTC Suitability',
  WS_AGENT_REPORT_PAGE: 'LFG - Wholesaler - Agent Report',
  WS_TEMPORARY_LIFE_INSURANCE_PAGE: 'LFG - Wholesaler - Temporary Insurance Agreement',
  WS_COVERAGE_SUMMARY: 'LFG - Wholesaler - Coverage Summary',
  WS_FORM_SELECTIONS: 'LFG - Wholesaler - Form Selections',
};
