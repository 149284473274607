import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultPageload } from '../shared/services/defaultPageload.service';
import { LfgLoaderService } from '@ng/lfg-loader';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { ESignatureTableConfig } from '../case/electronic-signature/e-signature-table-config';
import { CaseUtilsService } from '../shared/services/case-util-service';
import { CaseAndPageStatus, CaseStatus as CaseStatusInfo, MessageType, PageJsonObject, Question } from '../shared/models/casePage.model';
import { PageService } from '../shared/services/page.service';
import { ModalSize } from '@ng/lfg-modal-popup';
import { Message, CaseStatus } from 'src/config/constants';
import { UtilsService } from '../shared/services/utils.service';
import { AgentAndFirmService } from '../shared/services/agent-and-firm.service';
import { take } from 'rxjs/operators';
import { ActivityTableConfig } from './activity-table.config';
import { AppAnalyticsService } from '../shared/services/app-analytics.service';
import { DelegationService } from '../shared/services/delegation.service';
import { FunctionalityConfig } from 'src/config/delegationFunctionality.config';
import { MessageService } from '../shared/services/message.service';

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss'],
  providers: [MessageService]
})
export class CaseDetailsComponent implements OnInit {

  systemMessage: any;
  windowRef: any = window;
  caseId: string;
  showLoader = 0;
  show = false;
  tableData;
  activityTableData;

  errorInLoadingRecipients = false;
  caseLocked = false;

  deleteModal: any;
  MessageConst = Message;

  errorInGeneratingForm = false;
  displayProgressModal = false;

  summaryData: Question[] = [];
  dataMap: Map<string, string> = new Map<string, string>();

  activeEnvelopeStatus = ['sent', 'created', 'completed', 'delivered'];

  pageName = 'eticket - case details';

  progressPercentArray = [1, 1, 5, 5, 5, 10, 10, 10, 15, 15, 15, 20, 20, 20,
    25, 31, 31, 31, 31, 36, 36, 42, 42, 54, 62, 62, 62, 67, 78,
    78, 85, 85, 85, 85, 90, 90, 90, 98];

  isCaseSubmitted = false;
  userAccessDetails: FunctionalityConfig;
  MessageType = MessageType;
  generateFormsError = null;


  constructor(
    private router: Router, private pageLoadService: DefaultPageload,
    private loaderService: LfgLoaderService,
    private route: ActivatedRoute, private caseUtilsService: CaseUtilsService,
    private pageService: PageService, private utilsService: UtilsService,
    private agentService: AgentAndFirmService,
    private appAnalyticsService: AppAnalyticsService,
    private delegationService: DelegationService,
    private defaultPageLoadService: DefaultPageload,
    private messageService: MessageService,
  ) {
    // add code
  }

  ngOnInit(): void {
    this.deleteModal = {
      header: true,
      state: false,
      footer: true,
      size: ModalSize.medium
    };
    this.subscribeForMessage();
    this.fetchAgentDataAndlogPageAnalytics();
  }

  private subscribeForMessage(): void {
    this.messageService.getMessageSubscription().subscribe(message => {
      if (message) {
        this.systemMessage = message;
      }
    });
  }

  redirectToDbpage(): void {
    this.appAnalyticsService.logLinkEvents('back to dashboard', this.pageName);
    const url = window.location.href;
    const replaceStr = url?.split('#')[1];
    const eticketURL = url?.replace('lincolnlifeeappportal', 'lincolnlifeeticketportal').replace(replaceStr, '/dashboard');
    this.windowRef.location.href = encodeURI(eticketURL);
  }


  openCase(): void {
    this.appAnalyticsService.logLinkEvents('open case', this.pageName);
    this.router.navigate(['/case'], { queryParams: { caseId: this.caseId } });
  }

  deleteCase(): void {
    this.appAnalyticsService.logButtonEvents('delete case', this.pageName);
    this.deleteModal.state = false;
    this.showPageLoader(true);
    this.pageService.deleteCases([this.caseId]).subscribe((response) => {
      if (response && response.responseStatus === 'SUCCESS') {
        this.redirectToDbpage();
      }
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private loadCaseSummary(): void {
    this.showPageLoader(true);
    this.pageLoadService.getCaseInfoPageQuestions({
      caseId: this.caseId,
      pageGroupName: PAGE_GROUP_NAME.SUMMARY_INFO
    }).subscribe((response) => {
      if (response.data) {
        this.reArrangeData(response.data);
      } else if (response.error?.errorCode) {
        this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
      }
    }, (_err) => {
      this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private loadRecipients(): void {
    this.showPageLoader(true);
    this.pageLoadService.getPackageDetails(this.caseId).subscribe((response) => {
      this.tableData = new ESignatureTableConfig().tableData;
      if (response.data) {
        let sortedSignerList;
        if (response.data.activePacket) {
          const packetStatus = response.data.activePacket.envelopeStatus;
          if (this.activeEnvelopeStatus.includes(packetStatus?.toLowerCase())) {
            sortedSignerList = this.caseUtilsService.sortListBySignerRole(response.data.activePacket?.recipients, false);
            this.caseUtilsService.updateDisplayStatus(sortedSignerList);
            this.tableData.data = sortedSignerList;
          }
        }
      }
    }, (_err) => {
      this.errorInLoadingRecipients = true;
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private initializePage(): void {
    this.route?.paramMap?.subscribe(params => {
      this.caseId = params.get('caseId');
      if (this.caseId) {
        this.loadCaseSummary();
        this.loadRecipients();
        this.getPageStatus();
        this.getActivityLogs();
      }
    });
  }

  private getActivityLogs(): void {
    this.showPageLoader(true);
    this.pageLoadService.getActivityLog(this.caseId).subscribe(response => {
      this.activityTableData = new ActivityTableConfig().tableData;
      if (response.data?.length > 0) {
        this.activityTableData.data = response.data;
      }
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private getPageStatus(): void {
    this.showPageLoader(true);
    this.pageLoadService.getPageStatus(this.caseId).subscribe(response => {
      if (response.data) {
        const casePageStatusRes: CaseAndPageStatus = {
          pageStatusList: response.data.pageStatusList ? this.caseUtilsService.filterOutInactiveTabs(response.data.pageStatusList) : [],
          caseStatus: response.data.caseStatus ? response.data.caseStatus : {}
        };
        this.caseLocked = casePageStatusRes.caseStatus?.isLocked;
        this.checkIfCaseSubmitted(casePageStatusRes.caseStatus);
      }
    }, (err) => {
      if (err.status === 500 || err.status === 404) {
        this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
      }
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private checkIfCaseSubmitted(caseStatus: CaseStatusInfo): void {
    if ((caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED) || (caseStatus.status === CaseStatus.READY_TO_SUBMIT)) {
      this.isCaseSubmitted = true;
    }
  }

  generateForms(): any {
    const cachedForm = this.defaultPageLoadService.getCachedMergedForm()
    const existingTab = this.defaultPageLoadService.getMergedFormsTab()
    if(existingTab && !existingTab.closed){
      const tab = this.utilsService.openPdf(null,null,existingTab)
      this.defaultPageLoadService.updateMergedFormsTab(tab)
    } else if (cachedForm){
      const tab = this.utilsService.openPdf(cachedForm)
      this.defaultPageLoadService.updateMergedFormsTab(tab)
    } else {
      this.showPageLoader(true);
      this.defaultPageLoadService.generateForm(this.caseId, 'GENERATE_ALL_ETICKETMG_FORMS').subscribe((res: any)=>{
        this.defaultPageLoadService.cacheMergedForm(res.data);
        const tab = this.utilsService.openPdf(res.data);
        this.defaultPageLoadService.updateMergedFormsTab(tab);
      }, (_error)=>{
        this.generateFormsError = true
      }).add(()=>{
        this.showPageLoader(false);
      })
    }
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      this.showLoader++;
      if (this.showLoader === 1) {
        this.loaderService.show();
      }
    } else {
      this.showLoader--;
      if (this.showLoader === 0) {
        this.loaderService.hide();
      }
    }
  }

  private reArrangeData(data: PageJsonObject): void {
    this.summaryData = data?.pages[0]?.questions;
    this.dataMap.set('name', this.getInsuredFullName());
    this.dataMap.set('address', this.getInsuredFullAddress());
    this.updateOtherValues();
  }

  private updateOtherValues(): void {
    this.summaryData.forEach(ques => {
      if (ques.questionText?.toLowerCase()?.indexOf('date of birth') > -1) {
        this.dataMap.set('dob', ques.question_answer);
      } else if (ques.questionText?.toLowerCase() === 'age:') {
        this.dataMap.set('age', ques.question_answer);
      } else if (ques.questionText?.toLowerCase() === 'address') {
        this.dataMap.set('address1', ques.question_answer);
      } else if (ques.questionText?.toLowerCase() === 'email address:') {
        this.dataMap.set('email', ques.question_answer);
      } else if (ques.questionText?.toLowerCase()?.indexOf('primary phone number') > -1) {
        this.dataMap.set('phone', ques.question_answer);
      } else if (ques.questionText?.toLowerCase()?.indexOf('product name') > -1) {
        this.dataMap.set('product', ques.question_answer);
      } else if (ques.questionText?.toLowerCase()?.indexOf('contract state') > -1) {
        this.dataMap.set('state', ques.question_answer);
      } else if (ques.questionText?.toLowerCase()?.indexOf('amount of insurance') > -1) {
        this.dataMap.set('faceamount', ques.question_answer);
      }
    });
  }

  private getInsuredFullName(): string {
    let name = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'name') {
        name = name + ques.question_answer + ' ';
      }
    });
    return name;
  }

  private getInsuredFullAddress(): string {
    let address = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'address2') {
        address = address + ques.question_answer + ' ';
        if (ques.questionText?.toLowerCase() === 'city' && ques.question_answer) {
          address = address.trim() + ', ';
        }
      }
    });
    return address;
  }

  closeDeleteModalPopup(modalConfig: any): void {
    modalConfig.state = false;
  }

  openDeleteModalPopup(modalConfig: any): void {
    modalConfig.state = true;
  }

  private subscribeForProfileChangeAndInitPage(): void {
    this.agentService.fetchAgentProfileCompletionSubj.pipe(take(1)).subscribe(res => {
      if (res === 'success') {
        this.initializePage();
        this.updateAccessDetails();
        this.appAnalyticsService.logPageView('case details');
      } else if (res === '500') {
        this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
      } else if (res === '403') {
        this.messageService.setMessage(Message.UNAUTHORIZED_USER_ERROR, MessageType.OUTAGE);
      }

    });
  }

  private updateAccessDetails(): void {
    this.userAccessDetails = this.delegationService.getAccessConfigForUser();
  }

  private fetchAgentDataAndlogPageAnalytics(): void {
    this.subscribeForProfileChangeAndInitPage();
    this.agentService.fetchAgentProfile();
  }


  cloneCase(): void {
    this.appAnalyticsService.logButtonEvents('duplicate case', this.pageName);
    this.showPageLoader(true);
    this.pageService.cloneCase(this.caseId).subscribe((response) => {
      if (response && response.responseStatus === 'SUCCESS') {
        this.redirectToDbpage();
      }
    }).add(() => {
      this.showPageLoader(false);
    });
  }
}
