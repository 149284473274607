import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  SimpleChanges,
  ElementRef,
  AfterViewChecked,
  OnChanges,
  EventEmitter
} from '@angular/core';
import { EticketDashboardTableConfig } from './eticket-dashboard-table-config';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchPipe } from 'src/app/shared/pipes/search.pipe';
import { Case } from 'src/app/shared/models/casePage.model';
import { WindowRefService } from '@ng/lfg-common-utilities';
import { CaseStatus, Message, StatusHelpConst } from 'src/config/constants';
import { PageService } from 'src/app/shared/services/page.service';
import { LfgLoaderService } from '@ng/lfg-loader';
import { ModalSize } from '@ng/lfg-modal-popup';
import { AppAnalyticsService } from 'src/app/shared/services/app-analytics.service';
import { FunctionalityConfig } from 'src/config/delegationFunctionality.config';


@Component({
  selector: 'app-eticket-dashboard-table',
  templateUrl: './eticket-dashboard-table.component.html',
})
export class EticketDashboardTableComponent
  implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
  @Input() searchValue: string;
  @Input() userAccessDetails: FunctionalityConfig;

  // table related values
  tableData;
  tableDataLoaded = false;
  clickEventAddedToHelpIcon = false;
  selectedRowsCount = 0;
  selectedRows: Case[] = [];

  // search
  searchResultSub: Subscription;
  dashboardDDForm: FormGroup;
  searchCurrentValue = { insuredLastName: '' };
  dropDownList = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
  ];

  // modal pop up
  modalConfig: any;
  deleteModal: any;
  deleteResultModal: any;
  deleteResultMessage: string;
  showLoader = false;

  casedata: any;
  helpTableConfig = StatusHelpConst;
  MessageConst = Message;

  warnStatus = [
    CaseStatus.AWAITING_CUSTOMER_ESIGN,
    CaseStatus.AWAITING_AGENT_ESIGN,
    CaseStatus.CUSTOMER_DECLINE_TO_ESIGN,
    CaseStatus.PRINCIPAL_AGENT_DECLINED,
    CaseStatus.READY_TO_SUBMIT,
    CaseStatus.STARTED,
    CaseStatus.LOCKED_READY_TO_SIGN
  ];

  noOfSubmittedCaseSelected = 0;
  successStatus = CaseStatus.APPLICATION_ESUBMITTED;
  noOfCasesLeavingSoon = 0;

  @Output() tableLoadedEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private router: Router,
    private formbuilder: FormBuilder,
    private pageService: PageService,
    private elementRef: ElementRef,
    private searchPipeInstance: SearchPipe,
    private windowRef: WindowRefService,
    private loaderService: LfgLoaderService,
    private appAnalyticsService: AppAnalyticsService,
  ) { }

  ngOnInit(): any {
    this.tableData = new EticketDashboardTableConfig().tableData;
    this.getCaseData();
    const formGroupControls: any = {
      viewRecordsDDown: [this.dropDownList[0], []],
    };
    this.dashboardDDForm = this.formbuilder.group(formGroupControls);
    this.intializeModals();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.searchValue?.currentValue) {
      this.searchCurrentValue = changes.searchValue.currentValue;
      const transformData = this.searchPipeInstance.transform(
        this.casedata,
        this.searchCurrentValue
      );
      this.tableData.config.currentPage = 0;
      this.tableData.data = transformData;
    }
    if (changes?.userAccessDetails?.currentValue && !changes.userAccessDetails.previousValue) {
      this.getCaseData();
    }
  }

  ngAfterViewChecked(): void {
    if (this.elementRef.nativeElement.querySelector('.status-help-icon') && !this.clickEventAddedToHelpIcon) {
      this.elementRef.nativeElement
        .querySelector('.status-help-icon')
        .addEventListener('click', this.openModal.bind(this));
      this.clickEventAddedToHelpIcon = true;
    }
  }

  scrollTopOfTable(): any {
    const topOffset = document.getElementById('table-header-top')?.offsetTop - 85;
    setTimeout(() => {
      this.windowRef.getWindow().scrollTo({ top: topOffset, behavior: 'smooth' });
    }, 0);
  }

  handleSelectionChange(selectedOption): any {
    console.log('Dropdown selection changed to: ', selectedOption.value);
    this.dashboardDDForm?.markAsUntouched();
    this.tableData.config.rowsPerPage = selectedOption.value;
    this.tableData.config.currentPage = 0;
  }

  changeRows(): any {
    this.tableData.config.rowsPerPage = 10;
    this.handleSelectionChange(10);
  }

  openClickedRowDetail(row): any {
    const caseId = row.caseId;
    this.appAnalyticsService.logLinkEvents('view case details', 'eticket - dashboard');
    this.router.navigate(['case/summary', caseId]);
  }

  setDefaultSort(): any {
    if (this.tableData.data.length > 0) {
      setTimeout(() => {
        this.sortByDate({ order: false });
      }, 0);
    }
  }

  sortTableData(event): any {
    if (event.columnkey === 'insuredName') {
      this.sortInsuredNameData(event);
    } else if (event.columnkey === 'lastActivityDate') {
      this.sortByDate(event);
    } else {
      this.tableData.data.sort((obj1, obj2) => {
        if (event.order === true) {
          return obj1[event.columnkey] < obj2[event.columnkey] ? -1 : 1;
        } else {
          return obj1[event.columnkey] < obj2[event.columnkey] ? 1 : -1;
        }
      });
    }
  }

  sortByDate(event): any {
    this.tableData.data.sort((obj1, obj2) => {
      const date1 = new Date(obj1.updatedDate);
      const date2 = new Date(obj2.updatedDate);
      if (event.order === true) {
        return date1.getTime() - date2.getTime() > 0 ? 1 : -1;
      } else {
        return date2.getTime() - date1.getTime() > 0 ? 1 : -1;
      }
    });
  }

  sortInsuredNameData(event): any {
    this.tableData.data.sort((obj1, obj2) => {
      const insuredNameObj1 = (
        obj1.insuredLastName +
        ', ' +
        obj1.insuredFirstName
      ).toLowerCase();
      const insuredNameObj2 = (
        obj2.insuredLastName +
        ', ' +
        obj2.insuredFirstName
      ).toLowerCase();
      if (event.order === true) {
        return insuredNameObj1 > insuredNameObj2 ? 1 : -1;
      } else {
        return insuredNameObj2 > insuredNameObj1 ? 1 : -1;
      }
    });
  }

  openDeleteResultModal(isSuccess: boolean, responseData?: any): void {
    this.deleteResultModal.state = true;
    if (isSuccess && responseData) {
      this.deleteResultMessage = Message.SUCCESSFULLY_DELETE_CASE_MESSAGE.replace('{noOfCase}', responseData.deletedCaseIds?.length);
      if (responseData.failedCaseIds.length > 0) {
        this.deleteResultMessage = this.deleteResultMessage +
          Message.FAILED_TO_DELETE_CASE_MESSAGE.replace('{noOfCase}', responseData.failedCaseIds?.length);
      }
    }
    else {
      this.deleteResultMessage = Message.DELETE_CASE_ERROR;
    }
    this.appAnalyticsService.logButtonEvents('delete', 'eticket - dashboard');
  }

  selectCaseCheckbox(event, row): void {
    if (event.target.checked) {
      row.checkbox = true;
      this.checkSubmittedCase(row, true);
      this.selectedRows.push(row);
    } else {
      row.checkbox = false;
      this.checkSubmittedCase(row, false);
      this.selectedRows.splice(this.selectedRows.indexOf(row), 1);
    }
    this.selectedRowsCount = this.selectedRows.length;
  }

  editCase(isOpenClicked: string, row): void {
    let caseId = row.caseId;
    let refCaseId = row.refCaseId;
    if (isOpenClicked) {
      caseId = this.selectedRows[0]?.caseId;
    }
    this.appAnalyticsService.logLinkEvents('edit case - ' + caseId, 'eticket - dashboard');
    this.router.navigate(['/case'], { queryParams: { caseId, refCaseId } });
  }

  deleteSelectedCases(): void {
    const selectedCasesToDelete = this.selectedRows.map((caseInfo) => caseInfo.caseId);
    this.deleteModal.state = false;
    this.showLoader = true;
    this.loaderService.show();
    this.pageService.deleteCases(selectedCasesToDelete).subscribe((response) => {
      if (response && response.responseStatus === 'SUCCESS') {
        this.loaderService.hide();
        this.openDeleteResultModal(true, response.data);
        this.deleteFromNoOfLeavingCaseCount(response.data.deletedCaseIds, this.selectedRows);
        this.deleteElementsFromArray(response.data.deletedCaseIds, this.tableData.data);
        this.deleteElementsFromArray(response.data.deletedCaseIds, this.casedata);
        this.deleteElementsFromArray(response.data.deletedCaseIds, this.selectedRows);
        this.selectedRowsCount = this.selectedRows.length;
      } else if (response && response.responseStatus === 'FAILURE') {
        this.loaderService.hide();
        this.openDeleteResultModal(false);
      }
      this.showLoader = false;
    },
      (_err) => {
        this.loaderService.hide();
        this.openDeleteResultModal(false);
        this.showLoader = false;
      });
      this.appAnalyticsService.logButtonEvents('delete case confirm', 'eticket - dashboard');
  }

  private deleteElementsFromArray(deleteCaseIds: string[], dataArray: any[]): void {
    deleteCaseIds.forEach(caseId => {
      const deleteIndex = dataArray.findIndex((data) => data.caseId === caseId);
      if (deleteIndex > -1) {
        dataArray.splice(deleteIndex, 1);
      }
    });
  }

  private calculateDateDiff(initialCreatedDate: any): any {
    const currentDate = new Date();
    const createdDate = new Date(initialCreatedDate);
    return Math.floor((currentDate.getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24));
  }

  private updateDaysPassedForCases(casesList: Case[]): void {
    this.noOfCasesLeavingSoon = 0;
    casesList.forEach(caseInfo => {
      caseInfo.noOfDaysPassed = this.calculateDateDiff(caseInfo.createdDate);
      if (this.isLeavingSoon(caseInfo)) {
        this.noOfCasesLeavingSoon++;
      }
    });
  }

  private getCaseData(): void {
    this.pageService.getTableData().subscribe((res) => {
      if (res.data) {
        this.updateDaysPassedForCases(res.data);
        this.tableData.data = res.data;
        this.casedata = res.data;
        this.tableDataLoaded = true;
        this.tableData.config.currentPage = 0;
        this.setDefaultSort();
      } else if (res.error?.errorCode) {
        this.tableDataLoaded = true;
      }
    }, (_err) => {
      this.tableDataLoaded = true;
    }).add(() => {
      if (this.tableDataLoaded) {
        this.tableLoadedEvent.emit(true);
      }
    });
  }

  private intializeModals(): void {
    this.modalConfig = {
      header: true,
      state: false,
      footer: true,
    };

    this.deleteModal = {
      header: true,
      state: false,
      footer: true,
      size: ModalSize.medium
    };

    this.deleteResultModal = {
      header: true,
      state: false,
      footer: true,
      size: ModalSize.medium
    };
  }

  openModal(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.modalConfig.state = true;
  }

  closeModalPopup(): void {
    this.modalConfig.state = false;
  }

  openDeleteModal(): void {
    this.deleteModal.state = true;
    this.appAnalyticsService.logButtonEvents('delete case', 'eticket - dashboard');
  }

  closeDeleteModalPopup(): void {
    this.deleteModal.state = false;
    this.appAnalyticsService.logButtonEvents('delete case cancel', 'eticket - dashboard');
  }

  closeDeleteResultModalPopup(): void {
    this.deleteResultModal.state = false;
  }

  private checkSubmittedCase(caseInfo: any, isChecked: boolean): void {
    if ((caseInfo.status === CaseStatus.APPLICATION_ESUBMITTED) || (caseInfo.status === CaseStatus.READY_TO_SUBMIT)) {
      isChecked ? this.noOfSubmittedCaseSelected++ : this.noOfSubmittedCaseSelected--;
    }
  }

  private deleteFromNoOfLeavingCaseCount(deletedCaseIds: string[], seletedRows: Case[]): void {
    seletedRows.forEach(caseInfo => {
      if (this.isLeavingSoon(caseInfo) && deletedCaseIds.includes(caseInfo.caseId)) {
        this.noOfCasesLeavingSoon--;
      }
    });
  }

  private isLeavingSoon(caseInfo: Case): boolean {
    return caseInfo.noOfDaysPassed >= 75 && caseInfo.status.toLowerCase() !== this.successStatus.toLowerCase();
  }


  ngOnDestroy(): void {
    if (this.searchResultSub) {
      this.searchResultSub.unsubscribe();
    }
  }

}
