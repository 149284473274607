import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';

import { LfgLoaderService } from '@ng/lfg-loader';

import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { PageService } from 'src/app/shared/services/page.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { CaseAndPageStatus, MessageType, PackageInfo, Recipient, ResponseObject } from 'src/app/shared/models/casePage.model';
import { TAB, TabInformation } from 'src/config/sideNav.config';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import {
  COMMUNITY_STATES,
  CaseInfoConstant, CaseStatus, LCN, Message, PrimaryInsuredConstant, TABS_AFTER_LOCK_SCREEN, TABS_ALWAYS_IN_GOOD_ORDER, TABS_EXCLUDED_FROM_GET_QUESTIONS
} from 'src/config/constants';
import { CaseUtilsService } from 'src/app/shared/services/case-util-service';
import { ModalSize } from '@ng/lfg-modal-popup';
import { ClipboardService } from '@ng/lfg-common-utilities';
import { AccessibilityConfig, FunctionalityConfig, ROLE_TYPE } from 'src/config/delegationFunctionality.config';
import { DelegationService } from 'src/app/shared/services/delegation.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { UpdateCaseService } from 'src/app/shared/services/update-case.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
  providers: [MessageService]
})
export class BasePageComponent implements OnInit, OnDestroy {
  activeTab: TabInformation;
  eticketQuestions: Map<string, any> = new Map<string, any>();
  showLoader: boolean;
  caseId: string;
  tabStatusChanged = new BehaviorSubject(null);
  casePageStatusRes: CaseAndPageStatus;
  private unSubscribeSub = new Subject();
  readonly TAB = TAB;
  buttonStatus = {
    nextDisable: true,
    backDisable: false,
  };
  latestEmittedData = null;
  currentFormId: string;
  firstTimeLoad = true;
  onLoadExistingCase = true;

  signerList: any[] = [];
  agentSignerInfo: Recipient;

  // remove this once package details apis are integrated
  tempSignerList: any[] = [];
  Message = Message;

  // for product change
  prevTabList = [];
  ROLE_TYPE = ROLE_TYPE

  signerInfo;
  isSignatureFormChange = false;
  selectedSignatureMethod: string;
  noGreenCardholder = false;
  ownerAddress: string;
  isSameInsuredAddress: any;
  ownerCity: any;
  ownerState: any;
  ownerZip: any;
  labelFirstName: any;
  labelLastName: any;
  ownerQuesWithSameAddr: any;
  policyCreationError: any;

  displayProgressModal: boolean;
  sliderObj: any;
  illustrationTab = 'basic'
  innerWidth: number;
  caseInfoUpdate = false;
  hasRemoteSigners: boolean;
  activePacket: PackageInfo;
  TABS_ALWAYS_IN_GOOD_ORDER = TABS_ALWAYS_IN_GOOD_ORDER;

  progressPercentage = 0;
  isAllSignerRemote = false;
  displayProgressModalForESign = false;
  noOfTimesTransactionStatusApiCalled = 0;
  timerSubscription;
  consentTabList = [];
  errorOccuredGettingTransactionId = false;
  errorOccuredCreateEnvelope = false;
  errorOccuredCreateEnvelopeRemote = false;
  envelopeCreated = false;
  errorOccuredGettingTransactionIdRemote = false;
  envelopeCreatedRemote = false;
  areYouSureModalConfig = {
    header: true,
    state: false,
    footer: true,
    size: ModalSize.small,
  };
  signingCompleted = false;
  userRole: string;

  // indicators of the existing case loaded properly with case info page and all tabs
  allTabsLoaded = false;
  caseInfoPageLoaded = false;
  isPolicySubmitted = false;

  tabsAfterLock = TABS_AFTER_LOCK_SCREEN;

  isFormChanged = false;
  lcnNumber = LCN.number;
  windowReference: any = window;
  userAccessDetails: FunctionalityConfig;
  userFunctionalityConfig: AccessibilityConfig;
  displayHelpData = false;
  closeHelpHint = true;
  systemMessage: any;
  MessageType = MessageType;
  enableNextButtonIllustration = false;
  wholeSalerSummaryData;
  showConfirmationModal = false;
  redirectConfirmationSubj = new Subject<boolean>();
  isSaveAndExitClicked = false;
  isHub = true;

  constructor(
    private tabDetailsService: TabDetailsService,
    private lfgLoaderService: LfgLoaderService,
    private pageLoadService: DefaultPageload,
    private utilsService: UtilsService,
    private pageService: PageService,
    private userService: UserDetailsService,
    private productService: ProductDetailsService,
    private agentService: AgentAndFirmService,
    private route: ActivatedRoute,
    private caseUtilsService: CaseUtilsService,
    private clipboardService: ClipboardService,
    private delegationService: DelegationService,
    private messageService: MessageService,
    private updateCaseService: UpdateCaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscribeForMessage();
    this.fetchAgentProfileAndPageData();
    this.onResize();
    this.isHub = environment.isHub; 
  }


  private subscribeForMessage(): void {
    this.messageService.getMessageSubscription().subscribe(message => {
      if (message) {
        this.systemMessage = message;
      }
    });
  }

  private getCaseIdForExistingCase(): void {
    this.route?.queryParams?.subscribe(params => {
      this.caseId = params?.caseId;
      const refCaseId = params?.refCaseId;
      if (this.caseId) {
        this.pageLoadService.setCaseId(this.caseId);
        if (refCaseId) {
          this.userService.setRefCaseId(refCaseId)
        }
      }
      this.tabDetailsService.initializeTabService();
      this.updatePageStatus();
    });
  }

  private subscribeForProfileChangeAndInitPageData(): void {
    this.agentService.fetchAgentProfileCompletionSubj.pipe(take(1)).subscribe(res => {
      if (res === 'success') {
        this.getCaseIdForExistingCase();
        this.getDataForActiveTab();
        this.updateAccessDetails();
        this.showPageLoader(false);
      } else if (res === '500') {
        this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
        this.showPageLoader(false);
      } else if (res === '403') {
        this.messageService.setMessage(Message.UNAUTHORIZED_USER_ERROR, MessageType.OUTAGE);
        this.showPageLoader(false);

      }
    });
  }

  private getDataForActiveTab(): void {
    this.tabDetailsService.activeTabSubject.pipe(takeUntil(this.unSubscribeSub)).subscribe(
      (activeTab: TabInformation) => {
        if (this.activeTab?.name !== activeTab?.name) {
          this.isFormChanged = false;
          this.closeSlide(false);
          this.saveDataAndGetDataForNextPage(activeTab);
        }
      }
    );
  }

  saveAndExit(_params?): void {
    this.isSaveAndExitClicked = true;
    console.log('save exit called');
    this.pageLoadService.logButtonClick('save & exit');
    if (this.activeTab?.name === TAB.APPLICATION_SUMMARY || this.activeTab?.name === TAB.WET_SIGN_COLLECTION_INFO_MG
      || this.activeTab?.name === TAB.COVERAGE_SUMMARY_WS
    ) {
      this.rdToeTicketDashboard();
      return;
    }
    this.saveAnswers().subscribe((res) => {
      if (res) {
        if (this.activeTab?.name === TAB.SIGNATURE_METHOD) {
          this.saveRecipientInfo();
          setTimeout(() => this.rdToeTicketDashboard(), 500);
        } else {
          this.rdToeTicketDashboard();
        }
      }
    },
      () => {
        this.rdToeTicketDashboard();
      });
  }

  formStatus(event): any {
    console.log(event);
    this.closeSlide(true);
    this.currentFormId = event.formId ? event.formId : this.currentFormId;
    this.latestEmittedData = event;
    this.noGreenCardholder = event.noGreenCardholder;
    this.buttonStatus.nextDisable = !event.formValid || this.noGreenCardholder;
    this.updateFormChangeStatus(event);
    if (!event.formValid) {
      console.log('Form not valid');
    }
    if (this.activeTab) {
      this.tabDetailsService.updateTabStatusMap(
        this.activeTab?.name,
        event.formValid
      );
    }
    this.updateUserInfo(event);
    this.signerInfo = event.signatureFormData;
    this.isSignatureFormChange = event.isSignatureFormChange;
  }

  close(): any {
    this.pageLoadService.logButtonClick('close');
    this.rdToeTicketDashboard();
  }

  caseInfoUpdated(_event): any {
    const firstTabName = this.userRole === ROLE_TYPE.WHOLESALER ? TAB.CASE_INFO_WS : TAB.CASE_INFO;
    const caseInfo = this.eticketQuestions.get(firstTabName);
    this.eticketQuestions = new Map<string, any>();
    // to make sure prevTabList only set if the case is created and not reset after productSelection
    if (this.prevTabList?.length === 0 && this.pageLoadService.getCaseId()) {
      this.prevTabList = [...this.tabDetailsService.tabInfoList];
    }
    this.eticketQuestions.set(firstTabName, caseInfo);
    this.tabDetailsService.initializeTabService();
    this.caseInfoUpdate = true;
    this.agentService.setIsAgentsValidated(false)
  }

  wholeSalerCaseUnlocked(event: any): void {
    event?.forEach((tab) => {
      this.eticketQuestions.delete(tab);
    });
  }

  continue(): void {
    this.createCaseDetails();
    const selectedProductType = this.productService.getProductType();
    if (selectedProductType?.includes('Long Term Care')) {
      this.pageLoadService.logButtonClick('continue');
      this.userRole === ROLE_TYPE.WHOLESALER ? this.tabDetailsService.updateTabListbyConfigForWholesaler() : this.tabDetailsService.updateTabListbyConfig();
    }
  }

  proceedToSign(_event: any): void {
    this.tabDetailsService.updateTabList(TAB.SIGNATURE_METHOD, true);
    this.tabDetailsService.next();
  }

  checkForWetSignatureTab(_event: any): void {
    if (this.userService.getInsuredState()?.toLowerCase() === 'ca'
      || (this.userService.getIsMarried() && COMMUNITY_STATES.includes(this.userService.getInsuredState()?.toUpperCase()) && this.userService.getIs1035())) {
      this.updateTab(true, TAB.WET_SIGN_COLLECTION_INFO_MG);
    } else {
      this.updateTab(false, TAB.WET_SIGN_COLLECTION_INFO_MG);
    }
  }

  nextTab(): void {
    this.pageLoadService.logButtonClick('next');
    if (this.activeTab.name !== TAB.ELECTRONIC_SIGNATURE) {
      this.updateTabsForElectronicSignature();
    }
    // no need to move to next screen for below conditions
    // voice term of use as it submit data and then decides to move to next screen
    // in agent instruction screen for all in person signer it calls create envelope
    // if it has remote signers then go to next screen
    setTimeout(() => {
      if ((this.activeTab.name !== TAB.E_SIGNATURE_INSTRUCTION) &&
        ((this.activeTab.name === TAB.SIGNATURE_METHOD && this.clipboardService.get('POSWetSignInd') || this.activeTab.name !== TAB.SIGNATURE_METHOD))) {
        this.tabDetailsService.next();
      }
    }, 150)
  }

  private updateTabsForElectronicSignature(): void {
    if (this.activeTab.name === TAB.SIGNATURE_METHOD && this.clipboardService.get('POSeSignInd')) {
      this.signatureMethodWitheSign();
    } else if (this.activeTab.name === TAB.SIGNATURE_METHOD && this.clipboardService.get('POSWetSignInd')) {
      this.updateTabList(TAB.WET_SIGNATURE_INSTRUCTION);
    } else if (this.activeTab.name === TAB.E_SIGNATURE_INSTRUCTION) {
      this.createEnvelope('remote');
    } else if (!this.hasRemoteSigners && this.activeTab.name === this.consentTabList[this.consentTabList.length - 1]
      && this.allConsentSigned()) {
      this.updateTabList(TAB.CONSENT_PAGE_AGENT);
    } else if (this.activeTab.name === TAB.CONSENT_PAGE_AGENT && (this.allConsentSigned() || (this.activeTab.status?.visited && !this.activeTab.status?.errorExists))) {
      this.updateTab(true, TAB.REVIEW_AND_SUBMIT);
      this.tabDetailsService.updateTabStatusMap(TAB.REVIEW_AND_SUBMIT, true);
      // wait for few moment to update the tab status of previous page
      setTimeout(() => {
        this.tabDetailsService.lockAllTabs(true, [TAB.REVIEW_AND_SUBMIT]);
        this.signingCompleted = true;
      }, 500);
    }
  }

  signatureMethodWitheSign(): void {
    if (this.isDataSaveRequired()) {
      this.saveDataAndGetDataForNextPage(this.activeTab, true);
    } else if (this.hasRemoteSigners) {
      this.updateTabList(TAB.E_SIGNATURE_INSTRUCTION);
      this.tabDetailsService.next();
    } else {
      this.createEnvelope('inperson');
    }
  }

  private allConsentSigned(): boolean {
    return this.caseUtilsService.allConsentSigned(this.consentTabList, this.activeTab);
  }

  private checkIfAgentIsApplicableToSignInPerson(): void {
    // below code will filter the list of term of use tab which are signed
    const termOfUseTabList = this.tabDetailsService.tabInfoList.filter(tab => {
      return (tab.name.indexOf('Term of use -') > -1 && tab.isEnable && tab.status?.visited && !tab.status?.errorExists);
    });
    // there are more than 0 term of use tab and all term of use is signed
    // and last tab is the last recipient term of use then add agent term of use
    if (termOfUseTabList.length > 0 && termOfUseTabList.length === this.signerList?.length
      && this.activeTab.name === termOfUseTabList[termOfUseTabList?.length - 1].name) {
      this.updateTabList(TAB.CONSENT_PAGE_AGENT);
    }
  }

  private createEnvelope(signerType: string): void {
    this.progressPercentage = 0;
    this.updateEnvelopeFlag(false, false, false, signerType);
    this.showPageLoader(true);
    this.pageLoadService.createEnvelope().subscribe((response) => {
      if (response.data) {
        const transactionId = response.data.transaction_id;
        this.isAllSignerRemote = this.caseUtilsService.getInPersonSigners(this.signerList)?.length === 0;
        this.displayProgressModalForESign = true;
        this.getTransactionStatusInIntervals(transactionId, signerType);
      } else {
        this.updateEnvelopeFlag(true, undefined, undefined, signerType);
      }
    }, (_err) => {
      this.updateEnvelopeFlag(true, undefined, undefined, signerType);
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  updateAreYouSureModalPopup(isOpen: boolean): void {
    this.areYouSureModalConfig.state = isOpen;
  }

  packetCancelled(event: any): void {
    this.consentDeclined(event);
  }

  lockStatusUpdated(event: any): void {
    if (event === 'yes') {
      this.delegationService.resetAgentConfCheckbox();
      this.updateAccessDetails();
      this.saveAnswers().subscribe();
    }
  }

  policySubmitted(event: any): void {
    if (event === 'yes') {
      this.tabDetailsService.updateTabStatusMap(TAB.REVIEW_AND_SUBMIT, true);
      this.tabDetailsService.lockAllTabs(true, [TAB.REVIEW_AND_SUBMIT]);
      this.isPolicySubmitted = true;
    }
  }

  caseInfoLoaded(event: any): void {
    if (event === 'yes') {
      this.caseInfoPageLoaded = true;
      this.accessLastVisitedPage();
    }
  }

  private getTransactionStatusInIntervals(transactionId: string, signerType: string): void {
    this.updateEnvelopeFlag(undefined, false, undefined, signerType);
    this.noOfTimesTransactionStatusApiCalled = 0;
    this.timerSubscription = timer(1000, 7000).pipe(
      map(() => {
        this.noOfTimesTransactionStatusApiCalled++;
        this.getTransactionStatus(transactionId, signerType);
      })
    ).subscribe();
  }

  activePacketUpdated(event: any): void {
    if (event) {
      this.activePacket = event;
    }
  }


  private getTransactionStatus(transactionId: string, signerType: string): void {
    this.pageLoadService.getTransactionStatus(transactionId).subscribe((response) => {
      if (response.data) {
        this.progressPercentage = this.caseUtilsService.getProgressPercentageByStatus(response.data.status);
        if (this.progressPercentage === 100) {
          this.updateEnvelopeFlag(undefined, undefined, true, signerType);
          this.closeProgressOverlay();
          this.updateNextTabsAfterEnvelopeCreation(true);
        }
        else if (this.progressPercentage === -1 || this.noOfTimesTransactionStatusApiCalled === 45) {
          this.updateEnvelopeFlag(undefined, true, undefined, signerType);
          this.closeProgressOverlay();
        }
      }
    });
  }

  private closeProgressOverlay(): void {
    this.displayProgressModalForESign = false;
    this.timerSubscription.unsubscribe();
  }

  checkIfReviewAndSubmitAvailable(): boolean {
    let nextTabAvailable = false;
    this.tabDetailsService.tabInfoList.forEach((tab) => {
      if (tab.name === TAB.REVIEW_AND_SUBMIT && tab.isEnable) {
        nextTabAvailable = true;
      }
    });
    return nextTabAvailable;
  }

  private updateNextTabsAfterEnvelopeCreation(shouldMoveNext: boolean): void {
    this.addConsentPageForInPersonSignature(true);
    this.tabDetailsService.updateTabLockStatus(TAB.SIGNATURE_METHOD, true);
    if (this.activeTab.name === TAB.E_SIGNATURE_INSTRUCTION) {
      this.tabDetailsService.updateTabLockStatus(this.activeTab.name, true);
      this.updateTab(true, TAB.ELECTRONIC_SIGNATURE);
      this.tabDetailsService.updateTabStatusMap(TAB.ELECTRONIC_SIGNATURE, true);
    }
    this.tabDetailsService.next();
  }

  private addConsentPageForInPersonSignature(isTabUpdateReq: boolean): void {
    this.consentTabList = this.caseUtilsService.addConsentPageForInPersonSignature(this.signerList, isTabUpdateReq);
  }

  private updateEnvelopeFlag(
    errorInTransaction: boolean, errorInEnvelopeCreation: boolean,
    envelopeCreated: boolean, signerType: string): void {
    if (signerType === 'inperson') {
      this.errorOccuredGettingTransactionId = errorInTransaction ?? this.errorOccuredGettingTransactionId;
      this.errorOccuredCreateEnvelope = errorInEnvelopeCreation ?? this.errorOccuredCreateEnvelope;
      this.envelopeCreated = envelopeCreated ?? this.envelopeCreated;
    } else if (signerType === 'remote') {
      this.errorOccuredGettingTransactionIdRemote = errorInTransaction ?? this.errorOccuredGettingTransactionIdRemote;
      this.errorOccuredCreateEnvelopeRemote = errorInEnvelopeCreation ?? this.errorOccuredCreateEnvelopeRemote;
      this.envelopeCreatedRemote = envelopeCreated ?? this.envelopeCreatedRemote;
    }
  }

  private updateTabsAfterAgentInstruction(): void {
    this.updateTab(true, TAB.E_SIGNATURE_INSTRUCTION);
    this.tabDetailsService.updateTabLockStatus(TAB.SIGNATURE_METHOD, true);
    this.tabDetailsService.updateTabLockStatus(this.activeTab.name, true);
  }

  deleteTabsAfterUnlock(tabList: string[]): void {
    this.deleteDataFromEticketQuestionMap(tabList);
    this.caseUtilsService.deleteTabsAfterUnlock(tabList);
    // clear signer list
    this.signerList = [];
    this.hasRemoteSigners = undefined;
  }

  private getRecipientList(): void {
    if (this.signerList?.length === 0) {
      this.showPageLoader(true);
      this.pageLoadService.getRecipientsList().subscribe(response => {
        if (response?.data) {
          this.signerList = this.caseUtilsService.sortListBySignerRole(response.data, true);
          this.agentSignerInfo = this.caseUtilsService.getSignerByRole(response.data, 'Agent');
          this.hasRemoteSigners = this.caseUtilsService.hasElectronicSignerAvailable(this.signerList);
          this.addConsentPageForInPersonSignature(false);
          this.showPageLoader(false);
        }
      });
    }
  }

  private updateTabList(tab: string): void {
    const isTabValid = TABS_ALWAYS_IN_GOOD_ORDER.includes(tab);
    this.tabDetailsService.updateTabList(tab, true, { isLocked: false, errorExists: !isTabValid, visited: false });
    this.tabDetailsService.updateTabStatusMap(tab, isTabValid);
  }

  previousTab(): void {
    this.pageLoadService.logButtonClick('back');
    this.tabDetailsService.previous();
  }

  isTabsLocked(): boolean {
    return this.tabDetailsService.isTabsLocked();
  }

  hasNext(): boolean {
    return this.tabDetailsService.hasNextTab();
  }

  hasPrevious(): boolean {
    return this.tabDetailsService.hasPreviousTab();
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  checkIfActiveTabLocked(): boolean {
    return this.tabDetailsService.getTabInfoByTabName(this.activeTab?.name)?.status?.isLocked 
    || !this.userAccessDetails?.editCase 
    || (this.isTabAfterLockPage() && this.userAccessDetails?.readOnlyPostLock);
  }

  isWholesalerCaseSubmitted(): boolean {
    return this.tabDetailsService.isTabActive(TAB.PRIMARY_INSURED_WS);
  }

  private isTabAfterLockPage(): boolean {
    return TABS_AFTER_LOCK_SCREEN.includes(this.activeTab.name);
  }

  signatureMethodUpdated(event: any): void {
    if (event?.signatureMethod === 'electronic') {
      this.selectedSignatureMethod = 'electronic';
    } else if (event?.signatureMethod === 'voice') {
      this.selectedSignatureMethod = 'voice';
    }
    if (event?.isChanged === 'yes') {
      this.deleteListOfTabs(this.tabDetailsService.getTabsAvailableAfter(this.activeTab.name));
      this.clearAgentInstructionData();
    }
  }

  private deletePagesAfterCaseInfoUpdate(): void {
    this.caseUtilsService.deletePagesAfterCaseInfoUpdate(this.prevTabList);
    this.prevTabList = [];
  }

  private deleteListOfTabs(tabsToDelete: TabInformation[]): void {
    const deletedTabs = this.caseUtilsService.deleteListOfTabs(tabsToDelete);
    this.deleteDataFromEticketQuestionMap(deletedTabs);
  }

  private deleteDataFromEticketQuestionMap(tabList: string[]): void {
    tabList.forEach(tab => {
      this.eticketQuestions.delete(tab);
    });
  }

  private updateTab(add: boolean, tab: string): void {
    const isTabValid = TABS_ALWAYS_IN_GOOD_ORDER.includes(tab);
    if (add && !this.tabDetailsService.getTabInfoByTabName(tab)?.isEnable) {
      this.tabDetailsService.updateTabList(tab, true, { visited: false, isLocked: false, errorExists: !isTabValid });
      this.pageLoadService.addEmptyPage(this.pageService.getPageGroupByTabByTabName(tab)).subscribe();
    } else if (!add && this.tabDetailsService.getTabInfoByTabName(tab)?.isEnable) {
      this.tabDetailsService.updateTabList(tab, false, { visited: false, isLocked: false, errorExists: false });
      this.eticketQuestions.delete(tab);
      this.pageLoadService.deletePages([this.pageService.getPageGroupByTabByTabName(tab)]).subscribe();
    }
  }

  private updateUserInfo(event: any): void {
    this.setInsuredDetails(event);
    let residentState;
    let ownerSameAsType;
    if (this.activeTab?.name === TAB.PRIMARY_INSURED_MG) {
      event?.questions?.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === PrimaryInsuredConstant.addressState) {
          residentState = this.utilsService.getCodeFromValue(question.question_answer);
          this.userService.setResidentState(residentState);
        }
      });
    }
    if (this.activeTab?.name === TAB.OWNER_INFO || this.activeTab?.name === TAB.OWNER_INFO_WS) {
      event?.questions?.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === '/XML/ApplicationDetail/OwnerSameAsType') {
          ownerSameAsType = this.utilsService.getCodeFromValue(question.question_answer);
          this.userService.setOwnerSameAsType(ownerSameAsType);
        }
      });
    }
    if (this.activeTab?.name === TAB.CASE_INFO_WS) {
      event?.questions?.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === CaseInfoConstant.caseDesc) {
          this.userService.setCaseDescription(question.question_answer);
        }
      });
    }
  }

  setInsuredDetails(event: any): void {
    let firstName;
    let lastName;
    let middleName;
    let suffix;
    let age;
    let dob;
    let state;
    let productType;
    let productName;
    if ((event?.formValid || this.caseId) && (this.activeTab?.name === TAB.CASE_INFO || this.activeTab?.name === TAB.CASE_INFO_WS)) {
      event?.questions?.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === CaseInfoConstant.firstNameId) {
          firstName = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.lastNameId) {
          lastName = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.suffixId) {
          suffix = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.middleNameId) {
          middleName = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.dobQuestionId) {
          dob = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.ageQuestId) {
          age = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.genderId) {
          this.userService.setInsuredGender(question.question_answer);
        } else if (question.xmlTag === CaseInfoConstant.stateId) {
          state = this.utilsService.getCodeFromValue(question.question_answer);
        } else if (question.xmlTag === CaseInfoConstant.productTypeQuestId) {
          productType = this.utilsService.getCodeFromValue(question.question_answer);
        } else if (question.questionText?.toLowerCase() === 'display product name') {
          productName = this.utilsService.getCodeFromValue(question.question_answer);
        }
      });
      this.userService.setInsuredName(firstName, middleName, lastName, suffix);
      this.userService.setInsuredDOBInfo(dob, age);
      this.userService.setInsuredState(state);
      this.productService.setProductName(productName);
      this.productService.setProductType(productType);
      this.productService.setProductCondition();
      this.updateTabStatusIfExistingCase();
    }
    this.setSignatureMethodFlags(event);
  }

  setSignatureMethodFlags(event): void {
    if ((event?.formValid || this.caseId) && this.activeTab?.name === TAB.SIGNATURE_METHOD) {
      if (event.formValid === 'electronic') {
        this.clipboardService.set('POSeSignInd', true);
        this.clipboardService.set('POSWetSignInd', false);
      }
      if (event.formValid === 'wet') {
        this.clipboardService.set('POSWetSignInd', true);
        this.clipboardService.set('POSeSignInd', false);
      }
    }
  }

  /**
   * get page status and update case info tab status
   * then wait for case info to load, so that we will have nex=cessary data to
   * determine the next tabs
   */
  private updatePageStatus(): void {
    if (this.caseId) {
      this.pageLoadService.getPageStatus(this.caseId).pipe(take(1))
        .subscribe(response => {
          if (response.data) {
            this.casePageStatusRes = {
              pageStatusList: response.data.pageStatusList ? this.caseUtilsService.filterOutInactiveTabs(response.data.pageStatusList) : [],
              caseStatus: response.data.caseStatus ? response.data.caseStatus : {},
              additionalInfo: response.data.additionalInfo ?? {}
            };
            this.updateAdditionalInfoForExistingCase(response.data);
            this.pageLoadService.updateTabInfoForExistingCase(response.data, this.userRole === ROLE_TYPE.WHOLESALER);
            this.updateAccessibilityForUser();
            this.isPolicySubmitted = response.data?.caseStatus?.status === CaseStatus.APPLICATION_ESUBMITTED;
            this.tabStatusChanged.next(true);
          }
        });
    }
  }

  private updateAdditionalInfoForExistingCase(data: any): void {
    if (data?.additionalInfo) {
      this.updateCaseService.setSavedData(data.additionalInfo);
    }
  }

  /**
   * once case info details is loaded, based on case info page status load the remaining tabs
   * first load tabs based on the product, state and product type (helps to add tabs which were not visited)
   * then load tabs whose status is fetched by service and lock tabs based on isPageLocked status
   * then update other tabs is page locked or policy created
   * if any consent page is available then lock signature method and agent instruction page for esignature
   */

  private updateTabStatusIfExistingCase(): void {
    const isWholesaler = this.userRole === ROLE_TYPE.WHOLESALER;
    if (this.onLoadExistingCase) {
      this.tabStatusChanged.pipe(take(1)).subscribe((tabStatusUpdated) => {
        if (tabStatusUpdated && this.caseId && (this.activeTab.name === TAB.CASE_INFO || this.activeTab.name === TAB.CASE_INFO_WS)
          && this.onLoadExistingCase) {
          console.log(tabStatusUpdated, this.caseId, this.activeTab.name === TAB.CASE_INFO, this.onLoadExistingCase)
          const caseInfoTabName = isWholesaler ? TAB.CASE_INFO_WS : TAB.CASE_INFO;
          const tabName = this.tabDetailsService.getTabByTabName(caseInfoTabName);
          if (tabName?.isEnable && tabName.status.visited) {
            this.tabDetailsService.updateTabListbyConfig();
            if (isWholesaler && this.pageLoadService.isWholesalerCaseSubmitted(this.casePageStatusRes)) {
              this.tabDetailsService.updateTabListForWholesalerPhase2();
            }
            setTimeout(() => {
              this.pageLoadService.updateTabStatusBasedOnValueFetchedFromService(this.casePageStatusRes, isWholesaler);
              this.pageLoadService.addAdditionalTabsIfPageLockedOrPolicyCreated(this.casePageStatusRes);
              this.pageLoadService.updatePageStatusForESignature(this.casePageStatusRes);
              this.addReviewAndSubmitIfApplicable(this.casePageStatusRes);
              this.allTabsLoaded = true;
              this.accessLastVisitedPage();
              this.removeConsentPagesIfReq(this.casePageStatusRes);
            }, 1500);
          }
          this.onLoadExistingCase = false;
        }
      });
    }
  }

  private accessLastVisitedPage(): void {
    // checking these two values to make sure case info page values are initialized and
    // all tabs are initialized and also lock locked if required
    if (this.caseInfoPageLoaded && this.allTabsLoaded) {
      if (this.userAccessDetails?.caseRelatedAccess?.redirectToLastAccessedPage) {
        this.pageLoadService.goToLastAccessedTab(this.casePageStatusRes);
      }
      // for transact access - land on attestation
      else {
        const policyInfoTab = this.tabDetailsService.getTabByTabName(TAB.POLICY_INFO_MG);
        if (policyInfoTab.isEnable) {
          this.tabDetailsService.selectTab(policyInfoTab);
        }
      }
    }
  }

  private removeConsentPagesIfReq(casePageStatusRes: CaseAndPageStatus): void {
    const envelopeDeclinedStatuses = [CaseStatus.CUSTOMER_DECLINE_TO_ESIGN, CaseStatus.PRINCIPAL_AGENT_DECLINED];
    if (casePageStatusRes.caseStatus?.status && envelopeDeclinedStatuses.includes(casePageStatusRes.caseStatus.status)) {
      // this condition is written for the revisit scenario when case has both in-person and remote signers
      // and in person signer has signed and remote signer has declined to sign
      // then on revisit delete all the pages after signature method page
      if (this.tabDetailsService.hasConsentTab() && this.tabDetailsService.isTabActive(TAB.ELECTRONIC_SIGNATURE)) {
        this.removeTabsAfterSignatureMethod();
      }
    }
  }

  private removeTabsAfterSignatureMethod(): void {
    this.tabDetailsService.selectTab(this.tabDetailsService.getTabInfoByTabName(TAB.SIGNATURE_METHOD));
    this.tabDetailsService.updateTabList(TAB.SIGNATURE_METHOD, true, { isLocked: false, errorExists: false, visited: true });
    this.deleteListOfTabs(this.tabDetailsService.getTabsAvailableAfter(TAB.SIGNATURE_METHOD));
  }

  consentDeclined(event: any): void {
    if (event === 'yes') {
      this.activePacket = undefined;
      this.removeTabsAfterSignatureMethod();
      this.clearAgentInstructionData();
    }
  }

  private addReviewAndSubmitIfApplicable(casePageStatusRes: CaseAndPageStatus): void {
    // checking if it is a eSignature policy not a voice one
    if (casePageStatusRes?.caseStatus?.status === CaseStatus.READY_TO_SUBMIT
      || casePageStatusRes?.caseStatus?.status === CaseStatus.APPLICATION_ESUBMITTED) {
      const isEsignature = casePageStatusRes.pageStatusList.filter((page) => {
        return this.pageService.getTabByPageGroupName(page.pageGroupName) === TAB.ELECTRONIC_SIGNATURE ||
          page.pageGroupName?.indexOf('LFG - eTicket - Consent') > -1;
      })?.length > 0;
      if (isEsignature) {
        this.updateTab(true, TAB.REVIEW_AND_SUBMIT);
        this.tabDetailsService.updateTabStatusMap(TAB.REVIEW_AND_SUBMIT, true);
        setTimeout(() => {
          this.tabDetailsService.lockAllTabs(true, [TAB.REVIEW_AND_SUBMIT]);
          this.signingCompleted = true;
        }, 500);
      }
    }
  }

  private clearDataIfExists(): void {
    this.eticketQuestions.delete(TAB.APPLICATION_SUMMARY);
  }

  private saveDataAndGetDataForNextPage(activeTab: TabInformation, callDocusign?): void {
    if (this.isDataSaveRequired()) {
      this.triggerSaveAndUpdateRecipients(activeTab, callDocusign);
    } else if (this.activeTab?.name === TAB.WET_SIGN_COLLECTION_INFO_MG && this.latestEmittedData?.isFormChange) {
      this.pageLoadService.updateEmptyPageStatus(PAGE_GROUP_NAME.WET_SIGN_COLLECTION_INFO_PAGE, true).subscribe();
      this.activeTab = activeTab;
      this.getQuestions();
      window.scroll(0, 0);
    } else {
      this.activeTab = activeTab;
      this.clearDataIfExists();
      if (!TABS_EXCLUDED_FROM_GET_QUESTIONS.includes(this.activeTab?.name)) {
        this.getQuestions();
      }
      if (this.activeTab?.name === TAB.E_SIGNATURE_INSTRUCTION) {
        this.getRecipientList();
      }
      window.scroll(0, 0);
    }
  }

  triggerSaveAndUpdateRecipients(activeTab, callDocusign): void {
    const subscrp = this.saveAnswers().subscribe((pageGroupName) => {
      console.log(pageGroupName)
      if (pageGroupName) {
        if (this.isSignatureFormChange && callDocusign) {
          this.triggerSaveRecipientCall();
        } else if (this.hasRemoteSigners && callDocusign) {
          this.updateTabList(TAB.E_SIGNATURE_INSTRUCTION);
        } else if (!this.hasRemoteSigners && callDocusign) {
          this.createEnvelope('inperson');
        }
        this.activeTab = activeTab;
        this.clearDataIfExists();
        if (!TABS_EXCLUDED_FROM_GET_QUESTIONS.includes(this.activeTab?.name)) {
          this.getQuestions(callDocusign);
        }
        window.scroll(0, 0);
        subscrp.unsubscribe();
      }
    });
  }

  triggerSaveRecipientCall(): void {
    this.showPageLoader(true);
    this.saveRecipientInfo().subscribe(response => {
      if (response.responseStatus === 'SUCCESS') {
        if (this.hasRemoteSigners) {
          this.updateTabList(TAB.E_SIGNATURE_INSTRUCTION);
          this.tabDetailsService.next();
        } else {
          this.createEnvelope('inperson');
        }
        this.showPageLoader(false);
      }
    });
  }

  private saveRecipientInfo(): Observable<ResponseObject> {
    console.log('this.agentSignerInfo', this.agentSignerInfo)
    if (this.activeTab.name === TAB.SIGNATURE_METHOD && this.isSignatureFormChange && this.clipboardService.get('POSeSignInd')) {
      this.updateSignerListForSave();
      this.agentSignerInfo.eSignatureMethod = this.caseUtilsService.hasElectronicSignerAvailable(this.signerList) ? 'remote' : 'inperson';
      this.isSignatureFormChange = false;
      return this.pageLoadService.saveRecipientInfo(this.signerList, this.agentSignerInfo);
    }
  }

  private getQuestions(callDocusign?): any {
    const pageGroupName = this.pageService.getPageGroupByTab(this.activeTab);
    if (this.activeTab?.name === TAB.E_SIGNATURE_INSTRUCTION || this.activeTab?.name === TAB.SIGNATURE_METHOD) {
      this.eticketQuestions.delete(this.activeTab?.name);
    }
    if (this.activeTab?.name === TAB.COVERAGE_SUMMARY_WS) {
      this.wholeSalerSummaryData = undefined;
      this.showPageLoader(true);
      this.pageLoadService.getWholesalerSummaryData().subscribe((resp) => {
        if (resp.responseStatus === 'SUCCESS' && resp.data?.length > 0) {
          this.wholeSalerSummaryData = resp.data;
        }
      }).add(() => {
        this.showPageLoader(false);
      });
    } else if (this.allowGetQuestionCall(pageGroupName) || !this.eticketQuestions.get(this.activeTab?.name)) {
      this.getQuestionByPageGroup(pageGroupName, callDocusign);
      setTimeout(() => {
        if (this.activeTab?.name === TAB.SIGNATURE_METHOD) {
          this.getRecipientList();
        }
        if (this.tabDetailsService.isConsentTab(this.activeTab.name)) {
          this.getRecipientList();
          this.getActivePacketDetails();
        }
      }, 200);
    }
  }

  private getActivePacketDetails(voidEnvelope?: boolean, reason?: string): any {
    if (!this.activePacket) {
      this.showPageLoader(true);
      this.pageLoadService.getPackageDetails().subscribe((response) => {
        if (response.data?.activePacket) {
          this.activePacket = response.data.activePacket;
          if (voidEnvelope) {
            this.voidEnvelopeCall(reason);
          }
        }
      }).add(() => {
        this.showPageLoader(false);
      });
    }
  }

  caseUnlocked(event: any): void {
    if (event === 'yes') {
      this.voidEnvelope('CASE_UNLOCKED');
    }
  }

  unlockPageAndVoidEnvelope(): void {
    this.deleteListOfTabs(this.tabDetailsService.getTabsAvailableAfter(this.activeTab.name));
    this.tabDetailsService.updateTabLockStatus(this.activeTab.name, false);
    this.clearAgentInstructionData();
    this.updateAreYouSureModalPopup(false);
    window.scroll(0, 0);
    // call service to void envelope and delete the data from getSigner
    this.voidEnvelope();
  }

  private voidEnvelope(reason?: string): void {
    if (this.activePacket) {
      this.voidEnvelopeCall(reason);
    } else {
      this.getActivePacketDetails(true, reason);
    }
  }

  private voidEnvelopeCall(reason?: string): void {
    if (this.activePacket?.envelopeId) {
      this.showPageLoader(true);
      this.pageLoadService.voidEnvelope(this.activePacket?.envelopeId, reason ?? 'PAGE_UNLOCKED').subscribe((res) => {
        if (res?.responseStatus === 'SUCCESS' && res?.data?.voided) {
          this.activePacket = undefined;
        }
      }).add(() => {
        this.showPageLoader(false);
      });
    }
  }

  allowGetQuestionCall(pageGroupName: string): boolean {
    if (pageGroupName === PAGE_GROUP_NAME.OWNER_INFO_PAGE || pageGroupName === PAGE_GROUP_NAME.WS_OWNER_INFO_PAGE || pageGroupName === PAGE_GROUP_NAME.BENEFICIARY_INFO_PAGE || pageGroupName === PAGE_GROUP_NAME.WS_BENEFICIARY_INFO_PAGE 
      || pageGroupName === PAGE_GROUP_NAME.BILLING_INFO_PAGE || pageGroupName === PAGE_GROUP_NAME.WS_BILLING_INFO_PAGE
      || pageGroupName === PAGE_GROUP_NAME.TEMPORARY_LIFE_INSURANCE_PAGE || pageGroupName === PAGE_GROUP_NAME.WS_TEMPORARY_LIFE_INSURANCE_PAGE 
      || pageGroupName === PAGE_GROUP_NAME.DCA_PREMIUM_ALLOCATION || pageGroupName === PAGE_GROUP_NAME.WS_DCA_PREMIUM_ALLOCATION
      || pageGroupName === PAGE_GROUP_NAME.LTC_SUITABILITY_PAGE || pageGroupName === PAGE_GROUP_NAME.WS_LTC_SUITABILITY_PAGE
      || pageGroupName === PAGE_GROUP_NAME.LTC_REPLACEMENT_PAGE || pageGroupName === PAGE_GROUP_NAME.WS_LTC_REPLACEMENT_PAGE 
      || pageGroupName === PAGE_GROUP_NAME.ILLUSTRATION_COMPLIANCE_PAGE) {
      this.eticketQuestions.delete(this.activeTab?.name);
      return true;
    }
    return false;
  }

  private getQuestionByPageGroup(pageGroupName: string, callDocusign?): void {
    const product = this.productService.getProductName();
    const stateCode = this.userService.getInsuredState();
    const productType = this.productService.getProductType();
    const insuredAge = this.userService.getInsuredAge()?.toString();
    const caseId = this.pageLoadService.getCaseId();
    const mktName = this.productService.getMarketName();
    this.showPageLoader(true);
    this.pageLoadService
      .getCaseInfoPageQuestions({ pageGroupName, product, stateCode, productType, insuredAge, caseId, mktName })
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe(
        (result) => {
          this.pageLoadService.filterResultsBasedOnBusinessRules(pageGroupName, result.data);
          this.eticketQuestions.set(this.activeTab?.name, result.data);
          this.pageLoadService.updateQuestionMap(result.data);
          if ((this.activeTab?.name === TAB.SIGNATURE_METHOD && !callDocusign) || this.activeTab?.name !== TAB.SIGNATURE_METHOD) {
            this.showPageLoader(false);
          }
          if (pageGroupName === PAGE_GROUP_NAME.OWNER_INFO_PAGE || pageGroupName === PAGE_GROUP_NAME.WS_OWNER_INFO_PAGE) {
            this.userService.setIsInsuredUSCitizen(result?.data?.pages[0]?.usCitizen);
          }
          if (pageGroupName === PAGE_GROUP_NAME.LTC_SUITABILITY_PAGE || pageGroupName === PAGE_GROUP_NAME.WS_LTC_SUITABILITY_PAGE) {
            this.userService.setPolicyInflationProtectionOption(result?.data?.policyInflationProtectionOption);
          }
        },
        (error) => {
          this.showPageLoader(false);
          this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
          console.log(error);
        }
      );
  }

  private saveAnswers(updateWholesalerCaseStatus?: boolean): BehaviorSubject<any> {
    const saveCompletionObservable = new BehaviorSubject(null);
    this.pageLoadService.updateGridSequenceForGridData(this.latestEmittedData.questions);
    this.pageLoadService.updateAgentInformation(this.latestEmittedData.questions);
    this.pageLoadService.updateOtherInformation(this.latestEmittedData.questions);
    this.showPageLoader(true);
    this.pageLoadService.updateCaseDetails(
      this.latestEmittedData.questions,
      !this.buttonStatus?.nextDisable,
      this.tabDetailsService.activeTab,
      false,
      updateWholesalerCaseStatus
    ).pipe(takeUntil(this.unSubscribeSub))
      .subscribe().add(() => {
        this.showPageLoader(false);
        saveCompletionObservable.next(this.latestEmittedData.questions.page_group_name);
      });
    return saveCompletionObservable;
  }

  private createCaseDetails(): any {
    this.showPageLoader(true);
    // to make sure deletePage called only if the case is created
    if (this.caseInfoUpdate && this.pageLoadService.getCaseId()) {
      this.deletePagesAfterCaseInfoUpdate();
    }
    this.pageLoadService
      .saveCaseDetails(this.latestEmittedData.questions, !this.buttonStatus?.nextDisable, this.caseInfoUpdate, this.userRole)
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe(
        (result) => {
          this.caseInfoUpdate = false;
          this.prevTabList = [];
          if (result.data?.caseId) {
            this.pageLoadService.setCaseId(result.data?.caseId);
            this.userService.setRefCaseId(result.data?.refCaseId)
            this.latestEmittedData.isSaved = true;
            this.tabDetailsService.next();
            this.showPageLoader(false);
          } else if (result.error.errorCode === 'E500') {
            this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
            this.showPageLoader(false);
          }
        },
        (error) => {
          this.showPageLoader(false);
          console.log('error response' + error);
        }
      );
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      if (!this.showLoader) {
        this.showLoader = true;
        this.lfgLoaderService.show();
      }
    } else {
      this.lfgLoaderService.hide();
      this.showLoader = false;
    }
  }

  // private getPackageInfo(): void {
  //   let insured;
  //   const ownerList = [];
  //   this.showPageLoader(true);
  //   this.pageLoadService.getPackageDetails().subscribe(response => {
  //     if (response?.data) {
  //       const packagDetails: PackageDetails = response.data;
  //       packagDetails.packet_info?.users?.forEach(user => {
  //         if (user.type?.toLowerCase().indexOf('owner') > -1) {
  //           ownerList.push({ name: user?.name, signatureMethod: user.signatureMethod, type: user.type });
  //         } else if (user.type?.toLowerCase() === 'insured') {
  //           insured = { name: user?.name, signatureMethod: user.signatureMethod, type: user.type };
  //         }
  //       });
  //       this.updateSignerList(insured, ownerList);
  //       this.showPageLoader(false);
  //     }
  //   });
  // }

  private updateSignerList(insured: any, ownerList: any[]): void {
    let signList = [];
    if (insured) {
      signList.push(insured);
    }
    if (ownerList.length > 0) {
      signList = signList.concat(ownerList);
    }
    this.tempSignerList = [...signList];
  }

  private updateSignerListForSave(): void {
    console.log('this.signerInfo', this.signerInfo);
    this.signerList?.forEach((signer, index) => {
      signer.eSignatureMethod = this.signerInfo ? this.signerInfo.get(signer.roleName + index)?.value : '';
    });
    this.hasRemoteSigners = this.caseUtilsService.hasElectronicSignerAvailable(this.signerList);
  }

  private isDataSaveRequired(): boolean {
    if (this.activeTab?.name === TAB.PRIMARY_BENEFICIARY_MG || this.activeTab?.name === TAB.PRIMARY_BENEFICIARY_WS) {
      return (this.latestEmittedData?.isFormChange && !this.activeTab.status.isLocked && !this.latestEmittedData.isSaved);
    } else if (this.activeTab?.name === TAB.SIGNATURE_METHOD || this.activeTab?.name === TAB.E_SIGNATURE_INSTRUCTION) {
      return (this.latestEmittedData?.isFormChange && !this.latestEmittedData.isSaved);
    } else {
      return (this.latestEmittedData?.isFormChange && (!this.activeTab.status.isLocked || this.activeTab.name === TAB.SIGNATURE_METHOD || this.activeTab.name === TAB.E_SIGNATURE_INSTRUCTION)
        && !this.latestEmittedData.isSaved
        && this.activeTab.name === this.pageService.getTabByPageGroupName(this.latestEmittedData?.questions?.page_group_name))
        && ((this.tabDetailsService.isConsentTab(this.activeTab.name) && this.latestEmittedData?.saveRequired)
          || !this.tabDetailsService.isConsentTab(this.activeTab.name))
        && this.userAccessDetails?.editCase;
    }
  }


  private fetchAgentProfileAndPageData(): void {
    this.showPageLoader(true);
    this.subscribeForProfileChangeAndInitPageData();
    this.agentService.fetchAgentProfile();
  }

  private subscribeForProfileChange(): void {
    this.agentService.fetchAgentProfileCompletionSubj.pipe(take(1)).subscribe(res => {
      if (res) {
        this.getDataForActiveTab();
      }
    });
  }

  ngOnDestroy(): void {
    this.unSubscribeSub.next();
    this.unSubscribeSub.complete();
    this.productService.clearProductDetails();
    this.utilsService.clearQuestionMap();
    this.utilsService.clearPdfData();
    this.pageLoadService.setCaseId(undefined);
    this.agentService.clearSavedData();
    this.userService.clearUserDetails();
    this.delegationService.resetUserAccessForCase();
  }

  showHelpSlide(sliderName: string): void {
    this.sliderObj = {
      type: 'step',
      page: this.activeTab.name,
      product: this.productService.getMarketName(),
      illustrationTab: this.illustrationTab,
      focusOnClose: document.querySelector('#' + sliderName)?.nextElementSibling
    };
    setTimeout(() => {
      const ele2 = document.querySelector('.help-slider');
      const ele3 = document.querySelector('#' + sliderName + 'Container');
      if (ele2 && ele3) {
        (ele2 as HTMLElement).style.paddingTop = ((ele3 as HTMLElement).offsetTop - 110) + 'px';
      }
    }, 100);
  }

  sliderEmits(sliderReturnObj: any): void {
    this.sliderObj = null;
    if (sliderReturnObj[`type`].indexOf('step') > -1) {
      const slider = document.querySelector('.help-slider');
      if (slider) {
        (slider as HTMLElement).style.paddingTop = '';
      }
      const ele = document.querySelector('#' + sliderReturnObj[`type`] + 'Container');
      if (ele) {
        (ele as HTMLElement).style.minHeight = '';
      }
    }
  }

  updateIllustrationTab(tab): void {
    console.log(tab)
    this.illustrationTab = tab
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  recipientListUpdated(recipientList: Recipient[]): void {
    if (recipientList?.length > 0) {
      this.signerList = this.caseUtilsService.sortListBySignerRole(recipientList, true);
      this.agentSignerInfo = this.caseUtilsService.getSignerByRole(recipientList, 'Agent');
      this.hasRemoteSigners = this.caseUtilsService.hasElectronicSignerAvailable(this.signerList)
    }
  }

  getSignerbyTab(tabname: string): Recipient {
    return this.caseUtilsService.getSignerbyTab(tabname, this.signerList, this.agentSignerInfo);
  }

  signingStatusUpdated(event: any): void {
    if (event === 'yes') {
      this.saveAnswers().subscribe();
    }
  }

  policyIllustrationNext(event: any): void {
    if (event === 'yes') {
      this.enableNextButtonIllustration = true;
    } else if (event === 'no') {
      this.enableNextButtonIllustration = false;
    }
  }

  nextTabForIllustration(): void {
    if (this.enableNextButtonIllustration) {
      let updateWholesalerCaseStatus = false;
      if (this.tabDetailsService.numberOfActiveTab() === 3) {
        updateWholesalerCaseStatus = true;
      }
      this.tabDetailsService.updateTabListForWholesalerPhase2();
      this.saveAnswers(updateWholesalerCaseStatus).subscribe();
      this.nextTab();
    }
  }

  private clearAgentInstructionData(): void {
    this.updateEnvelopeFlag(false, false, false, 'inperson');
    this.updateEnvelopeFlag(false, false, false, 'remote');
    this.progressPercentage = 0;
    this.displayProgressModalForESign = false;
    this.noOfTimesTransactionStatusApiCalled = 0;
    this.timerSubscription?.unsubscribe();
  }

  rdToeTicketDashboard(): void {
    this.pageLoadService.logButtonClick('back to dashboard');
    if (this.userRole === ROLE_TYPE.WHOLESALER) {
      this.router.navigate(['/dashboard']);
    } else {
      const url = window.location.href;
      const replaceStr = url?.split('#')[1];
      const eticketURL = url?.replace('lincolnlifeeappportal', 'lincolnlifeeticketportal').replace(replaceStr, '/dashboard');
      this.windowReference.location.href = encodeURI(eticketURL);
    }
  }

  private updateFormChangeStatus(event: any): void {
    // this is only applicable for form based tabs
    // check for agent instruction page as for remote users there is no form
    this.isFormChanged = (event.isFormChange && this.activeTab.isFormBasedTab) &&
      (!this.hasRemoteSigners) && this.userAccessDetails?.editCase;
  }

  private updateAccessDetails(): void {
    this.userFunctionalityConfig = this.delegationService.getUserAccessibilityConfig();
    this.userAccessDetails = this.userFunctionalityConfig?.functionalityAccess;
    this.userRole = this.delegationService.getRoleForUser();
    console.log("user role" + this.userRole);
  }

  private updateAccessibilityForUser(): void {
    this.delegationService.updateAccessForCase(this.casePageStatusRes.pageStatusList,
      this.casePageStatusRes.caseStatus?.isCreatedByDelegate,
      this.casePageStatusRes.caseStatus.isLockedByDelegate, this.userFunctionalityConfig);
    this.userAccessDetails = this.userFunctionalityConfig?.functionalityAccess;
    this.userRole = this.delegationService.getRoleForUser();
    console.log("user role" + this.userRole);
  }

  getHelpData(): void {
    this.displayHelpData = true;
    this.closeHelpHint = false;
  }

  closeSlide(closeSlide: boolean): void {
    if (closeSlide) {
      this.closeHelpHint = true;
      setTimeout(() => {
        this.displayHelpData = false;
      }, 500);
    } else {
      this.closeHelpHint = true;
      this.displayHelpData = false;
    }
  }
  openRedirectConfirmationModal(): void {
    this.showConfirmationModal = true;
  }

  confirmationModalResponse($event: boolean): void {
    this.showConfirmationModal = false;
    this.redirectConfirmationSubj.next($event);
  }
}
