import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { DisableStyle } from '@ng/lfg-calendar';

import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { CommonService } from 'src/app/shared/services/common.service';
import { ServiceInvoker } from 'src/app/shared/services/service-invoker';
import { Condition, MessageType, Product, Question } from 'src/app/shared/models/casePage.model';
import { ErrorMessage, CaseInfoConstant, Message } from 'src/config/constants';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { basicDobValidator } from 'src/app/shared/validators/business-validator';
import { LfgLoaderService } from '@ng/lfg-loader';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-case-info',
  templateUrl: './case-info.component.html',
  styleUrls: ['./case-info.component.scss'],
})
export class CaseInfoComponent implements OnInit {
  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() caseInfoUpdated: EventEmitter<any> = new EventEmitter();
  @Output() caseInfoLoaded: EventEmitter<any> = new EventEmitter();

  questionsData;
  showLoader = false;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  selectedProduct: Product;
  caseInfoData: Question[] = [];
  caseInfoForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Date of Birth',
    disableStyle: DisableStyle.locked,
  };
  setTimeFocus = null;
  alldropDownOptions = {};
  calculatedAge: number;
  productList: Product[] = [];
  selectedProductType: string;
  selectedStateCode: string;
  selectedProductCode: string;
  noProductAvailable = false;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  ageQuesId: string;
  previousAge: number;
  isFormUpdated = false;


  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    public utilsService: UtilsService,
    private commonService: CommonService,
    private serviceInvoker: ServiceInvoker,
    private productService: ProductDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private loaderService: LfgLoaderService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.caseInfoForm);
      this.defaultPageLoadService.disableFormIfLocked(this.caseInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      this.updateDOBRelatedValues();
    }
  }

  private updateDOBRelatedValues(): void {
    this.ageQuesId = this.getQuestionIdByXmlTag(CaseInfoConstant.ageQuestId);
    this.previousAge = +((this.caseInfoForm.get(this.ageQuesId)?.value) ?? 0);
  }

  onRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  handleSelectionChange(event, data, _ix?): any {
    this.updateSelectedState(event, data);
    this.updateSelectedProductType(event, data);
    this.updatedAnswersOnHidden(data);
  }

  loadAvailableProduct(): any {
    const param = {
      stateCode: this.selectedStateCode,
      productType: this.selectedProductType,
    };
    if (this.productService.getListOfProduct()?.length > 0) {
      this.productList = this.productService.getListOfProduct();
      this.updateDobValidator();
      this.noProductAvailable = false;
    } else {
      this.loaderService.show();
      this.showLoader = true;
      this.serviceInvoker
        .invokeService('GET_AVAILABLE_PRODUCTS', param)
        .subscribe(
          (productRes) => {
            this.productList = productRes.data;
            this.productService.setListOfProduct(this.productList);
            this.noProductAvailable = false;
            this.updateDobValidator();
            if (this.productList?.length === 0) {
              this.noProductAvailable = true;
            } else if (this.productList?.length > 0) {
              if (this.productService.getProductName()) {
                this.productService.setProductCondition();
              }
            } else if (productRes.error?.errorCode) {
              this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
            }
            // emiting value to indicate case info page is loaded
            this.caseInfoLoaded.emit('yes');
          },
          (_err) => {
            this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
          }
        ).add(() => {
          this.showLoader = false;
          this.loaderService.hide();
        });
    }
    this.defaultPageLoadService.logButtonClick('find available products');
  }

  updateSelectedProduct(product: Product): void {
    if (!product) {
      this.selectedProduct = this.selectedProductCode = undefined;
      this.checkFormStatus();
    } else {
      if (this.selectedProduct?.productName !== product?.productName) {
        this.caseInfoUpdated.emit();
      }
      this.selectedProduct = product;
      this.addDobValidator(product?.productDetail?.condition);
      this.updateProductInQuestionData(product.productName);
    }
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  validateOnFocus(data, type): any {
    this.setTimeFocus = setTimeout(() => {
      if (type === 'in') {
        this.caseInfoForm.get(data.fieldId)?.markAsUntouched();
      } else {
        this.caseInfoForm.get(data.fieldId)?.markAsTouched();
      }
      this.calculateAge();
    }, 10);
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  refresh(): void {
    this.caseInfoForm.get(this.getQuestionIdByXmlTag(CaseInfoConstant.productTypeQuestId))?.setValue('');
    this.caseInfoForm.get(this.getQuestionIdByXmlTag(CaseInfoConstant.stateQuesid))?.setValue('');
    this.updateProductInQuestionData('');
    this.resetProductList();
    this.selectedStateCode = this.selectedProductType = null;
    this.defaultPageLoadService.logButtonClick('reset');
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  private updateSelectedProductType(event: any, data: any): void {
    if (data?.xmlTag === CaseInfoConstant.productTypeQuestId) {
      this.selectedProductType = this.utilsService.getCodeFromValue(
        event?.value
      );
      this.selectedProduct = null;
      this.resetProductList();
      this.productService.setProductType(this.selectedProductType);
      this.caseInfoUpdated.emit();
    }
  }

  private updateSelectedState(event: any, data: any): void {
    if (data?.xmlTag === CaseInfoConstant.stateQuesid) {
      this.selectedStateCode = this.utilsService.getCodeFromValue(event?.value);
      this.resetProductList();
      this.caseInfoUpdated.emit();
      this.selectedProduct = null;
    }
  }

  private resetProductList(): void {
    this.selectedProductCode = null;
    this.productList = [];
    this.productService.setListOfProduct([]);
    this.noProductAvailable = false;
  }

  private buildFormData(): any {
    this.caseInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.caseInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.caseInfoData
    );
    this.caseInfoForm = this.fb.group(form);
    this.valueChanges();
    setTimeout(() => {
      this.makeFieldsNonEditable();
    }, 100);

    this.setProductForm();
  }

  private setProductForm(): void {
    this.selectedStateCode = this.utilsService.getCodeFromValue(
      this.caseInfoForm.get(this.getQuestionIdByXmlTag(CaseInfoConstant.stateQuesid))?.value?.value
    );
    this.selectedProductType = this.utilsService.getCodeFromValue(
      this.caseInfoForm.get(this.getQuestionIdByXmlTag(CaseInfoConstant.productTypeQuestId))?.value?.value
    );
    this.productService.setProductType(this.selectedProductType);
    if (this.selectedStateCode && this.selectedProductType) {
      this.loadAvailableProduct();
      const selectedProd = this.getProductNameFromQuestionData();
      if (selectedProd) {
        this.selectedProductCode = selectedProd;
        this.updateDobValidator();
      }
    }
  }

  private makeFieldsNonEditable(): any {
    const fieldsToBeNonEditable = this.appConfig.caseInfoDisableFieldKey;
    for (const key of fieldsToBeNonEditable) {
      const formKey = this.utilsService.getFieldId(this.caseInfoData, key);
      if (formKey) {
        this.caseInfoForm.get(formKey).disable();
      }
    }
  }

  private updatedAnswersOnHidden(data): any {
    const value = this.caseInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.caseInfoForm.get(key)?.markAsUntouched();
      }
      this.caseInfoForm.patchValue(updatedval);
    }
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    const initialValue = this.caseInfoForm.value;
    this.caseInfoForm.valueChanges.subscribe((_val) => {
      this.checkIfNameOrGenderInfoUpdated(initialValue);
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.caseInfoForm,
      data: null,
    };
    for (const data of this.caseInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const childFormValid =
      this.selectedProduct || this.selectedProductCode ? true : false;
    const obj = {
      formValid: this.formValid && childFormValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'caseInfoForm',
      isFormChange: this.caseInfoForm.dirty,
    };
    this.formStatus.emit(obj);
  }

  private getQuestionIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private calculateAge(): void {
    this.caseInfoForm.get(this.getQuestionIdByXmlTag(CaseInfoConstant.dobQuestionId))?.valueChanges.subscribe((value) => {
      if (value) {
        this.calculatedAge = this.utilsService.calculateAge(value);
        this.caseInfoForm.patchValue({ [this.getQuestionIdByXmlTag(CaseInfoConstant.ageQuestId)]: this.calculatedAge.toString() });
        if (this.previousAge !== this.calculatedAge) {
          this.caseInfoUpdated.emit();
          this.previousAge = this.calculatedAge;
        }
      }
      this.caseInfoForm.get(this.getQuestionIdByXmlTag(CaseInfoConstant.dobQuestionId))?.markAsTouched();
    });
  }

  private addDobValidator(productCondition: Condition): void {
    const dobFieldId = this.getQuestionIdByXmlTag(CaseInfoConstant.dobQuestionId);
    this.caseInfoForm.get(dobFieldId)?.setValidators([basicDobValidator(productCondition?.termMinAge,
      productCondition?.termMaxAge)]);
    this.caseInfoForm.get(dobFieldId)?.updateValueAndValidity();
    this.caseInfoForm.get(dobFieldId)?.markAsTouched();
  }

  private updateDobValidator(): void {
    if (this.productList?.length > 0 && this.selectedProductCode) {
      const selectedProductConditions = this.productList.filter(
        product => product.productName === this.selectedProductCode)[0]?.productDetail?.condition;
      this.addDobValidator(selectedProductConditions);
    }
  }

  private updateProductInQuestionData(productName: string): void {
    this.questionsData?.pages[0]?.questions?.forEach(ques => {
      if (ques.questionText.toLowerCase() === 'display product name') {
        ques.question_answer = productName;
      }
    });
  }

  private getProductNameFromQuestionData(): string {
    return this.questionsData.pages[0].questions.filter(ques => {
      return ques.questionText.toLowerCase() === 'display product name';
    })[0]?.question_answer;
  }

  private checkIfNameOrGenderInfoUpdated(initialValue: any): void {
    const nameAndGenderTags = [CaseInfoConstant.firstNameId, CaseInfoConstant.middleNameId,
    CaseInfoConstant.lastNameId, CaseInfoConstant.suffixId, CaseInfoConstant.genderId];
    if (this.formValid && this.selectedProductCode) {
      nameAndGenderTags.forEach(tag => {
        Object.keys(initialValue).forEach(formcontrol => {
          if (formcontrol.indexOf(tag) > -1 && this.caseInfoForm.value[formcontrol] !== initialValue[formcontrol]) {
            this.isFormUpdated = true;
          }
        });
      });
      if (this.isFormUpdated) {
        this.caseInfoUpdated.emit();
      }
    }
  }

}
