/** Business validators start here */

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ESignatureInstructions, Message } from 'src/config/constants';
import { AgeCondition, Condition } from '../models/casePage.model';

export function stateValidator(solicitationState: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value || !solicitationState) {
      return null;
    }
    const stateSelected = value.value?.substring(0, value.value?.indexOf('{'));
    return stateSelected?.toLowerCase() !== solicitationState.toLowerCase() ? { stateError: true } : null;
  };
}

export function checkForNoOfDigits(minDigit: number, maxDigit: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value.length < minDigit || value.length > maxDigit) ? { noOfDigitError: true } : null;
  };
}

export function checkForAlphaNumeric(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value.match('^[A-Za-z]+$') || value.match('^[0-9]+$')) ? { textError: true } : null;
  };
}

export function checkForGreaterThanZero(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value < 1) ? { textError: true } : null;
  };
}

export function attestValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value.toLowerCase() === 'no{47}no' || value.toLowerCase().indexOf('do not') !== -1) ? { attestError: true } : null;
  };
}

export function basicDobValidator(minAge: number, maxAge: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (minAge === null || maxAge === null) {
      return null;
    } else if (!value) {
      return { dobError: true };
    }
    const age = Math.floor(Math.abs(Date.now() - new Date(value).getTime()) / (1000 * 3600 * 24) / 365);
    return age < minAge || age > maxAge ? { dobLimitError: true } : null;
  };
}

export function rateDurationAndDobValidator(ageConditions: AgeCondition[], duration: string, age: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? control.value.label : null;
    if (!value || !duration || !ageConditions) {
      return null;
    }
    const tobaccoStatus = value?.toLowerCase();
    let error = null;
    ageConditions.forEach(ageCond => {
      const ageCondTobaccoList = ageCond.tabaccoStatusList.map(e => e.toLowerCase());
      if (ageCond.durationList.map(String).includes(duration) &&
        (ageCond.tabaccoStatusList.length === 0 || ageCondTobaccoList.includes(tobaccoStatus))) {
        if (age < ageCond.minAge || age > ageCond.maxAge) {
          error = { termAgeError: true };
        }
      }
    });
    return error;
  };
}

export function rateAndDobValidator(ageConditions: AgeCondition[], state: string, age: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? control.value.label : null;
    if (!value || !state || !ageConditions) {
      return null;
    }
    let error = null;
    const tobaccoStatus = value.toLowerCase();
    ageConditions.forEach(ageCond => {
      const ageCondTobaccoList = ageCond.tabaccoStatusList.map(e => e.toLowerCase());
      if ((ageCond.stateList.includes(state) || ageCond.stateList.length === 0) && (!ageCond.excludedStateList.includes(state)) &&
        (ageCond.tabaccoStatusList.length === 0 || ageCondTobaccoList.includes(tobaccoStatus))) {
        if (age < ageCond.minAge || age > ageCond.maxAge) {
          error = { nonTermAgeError: true };
        }
      }
    });
    return error;
  };
}

export function faceAmountValidator(conditions: Condition): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? control.value : null;
    if (!value || !conditions) {
      return null;
    }
    return value < conditions.minFaceAmount || value > conditions.maxFaceAmount ? { faceAmountError: true } : null;
  };
}


export function modalAmountValidator(premiumMode: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? control.value : null;
    if (!value || !premiumMode) {
      return null;
    }
    const errorMessage = Message.MODAL_PREMIUM_ERROR.replace('{mode}', premiumMode);
    if (premiumMode.toLowerCase().indexOf('semi-annual') > -1) {
      return checkForPremiumVal(100, value, errorMessage);
    } else if (premiumMode.toLowerCase().indexOf('annual') > -1) {
      return checkForPremiumVal(200, value, errorMessage);
    } else if (premiumMode.toLowerCase().indexOf('quarterly') > -1) {
      return checkForPremiumVal(50, value, errorMessage);
    } else if (premiumMode.toLowerCase().indexOf('monthly') > -1) {
      return checkForPremiumVal(15, value, errorMessage);
    } else {
      return null;
    }
  };
}

function checkForPremiumVal(minValue: number, value: number, errorMessage: string): any {
  return value < minValue ? { premiumError: { errorMessage: errorMessage.replace('{minimum}', minValue.toString()) } } : null;
}



export function confirmEmailValidator(email: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value?.toLowerCase() !== email?.toLowerCase())
      ? { confirmEmailError: ESignatureInstructions.EmailError } : null;
  };
}
