import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { textValidator } from '../../shared/validators/validator';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppAnalyticsService } from 'src/app/shared/services/app-analytics.service';

@Component({
  selector: 'app-eticket-search',
  templateUrl: './eticket-search.component.html',
  styleUrls: ['./eticket-search.component.scss'],
})
export class EticketSearchComponent implements OnInit, OnDestroy {
  eticketSearchForm: FormGroup;
  dynamicPlaceHolder: string;
  public isButtonDisabled: boolean;
  public formValueChangeSubscription: Subscription;
  setTimeFocus = null;
  insuredFirstName = '';
  insuredLastName = '';

  @ViewChild('searchBtn') searchButton: ElementRef;
  @Output() searchValue = new EventEmitter<any>();
  @Output() clearSearchData = new EventEmitter<any>();
  constructor(
    private formbuilder: FormBuilder,
    private appAnalyticsService: AppAnalyticsService,
  ) { }

  ngOnInit(): any {
    const formGroupControls: any = {
      clientName: ['', [Validators.minLength(3), textValidator()]],
      producerName: ['', [Validators.minLength(3), textValidator()]],
    };
    this.eticketSearchForm = this.formbuilder.group(formGroupControls);

    this.formValueChangeSubscription =
      this.eticketSearchForm.valueChanges.subscribe(() => {
        this.isButtonDisabled = Object.keys(
          this.eticketSearchForm.controls
        ).some((formKey) => this.eticketSearchForm.controls[formKey].value);
      });
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscription.unsubscribe();
  }

  // convenience getter for easy access to form fields
  get f(): any {
    return this.eticketSearchForm.controls;
  }

  getClientName(clientName: string) {
    const names = clientName?.split(' ');
    this.insuredFirstName = names[1] || ''
    this.insuredLastName = names[0].replace(',', '') || '';
  }
  searchPolicies(): any {
    this.searchButton.nativeElement.blur();
    const clientName = this.eticketSearchForm.get('clientName').value || '';
    const producerName = this.eticketSearchForm.get('producerName').value || '';
    this.getClientName(clientName);
    const searchParams = { insuredFirstName: this.insuredFirstName, insuredLastName: this.insuredLastName, producerName: producerName.trim() };
    this.searchValue.emit(searchParams);
    this.appAnalyticsService.logButtonEvents('search', 'eticket - dashboard');
  }

  clearSearch(): void {
    // this.eticketSearchForm.get('userInputField').setValue('');
    this.eticketSearchForm.reset();
    this.clearSearchData.emit('clear');
    this.appAnalyticsService.logButtonEvents('clear', 'eticket - dashboard');
  }

}
