import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(data: any[], filterObj: any): any {
    if (!data || !data.length) {
      return [];
    }
    if (Object.keys(filterObj).length === 0) {
      return data;
    }
    const getfilteredKey = Object.keys(filterObj);
    /*
    return data.filter((item) => {
      const matchingFields = getfilteredKey.some(
        (key) => {

          if (item[key] === 'insuredFirstName' || item[key] === 'insuredFirstName') {
            const firstNameValue = item.insuredFirstName?.replace(/,/g, '').toLowerCase();
            const lastNameValue = item.insuredLastName?.replace(/,/g, '').toLowerCase();

            const filterFirstNameValue = filterObj.insuredFirstName?.replace(/,/g, '').toLowerCase();
            const filterLastNameValue = filterObj.insuredLastName?.replace(/,/g, '').toLowerCase();

            return !(firstNameValue === filterFirstNameValue || lastNameValue === filterLastNameValue);

          }
          const itemValue = item[key]?.replace(/,/g, '').toLowerCase();
          const filterValue = filterObj[key].replace(/,/g, '').toLowerCase();

          return itemValue?.indexOf(filterValue) < 0;
        }
      );
      return !matchingFields;
    });
    // */
    return data.filter(item => {
      return getfilteredKey.some(key => {
        if (filterObj['insuredFirstName'] == '' && filterObj['insuredLastName'] != '') {
          return item['insuredLastName'] && filterObj['insuredLastName'] && (this.toLW(item['insuredLastName'])).startsWith(this.toLW(filterObj['insuredLastName']));
        }
        if (filterObj['insuredFirstName'] != '' || filterObj['insuredLastName'] != '') {
          return item['insuredFirstName'] && item['insuredLastName'] && filterObj['insuredFirstName'] && filterObj['insuredLastName'] && (this.toLW(item['insuredFirstName']) + ", " + this.toLW(item['insuredLastName'])).indexOf((this.toLW(filterObj['insuredFirstName']) + ", " + this.toLW(filterObj['insuredLastName']))) > -1;
        }
        if (filterObj['producerName'] != "") {
          return item['producerName'] && filterObj['producerName'] && this.toLW(item['producerName']).startsWith(this.toLW(filterObj['producerName']))
        }
      });
    });
    }
    toLW(str) {
        return str?.replace(/,/g, '').toLowerCase();
    }
}
