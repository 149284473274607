<section class="owner-info-page page-content-container">
  <div class="questions--container">
    <div class="loader-container" *ngIf="showLoader">
      <lfg-loader></lfg-loader>
    </div>
    <h1>
      Owner Information
    </h1>
    <lfg-alert-message type="info">All owners must be of the same type (Individual, Trust and Entity). Please provide the owner details accordingly. </lfg-alert-message>
    <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isGridFormValid)" type="error">{{errorMessage}}</lfg-alert-message>
    <lfg-alert-message *ngIf="noOfTrustSelected > 1" type="error">{{multipleTrustError}}</lfg-alert-message>
    <lfg-alert-message *ngIf="noOfEntitySelected > 1" type="error">{{multipleEntityError}}</lfg-alert-message>
    <section>

      <form class="form-group" [formGroup]="ownerInfoForm" id="ownerInfoForm" method="post" *ngIf="ownerInfoData && ownerInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let ownerData of ownerInfoData; let ind = index;" >


          <!-- Questions headers -->
          <div class="questions-header" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.HDR && ownerData.questionText != 'Line' && ownerData.subText != 'Information'"
          [innerHTML]="ownerData.questionText">
          </div>

          <div class="section-divider" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.HDR && ownerData.questionText == 'Line'">
          </div>

          <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
          <div class="questionText" *ngIf="addCorrectPlaceHolder(ownerData, 'display')">
            <span [innerHTML]="addCorrectPlaceHolder(ownerData, 'question')"></span>
          </div>

          <!-- USA address -->
          <div class="questions-header" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
            <h2>{{ ownerData.questionText }}</h2>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.TEXT && ownerData.notes !== 'ITES_FORMAT_AS_ZIPCODE'"
            [ngClass]="{'resetError': !ownerInfoForm.get(ownerData.fieldId).errors}">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(ownerData, 'placeHolder') }}" name="{{ displayTextLabel(ownerData) }}" aria-live="off" type='text'
              customAttr="text" formControlName='{{ ownerData.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols
              [maxlength]="ownerData.maxLength >= 999 ? 30 : ownerData.maxLength" [required]="ownerData.required == 'true'">

              <lfg-error *ngIf="ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid" message="{{ getErrorMsg(ownerData) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

           <!-- Form element for zip  -->
          <div class="form--control" *ngIf="ownerData.controlTypeDesc === appConfig.fieldTypes.TEXT && ownerData.notes === 'ITES_FORMAT_AS_ZIPCODE'">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" mask='00000' id="{{ownerData.fieldId}}"
                placeholder="{{ utilsService.getCorrectContent(ownerData, 'placeHolder') }}" appNoSpecialSymbols
                type='text' [formControl]='ownerInfoForm.get(ownerData.fieldId)'
                autocomplete="off" appValidateOnBlur value="" maxlength='5'
                [ngClass]="{'inp-txt--err': (ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid) }"
                [required]="ownerData.required === 'true'">

              <lfg-error
                *ngIf="ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid"
                message="{{ getErrorMsg(ownerData) }}"></lfg-error>
            </lfg-input-wrap>
          </div>


          <!-- Form element for SSN -->
          <div class="form--control" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.SSN">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(ownerData) }}" placeholder="{{ addCorrectPlaceHolder(ownerData, 'placeHolder') }}"
                mask='AAA-AA-0000' [formControl]='ownerInfoForm.get(ownerData.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9'
                name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="ownerData.required == 'true'">

              <lfg-error *ngIf="(ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid) && ( ownerInfoForm.get(ownerData.fieldId).errors == null || (ownerInfoForm.get(ownerData.fieldId).errors != null && !ownerInfoForm.get(ownerData.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>

              <lfg-error *ngIf="(ownerInfoForm.get(ownerData.fieldId).invalid && ownerInfoForm.get(ownerData.fieldId).touched) &&(ownerInfoForm.get(ownerData.fieldId).errors != null && ownerInfoForm.get(ownerData.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.RADIO">
            <span class="lfg-radio-section"
            [ngClass]="{'radio--err': (ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid)}">
              <p class="question-radio_text">{{ ownerData.questionText + (ownerData.required == 'true' ? '' : ' (optional)') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="ownerData.questionOption[0].description" tabindex="0" id="yes_{{ ownerData.fieldId }}" type="radio"
                  formControlName="{{ ownerData.fieldId }}" name="{{ ownerData.fieldId }}" value="{{ ownerData.questionOption[0].value }}" required="{{ownerData.required}}"
                  (change)="onRadioChange(ownerData)"/>
                <label for="yes_{{ ownerData.fieldId }}">{{ ownerData.questionOption[0].description }}</label>
                <input [attr.aria-label]="ownerData.questionOption[1].description" tabindex="0" id="no_{{ ownerData.fieldId }}" type="radio"
                  formControlName="{{ ownerData.fieldId }}" name="{{ ownerData.fieldId }}" value="{{ ownerData.questionOption[1].value }}" required="{{ownerData.required}}"
                  (change)="onRadioChange(ownerData)">
                <label for="no_{{ ownerData.fieldId }}">{{ ownerData.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid " message="{{ getErrorMsg(ownerData) }}"></lfg-error>
          </div>

          <lfg-alert-message type="info" *ngIf="ownerData.xmlTag === '/XML/ApplicationDetail/OwnerSameAsType' && ownerInfoForm.get(ownerData.fieldId)?.disabled">Only four owner are allowed. If you want to add insured as owner please remove one of the added owner.</lfg-alert-message>

          <lfg-alert-message type="info" *ngIf="ownerData.xmlTag === '/XML/ApplicationDetail/OwnerSameAsType' && solicitationMsgNotWAorUT">State of Solicitation does not match Insured resident state.  A Lincoln representative will contact you for clarification.</lfg-alert-message>

          <lfg-alert-message type="info" *ngIf="ownerData.xmlTag === '/XML/ApplicationDetail/OwnerSameAsType' && solicitationMsgWAorUT">State of Solicitation does not match Insured state.  Cross border sales are not allowed in UT and WA.  If owner/applicant resides in UT or WA, the policy must be sitused there.  A Lincoln representative will contact you for clarification.</lfg-alert-message>

          <lfg-alert-message type="info" *ngIf="ownerData.xmlTag === '/XML/AnswerSet/Party/JointOwner/Indicator' && noAdditionalOwnerForTrustEntity">If you are choosing a Trust or Entity Owner type, only one owner can be added to this case.</lfg-alert-message>

          <!-- Form Element for date  -->
          <div class="form--control lfg-cal" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.DOB">
            <lfg-calendar (focusin)="validateOnFocus(ownerData, 'in')" id="{{ownerData.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(ownerData, 'out')"
              lfgCheckValidDate [formControl]="ownerInfoForm.get(ownerData.fieldId)" [config]="dateConfig"
              [class.calendar--err]="(ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid)" [required]="ownerData.required == 'true'">
              <lfg-error *ngIf="ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid " message="{{ getErrorMsg(ownerData) }}"></lfg-error>
            </lfg-calendar>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ ownerData.fieldId }}" id="{{displayIdCreation(ownerData)}}_{{ind}}" [options]="alldropDownOptions[ownerData.fieldId]"
              placeholder="{{ addCorrectPlaceHolder(ownerData, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(ownerData, ind)" [required]="ownerData.required == 'true'"
              [ngClass]="{'dropdown--err': (ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid)}">
            </lfg-dropdown>
            <lfg-error *ngIf="ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid" message="{{ getErrorMsg(ownerData, ownerInfoForm.get(ownerData.fieldId)) }}"></lfg-error>

          </div>

          <!-- Form element for phone number -->
          <div class="form--control" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.PHONE">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(ownerData, 'placeHolder') }}" mask='(000) 000-0000' type='text'
                formControlName='{{ ownerData.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="ownerData.required == 'true'">
              <lfg-error *ngIf="(ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid)" message="{{ getErrorMsg(ownerData) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for email  -->
          <div class="form--control" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.EMAIL">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(ownerData, 'placeHolder') }}" name="{{ displayTextLabel(ownerData) }}" aria-live="off" type='text'
                maxlength='50' formControlName='{{ ownerData.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email"
                [required]="ownerData.required == 'true'">

              <lfg-error *ngIf="ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid" message="{{ getErrorMsg(ownerData) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(ownerData) }}" placeholder="{{ addCorrectPlaceHolder(ownerData, 'placeHolder') }}"
                appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ ownerData.fieldId }}' autocomplete="off" name="" appValidateOnBlur value=""
                [maxlength]="ownerData.maxlength" [required]="ownerData.required == 'true'">

              <lfg-error *ngIf="ownerInfoForm.get(ownerData.fieldId).touched && ownerInfoForm.get(ownerData.fieldId).invalid" message="{{ getErrorMsg(ownerData) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="ownerData.controlTypeDesc == appConfig.fieldTypes.HDR && ownerData.subText == 'Information'">
            <lfg-alert-message type="info">{{ ownerData.questionText }}</lfg-alert-message>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="ownerData.hasReflexive == 'true' && ownerInfoForm.get(ownerData.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayedOwner(ownerData) && !hideCIVQuestions }">
            <ng-container *ngFor="let ownerChildL1 of ownerData.reflexiveQuestionAL; index as ownerChildL1ix">
              <ng-container *ngIf="nestedQuestionCheckOwner(ownerData, ownerChildL1, parentAnswer)">

                <!-- Questions headers -->
                <div class="questions-header" *ngIf="ownerChildL1.controlTypeDesc == appConfig.fieldTypes.HDR">
                <h2>{{ ownerChildL1.questionText }}</h2>
                </div>

                <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                <div class="questionText" *ngIf="addCorrectPlaceHolder(ownerChildL1, 'display') && !hideCIVQuestions">
                  {{ addCorrectPlaceHolder(ownerChildL1, 'question')  }}
                </div>

                <!-- Form element for text  -->
                <div class="form--control" *ngIf="ownerChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && ownerChildL1.notes !== 'ITES_FORMAT_AS_ZIPCODE'"
                  [ngClass]="{'resetError': !ownerInfoForm.get(ownerChildL1.fieldId).errors}">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(ownerChildL1) }}" placeholder="{{ addCorrectPlaceHolder(ownerChildL1, 'placeHolder') }}"
                      type='text' formControlName='{{ ownerChildL1.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="'text'"
                      [maxlength]="ownerChildL1.maxLength >= 999 ? 30 : ownerChildL1.maxLength" [required]="ownerChildL1.required == 'true'">
                    <lfg-error *ngIf="ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid" message="{{ getErrorMsg(ownerChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for zip  -->
                <div class="form--control" *ngIf="ownerChildL1.controlTypeDesc === appConfig.fieldTypes.TEXT && ownerChildL1.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" mask='00000' id="{{ownerChildL1.fieldId}}"
                      placeholder="{{ utilsService.getCorrectContent(ownerChildL1, 'placeHolder') }}" appNoSpecialSymbols
                      type='text' [formControl]='ownerInfoForm.get(ownerChildL1.fieldId)'
                      autocomplete="off" appValidateOnBlur value="" maxlength='5'
                      [ngClass]="{'inp-txt--err': (ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid) }"
                      [required]="ownerChildL1.required === 'true'">

                    <lfg-error
                      *ngIf="ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid"
                      message="{{ getErrorMsg(ownerChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for alphanumeric  -->
                <div class="form--control" *ngIf="ownerChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(ownerChildL1) }}" placeholder="{{ addCorrectPlaceHolder(ownerChildL1, 'placeHolder') }}"
                      appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ ownerChildL1.fieldId }}' autocomplete="off" name=""
                      appValidateOnBlur value="" [maxlength]="ownerChildL1.maxLength" [required]="ownerChildL1.required == 'true'">

                    <lfg-error *ngIf="ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid" message="{{ getErrorMsg(ownerChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form Element for date  -->
                <div class="form--control lfg-cal" *ngIf="ownerChildL1.controlTypeDesc == appConfig.fieldTypes.DOB">
                  <lfg-calendar (focusin)="validateOnFocus(ownerChildL1, 'in')" id="{{ownerChildL1.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(ownerChildL1, 'out')"
                    lfgCheckValidDate [formControl]="ownerInfoForm.get(ownerChildL1.fieldId)" [config]="dateConfig"
                    [class.calendar--err]="(ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid)" [required]="ownerChildL1.required == 'true'">
                    <lfg-error *ngIf="ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid " message="{{ getErrorMsg(ownerChildL1) }}"></lfg-error>
                  </lfg-calendar>
                </div>

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" *ngIf="(ownerChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT && !hideCIVQuestions)" [ngClass]="{'drop-down-medium': ownerChildL1.questionText.length > 32}">
                  <lfg-dropdown formControlName="{{ ownerChildL1.fieldId }}" id="{{displayIdCreation(ownerChildL1)}}_{{ownerChildL1ix}}"
                    [options]="alldropDownOptions[ownerChildL1.fieldId]" placeholder="{{ addCorrectPlaceHolder(ownerChildL1, 'placeHolder') }}"
                    (selectionChanged)="handleSelectionChange(ownerChildL1, ownerChildL1ix)" [required]="ownerChildL1.required == 'true'"
                    [ngClass]="{'dropdown--err': (ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid" message="{{ getErrorMsg(ownerChildL1) }}"></lfg-error>
                </div>

                <!-- Form element for radio question -->
                <div class="lfg-customRadio-field" *ngIf="ownerChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO">
                  <span class="lfg-radio-section"
                  [ngClass]="{'radio--err': (ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid)}">
                    <p class="question-radio_text">{{ ownerChildL1.questionText + (ownerChildL1.required == 'true' ? '' : ' (optional)') }}</p>
                    <div class="custom-radio-button">
                      <input [attr.aria-label]="ownerChildL1.questionOption[0].description" tabindex="0" id="yes_{{ ownerChildL1.fieldId }}" type="radio"
                        formControlName="{{ ownerChildL1.fieldId }}" name="{{ ownerChildL1.fieldId }}" value="{{ ownerChildL1.questionOption[0].value }}"
                        required="{{ownerChildL1.required}}" (change)="onRadioChange(ownerChildL1)"/>
                      <label for="yes_{{ ownerChildL1.fieldId }}">{{ ownerChildL1.questionOption[0].description }}</label>
                      <input [attr.aria-label]="ownerChildL1.questionOption[1].description" tabindex="0" id="no_{{ ownerChildL1.fieldId }}" type="radio"
                        formControlName="{{ ownerChildL1.fieldId }}" name="{{ ownerChildL1.fieldId }}" value="{{ ownerChildL1.questionOption[1].value }}"
                        required="{{ownerChildL1.required}}" (change)="onRadioChange(ownerChildL1)">
                      <label for="no_{{ ownerChildL1.fieldId }}">{{ ownerChildL1.questionOption[1].description }}</label>
                    </div>
                  </span>
                  <lfg-error *ngIf="ownerInfoForm.get(ownerChildL1.fieldId).touched && ownerInfoForm.get(ownerChildL1.fieldId).invalid " message="{{ getErrorMsg(ownerChildL1) }}"></lfg-error>
                </div>


                <!-- Nestion question Level 2 -->
                <div *ngIf="ownerChildL1.hasReflexive == 'true' && ownerInfoForm.get(ownerChildL1.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayedOwner(ownerChildL1) }">
                  <ng-container *ngFor="let ownerChildL2 of ownerChildL1.reflexiveQuestionAL; index as ownerChildL1ix">
                    <ng-container *ngIf="nestedQuestionCheckOwner(ownerChildL1, ownerChildL2, parentAnswer)">

                      <!-- Questions headers -->
                      <div class="questions-header" *ngIf="ownerChildL2.controlTypeDesc == appConfig.fieldTypes.HDR">
                      <h2>{{ ownerChildL2.questionText }}</h2>
                      </div>

                      <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                      <div class="questionText" *ngIf="addCorrectPlaceHolder(ownerChildL2, 'display')">
                        {{ addCorrectPlaceHolder(ownerChildL2, 'question')  }}
                      </div>

                      <!-- Form element for text  -->
                      <div class="form--control" *ngIf="ownerChildL2.controlTypeDesc == appConfig.fieldTypes.TEXT && ownerChildL2.notes !== 'ITES_FORMAT_AS_ZIPCODE'"
                        [ngClass]="{'resetError': !ownerInfoForm.get(ownerChildL2.fieldId).errors}">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" name="{{ displayTextLabel(ownerChildL2) }}" placeholder="{{ addCorrectPlaceHolder(ownerChildL2, 'placeHolder') }}"
                            type='text' formControlName='{{ ownerChildL2.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="'text'"
                            [maxlength]="ownerChildL2.maxLength >= 999 ? 30 : ownerChildL2.maxLength" [required]="ownerChildL2.required == 'true'">
                          <lfg-error *ngIf="ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid" message="{{ getErrorMsg(ownerChildL2) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form element for zip  -->
                      <div class="form--control" *ngIf="ownerChildL2.controlTypeDesc === appConfig.fieldTypes.TEXT && ownerChildL2.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" mask='00000' id="{{ownerChildL2.fieldId}}"
                            placeholder="{{ utilsService.getCorrectContent(ownerChildL2, 'placeHolder') }}" appNoSpecialSymbols
                            type='text' [formControl]='ownerInfoForm.get(ownerChildL2.fieldId)'
                            autocomplete="off" appValidateOnBlur value="" maxlength='5'
                            [ngClass]="{'inp-txt--err': (ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid) }"
                            [required]="ownerChildL2.required === 'true'">

                          <lfg-error
                            *ngIf="ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid"
                            message="{{ getErrorMsg(ownerChildL2) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form element for alphanumeric  -->
                      <div class="form--control" *ngIf="ownerChildL2.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" name="{{ displayTextLabel(ownerChildL2) }}" placeholder="{{ addCorrectPlaceHolder(ownerChildL2, 'placeHolder') }}"
                            appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ ownerChildL2.fieldId }}' autocomplete="off" name=""
                            appValidateOnBlur value="" [maxlength]="ownerChildL2.maxLength" [required]="ownerChildL2.required == 'true'">

                          <lfg-error *ngIf="ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid" message="{{ getErrorMsg(ownerChildL2) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form Element for date  -->
                      <div class="form--control lfg-cal" *ngIf="ownerChildL2.controlTypeDesc == appConfig.fieldTypes.DOB">
                        <lfg-calendar (focusin)="validateOnFocus(ownerChildL2, 'in')" id="{{ownerChildL2.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(ownerChildL2, 'out')"                          lfgCheckValidDate [formControl]="ownerInfoForm.get(ownerChildL2.fieldId)"
                          [config]="(ownerChildL2.questionText.indexOf('Expiration Date') >= 0) ? utilsService.getCalendarConfig(ownerChildL2, dateConfigForExpiration) :  utilsService.getCalendarConfig(ownerChildL2, dateConfig)"
                          [class.calendar--err]="(ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid)" [required]="ownerChildL2.required == 'true'">
                          <lfg-error *ngIf="ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid " message="{{ getErrorMsg(ownerChildL2) }}"></lfg-error>
                        </lfg-calendar>
                      </div>

                      <!-- Form element for Drop Down -->
                      <div class="form--control form-dropDown" *ngIf="ownerChildL2.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': ownerChildL2.questionText.length > 32}">
                        <lfg-dropdown formControlName="{{ ownerChildL2.fieldId }}" id="{{displayIdCreation(ownerChildL2)}}_{{ownerChildL2ix}}"
                          [options]="alldropDownOptions[ownerChildL2.fieldId]" placeholder="{{ addCorrectPlaceHolder(ownerChildL2, 'placeHolder') }}"
                          (selectionChanged)="handleSelectionChange(ownerChildL2, ownerChildL2ix)" [required]="ownerChildL2.required == 'true'"
                          [ngClass]="{'dropdown--err': (ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid)}">
                        </lfg-dropdown>
                        <lfg-error *ngIf="ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid" message="{{ getErrorMsg(ownerChildL2) }}"></lfg-error>
                      </div>

                      <!-- Form element for radio question -->
                      <div class="lfg-customRadio-field" *ngIf="ownerChildL2.controlTypeDesc == appConfig.fieldTypes.RADIO">
                        <span class="lfg-radio-section"
                        [ngClass]="{'radio--err': (ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid)}">
                          <p class="question-radio_text">{{ ownerChildL2.questionText + (ownerChildL2.required == 'true' ? '' : ' (optional)') }}</p>
                          <div class="custom-radio-button">
                            <input [attr.aria-label]="ownerChildL2.questionOption[0].description" tabindex="0" id="yes_{{ ownerChildL2.fieldId }}" type="radio"
                              formControlName="{{ ownerChildL2.fieldId }}" name="{{ ownerChildL2.fieldId }}" value="{{ ownerChildL2.questionOption[0].value }}"
                              required="{{ownerChildL2.required}}" (change)="onRadioChange(ownerChildL2)"/>
                            <label for="yes_{{ ownerChildL2.fieldId }}">{{ ownerChildL2.questionOption[0].description }}</label>
                            <input [attr.aria-label]="ownerChildL2.questionOption[1].description" tabindex="0" id="no_{{ ownerChildL2.fieldId }}" type="radio"
                              formControlName="{{ ownerChildL2.fieldId }}" name="{{ ownerChildL2.fieldId }}" value="{{ ownerChildL2.questionOption[1].value }}"
                              required="{{ownerChildL2.required}}" (change)="onRadioChange(ownerChildL2)">
                            <label for="no_{{ ownerChildL2.fieldId }}">{{ ownerChildL2.questionOption[1].description }}</label>
                          </div>
                        </span>
                        <lfg-error *ngIf="ownerInfoForm.get(ownerChildL2.fieldId).touched && ownerInfoForm.get(ownerChildL2.fieldId).invalid " message="{{ getErrorMsg(ownerChildL2) }}"></lfg-error>
                      </div>

                    </ng-container>
                  </ng-container>
                </div>


              </ng-container>
            </ng-container>
          </div>
          <div class="section-divider"></div>
        </ng-container>
      </form>


      <div *ngIf="showOwner && displayGrid">
        <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridStatus($event)" [hideAddLink]='hideAddLink'
          [showOwner]="showOwner" [ownerAnswers]='ownerAnswers' [removeAdditionalOwner]='removeAdditionalOwner'
          [isRevisit]="isTabVisited" [addAccordionText]="'Add Owner'" [maxGridAvailable]="maxNoOfOwner"
          [accordionHeader]="'Owner'" [type]="'OWNER'" [civInput]="civInput" [disableForm]="disableForm" (hideAddLinkForTrustEntity)="hideAddLinkForTrustEntity($event)"
          [insuredDLInfo]="dlInfo" [isWholesaler]="isWholesaler"></app-grid-structure>
      </div>
      <div *ngIf="!showOwner && displayGrid">
        <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridStatus($event)" [hideAddLink]='hideAddLink'
          [showOwner]="showOwner" [ownerAnswers]='ownerAnswers' [removeAdditionalOwner]='removeAdditionalOwner'
          [isRevisit]="isTabVisited" [addAccordionText]="'Add Owner'" [maxGridAvailable]="maxNoOfOwner"
          [accordionHeader]="'Owner'" [type]="'OWNER'" [civInput]="civInput" [disableForm]="disableForm" (hideAddLinkForTrustEntity)="hideAddLinkForTrustEntity($event)" [isWholesaler]="isWholesaler"></app-grid-structure>
      </div>
    </section>
  </div>
</section>
