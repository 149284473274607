import { Injectable } from '@angular/core';
import { Condition, Product } from '../models/casePage.model';
import { ProductType } from 'src/config/sideNav.config';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {

  private productType: string;
  private productName: string;
  private productId: string;
  private productTypeId: string;
  private productCondition: Condition;
  private listOfProduct: Product[];
  private marketName: string;

  constructor() {
    // write code here
  }


  getProductName(): string {
    return this.productName;
  }

  getProductType(): string {
    return this.productType;
  }

  setProductName(productName: string): void {
    this.productName = productName;
  }

  setProductType(productType: string): void {
    this.productType = productType;
  }

  setProductCondition(): void {
    this.listOfProduct?.forEach(product => {
      if (product.productName === this.productName) {
        this.productCondition = product.productDetail?.condition;
        if (this.productType?.toLowerCase() !== ProductType.TERM) {
          this.marketName = product.productDetail?.mktName;
        }
      }
    });
  }

  getProductCondition(): Condition {
    return this.productCondition;
  }

  setListOfProduct(products: Product[]): void {
    this.listOfProduct = products;
  }

  getListOfProduct(): Product[] {
    return this.listOfProduct;
  }

  getMarketName(): string {
    return this.marketName;
  }

  getProductId(): string {
    return this.productId;
  }

  getProductTypeId(): string {
    return this.productTypeId;
  }


  updateProductDetailsForTermProducts(duration: string): void {
    if (duration && +duration > 0) {
      this.productCondition.ageConditions.forEach(ageCond => {
        if (ageCond.durationList?.includes(+duration)) {
          this.marketName = ageCond.termMktName;
        }
      });
    }
  }

  clearProductDetails(): void {
    this.productType = this.productName = this.productId = undefined;
    this.productCondition = this.marketName = undefined;
    this.listOfProduct = [];
  }
}
