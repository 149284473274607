import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Product } from 'src/app/shared/models/casePage.model';
import { FormGroup, FormControl } from '@angular/forms';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { ProductCodes } from 'src/config/sideNav.config';
import { ErrorMessage, MGMA22_AVAILABLE_STATES } from 'src/config/constants';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  @Input() disableForm: boolean;
  @Input()
  productList: Product[];

  @Input()
  selectedProductCode: string;

  @Input()
  selectedStateCode: string;

  @Output() selectedProduct: EventEmitter<Product> = new EventEmitter();

  productInfoForm: FormGroup;

  showProductUnavailableMessage = false;

  mgma22UnavailableMessage = ErrorMessage.MGMA22_UNAVAILABLE;

  constructor(private defaultPageLoadService: DefaultPageload) {
    // This is intentional
  }

  ngOnInit(): void {
    this.productInfoForm = new FormGroup({});
    this.productInfoForm.addControl('product', new FormControl());
    if (this.selectedProductCode) {
      this.productInfoForm.get('product').setValue(this.selectedProductCode);
    }
    this.filterProductList();
    this.defaultPageLoadService.disableFormIfLocked(this.productInfoForm, this.disableForm);
  }

  onProductSelect(product: Product): void {
    this.selectedProduct.emit(product);
  }

  // temporary code
  // below condition is to support MGMA22 for locked cases
  private filterProductList(): void {
    this.productList?.forEach(product => {
      if ((!this.disableForm && this.checkForMGMA22AndInvalidStates(product.productName))
        || (this.disableForm && this.checkForMGMA22AndInvalidStates(product.productName) && this.selectedProductCode?.toLowerCase() !== ProductCodes.MGMA22)) {
        product.hideProduct = true;
      }
    });
    if (!this.disableForm && this.checkForMGMA22AndInvalidStates(this.selectedProductCode)) {
      this.showProductUnavailableMessage = true;
      this.selectedProduct.emit(undefined);
    }
  }

  private checkForMGMA22AndInvalidStates(productName: string): boolean {
    return productName?.toLowerCase() === ProductCodes.MGMA22 && !MGMA22_AVAILABLE_STATES.includes(this.selectedStateCode);
  }
}
