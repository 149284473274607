import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { LfgLoaderService } from '@ng/lfg-loader';
import { ModalConfig, ModalSize } from '@ng/lfg-modal-popup';
import { GridInstance, Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, ExcludeTabsForValidityWholesaler, ExcludeTabsFromLockWholesaler, Message, PolicyProjectionConstant, PolicyProjectionErrorType, Products } from 'src/config/constants';
import { TAB, TabStatus } from 'src/config/sideNav.config';
import { PolicyProjectionPaymentTableConfig } from '../policy-projection/PolicyProjectionPaymentTableConfig';
import { PolicyProjectionConfig } from 'src/config/policy-projection.config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-policy-projection',
  templateUrl: './policy-projection.component.html',
  styleUrls: ['./policy-projection.component.scss']
})
export class PolicyProjectionComponent implements OnInit, OnChanges {

  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() enableNextButton: EventEmitter<any> = new EventEmitter();
  @Output() wholeSalerCaseUnlocked: EventEmitter<any> = new EventEmitter();
  protected cancelSubscription: Subject<void> = new Subject<void>();
  
  gridInstance1: GridInstance;
  gridAnswers1: any[];
  grid1HasNoErrors = false;
  isGrid1FormValid = true;
  isGrid1FormChange = false;
  maxGridValue1: string;

  gridInstance2: GridInstance;
  gridAnswers2: any[];
  grid2HasNoErrors = false;
  isGrid2FormValid = true;
  isGrid2FormChange = false;
  maxGridValue2: string;
  variableRateOfReturnGridAvailable = false;
  modalPremiumGridAvailable = false;

  formValid = true;
  alldropDownOptions = {};
  policyProjectionForm: FormGroup;
  policyProjectionData: Question[] = [];
  isMGMA: boolean;

  formHasNoErrors = true;

  isPolicyProjectionFormValid = false;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;

  showCaseAttestRadioButton = false;
  showChildRiderInfo = false;

  tabStatusOnInit: TabStatus;
  sectionList: any[] = [];
  solveObjectSectionIndex: number;
  solveObjectSectionQues: Question[];

  lapseSectionIndex: number;
  lapseSectionQues: Question[];

  isAppLocked: boolean;
  isAllTabsValid: boolean;
  isProjectionGenerated: boolean;
  showLoader: boolean;
  errorList: any[] = [];
  summaryReport: any;
  errorInSavingData = false;
  Message = Message;
  openErrorModal = true;
  projectionRunSuccess = false;

  PolicyConst = PolicyProjectionConstant;
  reportGenerationDate: string;
  paymentOptionInfoModal: ModalConfig;
  paymentOptionTableData;
  tableData;
  marketName: string;
  product: string;

  warnings = [];
  infos = [];
  errors = [];
  ErrorType = PolicyProjectionErrorType;

  displayProgressModal = false;
  progressPercentage = 0;
  isProjectionReportAvailable: boolean;
  projectionReportData = '';

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private userService: UserDetailsService,
    private tabDetailsService: TabDetailsService,
    private lfgLoaderService: LfgLoaderService,
    private fileUploadService: FileUploadService,
    private productDetails: ProductDetailsService,
    private policyProjectionConfig: PolicyProjectionConfig,
    private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.product = this.productDetails.getProductName();
      this.isMGMA = this.product?.toLowerCase().indexOf('market advantage') > -1;
      this.buildFormData();
      this.createSections();
      this.filterOptionalSections();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.policyProjectionForm);
      this.defaultPageLoadService.disableFormIfLocked(this.policyProjectionForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      this.initializeValues();
    }
  }

  private loadPaymentOptionTable() {
    this.tableData = new PolicyProjectionPaymentTableConfig().tableData;
    this.marketName = this.productDetails.getMarketName();
    this.policyProjectionConfig.yearDurationMap.forEach((yearDurationData) => {
      if (yearDurationData.productMrktName === this.marketName) {
        this.tableData.data = yearDurationData.ageDurationMap;
      }
    });
  }

  paymentOptionInfo(config): void {
    config.state = true;
  }

  closeModal(config): void {
    config.state = false;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.disableForm?.currentValue !== changes?.disableForm?.previousValue && this.policyProjectionForm) {
      this.defaultPageLoadService.disableFormIfLocked(this.policyProjectionForm, this.disableForm);
    }
  }

  buildFormData(): void {
    this.policyProjectionData = this.questionsData.pages[0]?.questions;
    this.updateGridValues();

    this.updateDefaultStateValue();

    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.policyProjectionData
    );
    const form = this.formgeneratorService.createFormControls(
      this.policyProjectionData
    );
    this.policyProjectionForm = this.fb.group(form);

    this.updateDefaultValuesForAmount();
    this.updateDefaultValuesForPercentage();
    this.updateDefaultAgeValue();
    this.updateInsuredAge();
    this.updateValidationForLumpsumAmount();
    this.disableQuestions();

    this.valueChanges();
  }

  private updateGridValues(): void {
    if (this.isGridAvailable(this.questionsData?.pages[0])) {
      this.gridInstance1 = this.questionsData?.pages[0]?.gridInstances[0];
      this.maxGridValue1 = this.gridInstance1?.maxGridIndex;
      this.gridAnswers1 = this.questionsData?.pages[0]?.gridAnswers[0]?.[this.gridInstance1?.id];
    }
    if (this.isGridAvailable(this.questionsData?.pages[0])) {
      this.gridInstance2 = this.questionsData?.pages[0]?.gridInstances[1];
      this.maxGridValue2 = this.gridInstance2?.maxGridIndex;
      this.gridAnswers2 = this.questionsData?.pages[0]?.gridAnswers[0]?.[this.gridInstance2?.id];
    }
  }

  private isGridAvailable(pageGroup: any): boolean {
    return pageGroup?.gridInstances?.length > 0;
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.policyProjectionForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.policyProjectionForm,
      data: null,
    };
    for (const data of this.policyProjectionData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  emitStatus(): void {
    this.updateGridAnswers();
    this.isPolicyProjectionFormValid = this.formValid && this.isGrid1FormValid && this.isGrid2FormValid;
    const policyProjectionObj = {
      formValid: this.isPolicyProjectionFormValid,
      formHasNoErrors: this.formHasNoErrors && this.grid1HasNoErrors && this.grid2HasNoErrors,
      questions: this.questions,
      formId: 'policyProjectionForm',
      isFormChange: this.policyProjectionForm.dirty || this.isGrid1FormChange || this.isGrid2FormChange || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.policyProjectionForm),
    };
    this.formStatus.emit(policyProjectionObj);
  }

  private updateGridAnswers(): void {
    this.questions.pages[0].gridAnswers[0] = {}
    if (this.variableRateOfReturnGridAvailable) {
      this.questions.pages[0].gridAnswers[0][this.gridInstance1?.id] = this.gridAnswers1;
    }
    if (this.modalPremiumGridAvailable) {
      this.questions.pages[0].gridAnswers[0][this.gridInstance2?.id] = this.gridAnswers2;
    }
    if (!this.variableRateOfReturnGridAvailable && !this.modalPremiumGridAvailable) {
      this.questions.pages[0].gridAnswers = [];
    }
  }
  handlePolicyProjectionSelectionChange(data: Question): any {
    this.updateDefaultValuesForAmount(true, data);
    this.updateDefaultValuesForPercentage(true, data);
    this.updateDefaultValuesForAgeOrYear(true, data);
    this.updateYearOrAgeValueForModalPremium(data);
    this.filterOptionalSections();
    this.checkForGridValues();
    this.updatedPolicyProjectionAnswersOnHidden(data);
  }

  updatedPolicyProjectionAnswersOnHidden(data): any {
    const value = this.policyProjectionForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.policyProjectionForm.get(key)?.markAsUntouched();
      }
      this.policyProjectionForm.patchValue(updatedval);
    }
  }

  addPolicyProjectionCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  onRadioChange(data): void {
    this.updateInsuredAge(data);
    this.policyProjectionForm.get(data.fieldId)?.markAsTouched();
    this.updatedPolicyProjectionAnswersOnHidden(data);
  }

  onPolicyProjectionRadioChange(data): void {
    this.policyProjectionForm.get(data.fieldId)?.markAsTouched();
    this.updatedPolicyProjectionAnswersOnHidden(data);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.policyProjectionForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  onCheckboxChange(data): void {
    this.updateDefaultAgeValue(data);
    this.updateValidationForLumpsumAmount(data);
    this.updatedPolicyProjectionAnswersOnHidden(data);
  }

  nestedQuestionPolicyProjectionCheck(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData.optionChoice === 'Yes{47}Yes' || childData.optionChoice === 'Yes') {
        return parent === true;
      } else if (childData.optionChoice === 'No{47}No' || childData.optionChoice === 'No') {
        return parent === false;
      }
    } else {
      return parent === childData.optionChoice;
    }
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private getFieldIdByXmlTagAndAnswerSelected(xmlTag: string, answerSelected: string, childQuesTag: string, grdChildQuesTag?: string, childAnswerText?: string): string {
    return this.getQuestionObj(xmlTag, childQuesTag, answerSelected, grdChildQuesTag, childAnswerText)?.fieldId;
  }

  private getQuestionObj(xmlTag: string, childQuesTag: string, answerText: string, grdChildQuesTag?: string, childAnswerText?: string): Question {
    let childQues, grdChildQues;
    const mainQues = this.getQuestionMainObj(xmlTag, this.policyProjectionData);
    if (mainQues) {
      childQues = this.getReflexiveQuesObj(childQuesTag, mainQues?.reflexiveQuestionAL, answerText);
    }
    if (!grdChildQuesTag) {
      return childQues;
    } else {
      grdChildQues = this.getReflexiveQuesObj(grdChildQuesTag, childQues?.reflexiveQuestionAL, childAnswerText)
    }
    return grdChildQues;
  }

  private getQuestionMainObj(xmlTag: string, questionList: Question[]): Question {
    return questionList.filter(ques => {
      return xmlTag === ques.xmlTag;
    })[0];
  }

  private getReflexiveQuesObj(xmlTag: string, questionList: any[], answerText: string): Question {
    return questionList?.filter(ques => {
      return xmlTag === ques.xmlTag && ques.optionChoice === answerText;
    })[0];
  }

  private createSections(): void {
    let index = -1;
    this.policyProjectionData.forEach((quest) => {
      if (quest.subText !== 'header') {
        let section = this.sectionList[index];
        section.push(quest);
      } else {
        let section = [quest];
        this.sectionList.push(section);
        index++;
      }
      if (quest.questionText?.toLowerCase() === 'solve objective') {
        this.solveObjectSectionIndex = index;
      } else if (quest.questionText?.toLowerCase() === 'no lapse guarantee period') {
        this.lapseSectionIndex = index;
      }
    });
  }

  private updateDefaultValuesForAmount(isSelectionChanged?: boolean, data?: any): void {
    this.updateDefaultValuesForAmountForNonMGMA(isSelectionChanged, data);
    this.updateDefaultValuesForAmountForMGMA(isSelectionChanged, data);
  }

  private updateDefaultValuesForAmountForNonMGMA(isSelectionChanged?: boolean, data?: any): void {
    if (!this.isMGMA && (!data || data.xmlTag === PolicyProjectionConstant.solveFor)) {
      const amountFieldId = this.getFieldIdByXmlTag(PolicyProjectionConstant.amount);
      const amountFormControl = this.policyProjectionForm.get(amountFieldId);
      if (!amountFormControl?.value || isSelectionChanged) {
        const solveForValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.solveFor))?.value?.value;
        const yearAgeCntrl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.yearOrAge));
        const yearCntrl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.year));
        if (solveForValue?.toLowerCase()?.indexOf('premium with monthly benefit amount of') > -1) {
          amountFormControl?.setValue('5000');
          this.triggerDirective(amountFieldId);
          yearAgeCntrl?.setValue({ label: 'Year', value: 'Year' });
          yearCntrl?.setValue('10');
        } else {
          amountFormControl?.setValue('100000');
          this.triggerDirective(amountFieldId);
          this.clearValueForControl(yearAgeCntrl);
          this.clearValueForControl(yearCntrl);
        }
      }
    }
  }

  private updateDefaultValuesForAmountForMGMA(isSelectionChanged?: boolean, data?: any): void {
    if (this.isMGMA) {
      if (!data || data.xmlTag === PolicyProjectionConstant.specifiedAmount) {
        const specifiedAmountValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.specifiedAmount))?.value?.value;
        const amountFieldId = this.getFieldIdByXmlTagAndAnswerSelected(PolicyProjectionConstant.specifiedAmount, specifiedAmountValue, PolicyProjectionConstant.specifiedAmountDollar);
        const amountFormControl = this.policyProjectionForm.get(amountFieldId);
        if (!amountFormControl?.value || isSelectionChanged) {
          if (specifiedAmountValue?.toLowerCase()?.indexOf('ltc max monthly') > -1) {
            amountFormControl?.setValue('5000');
          } else if (specifiedAmountValue?.toLowerCase()?.indexOf('ltc total benefit') > -1) {
            amountFormControl?.setValue('150000');
          } else if (specifiedAmountValue?.toLowerCase()?.indexOf('known face amount') > -1) {
            amountFormControl?.setValue('100000');
          }
          this.triggerDirective(amountFieldId);
        }
      }
  
      if (!data || data.xmlTag === PolicyProjectionConstant.modalPremium) {
        const modalPremiumValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.modalPremium))?.value?.value;
        const amountFieldId = this.getFieldIdByXmlTag(PolicyProjectionConstant.modalPremiumAmount)
        const amountFormControl = this.policyProjectionForm.get(amountFieldId);
        const yearAgeCntrl = this.policyProjectionForm.get(this.getFieldIdByXmlTagAndAnswerSelected(PolicyProjectionConstant.modalPremium, modalPremiumValue, PolicyProjectionConstant.modalPremiumYearAge));
        if (!amountFormControl?.value || isSelectionChanged) {
          if (modalPremiumValue?.toLowerCase()?.indexOf('known premium amount') > -1) {
            amountFormControl?.setValue('15000');
            this.updateYearValue(yearAgeCntrl, PolicyProjectionConstant.modalPremium, modalPremiumValue, PolicyProjectionConstant.modalPremiumYearAge);
          } else {
            if (!yearAgeCntrl?.value) {
              this.updateYearValue(yearAgeCntrl, PolicyProjectionConstant.modalPremium, modalPremiumValue, PolicyProjectionConstant.modalPremiumYearAge);
            }
          }
          this.triggerDirective(amountFieldId);
        }
      }
    }
  }

  private updateYearValue(yearAgeCntrl: any, xmlTag: string, answerSelected: string, childQuesTag: string): void {
    yearAgeCntrl?.setValue({ label: 'Year', value: 'Year' });
    const yearCntrl = this.policyProjectionForm.get(this.getFieldIdByXmlTagAndAnswerSelected(xmlTag, answerSelected, childQuesTag, PolicyProjectionConstant.year, 'Year'));
    yearCntrl?.setValue('10');
  }

  private updateYearOrAgeValueForModalPremium(data: Question): void {
    if (data.xmlTag === PolicyProjectionConstant.modalPremiumYearAge) {
      const modalPremiumYearAgeValue = this.policyProjectionForm.get(data.fieldId);
      if (modalPremiumYearAgeValue?.value?.value === 'Year') {
        const yearCntrl = this.policyProjectionForm.get(this.getReflexiveQuesObj(PolicyProjectionConstant.modalPremiumYear, data?.reflexiveQuestionAL, 'Year')?.fieldId);
        yearCntrl.setValue('10');
      } else if (modalPremiumYearAgeValue?.value?.value === 'Age') {
        const ageCntrl = this.policyProjectionForm.get(this.getReflexiveQuesObj(PolicyProjectionConstant.modalPremiumAge, data?.reflexiveQuestionAL, 'Age')?.fieldId);
        ageCntrl.setValue('0');
      }
    }
  }

  private updateDefaultValuesForPercentage(isSelectionChanged?: boolean, data?: any): void {
    if (!data || data.xmlTag === PolicyProjectionConstant.variableRateOfReturn) {
      const rateOfReturnValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.variableRateOfReturn))?.value?.value;
      const percentageFieldId = this.getFieldIdByXmlTag(PolicyProjectionConstant.variableRRPercentage);
      const percentageFormControl = this.policyProjectionForm.get(percentageFieldId);
      if (!percentageFormControl?.value || isSelectionChanged) {
        if (rateOfReturnValue?.toLowerCase()?.indexOf('known rate of return') > -1) {
          percentageFormControl?.setValue('8');
        }
        this.triggerDirective(percentageFieldId);
      }
    }
  }

  private clearValueForControl(frmCntrl: any): void {
    if (frmCntrl) {
      frmCntrl.setValue('');
      frmCntrl.markAsUntouched();
      frmCntrl.setErrors(null);
    }
  }

  private updateDefaultValuesForAgeOrYear(isSelectionChanged?: boolean, data?: any): void {
    if (!data || data.xmlTag === PolicyProjectionConstant.yearOrAge || data.xmlTag === PolicyProjectionConstant.solveForYearAge ||
      data.xmlTag === PolicyProjectionConstant.guaranteeYearAge) {
      let yearControl, ageControl, yearOrAgeValue;
      if (data.xmlTag === PolicyProjectionConstant.yearOrAge) {
        yearControl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.year));
        ageControl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.age));
        yearOrAgeValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.yearOrAge))?.value?.value;
      } else if (data.xmlTag === PolicyProjectionConstant.solveForYearAge) {
        yearControl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.solveForYear));
        ageControl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.solveForAge));
        yearOrAgeValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.solveForYearAge))?.value?.value;
      } else if (data.xmlTag === PolicyProjectionConstant.guaranteeYearAge) {
        yearControl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.guaranteeYear));
        ageControl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.guaranteeAge));
        yearOrAgeValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.guaranteeYearAge))?.value?.value;
      }
      if (isSelectionChanged) {
        if (yearOrAgeValue?.toLowerCase()?.indexOf('year') > -1) {
          yearControl.setValue('10');
        } else {
          ageControl.setValue('0');
          ageControl.markAsTouched();
          ageControl.updateValueAndValidity();
        }
      }
    }
  }

  private updateDefaultAgeValue(data?: any): void {
    if (!data || data.xmlTag === PolicyProjectionConstant.bTRFunding) {
      const btrFundingValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.bTRFunding))?.value;
      const yearOrAgeContrl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.btrYearOrAge));
      if (btrFundingValue && !yearOrAgeContrl?.value) {
        setTimeout(() => {
          yearOrAgeContrl?.setValue({ label: 'Age', value: 'Age' });
        }, 0);
      } else if (!btrFundingValue) {
        yearOrAgeContrl?.setValue('');
      }
    }
  }

  private updateDefaultStateValue(): void {
    const contractState = this.userService.getInsuredState();
    this.policyProjectionData.forEach(ques => {
      if (ques.xmlTag === PolicyProjectionConstant.alternateCostCareState && !ques.question_answer) {
        ques.question_answer = contractState;
      }
    });
  }

  private updateInsuredAge(data?: any): void {
    if (!data || data.xmlTag === PolicyProjectionConstant.backdatingOption) {
      const backdatingOption = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.backdatingOption))?.value;
      const saveAgeCntrl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.saveAge));
      const numberOfMonthsCntrl = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.numberOfMonths))
      if (backdatingOption?.indexOf('Backdate') > -1) {
        if (!saveAgeCntrl?.value) {
          saveAgeCntrl.setValue(this.userService.getInsuredAge());
        }
        if (!numberOfMonthsCntrl?.value) {
          numberOfMonthsCntrl.setValue('1');
        }
      }
    }
  }

  private disableQuestions(): void {
    const fieldsToDisable = [PolicyProjectionConstant.longTermBenefitRider, PolicyProjectionConstant.valueEndorsementRider,
    PolicyProjectionConstant.valueProtectionRider, PolicyProjectionConstant.longTermAccBenefit, PolicyProjectionConstant.backdatingOption];
    fieldsToDisable.forEach((riderXmlTag) => {
      this.policyProjectionForm.get(this.getFieldIdByXmlTag(riderXmlTag))?.disable();
    });
  }

  private updateValidationForLumpsumAmount(data?: any): void {
    if (!data || data.xmlTag === PolicyProjectionConstant.lumpsumFund) {
      const lumpsumFundValue = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.lumpsumFund))?.value;
      this.policyProjectionData.forEach((ques) => {
        if (ques.xmlTag === PolicyProjectionConstant.lumpsumAmount) {
          ques.required = lumpsumFundValue ? 'true' : 'false';
        }
      });
      this.policyProjectionForm.updateValueAndValidity();
    }
  }

  private initializeValues(): void {
    this.isAppLocked = this.tabDetailsService.getTabsLockStatus(ExcludeTabsFromLockWholesaler);
    this.isAllTabsValid = this.tabDetailsService.isAllTabsValid(ExcludeTabsForValidityWholesaler);
    const projectionDetails = this.userService.getProjectionDetails();
    this.checkForGridValues();
    if (projectionDetails && projectionDetails.isProjectionGenerated) {
      this.reportGenerationDate = projectionDetails.dateOfProjection;
      this.summaryReport = projectionDetails.projectionSummaryData;
      this.projectionRunSuccess = true;
      this.enableNextButton.emit('yes');
    }
    this.paymentOptionInfoModal = {
      header: true,
      state: false,
      footer: true,
      effect: true,
      size: ModalSize.medium
    };
    this.loadPaymentOptionTable();
  }

  private checkForGridValues(): void {
    this.variableRateOfReturnGridAvailable = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.variableRateOfReturn))?.value?.value?.toLowerCase() === 'vary the rate of return';
    this.modalPremiumGridAvailable = this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.modalPremium))?.value?.value?.toLowerCase() === 'vary the scheduled modal premium';
  }

  generateProjection(): void {
    this.displayProgressModal = true;
    this.userService.setProjectionDetails(false, '', null);
    this.resetError();
    this.projectionRunSuccess = false;
    this.defaultPageLoadService.updateCaseDetails(this.questions, true, this.tabDetailsService.activeTab, false)
      .subscribe((saveResp) => {
        if (saveResp.data) {
          this.cancelSubscription = new Subject<void>();
          // call designIT for projection
          this.defaultPageLoadService.generateProjection()
          .pipe( takeUntil(this.cancelSubscription) )
          .subscribe((projectionResp) => {
            if (projectionResp.responseStatus === 'SUCCESS' && projectionResp.data) {
              this.projectionReportData = projectionResp.data.illustrationPDF;
              this.lockScreens();
              this.generateSummaryReport(projectionResp.data);
              this.projectionRunSuccess = true;
              this.reportGenerationDate = (new Date()).toString();
              this.userService.setProjectionDetails(true, this.reportGenerationDate, this.summaryReport);
              this.defaultPageLoadService.updateCaseDetails(this.questions, true, this.tabDetailsService.activeTab, false).subscribe();
              this.enableNextButton.emit('yes');
              this.isProjectionReportAvailable = true;
            }
            if (projectionResp.error?.errorDataList?.length > 0) {
              this.updateErrorList(projectionResp.error);
              this.displayProgressModal = false;
            } else if (projectionResp.responseStatus === 'FAILURE' && projectionResp.error) {
              this.errorInSavingData = true;
              this.displayProgressModal = false;
            }
          }, (_err) => {
            this.displayProgressModal = false;
          });
        } else {
          this.errorInSavingData = true;
          this.isProjectionReportAvailable = false;
          this.displayProgressModal = false;
        }
      });
  }

  private resetError(): void {
    this.errorInSavingData = false;
    this.errorList = [];
    this.errors = [];
    this.warnings = [];
    this.infos = [];
  }

  public toggleErrorPopup(): void {
    this.openErrorModal = !this.openErrorModal;
  }

  public viewReport(): void {
    const baseData = this.utilsService.getPdfData(this.defaultPageLoadService.getCaseId());
    if (!baseData) {
      this.getFile();
    } else {
      this.utilsService.getPdf(baseData, this.defaultPageLoadService.getCaseId(), false);
    }
  }

  private getFile(): void {
    this.fileUploadService.getFile('mgeapp-wholesaler-illustration doc',
      this.defaultPageLoadService.getCaseId()).subscribe((response) => {
        if (response.responseStatus === 'SUCCESS' && response.data) {
          this.utilsService.openPdf(response.data);
          console.log('Got file from service');
        } else {
          console.log('get file failed');
        }
      });
  }

  private filterOptionalSections(): void {
    let solveSectionRequired = false, lapseGuaranteeSectionRequired = false;
    if (this.productDetails.getMarketName()?.indexOf(Products.MGMA) > -1) {
      const valToCheck = 'solver for future ltc max monthly';
      if (this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.specifiedAmount))?.value?.value?.toLowerCase() === valToCheck
        || this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.variableRateOfReturn))?.value?.value?.toLowerCase() === valToCheck
        || this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.modalPremium))?.value?.value?.toLowerCase() === valToCheck) {
        solveSectionRequired = true;
      }
      const valForSpecifiedFA = 'known premium/optimize overall benefits';
      const valForModalPremium = 'minimum premiums required with guarantee'
      if (this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.specifiedAmount))?.value?.value?.toLowerCase() === valForSpecifiedFA
        || this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.modalPremium))?.value?.value?.toLowerCase() === valForModalPremium) {
        lapseGuaranteeSectionRequired = true;
      }
      this.showOrHideSection(solveSectionRequired, lapseGuaranteeSectionRequired);
    }
  }

  private showOrHideSection(solveSectionRequired: boolean, lapseGuaranteeSectionRequired: boolean): void {
    if (solveSectionRequired && this.solveObjectSectionIndex && this.solveObjectSectionQues) {
      this.sectionList?.splice(this.solveObjectSectionIndex, 0, this.solveObjectSectionQues);
      this.updateDefualtValuesForOptionalSection(this.solveObjectSectionQues);
      this.solveObjectSectionQues = undefined;
    } else if (!solveSectionRequired && !this.solveObjectSectionQues) {
      this.solveObjectSectionQues = this.sectionList[this.solveObjectSectionIndex];
      this.sectionList?.splice(this.solveObjectSectionIndex, 1);
      this.resetSection(this.solveObjectSectionQues);
    }
    if (lapseGuaranteeSectionRequired && this.lapseSectionIndex && this.lapseSectionQues) {
      solveSectionRequired ? this.sectionList?.splice(this.lapseSectionIndex, 0, this.lapseSectionQues) : this.sectionList?.splice(this.lapseSectionIndex - 1, 0, this.lapseSectionQues);
      this.updateDefualtValuesForOptionalSection(this.lapseSectionQues);
      this.lapseSectionQues = undefined;
    } else if (!lapseGuaranteeSectionRequired && !this.lapseSectionQues) {
      this.lapseSectionQues = solveSectionRequired ? this.sectionList[this.lapseSectionIndex] : this.sectionList[this.lapseSectionIndex - 1];
      this.sectionList?.splice(solveSectionRequired ? this.lapseSectionIndex : this.lapseSectionIndex - 1, 1);
      this.resetSection(this.lapseSectionQues);
    }
  }

  private updateDefualtValuesForOptionalSection(sectionQues: any[]): void {
    sectionQues?.forEach(ques => {
      if (ques.xmlTag === PolicyProjectionConstant.solveForYearAge || ques.xmlTag === PolicyProjectionConstant.guaranteeYearAge) {
        this.policyProjectionForm.get(ques.fieldId).setValue({ label: 'Maturity', value: 'Maturity' });
      }
    });
  }

  private resetSection(sectionQues: any[]): void {
    sectionQues?.forEach(ques => {
      this.policyProjectionForm.get(ques.fieldId)?.setValue('');
      this.policyProjectionForm.get(ques.fieldId)?.setErrors(null);
      if (ques.hasReflexive === 'true') {
        this.resetSection(ques?.reflexiveQuestionAL);
      }
    });
  }

  private lockScreens(): void {
    this.tabDetailsService.updateTabList(TAB.POLICY_PROJECTION_WS, true, { errorExists: false, isLocked: false, visited: true });
    this.tabDetailsService.lockAllTabs(true, ExcludeTabsFromLockWholesaler);
    this.isAppLocked = true;
  }

  private generateSummaryReport(responseData: any): void {
    this.summaryReport = {
      specifiedFaceAmount: responseData.specifiedFaceAmount,
      variableRateOfReturn: responseData.variableRateOfReturn,
      modalPremium: responseData.modalPremium,
      totalLongTermCareAmount: responseData.totalLongTermCareAmount,
      monthlyMaximumBenefitAmount: responseData.monthlyMaximumBenefitAmount,
      initialLTCBenefitLimit: responseData.initialLTCBenefitLimit,
      initialLTCMaxMonthly: responseData.initialLTCMaxMonthly,
      modifiedEndowmentContract: responseData.modifiedEndowmentContract,
      mec: responseData.mec
    }
    if (this.marketName === Products.MGFA || this.marketName === Products.MGFA25) {
      this.userService.setBenefitPlanValueProtectionEndorsement(this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.VPEBenefitPlanMGFA))?.value);
      this.userService.setLTCBenefitsRiderDuration(this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.LTCBenefitPlanMGFA))?.value?.value);
    } else if (this.marketName === Products.MGII17) {
      this.userService.setBenefitPlanValueProtectionEndorsement(this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.VPEBenefitPlanMGII))?.value);
      this.userService.setLTCBenefitsRiderDuration(this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.LTCBenefitPlanMGII))?.value?.value);
      this.userService.setLEBenefitsRiderDuration(this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.LTCExtensionBenefitMGII))?.value?.value);
    } else if (this.marketName === Products.MGMA22 || this.marketName === Products.MGMA24 || this.marketName === Products.MGMA) {
      this.userService.setLTCBenefitsRiderDuration(this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.LTCBenefitPlanMGMA))?.value?.value);
    }
    this.userService.setPaymentMode(this.policyProjectionForm.get(this.getFieldIdByXmlTag(PolicyProjectionConstant.paymentMode))?.value?.value);
    this.utilsService.savePdfData(this.defaultPageLoadService.getCaseId(), responseData.illustrationPDF);
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      if (!this.showLoader) {
        this.showLoader = true;
        this.lfgLoaderService.show();
      }
    } else {
      this.lfgLoaderService.hide();
      this.showLoader = false;
    }
  }

  private updateErrorList(errorList: any): void {
    errorList?.errorDataList?.forEach(err => {
      const errDisplay = {
        errLabel: err.errorMsg?.split(':')[1] ? err.errorMsg?.split(':')[0] : "",
        errMsg: err.errorMsg?.split(':')[1] ?? err.errorMsg?.split(':')[0],
        errType: err.errorType,
        errCode: err.errorCode
      };
      this.errorList.push(errDisplay);
    });
    this.warnings = this.errorList.filter((err) => err.errType === PolicyProjectionErrorType.WARN);
    this.infos = this.errorList.filter((err) => err.errType === PolicyProjectionErrorType.INFO);
    this.errors = this.errorList.filter((err) => err.errType === PolicyProjectionErrorType.ERROR);
  }

  gridformStatus(event, seq: number): void {
    if (seq === 1) {
      this.isGrid1FormValid = event?.formValid;
      this.grid1HasNoErrors = event.formHasNoErrors;
      this.gridAnswers1 = event.gridAnswers;
      this.isGrid1FormChange = event.isGridFormChange;
    } else if (seq === 2) {
      this.isGrid2FormValid = event?.formValid;
      this.grid2HasNoErrors = event.formHasNoErrors;
      this.gridAnswers2 = event.gridAnswers;
      this.isGrid2FormChange = event.isGridFormChange;
    }
    this.checkFormStatus();
  }

  unlockAndEditCase(): void {
    this.showPageLoader(true);
    this.defaultPageLoadService.unlockWholesalerCase().subscribe((resp) => {
      if (resp.responseStatus === 'SUCCESS' && resp.data === true) {
        // unlock tabs and remove cache data
        this.tabDetailsService.lockAllTabs(false, []);
        this.deleteTabsAfterLockScreen();
        // enable form
        this.disableForm = false;
        this.enableForm();
        // remove the illustration data
        this.projectionRunSuccess = false;
        this.enableNextButton.emit('no');
        this.userService.setProjectionDetails(false, undefined, undefined);
        this.defaultPageLoadService.updateCaseDetails(this.questions, true, this.tabDetailsService.activeTab, false).subscribe();
      }
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private enableForm(): void {
    setTimeout(() => {
      this.policyProjectionForm?.enable();
    }, 100);
  }

  private deleteTabsAfterLockScreen(): void {
    ExcludeTabsFromLockWholesaler.forEach(tab => {
      this.tabDetailsService.updateTabList(tab, false, { visited: false, isLocked: false, errorExists: false });
    });
    this.wholeSalerCaseUnlocked.emit(ExcludeTabsFromLockWholesaler);
  }

  private triggerDirective(fieldId: string): void {
    const inputElementRef = this.elementRef.nativeElement.querySelector('input[name="' + fieldId + '"]');
    inputElementRef?.focus();
    inputElementRef?.blur();
  }

  closeProgressModal(_event: any): void {
    this.isProjectionReportAvailable = false;
    this.displayProgressModal = false;
    this.cancelSubscription.next();
    this.cancelSubscription.complete();
  }

  openPdfForm(event: any): void {
    if (event === 'yes' && this.projectionReportData != '') {
      this.isProjectionReportAvailable = false;
      this.displayProgressModal = false;
      this.utilsService.getPdf(this.projectionReportData, this.defaultPageLoadService.getCaseId(), false);
    }
  }
}
