import { Component, Input, OnInit } from '@angular/core';
import { Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';

import {  Message } from 'src/config/constants';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { FunctionalityConfig } from 'src/config/delegationFunctionality.config';


@Component({
  selector: 'app-wet-signature',
  templateUrl: './wet-signature.component.html',
  styleUrls: ['./wet-signature.component.scss'],
})
export class WetSignatureComponent implements OnInit {

  @Input() userAccessDetails: FunctionalityConfig;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  agentInstructionData: Question[] = [];
  showLoader = false;
  displayProgressModal: boolean;
  formAvailable: boolean;
  errorOccurred: boolean;
  CONST = Message;

  progressPercentArray = [1, 1, 5, 5, 5, 10, 10, 10, 15, 15, 15, 20, 20, 20,
    25, 31, 31, 31, 31, 36, 36, 42, 42, 54, 62, 62, 62, 67, 78,
    78, 85, 85, 85, 85, 90, 90, 90, 98];

  constructor(
    private appConfig: ApplicationConfig,
    private defaultPageLoadService: DefaultPageload,
    private commonService: CommonService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.agentInstructionData = this.questionsData.pages[1].questions;
      this.defaultPageLoadService.logPageLoad();
    }
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  generateForms() {
    this.errorOccurred = false;
    this.displayProgressModal = true;
    if (this.utilsService.getPdfData(this.defaultPageLoadService.getCaseId()+"-allForms")) {
      this.formAvailable = true;
    } else {
      this.defaultPageLoadService.generateForm(this.defaultPageLoadService.getCaseId(), 'GENERATE_ALL_ETICKETMG_FORMS').subscribe(response => {
        if (response.data && response.responseStatus === 'SUCCESS') {
          this.utilsService.savePdfData(this.defaultPageLoadService.getCaseId()+"-allForms", response.data);
          this.formAvailable = true;
        } else {
          this.errorOccurred = true;
          this.displayProgressModal = false;
        }
      }, (_err) => {
        this.errorOccurred = true;
        this.displayProgressModal = false;
      });
    }
  }

  openPdfForm(event: any): void {
    if (event === 'yes') {
      this.formAvailable = false;
      this.displayProgressModal = false;
      this.utilsService.getPdf(this.utilsService.getPdfData(this.defaultPageLoadService.getCaseId()+"-allForms"), this.defaultPageLoadService.getCaseId(), false);
    }
  }

  closeProgressModal(_event: any): void {
    this.formAvailable = false;
    this.displayProgressModal = false;
  }
}
