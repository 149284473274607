import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { DisableStyle } from '@ng/lfg-calendar';
import { ErrorMessage, Products, billingConstants, EFTKeys, minPremiumAmmountForModeConfig, premiumAndBillingConditinalMsgs, premiumAndBillingKeys } from 'src/config/constants';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { DatePipe } from '@angular/common';
import { Question } from 'src/app/shared/models/casePage.model';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { ClipboardService } from '@ng/lfg-common-utilities';
import { currencyValidator } from 'src/app/shared/validators/validator';
import * as _ from 'lodash';
import { LfgLoaderService } from '@ng/lfg-loader';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.scss'],
})
export class BillingInfoComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  billingQuestionData: Question[] = [];
  billingQuestionForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  placeholders = {};
  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Date',
    disableStyle: DisableStyle.locked,
  };
  calculatedAge: number;
  alldropDownOptions = {};
  setTimeFocus = null;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  pushFieldToNewLine = ['street address', 'bank or credit union name', 'mode', 'premium notices to:'];
  PAGE_GROUP_NAME = PAGE_GROUP_NAME;
  premiumAmount: number;
  premiumMode: any;
  minPremiumAmmountForMode: string;
  minAllowableAmountError = false;
  marketName: string;
  premiumAmmountForMode = minPremiumAmmountForModeConfig;
  premiumAndBillingMsgs = premiumAndBillingConditinalMsgs;
  billingKeys = premiumAndBillingKeys;
  showLoader = true;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private userService: UserDetailsService,
    private datePipe: DatePipe,
    private productService: ProductDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private clipboardService: ClipboardService,
    private loaderService: LfgLoaderService
  ) { }

  ngOnInit(): void {
    this.addLoader(true);
    if (!this.questionsData) {
      return;
    }
    this.buildFormData();
    this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.billingQuestionForm);
    if (this.isTabVisited) {
      this.premiumMode = this.clipboardService.get('premiumMode');
      this.lumpSumQuestionCondition();
    }
    this.setPremiumMode();
    this.minAllowableAmountError = this.clipboardService.get('minAllowableAmountError');
    if (this.minAllowableAmountError) {
      this.checkMinAllowableAmount();
    }
    this.marketName = this.productService.getMarketName();
    this.eftQuestionCondition();
    this.defaultPageLoadService.disableFormIfLocked(this.billingQuestionForm, this.disableForm);
    this.defaultPageLoadService.logPageLoad();
    this.addLoader(false);
  }

  private buildFormData(): any {
    this.billingQuestionData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.billingQuestionData
    );
    const form = this.formgeneratorService.createFormControls(
      this.billingQuestionData
    );
    this.billingQuestionForm = this.fb.group(form);
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.billingQuestionForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.billingQuestionForm,
      data: null,
    };
    for (const data of this.billingQuestionData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const isAnyAlertExists = this.isAlertExists();
    const obj = {
      formValid: this.formValid && !isAnyAlertExists,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'billingQuestionForm',
      isFormChange: this.billingQuestionForm.dirty || !this.isTabVisited
    };
    this.formStatus.emit(obj);
  }

  onRadioChange(data: any): any {
    this.updatedAnswersOnHidden(data);
    if (data.xmlTag === premiumAndBillingKeys.eft) {
      const accountHolderFiledId = data?.reflexiveQuestionAL[0]?.fieldId;
      const accountHolderName = this.billingQuestionForm.get(accountHolderFiledId)?.value?.label;
      if (accountHolderName) {
        this.populateEFTDetails(data.reflexiveQuestionAL[0]);
      }
    }
  }

  handleSelectionChange(data: any, ix?): any {
    this.updatedAnswersOnHidden(data);
    if (data.xmlTag === premiumAndBillingKeys.PremiumMode) {
      this.checkMinAllowableAmount();
      this.eftQuestionCondition();
      this.lumpSumQuestionCondition();
    }
    if (data.xmlTag === premiumAndBillingKeys.accountHolder) {
      this.clearEFT();
      this.populateEFTDetails(data);
    }
  }

  populateEFTDetails(data: any): void {
    const ownerDetails: any = this.userService.getOwnerDetailsWithAddress();
    const accountHolderName = this.billingQuestionForm.get(data?.fieldId)?.value?.label;
    let selectedOwnerDetails: any = null;
    ownerDetails?.forEach(element => {
      const fullName = element.firstName + ' ' + element.lastName;
      if (accountHolderName?.toLowerCase()?.indexOf(fullName?.toLowerCase()) > -1
          || accountHolderName?.toLowerCase()?.indexOf(element.trustName?.toLowerCase()) > -1
          || accountHolderName?.toLowerCase()?.indexOf(element.entityName?.toLowerCase()) > -1) {
        selectedOwnerDetails = element;
      }
    });
    this.setEFTFieldValues(selectedOwnerDetails, accountHolderName);
  }

  clearEFT(): void {
    for (const key in EFTKeys) {
      const fieldId = this.utilsService.getFieldId(this.billingQuestionData, EFTKeys[key]);
      this.billingQuestionForm?.get(fieldId)?.setValue('');
    }
  }

  setEFTFieldValues(selectedOwnerDetails: any, accountHolderName): void {
    const maintFieldValues = true;
    for (const key in EFTKeys) {
      const fieldId = this.utilsService.getFieldId(this.billingQuestionData, EFTKeys[key]);
      if (selectedOwnerDetails) {
        this.setFormValue(key, selectedOwnerDetails, fieldId);
      } else if (accountHolderName?.toLowerCase()?.indexOf('other') > -1 && maintFieldValues) {
        const val = this.billingQuestionForm?.get(fieldId)?.value;
        if (val && val?.length === 0) {
          this.billingQuestionForm?.get(fieldId)?.setValue('');
        }
      } else {
        this.billingQuestionForm?.get(fieldId)?.setValue('');
      }
    }
  }

  setFormValue(key, selectedOwnerDetails, fieldId): void {
    let valueToPopulate;
    if (key === 'state') {
      const dropdownVal = selectedOwnerDetails?.address[key]?.split('{')[0];
      valueToPopulate = (dropdownVal?.length > 0) ? { value: dropdownVal, label: dropdownVal } : '';
    } else if (key === 'phoneType' || key === 'phonePin') {
      valueToPopulate = selectedOwnerDetails[key] === '' ? '' : `${selectedOwnerDetails[key]}{47}${selectedOwnerDetails[key]}`;
    } else {
      valueToPopulate = (key === 'phone' || key === 'email') ? (selectedOwnerDetails[key] || '') : (selectedOwnerDetails?.address[key] || '');
    }
    valueToPopulate = _.isEmpty(valueToPopulate) ? '' : valueToPopulate;
    this.billingQuestionForm.get(fieldId)?.setValue(valueToPopulate);
  }

  addCorrectPlaceHolder(data: any, type): any {
    this.utilsService.updatePolicyRelatedData(data);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAnswersOnHidden(data: any): any {
    const value = this.billingQuestionForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.billingQuestionForm.get(key)?.markAsUntouched();
      }
      this.billingQuestionForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data: any): any {
    const answer = this.billingQuestionForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckBillingInfo(data: any, childData: any, parent: any): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data: any, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  private isAlertExists(): boolean {
    const htmlAttAlert = Array.from(document.getElementsByTagName('lfg-alert-message'));
    let alertExist = false;
    if (htmlAttAlert?.length > 0) {
      for (const htmlElement of htmlAttAlert) {
        if ((htmlElement.getAttribute('type') === 'error') && (htmlElement.innerHTML?.toLowerCase().indexOf(premiumAndBillingConditinalMsgs.minAllowableAmount) > -1 || htmlElement.innerHTML?.toLowerCase().indexOf(premiumAndBillingConditinalMsgs.maxAllowableAmount) > -1 || htmlElement.innerHTML?.toLowerCase().indexOf(premiumAndBillingConditinalMsgs.maxAllowableLumpSumAmount) > -1 || htmlElement.innerHTML?.toLowerCase().indexOf(premiumAndBillingConditinalMsgs.isMoreThanOnePolicy) > -1 || htmlElement.innerHTML?.toLowerCase().indexOf(premiumAndBillingConditinalMsgs.allowableYearsPayable) > -1)) {
          alertExist = true;
          break;
        }
      }
    }
    return alertExist;
  }

  setPremiumMode(): void {
    const premiumModeFieldId = this.utilsService.getFieldByXmlTag(this.billingQuestionData, premiumAndBillingKeys.PremiumMode);
    const premiumModeValue = this.billingQuestionForm.get(premiumModeFieldId)?.value;
    this.premiumMode = premiumModeValue?.value?.split('{')[0];
  }

  checkMinAllowableAmount(): void {
    this.setPremiumMode();
    if (!this.premiumMode) {
      return;
    }
    this.minAllowableAmountError = false;
    this.clipboardService.set('minAllowableAmountError', this.minAllowableAmountError);
    this.minPremiumAmmountForMode = this.premiumAmmountForMode.get(this.premiumMode.toLowerCase());
    const premiumAmountFieldId = this.utilsService.getFieldByXmlTag(this.billingQuestionData, premiumAndBillingKeys.SelectedPremium);
    const premiumAmountControl = this.billingQuestionForm.get(premiumAmountFieldId);
    this.premiumAmount = premiumAmountControl.value;
    if(+this.premiumAmount < +this.minPremiumAmmountForMode){
      this.minAllowableAmountError = true;
      this.clipboardService.set('minAllowableAmountError', this.minAllowableAmountError);
    }
  }

  eftQuestionCondition(): void {
    const eftQFieldId = this.utilsService.getFieldByXmlTag(this.billingQuestionData, premiumAndBillingKeys.eft);
    const eftQCtrl = this.billingQuestionForm.get(eftQFieldId);
    if (this.premiumMode === billingConstants.premiumModeMonthly) {
      eftQCtrl?.setValue('yes');
      eftQCtrl?.disable();
    } else {
      if (eftQCtrl?.value !== 'yes') {
        eftQCtrl?.setValue('no');
      }
      eftQCtrl?.enable();
    }
  }

  lumpSumQuestionCondition(): void {
    const lumpSumQFieldId = this.utilsService.getFieldByXmlTag(this.billingQuestionData, premiumAndBillingKeys.paymentLumpSumAmount);
    const lumpSumQCtrl = this.billingQuestionForm.get(lumpSumQFieldId);
    this.clipboardService.set('premiumMode', this.premiumMode);
    if (this.premiumMode === billingConstants.premiumModeSingle) {
      lumpSumQCtrl?.setValue('');
      this.billingQuestionForm.removeControl(lumpSumQFieldId?.toString());
    } else if (!lumpSumQCtrl) {
      const newLumpSumCtrl = new FormControl('');
      this.billingQuestionForm.addControl(lumpSumQFieldId?.toString(), newLumpSumCtrl);
      newLumpSumCtrl.setValidators(currencyValidator());
      newLumpSumCtrl.setErrors(null);
      newLumpSumCtrl.markAsUntouched();
      newLumpSumCtrl.updateValueAndValidity();
    }
  }

  checkMaxPremiumAmount(premiumAmountFieldId: string): void {
    const premiumAmountControl = this.billingQuestionForm.get(premiumAmountFieldId);
    this.premiumAmount = premiumAmountControl.value;
    this.checkMinAllowableAmount();
  }

  checkMaxPremiumAmountQues(data: any): boolean {
    if (data.questionText.toLowerCase().indexOf(premiumAndBillingConditinalMsgs.maxAllowableAmount) > -1 && this.premiumAmount > 500000) {
      return true;
    }
  }

  checLumpsumAmount(data: any): boolean {
    const lumpSumAmountFieldId = this.utilsService.getFieldByXmlTag(this.billingQuestionData, premiumAndBillingKeys.paymentLumpSumAmount);
    const lumpSumAmount = this.billingQuestionForm.get(lumpSumAmountFieldId)?.value;
    if (data.questionText.toLowerCase().indexOf(premiumAndBillingConditinalMsgs.maxAllowableLumpSumAmount) > -1 && lumpSumAmount > 1000000) {
      return true;
    }
  }

  checkAllowableYearsPayble(data: any): boolean {
    if (data.questionText.indexOf(billingConstants.allowableRangeYearInfo_MGFA) > -1
        && ((this.marketName === Products.MGFA || this.marketName === Products.MGFA25) && this.premiumMode && this.premiumMode !== billingConstants.premiumModeSingle)) {
      return true;
     }
    if (data.questionText.indexOf(billingConstants.allowableRangeYearInfo_MGMA) > -1
        && ((this.marketName === Products.MGMA || this.marketName === Products.MGMA22 || this.marketName === Products.MGMA24) && this.premiumMode && this.premiumMode !== billingConstants.premiumModeSingle)) {
      return true;
     }
  }

  premiumModeOverrideCheck(data: any): boolean {
    if (data.questionText.toLowerCase().indexOf(premiumAndBillingConditinalMsgs.isMoreThanOnePolicy) > -1
        && ((this.marketName === Products.MGFA || this.marketName === Products.MGFA25) && this.premiumMode === billingConstants.premiumModeMonthly)) {
      return true;
    }
  }

  addLoader(isAddLoader: boolean): void {
    if (isAddLoader) {
      this.showLoader = true;
      this.loaderService.show();
    } else {
      setTimeout(()=>{
        this.showLoader = false;
        this.loaderService.hide();
      }, 0);
    }
  }
}
