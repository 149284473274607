import { Injectable } from '@angular/core';
import { OwnerInfoConstant } from 'src/config/constants';

@Injectable({
  providedIn: 'root'
})
export class CivService {

  CIVOwnerIdentityType: any;
  CIVIDNumber: any;
  CIVExpirationDate: any;
  CIVStateCountryOfIssuance: any;

  getGridDLObj(civQuestionsObj: any): Array<any> {
    return civQuestionsObj['OWNER-IND_GRID'][0].reflexiveQuestionAL?.filter((element: any) => {
      if (element.xmlTag === OwnerInfoConstant.DriverLicenseNum) {
        return element;
      }
    });
  }

  getGridDLStateObj(civQuestionsObj: any): Array<any> {
    return civQuestionsObj['OWNER-IND_GRID'][0].reflexiveQuestionAL?.filter((element: any) => {
      if (element.xmlTag === OwnerInfoConstant.DriverLicenseState) {
        return element;
      }
    });
  }

  getGridIDObj(civQuestionsObj: any, CIVOwnerIdentityType: any): Array<any> {
    return civQuestionsObj['OWNER-IND_GRID'][0].reflexiveQuestionAL?.filter((element: any) => {

      const passportCondition = CIVOwnerIdentityType?.label?.indexOf('Passport') > -1
                                ? element.xmlTag === OwnerInfoConstant.OwnerPassPortNumber
                                : element.xmlTag === OwnerInfoConstant.DriverLicenseNum

      if (CIVOwnerIdentityType?.label?.indexOf('Government') > -1 ? element.xmlTag === OwnerInfoConstant.GovtPhotoID : passportCondition) {
        return element;
      }
    });
  }

  getGridExpirationDateObj(civQuestionsObj: any, CIVOwnerIdentityType: any): Array<any> {
    return civQuestionsObj['OWNER-IND_GRID'][0].reflexiveQuestionAL?.filter((element: any) => {

      const passportExpDateCondition = CIVOwnerIdentityType?.label?.indexOf('Passport') > -1
                                ? element.fieldId === `${OwnerInfoConstant.OwnerCountryStateOfIssuanceExpirationDate}_~_${element.name}`
                                : element.xmlTag === OwnerInfoConstant.DriverLicenseExpirationDate

      if (CIVOwnerIdentityType?.label?.indexOf('Government') > -1
          ? element.fieldId === `${OwnerInfoConstant.OwnerCountryStateOfIssuanceExpirationDate}_~_${element.name}` : passportExpDateCondition) {
        return element;
      }
    });
  }

  getGridDriverLicenseStateObj(civQuestionsObj: any, CIVOwnerIdentityType: any): Array<any> {
    return civQuestionsObj['OWNER-IND_GRID'][0].reflexiveQuestionAL?.filter((element: any) => {

      const passportDLStateCondition = CIVOwnerIdentityType?.label?.indexOf('Passport') > -1
                                        ? element.xmlTag === OwnerInfoConstant.CIVPassportCountryOfIssuance
                                        : element.xmlTag === OwnerInfoConstant.DriverLicenseState

      if (CIVOwnerIdentityType?.label?.indexOf('Government') > -1 ? element.xmlTag === OwnerInfoConstant.OwnerCountryStateofIssuance : passportDLStateCondition) {
        return element;
      }
    });
  }

  setCIVOwnerIdentityType(CIVOwnerIdentityType: any, isclearCIVOwnerIdentityType: any): void {
    if (CIVOwnerIdentityType || isclearCIVOwnerIdentityType) {
      this.CIVOwnerIdentityType = CIVOwnerIdentityType;
    }
  }

  getCIVOwnerIdentityType(): any {
    return this.CIVOwnerIdentityType;
  }

  setCIVIDNumber(CIVIDNumber: string, isclearCIVProperties: boolean): void {
    if (CIVIDNumber || isclearCIVProperties) {
      this.CIVIDNumber = CIVIDNumber;
    }
  }

  getCIVIDNumber(): any {
    return this.CIVIDNumber;
  }

  setCIVExpirationDate(CIVExpirationDate: any, isclearCIVProperties: boolean): void {
    if (CIVExpirationDate || isclearCIVProperties) {
      this.CIVExpirationDate = CIVExpirationDate;
    }
  }

  getCIVExpirationDate(): any {
    return this.CIVExpirationDate;
  }

  setCIVStateCountryOfIssuance(CIVStateCountryOfIssuance: any, isclearCIVProperties: boolean): void {
    if (CIVStateCountryOfIssuance || isclearCIVProperties) {
      this.CIVStateCountryOfIssuance = CIVStateCountryOfIssuance;
    }
  }

  getCIVStateCountryOfIssuance(): any {
    return this.CIVStateCountryOfIssuance;
  }

}
