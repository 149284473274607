import { Injectable } from '@angular/core';
import { PageStatus, Recipient } from '../models/casePage.model';
import { TAB, TabInformation } from 'src/config/sideNav.config';
import { TABS_ALWAYS_IN_GOOD_ORDER } from 'src/config/constants';
import { DefaultPageload } from './defaultPageload.service';
import { PageService } from './page.service';
import { TabDetailsService } from './tab-details.service';

@Injectable({
  providedIn: 'root'
})
export class CaseUtilsService {

  constructor(
    private tabDetailsService: TabDetailsService,
    private pageLoadService: DefaultPageload,
    private pageService: PageService,
  ) { }

  roleMap = {
    'Insured-Owner': 'Insured Owner',
    'Additional Insured-Co-Owner': 'Additional Insured Co-Owner',
    Agent: 'Agent',
    Insured: 'Insured',
    Owner: 'Owner',
    'Co-Owner': 'Co-Owner',
    'Co-Owner2': 'Co-Owner 2',
    'Co-Owner3': 'Co-Owner 3',
    'Additional Insured': 'Additional Insured',
    'Principal': 'Principal',
    'Payor': 'Payor'
  };

  displayDocuSignStatusMap = new Map([
    ['sent', 'Email sent'],
    ['created', 'Email not sent'],
    ['completed', 'Completed'],
    ['delivered', 'In progress'],
    ['declined', 'Declined to sign'],
    ['auto_responded', 'Undeliverable'],
    ['voided', 'Cancelled'],
  ]);

  docuSignStatusIconMap = new Map([
    ['Email sent', 'fas fa-paper-plane complete-icon'],
    ['Email not sent', 'fas fa-minus-circle not-started-icon'],
    ['Completed', 'fas fa-check-circle complete-icon'],
    ['In progress', 'fas fa-exclamation-triangle progress-icon'],
    ['Declined to sign', 'fas fa-exclamation-triangle error-icon'],
    ['Undeliverable', 'fas fa-exclamation-triangle error-icon'],
    ['Cancelled', 'fas fa-exclamation-triangle error-icon'],
  ]);

  sortListBySignerRole(recipientList: Recipient[], filterAgent: boolean): Recipient[] {
    const roleList = ['Insured', 'Insured-Owner', 'Additional Insured', 'Additional Insured-Co-Owner', 'Owner', 'Co-Owner', 'Co-Owner2', 'Co-Owner3', 'Agent', 'Principal', 'Payor'];
    const recipientMap = new Map<string, Recipient>();
    recipientList.forEach(recipient => {
      recipientMap.set(recipient.roleName, recipient);
    });
    const sortedList = [];
    roleList.forEach(role => {
      if (recipientMap.has(role) && ((filterAgent && role !== 'Agent') || !filterAgent)) {
        const rec = recipientMap.get(role);
        rec.displayRole = this.roleMap[rec.roleName] ?? rec.roleName;
        sortedList.push(rec);
      }
    });
    return sortedList;
  }

  getSignerByRole(signerList: Recipient[], roleName: string): Recipient {
    const signerInfo = signerList.filter(signer => {
      return signer.roleName === roleName;
    });
    return signerInfo[0];
  }

  hasElectronicSignerAvailable(signerList: Recipient[]): boolean {
    return this.getRemoteSigners(signerList)?.length > 0;
  }

  getRemoteSigners(signerList: Recipient[]): Recipient[] {
    return this.filterSignersBySigningMethod(signerList, 'remote');
  }

  getInPersonSigners(signerList: Recipient[]): Recipient[] {
    return this.filterSignersBySigningMethod(signerList, 'inperson');
  }

  private filterSignersBySigningMethod(signerList: Recipient[], signingMethod: string): Recipient[] {
    return signerList.filter(signer => {
      return signer.eSignatureMethod === signingMethod;
    });
  }

  getProgressPercentageByStatus(status: string): number {
    let progressPercentage = 0;
    switch (status) {
      case 'STARTED':
        progressPercentage = 5;
        break;
      case 'FORMS_GEN_REQUESTED':
        progressPercentage = 30;
        break;
      case 'FORMS_GEN_COMPLETED':
        progressPercentage = 50;
        break;
      case 'ENVELOPE_ID_RQUESTED':
        progressPercentage = 75;
        break;
      case 'ENVELOPE_ID_GENERATED':
        progressPercentage = 100;
        break;
      case 'EXCEPTION_OCCURED':
        progressPercentage = -1;
        break;
      default:
        progressPercentage = -1;
        break;
    }
    return progressPercentage;
  }

  addConsentPageForInPersonSignature(signerList: Recipient[], isTabUpdateRequired: boolean): string[] {
    console.log(signerList);
    const consentTabList = [];
    signerList?.forEach(signer => {
      if (signer.eSignatureMethod === 'inperson') {
        if (signer.displayRole === 'Insured') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_PI, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_PI);
        } else if (signer.displayRole === 'Insured Owner') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_INSURED_OWNER, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_INSURED_OWNER);
        } else if (signer.displayRole === 'Additional Insured Co-Owner') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_INSURED_B, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_INSURED_B);
        } else if (signer.displayRole === 'Owner') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_OWNER, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_OWNER);
        } else if (signer.displayRole === 'Co-Owner') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_COOWNER, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_COOWNER);
        } else if (signer.displayRole === 'Co-Owner 2') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_COOWNER2, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_COOWNER2);
        } else if (signer.displayRole === 'Co-Owner 3') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_COOWNER3, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_COOWNER3);
        } else if (signer.displayRole === 'Additional Insured') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_ADDITIONAL_INSURED, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_ADDITIONAL_INSURED);
        } else if (signer.displayRole === 'Principal') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_PRINCIPAL, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_PRINCIPAL);
        } else if (signer.displayRole === 'Payor') {
          this.updateTabBasedOnCond(TAB.CONSENT_PAGE_PAYOR, isTabUpdateRequired);
          consentTabList.push(TAB.CONSENT_PAGE_PAYOR);
        }
      }
    });
    return consentTabList;
  }



  private updateTabBasedOnCond(tabName: string, isTabUpdateRequired): void {
    if (isTabUpdateRequired) {
      this.updateTab(true, tabName);
    }
  }

  /**
   * this method will check if we need to add or delete tab
   * accordingly it will add tabs to the tab list and call api to add black tab
   * and delete tabs from the tab list and and call api to delete tab in backend
   * @param add - is add or delete
   * @param tab - tab name
   * @returns - returns list of tabs to be delete
   */
  updateTab(add: boolean, tab: string): string[] {
    const tabListToDelete = [];
    const isTabValid = TABS_ALWAYS_IN_GOOD_ORDER.includes(tab);
    if (add && !this.tabDetailsService.getTabInfoByTabName(tab).isEnable) {
      this.tabDetailsService.updateTabList(tab, true, { visited: false, isLocked: false, errorExists: !isTabValid });
      this.pageLoadService.addEmptyPage(this.pageService.getPageGroupByTabByTabName(tab)).subscribe();
    } else if (!add) {
      if (this.tabDetailsService.getTabInfoByTabName(tab).isEnable) {
        this.tabDetailsService.updateTabList(tab, false, { visited: false, isLocked: false, errorExists: false });
        tabListToDelete.push(tab);
        this.pageLoadService.deletePages([this.pageService.getPageGroupByTabByTabName(tab)]).subscribe();
      }
    }
    return tabListToDelete;
  }

  /**
   * this method will delete the tabs after lock screen
   * @param tabList tabs to be delete
   */
  deleteTabsAfterUnlock(tabList: string[]): void {
    const pageListToDelete = [];
    tabList.forEach(tab => {
      this.tabDetailsService.updateTabList(tab, false, { visited: false, isLocked: false, errorExists: false });
      if (this.pageService.getPageGroupByTabByTabName(tab)) {
        pageListToDelete.push(this.pageService.getPageGroupByTabByTabName(tab));
      }
    });
    if (pageListToDelete.length > 0) {
      this.pageLoadService.deletePages(pageListToDelete).subscribe();
    }
  }

  /**
   * this method will return signer data based on tab name
   * @param tabname - tab name
   * @param signerList - signer list
   * @returns - return signer data
   */
  getSignerbyTab(tabname: string, signerList: Recipient[], agentInfo: Recipient): Recipient {
    let signerVal;
    signerList.forEach(signer => {
      if (this.checkIsTabForSigner(tabname, signer)) {
        signerVal = signer;
      }
    });
    if (tabname === TAB.CONSENT_PAGE_AGENT) {
      signerVal = agentInfo;
    }
    return signerVal;
  }

  private checkIsTabForSigner(tabname: string, signer: Recipient): boolean {
    return (tabname === TAB.CONSENT_PAGE_PI && signer.displayRole === 'Insured')
      || (tabname === TAB.CONSENT_PAGE_OWNER && signer.displayRole === 'Owner')
      || (tabname === TAB.CONSENT_PAGE_COOWNER && signer.displayRole === 'Co-Owner')
      || (tabname === TAB.CONSENT_PAGE_COOWNER2 && signer.displayRole === 'Co-Owner 2')
      || (tabname === TAB.CONSENT_PAGE_COOWNER3 && signer.displayRole === 'Co-Owner 3')
      || (tabname === TAB.CONSENT_PAGE_INSURED_OWNER && signer.displayRole === 'Insured Owner')
      || (tabname === TAB.CONSENT_PAGE_PRINCIPAL && signer.displayRole === 'Principal')
      || (tabname === TAB.CONSENT_PAGE_PAYOR && signer.displayRole === 'Payor');
  }

  /**
   * this method will delete the pages which are not required after case info updated
   * comparing with the prev list of tabs
   */
  deletePagesAfterCaseInfoUpdate(prevTabList: TabInformation[]): void {
    const tabDiff = this.tabDetailsService.getTabListDifference(
      prevTabList, this.tabDetailsService.checkForTabApplicableAfterProductUpdate());
    if (tabDiff.length > 0) {
      const pageDiff = [];
      tabDiff.forEach(tab => {
        if (this.pageService.getPageGroupByTab(tab)) {
          pageDiff.push(this.pageService.getPageGroupByTab(tab));
        }
      });
      if (pageDiff.length > 0) {
        this.pageLoadService.deletePages(pageDiff).subscribe();
      }
    }
  }

  /**
   * this method will delete list of tabs passed as parameter
   * @param tabsToDelete - tab list to be deleted
   * @returns return the list of tabs to be delete from the map
   */
  deleteListOfTabs(tabsToDelete: TabInformation[]): string[] {
    const tablist = [];
    const pagesToDelete = [];
    tabsToDelete.forEach(tab => {
      this.tabDetailsService.updateTabList(tab.name, false, { visited: false, isLocked: false, errorExists: false });
      tablist.push(tab.name);
      if (this.pageService.getPageGroupByTabByTabName(tab.name)) {
        pagesToDelete.push(this.pageService.getPageGroupByTabByTabName(tab.name));
      }
    });
    if (pagesToDelete.length > 0) {
      this.pageLoadService.deletePages(pagesToDelete).subscribe();
    }
    return tablist;
  }

  /**
   * this method will filter out inactive tabs
   * @param pageStatusList - unfiltered page list
   * @returns filtered out data
   */
  filterOutInactiveTabs(pageStatusList: PageStatus[]): PageStatus[] {
    return pageStatusList.filter((pagestatus) => {
      return pagestatus.pageStatus?.toLowerCase() !== 'inactive';
    });
  }

  /**
   * this method will check whether all in person consents are signed or not
   * @param consentTabList - consent tab
   * @param activeTab - active tab
   * @returns - check if all consent signed
   */
  allConsentSigned(consentTabList: string[], activeTab: TabInformation): boolean {
    let consentSigned = 0;
    consentTabList.forEach((tab) => {
      const tabStatus = this.tabDetailsService.getTabInfoByTabName(tab)?.status;
      if (tabStatus?.visited && !tabStatus.errorExists) {
        consentSigned++;
      }
    });
    if (consentSigned === consentTabList.length - 1 && activeTab.name === consentTabList[consentTabList.length - 1]) {
      this.updateActiveConsentTabStatus(activeTab);
      consentSigned++;
    }
    return consentSigned === consentTabList.length;
  }

  private updateActiveConsentTabStatus(activeTab: TabInformation): void {
    this.tabDetailsService.updateTabList(activeTab.name, true, { visited: true, errorExists: false, isLocked: false });
  }

  updateDisplayStatus(recipientList: Recipient[]): void {
    recipientList.forEach(recipient => {
      if (this.displayDocuSignStatusMap.has(recipient.docusignStatus?.toLowerCase())) {
        recipient.displayDocuSignStatus = this.displayDocuSignStatusMap.get(recipient.docusignStatus?.toLowerCase());
        recipient.displayIconClass = this.docuSignStatusIconMap.get(recipient.displayDocuSignStatus);
      } else {
        recipient.displayDocuSignStatus = recipient.docusignStatus;
      }
    });
  }
}
