<section class="policy-projection-page page-content-container">
  <div class="questions--container header--container">
    <h1>
      Policy Projection
    </h1>
    <lfg-left-button-tray *ngIf="disableForm">
      <button class="btn btn--whitebg-border" type="submit" id="next" tabindex="0" aria-label="Edit"  (click)="unlockAndEditCase()"><i class="far fa-edit"></i>Edit</button>
    </lfg-left-button-tray>
  </div>
  <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
  <lfg-alert-message *ngIf="errorInSavingData" type="error">{{Message.GENERIC_ERROR}}</lfg-alert-message>
  <div *ngIf="showLoader" >
    <lfg-loader></lfg-loader>
  </div>
  <section class="page-section">
    <form class="form-group mar-border-15 pad-bot-30" [formGroup]="policyProjectionForm" id="policyProjectionForm" method="post" *ngIf="policyProjectionData && policyProjectionData.length > 0">
      <div *ngFor="let section of sectionList; let secInd = index;" [ngClass]="secInd % 2 != 0 ? 'section-container-background' : 'section-container-no-background'">
        <ng-container class="form-control-parent" *ngFor="let data of section; let ind = index;">

          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'link' && data.subText != 'Button' && data.subText != 'Frame' && data.subText != 'Message' && data.subText !== 'header' && data.subText !== 'grid-header' && data.subText != 'table-header'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="questions-header section-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && (data.subText == 'header')">
            <p>{{ data.questionText }}</p>
          </div>

          <div class="form--control link-section" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'link'">
            <a class="btn-link" type="button" (click)="paymentOptionInfo(paymentOptionInfoModal)">{{ data.questionText }}</a>
          </div> 

          <div class="question-paragraph" *ngIf="data.subText == 'Message'">
            <p>{{ data.questionText }}</p>
          </div>
  
          <div class="questions-header frame" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Frame'">
            <h2>{{ data.questionText }}</h2>
          </div>
  
          <!--Line text-->
          <div class="section-divider-line" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <div class="grid-container" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && (data.notes == 'ADD_GRID_RATE_OF_RETURN' || data.questionText?.toLowerCase() === 'vary rate of return') && variableRateOfReturnGridAvailable">
            <div class="questions-header section-header" *ngIf="data.subText == 'grid-header'">
              <p>{{ data.questionText }}</p>
            </div>
            <app-policy-projection-grid *ngIf="data.notes == 'ADD_GRID_RATE_OF_RETURN'" [gridInstance]="gridInstance1" [maxGridAvailable]="maxGridValue1" (gridStatus)="gridformStatus($event, 1)" [gridAnswers]="gridAnswers1" [addAccordionText]="'Add More'" [isRevisit]="isTabVisited" [disableForm]="disableForm"></app-policy-projection-grid>
          </div>

          <div class="grid-container" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && (data.notes == 'ADD_GRID_SCHEDULED_PREMIUM' ||  data.questionText?.toLowerCase() === 'vary schedule premium') && modalPremiumGridAvailable">
            <div class="questions-header section-header" *ngIf="data.subText == 'grid-header'">
              <p>{{ data.questionText }}</p>
            </div>
            <app-policy-projection-grid *ngIf="data.notes == 'ADD_GRID_SCHEDULED_PREMIUM'" [gridInstance]="gridInstance2" [maxGridAvailable]="maxGridValue2" (gridStatus)="gridformStatus($event, 2)" [gridAnswers]="gridAnswers2" [addAccordionText]="'Add More'" [isRevisit]="isTabVisited" [disableForm]="disableForm"></app-policy-projection-grid>
          </div>
  
          <!-- Informational text -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>
  
          <!-- Form element for currency  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1 && data.subText != 'inline-ques'" [ngClass] = "{'form--control__split-width': data.subText === 'col-split' }">           
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, policyProjectionForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for small currency  -->
          <div class="form--control inline-field-small currency-inline-form-control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1 && data.subText == 'inline-ques'">
            <div class="inline-ques-container">
              <span class="inline-ques-text">{{ data.required === 'true' ? data.questionText : data.questionText + ' (optional)' }}</span>
              <lfg-input-wrap class="form-dropDown inline-currency">
                <input lfgInput aria-live="off" placeholder="Amount" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, policyProjectionForm.get(data.fieldId)) }}"></lfg-error>
              </lfg-input-wrap>
            </div>
          </div>  
  
          <!-- Form element for text  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1) && data.subText != 'inline-ques'" [ngClass] = "{'form--control__split-width': data.subText === 'col-split' }">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{data.required === 'true' ? data.questionText : data.questionText + ' (optional)'}}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, policyProjectionForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>
  
          <!-- Form element for small text  -->
          <div class="form--control inline-field-small text-inline-form-control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1) && data.subText == 'inline-ques'">
            <div class="inline-ques-container">
              <span class="inline-ques-text">{{ data.required === 'true' ? data.questionText : data.questionText + ' (optional)' }}</span>
              <ng-container>
                <div class="form-dropDown inline-currency">
                  <input lfgInput placeholder="{{ data.questionText.indexOf('month') > -1 ? 'Months' : data.questionText }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                  <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, policyProjectionForm.get(data.fieldId)) }}"></lfg-error>
                </div>
              </ng-container>
            </div>
          </div>  

          <!-- Form element for email  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addPolicyProjectionCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">
  
              <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>
  
          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.subText !== 'table-value'" [ngClass]="{'form--control__split-width': data.subText === 'col-split' }">
            <span class="lfg-radio-section" [ngClass]="{'radio--err': (policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid)}">
              <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)"/>
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
          </div>
  
          <!-- Form Element for Inline Dropdown  -->
          <div class="form--control " aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.subText !== 'inline-ques' && data.subText != 'table-value' && (data.xmlTag !== PolicyConst.yearOrAge || (data.xmlTag == PolicyConst.yearOrAge && (policyProjectionForm.get(data.fieldId).value)))" [ngClass]="{'inline-ques': data.questionText.length >= 27 && data.subText !== 'col-split', 'form--control__split-width': data.subText === 'col-split' }">
            <div class="inline-ques-container">
              <span *ngIf="data.questionText.length >= 27 && data.subText !== 'col-split'" class="inline-ques-text">{{ addPolicyProjectionCorrectPlaceHolder(data, 'placeHolder') }}</span>
              <ng-container *ngIf="data.questionText.length >= 27 && data.subText !== 'col-split'">
                <div class="form-dropDown inline-dropdown">
                  <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" (selectionChanged)="handlePolicyProjectionSelectionChange(data)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                </div>
              </ng-container>
              <ng-container *ngIf="data.questionText.length < 27 || data.subText === 'col-split'">
                <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}" (selectionChanged)="handlePolicyProjectionSelectionChange(data)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid)}">
                </lfg-dropdown>
                <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </ng-container>
            </div>
          </div>

          <!-- Form Element for Inline small Dropdown  -->
          <div class="form--control inline-field-small drop-inline-form-control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.subText == 'inline-ques'">
            <div class="inline-ques-container">
              <span class="inline-ques-text">{{ data.required === 'true' ? data.questionText : data.questionText + ' (optional)' }}</span>
              <ng-container>
                <div class="form-dropDown inline-dropdown">
                  <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" (selectionChanged)="handlePolicyProjectionSelectionChange(data)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="policyProjectionForm.get(data.fieldId).touched && policyProjectionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                </div>
              </ng-container>
            </div>
          </div>
  
          <!-- Form element for check box  -->
          <div class="form--control form--control__full-width" [ngClass] = "{'form--control__split-width': data.subText === 'col-split' }" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.subText != 'table-value'">
            <div class="lfg-checkbox">
                <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data);">
                <label class="wrap wrap-check" for="{{ data.fieldId }}">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</label>
            </div>
          </div>

          <!-- Funds table -->
          <div class="funds-table-container" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-header'">
            <div class="funds-table-header funds-table">
              <div class="table-column col-1">{{data.questionText | uppercase}} </div>
              <div class="col-2 table-column">BENEFIT NAME</div>
              <div class="col-3 table-column">BENEFIT PLAN</div>
            </div>
          </div>
          <div *ngIf="data.controlTypeDesc === appConfig.fieldTypes.CHECKBOX && data.subText == 'table-value'" class="funds-table funds-table-row">
            <div class="table-column col-1">
              <div class="lfg-checkbox">
                <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data);">
                <label class="wrap wrap-check hidden-label" for="{{ data.fieldId }}">{{data.questionText}}</label>
              </div>
            </div>
            <div class="table-column col-2">{{data.questionText}}</div>
            <div class="table-column col-3">
              <div class="form--control table-dropdown-form-control" aria-live="assertive" *ngIf="sectionList[secInd][ind+1].controlTypeDesc == appConfig.fieldTypes.SELECT">
                <div class="inline-ques-container">
                  <ng-container>
                    <lfg-dropdown formControlName="{{ sectionList[secInd][ind+1].fieldId }}" id="{{displayIdCreation(sectionList[secInd][ind+1])}}_{{ind}}" [options]="alldropDownOptions[sectionList[secInd][ind+1].fieldId]" placeholder="{{ sectionList[secInd][ind+1].questionText + (sectionList[secInd][ind+1].required == 'true' ? '' : ' (optional)') }}" (selectionChanged)="handlePolicyProjectionSelectionChange(sectionList[secInd][ind+1])" [required]="sectionList[secInd][ind+1].required == 'true'" [ngClass]="{'dropdown--err': (policyProjectionForm.get(sectionList[secInd][ind+1].fieldId).touched && policyProjectionForm.get(sectionList[secInd][ind+1].fieldId).invalid)}">
                    </lfg-dropdown>
                    <lfg-error *ngIf="policyProjectionForm.get(sectionList[secInd][ind+1].fieldId).touched && policyProjectionForm.get(sectionList[secInd][ind+1].fieldId).invalid" message="{{ getErrorMsg(sectionList[secInd][ind+1]) }}"></lfg-error>
                  </ng-container>
                </div>
              </div>

              <div class="lfg-customRadio-field radio-btn-custom" *ngIf="sectionList[secInd][ind+1].controlTypeDesc == appConfig.fieldTypes.RADIO">
                <span class="lfg-radio-section" [ngClass]="{'radio--err': (policyProjectionForm.get(sectionList[secInd][ind+1].fieldId).touched && policyProjectionForm.get(sectionList[secInd][ind+1].fieldId).invalid)}">
                  <p class="question-radio_text">{{ sectionList[secInd][ind+1].questionText + (sectionList[secInd][ind+1].required == 'true' ? '' : ' (optional)') }}</p>
                  <div class="custom-radio-button">
                    <input [attr.aria-label]="sectionList[secInd][ind+1].questionOption[0].description" tabindex="0" id="yes_{{ sectionList[secInd][ind+1].fieldId }}" type="radio" formControlName="{{ sectionList[secInd][ind+1].fieldId }}" name="{{ sectionList[secInd][ind+1].fieldId }}" value="{{ sectionList[secInd][ind+1].questionOption[0].value }}" required="{{sectionList[secInd][ind+1].required}}" (change)="onRadioChange(sectionList[secInd][ind+1])"/>
                    <label for="yes_{{ sectionList[secInd][ind+1].fieldId }}">{{ sectionList[secInd][ind+1].questionOption[0].description }}</label>
                    <input [attr.aria-label]="sectionList[secInd][ind+1].questionOption[1].description" tabindex="0" id="no_{{ sectionList[secInd][ind+1].fieldId }}" type="radio" formControlName="{{ sectionList[secInd][ind+1].fieldId }}" name="{{ sectionList[secInd][ind+1].fieldId }}" value="{{ sectionList[secInd][ind+1].questionOption[1].value }}" required="{{sectionList[secInd][ind+1].required}}" (change)="onRadioChange(sectionList[secInd][ind+1])">
                    <label for="no_{{ sectionList[secInd][ind+1].fieldId }}">{{ sectionList[secInd][ind+1].questionOption[1].description }}</label>
                  </div>
                </span>
                <lfg-error *ngIf="policyProjectionForm.get(sectionList[secInd][ind+1].fieldId).touched && policyProjectionForm.get(sectionList[secInd][ind+1].fieldId).invalid " message="{{ getErrorMsg(sectionList[secInd][ind+1]) }}"></lfg-error>
              </div>
            </div>
          </div>
  
          <!-- Nested question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && (data.xmlTag !== PolicyConst.yearOrAge || (data.xmlTag == PolicyConst.yearOrAge && (policyProjectionForm.get(data.fieldId).value))) && policyProjectionForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions-no-background': checkIfQuestionDisplayed(data), 'display-inline': isMGMA }">
            <ng-container *ngFor="let policyProjectionChild1 of data.reflexiveQuestionAL; index as policyProjectionChild1ix">
              <ng-container *ngIf="nestedQuestionPolicyProjectionCheck(data, policyProjectionChild1, parentAnswer)">
  
                <div class="questions-header" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.HDR && policyProjectionChild1.questionText != 'Line' && policyProjectionChild1.subText != 'Information' && policyProjectionChild1.subText != 'Button' && policyProjectionChild1.subText != 'Frame' && policyProjectionChild1.subText != 'Message' && policyProjectionChild1.subText != 'Alert'">
                  <p><strong>{{ policyProjectionChild1.questionText }}</strong></p>
                </div>
  
                <!-- Informational text -->
                <div class="question-info" aria-live="assertive" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.HDR && policyProjectionChild1.subText == 'Information'">
                  <lfg-alert-message type="info">{{ policyProjectionChild1.questionText }}</lfg-alert-message>
                </div>
  
                <!-- Alert text -->
                <div class="question-info" aria-live="assertive" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.HDR && policyProjectionChild1.subText == 'Alert'">
                  <lfg-alert-message type="error">{{ policyProjectionChild1.questionText }}</lfg-alert-message>
                </div>
  
                <!-- Form element for text  -->
                <div class="form--control" aria-live="assertive" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (!policyProjectionChild1.notes || (policyProjectionChild1.notes.indexOf('ITES_CURRENCY_USD') == -1 && policyProjectionChild1.notes.indexOf('ITES_FORMAT_AS_PERCENTAGE') == -1)) && policyProjectionChild1.subText != 'inline-ques'" [ngClass] = "{'form--control__split-width': policyProjectionChild1.subText === 'col-split' }">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(policyProjectionChild1) }}" placeholder="{{ policyProjectionChild1.required === 'true' ? policyProjectionChild1.questionText : policyProjectionChild1.questionText + ' (optional)' }}" type='text' formControlName='{{ policyProjectionChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(policyProjectionChild1.answerTextType)" [maxlength]="policyProjectionChild1.maxLength" [required]="policyProjectionChild1.required == 'true'">
                    <lfg-error *ngIf="policyProjectionForm.get(policyProjectionChild1.fieldId).touched && policyProjectionForm.get(policyProjectionChild1.fieldId).invalid" message="{{getErrorMsg(policyProjectionChild1, policyProjectionForm.get(policyProjectionChild1.fieldId))}}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for percentage  -->
                <div class="form--control" aria-live="assertive" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && policyProjectionChild1.notes === 'ITES_FORMAT_AS_PERCENTAGE'" [ngClass] = "{'form--control__split-width': policyProjectionChild1.subText === 'col-split' }">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(policyProjectionChild1) }}" placeholder="{{ policyProjectionChild1.required === 'true' ? policyProjectionChild1.questionText : policyProjectionChild1.questionText + ' (optional)' }}" type='text' formControlName='{{ policyProjectionChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols appPercentageFormat [attr.customAttr]="getAnswerType(policyProjectionChild1.answerTextType)" [maxlength]="policyProjectionChild1.maxLength" [required]="policyProjectionChild1.required == 'true'">
                    <lfg-error *ngIf="policyProjectionForm.get(policyProjectionChild1.fieldId).touched && policyProjectionForm.get(policyProjectionChild1.fieldId).invalid" message="{{getErrorMsg(policyProjectionChild1, policyProjectionForm.get(policyProjectionChild1.fieldId))}}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for small text  -->
                <div class="form--control inline-field-small text-inline-form-control" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (!policyProjectionChild1.notes || policyProjectionChild1.notes.indexOf('ITES_CURRENCY_USD') == -1) && policyProjectionChild1.subText == 'inline-ques'">
                  <div class="inline-ques-container">
                    <span class="inline-ques-text">{{ policyProjectionChild1.required === 'true' ? policyProjectionChild1.questionText : policyProjectionChild1.questionText + ' (optional)' }}</span>
                    <ng-container>
                      <lfg-input-wrap class="form-dropDown inline-currency">
                        <input lfgInput placeholder="{{ policyProjectionChild1.questionText.indexOf('month') > -1 ? 'Months' : policyProjectionChild1.questionText }}" name="{{ displayTextLabel(policyProjectionChild1) }}" aria-live="off" type='text' formControlName='{{ policyProjectionChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(policyProjectionChild1.answerTextType)" [maxlength]="policyProjectionChild1.maxLength" [required]="policyProjectionChild1.required == 'true'">
                        <lfg-error *ngIf="policyProjectionForm.get(policyProjectionChild1.fieldId).touched && policyProjectionForm.get(policyProjectionChild1.fieldId).invalid" message="{{ getErrorMsg(policyProjectionChild1, policyProjectionForm.get(policyProjectionChild1.fieldId)) }}"></lfg-error>
                      </lfg-input-wrap>
                    </ng-container>
                  </div>
                </div>  
  
                <!-- Form element for currency  -->
                <div class="form--control" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (policyProjectionChild1.notes && policyProjectionChild1.notes.indexOf('ITES_CURRENCY_USD') > -1) && policyProjectionChild1.subText != 'inline-ques'" [ngClass] = "{'form--control__split-width': policyProjectionChild1.subText === 'col-split' }">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" placeholder="{{ policyProjectionChild1.required === 'true' ? policyProjectionChild1.questionText : policyProjectionChild1.questionText + ' (optional)' }}" type='text' formControlName='{{ policyProjectionChild1.fieldId }}' autocomplete="off" name="{{displayTextLabel(policyProjectionChild1)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="policyProjectionChild1.maxLength" [required]="policyProjectionChild1.required == 'true'">
                    <lfg-error *ngIf="policyProjectionForm.get(policyProjectionChild1.fieldId).touched && policyProjectionForm.get(policyProjectionChild1.fieldId).invalid" message="{{ getErrorMsg(policyProjectionChild1, policyProjectionForm.get(policyProjectionChild1.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>
  
                <!-- Form element for small currency  -->
                <div class="form--control inline-field-small currency-inline-form-control" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && policyProjectionChild1.notes && policyProjectionChild1.notes.indexOf('ITES_CURRENCY_USD') > -1 && policyProjectionChild1.subText == 'inline-ques'" [ngClass]="{'display-45-width': isMGMA}">
                  <div class="inline-ques-container">
                    <span class="inline-ques-text" aria-labelledby="{{ policyProjectionChild1.fieldId }}">{{ policyProjectionChild1.required === 'true' ? policyProjectionChild1.questionText : policyProjectionChild1.questionText + ' (optional)' }}</span>
                    <lfg-input-wrap class="form-dropDown inline-currency">
                      <input lfgInput aria-live="off" placeholder="Amount" type='text' formControlName='{{ policyProjectionChild1.fieldId }}' autocomplete="off" name="{{displayTextLabel(policyProjectionChild1)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="policyProjectionChild1.maxLength" [required]="policyProjectionChild1.required == 'true'">
                      <lfg-error *ngIf="policyProjectionForm.get(policyProjectionChild1.fieldId).touched && policyProjectionForm.get(policyProjectionChild1.fieldId).invalid" message="{{ getErrorMsg(policyProjectionChild1, policyProjectionForm.get(policyProjectionChild1.fieldId)) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>
                </div> 

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" aria-live="assertive" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass] = "{'form--control__split-width': policyProjectionChild1.subText === 'col-split' }">
                  <lfg-dropdown formControlName="{{ policyProjectionChild1.fieldId }}" id="{{displayIdCreation(policyProjectionChild1)}}_{{ind}}" [options]="alldropDownOptions[policyProjectionChild1.fieldId]" placeholder="{{ policyProjectionChild1.required === 'true' ? policyProjectionChild1.questionText : policyProjectionChild1.questionText + ' (optional)' }}" (selectionChanged)="handlePolicyProjectionSelectionChange(policyProjectionChild1)" [required]="policyProjectionChild1.required == 'true'" [ngClass]="{'dropdown--err':(policyProjectionForm.get(policyProjectionChild1.fieldId).touched && policyProjectionForm.get(policyProjectionChild1.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="policyProjectionForm.get(policyProjectionChild1.fieldId).touched && policyProjectionForm.get(policyProjectionChild1.fieldId).invalid" message="Please select a valid option."></lfg-error>
                </div>
  
                <!-- Form element for text area -->
                <div class="form--control form--control__full-width" aria-live="assertive" *ngIf="policyProjectionChild1.controlTypeDesc == appConfig.fieldTypes.TEXTAREA">
                  <lfg-input-wrap>
                    <input lfgInput placeholder="{{ policyProjectionChild1.questionText}}" name="{{ displayTextLabel(policyProjectionChild1) }}" aria-live="off" type='text' formControlName='{{ policyProjectionChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="policyProjectionChild1.maxLength" [required]="policyProjectionChild1.required">
                    <span class="text-area-count">{{policyProjectionForm.get(policyProjectionChild1.fieldId).value.length}}/{{policyProjectionChild1.maxLength}}</span>
                    <lfg-error *ngIf="policyProjectionForm.get(policyProjectionChild1.fieldId).touched && policyProjectionForm.get(policyProjectionChild1.fieldId).invalid" message="Please enter a valid input."></lfg-error>
                  </lfg-input-wrap>
                </div>
  
                <!-- Level 2 reflexive ques  -->
                <div *ngIf="policyProjectionChild1.hasReflexive == 'true' && policyProjectionForm.get(policyProjectionChild1.fieldId).value as parentAnswer1" [ngClass]="{'display-inline': isMGMA}">
                  <ng-container *ngFor="let policyProjectionChild2 of policyProjectionChild1.reflexiveQuestionAL; index as policyProjectionChild2ix">
                    <ng-container *ngIf="nestedQuestionPolicyProjectionCheck(policyProjectionChild1, policyProjectionChild2, parentAnswer1)">
  
                      <!-- Form element for text  -->
                      <div class="form--control" aria-live="assertive" *ngIf="policyProjectionChild2.controlTypeDesc == appConfig.fieldTypes.TEXT" [ngClass] = "{'form--control__split-width': policyProjectionChild2.subText === 'col-split' }">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" name="{{ displayTextLabel(policyProjectionChild2) }}" placeholder="{{ policyProjectionChild2.required === 'true' ? policyProjectionChild2.questionText : policyProjectionChild2.questionText + ' (optional)' }}" type='text' formControlName='{{ policyProjectionChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(policyProjectionChild2.answerTextType)" [maxlength]="policyProjectionChild2.maxLength" [required]="policyProjectionChild2.required == 'true'">
                          <lfg-error *ngIf="policyProjectionForm.get(policyProjectionChild2.fieldId).touched && policyProjectionForm.get(policyProjectionChild2.fieldId).invalid" message="{{getErrorMsg(policyProjectionChild2, policyProjectionForm.get(policyProjectionChild2.fieldId))}}"></lfg-error>
                        </lfg-input-wrap>
                      </div>
  
                    </ng-container>
                  </ng-container>
                </div>
  
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="projection-btn-container" *ngIf="!projectionRunSuccess">
        <div class="question-info" aria-live="assertive">
          <lfg-alert-message type="info">{{Message.RUN_PROJECTION_MSG}}</lfg-alert-message>
        </div>
        <div class="btn-container">
          <button type="button" lfgDefaultButton (click)="generateProjection()" [disabled]="!formValid || !isAllTabsValid">RUN PROJECTION</button>
        </div>
      </div>
      <div class="projection-summary-container" *ngIf="projectionRunSuccess">
        <div class="questions-header section-header">
          <p>Quick Preview Results</p>
        </div>
        <div class="summary-content">
          <div *ngIf="summaryReport.specifiedFaceAmount" class="summary-val"><span class="summary-label">Specified Face Amount:</span><span class="summary-result">{{summaryReport.specifiedFaceAmount | currency}}</span></div>
          <div *ngIf="summaryReport.variableRateOfReturn" class="summary-val"><span class="summary-label">Variable Account Rate of Return:</span><span class="summary-result">{{summaryReport.variableRateOfReturn | currency}}</span></div>
          <div *ngIf="summaryReport.modalPremium" class="summary-val"><span class="summary-label">Modal Premium:</span><span class="summary-result">{{summaryReport.modalPremium | currency}}</span></div>
          <div *ngIf="summaryReport.totalLongTermCareAmount" class="summary-val"><span class="summary-label">Total Long Term Care Amount:</span><span class="summary-result">{{summaryReport.totalLongTermCareAmount | currency}}</span></div>
          <div *ngIf="summaryReport.monthlyMaximumBenefitAmount" class="summary-val"><span class="summary-label">Monthly Maximum Benefit Amount:</span><span class="summary-result">{{summaryReport.monthlyMaximumBenefitAmount | currency}}</span></div>
          <div *ngIf="summaryReport.initialLTCBenefitLimit" class="summary-val"><span class="summary-label">Initial LTC Benefit Limit:</span><span class="summary-result">{{summaryReport.initialLTCBenefitLimit | currency}}</span></div>
          <div *ngIf="summaryReport.initialLTCMaxMonthly" class="summary-val"><span class="summary-label">Initial LTC Max Monthly:</span><span class="summary-result">{{summaryReport.initialLTCMaxMonthly | currency}}</span></div>
          <div *ngIf="summaryReport.modifiedEndowmentContract" class="summary-val"><span class="summary-label">Modified Endowment Contract (MEC):</span><span class="summary-result">{{summaryReport.modifiedEndowmentContract | currency}}</span></div>
          <div *ngIf="summaryReport.mec" class="summary-val"><span class="summary-label">Modified Endowment Contract (MEC):</span><span class="summary-result">{{summaryReport.mec}}</span></div>
        </div>
        <div class="btn-container view-report-edit-btn">
          <button type="button" lfgDefaultButton (click)="viewReport()">VIEW REPORT</button>
          <button class="btn btn--whitebg-border" type="submit" id="next" tabindex="0" aria-label="Edit"  (click)="unlockAndEditCase()"><i class="far fa-edit"></i>Edit</button>
        </div>
        <div class="question-info summary-alert" aria-live="assertive">
          <lfg-alert-message type="info">{{Message.ILLUSTRATION_TIME_MSG}}{{reportGenerationDate | date:'medium'}}</lfg-alert-message>
        </div>

      </div>
    </form>
  </section>

  <ng-container *ngIf="displayProgressModal">
    <app-progress-overlay [modalStateInput]="displayProgressModal" [messageToshow]="Message.REPORT_LOADER_MESSAGE" [showActionButton]="true" [submitButtonLabel]="'VIEW REPORT'" [formAvailable]="isProjectionReportAvailable" [showDoNotCloseBrowserMessage]="false" (closeProgressModal)="closeProgressModal($event)" (openPdfForm)="openPdfForm($event)">
    </app-progress-overlay>
  </ng-container>

  <!-- Error section  -->
  <section class="projection-error-container" *ngIf="errorList.length > 0" [ngClass]="{'error-container-err' : errors.length > 0,  'error-container-warn' : errors.length === 0 && warnings.length > 0, 'error-container-info' : errors.length === 0 && warnings.length === 0 && infos.length > 0}">
    <div class="error-container-header" [ngClass]="{'error-container-closed' : !openErrorModal }">
      <span>
        <i aria-hidden="true" class="fas fa-exclamation-triangle icn-err" *ngIf="errors.length > 0"></i>
        <span class="error-msg-header" *ngIf="errors.length > 0">Error Messages ({{errorList.length}})</span>
        <i aria-hidden="true" class="fas fa-exclamation-triangle icn-wrn" *ngIf="errors.length === 0 && warnings.length > 0"></i>
        <span class="error-msg-header" *ngIf="errors.length === 0 && warnings.length > 0">Warning Messages ({{errorList.length}})</span>
        <i aria-hidden="true" class="fas fa-info-circle icn-inf" *ngIf="errors.length === 0 && warnings.length === 0 && infos.length > 0"></i>
        <span class="error-msg-header" *ngIf="errors.length === 0 && warnings.length === 0 && infos.length > 0">Information ({{errorList.length}})</span>
      </span>
      <span class="up-down-arrow" (click)="toggleErrorPopup()">
        <i class="fa-chevron-up fas" aria-hidden="true" *ngIf="!openErrorModal"></i>
        <i class="fa-chevron-down fas" aria-hidden="true" *ngIf="openErrorModal"></i>
      </span>
    </div>
    <div class="error-msg-section" *ngIf="openErrorModal">
      <ng-container *ngFor="let error of errorList">
        <div class="error-row">
          <i class="fa-exclamation-triangle fas error-icon mar-right10" *ngIf="error.errType === ErrorType.ERROR" aria-hidden="true"></i>
          <i class="fa-exclamation-triangle fas warn-icon mar-right10" *ngIf="error.errType === ErrorType.WARN" aria-hidden="true"></i>
          <i class="fa-info-circle fas info-icon mar-right10" *ngIf="error.errType === ErrorType.INFO" aria-hidden="true"></i>
          <span *ngIf="error.errLabel"><strong>{{error.errLabel + ": "}}</strong></span><strong>{{error.errMsg}}</strong></div>
      </ng-container>
    </div>
  </section>
<!-- Modal popup -->
  <section>
      <lfg-modal-popup *ngIf="paymentOptionInfoModal" class="payment-modal" [modalConfig]="paymentOptionInfoModal" (closeModalPopup)="closeModal(paymentOptionInfoModal)">
        <div modal-header>
          <h1 class="lfg-color">Flex pay options</h1>
        </div>
        <div modal-body class="modal-popup__body--scroll">
          <ng-container *ngIf="tableData?.data?.length > 0">
               <div> 
                  <p class="product-name-header">{{product}}</p>
                  <p class="payment-modal-sec">Reflected below are the maximum flex pay years available by issue age. </p>
                </div>
              <lfg-primary-table [tableData]="tableData" id="eticket-dbResult-table">
                <ng-template #tableCelContentTemplate let-column="column" let-row="row">
                  <div tabindex="0" *ngIf="column.columnFlag === 'issueAge'">{{ row.issueAge }} </div>
                  <div tabindex="0" *ngIf="column.columnFlag === 'payDuration'">{{ row.payDuration }}</div>
                </ng-template>
              </lfg-primary-table>
            </ng-container>
        </div>
  
        <div modal-footer>
          <lfg-left-button-tray>
            <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Next"  (click)="closeModal(paymentOptionInfoModal)">Close</button>
          </lfg-left-button-tray>
        </div>
      </lfg-modal-popup>

  </section>
</section>