import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LfgLoaderService } from '@ng/lfg-loader';
import { ClipboardService } from '@ng/lfg-common-utilities';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { FileDetails, Question } from 'src/app/shared/models/casePage.model';
import { ApplicationConfig } from 'src/config/app.config';
import checkboxData from './checkbox_data.json';
import { ErrorMessage, KeysForIllustrationConditionals, Message, Products,
        IllustrationKeys, IllustrationMessages, 
        COMMUNITY_STATES} from 'src/config/constants';

@Component({
  selector: 'app-illustration-compliance',
  templateUrl: './illustration-compliance.component.html',
  styleUrls: ['./illustration-compliance.component.scss']
})
export class IllustrationComplianceComponent implements OnInit {
  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  questionsData;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  alldropDownOptions = {};
  illustrationComplianceData: Question[] = [];
  illustrationComplianceForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  setTimeFocus = null;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  fileInfoMessage = Message.FILE_INFO;
  modalConfig: any;
  showLoader = false;
  showWetFormInfo: boolean = false;
  showValidIllustrationMsg: boolean = false

  openAccordion = {}; // for opening and closing accordion
  files = {}; // contains files uploaded by user (valid/invalid)
  fileName = {}; // contains files names uploaded by user (valid/invalid)
  isQuestionValid = {}; // contains true/false value depending upon the file status
  fileFromRepo = {}; // contains filename loaded from db
  hasFileError = {}; // contains files having error
  openPdfs = {} // contains files that are open as pdfs

  activeAccordionId: string;
  activeData: Question;
  unselectCheckbox = false;
  removeFileFormControl: FormControl;

  wetFormKeys = IllustrationKeys.WetFormKeys;
  attachIllustrationKey = IllustrationKeys.AttachIllustrationKey;
  customerIdentityVerificationKey = IllustrationKeys.CustomerIdentityVerificationKey;
  illustrationMatchesKey = IllustrationKeys.IllustrationMatchesKey;

  attachIllustrationText = IllustrationMessages.AttachIllustrationText;
  validIllustrationMsgText = IllustrationMessages.ValidIllustrationMsgText;
  VOICE_SIGNATURE_DISCLOSURE = IllustrationMessages.VoiceSignatureDisclosureTemplate;
  WET_FORM_INFO = IllustrationMessages.WetFormInfo;

  conditionalKeys = KeysForIllustrationConditionals;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private pageLoadService: DefaultPageload,
    private fileUploadService: FileUploadService,
    private loaderService: LfgLoaderService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private clipboardService: ClipboardService,
    private tabDetailsService: TabDetailsService,
    private productService: ProductDetailsService,
    private userService: UserDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.isTabVisited = this.tabDetailsService.getActiveTab()?.status?.visited
      let checkboxIdx = this.questionsData.pages[0].questions.findIndex(e=>e.questionText.toLowerCase().indexOf(this.attachIllustrationText.toLowerCase())>-1)
      if(checkboxIdx===-1){
        checkboxIdx = this.questionsData.pages[0].questions.findIndex(e=>e.questionText.toLowerCase().indexOf(this.validIllustrationMsgText.toLowerCase())>-1)
        this.questionsData.pages[0].questions.splice(checkboxIdx+1,0,...checkboxData)
      }
      this.filterCheckboxData()
      this.buildFormData();
      this.pageLoadService.updateFormErrors(this.illustrationComplianceForm);
      this.updateFileInfo();
      this.defaultPageLoadService.disableFormIfLocked(this.illustrationComplianceForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
    this.modalConfig = {
      header: true,
      state: false,
      footer: true
    };
    this.openPdfs = this.clipboardService.get('openPdfs') || [];
    const illustrationMatchesFieldId = this.utilsService.getFieldId(this.illustrationComplianceData, this.illustrationMatchesKey);
    const illustrationMatchesAns = this.illustrationComplianceForm.get(illustrationMatchesFieldId)?.value;
    if (illustrationMatchesAns !== '' && illustrationMatchesAns?.length > 0) {
      this.showValidIllustrationMsg = true;
    }
    this.checkIfWetFormSelected();
  }

  viewFile(): void {
    if (this.openPdfs[this.activeAccordionId] && !this.openPdfs[this.activeAccordionId].closed){
      this.utilsService.openPdf('','',this.openPdfs[this.activeAccordionId]);
    } else if (this.files[this.activeAccordionId]) {
      const fileType = this.getFileTypeByXmlTag(this.activeAccordionId)
      this.utilsService.toBase64(this.files[this.activeAccordionId]).then(pdfData => {
        this.openPdfs[this.activeAccordionId] = this.utilsService.openPdf(pdfData,fileType);
      });
    } else if (this.fileFromRepo[this.activeAccordionId]) {
      this.getFile(this.activeAccordionId);
    }
    this.clipboardService.set('openPdfs',this.openPdfs)
  }

  private updateFiles(file: File, id: string): void {
    this.files[id] = file;
    this.fileName[id] = file.name;
    delete this.hasFileError[id];
  }

  onFileSelected(event, formControl: FormControl, id: string, data: Question): void {
    const file: File = event.target.files[0];
    if (file) {
      if (file?.size > 30 * 1024 * 1024) {
        formControl.setErrors({ fileError: true });
      }
      if (formControl.valid) {
        this.addFileDetails(file, id);
      } else {
        this.removeFileDetails(data, id);
      }
    } else {
      this.removeFileDetails(data, id);
    }
  }

  closeModalPopup(): any {
    this.modalConfig.state = false;
  }

  private buildFormData(): any {
    this.illustrationComplianceData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.illustrationComplianceData
    );
    this.illustrationComplianceForm = this.fb.group(form);
    this.valueChanges();
  }

  valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.illustrationComplianceForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus(true);
      }, 200);
    });
  }

  checkFormStatus(fileChanged?: boolean): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.illustrationComplianceForm,
      data: null,
    };
    for (const data of this.illustrationComplianceData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid && this.checkIfAllCheckBoxValid(),
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'illustrationComplianceForm',
      isFormChange: this.illustrationComplianceForm.dirty || (!!fileChanged) || !this.isTabVisited
    };
    this.formStatus.emit(obj);
  }

  removeAttachment(): void {
    if (this.unselectCheckbox) {
      this.unselectTheCheckbox();
    }
    this.modalConfig.state = false;
    delete this.files[this.activeData.xmlTag];
    delete this.fileName[this.activeData.xmlTag];
    delete this.isQuestionValid[this.activeData.xmlTag];
    delete this.fileFromRepo[this.activeData.xmlTag];
    delete this.hasFileError[this.activeData.xmlTag];
    this.removeFile(this.activeData.xmlTag);
    if (this.removeFileFormControl) {
      this.removeFileFormControl.reset();
      this.removeFileFormControl = undefined;
    }
  }

  onCheckboxChange(data: Question): any {
    this.updatedAnswersOnHidden(data);
    if (this.illustrationComplianceForm.get(data.fieldId).value) {
      this.updateAccordionStatus(data.xmlTag, true);
      this.isQuestionValid[data.xmlTag] = false;
    } else {
      this.updateAccordionStatus(data.xmlTag, false);
      this.isQuestionValid[data.xmlTag] = true;
    }
  }

  onCheckBoxClick(event: any, data: Question): void {
    if (this.files[data.xmlTag] || this.fileFromRepo[data.xmlTag]) {
      this.toggleAccordionStatus(data.xmlTag);
      event.preventDefault();
      event.stopPropagation();
    } else {
      delete this.hasFileError[data.xmlTag];
    }
  }

  updatedAnswersOnHidden(data): any {
    const value = this.illustrationComplianceForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(data, value);
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.illustrationComplianceForm.get(key)?.markAsUntouched();
      }
      this.illustrationComplianceForm.patchValue(updatedval);
    }
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.illustrationComplianceForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  nestedQuestionCheckIllustrationCompliance(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData.optionChoice === 'Yes{47}Yes') {
        return parent === true;
      } else if (childData.optionChoice === 'No{47}No') {
        return parent === false;
      }
    } else {
      return parent === childData.optionChoice;
    }
  }

  openModal(event: any, data: Question, isRemove?: boolean): void {
    event.preventDefault();
    event.stopPropagation();
    if (isRemove) {
      this.getFormControlForFile(data);
    }
    if (this.files[data.xmlTag] || this.fileFromRepo[data.xmlTag]) {
      this.modalConfig.state = true;
      this.activeData = data;
    }
  }

  private getFormControlForFile(data: Question): void {
    data.reflexiveQuestionAL.forEach(question => {
      if (question.subText === 'File') {
        this.removeFileFormControl = this.illustrationComplianceForm.get(question.fieldId) as FormControl;
      }
    });
  }

  private updateAccordionStatus(quesId: string, isOpen: boolean): void {
    if (isOpen) {
      this.closeAllAccordion();
      this.activeAccordionId = quesId;
    }
    this.openAccordion[quesId] = isOpen;
  }

  toggleAccordionStatus(quesId: string): void {
    this.updateAccordionStatus(quesId, !this.openAccordion[quesId]);
  }

  private closeAllAccordion(): void {
    Object.keys(this.openAccordion).forEach((key) => {
      this.openAccordion[key] = false;
    });
  }

  private unselectTheCheckbox(): void {
    this.illustrationComplianceForm.get(this.activeData.fieldId)?.patchValue(false);
    this.unselectCheckbox = false;
    this.updatedAnswersOnHidden(this.activeData.fieldId);
    this.updateAccordionStatus(this.activeData.xmlTag, false);
    this.isQuestionValid[this.activeData.xmlTag] = true;
  }

  private uploadFile(file: File, xmlTag: string): void {
    this.loaderService.show();
    this.showLoader = true;
    const updatedFileName = this.stripSpecialCharacters(file.name)
    this.utilsService.toBase64(file).then(pdfData => {
      const fileDetails: FileDetails = {
        caseId: this.pageLoadService.getCaseId(),
        fileName: updatedFileName,
        fileSize: file.size.toString(),
        fileType: this.getFileTypeByXmlTag(xmlTag),
        fileData: pdfData
      };
      this.fileUploadService.uploadFile(fileDetails).subscribe((response) => {
        if (response.responseStatus === 'SUCCESS' && response.data) {
          this.isQuestionValid[xmlTag] = true;
          this.updateAnswerFromResponse(response.data, xmlTag);
          this.updateFiles(file, xmlTag);
          this.checkFormStatus(true);
        } else {
          this.hasFileError[xmlTag] = Message.FILE_UPLOAD_ERR;
        }
        this.loaderService.hide();
        this.showLoader = false;
      },
        (_err) => {
          this.hasFileError[xmlTag] = Message.FILE_UPLOAD_ERR;
          this.loaderService.hide();
          this.showLoader = false;
        });
    });
  }

  private removeFile(xmlTag: string): void {
    this.fileUploadService.deleteFile(this.getFileTypeByXmlTag(xmlTag),
      this.pageLoadService.getCaseId()).subscribe((response) => {
        if (response.responseStatus === 'SUCCESS' && response.data) {
          this.updateFileName('', xmlTag);
          this.checkFormStatus(true);
        } else {
          console.log('file delete failed');
        }
      });
  }

  private getFile(xmlTag: string): void {
    this.fileUploadService.getFile(this.getFileTypeByXmlTag(xmlTag),
      this.pageLoadService.getCaseId()).subscribe((response) => {
        if (response.responseStatus === 'SUCCESS' && response.data) {
          this.openPdfs[this.activeAccordionId] = this.utilsService.openPdf(response.data,this.getFileTypeByXmlTag(xmlTag));
          console.log('Got file from service');
        } else {
          console.log('get file failed');
        }
      });
  }

  private getFileTypeByXmlTag(xmlTag: string): string {
    const fileType = this.illustrationComplianceData.filter((ques) => {
      return ques.xmlTag === xmlTag;
    })[0]?.subText;

    return 'mgeapp-'+fileType
  }

  private updateAnswerFromResponse(responseData: any, xmlTag: string): void {
    if (responseData?.fileName) {
      this.updateFileName(responseData.fileName, xmlTag);
    } else {
      this.updateFileName('', xmlTag);
    }
  }

  private updateFileName(fileName: string, xmlTag: string): void {
    this.illustrationComplianceData.forEach(ques => {
      if (ques.xmlTag === xmlTag) {
        ques.reflexiveQuestionAL.forEach(refQues => {
          if (refQues.subText === 'File') {
            refQues.question_answer = fileName;
          }
        });
      }
    });
  }

  private updateFileInfo(): void {
    this.illustrationComplianceData.forEach(ques => {
      if (ques.question_answer) {
        ques.reflexiveQuestionAL.forEach(refQues => {
          if (refQues.subText === 'File') {
            if (refQues.question_answer && refQues.question_answer !== '') {
              this.fileName[ques.xmlTag] = refQues.question_answer;
              this.isQuestionValid[ques.xmlTag] = true;
              this.illustrationComplianceForm.get(refQues.fieldId).markAsUntouched();
              this.illustrationComplianceForm.get(refQues.fieldId).setErrors(null);
              this.fileFromRepo[ques.xmlTag] = ques.questionText;
              delete this.hasFileError[ques.xmlTag];
            } else {
              this.hasFileError[ques.xmlTag] = Message.FILE_FORMAT_ERR;
            }
          }
        });
      }
    });
  }

  private removeFileDetails(data: Question, id: string): void {
    this.isQuestionValid[id] = false;
    this.activeData = data;
    this.removeAttachment();
    this.hasFileError[id] = Message.FILE_FORMAT_ERR;
  }

  private addFileDetails(file: File, id: string): void {
    this.uploadFile(file, id);
  }

  private checkIfAllCheckBoxValid(): boolean {
    let allFieldsValid = true;

    const attachIllustrationFieldId = this.utilsService.getFieldId(this.illustrationComplianceData, this.attachIllustrationKey);
    if(!this.illustrationComplianceForm.get(attachIllustrationFieldId).value){
      return false;
    }

    this.illustrationComplianceData.forEach(ques => {
      if (
        ques.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX &&
        ques.question_answer &&
        !this.fileName[ques.xmlTag] &&
        ques.xmlTag !== this.illustrationMatchesKey
      ) {
        allFieldsValid = false;
      }
    });
    return allFieldsValid;
  }

  private filterCheckboxData(): void {
    const product = this.productService.getMarketName();
    const wetFormsData = this.questionsData.wetFormsUploaded;
    const wetFormsDataCA = this.userService.getInsuredState()?.toLowerCase() === 'ca';
    const isCommunityState = COMMUNITY_STATES.includes(this.userService.getInsuredState()?.toUpperCase());
    const wetFormsDataIsLongTermCare = this.userService.getIsLongTermCareInforce();
    const wetFormsDataIs1035 = this.userService.getIs1035();
    const isMarried = this.userService.getIsMarried();
    this.questionsData.pages[0].questions.forEach(c => {
      if (c.xmlTag === this.customerIdentityVerificationKey) {
        c.display = product === Products.MGMA || product === Products.MGMA22 || product === Products.MGMA24;
      }
      if (c.xmlTag === this.wetFormKeys.wetProtection) {
        c.display = (wetFormsData && wetFormsData.hasOwnProperty('wetProtection')) || wetFormsDataCA;
      } else if (c.xmlTag === this.wetFormKeys.wetExchange) {
        c.display = (wetFormsData && wetFormsData.hasOwnProperty('wetExchange')) || (isMarried && wetFormsDataIs1035 && isCommunityState);
      } else if (c.xmlTag === this.wetFormKeys.wetNotice) {
        c.display = (wetFormsData && wetFormsData.hasOwnProperty('wetNotice')) || (wetFormsDataIsLongTermCare && wetFormsDataCA)
      }
    })
  }
  private onRadioChange(data): void {
    if (data?.xmlTag === this.illustrationMatchesKey) {
      this.showValidIllustrationMsg = true
    }
  }


  private checkIfWetFormSelected(): void {
    let showInfo = false;
    Object.values(this.wetFormKeys).forEach(key => {
      const question = this.illustrationComplianceData.find(ques => ques.xmlTag === key);
      if (question?.display) {
        showInfo = true;
      }
    })
    this.showWetFormInfo = showInfo;
    
  }

  reset(): void {
    const ques = this.illustrationComplianceForm.get(this.utilsService.getFieldId(this.illustrationComplianceData, this.illustrationMatchesKey))
    this.showValidIllustrationMsg = false
    ques?.setValue('')
  }

  stripSpecialCharacters(filename): string {
    const regex = /[^a-zA-Z0-9 ()\-,._®]/g;
    return filename.replace(regex, '')
  }
}
