import { Directive, OnInit, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPercentageFormat]'
})
export class PercentageFormatDirective implements OnInit {

  private elem: any;

  constructor(private elementRef: ElementRef) {
    this.elem = this.elementRef.nativeElement;
  }

  ngOnInit(): any {
    if (this.elem.value) {
      this.elem.value = this.transform(this.elem.value);
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value): any {
    this.elem.value = this.parse(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value): any {
    if (value) {
      this.elem.value = this.transform(value);
    }
  }

  parse(value: string): any {
    return value.replace('%', '');
  }

  transform(value: any): any {
    value = this.parse(value);
    return value + '%';
  }
}

