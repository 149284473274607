import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { COMMUNITY_STATES, Message, WET_SIGN_COLLECTION_INFO_SCREEN } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';
@Component({
  selector: 'app-wet-signature-collection-info',
  templateUrl: './wet-signature-collection-info.component.html',
  styleUrls: ['./wet-signature-collection-info.component.scss']
})
export class WetSignatureCollectionInfoComponent implements OnInit {

  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  messages: any;
  tabStatusOnInit: TabStatus;
  displayWetformList = [];
  errorOccurred: boolean;
  displayProgressModal: boolean;
  formAvailable: boolean;
  CONST = Message;

  progressPercentArray = [1, 1, 5, 5, 5, 10, 10, 10, 15, 15, 15, 20, 20, 20,
    25, 31, 31, 31, 31, 36, 36, 42, 42, 54, 62, 62, 62, 67, 78,
    78, 85, 85, 85, 85, 90, 90, 90, 98];

  constructor(
    private utilsService: UtilsService,
    private userService: UserDetailsService,
    private pageLoadService: DefaultPageload
  ) { }

  ngOnInit(): void {
    this.tabStatusOnInit = this.pageLoadService.getActiveTabStatus();
    this.utilsService.deletePdfData(this.pageLoadService.getCaseId());
    this.updateFileList();
    this.updateFormStatus();
    this.pageLoadService.logPageLoad();
  }

  wetSignFormClick() {
    this.errorOccurred = false;
    this.displayProgressModal = true;
    if (this.utilsService.getPdfData(this.pageLoadService.getCaseId())) {
      this.formAvailable = true;
    } else {
      this.pageLoadService.generateForm(this.pageLoadService.getCaseId(), 'GENERATE_WETSIGN_FORMS_ONLY').subscribe(response => {
        if (response.data && response.responseStatus === 'SUCCESS') {
          this.utilsService.savePdfData(this.pageLoadService.getCaseId(), response.data);
          this.formAvailable = true;
        } else {
          this.errorOccurred = true;
          this.displayProgressModal = false;
        }
      }, (_err) => {
        this.errorOccurred = true;
        this.displayProgressModal = false;
      });
    }
  }

  public updateFileList(): void {
    this.messages = WET_SIGN_COLLECTION_INFO_SCREEN;
    this.createWetFormList();
  }

  createWetFormList(): void {
    this.displayWetformList = [];
    const isInsuredStateCA = this.userService.getInsuredState()?.toUpperCase() === 'CA'
    const isCommunityState = COMMUNITY_STATES.includes(this.userService.getInsuredState()?.toUpperCase());
    if (this.userService.getIs1035() && this.userService.getIsMarried() && isCommunityState) {
      this.displayWetformList.push(WET_SIGN_COLLECTION_INFO_SCREEN.FILE_1);
    }
    if (this.userService.getIsLongTermCareInforce() && isInsuredStateCA) {
      this.displayWetformList.push(WET_SIGN_COLLECTION_INFO_SCREEN.FILE_2);
    }
    if (isInsuredStateCA) {
      this.displayWetformList.push(WET_SIGN_COLLECTION_INFO_SCREEN.FILE_3);
    }
  }

  public updateFormStatus(): void {
    const wetSignCollectionInfoObj = {
      formValid: true,
      formHasNoErrors: true,
      gridForm: false,
      isFormChange: !this.tabStatusOnInit.visited,
    };
    this.formStatus.emit(wetSignCollectionInfoObj);
  }

  openPdfForm(event: any): void {
    if (event === 'yes') {
      this.formAvailable = false;
      this.displayProgressModal = false;
      this.utilsService.getPdf(this.utilsService.getPdfData(this.pageLoadService.getCaseId()), this.pageLoadService.getCaseId(), false);
    }
  }

  closeProgressModal(_event: any): void {
    this.formAvailable = false;
    this.displayProgressModal = false;
  }

}
