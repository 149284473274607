<section class="ltc-replacement-page page-content-container">

  <div class="questions--container">
    <h1>
      LTC Replacement
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isGridFormValid)" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="ltcReplacementForm" id="ltcReplacementForm" method="post" *ngIf="ltcReplacementData && ltcReplacementData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of ltcReplacementData; let ind = index;" >

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Long-Term Care or Health Insurance' && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Alert'"
            [innerHTML]="data.questionText">
          </div>

          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Long-Term Care or Health Insurance' && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Alert'">
            <h2 class="grid-ques">{{ data.questionText }}</h2>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required == 'true'">

              <lfg-error *ngIf="ltcReplacementForm.get(data.fieldId).touched && ltcReplacementForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag !== EI_CONSTANT.otherInsPolicyInForceQuesID"
            [ngClass]="{'radio--err': (ltcReplacementForm.get(data.fieldId).touched && ltcReplacementForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio"
                  formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}"
                  (change)="onRadioChange(data, data?.reflexiveQuestionAL,  data?.questionOption[0].value)"/>
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio"
                  formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}"
                  (change)="onRadioChange(data, data?.reflexiveQuestionAL,  data?.questionOption[1].value)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="ltcReplacementForm.get(data.fieldId).touched && ltcReplacementForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

              <lfg-error *ngIf="ltcReplacementForm.get(data.fieldId).touched && ltcReplacementForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (ltcReplacementForm.get(data.fieldId).touched && ltcReplacementForm.get(data.fieldId).invalid) }">
            </lfg-dropdown>
            <lfg-error *ngIf="ltcReplacementForm.get(data.fieldId).touched && ltcReplacementForm.get(data.fieldId).invalid" message="Please select a valid option"></lfg-error>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Alert'">
            <lfg-alert-message type="warning">{{ data.questionText }}</lfg-alert-message>
          </div>

          <div class="grid-hr_top nested-questions" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.GRID">
            <app-grid-structure [gridInstances]="getGridInstances(data.gridId)" [gridAnswers]="getGridAns(data.gridId)" [focusOnGrid]="focusGrid" [maxGridAvailable]="gridInd" [type]="'LTC'" [name]="'LTC'"
            [accordionHeader]="'Policy'" [isRevisit]="isTabVisited" [addAccordionText]="'Add Policy'" [disableForm]="disableForm" (gridStatus)="gridformStatus($event)"></app-grid-structure>
          </div>

            <!-- Nestion question Level 1 -->
            <div *ngIf="data.hasReflexive == 'true' && ltcReplacementForm.get(data.fieldId).value as parentAnswer">
              <ng-container *ngFor="let ltcChildL1 of data.reflexiveQuestionAL; index as ltcChildL1ix">
                <ng-container *ngIf="nestedQuestionCheckltc(data, ltcChildL1, parentAnswer)">

                  <div class="grid-hr_top nested-questions" *ngIf="ltcChildL1.controlTypeDesc === appConfig.fieldTypes.GRID">
                    <app-grid-structure [gridInstances]="getGridInstances(ltcChildL1.gridId)" [gridAnswers]="getGridAns(ltcChildL1.gridId)" [focusOnGrid]="focusGrid" [maxGridAvailable]="gridInd"  [type]="'LTC'" [name]="'LTC'"
                    [accordionHeader]="'Policy'" [isRevisit]="isTabVisited" [addAccordionText]="'Add Policy'" [disableForm]="disableForm" (gridStatus)="gridformStatus($event)"></app-grid-structure>
                  </div>

                  <!-- Questions headers -->
                  <div class="questions-header" *ngIf="ltcChildL1.controlTypeDesc == appConfig.fieldTypes.HDR">
                  <h2>{{ ltcChildL1.questionText }}</h2>
                  </div>

                  <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                  <div class="questionText" *ngIf="addCorrectPlaceHolder(ltcChildL1, 'display') && !hideCIVQuestions">
                    {{ addCorrectPlaceHolder(ltcChildL1, 'question')  }}
                  </div>

                  <!-- Form element for text  -->
                  <div class="form--control" *ngIf="ltcChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && ltcChildL1.notes !== 'ITES_FORMAT_AS_ZIPCODE'"
                    [ngClass]="{'resetError': !ltcReplacementForm.get(ltcChildL1.fieldId).errors}">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(ltcChildL1) }}" placeholder="{{ addCorrectPlaceHolder(ltcChildL1, 'placeHolder') }}"
                        type='text' formControlName='{{ ltcChildL1.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="'text'"
                        [maxlength]="ltcChildL1.maxLength >= 999 ? 30 : ltcChildL1.maxLength" [required]="ltcChildL1.required == 'true'">
                      <lfg-error *ngIf="ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid" message="{{ getErrorMsg(ltcChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for zip  -->
                  <div class="form--control" *ngIf="ltcChildL1.controlTypeDesc === appConfig.fieldTypes.TEXT && ltcChildL1.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" mask='00000' id="{{ltcChildL1.fieldId}}"
                        placeholder="{{ utilsService.getCorrectContent(ltcChildL1, 'placeHolder') }}" appNoSpecialSymbols
                        type='text' [formControl]='ltcReplacementForm.get(ltcChildL1.fieldId)'
                        autocomplete="off" appValidateOnBlur value="" maxlength='5'
                        [ngClass]="{'inp-txt--err': (ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid) }"
                        [required]="ltcChildL1.required === 'true'">

                      <lfg-error
                        *ngIf="ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid"
                        message="{{ getErrorMsg(ltcChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for alphanumeric  -->
                  <div class="form--control" *ngIf="ltcChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(ltcChildL1) }}" placeholder="{{ addCorrectPlaceHolder(ltcChildL1, 'placeHolder') }}"
                        appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ ltcChildL1.fieldId }}' autocomplete="off" name=""
                        appValidateOnBlur value="" [maxlength]="ltcChildL1.maxLength" [required]="ltcChildL1.required == 'true'">

                      <lfg-error *ngIf="ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid" message="{{ getErrorMsg(ltcChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form Element for date  -->
                  <div class="form--control lfg-cal" *ngIf="ltcChildL1.controlTypeDesc == appConfig.fieldTypes.DOB">
                    <lfg-calendar (focusin)="validateOnFocus(ltcChildL1, 'in')" id="{{ltcChildL1.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(ltcChildL1, 'out')"
                      lfgCheckValidDate [formControl]="ltcReplacementForm.get(ltcChildL1.fieldId)" [config]="dateConfig"
                      [class.calendar--err]="(ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid)" [required]="ltcChildL1.required == 'true'">
                      <lfg-error *ngIf="ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid " message="{{ getErrorMsg(ltcChildL1) }}"></lfg-error>
                    </lfg-calendar>
                  </div>

                  <!-- Form element for Drop Down -->
                  <div class="form--control form-dropDown" *ngIf="(ltcChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT && !hideCIVQuestions)" [ngClass]="{'drop-down-medium': ltcChildL1.questionText.length > 32}">
                    <lfg-dropdown formControlName="{{ ltcChildL1.fieldId }}" id="{{displayIdCreation(ltcChildL1)}}_{{ltcChildL1ix}}"
                      [options]="alldropDownOptions[ltcChildL1.fieldId]" placeholder="{{ addCorrectPlaceHolder(ltcChildL1, 'placeHolder') }}"
                      (selectionChanged)="handleSelectionChange(ltcChildL1, ltcChildL1ix)" [required]="ltcChildL1.required == 'true'"
                      [ngClass]="{'dropdown--err': (ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid)}">
                    </lfg-dropdown>
                    <lfg-error *ngIf="ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid" message="{{ getErrorMsg(ltcChildL1) }}"></lfg-error>
                  </div>

                  <!-- Form element for radio question -->
                  <div class="lfg-customRadio-field" *ngIf="ltcChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO"
                  [ngClass]="{'radio--err': (ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid)}">
                    <span class="lfg-radio-section">
                      <p class="question-radio_text">{{ ltcChildL1.questionText + (ltcChildL1.required == 'true' ? '' : ' (optional)') }}</p>
                      <div class="custom-radio-button">
                        <input [attr.aria-label]="ltcChildL1.questionOption[0].description" tabindex="0" id="yes_{{ ltcChildL1.fieldId }}" type="radio"
                          formControlName="{{ ltcChildL1.fieldId }}" name="{{ ltcChildL1.fieldId }}" value="{{ ltcChildL1.questionOption[0].value }}"
                          required="{{ltcChildL1.required}}" (change)="onRadioChange(ltcChildL1)"/>
                        <label for="yes_{{ ltcChildL1.fieldId }}">{{ ltcChildL1.questionOption[0].description }}</label>
                        <input [attr.aria-label]="ltcChildL1.questionOption[1].description" tabindex="0" id="no_{{ ltcChildL1.fieldId }}" type="radio"
                          formControlName="{{ ltcChildL1.fieldId }}" name="{{ ltcChildL1.fieldId }}" value="{{ ltcChildL1.questionOption[1].value }}"
                          required="{{ltcChildL1.required}}" (change)="onRadioChange(ltcChildL1)">
                        <label for="no_{{ ltcChildL1.fieldId }}">{{ ltcChildL1.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error *ngIf="ltcReplacementForm.get(ltcChildL1.fieldId).touched && ltcReplacementForm.get(ltcChildL1.fieldId).invalid " message="{{ getErrorMsg(ltcChildL1) }}"></lfg-error>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <div class="section-divider"></div>
        </ng-container>

      </form>
    </section>
  </div>
</section>
