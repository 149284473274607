import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ClipboardService } from '@ng/lfg-common-utilities';

import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { GridInstance, PageJsonObject, Question, } from 'src/app/shared/models/casePage.model';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, ExistingInsuranceConstant, ExistingInsuranceMessage, KeysForIllustrationConditionals } from 'src/config/constants';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { TabStatus } from 'src/config/sideNav.config';



@Component({
  selector: 'app-ltc-replacement',
  templateUrl: './ltc-replacement.component.html',
  styleUrls: ['./ltc-replacement.component.scss'],
})
export class LtcReplacementComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData: PageJsonObject;
  @Input() isWholesaler: boolean;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() isLongTermCareInforce: EventEmitter<string> = new EventEmitter();

  // variable to save ques answer for checking other conditions
  inforceInsQues: string;
  financeInsQues: string;
  replacementInsQues1: string;
  replacementInsQues2: string;
  ltcReplacementData: Question[] = [];
  ltcReplacementForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  gridHasNoErrors = false;
  formHasNoErrors = true;
  isGridFormValid = true;
  isGridFormChange = false;
  placeholders = {};
  alldropDownOptions = {};
  setTimeFocus = null;
  gridQuestionsObj;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  existingInsError = ExistingInsuranceMessage.INSURANCE_ERROR_MESSAGE;
  EI_CONSTANT = ExistingInsuranceConstant;
  hasExistingInsurance = false;
  hasError = false;
  otherInsPolicyQuestion = {};
  maxNoOfInsurance: number;
  gridInd: number;
  updatedGridAnswers: any = {};
  gridValid: any = {};
  gridFromValid = true;
  keysForIllustration = KeysForIllustrationConditionals;
  tabStatusOnInit: TabStatus;


  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private userService: UserDetailsService,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit(): void {
    console.log(this.questionsData);
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers[0];
      this.maxNoOfInsurance = +(this.gridInstance[0]?.maxGridIndex);
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.ltcReplacementForm);
      this.defaultPageLoadService.disableFormIfLocked(this.ltcReplacementForm, this.disableForm);
      this.emitIsLongTermCareInfo();
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.ltcReplacementData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.ltcReplacementData
    );
    const form = this.formgeneratorService.createFormControls(
      this.ltcReplacementData
    );
    this.ltcReplacementForm = this.fb.group(form);
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.updateBusinessRule();
      this.checkFormStatus();
    }, 200);
    this.ltcReplacementForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.updateBusinessRule();
        this.checkFormStatus();
      }, 200);
    });
  }

  private updateBusinessRule(): void {
    this.updateAnswerValues();
    this.checkForExistingInsurance();
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.ltcReplacementForm,
      data: null,
    };
    for (const data of this.ltcReplacementData) {
      componentData.data = data;
      componentData = this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  onRadioChange(data, childData, value): any {
    this.updatedAnswersOnHidden(data);
    if (data.xmlTag === this.keysForIllustration.replaceMedicalHealthLTCIndicator2) {
      const medicalHealthLTCIndicatorValue = this.ltcReplacementForm.get(data.fieldId)?.value;
      const ans = medicalHealthLTCIndicatorValue?.toLowerCase()?.indexOf('yes') > -1
      this.clipboardService.set('replaceMedicalHealthLTCIndicator2', ans);
      this.emitIsLongTermCareInfo();
    }
    const index = childData?.findIndex((obj: any) => obj.controlTypeDesc === 'Grid');
    if (childData && childData[index]?.controlTypeDesc === 'Grid' && value.indexOf('no') > -1) {
      for (const key in this.gridValid) {
        if (key === data.reflexiveQuestionAL[index].gridId) {
          this.gridValid[key] = true;
          delete this.updatedGridAnswers[key];
          this.gridFromValidUpdate();
          return;
        }
      }
    }
  }

  gridFromValidUpdate() {
    for (const key in this.gridValid) {
      const element = this.gridValid[key];
      if (element === false) {
        this.isGridFormValid = false;
        this.gridFromValid = false;
        break;
      }
      this.isGridFormValid = true;
      this.gridFromValid = true;
    }
    this.checkFormStatus();
  }

  handleSelectionChange(data, ix?): any {
    const formotivId = this.displayIdCreation(data);
    this.commonService.triggerChangeCustom(
      'dropdown',
      ix,
      formotivId,
      'insuredInfoForm',
      data
    );
    this.updatedAnswersOnHidden(data);
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.ltcReplacementForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.ltcReplacementForm.get(key)?.markAsUntouched();
      }
      this.ltcReplacementForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.ltcReplacementForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckltc(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data): any {
    return this.utilsService.getInlineErrorMessage(data);
  }

  gridformStatus(event): void {
    this.gridValid[event.gridId] = event.formValid;
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    const key = event.gridId;
    this.updatedGridAnswers[key] = event.gridAnswers
    this.gridAnswers = event.gridAnswers;
    this.isGridFormChange = event.isGridFormChange;
    this.gridFromValidUpdate();
    this.emitStatus();
  }

  emitStatus(): void {
    if (Object.entries(this.updatedGridAnswers).length > 0) {
      this.questions.pages[0].gridAnswers[0] = this.updatedGridAnswers;
    } else {
      this.questions.pages[0].gridAnswers = [];
    }
    const existingInsuranceInfoObj = {
      formValid: this.formValid && this.gridFromValid,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'ltcReplacementForm',
      gridForm: true,
      isFormChange: true || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.ltcReplacementForm),
    };
    this.formStatus.emit(existingInsuranceInfoObj);
  }

  private checkForExistingInsurance(): void {
    this.hasExistingInsurance = this.replacementInsQues1?.toLocaleLowerCase().indexOf('yes') > -1
                                || this.replacementInsQues2?.toLocaleLowerCase().indexOf('yes') > -1
    if (!this.hasExistingInsurance) {
      this.gridAnswers = [];
    }
  }

  private getValueById(id: string): string {
    return this.ltcReplacementForm.get(this.defaultPageLoadService.getFieldIdByTag(id))?.value?.toLowerCase();
  }

  private updateDisplayForQues(shouldDisplay: boolean, xmlTag?: string, fieldId?: string): void {
    this.ltcReplacementData.forEach(ques => {
      if (ques.xmlTag === xmlTag || ques.fieldId === fieldId) {
        ques.required = shouldDisplay ? 'true' : 'false';
        ques.display = shouldDisplay;
      }
    });
  }

  private updateAnswerValues(): void {
    this.replacementInsQues1 = this.getValueById(ExistingInsuranceConstant.replacementkey1);
    this.replacementInsQues2 = this.getValueById(ExistingInsuranceConstant.replacementkey2);
  }

  getGridInstances(gridId: any): any {
    const gridInstancesBYgridId: any = [];
    gridInstancesBYgridId.push(this.gridInstance[this.gridInstance.findIndex((x: any) => x.id === gridId)]);
    this.gridInd = gridInstancesBYgridId[0].maxGridIndex;
    return gridInstancesBYgridId;
  }

  getGridAns(gridId: any): any {
    if (this.gridAnswers) {
      return this.gridAnswers[gridId];
    }
  }

  private emitIsLongTermCareInfo(): void {
    const isLongTermCare = this.ltcReplacementForm.get(
      this.getFieldIdByXmlTag(this.keysForIllustration.replaceMedicalHealthLTCIndicator2))?.value?.toLowerCase()?.indexOf('yes') > -1;
    this.userService.setIsLongTermCareInforce(isLongTermCare);
    this.isLongTermCareInforce.emit(isLongTermCare ? 'yes' : 'no');
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }
  
}
