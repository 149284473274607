<section class="agent-instr-page page-content-container">
  <div class="questions--container">

    <h1>
      Wet Signature Instructions
    </h1>

    <div class="loader-container" *ngIf="showLoader">
      <lfg-loader></lfg-loader>
    </div>

    <lfg-alert-message type="error" *ngIf="errorOccurred">{{CONST.GENERIC_ERROR}}</lfg-alert-message>

    <ng-container class="form-control-parent" *ngFor="let data of agentInstructionData; let ind = index;">

      <!-- Element for Alert Text -->
      <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Alert'">
        <lfg-alert-message type="warning">{{ data.questionText }}</lfg-alert-message>
      </div>

      <!-- Element for showing text -->
      <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Instruction' && data.subText != 'Alert' && data.notes != 'FORMAT_AS_BUTTON'">
        <h2 [innerHTML]="data.questionText"></h2>
      </div>

       <!-- Form element for Button -->
       <div class="form--control form--length-btn search-btn" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.notes === 'FORMAT_AS_BUTTON'">
        <button id="{{displayIdCreation(data)}}_{{ind}}" (click)="generateForms(); focusOff($event);" [disabled]="(!userAccessDetails?.editCase)" class="btn focusOffBtn">{{data.questionText}}</button>
      </div>
    </ng-container>

  </div>
</section>

<ng-container *ngIf="displayProgressModal">
  <app-progress-overlay [modalStateInput]="displayProgressModal" [messageToshow]="CONST.FORM_LOADER_MESSAGE"
      [progressPercentArray]="progressPercentArray" [showActionButton]="true" (openPdfForm)="openPdfForm($event)"
      [formAvailable]="formAvailable" [showDoNotCloseBrowserMessage]="false" (closeProgressModal)="closeProgressModal($event)">
  </app-progress-overlay>
</ng-container>
