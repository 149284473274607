<section class="overlay-modal-container" *ngIf="overlayModalConfiguration" id="progressModalId">
  <lfg-modal-popup [modalConfig]="overlayModalConfiguration" (closeModalPopup)=closeModalPopup()>
    <div modal-header>
      <h1 class="visually-hidden">Policy creation in progress </h1>
      <div class="spinner">
        <div class="circle" [ngClass]="{'circle-color': showActionButton && progressPercentage == 100}">
          <div class="modal-spinner-image-container image-container loading-image-size" *ngIf="!showActionButton || (showActionButton && progressPercentage != 100)"></div>
          <div class="modal-success-image-container image-container success-image-size" *ngIf="showActionButton && progressPercentage == 100"></div>
        </div>
      </div>
    </div>
    <div modal-body>
      <div class="hr"></div>
      <div class="modal-body-container">
        <div class="progress-bar-container">
          <div class="progress-bar-width" [ngClass]="progressPercentage ? 'progress-bar-width-' + progressPercentage: ''"></div>
          <div class="progress-bar-percentage" [ngClass]="{'progress-bar-percentage-fill' : progressPercentage == 100}" aria-label="{{ progressPercentage }} %" aria-live="assertive">{{ progressPercentage }}%</div>
        </div>

        <div>
          <h2 class="headerText" id="headerText">{{Message.POLICY_SUBMISSION_HEADER}}</h2>
          <p class="bodyText">{{messageToshow}}</p>
          <p class="bodyText general-info" *ngIf="showDoNotCloseBrowserMessage">{{Message.DO_NOT_CLOSE_MESSAGE}}</p>
        </div>
        <div class="btn-container-review" *ngIf="showActionButton">
          <lfg-left-button-tray>
            <button class='btn' lfgDefaultButton (click)="showDownloadedForm()" aria-label="View Forms" [disabled]="progressPercentage < 100">View Forms</button>
            <button class='btn' lfgBorderButton (click)="closeModalPopup()" aria-label="Cancel">Cancel</button>
          </lfg-left-button-tray>
        </div>
      </div>
    </div>
    <div modal-footer></div>
  </lfg-modal-popup>
</section>
