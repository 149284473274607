import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { LfgModalPopupModule } from '@ng/lfg-modal-popup';
import { ApplicationConfig } from '../../config/app.config';
import { SideNavConfig } from '../../config/sideNav.config';
import { HelpInfoData } from 'src/config/helpInfo.config';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    LfgModalPopupModule,
  ],
  declarations: [],
  exports: [
    HttpClientModule,
    LfgModalPopupModule,
  ],
  providers: [
    HelpInfoData,
    ApplicationConfig,
    SideNavConfig
  ]
})
export class CoreModule { }
