export class FormSelectionTableConfig {
    tableData = {
      config: {
        tableId: 'eticket-dbResult-table',
        tableClass: 'eticket-dbResult-table',
        sorting: false,
        pagination: true,
        rowsPerPage: 10,
        currentPage: 0,
        defaultSortOrder: 'desc',
        clickSortOrder: 'asc',
        tableFoot: false,
        handleSubDetailRow: false,
        noRecordsText: 'No records found'
      },
      column: [
        {
          displayText: 'INCLUDE',
          columnKey: 'checkbox',
          columnFlag: 'checkbox',
          isAction: 'noaction',
          pipeName: null,
          sorting: false,
          align: 'center',
          classNameTh: '',
          type: 'htmlText',
          classNameTd: 'lfg-checkbox eticket-dbResult-table__checkbox'
        },
        {
          displayText: 'FORM NAME',
          columnKey: 'formName',
          columnFlag: 'formName',
          pipeName: null,
          type: 'htmlText',
          sorting: false,
          align: 'left',
          isAction: 'noaction',
          classNameTh: 'eticket-dbResult-table-th_formName',
          classNameTd: 'result-table-td_formName'
        },
        {
          displayText: 'FORM NUMBER',
          columnKey: 'formNumber',
          columnFlag: 'formNumber',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: false,
          sortBy: 'formNumber',
          align: 'left',
          dataType: 'string',
          classNameTh: 'eticket-dbResult-table-th_formNumber',
          classNameTd: 'result-table-td_formNumber'
        },
        {
          displayText: 'REQUIRED',
          columnKey: 'required',
          columnFlag: 'required',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: false,
          sortBy: 'required',
          align: 'left',
          dataType: 'string',
          classNameTh: 'eticket-dbResult-table-th_required',
          classNameTd: 'result-table-td_required'
        },
        {
          displayText: 'FORM ORIGIN',
          columnKey: 'formOrigin',
          columnFlag: 'formOrigin',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: false,
          align: 'left',
          classNameTh: 'eticket-dbResult-table-th_formOrigin',
          classNameTd: 'result-table-td_formOrigin'
        }
      ],
      data: [ ]
    };
  }
  