<section *ngIf="tableDataLoaded">
  <div *ngIf="showLoader">
    <lfg-loader></lfg-loader>
  </div>

  <div class="warning-container" *ngIf="noOfCasesLeavingSoon > 0">
    <lfg-alert-message type="warning">{{MessageConst.ACTION_REQ_CASES_LEAVING_SOON.replace('{$noOfCases}', noOfCasesLeavingSoon)}}</lfg-alert-message>
  </div>

  <div class="result-table-header" id="table-header-top">
    <div class="result-table__export">
      <p>{{MessageConst.NO_OF_RECORD_MATCHING}}({{ tableData.data.length }})</p>
      <ul class="result-table__export--list" [ngClass]="{ 'link-is-disabled': tableData.data.length === 0 }">
        <li>
          <form [formGroup]="dashboardDDForm">
            <label for="viewRecordsDDown">Records per page: </label>
            <lfg-dropdown formControlName="viewRecordsDDown" ngDefaultControl modifierClass="lfg-dropdown--no-float no-placeholder" [options]="dropDownList" (selectionChanged)="handleSelectionChange($event)">
            </lfg-dropdown>
          </form>
        </li>
      </ul>
    </div>
  </div>
  <div class="result-table-section" *ngIf="tableData.data.length !== 0">
    <div class="result-table-desktoplink--section" [ngClass]=" {'warn-background': selectedRowsCount > 5}">
      <ul *ngIf="selectedRowsCount < 6">
        <li class="noOfSelectedItem"><strong>{{selectedRowsCount}} selected</strong> - available actions:</li>
        <li><a (click)="editCase(true)" tabindex="0" class="cursor-pointer" [ngClass]="{disableLink: selectedRowsCount > 1 || selectedRowsCount == 0}">OPEN</a></li>|
        <li><a (click)="cloneCase()" tabindex="0" class="cursor-pointer" [ngClass]="{disableLink: selectedRowsCount > 1 || selectedRowsCount == 0 || !userAccessDetails.duplicate || selectedRows[0]?.productName?.toLowerCase()?.indexOf('moneyguard') > -1}">DUPLICATE</a></li>|
        <li><a (click)="openDeleteModal()" tabindex="0" class="cursor-pointer" [ngClass]="{disableLink: (selectedRowsCount == 0 || !userAccessDetails.delete) || noOfSubmittedCaseSelected > 0}">DELETE</a></li>
      </ul>
      <span *ngIf="selectedRowsCount > 5" class="result-table-warn">
        <i class="fas fa-exclamation-circle warn-icon" aria-hidden="true"></i>
        {{MessageConst.ONLY_5_ALLOWED}}
      </span>
      <ul>
        <li><i class="fas fa-exclamation-triangle warn-icon" aria-hidden="true"></i>Action needed</li>
      </ul>
    </div>
    <div class="result-table-mobilelink--section" [ngClass]=" {'warn-background': selectedRowsCount > 5}">
      <ul *ngIf="selectedRowsCount < 6">
        <li class="noOfSelectedItem"><strong>{{selectedRowsCount}} selected</strong></li>
        <li class="dbPageTable__mobActions">
          <span><a (click)="editCase(true)" tabindex="0" [ngClass]="{disableLink: selectedRowsCount > 1 || selectedRowsCount == 0}">OPEN</a></span>|
          <span><a (click)="cloneCase()" tabindex="0" [ngClass]="{disableLink: selectedRowsCount > 1 || selectedRowsCount == 0 || !userAccessDetails.duplicate || selectedRows[0]?.productName?.toLowerCase()?.indexOf('moneyguard') > -1}">DUPLICATE</a></span>|
          <span><a (click)="openDeleteModal()" tabindex="0" [ngClass]="{disableLink: (selectedRowsCount == 0 || !userAccessDetails.delete) || noOfSubmittedCaseSelected > 0}">DELETE</a></span>
        </li>
      </ul>
      <span *ngIf="selectedRowsCount > 5" class="result-table-warn">
        <i class="fas fa-exclamation-circle warn-icon" aria-hidden="true"></i>
        {{MessageConst.ONLY_5_ALLOWED}}
      </span>
    </div>
    <lfg-primary-table [tableData]="tableData" (tableSortTriggered)="sortTableData($event)" (pageNumberClicked)="scrollTopOfTable()" id="case-info-dbtable">
      <ng-template #tableCelContentTemplate let-column="column" let-row="row">
        <div *ngIf="column.columnFlag === 'checkbox'">
          <div class="lfg-checkbox">
            <input type="checkbox" value="checked" id="{{ row.caseId }}" name="{{ row.caseId }}" (change)="selectCaseCheckbox($event, row)" [checked]="row.checkbox">
            <label for="{{ row.caseId }}" class="caseCheckBoxLabel">
              <span>none</span>
            </label>
          </div>
        </div>
        <div *ngIf="column.columnFlag === 'insuredName'">
          <a (click)="editCase(false, row)" class="cursor-pointer">{{ (row.insuredLastName | combine: [row.insuredFirstName]) }}</a>
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'status'">
          <strong>
            <i *ngIf="warnStatus.includes(row.status) && row.noOfDaysPassed < 75" class="fas fa-exclamation-triangle warn-icon" aria-hidden="true"></i>
            <i *ngIf="successStatus.toLowerCase() === row.status?.toLowerCase()" class="fas fa-check-circle complete-icon" aria-hidden="true"></i>
            <i *ngIf="row.noOfDaysPassed >= 75 && successStatus.toLowerCase() !== row.status?.toLowerCase()" class="fas fa-bolt warn-icon" aria-hidden="true"></i>
            {{ row.status }}</strong>
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'productName'">
          {{ row.productName }}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'lastActivityDate'">
          {{row.updatedDate | date: 'MM/dd/yyyy'}}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'caseDescription' ">
          {{ row.caseDescription ? row.caseDescription : 'NA' }}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'producerName' ">
          {{ row.producerName }}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'refCaseId' ">
          {{ row.refCaseId ? row.refCaseId : 'NA' }}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'createdDate'">
          {{row.createdDate | date: 'MM/dd/yyyy hh:mm:ss a'}}
        </div>
      </ng-template>
    </lfg-primary-table>
  </div>
  <div class="result-table-section" *ngIf="tableData.data.length === 0">
    <p id="no_record_table"></p>
    <table class="lfg-table-primary" aria-describedby="no_record_table">
      <thead>
        <tr>
          <th class="lfg-table-primary__th"></th>
          <th class="lfg-table-primary__th">CLIENT NAME</th>
          <th class="lfg-table-primary__th">STATUS</th>
          <th class="lfg-table-primary__th">PRODUCT TYPE</th>
          <th class="lfg-table-primary__th">CASE DESCRIPTION</th>
          <th class="lfg-table-primary__th">WRITING AGENT</th>
          <th class="lfg-table-primary__th">CASE ID</th>
          <th class="lfg-table-primary__th">CREATED DATE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="lfg-table-primary__td" colspan="7">{{tableData.config.noRecordsText}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
<lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" (closeModalPopup)="closeModalPopup(modalConfig)">
  <div modal-header id="status_details_table">
    <h1 class="lfg-color">Status details</h1>
  </div>
  <div modal-body>
    <div class="result-modalTable-section">
      <table class="lfg-table-primary" aria-describedby="status_details_table">
        <thead>
          <tr>
            <th class="lfg-table-primary__th">STATUS</th>
            <th class="lfg-table-primary__th">DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let status of helpTableConfig">
            <td class="lfg-table-primary__td">{{status.status}}</td>
            <td class="lfg-table-primary__td">{{status.description}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div modal-footer>
    <lfg-left-button-tray>
      <button class='btn' lfgDefaultButton (click)="closeModalPopup(modalConfig)">Close</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>

<lfg-modal-popup *ngIf="deleteModal" [modalConfig]="deleteModal" class="modal-popup--sm" (closeModalPopup)="closeDeleteModalPopup(modalConfig)">

  <div modal-header>
    <h1>{{MessageConst.DELETE_CASE_DIALOG_HEADER}}</h1>
  </div>

  <div modal-body class="modal-popup__body--scroll">{{MessageConst.DELETE_CASE_WARNING_MESSAGE}} <br>
    <strong>{{MessageConst.DELETE_CASE_CONFIRMATION_MESSAGE}}</strong>
  </div>

  <div modal-footer>
    <lfg-left-button-tray>
      <button type="" class='btn' (click)="deleteSelectedCases()" lfgDefaultButton>YES, DELETE</button>
      <button type="" class='btn' (click)="closeDeleteModalPopup(modalConfig)" lfgDefaultButton>NO, CANCEL</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>

<lfg-modal-popup *ngIf="deleteResultModal" [modalConfig]="deleteResultModal" (closeModalPopup)="closeDeleteResultModalPopup(deleteResultModal)">
  <div modal-header>
    <h1 class="lfg-color">{{MessageConst.DELETE_CASE_STATUS_DIALOG_HEADER}}</h1>
  </div>
  <div modal-body>{{deleteResultMessage}}</div>
  <div modal-footer>
    <lfg-left-button-tray>
      <button type="" class='btn' (click)="closeDeleteResultModalPopup(deleteResultModal)" lfgDefaultButton>Close</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>
