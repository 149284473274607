<section class="welcome-container">
  <div class="welcome-msg">
    <span>
      <i class="fas fa-info-circle info-icon" aria-hidden="true"></i>
    </span>
    <p class="mobile-info">
      <span *ngIf="readMore">{{welcomeMsg | truncate : 108}}</span>
      <span *ngIf="!readMore">{{welcomeMsg}}</span>
      <span class="read-link">
        <a *ngIf="readMore" (click)="linkClicked()">Read more <i class="fas
            fa-angle-down" aria-hidden="true"></i></a>
        <a *ngIf="!readMore" (click)="linkClicked()">Read less <i class="fas
            fa-angle-up" aria-hidden="true"></i></a>
      </span>
    </p>
    <p class="desktop-info">
      <span>{{welcomeMsg}}</span>
    </p>


  </div>
  <div class="welcome-msg">
    <span>
      <i class="fas fa-info-circle info-icon" aria-hidden="true"></i>
    </span>
    <p>
      {{requiredQuesMsg}}
    </p>
  </div>
</section>
