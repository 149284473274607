import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelpInfoData } from 'src/config/helpInfo.config';

@Component({
  selector: 'app-help-hint',
  templateUrl: './help-hint.component.html',
  styleUrls: ['./help-hint.component.scss']
})
export class HelpHintComponent implements OnInit {

  @Output() closeSlide = new EventEmitter();
  @Input()
  activeTab: string;
  displayHelpData = false;

  helpInfo: any;
  constructor(public helpInfoData: HelpInfoData) { }

  ngOnInit(): void {
    this.getHelpData();
  }

  getHelpData(): void {
    this.displayHelpData = true;
    this.helpInfo = this.helpInfoData.tabSpecificHelpInfo.filter((info) => info.tabName === this.activeTab);
    if (this.helpInfo.length === 0) {
      this.helpInfo = this.helpInfoData.tabSpecificHelpInfo.filter(
        info => info.tabName === 'Others - Tab'
      );
    }
  }

  closeHelpData(): void {
    this.closeSlide.emit(true);
  }

}
