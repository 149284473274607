export class SignerInfoTableConfig {
    tableData = {
      config: {
        tableId: 'eticket-dbResult-table',
        tableClass: 'eticket-dbResult-table',
        sorting: false,
        pagination: true,
        rowsPerPage: 10,
        defaultSortOrder: 'desc',
        clickSortOrder: 'asc',
        handleSubDetailRow: false,
        currentPage: 0,
        tableFoot: false,
        noRecordsText: 'No records found'
      },
      column: [
        {
          displayText: 'NAME',
          isAction: 'noaction',
          columnKey: 'name',
          columnFlag: 'name',
          pipeName: null,
          sorting: false,
          align: 'left',
          type: 'htmlText',
          classNameTd: 'agent-table-td_info',
          classNameTh: 'agent-table-th_info'
        },
        {
          displayText: 'SIGNER ROLE',
          isAction: 'noaction',
          columnKey: 'role',
          columnFlag: 'role',
          pipeName: null,
          align: 'left',
          type: 'htmlText',
          sorting: false,
          classNameTd: 'agent-table-td_info',
          classNameTh: 'agent-table-th_info'
        },
        {
          displayText: 'PHONE',
          pipeName: null,
          columnKey: 'phone',
          columnFlag: 'phone',
          isAction: 'noaction',
          type: 'htmlText',
          sorting: false,
          align: 'left',
          classNameTd: 'agent-table-td_info',
          classNameTh: 'agent-table-th_info'
        },
        {
          displayText: 'EMAIL ADDRESS',
          isAction: 'noaction',
          columnKey: 'email',
          columnFlag: 'email',
          pipeName: null,
          type: 'htmlText',
          sorting: false,
          align: 'left',
          classNameTd: 'agent-table-td_info',
          classNameTh: 'agent-table-th_info'
        },
        {
          displayText: 'LAST 4 OF SSN',
          isAction: 'noaction',
          columnKey: 'last4digit',
          columnFlag: 'last4digit',
          pipeName: null,
          type: 'htmlText',
          sorting: false,
          align: 'left',
          classNameTd: 'agent-table-td_info',
          classNameTh: 'agent-table-th_info'
        }
      ],
      data: []
    };
  }
  