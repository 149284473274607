
<div class="questions-header section-divider">
  <h2>Available products</h2>
  <span>Select one of the available products to continue.</span>
  <lfg-alert-message *ngIf="showProductUnavailableMessage" type="warning">{{mgma22UnavailableMessage}}</lfg-alert-message>
</div>

<div class="product-list-container">
  <form class="product-form-group" [formGroup]="productInfoForm" id="productInfoForm">
    <div class="view-table">
      <div class="lfg-radio view-row" *ngFor="let product of productList">
        <div class="view-cell" *ngIf="!product.hideProduct">
          <div class="lfg-radio--single">
            <input [attr.aria-label]="product.productName" tabindex="0" id="{{ product.productName }}"
              type="radio" name="product" value="{{ product.productName }}"
              (change)="onProductSelect(product)" formControlName="product">
            <label for="{{ product.productName }}">{{ product.productName }}</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
