<div class="policy-projection-grid-page">
    <form [formGroup]="mainFormGroup" id="mainFormGroup" method="post" *ngIf="mainFormGroup" class="main-form-container">
        <div class="funds-table-row funds-table-row-header funds-table">
            <div class="funds-table-header funds-table" *ngFor="let header of headerList; let headerInd = index">
                <div class="col-{{ headerInd + 1 }} table-column">{{header}} </div>
            </div>
        </div>
        <div *ngFor="let grid of gridSequences; let i = index;" [formArrayName]="grid.gridId">
            <div class="funds-table funds-table-container" [formGroupName]="grid.gridIndex" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
                <ng-container class="form-control-parent funds-table-rows" *ngFor="let data of gridQuestionsObj[grid.gridId]?.gridQuestions; let rowIndex = index;">
                    <div class="funds-table funds-table-row funds-table-row-value"  *ngIf="data.subText == 'table-value'">
                        <div class="col-{{ rowIndex }} table-column">
                            <div class="grid-form--control delete-button" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.notes === 'DELETE_BUTTON' && i > 0">
                                <a (click)="removeRowGrid(grid)" tabindex="0">Delete</a>
                            </div>
                                <!-- Form element for currency  -->
                            <div class="grid-form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes === 'ITES_CURRENCY_USD'">
                                <lfg-input-wrap>
                                    <input lfgInput placeholder="{{data.required === 'true' ? data.questionText : data.questionText + ' (optional)'}}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols appCurrencyFormat [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
                                </lfg-input-wrap>
                            </div>
                            <!-- Form element for percentage  -->
                            <div class="grid-form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes === 'ITES_FORMAT_AS_PERCENTAGE'">
                                <lfg-input-wrap>
                                    <input lfgInput placeholder="{{data.required === 'true' ? data.questionText : data.questionText + ' (optional)'}}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols appPercentageFormat [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
                                </lfg-input-wrap>
                            </div>
                            <!-- Form element for text  -->
                            <div class="grid-form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes !== 'ITES_CURRENCY_USD' && data.notes !== 'ITES_FORMAT_AS_PERCENTAGE'">
                                <lfg-input-wrap>
                                    <input lfgInput placeholder="{{data.required === 'true' ? data.questionText : data.questionText + ' (optional)'}}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
                                </lfg-input-wrap>
                            </div>
                            <!-- Form element for Drop Down -->
                            <div class="grid-form--control grid-form-dropDown" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
                                <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ data.required === 'true' ? data.questionText : data.questionText + ' (optional)' }}" [required]="data.required == 'true'" [ngClass]="{'dropdown--err':(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid)}">
                                </lfg-dropdown>
                                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
    <div class="grid-footer">
        <div class="add-grid" *ngIf="addAccordionText">
            <a (click)="createNewGrid(true)" [ngClass]="{'btn--link-disabled': disableForm || maxGridAvailable <= gridSequences.length}">
                <i class="fas fa-plus-circle add-icon" aria-hidden="true"></i>{{addAccordionText}}</a>
            <span class="grid-count">{{gridSequences.length}}/{{maxGridAvailable}}</span>
        </div>
    </div>
</div>