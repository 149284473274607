import { Component, OnInit } from '@angular/core';
import { LfgLoaderService } from '@ng/lfg-loader';

@Component({
  selector: 'app-docusign-thank-you',
  templateUrl: './docusign-thank-you.component.html',
  styleUrls: ['./docusign-thank-you.component.scss']
})
export class DocusignThankYouComponent implements OnInit {

  showLoader = false;
  constructor(
    private loaderService: LfgLoaderService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.loaderService.show();
  }
}
