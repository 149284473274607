import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  selector: '[appValidateOnBlur]'
})
export class ValidateOnBlurDirective {

  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private control: NgControl) {}

  @HostListener('focus', ['$event.target'])
  onFocus(_target): any {
    this.control.control?.markAsUntouched();
    this.setFocusInIndicator(true);
  }

  @HostListener('focusout', ['$event.target'])
  onFocusout(_target): any {
    this.control.control?.markAsTouched();
    this.setFocusInIndicator(false);
  }

  setFocusInIndicator(value): any {
    this.changed.emit(value);
  }
}
