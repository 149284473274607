import { Injectable } from '@angular/core';
import { TAB } from './sideNav.config';

@Injectable()
export class HelpInfoData {
  tabSpecificHelpInfo = [
    {
      tabName: TAB.PRIMARY_INSURED_MG,
      helpHeader: 'Proposed insured help',
      helpContent: [
        {
          sectionHeading: 'Need help on a question?',
          sectionContent: 'If you need help understanding a question, please call 877-546-2647.'
        },
        {
          sectionHeading: 'Frequently asked questions',
          sectionContent: `Please be sure this phone number can receive an automated voice call. It may be used during a forms signing process if necessary.<br/><br/>
                    The phone number will be used to send a pin number via text or an automated voice call during the forms signing process.</br></br>
                    Email address will be used to send the link to start the eSigning process.</br></br>
                    The last 4 digits of the SSN will be used to authenticate each signer.</br><br>`
        }
      ]
    },
    {
      tabName: TAB.OWNER_INFO,
      helpHeader: 'Owner help',
      helpContent: [
        {
          sectionHeading: 'Need help on a question?',
          sectionContent: 'If you need help understanding a question, please call 877-546-2647.'
        },
        {
          sectionHeading: 'Frequently asked questions',
          sectionContent: `Please be sure this phone number can receive an automated voice call.It may be used during a forms signing process if necessary.</br></br>
                    Email address will be used to send the link to start the eSigning process.</br></br>
                    The last 4 digits of the SSN will be used to authenticate each signer.</br></br>
                    The Owner of the life insurance policy has all rights to the policy. The Owner has the right to loan, surrender, assign, or in any way, alter the contract.</br></br>`
        }
      ]
    },
    {
      tabName: TAB.EXISTING_INS_MG,
      helpHeader: 'Existing insurance help',
      helpContent: [
        {
          sectionHeading: 'Need help on a question?',
          sectionContent: 'If you need help understanding a question, please call 877-546-2647.'
        },
        {
          sectionHeading: 'Frequently asked questions',
          sectionContent: `An insurance policy that has been sold is one that is active and inforce on your life which you sold to a third party for cash, and in return they continue to pay the premiums and will receive the death benefit upon your passing.</br></br>
                    A replacement means you have inforce insurance coverage that you plan to cancel or stop paying premiums on in order to get this new policy. All policies that you are planning to replace must be listed.</br></br>`
        }
      ]
    },
    {
      tabName: TAB.EXISTING_INS_MG,
      helpHeader: 'Existing insurance help',
      helpContent: [
        {
          sectionHeading: 'Need help on a question?',
          sectionContent: 'If you need help understanding a question, please call 877-546-2647.'
        },
        {
          sectionHeading: 'Frequently asked questions',
          sectionContent: `An insurance policy that has been sold is one that is active and inforce on your life which you sold to a third party for cash, and in return they continue to pay the premiums and will receive the death benefit upon your passing.</br></br>
                    A replacement means you have inforce insurance coverage that you plan to cancel or stop paying premiums on in order to get this new policy. All policies that you are planning to replace must be listed.</br></br>`
        }
      ]
    },
    {
      tabName: TAB.EXISTING_INS_WS,
      helpHeader: 'Existing insurance help',
      helpContent: [
        {
          sectionHeading: 'Need help on a question?',
          sectionContent: 'If you need help understanding a question, please call 877-546-2647.'
        },
        {
          sectionHeading: 'Frequently asked questions',
          sectionContent: `An insurance policy that has been sold is one that is active and inforce on your life which you sold to a third party for cash, and in return they continue to pay the premiums and will receive the death benefit upon your passing.</br></br>
                    A replacement means you have inforce insurance coverage that you plan to cancel or stop paying premiums on in order to get this new policy. All policies that you are planning to replace must be listed.</br></br>`
        }
      ]
    },
    {
      tabName: TAB.POLICY_PROJECTION_WS,
      helpHeader: 'Policy Projection help',
      helpContent: [
        {
          sectionHeading: 'Need help on a question?',
          sectionContent: 'If you need help understanding a question, please call 877-546-2647.'
        },
        {
          sectionHeading: 'Frequently asked questions',
          sectionContent: `Select Run Projection to create an illustration.</br></br>Select View Report to review and download the illustration.</br></br>Select Edit to unlock the case and rerun a quote. The most recent illustration will be available for review when a requote is perform.</br></br>
                  Select Next to send information to salesforce and continue to the next step.</br></br>`
        }
      ]
    },
    {
      tabName: 'Others - Tab',
      helpHeader: 'Need Help?',
      helpContent: [
        {
          sectionHeading: 'For technical assistance',
          sectionContent: '<div class="help-subcontent">Email - webmaster@lfd.com </br>(response within 48 hours) </div><div class="help-subcontent">Phone - 877-533-1022 </br>(immediate assistance)</div><div class="help-subcontent">Tech Tip - Taking a screenshot of an error warning or issue is helpful.</div>'
        }
      ]
    },
    {
      tabName: 'Welcome',
      helpHeader: 'Need Help?',
      helpContent: [
        {
          sectionHeading: 'Need help on a question?',
          sectionContent: 'If you need help understanding a question, please call 877-546-2647.'
        }
      ]
    },
  ];
  needMoreInfo = [
    {
      sectionHeading: 'For application submission questions',
      sectionContent: `<div class="help-content--info">
            <div class="help-subcontent-info"><strong>MoneyGuard eApp Questions</strong></br>
            MoneyGuard Sales Support:</br>877-546-2647</br>
            Mon-Fri 8 am - 6 pm ET</br></div>
            <div class="help-subcontent-info">
            <strong>Life eTicket Questions</strong></br>
            Life Sales Support: </br>866-247-1604</br>
            Mon-Fri 8 am - 6 pm ET</div>
            </div>`
    }
  ];
  needMoreInfoWS = [
    {
      sectionHeading: 'For application questions',
      sectionContent: `<div class="help-content--info">
            <div class="help-subcontent-info">
            MoneyGuard Sales Support:</br>877-546-2647</br>
            Mon-Fri 8 am - 6 pm ET</br></div>
            </div>`
    }
  ];
}
