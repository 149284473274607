import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(data: any[], filterObj: any): any {
    if (!data || !data.length) {
      return [];
    }
    if (Object.keys(filterObj).length === 0) {
      return data;
    }
    const getfilteredKey = Object.keys(filterObj);
    return data.filter((item) => {
      const matchingFields = getfilteredKey.find(
        (key) =>
          item[key]?.toLowerCase().indexOf(filterObj[key].toLowerCase()) < 0
      );
      return !matchingFields;
    });
  }
}
