<lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" class="agentInfoModal"
  (closeModalPopup)="closeModalPopup('close')">
  <div modal-header>
    <h1>Agent Failed Validation</h1>
  </div>
  <div modal-body class="modal-popup__body--scroll">
    <div class="result-modalTable-section">
      <lfg-alert-message type="error">{{message}}</lfg-alert-message>
      <p *ngIf="additionalText" [innerHTML]="additionalText"></p>
    </div>
  </div>
  <div modal-footer class="modal-btn-footer">
    <div class="info-text-btn" *ngIf="okButtonMessage">
      <lfg-alert-message type="info">{{okButtonMessage}}</lfg-alert-message>
    </div>
    <lfg-left-button-tray>
      <button class='btn' lfgDefaultButton aria-label="Ok" (click)="closeModalPopup()">Ok</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>