<app-header *ngIf="userRole === ROLE_TYPE.WHOLESALER && !isHub"></app-header>
<section class="dashboard-main-container">
  <ng-container class="loader-container" *ngIf="!userRole || (showLoader > 0 && userRole != 'agent')">
    <lfg-loader></lfg-loader>
  </ng-container>
  <ng-container *ngIf="userRole === ROLE_TYPE.WHOLESALER">
  <div class="welcome-user-details">
    Welcome, {{agentName | titlecase}}
    <div class="dbtitleBtn--view">
      <button class="btn" (click)="rdToNewCasepge()" [disabled]="systemMessage?.type === MessageType.OUTAGE || !userAccessDetails?.newCase">New Case</button>
    </div>
  </div>
  <div class="eticket-dashboard-header">
    <div class="dashboard-title-help-hint-cont">
      <h1 class="desktopViewDBheader">Wholesaler dashboard</h1>
      <a (click)="openHelpHintModal()" class="help-icon-anchor">
        <span class="help-icon-dashboard"><i class="fas fa-question-circle help-icon-image"></i>Help</span>
      </a>
    </div>
    <div class="mobileViewDBheader">
      <h1>Wholesaler dashboard</h1>
      <div class="btn-container-mbl">
        <button class="btn btn-case dbtitleBtn" (click)="rdToNewCasepge()" [disabled]="systemMessage?.type === MessageType.OUTAGE">New Case</button>
      </div>
    </div>
    <p>The wholesaler dashboard includes a list of all cases that are currently in progress or completed. You can access specific case information by selecting the client's name. You can perform specific case actions by selecting the check box next to the case.</p>
  </div>

  <ng-container *ngIf="systemMessage?.type === MessageType.OUTAGE">
    <div class="application-unavailable-container">
      <lfg-alert-message type="error">{{systemMessage?.message}}</lfg-alert-message>
    </div>
  </ng-container>

  <ng-container *ngIf="systemMessage?.type !== MessageType.OUTAGE">
    <app-eticket-search (searchValue)="eticketDashSearch($event)" (clearSearchData)="clearSearch($event)"></app-eticket-search>
    <app-eticket-dashboard-table [searchValue]="searchValue" (tableLoadedEvent)="tableLoaded($event)" [userAccessDetails]="userAccessDetails"></app-eticket-dashboard-table>
  </ng-container>
  <lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" (closeModalPopup)="closeHelpHintModalPopup()">
    <div modal-header>
      <h1>Need Help?</h1>
    </div>
    <div modal-body>
      <div *ngFor="let content of helpInfo[0].helpContent" class="help-section-cont">
        <p class="help-content--header">{{content.sectionHeading}}</p>
        <div class="help-content--info" [innerHtml]="content.sectionContent"></div>
      </div>
      <div class="help-section-cont" *ngIf="userRole !== ROLE_TYPE.WHOLESALER">
        <p class="help-content--header">{{helpInfoData.needMoreInfo[0].sectionHeading}}</p>
        <div class="help-content--info" [innerHtml]="helpInfoData.needMoreInfo[0].sectionContent"></div>
      </div>
      <div class="help-section-cont" *ngIf="userRole === ROLE_TYPE.WHOLESALER">
        <p class="help-content--header">{{helpInfoData.needMoreInfoWS[0].sectionHeading}}</p>
        <div class="help-content--info" [innerHtml]="helpInfoData.needMoreInfoWS[0].sectionContent"></div>
      </div>
    </div>
    <div modal-footer>
      <lfg-left-button-tray>
        <button class='btn' lfgDefaultButton (click)="closeHelpHintModalPopup()">CLOSE</button>
      </lfg-left-button-tray>
    </div>
  </lfg-modal-popup>
  </ng-container>
  <ng-container *ngIf="userRole && userRole !== ROLE_TYPE.WHOLESALER">
    <lfg-alert-message type="error">{{Message.UNAUTHORIZED_USER_MSG}}</lfg-alert-message>
  </ng-container>
</section>
<app-footer *ngIf="userRole === ROLE_TYPE.WHOLESALER && !isHub"></app-footer>