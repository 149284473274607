import { Injectable } from '@angular/core';
import { AgentProfile, PageStatus } from '../models/casePage.model';
import { ACCESS_TYPE, AccessibilityConfig, DelegationFunctionalityConfig, FunctionalityConfig } from 'src/config/delegationFunctionality.config';
import { PAGE_GROUP_NAME } from 'src/config/page.config';

@Injectable({
  providedIn: 'root'
})
export class DelegationService {

  loggedInUserAccess: string;
  functionsAccessConfig: AccessibilityConfig;

  constructor(private delegationFunctionalityConfig: DelegationFunctionalityConfig) { }

  setLoggedInUserAccess(agentData: AgentProfile): void {
    this.updateLoggedInUserAccess(agentData.isAgentFlag, agentData.fullaccess, agentData.limitedAccess, agentData.isHOuser);
    this.setAccessConfigForUser();
  }

  private updateLoggedInUserAccess(agentFlag: boolean, fullaccess: boolean, limitedAccess: boolean, isHoUserFlag: boolean): void {
    if (agentFlag || fullaccess) {
      this.loggedInUserAccess = ACCESS_TYPE.SUPERACCESS;
    } else if (limitedAccess) {
      this.loggedInUserAccess = ACCESS_TYPE.TRANSACT;
    } else {
      this.loggedInUserAccess = ACCESS_TYPE.READONLY;
    }
  }

  private setAccessConfigForUser(): void {
    this.functionsAccessConfig = JSON.parse(JSON.stringify(this.delegationFunctionalityConfig.accessFunctionalityList))
      .filter(accessFuncConfig => {
        return accessFuncConfig.accessType === this.loggedInUserAccess;
      })[0];
  }

  public getAccessConfigForUser(): FunctionalityConfig {
    return this.functionsAccessConfig?.functionalityAccess;
  }

  public getUserAccessibilityConfig(): AccessibilityConfig {
    return this.functionsAccessConfig;
  }

  public updateAccessForCase(
    caseStatus: PageStatus[], _isCreatedByDelegate: boolean, isLockedByDelegate: boolean,
    accessFuncConfig: AccessibilityConfig
  ): void {
    const isCaseEditable = this.isCaseEditable(caseStatus);
    accessFuncConfig.functionalityAccess.caseRelatedAccess = {
      onBehalfOfMessage: isCaseEditable && accessFuncConfig.accessType === ACCESS_TYPE.TRANSACT,
      showAgentConfCheckbox: isLockedByDelegate && accessFuncConfig.accessType === ACCESS_TYPE.SUPERACCESS,
      redirectToLastAccessedPage: (accessFuncConfig.accessType !== ACCESS_TYPE.TRANSACT) ||
        (accessFuncConfig.accessType === ACCESS_TYPE.TRANSACT && !isCaseEditable),
      unlockCase: (isCaseEditable && accessFuncConfig.accessType === ACCESS_TYPE.TRANSACT)
        || accessFuncConfig.accessType === ACCESS_TYPE.SUPERACCESS
    };
  }

  public resetAgentConfCheckbox(): void {
    this.functionsAccessConfig.functionalityAccess.caseRelatedAccess.showAgentConfCheckbox = false;
  }

  private isCaseEditable(caseStatus: PageStatus[]): boolean {
    // checking if signature method page is available then case is non editable
    return caseStatus?.filter(page => {
      return page.pageGroupName === PAGE_GROUP_NAME.SIGNATURE_METHOD;
    })?.length === 0;
  }

  public resetUserAccessForCase(): void {
    const tempAccessConfig = JSON.parse(JSON.stringify(this.delegationFunctionalityConfig.accessFunctionalityList));
    if (this.functionsAccessConfig?.functionalityAccess) {
      this.functionsAccessConfig.functionalityAccess.caseRelatedAccess =
        tempAccessConfig.filter(config => {
          return config.accessType === this.functionsAccessConfig.accessType;
        })[0].functionalityAccess.caseRelatedAccess;
    }
  }
}
