import { Pipe, PipeTransform } from '@angular/core';
import { ExistingInsuranceConstant, ExistingInsuranceInternalReplacementQ, InternalReplacementCompanies, TIAeftFieldsConst, billingConstants, premiumAndBillingKeys } from 'src/config/constants';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { UserDetailsService } from '../services/user-details.service';
import { FormGroup } from '@angular/forms';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'dataFilter',
  pure: false
})

export class DataFilterPipe implements PipeTransform {
  insuredState: string;

  constructor(private userDetailsService: UserDetailsService, private utilsService: UtilsService) {}

  // last parameter - add additional parameter as object
  transform(items: any, companySelected: any, type: any, pageGroupName: any, form: FormGroup, corpState: any, additionalParmObj: any): any {

    this.insuredState = this.userDetailsService.getInsuredState();

    if (pageGroupName === PAGE_GROUP_NAME.EXISTING_INSURANCE) {
      return this.ExistingInsusalesMaterialsIndConditionalDisplay(items, form);
    }

    if (pageGroupName === PAGE_GROUP_NAME.POLICY_INFO_PAGE) {
      return this.policyInfoConditinalDisplay(items);
    }

    if (type === 'OWNER') {
      return this.bonaFideCondition(items, corpState, form);
    }

    if (pageGroupName === PAGE_GROUP_NAME.BILLING_INFO_PAGE) {
      return this.filterBillingQuestions(items, additionalParmObj?.premiumMode, form);
    }

    if (pageGroupName === PAGE_GROUP_NAME.TEMPORARY_LIFE_INSURANCE_PAGE) {
      return this.filterTIAEFT(items, additionalParmObj?.isEFT);
    }

    if (type !== 'POLICY') {
      return items;
    }

    return items.filter(item => this.checkFieldConditionDispay(item, companySelected, form)[0] !== item.xmlTag);
  }

  filterBillingQuestions(items, premiumMode, form): any {
    return items?.filter(item => {
      if (item.xmlTag === premiumAndBillingKeys.premiumChangeInd) {
        if (premiumMode !== billingConstants.premiumModeSingle) {
          return item;
        }
        form.get(item.fieldId)?.setValue('');
        form.get(item.fieldId)?.setErrors(null);
        form.get(item.reflexiveQuestionAL[0].fieldId)?.setValue('');
        form.get(item.reflexiveQuestionAL[0].fieldId)?.setErrors(null);
        return;
      }
      return item;
    });
  }

  filterTIAEFT(items, isEFT): any {
    return items?.filter(item => {
      if (isEFT === 'true') {
        if (TIAeftFieldsConst.indexOf(item.xmlTag) === -1) {
          return item;
        }
      } else {
        return item;
      }
    });
  }

  bonaFideCondition(items, corpState, form): any {
    return items?.filter(item => {
      if (item.xmlTag === '/XML/Client/AppDetails/BusinessAddressNYMT') {
        const issueSt = ['NY', 'MT'];
        if (issueSt.indexOf(this.insuredState) > -1 && this.insuredState !== corpState) {
          return item;
        } else {
          form.get(item.fieldId)?.setValue('');
        }
      } else {
        return item;
      }
    });
  }

  policyInfoConditinalDisplay(items): any {
    return items.filter(item => {
      if (item.xmlTag === '/XML/MGeAPP/Riders/BenefitTransferRider') {
        if (!['GU', 'MP', 'NY'].includes(this.insuredState)) {
        return item;
        }
      }
      if (item.xmlTag === '/XML/MGeAPP/Riders/FlexibleCareCashAmendment') {
        if (!['CA', 'GU', 'MP', 'NY', 'VI'].includes(this.insuredState) ) {
          return item;
        }
      }
      if (item.xmlTag === '/XML/MGeAPP/Riders/LivingWellEndorsement') {
        if (!['CA', 'GU', 'MP', 'NY', 'VI', 'FL', 'ND', 'SD'].includes(this.insuredState) ) {
          return item;
        }
      }
      if (item.xmlTag === '/XML/MGeAPP/Riders/TerminalIllnessRider') {
        if (!['CA', 'GU', 'MP', 'NY'].includes(this.insuredState)) {
          return item;
        }
      }
      if (!['/XML/MGeAPP/Riders/BenefitTransferRider', '/XML/MGeAPP/Riders/FlexibleCareCashAmendment', '/XML/MGeAPP/Riders/LivingWellEndorsement', '/XML/MGeAPP/Riders/TerminalIllnessRider'].includes(item.xmlTag)) {
        return item;
      }
    })
  }

  checkFieldConditionDispay(item, companySelected, form: FormGroup): any {
    if (this.insuredState === 'FL' || item.xmlTag === '/XML/mg_eapp/existing_ins/OtherInsuranceCompany/MGMA_VariableInd') {
      return ExistingInsuranceInternalReplacementQ.filter(element => {
        if (element === item.xmlTag && this.checkInternalCompany(companySelected)) {
          if (form.get(item.fieldId).value) {
            form.get(item.fieldId).setValue('');
            form.get(item.fieldId).setErrors(null);
          }
          return item;
        }
      });
    } else {
      return item;
    }
  }

  checkInternalCompany(companySelected): boolean {
   return InternalReplacementCompanies.indexOf(companySelected) === -1;
  }

  ExistingInsusalesMaterialsIndConditionalDisplay(items, form: FormGroup): any {
    return items.filter(item => {
      if (item.xmlTag === ExistingInsuranceConstant.salesMaterialsInd) {
        if (this.checkConditionDisplayForSalesMaterial(items, form)) {
          return item;
        }
      } else {
        return item;
      }
    });
  }

  checkConditionDisplayForSalesMaterial(items: Array<any>, form: FormGroup): boolean {
    const replacementkey1FieldId = this.utilsService.getFieldByXmlTag(items, ExistingInsuranceConstant.replacementkey1);
    const replacementkey2FieldId = this.utilsService.getFieldByXmlTag(items, ExistingInsuranceConstant.replacementkey2);
    const salesMaterialsIndFieldId = this.utilsService.getFieldByXmlTag(items, ExistingInsuranceConstant.salesMaterialsInd);
    if (form.get(replacementkey1FieldId).value.toLocaleLowerCase() === 'yes' || form.get(replacementkey2FieldId).value.toLocaleLowerCase() === 'yes') {
      return true;
    }

    if (form.get(salesMaterialsIndFieldId)?.value) {
      form.get(salesMaterialsIndFieldId).setValue('');
      form.get(salesMaterialsIndFieldId).setErrors(null);
    } else {
      form.get(salesMaterialsIndFieldId).setErrors(null);
    }
    form.get(salesMaterialsIndFieldId).markAsUntouched();
    items.forEach((ele => {
      if (ele.hasReflexive) {
        ele.reflexiveQuestionAL.forEach((childQ => {
          if (form.get(childQ.fieldId)?.value) {
            this.resetControls(form, childQ.fieldId);
          }
        }))
      }
    }))
  }

  resetControls(form, fieldId): void {
    form.get(fieldId).setValue('');
    form.get(fieldId).setErrors(null);
    form.get(fieldId).markAsUntouched();
  }

}
