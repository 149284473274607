<ng-container *ngIf="type == 'POLICY_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header">
        <strong>{{name}}</strong>
      </span>
    </span>
  </span>
  <i [ngClass]="{'btn--link-disabled':disableForm}"  class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
</ng-container>

<ng-container *ngIf="type == 'POLICY_MOBILE_ICON'">
 <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <i [ngClass]="{'btn--link-disabled':disableForm}"  class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'POLICY_MOBILE_INFO' && name && name.length > 1">
  <div class="acrdn-sub-header-mobile">
    <strong>{{name}}</strong>
  </div>
</ng-container>

<ng-container *ngIf="type == 'AGENT_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value || gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value">
        <strong>{{gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value}} {{gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value}}</strong></span>
      <span class="divider" *ngIf="(gridForm.get(AI_CONST.secAgentNoQuesId)?.value || gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value) && (gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value ||
        gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value)">|</span>
      <span *ngIf="gridForm.get(AI_CONST.secAgentNoQuesId)?.value || gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value">
        {{gridForm.get(AI_CONST.secAgentNoQuesId)?.value}}{{gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value}}</span>
    </span>
  </span>
  <span class="right-header-container" [ngClass]="{'btn--link-disabled':disableForm}">
    <i  class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
    <span class="panel-percent" [ngClass]="{'invalid-split': !validPercentage || percentage?.indexOf('.') > -1}">
      {{ percentage }}
    </span>
  </span>
</ng-container>

<ng-container *ngIf="type == 'AGENT_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
     <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
     <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
     <span class="panel-percent" [ngClass]="{'invalid-split': !validPercentage || percentage?.indexOf('.') > -1}">
      {{ percentage }}
     </span>
     <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
   </span>
</ng-container>

<ng-container *ngIf="type == 'AGENT_MOBILE_INFO'">
  <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && (gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value || gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value)">
    <strong>{{gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value}} {{gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value}}</strong></div>
   <div *ngIf="grid.formLoadCompleted">
    <span *ngIf="gridForm.get(AI_CONST.secAgentNoQuesId)?.value || gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value">
      {{gridForm.get(AI_CONST.secAgentNoQuesId)?.value}}{{gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value}}</span>
   </div>
</ng-container>

<ng-container *ngIf="type == 'OWNER_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header">
        <strong>{{name}}</strong>
      </span>
    </span>
  </span>
  <span class="right-header-container">
    <i [ngClass]="{'btn--link-disabled': disableForm || (showOwner && index === 0)}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'OWNER_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
     <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
     <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
     <i  [ngClass]="{'btn--link-disabled': disableForm || (showOwner && index === 0)}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
   </span>
 </ng-container>

 <ng-container *ngIf="type == 'OWNER_MOBILE_INFO' && name && name.length > 1">
  <div class="acrdn-sub-header-mobile">
    <strong>{{name}}</strong>
  </div>
 </ng-container>

<ng-container *ngIf="type == 'INSURANCE_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value">
        <strong>{{gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value && gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value">|</span>
      <span class="acrdn-sub-header" *ngIf="gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value">
        <strong>{{gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value}}</strong></span>
    </span>
  </span>
  <span class="right-header-container">
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'INSURANCE_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
     <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
     <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
     <i  [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
   </span>
 </ng-container>

 <ng-container *ngIf="type == 'INSURANCE_MOBILE_INFO'">
  <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value">
    <strong>{{gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value}}</strong></div>
  <div *ngIf="gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value">
    <span>{{gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value}}</span>
  </div>
 </ng-container>

 <ng-container *ngIf="type == 'BENE_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header" *ngIf="name">
        <strong>{{name}}</strong>
      </span>
    </span>
  </span>
  <span class="right-header-container">
    <span class="panel-percent" [ngClass]="{'invalid-split': !validPercentage || percentage?.indexOf('.') > -1}">
      {{ percentage }}
    </span>
    <i [ngClass]="{'btn--link-disabled': disableForm || disableDelete }" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'BENE_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="panel-percent" [ngClass]="{'invalid-split': !validPercentage || percentage?.indexOf('.') > -1}">
      {{ percentage }}
    </span>
    <i [ngClass]="{'btn--link-disabled': disableForm || disableDelete }" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
   </span>
</ng-container>

<ng-container *ngIf="type == 'BENE_MOBILE_INFO' && name && name.length > 1">
  <div class="acrdn-sub-header-mobile" *ngIf="name">
    <strong>{{name}}</strong>
  </div>
</ng-container>
