import { Component, Output, Input, OnInit, EventEmitter, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-docusign-iframe',
  templateUrl: './docusign-iframe.component.html',
  styleUrls: ['./docusign-iframe.component.scss']
})
export class DocusignIframeComponent implements OnInit {

  @Input()
  docusignUrl: string;

  @Output() docuSignStatus: EventEmitter<string> = new EventEmitter();

  safeDocuSignUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.safeDocuSignUrl = this.sanitizer.bypassSecurityTrustResourceUrl( // NOSONAR
      this.sanitizer.sanitize(SecurityContext.URL, this.docusignUrl)); // NOSONAR
  }

  checkForSignatures(_event: any): void {
    try {
      const contentWindow = 'contentWindow';
      const sourceUrl = document.getElementById('iframe-container')[contentWindow]?.location?.href;
      if (sourceUrl?.indexOf('/thankyou?action=completed') > -1) {
        this.docuSignStatus.emit('ACCEPTED');
      } else if (sourceUrl?.indexOf('/thankyou?action=declined') > -1) {
        this.docuSignStatus.emit('DECLINED');
      }
    } catch (err: unknown) {
      // do nothing, when there is a url from different domain, it will throw error
    }
  }

}
