<section class="illustration-compliance-page page-content-container">
    <div class="questions--container">
      <h1>
        Illustration Information & Attachments
      </h1>

      <div class="loader-container" *ngIf="showLoader">
        <lfg-loader></lfg-loader>
      </div>

      <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
      <section>
        <form class="form-group" [formGroup]="illustrationComplianceForm" id="illustrationComplianceForm" method="post" *ngIf="illustrationComplianceData && illustrationComplianceData.length> 0">
          <ng-container class="form-control-parent" *ngFor="let data of illustrationComplianceData;">

            <div class="section-divider required" *ngIf="data.xmlTag == '/XML/MGeAPP/ApplicationDetails/VoiceSignAuthInd'"></div>

            <!-- Element for Line -->
            <div class="section-divider" *ngIf="data.controlTypeDesc == 'None' && data.subText == 'Line'">
            </div>

            <!-- element for header-->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && !data.subText && data.questionText?.indexOf(validIllustrationMsgText) === -1">
              <p [innerHtml]="data.questionText"></p>
            </div>

            <!-- element for subheader-->
            <div class="questions-subheader" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Subheader'">
              {{data.questionText}}
            </div>

            <!-- Question info-->
            <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText?.indexOf(validIllustrationMsgText) > -1 && showValidIllustrationMsg">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Question Info -->
            <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information' && data.questionText?.indexOf(validIllustrationMsgText) === -1 && data.questionText.toLowerCase().indexOf(WET_FORM_INFO.toLowerCase()) === -1">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!--Question Info-->
            <div class="question-info" *ngIf="data.questionText.toLowerCase().indexOf(WET_FORM_INFO.toLowerCase()) > -1">
              <div class="padbot20"></div>
              <div *ngIf="showWetFormInfo">
                <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
                <div class="section-divider"></div>
              </div>
            </div>

             <!-- Form element for radio question -->
            <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag!==illustrationMatchesKey"
            [ngClass]="{'radio--err': (illustrationComplianceForm.get(data.fieldId).touched && illustrationComplianceForm.get(data.fieldId).invalid)}">
              <span class="lfg-radio-section">
                <p class="question-radio_text">{{ data.questionText }}</p>
                <div class="custom-radio-button">
                  <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)"/>
                  <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                  <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                  <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                </div>
              </span>
              <lfg-error *ngIf="illustrationComplianceForm.get(data.fieldId).touched && illustrationComplianceForm.get(data.fieldId).invalid" message="Please select a valid option"></lfg-error>
            </div>


            <div class="lfg-radio" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag===illustrationMatchesKey"
				      [ngClass]="{'radio--err': (illustrationComplianceForm.get(data.fieldId).touched && (illustrationComplianceForm.get(data.fieldId).invalid))}">
                <p class="question-radio_text" [innerHtml]="data.questionText"></p>
                <div class="radio-btn">
                    <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" [required]="false" (change)="onRadioChange(data)"/>
                    <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                </div>
                <div class="radio-btn">
                    <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" [required]="false" (change)="onRadioChange(data)">
                    <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                </div>
                <lfg-error *ngIf="illustrationComplianceForm.get(data.fieldId).touched && illustrationComplianceForm.get(data.fieldId).invalid"
                  message="Please select a valid option"></lfg-error>
            </div>

            <div class="refresh-btn-container" *ngIf="data.xmlTag===illustrationMatchesKey && illustrationComplianceForm.get(data.fieldId).value">
              <a class="btn-link btn-refresh" [ngClass]="{'btn--link-disabled': disableForm}" id="refreshBtn" type="button" (click)="reset()">
                <i class="fas fa-redo refresh-icon" aria-hidden="true"></i> Clear answers above </a>
            </div>

            <!-- Form element for check box  -->
            <div class="form--control form--control__full-width checkbox-accrd" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.xmlTag!='/XML/AnswerSet/SignedIllustrationMatches' && data.display" [ngClass]="{'checkbox-control' : (illustrationComplianceForm.get(data.fieldId).value && openAccordion[data.xmlTag])}" >
              <div class="lfg-checkbox custom-checkbox">
                <span class="accrd-header">
                  <span class="checkbox-input-label-icon">
                    <span class="checkbox-input-label">
                      <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)" (click)="onCheckBoxClick($event, data)">
                      <label class="wrap wrap-check" for="{{ data.fieldId }}" [innerHTML]="data.questionText" [ngClass]="{'highlight-text': illustrationComplianceForm.get(data.fieldId).value}"></label>
                    </span>
                    <span class="file-status-icon desktop-icon" *ngIf="!isQuestionValid[data.xmlTag] && illustrationComplianceForm.get(data.fieldId).value && !openAccordion[data.xmlTag]"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
                  </span>
                  <span class="filename" *ngIf="illustrationComplianceForm.get(data.fieldId).value && isQuestionValid[data.xmlTag]">{{fileName[data.xmlTag]}}</span>
                </span>
                <span class="icon-container" *ngIf="(illustrationComplianceForm.get(data.fieldId).touched && illustrationComplianceForm.get(data.fieldId).value) || illustrationComplianceForm.get(data.fieldId).value ">
                  <span class="file-status-icon mobile-icon" *ngIf="!isQuestionValid[data.xmlTag] && illustrationComplianceForm.get(data.fieldId).value && !openAccordion[data.xmlTag]"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
                  <a (click)="toggleAccordionStatus(data.xmlTag)"><i class="fa arrow-button" [ngClass]="{'fa-chevron-down' : !openAccordion[data.xmlTag] , 'fa-chevron-up' : openAccordion[data.xmlTag]}" aria-hidden="true"></i></a>
                </span>
              </div>

              <!-- Form element for nested ques  -->
              <div *ngIf="data.hasReflexive == 'true' && illustrationComplianceForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'checkbox-nested-questions': checkIfQuestionDisplayed(data), 'hide-ques': !openAccordion[data.xmlTag]}">
                <ng-container *ngFor="let illustrationComplianceChild of data.reflexiveQuestionAL; index as addChildIndx">
                  <ng-container *ngIf="nestedQuestionCheckIllustrationCompliance(data, illustrationComplianceChild, parentAnswer)">

                    <!-- Question Text -->
                    <div class="questions-header" *ngIf="illustrationComplianceChild.controlTypeDesc == appConfig.fieldTypes.HDR && illustrationComplianceChild.subText != 'File' && illustrationComplianceChild.subText != 'Line' && illustrationComplianceChild.subText != 'Button'">
                      {{illustrationComplianceChild.questionText}}
                    </div>

                    <!-- Form element for Text  -->
                    <div class="form--control" *ngIf="illustrationComplianceChild.controlTypeDesc == appConfig.fieldTypes.TEXT">
                      <lfg-input-wrap>
                        <input lfgInput id="{{illustrationComplianceChild.fieldId}}" placeholder="{{ addCorrectPlaceHolder(illustrationComplianceChild, 'placeHolder') }}" name="{{ illustrationComplianceChild.fieldId }}" aria-live="off" type='text' formControlName='{{ illustrationComplianceChild.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="illustrationComplianceChild.maxLength" [required]="illustrationComplianceChild.required == 'true'">
                      </lfg-input-wrap>
                    </div>

                    <!-- Form element for input type file  -->
                    <div class="form--control" *ngIf="illustrationComplianceChild.controlTypeDesc == appConfig.fieldTypes.HDR && illustrationComplianceChild.subText == 'File'">
                      <label id="fileLabel" for="{{illustrationComplianceChild.fieldId}}">
                        <span class="visually-hidden">Label for the input</span>
                        <input id="{{illustrationComplianceChild.fieldId}}" type="file" accept=".pdf" (change)="onFileSelected($event, illustrationComplianceForm.get(illustrationComplianceChild.fieldId) , data.xmlTag, data)" name="{{ illustrationComplianceChild.fieldId }}" formControlName='{{ illustrationComplianceChild.fieldId }}' required="data.required == 'true'" [ngClass]="{'file-err': hasFileError[data.xmlTag]}">
                      </label>
                      <div class="file-info" *ngIf="!(hasFileError[data.xmlTag])">{{fileInfoMessage}}</div>
                      <!-- Get error message from service-->
                      <lfg-error *ngIf="hasFileError[data.xmlTag]" [message]="hasFileError[data.xmlTag]"></lfg-error>

                    </div>

                    <!-- view file button -->
                    <div class="form--control viewfile-button" *ngIf="illustrationComplianceChild.subText == 'Button' && illustrationComplianceChild.questionText == 'View File' " (click)="viewFile()">
                      <a [ngClass]="{disableLink: !fileName[data.xmlTag]}" *ngIf="!fileName[data.xmlTag]" >{{illustrationComplianceChild.questionText}}</a>
                      <a *ngIf="fileName[data.xmlTag]">{{fileName[data.xmlTag]}}</a>
                    </div>

                    <!-- element for line-->
                    <div class="section-divider" *ngIf="illustrationComplianceChild.controlTypeDesc == appConfig.fieldTypes.HDR && illustrationComplianceChild.subText == 'Line'">
                    </div>

                    <!-- element for remove button -->
                    <div class="form--control remove-button" *ngIf="illustrationComplianceChild.controlTypeDesc == appConfig.fieldTypes.HDR && illustrationComplianceChild.subText == 'Button' && illustrationComplianceChild.questionText !== 'View File'">

                      <a [ngClass]="{disableLink: !fileName[data.xmlTag] || disableForm}" (click)="openModal($event, data, true)"><i class="remove-icon fa fa-times-circle" [ngClass]="{disableIcon: !fileName[data.xmlTag]}" aria-hidden="true"></i>{{illustrationComplianceChild.questionText}}</a>
                    </div>
                  </ng-container>

                </ng-container>
              </div>
            </div>




          </ng-container>

        </form>
      </section>
    </div>

  </section>

  <lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" (closeModalPopup)="closeModalPopup(modalConfig)">
    <div modal-header>
      <div>
        <h2 class="lfg-color"><strong>{{"Are you sure?"}}</strong></h2>
      </div>
    </div>
    <div modal-body>
      <div> You are about to remove this attachment and clear all input data.</div>
    </div>
    <div modal-footer>
      <lfg-left-button-tray>
        <button class='btn' lfgDefaultButton (click)="removeAttachment()">Yes</button>
        <button class='btn' lfgBorderButton (click)="closeModalPopup(modalConfig)">No</button>
      </lfg-left-button-tray>
    </div>
  </lfg-modal-popup>
