import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ESignatureEmailTemplate, ElectronicSignatures, Message } from 'src/config/constants';
import { ESignatureTableConfig } from './e-signature-table-config';
import { PackageInfo, Recipient } from 'src/app/shared/models/casePage.model';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { LfgLoaderService } from '@ng/lfg-loader';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { timer } from 'rxjs/internal/observable/timer';
import { map } from 'rxjs/internal/operators/map';
import { Subscription } from 'rxjs';
import { CaseUtilsService } from 'src/app/shared/services/case-util-service';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/shared/validators/validator';
import { ModalSize } from '@ng/lfg-modal-popup';
import { confirmEmailValidator } from 'src/app/shared/validators/business-validator';
@Component({
  selector: 'app-electronic-signature',
  templateUrl: './electronic-signature.component.html',
  styleUrls: ['./electronic-signature.component.scss'],
})
export class ElectronicSignatureComponent implements OnInit {

  @Input()
  disableForm: boolean;

  @Output() activePacketUpdated: EventEmitter<PackageInfo> = new EventEmitter();
  @Output() packetCancelled: EventEmitter<string> = new EventEmitter();

  ELECTRONIC_SIGN_CONST = ElectronicSignatures;
  ESignatureEmailTemplate = ESignatureEmailTemplate;
  packets: PackageInfo[] = [];
  envelopeCreated = false;
  mailSentSuccessfully = false;

  tableData;
  tableDataLoaded = false;
  readMore = true;
  hasInPersonSigner = false;

  agentSignerInfo: Recipient;
  signerList: Recipient[];

  showLoader = 0;
  isTrustAvailable = false;
  displayProgressModal = false;
  activePacketStatus = '';
  activePacket: PackageInfo;
  progressPercentage = 0;
  timerSubscription: Subscription;
  errorOccured = false;
  noOfTimesStatusApiCalled = 0;
  filteredRecipients = [];

  Message = Message;
  modalConfig: any;
  emailExampleModalConfig: any;
  cancelModal: any;
  showCancelPackageMessage = false;

  agentEmail: string;
  resendEmailForm: FormGroup;
  resendModalFormCreated = false;
  subcription: Subscription;

  activeEnvelopeStatus = ['sent', 'created', 'completed', 'delivered'];

  constructor(
    private defaultPageLoadService: DefaultPageload, private agentService: AgentAndFirmService,
    private loaderService: LfgLoaderService, private tabService: TabDetailsService,
    private caseUtilsService: CaseUtilsService, private fb: FormBuilder,
    private cdr: ChangeDetectorRef) {
    // write code here
  }

  ngOnInit(): void {
    this.updateAgentInfo();
    this.getPackageData();
    this.defaultPageLoadService.logPageLoad();
    this.initializeModal();
  }

  closeModalPopup(modalConfig: any): any {
    modalConfig.state = false;
  }

  sendEmail(): void {
    this.showCancelPackageMessage = false;
    this.progressPercentage = 0;
    this.errorOccured = false;
    this.envelopeCreated = false;
    this.defaultPageLoadService.logButtonClick('send email');
    this.showPageLoader(true);
    this.defaultPageLoadService.createEnvelope().subscribe((response) => {
      if (response.data) {
        const transactionId = response.data.transaction_id;
        this.displayProgressModal = true;
        this.getTransactionStatusInIntervals(transactionId);
      } else {
        this.errorOccured = true;
      }
    }, (_err) => {
      this.errorOccured = true;
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  linkClicked(): void {
    this.readMore = !this.readMore;
  }

  refreshStatus(): void {
    this.errorOccured = false;
    this.mailSentSuccessfully = this.envelopeCreated = this.showCancelPackageMessage = false;
    this.defaultPageLoadService.logLinkClick('refresh');
    this.getPackageData();
  }

  resendPacket(): void {
    this.showCancelPackageMessage = false;
    this.createFormForResendEmailModal();
    this.defaultPageLoadService.logButtonClick('resend this packet');
  }

  resendEmails(): void {
    this.errorOccured = false;
    this.mailSentSuccessfully = false;
    this.showPageLoader(true);
    const signerListToUpdate = this.getUpdatedSignerList();
    this.defaultPageLoadService.resendEmail(this.activePacket?.envelopeId, signerListToUpdate).subscribe((res) => {
      if (res.responseStatus === 'SUCCESS' && res.data?.failureList?.length === 0) {
        this.mailSentSuccessfully = true;
      } else {
        this.errorOccured = true;
      }
    }, (_err) => {
      this.errorOccured = true;
    }).add(() => {
      this.modalConfig.state = false;
      setTimeout(() => {
        this.showPageLoader(false);
        this.getPackageData();
      }, 3000);
    });
  }

  cancelPacket(): void {
    this.showCancelPackageMessage = false;
    this.envelopeCreated = false;
    this.updateCancelModalStatus(false);
    this.showPageLoader(true);
    this.defaultPageLoadService.logButtonClick('cancel packet');
    if (this.activePacket) {
      this.defaultPageLoadService.voidEnvelope(this.activePacket?.envelopeId, 'Packet Cancelled').subscribe((res) => {
        if (res.responseStatus === 'SUCCESS' && res.data?.voided) {
          this.showCancelPackageMessage = true;
          this.getPackageData();
          if (this.hasInPersonSigner) {
            this.activePacket = undefined;
            this.activePacketUpdated.emit(this.activePacket);
            this.packetCancelled.emit('yes');
          }
        }
      }).add(() => {
        this.showPageLoader(false);
      });
    }
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      this.showLoader++;
      if (this.showLoader === 1) {
        this.loaderService.show();
      }
    } else {
      this.showLoader--;
      if (this.showLoader === 0) {
        this.loaderService.hide();
      }
    }
  }

  private getTransactionStatusInIntervals(transactionId: string): void {
    this.errorOccured = false;
    this.noOfTimesStatusApiCalled = 0;
    this.timerSubscription = timer(1000, 7000).pipe(
      map(() => {
        this.noOfTimesStatusApiCalled++;
        this.getTransactionStatus(transactionId);
      })
    ).subscribe();
  }

  private getTransactionStatus(transactionId: string): void {
    this.defaultPageLoadService.getTransactionStatus(transactionId).subscribe((response) => {
      if (response.data) {
        this.updateProgressStatus(response.data.status);
        if (this.progressPercentage === 100) {
          this.envelopeCreated = true;
          this.closeProgressOverlay();
          setTimeout(() => {
            this.getPackageData();
          }, 3000);
        }
        else if (this.progressPercentage === -1 || this.noOfTimesStatusApiCalled === 43) {
          this.errorOccured = true;
          this.closeProgressOverlay();
        }
      }
    });
  }

  private closeProgressOverlay(): void {
    this.displayProgressModal = false;
    this.timerSubscription.unsubscribe();
  }

  private updateProgressStatus(status: string): void {
    switch (status) {
      case 'STARTED':
        this.progressPercentage = 5;
        break;
      case 'FORMS_GEN_REQUESTED':
        this.progressPercentage = 30;
        break;
      case 'FORMS_GEN_COMPLETED':
        this.progressPercentage = 50;
        break;
      case 'ENVELOPE_ID_RQUESTED':
        this.progressPercentage = 75;
        break;
      case 'ENVELOPE_ID_GENERATED':
        this.progressPercentage = 100;
        break;
      case 'EXCEPTION_OCCURED':
        this.progressPercentage = -1;
        break;
      default:
        this.progressPercentage = -1;
        break;
    }
  }

  private getPackageData(): any {
    this.clearAllData();
    this.showPageLoader(true);
    this.defaultPageLoadService.getPackageDetails().subscribe((response) => {
      if (response.data) {
        let sortedSignerList;
        if (response.data.activePacket) {
          this.tableData = new ESignatureTableConfig().tableData;
          this.activePacketStatus = response.data.activePacket.envelopeStatus;
          sortedSignerList = this.caseUtilsService.sortListBySignerRole(response.data.activePacket?.recipients, false);
          this.tableData.data = this.filterRemoteSigners(sortedSignerList);
          this.hasInPersonSigners(sortedSignerList);
          this.tableDataLoaded = true;
          if (this.activeEnvelopeStatus.includes(this.activePacketStatus?.toLowerCase())) {
            this.activePacket = response.data.activePacket;
            this.activePacket.recipients = sortedSignerList;
            this.caseUtilsService.updateDisplayStatus(this.activePacket.recipients);
            this.packets.push(this.activePacket);
            this.activePacketUpdated.emit(this.activePacket);
          }
        } else {
          this.getRecipients();
        }
        if (response.data.cancelledPackets) {
          this.sortPacketsByDate(response.data.cancelledPackets);
          response.data.cancelledPackets.forEach(packets => {
            this.caseUtilsService.updateDisplayStatus(packets.recipients);
            packets.recipients = this.caseUtilsService.sortListBySignerRole(packets.recipients, false);
            this.packets.push(packets);
          });
        }
      } else {
        this.errorOccured = true;
      }
    }, (_err) => {
      this.errorOccured = true;
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private getRecipients(): void {
    this.showPageLoader(true);
    this.tableData = new ESignatureTableConfig().tableData;
    this.defaultPageLoadService.getRecipientsList().subscribe(response => {
      if (response?.data) {
        this.signerList = this.caseUtilsService.sortListBySignerRole(response.data, true);
        this.agentSignerInfo = this.caseUtilsService.getSignerByRole(response.data, 'Agent');
        this.agentSignerInfo.displayRole = 'Agent';
        this.updateTableData();
      }
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private updateTableData(): void {
    const signerListWithAgent = [...this.signerList];
    signerListWithAgent.push(this.agentSignerInfo);
    this.caseUtilsService.updateDisplayStatus(signerListWithAgent);
    this.tableData.data = this.filterRemoteSigners(signerListWithAgent);
    this.hasInPersonSigners(signerListWithAgent);
    this.tableDataLoaded = true;
  }

  private filterRemoteSigners(currentSignerList: Recipient[]): Recipient[] {
    return currentSignerList.filter((recipient) => {
      return recipient.eSignatureMethod?.toLowerCase() === 'remote';
    });
  }

  private hasInPersonSigners(currentSignerList: Recipient[]): void {
    this.hasInPersonSigner = currentSignerList.filter((recipient) => {
      return recipient.eSignatureMethod?.toLowerCase() === 'inperson';
    })?.length > 0;
  }

  openEmailExampleModal(): void {
    this.defaultPageLoadService.logLinkClick('view esignature invitation');
    this.emailExampleModalConfig.state = true;
  }

  private createFormForResendEmailModal(): void {
    this.clearForm();
    this.filteredRecipients = [];
    this.packets.forEach(packet => {
      if (packet.envelopeStatus?.toLowerCase() === 'sent' || packet.envelopeStatus?.toLowerCase() === 'created'
        || packet.envelopeStatus?.toLowerCase() === 'delivered') {
        const formControl = {};
        this.filteredRecipients = packet.recipients.filter((recipient) => {
          return recipient.docusignStatus?.toLowerCase() !== 'completed' && recipient.docusignStatus?.toLowerCase() !== 'created';
        });
        for (const recipient of this.filteredRecipients) {
          formControl[recipient.signerGuid + `_role`] = [];
          formControl[recipient.signerGuid + `_email`] = ['', [emailValidator()]];
          formControl[recipient.signerGuid + `_confirmEmail`] = ['', [emailValidator()]];
        }
        this.resendEmailForm = this.fb.group(formControl);
        this.modalConfig.state = true;
      }
    });
    this.resendModalFormCreated = true;
  }

  validateConfirmEmail(signerGuid: string): void {
    this.resendEmailForm?.get(signerGuid + `_confirmEmail`)?.setValidators(
      [Validators.required, emailValidator(), confirmEmailValidator(this.resendEmailForm.get(signerGuid + `_email`)?.value)]);
    this.resendEmailForm?.get(signerGuid + `_confirmEmail`)?.updateValueAndValidity();
  }

  onCheckboxChange(signerGuid: string): void {
    if (!this.resendEmailForm.get(signerGuid + `_role`)?.value) {
      this.clearField(signerGuid + `_email`);
      this.clearField(signerGuid + `_confirmEmail`);
    } else {
      this.cdr.detectChanges();
    }
  }

  private checkIfAnyRoleSelectedToUpdate(): boolean {
    let isSelected = false;
    if (this.resendEmailForm) {
      Object.keys(this.resendEmailForm?.controls).forEach(key => {
        if (key.indexOf('_role') > -1 && this.resendEmailForm.get(key)?.value) {
          isSelected = true;
        }
      });
    }
    return isSelected;
  }

  disableResendEmailButton(): boolean {
    return !this.checkIfAnyRoleSelectedToUpdate() || this.resendEmailForm?.status === 'INVALID';
  }

  private getUpdatedSignerList(): any[] {
    const signerList = [];
    Object.keys(this.resendEmailForm.controls).forEach((key) => {
      if (key.indexOf('_role') > -1 && this.resendEmailForm?.get(key)?.value) {
        const signerGuid = key.substring(0, key.indexOf('_'));
        const correctedEmail = this.resendEmailForm.get(signerGuid + '_email')?.value;
        signerList.push({ signerGuid, correctedEmail });
      }
    });
    return signerList;
  }

  private clearForm(): void {
    this.subcription?.unsubscribe();
    this.resendEmailForm?.reset();
    this.resendModalFormCreated = false;
  }

  private clearField(fieldId: string): void {
    this.resendEmailForm.get(fieldId)?.reset();
    this.resendEmailForm.get(fieldId)?.setErrors(null);
    this.resendEmailForm.get(fieldId)?.markAsUntouched();
  }

  private clearAllData(): void {
    this.packets = [];
    this.tableData = this.activePacket = undefined;
    this.activePacketStatus = '';
    this.tableDataLoaded = false;
    this.activePacketUpdated.emit(this.activePacket);
  }

  private initializeModal(): void {
    this.modalConfig = {
      header: true,
      state: false,
      footer: true
    };
    this.cancelModal = {
      header: true,
      state: false,
      footer: true,
      size: ModalSize.small
    };
    this.emailExampleModalConfig = {
      header: true,
      state: false,
      footer: true
    };
  }

  updateCancelModalStatus(open: boolean): void {
    this.cancelModal.state = open;
  }

  private sortPacketsByDate(packets: PackageInfo[]): void {
    packets.sort((obj1, obj2) => {
      const date1 = new Date(obj1.envelopeCreatedDate);
      const date2 = new Date(obj2.envelopeCreatedDate);
      return date1.getTime() - date2.getTime() > 0 ? -1 : 1;
    });
  }

  private updateAgentInfo(): void {
    this.agentEmail = this.agentService.getAgentPrimaryEmail();
  }
}
