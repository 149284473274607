export class SideNavConfig {
  tabInfoList: TabInformation[] = [
    {
      name: TAB.CASE_INFO,
      isDefault: true,
      isEnable: true,
      analyticsPageName: 'MGeAPP - case information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
    },
    /*{
      name: TAB.ILLUSTRATION,
      isDefault: false,
      isEnable: false,
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },*/
    {
      name: TAB.POLICY_INFO_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Policy Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.PRIMARY_INSURED_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Insured Info',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.OWNER_INFO,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Owner Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.PRIMARY_BENEFICIARY_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Primary Beneficiary Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.CONTINGENT_BENEFICIARY_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Contingent Beneficiary Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.PREMIUM_INFO_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Premium & Billing Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.EXISTING_INS_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Existing & Pending Insurance Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.DCA_PREMIUM_ALLOCATION_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - DCA & Premium Allocation',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.UNINTENDED_LAPSE_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Protection Against Unintended Lapse',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.TEMPORARY_LIFE_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Temporary Life Insurance Agreement',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
      excludeState: ['KS', 'MT'],
    },
    {
      name: TAB.LIFE_SUITABILITY_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Life Suitability',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.LTC_REPLACEMENT_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - LTC Replacement',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.LTC_SUITABILITY_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - LTC Suitability',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.WET_SIGN_COLLECTION_INFO_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Wet Signature Collection Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      includeState: ['CA']
    },
    {
      name: TAB.ILLUSTRATION_COMPLIANCE,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Illustration Compliance',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.AGENT_INFO,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Agent Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.AGENT_REPORT_MG,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Agent Report',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.APPLICATION_SUMMARY,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Summary Information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.VALIDATE_AND_LOCK,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Validate and Lock',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.MG],
    },
    {
      name: TAB.SIGNATURE_METHOD,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Signature Method',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
    },
    {
      name: TAB.WET_SIGNATURE_INSTRUCTION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Wet Signature Instructions',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
    },
    {
      name: TAB.E_SIGNATURE_INSTRUCTION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - eSignature instructions',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
    },
    {
      name: TAB.CONSENT_PAGE_PI,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - primary insured',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_INSURED_OWNER,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - insured owner',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
    },
    {
      name: TAB.CONSENT_PAGE_ADDITIONAL_INSURED,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - additional insured',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_INSURED_B,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - additional insured co-owner',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_OWNER,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - owner',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_COOWNER,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - co-owner',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_COOWNER2,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - co-owner 2',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_COOWNER3,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - co-owner 3',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_PRINCIPAL,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - principal',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_PAYOR,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - payor',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_AGENT,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Term of use - agent',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.ELECTRONIC_SIGNATURE,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Electronic Signature',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
    },
    {
      name: TAB.REVIEW_AND_SUBMIT,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'MGeAPP - Review and submit',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
    }
  ];
}

export const TAB = {
  CASE_INFO: 'Case Information',
  POLICY_INFO_MG: 'Policy Information',
  PRIMARY_INSURED_MG: 'Proposed Insured Information',
  OWNER_INFO: 'Owner Information',
  PRIMARY_BENEFICIARY_MG: 'Primary Beneficiary Information',
  CONTINGENT_BENEFICIARY_MG: 'Contingent Beneficiary Information',
  PREMIUM_INFO_MG: 'Premium & Billing Information',
  EXISTING_INS_MG: 'Existing & Pending Insurance Information',
  DCA_PREMIUM_ALLOCATION_MG: 'DCA & Premium Allocation',
  UNINTENDED_LAPSE_MG: 'Protection Against Unintended Lapse',
  TEMPORARY_LIFE_MG: 'Temporary Life Insurance Agreement',
  LIFE_SUITABILITY_MG: 'Life Suitability',
  LTC_REPLACEMENT_MG: 'LTC Replacement',
  LTC_SUITABILITY_MG: 'LTC Suitability',
  ILLUSTRATION_COMPLIANCE: 'Illustration Information & Attachments',
  AGENT_INFO: 'Agent Information',
  WET_SIGN_COLLECTION_INFO_MG: 'Wet Signature Collection Information',
  AGENT_REPORT_MG: 'Agent Report',
  APPLICATION_SUMMARY: 'Summary Information',
  VALIDATE_AND_LOCK: 'Validate and Lock',
  SIGNATURE_METHOD: 'Signature Method',
  ELECTRONIC_SIGNATURE: 'Electronic Signature',
  E_SIGNATURE_INSTRUCTION: 'eSignature instructions',
  WET_SIGNATURE_INSTRUCTION: 'Wet Signature Instructions',
  CONSENT_PAGE_PI: 'Term of use - primary insured',
  CONSENT_PAGE_INSURED_OWNER: 'Term of use - insured owner',
  CONSENT_PAGE_ADDITIONAL_INSURED: 'Term of use - additional insured',
  CONSENT_PAGE_INSURED_B: 'Term of use - additional insured co-owner',
  CONSENT_PAGE_OWNER: 'Term of use - owner',
  CONSENT_PAGE_COOWNER: 'Term of use - co-owner',
  CONSENT_PAGE_COOWNER2: 'Term of use - co-owner 2',
  CONSENT_PAGE_COOWNER3: 'Term of use - co-owner 3',
  CONSENT_PAGE_AGENT: 'Term of use - agent',
  CONSENT_PAGE_PRINCIPAL: 'Term of use - principal',
  CONSENT_PAGE_PAYOR: 'Term of use - payor',
  REVIEW_AND_SUBMIT: 'Review and submit',

  ILLUSTRATION: 'Illustration Page',
};

export class TabInformation {
  name: string;
  isDefault: boolean;
  isEnable: boolean;
  analyticsPageName?: string;
  displayConditionBasedOnProduct?: string[];
  displayConditionBasedOnProductCode?: string[];
  status: TabStatus;
  includeState?: string[];
  excludeState?: string[];
  isFormBasedTab?: boolean;
}

export class TabStatus {
  visited: boolean;
  errorExists: boolean;
  isLocked: boolean;
}

export const ProductType = {
  TERM: 'term',
  UL: 'universal life',
  VUL: 'variable universal life',
  IUL: 'indexed universal life',
  MG: 'long term care'
};

export const ProductCodes = {
  TERMACCEL: 'termaccel',
  LIFEELEMENT: 'lincoln lifeelements level term',
  // IUL Products
  WEALTHACCELERATE2022: 'lincoln wealthaccelerate indexed ul 2022',
  WEALTHPRESERVESIUL2022: 'lincoln wealthpreserve 2 survivorship iul 2022',
  WEALTHPRESERVE2IUL2020: 'lincoln wealthpreserve 2 indexed ul 2020',
  WEALTHACCUMULATEIUL2020: 'lincoln wealthaccumulate 2 indexed ul 2020',
  // VUL Products
  ASSETEDGEVUL2022: 'lincoln assetedge vul 2022',
  SVULONE2021: 'lincoln svulone 2021',
  VULONE2021: 'lincoln vulone 2021',
  ASSETEDGEVUL2020: 'lincoln assetedge vul 2020',
  ASSETEDGEEXEC2020: 'lincoln assetedge exec vul 2020',
  MONEYGUARD2022: 'MoneyGuard Market Advantage 2022',
  MGMA22: 'lincoln moneyguard market advantage 2022 (full application)',
  MGMA24: 'lincoln moneyguard market advantage 2024 (full application)',
  MGMA: 'lincoln moneyguard market advantage (full application)',
  MGFA: 'lincoln moneyguard fixed advantage (full application)',
  MGFA25: 'lincoln moneyguard fixed advantage 2025 (full application)',
};
