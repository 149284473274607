export class ESignatureTableConfig {
    tableData = {
      config: {
        tableId: 'eticket-dbResult-table',
        tableClass: 'eticket-dbResult-table',
        sorting: false,
        pagination: true,
        rowsPerPage: 10,
        currentPage: 0,
        tableFoot: false,
        defaultSortOrder: 'desc',
        clickSortOrder: 'asc',
        handleSubDetailRow: false,
        noRecordsText: 'No records found'
      },
      column: [
        {
          displayText: 'NAME',
          columnKey: 'name',
          columnFlag: 'name',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        },
        {
            displayText: 'ROLE',
            columnKey: 'type',
            columnFlag: 'type',
            isAction: 'noaction',
            pipeName: null,
            type: 'htmlText',
            sorting: true,
            align: 'left',
            classNameTh: 'agent-table-th_info',
            classNameTd: 'agent-table-td_info'
          },
        {
          displayText: 'PHONE',
          columnKey: 'phone',
          columnFlag: 'phone',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        },
        {
          displayText: 'EMAIL',
          columnKey: 'email',
          columnFlag: 'email',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        },
        {
          displayText: 'ESIGN METHOD',
          columnKey: 'eSignMethod',
          columnFlag: 'eSignMethod',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        },
        {
          displayText: 'STATUS',
          columnKey: 'status',
          columnFlag: 'status',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'agent-table-th_info',
          classNameTd: 'agent-table-td_info'
        }
      ],
      data: [ ]
    };
  }
