import { Injectable } from '@angular/core';
import { AnalyticsEventData, AnalyticsService } from '@ng/lfg-common-utilities';
import { AgentAndFirmService } from './agent-and-firm.service';

@Injectable({
  providedIn: 'root',
})
export class AppAnalyticsService {
  constructor(private analyticsService: AnalyticsService, private agentService: AgentAndFirmService) { }

  logLinkEvents(eventName: string, eventType: string): void {
    this.logEvents(eventName, eventType, 'link');
  }

  logButtonEvents(eventName: string, eventType: string): void {
    this.logEvents(eventName, eventType, 'button');
  }

  logPageView(pageName: string): void {
    this.analyticsService.setUTagProperty('page_l1', 'secure');
    this.analyticsService.setUTagProperty('page_l2', 'producer');
    this.analyticsService.setUTagProperty('page_l3', 'Life MGeAPP');
    this.analyticsService.setUTagProperty('page_l4', pageName);
    this.analyticsService.setUTagProperty('user_id', this.agentService.getAgentProfile()?.proxyGuid);
    this.analyticsService.setUTagProperty('page_security', 'secure');
    this.analyticsService.triggerUTagCollection();
  }

  private logEvents(eventName: string, eventType: string, eventAction: string): void {
    this.logAnalytics({
      eventAction, eventName, eventType, eventStatus: 'complete',
    });
  }

  private logAnalytics(data: AnalyticData): void {
    const analyticsEventData = this.getAnalyticsEventData(data);
    this.analyticsService.link(analyticsEventData);
  }

  private getAnalyticsEventData(data: AnalyticData): AnalyticsEventData {
    const analyticsEventData = new AnalyticsEventData();
    analyticsEventData.event_type = data.eventType;
    analyticsEventData.event_status = data.eventStatus;
    analyticsEventData.event_action = data.eventAction;
    analyticsEventData.event_name = data.eventName;
    return analyticsEventData;
  }
}

class AnalyticData {
  eventAction: string;
  eventName: string;
  eventType?: string;
  eventStatus?: string;
  eventProduct?: string;
  extraEventInfo?: string;
}
