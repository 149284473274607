import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { PageJsonObject, Question } from 'src/app/shared/models/casePage.model';
import { SummaryConstant } from 'src/config/constants';
import { SummaryTableConfig } from './summary-table-config';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';


@Component({
  selector: 'app-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.scss'],
})
export class ApplicationSummaryComponent implements OnInit {

  @Input() questions: PageJsonObject;

  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  tableData;
  tableDataLoaded = false;
  summaryData: Question[] = [];
  dataMap: Map<string, Question[]> = new Map<string, Question[]>();

  constructor(private utilsService: UtilsService, private defaultPageLoadService: DefaultPageload) { }

  ngOnInit(): void {
    this.tableData = new SummaryTableConfig().tableData;
    this.dataMap.clear();
    this.updateFormStatus();
    this.reArrangeData();
    this.getAgentInformation();
    this.defaultPageLoadService.logPageLoad();
  }

  private reArrangeData(): void {
    this.summaryData = this.questions?.pages[0]?.questions;
    this.summaryData?.forEach((ques) => {
      if (ques.notes && ques.notes.indexOf('col') > -1) {
        if (this.dataMap.get(ques.notes)) {
          this.setData(ques);
        } else {
          ques.question_answer = this.getAnswer(ques.subText, ques.question_answer);
          this.dataMap.set(ques.notes, [ques]);
        }
      }
    });
  }

  setData(ques): void {
    if (!this.checkIfQuestionPresent(ques.notes, ques.subText)) {
      ques.question_answer = this.getAnswer(ques.subText, ques.question_answer);
      if (ques.xmlTag === '/XML/Client/AppDetails/DriverLicenseState') {
        ques.question_answer = this.updateNotApplicableAnswer(ques.question_answer);
      }
      if (ques.xmlTag === '/XML/Client/CRMDetails/StateOfBirth') {
        ques.question_answer = this.updateNotApplicableAnswerStateOfBirth(ques.question_answer);
      }
      this.dataMap.get(ques.notes).push(ques);
    }
  }

  updateNotApplicableAnswer(quesAnswer): string {
    let ansValue = '';
    this.summaryData.forEach((questObj) => {
      if ((questObj.question_answer === '' || questObj.question_answer === 'No') && questObj.xmlTag === '/XML/Client/AppDetails/DriverLicenseNoneChecked') {
        ansValue = 'N/A';
      }
      if (questObj.question_answer !== '' && questObj.question_answer !== 'No' && (questObj.xmlTag === '/XML/Client/AppDetails/DriverLicenseNoneChecked')) {
        ansValue = quesAnswer;
      }
    });
    return ansValue;
  }

  updateNotApplicableAnswerStateOfBirth(quesAnswer): string {
    let ansValue = '';
    this.summaryData.forEach((questObj) => {
      if ((questObj.question_answer === '') && questObj.xmlTag === '/XML/Client/CRMDetails/StateOfBirth') {
        ansValue = 'N/A';
      }
      if (questObj.question_answer !== '' && (questObj.xmlTag === '/XML/Client/CRMDetails/StateOfBirth')) {
        ansValue = quesAnswer;
      }
    });
    return ansValue;
  }

  private getAnswer(type: string, quesAns: string): string {
    if (type?.toLowerCase() === 'name') {
      return this.getInsuredName();
    } else if (type?.toLowerCase() === 'address2') {
      return this.getInsuredAddress2();
    } else if (type?.toLowerCase() === 'address3') {
      return this.getInsuredAddress3();
    }else if (type?.toLowerCase() === 'age') {
      return this.getInsuredAgeDetails();
    } else {
      return quesAns || 'N/A';
    }
  }

  private checkIfQuestionPresent(colNo: string, type: string): boolean {
    const questionAdded = this.dataMap.get(colNo).filter((ques) => {
      return ques.subText?.toLowerCase() === type.toLowerCase() &&
        ques.subText?.toLowerCase() !== 'info';
    });
    return questionAdded.length > 0;
  }

  private getInsuredName(): string {
    let name = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'name') {
        name = name + ques.question_answer + ' ';
      }
    });
    return name || 'N/A';
  }

  private getInsuredAddress2(): string {
    let address = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'address2') {
        address = address + ques.question_answer + ' ';
      }
    });
    return address;
  }

  private getInsuredAddress3(): string {
    let address = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'address3') {
        if (ques.questionText === 'Zip') {
          ques.question_answer = (ques.question_answer?.length === 9)
                                ? ques.question_answer?.substring(0, 5) + '-' + ques.question_answer?.substring(5, 9)
                                : ques.question_answer ;
        }
        address = address + (ques.questionText === 'State' ? ques.question_answer : this.utilsService.toTitleCase(ques.question_answer)) + ' ';
        if (ques.questionText?.toLowerCase() === 'city' && ques.question_answer) {
          address = address.trim() + ', ';
        }
      }
    });
    return address;
  }

  private getInsuredAgeDetails(): string {
    let ageDetails = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'age') {
        ques.question_answer = ques.questionText?.toLowerCase().indexOf('date of birth') > -1
                              ? this.utilsService.formatDateToMMDDYYYY(ques.question_answer)
                              : ques.question_answer;
        ageDetails = ageDetails + ques.questionText + ' ' + '<strong>' + ques.question_answer + '</strong>' + ' | ';
      }
    });
    return ageDetails ? ageDetails?.substring(0, ageDetails.lastIndexOf(' | ')) : 'N/A';
  }

  private updateFormStatus(): void {
    const summaryInfoObj = {
      formValid: true,
      formHasNoErrors: true,
      questions: this.questions,
      formId: 'summaryInfo',
      gridForm: false,
      isFormChange: false,
    };
    this.formStatus.emit(summaryInfoObj);
  }

  private getAgentInformation(): void {
    const agentInfo = this.geAgentInfo();
    if (agentInfo && agentInfo.length > 0) {
      this.tableData?.data?.push(...agentInfo);
    }
    this.tableDataLoaded = true;
  }

  private geAgentInfo(): any[] {
    const agentList = [];
    let agentEmail = 'N/A';
    let agentPhone = 'N/A';
    const gridAnswer = this.questions?.pages[0]?.gridAnswers;
    gridAnswer?.forEach((ans) => {
      const agentName = (ans[SummaryConstant.agentFirstName] + ' ' + ans[SummaryConstant.agentLastName])?.trim();
       agentEmail = ans[SummaryConstant.agentEmail] || 'N/A';
       agentPhone = ans[SummaryConstant.agentPhone] || 'N/A';
      if (agentName) {
        agentList.push({ agentName, agentEmail, agentPhone });
      }
    });
    return agentList;
  }

  getagentInfoObj(agentName, agentEmail, agentPhone): any {
    if (agentName.trim().length > 0 || agentPhone.trim().length > 0 || agentEmail.trim().length > 0) {
      return { agentName, agentEmail, agentPhone };
    }
  }
}
