import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  AgentProfile,
  QuestionOption,
  ResponseObject,
} from '../models/casePage.model';
import { ServiceInvoker } from './service-invoker';
import { DelegationService } from './delegation.service';

@Injectable({
  providedIn: 'root'
})
export class AgentAndFirmService {
  firmListMap: Map<string, any> = new Map<string, any>();
  agentNpnMap: Map<string, any> = new Map<string, any>();
  agentProfile: AgentProfile;
  caseManagerEmail: string;
  agentPrimaryEmail: string;
  isAgentsValidated: boolean = false;
  fetchAgentProfileCompletionSubj = new Subject();
  agentLast4Digit: string;
  agentFirstName: string;
  agentLastName: string;

  constructor(private serviceInvoker: ServiceInvoker, private delegationService: DelegationService) { }

  getFirmList(agentSSN: string, agentLastName: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_FIRM_LIST', null, {
      agentSSN,
      agentLastName
    });
  }

  getRedirectUrl(idTokenUserInfo: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('WHOLESALER_REDIRECT', null, {idTokenUserInfo});
  }

  createDropDownFromFirmList(response: ResponseObject, quesOptions: QuestionOption[]): QuestionOption[] {
    if (response?.data?.firmList) {
      response.data.firmList.forEach((firm) => {
        const quesOpt: QuestionOption = {
          value: firm.firmId+'{47}'+firm.firmName,
          description: firm.firmName,
          label: firm.firmName,
        };
        quesOptions.push(quesOpt);
      });
    }
    return quesOptions;
  }

  saveFirmResponse(ssn: string, quesOptions: any): void {
    this.firmListMap.set(ssn, quesOptions);
  }

  getFirmResponse(ssn: string): any {
    return this.firmListMap.get(ssn);
  }

  saveAgentNpnResp(ssn: string, npn: any): void {
    this.agentNpnMap.set(ssn, npn);
  }

  getAgentNpnResp(ssn: string): any {
    return this.agentNpnMap.get(ssn);
  }


  getAgentDetails(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_AGENT_DETAILS');
  }

  getAgentProfile(): AgentProfile {
    return this.agentProfile;
  }

  setAgentProfile(agentProfile: AgentProfile): void {
    this.agentProfile = agentProfile;
    this.agentProfile.firstName = agentProfile?.firstName?.trim();
    this.agentProfile.lastName = agentProfile?.lastName?.trim();
  }

  getCaseManagerEmail(): string {
    return this.caseManagerEmail;
  }

  setCaseManagerEmail(email: string): void {
    this.caseManagerEmail = email;
  }

  getAgentPrimaryEmail(): string {
    return this.agentPrimaryEmail;
  }

  setAgentPrimaryEmail(email: string): void {
    this.agentPrimaryEmail = email;
  }

  clearSavedData(): void {
    this.firmListMap.clear();
    this.agentNpnMap.clear();
    this.agentProfile = undefined;
    this.caseManagerEmail = undefined;
    this.agentPrimaryEmail = undefined;
    this.isAgentsValidated = undefined;
    this.agentLast4Digit = undefined;
    this.agentFirstName = undefined;
    this.agentLastName = undefined;
  }

  setIsAgentsValidated(validity: boolean): void {
    this.isAgentsValidated = validity;
  }

  getIsAgentValidated(): boolean {
    return this.isAgentsValidated;
  }

  fetchAgentProfile(): void {
    let isCallSuccess = 'failure';
    if (!this.agentProfile) {
      this.getAgentDetails().subscribe((agentInfo) => {
        if (agentInfo.data) {
          this.setAgentProfile(agentInfo.data);
          this.delegationService.setLoggedInUserAccess(agentInfo.data);
          isCallSuccess = 'success';
        } else if (agentInfo.error?.errorMsg === 'USER_NOT_AUTHORIZED') {
          isCallSuccess = '403';
        } else if (agentInfo.error) {
          isCallSuccess = '500';
        }
      }, (_err) => {
        isCallSuccess = '500';
      }).add(() => {
        this.fetchAgentProfileCompletionSubj.next(isCallSuccess);
      });
    } else {
      this.fetchAgentProfileCompletionSubj.next('success');
    }
  }

  getAgentLast4DigitSSN(): string {
    return this.agentLast4Digit;
  }

  setAgentLast4DigitSSN(ssn: string, islast4Digit?: boolean): void {
    if (ssn?.length >= 9) {
      this.agentLast4Digit = ssn.substring(5, 9);
    } else {
      this.agentLast4Digit = '';
    }
    if (islast4Digit) {
      this.agentLast4Digit = ssn;
    }
  }

  setAgentFirstName(firstName: string): void {
    this.agentFirstName = firstName;
  }

  getAgentFirstName(): string {
    return this.agentFirstName;
  }

  setAgentLastName(lastName: string): void {
    this.agentLastName = lastName;
  }

  getAgentLastName(): string {
    return this.agentLastName;
  }

  getAgentFullName(): string {
    const agentFN = this.agentFirstName ?? '';
    const agentLN = this.agentLastName ?? '';
    return (agentFN + ' ' + agentLN).trim();
  }


}
