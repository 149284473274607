import { Injectable } from "@angular/core";
import { Products } from "./constants";

@Injectable({
    providedIn: 'root'
})
export class PolicyProjectionConfig {
    yearDurationMap: YearDurationConfig[] = [
        {
            productMrktName: Products.MGFA,
            ageDurationMap: AgeDurationMapMGFA
        },
        {
            productMrktName: Products.MGFA25,
            ageDurationMap: AgeDurationMapMGFA
        },
        {
            productMrktName: Products.MGII17,
            ageDurationMap: AgeDurationMapMGII
        },
        {
            productMrktName: Products.MGMA24,
            ageDurationMap: AgeDurationMapMGMA
        },
        {
            productMrktName: Products.MGMA22,
            ageDurationMap: AgeDurationMapMGMA
        },
        {
            productMrktName: Products.MGMA,
            ageDurationMap: AgeDurationMapMGMA
        }
    ];
}

const AgeDurationMapMGFA = [
    {
        "issueAge": "40",
        "payDuration": "10"
    },
    {
        "issueAge": "41",
        "payDuration": "10"
    },
    {
        "issueAge": "42",
        "payDuration": "10"
    },
    {
        "issueAge": "43",
        "payDuration": "10"
    },
    {
        "issueAge": "44",
        "payDuration": "10"
    },
    {
        "issueAge": "45",
        "payDuration": "10"
    },
    {
        "issueAge": "46",
        "payDuration": "10"
    },
    {
        "issueAge": "47",
        "payDuration": "10"
    },
    {
        "issueAge": "48",
        "payDuration": "10"
    },
    {
        "issueAge": "49",
        "payDuration": "10"
    },
    {
        "issueAge": "50",
        "payDuration": "10"
    },
    {
        "issueAge": "51",
        "payDuration": "10"
    },
    {
        "issueAge": "52",
        "payDuration": "10"
    },
    {
        "issueAge": "53",
        "payDuration": "10"
    },
    {
        "issueAge": "54",
        "payDuration": "10"
    },
    {
        "issueAge": "55",
        "payDuration": "10"
    },
    {
        "issueAge": "56",
        "payDuration": "10"
    },
    {
        "issueAge": "57",
        "payDuration": "10"
    },
    {
        "issueAge": "58",
        "payDuration": "10"
    },
    {
        "issueAge": "59",
        "payDuration": "10"
    },
    {
        "issueAge": "60",
        "payDuration": "10"
    },
    {
        "issueAge": "61",
        "payDuration": "10"
    },
    {
        "issueAge": "62",
        "payDuration": "10"
    },
    {
        "issueAge": "63",
        "payDuration": "10"
    },
    {
        "issueAge": "64",
        "payDuration": "10"
    },
    {
        "issueAge": "65",
        "payDuration": "10"
    },
    {
        "issueAge": "66",
        "payDuration": "10"
    },
    {
        "issueAge": "67",
        "payDuration": "10"
    },
    {
        "issueAge": "68",
        "payDuration": "10"
    },
    {
        "issueAge": "69",
        "payDuration": "10"
    },
    {
        "issueAge": "70",
        "payDuration": "10"
    },
    {
        "issueAge": "71",
        "payDuration": "10"
    },
    {
        "issueAge": "72",
        "payDuration": "10"
    },
    {
        "issueAge": "73",
        "payDuration": "9"
    },
    {
        "issueAge": "74",
        "payDuration": "8"
    },
    {
        "issueAge": "75",
        "payDuration": "7"
    },
    {
        "issueAge": "76",
        "payDuration": "6"
    },
    {
        "issueAge": "77",
        "payDuration": "5"
    },
    {
        "issueAge": "78",
        "payDuration": "4"
    },
    {
        "issueAge": "79",
        "payDuration": "3"
    },
    {
        "issueAge": "80",
        "payDuration": "2"
    }
];

const AgeDurationMapMGMA = [
    {
        "issueAge": "30",
        "payDuration": "46"
    },
    {
        "issueAge": "31",
        "payDuration": "45"
    },
    {
        "issueAge": "32",
        "payDuration": "44"
    },
    {
        "issueAge": "33",
        "payDuration": "43"
    },
    {
        "issueAge": "34",
        "payDuration": "42"
    },
    {
        "issueAge": "35",
        "payDuration": "41"
    },
    {
        "issueAge": "36",
        "payDuration": "40"
    },
    {
        "issueAge": "37",
        "payDuration": "39"
    },
    {
        "issueAge": "38",
        "payDuration": "38"
    },
    {
        "issueAge": "39",
        "payDuration": "37"
    },
    {
        "issueAge": "40",
        "payDuration": "36"
    },
    {
        "issueAge": "41",
        "payDuration": "35"
    },
    {
        "issueAge": "42",
        "payDuration": "34"
    },
    {
        "issueAge": "43",
        "payDuration": "33"
    },
    {
        "issueAge": "44",
        "payDuration": "32"
    },
    {
        "issueAge": "45",
        "payDuration": "31"
    },
    {
        "issueAge": "46",
        "payDuration": "30"
    },
    {
        "issueAge": "47",
        "payDuration": "29"
    },
    {
        "issueAge": "48",
        "payDuration": "28"
    },
    {
        "issueAge": "49",
        "payDuration": "27"
    },
    {
        "issueAge": "50",
        "payDuration": "26"
    },
    {
        "issueAge": "51",
        "payDuration": "25"
    },
    {
        "issueAge": "52",
        "payDuration": "24"
    },
    {
        "issueAge": "53",
        "payDuration": "23"
    },
    {
        "issueAge": "54",
        "payDuration": "22"
    },
    {
        "issueAge": "55",
        "payDuration": "21"
    },
    {
        "issueAge": "56",
        "payDuration": "20"
    },
    {
        "issueAge": "57",
        "payDuration": "19"
    },
    {
        "issueAge": "58",
        "payDuration": "18"
    },
    {
        "issueAge": "59",
        "payDuration": "17"
    },
    {
        "issueAge": "60",
        "payDuration": "16"
    },
    {
        "issueAge": "61",
        "payDuration": "15"
    },
    {
        "issueAge": "62",
        "payDuration": "15"
    },
    {
        "issueAge": "63",
        "payDuration": "15"
    },
    {
        "issueAge": "64",
        "payDuration": "15"
    },
    {
        "issueAge": "65",
        "payDuration": "15"
    },
    {
        "issueAge": "66",
        "payDuration": "15"
    },
    {
        "issueAge": "67",
        "payDuration": "15"
    },
    {
        "issueAge": "68",
        "payDuration": "15"
    },
    {
        "issueAge": "69",
        "payDuration": "15"
    },
    {
        "issueAge": "70",
        "payDuration": "15"
    }
];

const AgeDurationMapMGII = [
    {
        "issueAge": "40",
        "payDuration": "25"
    },
    {
        "issueAge": "41",
        "payDuration": "24"
    },
    {
        "issueAge": "42",
        "payDuration": "23"
    },
    {
        "issueAge": "43",
        "payDuration": "22"
    },
    {
        "issueAge": "44",
        "payDuration": "21"
    },
    {
        "issueAge": "45",
        "payDuration": "20"
    },
    {
        "issueAge": "46",
        "payDuration": "19"
    },
    {
        "issueAge": "47",
        "payDuration": "18"
    },
    {
        "issueAge": "48",
        "payDuration": "17"
    },
    {
        "issueAge": "49",
        "payDuration": "16"
    },
    {
        "issueAge": "50",
        "payDuration": "15"
    },
    {
        "issueAge": "51",
        "payDuration": "14"
    },
    {
        "issueAge": "52",
        "payDuration": "13"
    },
    {
        "issueAge": "53",
        "payDuration": "12"
    },
    {
        "issueAge": "54",
        "payDuration": "11"
    },
    {
        "issueAge": "55",
        "payDuration": "10"
    },
    {
        "issueAge": "56",
        "payDuration": "10"
    },
    {
        "issueAge": "57",
        "payDuration": "10"
    },
    {
        "issueAge": "58",
        "payDuration": "10"
    },
    {
        "issueAge": "59",
        "payDuration": "10"
    },
    {
        "issueAge": "60",
        "payDuration": "10"
    },
    {
        "issueAge": "61",
        "payDuration": "10"
    },
    {
        "issueAge": "62",
        "payDuration": "10"
    },
    {
        "issueAge": "63",
        "payDuration": "10"
    },
    {
        "issueAge": "64",
        "payDuration": "10"
    },
    {
        "issueAge": "65",
        "payDuration": "10"
    },
    {
        "issueAge": "66",
        "payDuration": "10"
    },
    {
        "issueAge": "67",
        "payDuration": "10"
    },
    {
        "issueAge": "68",
        "payDuration": "10"
    },
    {
        "issueAge": "69",
        "payDuration": "10"
    },
    {
        "issueAge": "70",
        "payDuration": "10"
    },
    {
        "issueAge": "71",
        "payDuration": "10"
    },
    {
        "issueAge": "72",
        "payDuration": "10"
    },
    {
        "issueAge": "73",
        "payDuration": "9"
    },
    {
        "issueAge": "74",
        "payDuration": "8"
    },
    {
        "issueAge": "75",
        "payDuration": "7"
    },
    {
        "issueAge": "76",
        "payDuration": "6"
    },
    {
        "issueAge": "77",
        "payDuration": "5"
    },
    {
        "issueAge": "78",
        "payDuration": "4"
    },
    {
        "issueAge": "79",
        "payDuration": "3"
    }
];

class YearDurationConfig {
    productMrktName: string;
    ageDurationMap: AgeDurationMap[];
}

class AgeDurationMap {
    issueAge: string;
    payDuration: string;
}