import { Injectable } from '@angular/core';
import { UserDetailsService } from './user-details.service';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { AgentAndFirmService } from './agent-and-firm.service';
import { ProductDetailsService } from './product-details.service';

@Injectable({
    providedIn: 'root'
})
export class UpdateCaseService {

    constructor(
        private agentService: AgentAndFirmService,
        private userService: UserDetailsService
    ) { }

    getSavedData(): any {
        const savedDataMap = {};
        savedDataMap[PAGE_GROUP_NAME.WS_CASE_INFO] = this.getWSCaseInfoData();
        savedDataMap[PAGE_GROUP_NAME.AGENT_INFORMATION] = this.getAgentInfoData();
        savedDataMap[PAGE_GROUP_NAME.WS_AGENT_INFO] = this.getWSAgentInfoData();
        savedDataMap[PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE] = this.getPrimaryInsuredData();
        savedDataMap[PAGE_GROUP_NAME.EXISTING_INSURANCE] = this.getExistingInsuranceData();
        savedDataMap[PAGE_GROUP_NAME.LTC_REPLACEMENT_PAGE] = this.getLTCReplacementData();
        savedDataMap[PAGE_GROUP_NAME.THIRD_PARTY_DESIGNEE_PAGE] = this.getThirdPartyDesigneeData();
        savedDataMap[PAGE_GROUP_NAME.WS_POLICY_PROJECTION] = this.getProjectionDetails();
        return savedDataMap;
    }

    setSavedData(savedDataMap: any): void {
        this.setWSCaseInfoData(savedDataMap[PAGE_GROUP_NAME.WS_CASE_INFO]);
        this.setAgentInfoData(savedDataMap[PAGE_GROUP_NAME.AGENT_INFORMATION]);
        this.setPrimaryInsuredData(savedDataMap[PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE]);
        this.setExistingInsuranceData(savedDataMap[PAGE_GROUP_NAME.EXISTING_INSURANCE]);
        this.setLTCReplacementData(savedDataMap[PAGE_GROUP_NAME.LTC_REPLACEMENT_PAGE]);
        this.setThirdPartyDesigneeData(savedDataMap[PAGE_GROUP_NAME.THIRD_PARTY_DESIGNEE_PAGE]);
        this.setProjectionDetails(savedDataMap[PAGE_GROUP_NAME.WS_POLICY_PROJECTION])
    }

    private getProjectionDetails(): any {
        const wsCaseInfoMap = new Map<string, any>();
        wsCaseInfoMap.set('isProjectionGenerated', this.userService.getProjectionDetails()?.['isProjectionGenerated']);
        wsCaseInfoMap.set('dateOfProjection', this.userService.getProjectionDetails()?.['dateOfProjection']);
        wsCaseInfoMap.set('projectionSummaryData', this.userService.getProjectionDetails()?.['projectionSummaryData']);
        wsCaseInfoMap.set('paymentMode', this.userService.getPaymentMode());
        wsCaseInfoMap.set('LTCbenefitsRiderDuration', this.userService.getLTCBenefitsRiderDuration());
        wsCaseInfoMap.set('benefitPlanValueProtectionEndorsement', this.userService.getBenefitPlanValueProtectionEndorsement);
        wsCaseInfoMap.set('LEBRDuration', this.userService.getLEBenefitsRiderDuration());
        return this.convertToArray(wsCaseInfoMap);
    }

    private setProjectionDetails(dataMap: any): void {
        const convDataMap = this.convertToMap(dataMap);
        if (this.convertToMap(convDataMap)) {
            this.userService.setProjectionDetails(convDataMap.get('isProjectionGenerated'), convDataMap.get('dateOfProjection'), convDataMap.get('projectionSummaryData'));
            this.userService.setPaymentMode(convDataMap.get('paymentMode'));
            this.userService.setLTCBenefitsRiderDuration(convDataMap.get('LTCbenefitsRiderDuration'));
            this.userService.setLEBenefitsRiderDuration(convDataMap.get('LEBRDuration'));
            this.userService.setBenefitPlanValueProtectionEndorsement(convDataMap.get('benefitPlanValueProtectionEndorsement'));
        }
    }

    private getWSAgentInfoData(): any {
        const wsCaseInfoMap = new Map<string, any>();
        wsCaseInfoMap.set('producerName', this.agentService.getAgentFullName());
        wsCaseInfoMap.set('agentFirstName', this.agentService.getAgentFirstName());
        wsCaseInfoMap.set('agentLastName', this.agentService.getAgentLastName());
        return this.convertToArray(wsCaseInfoMap);
    }

    /* tslint:disable:no-string-literal */
    private setWSCaseInfoData(dataMap: any): void {
        const convDataMap = this.convertToMap(dataMap);
        if (this.convertToMap(convDataMap)) {
            this.userService.setCaseDescription(convDataMap.get('caseDescription'));
            if (convDataMap.get('refCaseId')) {
                this.userService.setRefCaseId(convDataMap.get('refCaseId'));
            }
            this.userService.setInsuredName(convDataMap.get('firstName'), convDataMap.get('middleName'),
                convDataMap.get('lastName'), convDataMap.get('suffix'));
            this.userService.setInsuredDOBInfo(convDataMap.get('dob'), convDataMap.get('age'));
            this.userService.setInsuredGender(convDataMap.get('gender'));
            this.userService.setInsuredState(convDataMap.get('state'));
        }
    }

    private getWSCaseInfoData(): any {
        const wsCaseInfoMap = new Map<string, any>();
        wsCaseInfoMap.set('caseDescription', this.userService.getCaseDescription());
        wsCaseInfoMap.set('refCaseId', this.userService.getRefCaseId());
        wsCaseInfoMap.set('firstName', this.userService.getInsuredFirstName());
        wsCaseInfoMap.set('middleName', this.userService.getInsuredMiddleName());
        wsCaseInfoMap.set('lastName', this.userService.getInsuredLastName());
        wsCaseInfoMap.set('suffix', this.userService.getInsuredSuffix());
        wsCaseInfoMap.set('dob', this.userService.getInsuredDOB());
        wsCaseInfoMap.set('age', this.userService.getInsuredAge());
        wsCaseInfoMap.set('state', this.userService.getInsuredState());
        wsCaseInfoMap.set('gender', this.userService.getInsuredGender());
        return this.convertToArray(wsCaseInfoMap);
    }

    /* tslint:disable:no-string-literal */
    private setThirdPartyDesigneeData(dataMap: any): void {
        const convDataMap = this.convertToMap(dataMap);
        if (this.convertToMap(convDataMap)) {
            this.userService.setIsUnIntendedLapse(convDataMap.get('isUnIntendedLapse'));
        }
    }

    private getThirdPartyDesigneeData(): any {
        const thirdPartyDesigneeMap = new Map<string, any>();
        thirdPartyDesigneeMap.set('isUnIntendedLapse', this.userService.getIsUnIntendedLapse());
        return this.convertToArray(thirdPartyDesigneeMap);
    }

    /* tslint:disable:no-string-literal */
    private setAgentInfoData(dataMap: any): void {
        const convDataMap = this.convertToMap(dataMap);
        if (this.convertToMap(convDataMap)) {
            this.agentService.setAgentFirstName(convDataMap.get('agentFirstName'));
            this.agentService.setAgentLastName(convDataMap.get('agentLastName'));
        }
    }

    private getAgentInfoData(): any {
        const agentInfoMap = new Map<string, any>();
        agentInfoMap.set('agentFirstName', this.agentService.getAgentFirstName());
        agentInfoMap.set('agentLastName', this.agentService.getAgentLastName());
        return this.convertToArray(agentInfoMap);
    }

    private getPrimaryInsuredData(): any {
        const insurdInfoMap = new Map<string, any>();
        insurdInfoMap.set('isMarried', this.userService.getIsMarried());
        return this.convertToArray(insurdInfoMap);
    }

    /* tslint:disable:no-string-literal */
    private setPrimaryInsuredData(dataMap: any): void {
        const convDataMap = this.convertToMap(dataMap);
        if (this.convertToMap(convDataMap)) {
            this.userService.setIsMarried(convDataMap.get('isMarried'));
        }
    }

    private getExistingInsuranceData(): any {
        const existingInsuranceMap = new Map<string, any>();
        existingInsuranceMap.set('is1035', this.userService.getIs1035());
        return this.convertToArray(existingInsuranceMap);
    }

    /* tslint:disable:no-string-literal */
    private setExistingInsuranceData(dataMap: any): void {
        const convDataMap = this.convertToMap(dataMap);
        if (this.convertToMap(convDataMap)) {
            this.userService.setIs1035(convDataMap.get('is1035'));
        }
    }

    private getLTCReplacementData(): any {
        const ltcReplacementMap = new Map<string, any>();
        ltcReplacementMap.set('longTermCareInforce', this.userService.getIsLongTermCareInforce());
        return this.convertToArray(ltcReplacementMap);
    }

    /* tslint:disable:no-string-literal */
    private setLTCReplacementData(dataMap: any): void {
        const convDataMap = this.convertToMap(dataMap);
        if (this.convertToMap(convDataMap)) {
            this.userService.setIsLongTermCareInforce(convDataMap.get('longTermCareInforce'));
        }
    }

    private convertToArray(mapObject: Map<string, any>): any {
        const convMap = {};
        mapObject.forEach((val: any, key: string) => {
            convMap[key] = val;
        });
        return convMap;
    }

    private convertToMap(arrayObject: any): Map<string, any> {
        const convMap = new Map<string, any>();
        if (arrayObject) {
            Object.keys(arrayObject).forEach(obj => {
                convMap.set(obj, arrayObject[obj]);
            });
        }
        return convMap;
    }
}
