<section class="case-info-page page-content-container">

  <div class="questions--container">
    <h1>
      Case Information
    </h1>
    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group mar-border-15" [formGroup]="caseInfoForm" id="caseInfoForm" method="post"
        *ngIf="caseInfoData && caseInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of caseInfoData; let ind = index;">

          <div class="section-divider"
            *ngIf="data.questionText == 'Product Information' || data.questionText == 'Name of Firm' || data.xmlTag == caseInfoConstant.usCitizenIdWs">
          </div>

          <!-- Questions headers -->
          <div class="questions-header"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText?.indexOf('Select state and product type') == -1 && data.questionText !== 'Find Available Products' && data.questionText !== 'Line' && data.notes !== 'Hidden' && data.questionText?.toLowerCase().indexOf('case description') == -1 && data.subText != 'Information'">
            <h2>{{ data.questionText }} </h2>
          </div>
          <!-- Questions headers -->
          <div class="questions-header"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText?.indexOf('Select state and product type') > -1">
            {{ data.questionText }}
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && !(data.questionText?.includes('First Name') || data.questionText?.includes('Last Name'))">
            <lfg-input-wrap>
              <input lfgInput id="{{data.fieldId}}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}"
                name="{{ data.fieldId }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}'
                autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols
                [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength"
                [required]="data.required == 'true'">
              <div aria-live="assertive"
                *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid">
                <lfg-error message="Please enter a valid {{data.questionText | lowercase}}"></lfg-error>
              </div>
            </lfg-input-wrap>
          </div>
          
          <!-- Form element for First name and Last name  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.questionText?.includes('First Name') || data.questionText?.includes('Last Name'))">
            <lfg-input-wrap>
              <input lfgInput id="{{data.fieldId}}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}"
                name="{{ data.fieldId }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}'
                autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols
                customAttr="alphanumericWithSingleQuote" [maxlength]="data.maxLength"
                [required]="data.required == 'true'">
              <div aria-live="assertive"
                *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid">
                <lfg-error message="Please enter a valid {{data.questionText | lowercase}}"></lfg-error>
              </div>
            </lfg-input-wrap>
          </div>

          <!-- Form element for check box  -->
          <div class="form--control form--control__full-width form--control_checkbox" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
            <div class="lfg-checkbox">
            <input id="{{ data.fieldId }}" type="checkbox"  value="checked"  name="{{ data.fieldId }}"
                formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
            <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</label>
            </div>
          </div>

          <!-- Form Element for date  -->
          <div class="form--control lfg-cal" aria-live="assertive"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
            <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)"
              (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="caseInfoForm.get(data.fieldId)"
              [config]="dateConfig"
              [class.calendar--err]="(caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid)"
              aria-hidden="true" [required]="data.required == 'true'">
              <lfg-error
                *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid && !caseInfoForm.get(data.fieldId).errors?.dobLimitError"
                message="Please enter a valid {{data.questionText | lowercase}}"></lfg-error>
              <lfg-error
                *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid && caseInfoForm.get(data.fieldId).errors?.dobLimitError"
                message="{{appConfig.errorMessages.BASIC_DOB_ERROR}}"></lfg-error>
            </lfg-calendar>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}"
                placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols
                customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name=""
                appValidateOnBlur value="" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid"
                message="Please enter a valid {{data.questionText | lowercase}}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag == caseInfoConstant.firmNameIdWs">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}"
              [options]="alldropDownOptions[data.fieldId]"
              placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}"
              (selectionChanged)="handleSelectionChange($event, data, ind)" [required]="data.required == 'true'"
              [ngClass]="{'dropdown--err': (caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid) }">
            </lfg-dropdown>
            <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid"
              message="Please select a valid option"></lfg-error>
          </div>

          <div class="form--control form-dropDown"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag != caseInfoConstant.firmNameIdWs">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}"
              [options]="alldropDownOptions[data.fieldId]"
              placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}"
              (selectionChanged)="handleSelectionChange($event, data, ind)" [required]="data.required == 'true'"
              [ngClass]="{'dropdown--err': (caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid) }">
            </lfg-dropdown>
            <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid"
              message="Please select a valid option"></lfg-error>
          </div>




          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag !== '/XML/Product/ProductID'">
            <span class="lfg-radio-section"
            [ngClass]="{'radio--err': (caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid)}">
              <p class="question-radio_text">{{ data.questionText }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}"
                  type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}"
                  value="{{ data.questionOption[0].value }}" required="{{data.required}}"
                  (change)="onRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}"
                  type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}"
                  value="{{ data.questionOption[1].value }}" required="{{data.required}}"
                  (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid"
              message="Please select a valid option"></lfg-error>
          </div>

          <!-- Form element for Button -->
          <div class="form--control form--length-btn find-btn"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText === 'Find Available Products'">

            <button id="{{displayIdCreation(data)}}_{{ind}}" (click)="loadAvailableProduct(); focusOff($event);"
              class="btn focusOffBtn"
              [disabled]="isWholeSaler ? ( !selectedProductType || !selectedStateCode || disableForm || !selectedFirmNameWs) : (!selectedProductType || !selectedStateCode || disableForm)">{{data.questionText}}</button>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>



          <div *ngIf="data.hasReflexive == 'true' && caseInfoForm.get(data.fieldId).value as parentAnswer"  [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
            <ng-container *ngFor="let primaryChildL1 of data.reflexiveQuestionAL; index as primaryChildL1ix">
              <ng-container *ngIf="nestedQuestionCheckCaseInfo(data, primaryChildL1, parentAnswer)">
                <!-- Form element for text  -->
                <div class="form--control" aria-live="assertive"
                  *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}"
                      placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text'
                      formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur
                      appNoSpecialSymbols customAttr="text" [maxlength]="primaryChildL1.maxLength"
                      [required]="primaryChildL1.required == 'true'">
                    <lfg-error
                      *ngIf="caseInfoForm.get(primaryChildL1.fieldId).touched && caseInfoForm.get(primaryChildL1.fieldId).invalid"
                      message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>
                
                  <!-- Form element for radio question -->
                  <div class="lfg-customRadio-field" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO">
                    <span class="lfg-radio-section"
                    [ngClass]="{'radio--err': (caseInfoForm.get(primaryChildL1.fieldId).touched && caseInfoForm.get(primaryChildL1.fieldId).invalid)}">
                      <p class="question-radio_text">{{ primaryChildL1.questionText + (primaryChildL1.required == 'true' ? '' : ' (optional)') }}</p>
                      <div class="custom-radio-button">
                        <input [attr.aria-label]="primaryChildL1.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[0].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)"/>
                        <label for="yes_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[0].description }}</label>
                        <input [attr.aria-label]="primaryChildL1.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[1].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)">
                        <label for="no_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error *ngIf="caseInfoForm.get(primaryChildL1.fieldId).touched && caseInfoForm.get(primaryChildL1.fieldId).invalid " message="{{ getErrorMsg(primaryChildL1, caseInfoForm.get(primaryChildL1.fieldId)) }}"></lfg-error>
                  </div>

              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <!-- product listing componnent -->
        <div class="refresh-btn-container">
          <a class="btn-link btn-refresh" [ngClass]="{'btn--link-disabled': disableForm}" id="refreshBtn" type="button"
            (click)="refresh()">
            <i class="fas fa-redo refresh-icon" aria-hidden="true"></i> Refresh </a>
        </div>
        <div *ngIf="productList?.length > 0">
          <div class="product-list-content">
            <app-product-list (selectedProduct)="updateSelectedProduct($event)" [productList]="productList"
              [selectedProductCode]="selectedProductCode" [selectedStateCode]="selectedStateCode"
              [disableForm]="disableForm"></app-product-list>
          </div>
        </div>
        <div *ngIf="productList?.length == 0 && !noProductAvailable">
          <span class="productlist-noproduct"> Select options above to view available products.</span>
        </div>
        <div *ngIf="noProductAvailable && productList?.length == 0">
          <span class="productlist-noproduct"> No product available for selected state and product type.</span>
        </div>


        <ng-container class="form-control-parent" *ngFor="let data of caseInfoData; let ind = index;">
          
          <div class="questions-header"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText?.toLowerCase().indexOf('case description') > -1">
            <h2>{{ data.questionText }} </h2>
          </div>

          <!-- Form element for text area -->
          <div class="form--control form--control__full-width" aria-live="assertive"
            *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXTAREA">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}"
                name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}'
                autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text"
                [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <span class="text-area-count">{{caseInfoForm.get(data.fieldId).value.length}}/{{data.maxLength}}</span>
              <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid"
                message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>
        </ng-container>

      </form>
    </section>

  </div>


</section>