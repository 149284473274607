import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { ClipboardService } from '@ng/lfg-common-utilities';

import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Question } from 'src/app/shared/models/casePage.model';
import { ApplicationConfig } from 'src/config/app.config';
import { DcaAllocationConstants, ErrorMessage, FundAllocationConst } from 'src/config/constants';
import { LfgLoaderService } from '@ng/lfg-loader';
import { TabStatus } from 'src/config/sideNav.config';


@Component({
  selector: 'app-dca-allocation',
  templateUrl: './dca-allocation.component.html',
  styleUrls: ['./dca-allocation.component.scss']
})
export class DcaAllocationComponent implements OnInit {

  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  fundAllocationData: Question[] = [];
  fundAllocationForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  placeholders = {};
  alldropDownOptions = {};

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  fundError = ErrorMessage.FUND_PERCENTAGE_ERROR;

  totalFundPercentage = 0;
  openAccordion = false;
  dcaAllocationConstants = DcaAllocationConstants;
  accountToDCAfromError = false;
  telephoneTransfersRepOptionValues = [];
  @ViewChild("myselfEle") myselfEle: ElementRef;
  @ViewChild("registered") registered: ElementRef;
  tier3PercentageError = false;
  status = {};
  expandTiers = false;
  showLoader = true;
  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    public clipboardService: ClipboardService,
    private loaderService: LfgLoaderService
  ) { }

  ngOnInit(): void {
    this.addLoader(true);
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.fundAllocationForm);
      this.defaultDCAPeriodDCATransfer();
      if (this.isTabVisited) {
        this.accountToDCAfromError = this.clipboardService.get('accountToDCAfromError');
        this.tier3PercentageError = this.clipboardService.get('tier3PercentageError');
      }
      this.defaultPageLoadService.disableFormIfLocked(this.fundAllocationForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
    this.addLoader(false);
  }

  private buildFormData(): any {
    this.fundAllocationData = this.questionsData.pages[0].questions;
    this.fundAllocationData.forEach(ques => {
      if (ques.controlTypeDesc === 'Fund Allocation') {
        let updatedOptions = [];
        ques.questionOption.forEach(options => {
          let valueExist = Object.keys(options).some(key => key === 'value');
          if (valueExist) {
            updatedOptions.push({
              ...options
            });
          } else {
          let opt = {"value": null};
            updatedOptions.push({
              ...options, ...opt
            });
          }
          ques.questionOption = updatedOptions;
        })
      }
    });
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.fundAllocationData
    );
    const form = this.formgeneratorService.createFormControls(
      this.fundAllocationData
    );
    this.fundAllocationForm = this.fb.group(form);
    this.questionsData?.pages[0]?.questions?.forEach(ques => {
      if (ques.xmlTag === '/XML/ApplicationDetail/TelephoneTransfersRep' && ques.notes !== 'SEPARATE_CHECKBOX') {
        setTimeout(() => {
          if (!ques.question_answer) {
            return;
          }
          const ans = ques.question_answer?.split(',');
          if (ans[0] === ques.questionOption[0].description || ans[1] === ques.questionOption[0].description) {
              this.telephoneTransfersRepOptionValues.push(ques.questionOption[0].description);
          } else {
           this.myselfEle.nativeElement.checked = false;
          }
          if (ans[1] === ques.questionOption[1].description || ans[0] === ques.questionOption[1].description) {
              this.telephoneTransfersRepOptionValues.push(ques.questionOption[1].description);
          } else {
           this.registered.nativeElement.checked = false;
          }
        }, 200)
      }
    });
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.calculatetotalFundPercentage();
      this.checkFormStatus();
    }, 200);
    this.fundAllocationForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.calculatetotalFundPercentage();
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  private checkFormStatus(): any {
    const isAnyAlertExists = this.isAlertExists();
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid && this.totalFundPercentage === 100 && !this.tier3PercentageError && !isAnyAlertExists,
      formHasNoErrors: this.formHasNoErrors,
      form: this.fundAllocationForm,
      data: null,
    };
    for (const data of this.fundAllocationData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.questionsData?.pages[0]?.questions?.forEach(ques => {
      if (ques.xmlTag === '/XML/ApplicationDetail/TelephoneTransfersRep' && ques.notes !== 'SEPARATE_CHECKBOX') {
          if (ques.question_answer || this.telephoneTransfersRepOptionValues.length > 0) {
            ques.question_answer = this.telephoneTransfersRepOptionValues.join(',');
          }
      }
    });
    this.emitStatus();
  }

  onFundRadioChange(data): any {
    if (data.xmlTag === '/XML/ApplicationDetail/DCAFromFund') {
      this.clipboardService.set('DCAFromFundData', data);
      this.checkAccountToDCAfrom();
    }
    if (data.xmlTag === '/XML/ApplicationDetail/DCAIndicator') {
      if (this.fundAllocationForm.get(data.fieldId).value === 'N') {
        this.accountToDCAfromError = false;
      }
      setTimeout(() => {
        this.defaultDCAPeriodDCATransfer();
        this.clipboardService.set('DCAIndicatorData', data);
      }, 200);
    }
    this.updateFundAnswersOnHidden(data);
  }

  checkAccountToDCAfrom(): void {
    const DCAFromFundData = this.clipboardService.get('DCAFromFundData');
    const accountToDCAfromValue = this.fundAllocationForm.get(DCAFromFundData?.fieldId)?.value;
    const fixedAccountValue = this.fundAllocationForm.get('105').value;
    this.accountToDCAfromError = false;
    this.clipboardService.set('accountToDCAfromError', this.accountToDCAfromError);
    if (accountToDCAfromValue === '105' && fixedAccountValue && fixedAccountValue !== '0') {
      this.accountToDCAfromError = true;
      this.clipboardService.set('accountToDCAfromError', this.accountToDCAfromError);
    }
  }

  defaultDCAPeriodDCATransfer(): void {
    const DCAIndicatorFieldId = this.utilsService.getFieldId(this.fundAllocationData, '/XML/ApplicationDetail/DCAIndicator');
    const DCAPeriodFieldId = this.utilsService.getFieldId(this.fundAllocationData, '/XML/ApplicationDetail/DCAPeriod');
    const DCATransferOptionFieldId = this.utilsService.getFieldId(this.fundAllocationData, '/XML/ApplicationDetail/DCATransferOption');
    if (this.fundAllocationForm.get(DCAIndicatorFieldId).value === 'Y') {
      this.fundAllocationForm.get(DCAPeriodFieldId).setValue('1');
      this.fundAllocationForm.get(DCAPeriodFieldId).disable();
      this.fundAllocationForm.get(DCATransferOptionFieldId).setValue('12');
      this.fundAllocationForm.get(DCATransferOptionFieldId).disable();
    }
  }

  handleFundSelectionChange(data, _ix?): any {
    this.updateFundAnswersOnHidden(data);
  }

  addFundCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  onCheckboxChange(data, value): any {
   if( this.telephoneTransfersRepOptionValues.indexOf(value) === -1) {
    this.telephoneTransfersRepOptionValues.push(value);
   }
   if (!this.fundAllocationForm.get(data.fieldId).value) {
    const index = this.telephoneTransfersRepOptionValues.indexOf(value);
    this.telephoneTransfersRepOptionValues.splice(index, 1);
   }
  this.updateFundAnswersOnHidden(data);

  }

  updateFundAnswersOnHidden(data): any {
    const value = this.fundAllocationForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.fundAllocationForm.get(key)?.markAsUntouched();
      }
      this.fundAllocationForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.fundAllocationForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckFundInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  emitStatus(): void {
    const fundAllocationObj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'fundAllocationForm',
      gridForm: true,
      isFormChange: this.fundAllocationForm.dirty || !this.isTabVisited || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.fundAllocationForm),
    };
    this.formStatus.emit(fundAllocationObj);
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  resetForm(): void {
    Object.keys(this.fundAllocationForm.controls).forEach(key => {
      if (key.indexOf(FundAllocationConst.fundQuesXmlTag) > -1) {
        this.fundAllocationForm.get(key).reset();
      }
    });
  }

  private calculatetotalFundPercentage(): void {
    this.totalFundPercentage = 0;
    Object.keys(this.fundAllocationForm.controls).forEach(key => {
      key =  key.split('_')[0];
      if (this.dcaAllocationConstants.indexOf(key) === -1) {
        this.totalFundPercentage = this.totalFundPercentage + +(this.fundAllocationForm.get(key)?.value);
      }
    });

    this.tier3PercentageError = false;
    this.clipboardService.set('tier3PercentageError', this.tier3PercentageError);
    if (this.getTier1Percentage() < 20 && this.getTier3Percentage() != 0) {
      this.tier3PercentageError = true;
      this.clipboardService.set('tier3PercentageError', this.tier3PercentageError);
    }
  }

  getTier1Percentage(): number {
    const tier1Obj = this.fundAllocationData.filter(val => val.notes?.indexOf('Tier 1') > -1);
    let tier1Percentage = 0;
    Object.keys(this.fundAllocationForm.controls).forEach(key => {
      for (let questionObject of tier1Obj) {
        for (let options of questionObject.questionOption) {
          if (options.fundcode.indexOf(key) > -1) {
            tier1Percentage = tier1Percentage + +(this.fundAllocationForm.get(key)?.value);
          }
        }
      }
    })
    return tier1Percentage  + +this.fundAllocationForm.get('105')?.value;
  }

  getTier2Percentage(): number {
    const tier2Obj = this.fundAllocationData.filter(val => val.notes?.indexOf('Tier 2') > -1);
    let tier2Percentage = 0;
    Object.keys(this.fundAllocationForm.controls).forEach(key => {
      for (let questionObject of tier2Obj) {
        for (let options of questionObject.questionOption) {
          if (options.fundcode.indexOf(key) > -1) {
            tier2Percentage = tier2Percentage + +(this.fundAllocationForm.get(key)?.value);
          }
        }
      }
    });
    return tier2Percentage;
  }

  getTier3Percentage(): number {
    const tier3Obj = this.fundAllocationData.filter(val => val.notes?.indexOf('Tier 3') > -1);
    let tier3Percentage = 0;
    Object.keys(this.fundAllocationForm.controls).forEach(key => {
      for (let questionObject of tier3Obj) {
        for (let options of questionObject.questionOption) {
          if (options.fundcode.indexOf(key) > -1) {
            tier3Percentage = tier3Percentage + +(this.fundAllocationForm.get(key)?.value);
          }
        }
      }
    });
    return tier3Percentage;
  }

  toggleAccordionStatus(): void {
    this.openAccordion = !this.openAccordion;
  }

  private isAlertExists(): boolean {
    const htmlAttAlert = Array.from(document.getElementsByTagName('lfg-alert-message'));
    let alertExist = false;
    if (htmlAttAlert?.length > 0) {
      for (const htmlElement of htmlAttAlert) {
        if (htmlElement.getAttribute('type') === 'error' && (htmlElement.innerHTML?.toLowerCase().indexOf('dca from and to the same account is not allowed.') > -1)) {
          alertExist = true;
          break;
        }
      }
    }
    return alertExist;
  }

  toggleAccordian(status, index, ev?) {
    if (ev?.target.classList.contains('accordion__header') || ev?.target.parentElement.classList.contains('chevron-icon')) {
      this.status[index] = !status;
    }
    if (Object.values(this.status).length === 3) {
      this.expandTiers = !Object.values(this.status).includes(false);
    }
  }

  expandCollapseTiers(): void {
    this.expandTiers = !this.expandTiers;
    if(Object.keys(this.status).length < 3) {
      this.status = {1: false, 2: false, 3: false};
    }
    for(let item of Object.keys(this.status)) {
      this.status[item] = this.expandTiers;
    }
  }

  addLoader(isAddLoader: boolean): void {
    if (isAddLoader) {
      this.showLoader = true;
      this.loaderService.show();
    } else {
      setTimeout(()=>{
        this.showLoader = false;
        this.loaderService.hide();
      }, 0);
    }
  }
}
