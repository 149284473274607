import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  faceAmount: any = 0;
  ownerDetails: any;
  selectedFundList: string[] = [];
  insuredStreetAddress: any;
  ownerDetailsWithAdd: any;
  selectedAccountHolder: any;
  insuredAddress: any;
  insuredPrimaryPhone: any;
  insuredPrimaryTypePhone: any;
  insuredPrimaryPINPhone: any;
  insuredEmail: any;
  eftDetails: {} = {};
  checkPaymentProvider: any;
  eftPaymentProvider: any;
  constructor() {
    // can be used later
  }

  private insuredFirstName: string;
  private insuredLastName: string;
  private insuredMiddleName: string;
  private insuredSuffix: string;
  private insuredDob: Date;
  private insuredAge: number;
  private insuredGender: string;
  private insuredState: string;
  private isChildRiderSelected: boolean;
  private isTeleAppSelected: boolean;
  private selectedOwners: string[];
  private isBusinessEnhanceRider: boolean;
  private isIndividualEnhanceRider: boolean;
  private residentState: string;
  private ownerSameAsType: string;
  private CIVAgreementInd: string;
  private ownerState: string;
  private isInsuredUSCitizen: string;
  private policyInflationProtectionOption: string;

  // fund related Values
  private fixedAccountFund: number;
  private govtFund: number;
  private isFixedOrGovtFundUpdated: boolean;

  // for wetSignature
  private isUnIntendedLapse: boolean;
  private isMarried: boolean;
  private isLongTermCareInforce: boolean;
  private is1035: boolean;

  setInsuredName(
    firstName: string,
    middleName: string,
    lastName: string,
    suffix: string
  ): void {
    this.insuredFirstName = firstName;
    this.insuredMiddleName = middleName ? middleName : '';
    this.insuredLastName = lastName;
    this.insuredSuffix = suffix ? suffix : '';
  }

  setEFTPaymentProvider(value): void {
    this.eftPaymentProvider = value;
  }

  getEFTPaymentProvider(): any {
    return this.eftPaymentProvider;
  }

  setCheckPaymentProvider(value): void {
    this.checkPaymentProvider = value;
  }

  getCheckPaymentProvider(): any {
    return this.checkPaymentProvider;
  }

  setInsuredDOBInfo(dob: Date, age: number): void {
    this.insuredAge = age;
    this.insuredDob = dob;
  }

  getInsuredName(): string {
    return this.insuredFirstName + ' ' + this.insuredLastName;
  }

  getInsuredFullName(): string {
    const fullName =
      this.insuredFirstName +
      ' ' +
      this.insuredMiddleName +
      ' ' +
      this.insuredLastName +
      ' ' +
      this.insuredSuffix;
    return fullName.trim().replace('  ', ' ');
  }

  getInsuredFirstName(): string {
    return this.insuredFirstName;
  }

  getInsuredLastName(): string {
    return this.insuredLastName;
  }

  getInsuredMiddleName(): string {
    return this.insuredMiddleName;
  }

  setInsuredAddress(
    address1: string, address2: string, address3: string, city: string, state: string, country: string, zipCode: string): void {
    this.insuredAddress = {
      address1, address2, address3, city, state, country, zipCode
    };
  }

  getInsuredAddress(): any {
    return this.insuredAddress;
  }

  setInsuredPhoneNo(value): any {
    this.insuredPrimaryPhone = value;
  }

  getInsuredPhoneNo(): string {
    return this.insuredPrimaryPhone;
  }

  setInsuredPhoneType(value): any {
    this.insuredPrimaryTypePhone = value;
  }

  getInsuredPhoneType(): string {
    return this.insuredPrimaryTypePhone;
  }

  setInsuredPhonePIN(value): any {
    this.insuredPrimaryPINPhone = value;
  }

  getInsuredPhonePIN(): string {
    return this.insuredPrimaryPINPhone;
  }

  setInsuredEmail(value): any {
    this.insuredEmail = value;
  }

  getInsuredEmail(): string {
    return this.insuredEmail;
  }

  setSelectedAccHolder(value): void {
    this.selectedAccountHolder = value;
  }

  getSelectedAccHolder(): any {
    return this.selectedAccountHolder;
  }

  getInsuredSuffix(): string {
    return this.insuredSuffix;
  }

  getInsuredAge(): number {
    return this.insuredAge;
  }

  getInsuredDOB(): Date {
    return this.insuredDob;
  }

  getInsuredGender(): string {
    return this.insuredGender;
  }

  setInsuredGender(gender: string): void {
    this.insuredGender = gender;
  }

  setInsuredState(state: string): void {
    this.insuredState = state;
  }

  getInsuredState(): string {
    return this.insuredState;
  }

  getIsChildRiderSelected(): boolean {
    return this.isChildRiderSelected;
  }

  setIsChildRiderSelected(isChildRider: boolean): void {
    this.isChildRiderSelected = isChildRider;
  }

  getIsTeleAppSelected(): boolean {
    return this.isTeleAppSelected;
  }

  setIsTeleAppSelected(isTeleAppSelected: boolean): void {
    this.isTeleAppSelected = isTeleAppSelected;
  }

  setFaceAmount(faceAmount: string): void {
    this.faceAmount = faceAmount;
  }

  getFaceAmount(): any {
    return this.faceAmount;
  }

  setBusinessEnhanceRiderSeleted(value): void {
    this.isBusinessEnhanceRider = value;
  }

  getBusinessEnhanceRiderSeleted(): boolean {
    return this.isBusinessEnhanceRider;
  }

  setIndividualEnhanceRiderSeleted(value): void {
    this.isIndividualEnhanceRider = value;
  }

  getIndividualEnhanceRiderSeleted(): boolean {
    return this.isIndividualEnhanceRider;
  }

  setOwnerDetails(owner, ownerDrpdwnDetails): void {
    this.ownerDetails = owner;
    this.ownerDetailsWithAdd = ownerDrpdwnDetails;
  }

  getOwnerDetailsWithAddress(): void {
    return this.ownerDetailsWithAdd;
  }

  getOwnerDetails(): void {
    return this.ownerDetails;
  }
  getSelectedOwners(): string[] {
    return this.selectedOwners;
  }

  setSelectedOwners(ownersSelected: string[]): void {
    this.selectedOwners = ownersSelected;
  }

  setFixedAccountAndGovtFund(fixedAccountFund: number, govtFund: number): void {
    this.fixedAccountFund = fixedAccountFund;
    this.govtFund = govtFund;
  }

  getFixedAccountFund(): number {
    return this.fixedAccountFund;
  }

  getGovtFund(): number {
    return this.govtFund;
  }

  getSelectedFundList(): string[] {
    return this.selectedFundList;
  }

  setSelectedFundList(selectedfundList: string[]): void {
    this.selectedFundList = selectedfundList;
  }

  getIsFixedOrGovtFundUpdated(): boolean {
    return this.isFixedOrGovtFundUpdated;
  }

  setIsFixedOrGovtFundUpdated(isFixedOrGovtFundUpdated: boolean): void {
    this.isFixedOrGovtFundUpdated = isFixedOrGovtFundUpdated;
  }

  clearUserDetails(): void {
    this.setInsuredName(undefined, undefined, undefined, undefined);
    this.setInsuredDOBInfo(undefined, undefined);
    this.setFixedAccountAndGovtFund(undefined, undefined);
    this.insuredGender = this.insuredState = this.isChildRiderSelected = undefined;
    this.selectedOwners = [];
    this.isTeleAppSelected = this.isBusinessEnhanceRider = this.isIndividualEnhanceRider = undefined;
    this.ownerDetails = this.insuredStreetAddress = this.ownerDetailsWithAdd = undefined;
    this.faceAmount = 0;
    this.selectedAccountHolder = this.insuredAddress = undefined;
    this.isFixedOrGovtFundUpdated = false;

    // flags for MGeApp
    this.is1035 = this.isUnIntendedLapse = this.isMarried = this.isLongTermCareInforce = undefined;
  }

  setResidentState(residentState: string): void {
    this.residentState = residentState;
  }

  setOwnerState(ownerState: string): void {
    this.ownerState = ownerState;
  }

  setOwnerSameAsType(ownerSameAsType: string): void {
    this.ownerSameAsType = ownerSameAsType;
  }

  setCIVAgreementInd(CIVAgreementInd: string): void {
    this.CIVAgreementInd = CIVAgreementInd;
  }

  getResidentState(): string {
    return this.residentState;
  }

  getCIVAgreementInd(): string {
    return this.CIVAgreementInd;
  }

  getOwnerSameAsType(): string {
    return this.ownerSameAsType;
  }

  getOwnerState(): string {
    return this.ownerState;
  }

  setIsInsuredUSCitizen(value): void {
    this.isInsuredUSCitizen = value;
  }

  getIsInsuredUSCitizen(): string {
    return this.isInsuredUSCitizen;
  }

  setPolicyInflationProtectionOption(value): void {
    this.policyInflationProtectionOption = value;
  }

  getPolicyInflationProtectionOption(): string {
    return this.policyInflationProtectionOption;
  }

  getIsUnIntendedLapse(): boolean {
    return this.isUnIntendedLapse;
  }

  setIsUnIntendedLapse(isUnIntendedLapse: boolean): void {
    this.isUnIntendedLapse = isUnIntendedLapse;
  }

  getIsMarried(): boolean {
    return this.isMarried;
  }

  setIsMarried(isMarried: boolean): void {
    this.isMarried = isMarried;
  }

  getIs1035(): boolean {
    return this.is1035;
  }

  setIs1035(is1035: boolean): void {
    this.is1035 = is1035;
  }

  getIsLongTermCareInforce(): boolean {
    return this.isLongTermCareInforce;
  }

  setIsLongTermCareInforce(isLongTermCareInforce: boolean): void {
    this.isLongTermCareInforce = isLongTermCareInforce;
  }
}
