export class ActivityTableConfig {
    tableData = {
        config: {
            tableId: 'activity-log-dbtable',
            tableClass: 'activity-log-dbtable',
            sorting: false,
            pagination: false,
            rowsPerPage: 50,
            currentPage: 0,
            tableFoot: false,
            defaultSortOrder: 'desc',
            clickSortOrder: 'asc',
            handleSubDetailRow: false,
            noRecordsText: 'No records found'
        },
        column: [
            {
                displayText: 'DATE / TIME',
                columnKey: 'datetime',
                columnFlag: 'datetime',
                classNameTh: 'activity-table-th_date',
                classNameTd: 'activity-table-td_date',
                align: 'left',
                type: 'htmlText',
                isAction: 'noaction',
                pipeName: null,
                sorting: false,
            },
            {
                displayText: 'ACTIVITY',
                columnKey: 'activity',
                columnFlag: 'activity',
                classNameTh: 'agent-table-th_info',
                classNameTd: 'agent-table-td_info',
                align: 'left',
                type: 'htmlText',
                isAction: 'noaction',
                pipeName: null,
                sorting: false
            }
        ],
        data: []
    };
}
