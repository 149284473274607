import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GridInstance, Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';

@Component({
  selector: 'app-policy-projection-grid',
  templateUrl: './policy-projection-grid.component.html',
  styleUrls: ['./policy-projection-grid.component.scss']
})
export class PolicyProjectionGridComponent implements OnInit, OnChanges {

  @Input() disableForm: boolean;
  @Input() gridInstance: GridInstance;
  @Input() gridAnswers: any[];
  @Input() maxGridAvailable;
  @Input() addAccordionText: string;
  @Input() isRevisit = false;
  @Output() gridStatus: EventEmitter<any> = new EventEmitter();

  gridId: any;

  mainFormGroup: FormGroup;
  gridQuestionsObj = {};
  formValid: boolean;
  formHasNoErrors: boolean;
  gridSequences: any = [];
  placeholders = {};
  alldropDownOptions = {};
  showLoader = false;
  index = 0;
  headerList = [];

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload
  ) { }

  ngOnInit(): void {
    if (!this.gridInstance) {
      return;
    }
    this.initializeGridInstance();
    this.markAllFieldAsTouched();
    this.defaultPageLoadService.disableFormIfLocked(this.mainFormGroup, this.disableForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.disableForm?.currentValue !== changes?.disableForm?.previousValue && this.mainFormGroup) {
      if (this.disableForm) {
        this.defaultPageLoadService.disableFormIfLocked(this.mainFormGroup, this.disableForm);
      } else {
        this.enableGrid();
      }
    }
  }

  markAllFieldAsTouched(): void {
    if (this.isRevisit) {
      this.mainFormGroup.markAllAsTouched();
    }
  }

  loadGriddata(): any {
    const form = {};
    form[this.gridInstance?.id] = this.fb.array([]);
    return form;
  }

  onTrackById(index: number, item: FormGroup) {
    return index;
  }

  private initializeGridInstance(): void {
    const gridData = this.gridInstance;
    this.getTableHeader(gridData.gridQuestions);
    this.gridQuestionsObj[gridData.id] = gridData;
    const gridDropDownOption = this.formgeneratorService.getdropdownOptions(
      gridData.gridQuestions
    );
    this.alldropDownOptions = {
      ...this.alldropDownOptions,
      ...gridDropDownOption,
    };
    this.formgeneratorService.setGridQuestionsData(this.gridQuestionsObj);
    const form = {};
    form[this.gridInstance.id] = this.fb.array([]);
    this.mainFormGroup = this.fb.group(form);
    this.checkIfDataAvailable();
    this.valueChanges();
  }

  private checkIfDataAvailable(): void {
    if (this.gridAnswers && this.gridAnswers.length > 0) {
      this.prepopulateGrids(this.gridAnswers);
    } else {
      this.createNewGrid();
      this.updateGridSequenceValue();
    }
  }

  private prepopulateGrids(savedAnswers: any[]): void {
    for (const savedAnswer of savedAnswers) {
      const id = this.gridInstance.id;
      const gridUniqueId = new Date().toString() + this.index.toString();
      this.index++;
      const updatedAnswerObject =
        this.formgeneratorService.updateGridAnswerArray(
          id,
          this.gridQuestionsObj,
          savedAnswer
        );
      const gridFormGroup = this.formgeneratorService.createGrid(
        id,
        this.gridQuestionsObj,
        updatedAnswerObject
      );
      const formArray = this.mainFormGroup.get(id) as FormArray;
      const newControls = this.fb.group(gridFormGroup);
      formArray.push(newControls);
      const obj = {
        gridIndex: formArray.length - 1,
        gridId: id,
        formLoadCompleted: true,
        gridUniqueId,
      };
      this.gridSequences.push(obj);
    }
  }

  private updateGridSequenceValue(): void {
    const obj = this.gridSequences[this.gridSequences.length - 1];
    if (obj) {
      obj.formLoadCompleted = true;
      this.gridSequences[this.gridSequences.length - 1] = obj;
    }
  }

  private valueChanges(): void {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.mainFormGroup.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.updateGridSequenceValue();
        this.checkFormStatus(true);
      }, 200);
    });
  }

  private checkFormStatus(formUpdated?: boolean): void {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.mainFormGroup,
      data: {},
      gridData: {},
      gridDataWithNameTag: {},
    };
    for (const data of this.gridSequences) {
      if (!componentData.gridData.hasOwnProperty(data.gridId)) {
        componentData.form = this.mainFormGroup;
        componentData = this.formgeneratorService.checkGridStatusAndGetGridData(
          componentData,
          data.gridId
        );
        this.formValid = componentData.formValid;
        this.formHasNoErrors = componentData.formHasNoErrors;
      }
    }
    const gridAnswers = [];
    for (const data of this.gridSequences) {
      gridAnswers.push(
        componentData.gridDataWithNameTag[data.gridId][data.gridIndex]
      );
      this.checkIfFormIsValid(data);
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      gridAnswers,
      formId: 'mainFormGroup',
      isGridFormChange: this.mainFormGroup.dirty || formUpdated,
    };
    this.gridStatus.emit(obj);
  }

  private checkIfFormIsValid(gridData): any {
    const formGrid = ((this.mainFormGroup.get(gridData.gridId) as FormArray)?.at(gridData.gridIndex)) as FormGroup;
    const questData = this.gridInstance?.gridQuestions;
    const componentData = {
      formValid: true
    };
    questData.forEach(ques => {
      this.updateFormStatus(componentData, ques, formGrid);
    });
  }

  private getAnswer(form: FormGroup, data: Question): string {
    let answer = form.get(data.fieldId).value;
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT && answer) {
      answer = answer.value;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      answer = answer === true ? 'Yes{47}Yes' : 'No{47}No';
    }
    return answer;
  }

  private updateFormStatus(componentData: any, data: any, form: FormGroup, parentAnswer?: any): any {
    let inputVisible = true;
    if (typeof parentAnswer !== 'undefined') {
      const optionChoice = data.optionChoice || '';
      inputVisible = optionChoice.indexOf(parentAnswer) >= 0;
    }
    if (inputVisible && form.controls[data.fieldId]?.status === 'INVALID') {
      componentData.formValid = false;
    }
    if (data.hasReflexive === 'true') {
      for (const childData of data.reflexiveQuestionAL) {
        const answer = this.getAnswer(form, data);
        componentData = this.updateFormStatus(componentData, childData, form, answer);
      }
    }
    return componentData;
  }

  private addGrid(gridId: string, gridInd?): void {
    const gridFormGroup = this.formgeneratorService.createGrid(
      gridId,
      this.gridQuestionsObj[gridId].gridQuestions,
      undefined,
      'POLICY_PROJECTION'
    );
    const control = this.mainFormGroup.get(gridId) as FormArray;
    const newControls = this.fb.group(gridFormGroup);
    control.push(newControls);
  }

  private getTableHeader(gridQuestion: Question[]): void {
    gridQuestion?.forEach((ques => {
      if(ques.subText === 'table-header') {
        this.headerList = ques.questionText.split('$');
      }
    }))
  }

  createNewGrid(isButtonClick?: boolean): void {
    const id = this.gridInstance.id;
    this.addGrid(id);
    const formArray = this.mainFormGroup.get(id) as FormArray;
    const gridUniqueId = new Date().toString() + this.index.toString();
    this.index++;
    const obj = {
      gridIndex: formArray.length - 1,
      gridId: id,
      formLoadCompleted: false,
      gridUniqueId,
    };
    this.gridSequences.push(obj);
    if (isButtonClick) {
      this.defaultPageLoadService.logButtonClick(this.addAccordionText);
    }
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  removeRowGrid(grid: any) {
    this.gridSequences.splice(grid.gridIndex, 1);
    const control = this.mainFormGroup.get(grid.gridId) as FormArray;
    control.removeAt(grid.gridIndex);
    this.updateGridSequence(grid);
  }

  private enableGrid(): void {
    setTimeout(() => {
      this.mainFormGroup?.enable();
    }, 100);
  }

  private updateGridSequence(deletedObj): void {
    for (const obj of this.gridSequences) {
      if (
        obj.gridId === deletedObj.gridId &&
        obj.gridIndex > deletedObj.gridIndex
      ) {
        obj.gridIndex -= 1;
      }
    }
  }
}
