import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import * as _ from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';
import { Question, GridInstance } from 'src/app/shared/models/casePage.model';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, BeneficiaryErrors } from 'src/config/constants';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { ClipboardService } from '@ng/lfg-common-utilities';
import { LfgLoaderService } from '@ng/lfg-loader';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-beneficiary-info',
  templateUrl: './beneficiary-info.component.html'
})
export class BeneficiaryInfoComponent implements OnInit {

  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Input() type;
  @Input() isWholesaler: boolean;

  questionsData;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  beneInfoData: Question[] = [];
  beneInfoForm: any;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  gridHasNoErrors = false;
  formHasNoErrors = true;
  isGridFormValid = true;
  isGridFormChange = false;
  placeholders = {};
  alldropDownOptions = {};
  setTimeFocus = null;
  gridQuestionsObj;
  isTabVisited: boolean;
  defaultValueForMultiSelect;

  noOfTrustSelected = 0;
  maxNoOfBene: number;
  ownerAnswers = [];
  noOwnerError;

  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  beneError = BeneficiaryErrors;
  multipleTrustError = ErrorMessage.TRUST_OWNER_ERROR;
  hideAddLink = false;
  noBeneError = false;
  nameFieldnameKeys = [];
  gridInstances = [];
  displayGrid = false;
  shareEqually = false
  primaryHideLinkKey1 = [];
  primaryHideLinkKey2 = '';
  primaryShareEquallyKey1 = [];
  primaryShareEquallyKey2 = '';
  contingentHideLinkKey1 = '';
  contingentShareEquallykey1 = '';
  showOwner = false;
  removeAdditionalOwner = false;
  multiselectConfig = this.appConfig.dropdownWithCheckboxConfig;
  sharePercentageNameKey = [];
  addressFieldnameKeys = [];
  loadFlag = true
  showLoader = true;
  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private clipboardService: ClipboardService,
    private loaderService: LfgLoaderService
  ) { }

  ngOnInit(): void {
    this.addLoader(true);
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      const pageIdx = this.type === "Primary" ? 0 : 1;
      this.buildFormData(pageIdx);
      this.gridInstance = this.questionsData?.pages[pageIdx]?.gridInstances;
      this.gridAnswers = this.questionsData?.pages[pageIdx]?.gridAnswers;
      this.maxNoOfBene = +(this.gridInstance[0]?.maxGridIndex);
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.beneInfoForm);
      this.defaultPageLoadService.disableFormIfLocked(this.beneInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      setTimeout(()=>{
        this.loadFlag=false
      },1000)
    }
    this.addLoader(false);
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.questions?.currentValue) {
      this.questions = change.questions.currentValue;
      const pageIdx = this.type === "Primary" ? 0 : 1;
      this.buildFormData(pageIdx);
    }
  }

  private buildFormData(page): any {
    this.gridQuestionsObj = {};
    this.nameFieldnameKeys = [];
    this.sharePercentageNameKey = [];
    this.gridAnswers = this.questions.pages[page].gridAnswers || [];
    this.gridInstances = this.questions.pages[page].gridInstances;
    for (const gridData of this.gridInstances) {
      this.gridQuestionsObj[gridData.id] = gridData;
      if (this.type === 'Primary') {
        this.sharePercentageNameKey.push(gridData.gridQuestions[0].reflexiveQuestionAL[0].name);
      }
      for (const fieldObj of gridData.gridQuestions[0].reflexiveQuestionAL) {
        if (fieldObj && (fieldObj.xmlTag.toLowerCase().indexOf('/xml/client/crmdetails/firstname') >= 0
                        || fieldObj.xmlTag.toLowerCase().indexOf('/xml/client/crmdetails/lastname') >= 0)) {
          this.nameFieldnameKeys.push(fieldObj.name);
        }else if (fieldObj && fieldObj.xmlTag === this.appConfig.beneKeys.addressSameAsKey){
          fieldObj.reflexiveQuestionAL.forEach(ques=>{
            this.addressFieldnameKeys.push(ques.name)
          })
        }
      }
    }
    this.beneInfoData = this.questionsData.pages[page].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(this.beneInfoData);
    const form = this.formgeneratorService.createFormControls(this.beneInfoData);
    this.beneInfoForm = this.fb.group(form);
    this.generateFormKey()
    this.valueChanges();
    this.displayGridCondition();
    this.checkIfOwnersAvailableAndPopulate();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.beneInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  onRadioChange(data, uncheckedValue?): any {
    setTimeout(() => {
      this.updatedAnswersOnHidden(data);
      this.displayGridCondition();
    }, 500);
    if (this.type === 'Primary' && data.fieldId.indexOf(this.appConfig.primaryBeneDisplayGridKeys[0]) >= 0) {
      this.noOwnerErrorDisplay(data);
      if (this.beneInfoForm.get(data.fieldId)?.value.toLowerCase().indexOf('no') >= 0) {
        this.noOwnerError = false;
      } else {
        this.beneInfoForm.get(data?.reflexiveQuestionAL[0]?.fieldId)?.setValue(null);
      }
      this.defaultValueForMultiSelect = data.reflexiveQuestionAL[0].questionOption;
      for (const optionKey of this.defaultValueForMultiSelect) { // tslint:disable-next-line:no-string-literal
        optionKey['checked'] = false;
      }
      this.filterOwnersOnDropDownSelection(this.defaultValueForMultiSelect, true);
    }
    if (this.type === 'Contingent' && data.fieldId.indexOf(this.appConfig.contingentBeneDisplayGridKeys[0]) >= 0 &&
      this.beneInfoForm.get(data.fieldId)?.value.toLowerCase().indexOf('no') >= 0
    ) {
      this.displayGrid = false;
      this.clearGrids()
    }
  }

  displayGridCondition(): boolean {
    const primaryOrContingent = this.type === 'Primary'
                                ? this.appConfig.primaryBeneDisplayGridKeys
                                : this.appConfig.contingentBeneDisplayGridKeys;
    const key1 = this.utilsService.getFieldId(this.beneInfoData, primaryOrContingent[0]);
    if (this.type === 'Primary') {
      this.displayConditionPrimary(key1);
    } else if (this.type === 'Contingent' && key1) {
      this.displayGrid = this.beneInfoForm && this.beneInfoForm.get(key1).value.toLowerCase().indexOf('yes') >= 0;
      if (this.gridAnswers && this.gridAnswers.length > 0) {
        this.displayGrid = true;
      }
    }
    if (this.displayGrid) {
      return true;
    }
    this.clearGrids();
    return false;
  }

  displayConditionPrimary(key1: string): void {
    const dropDownKey = this.utilsService.getFieldId(this.beneInfoData, '/XML/ApplicationDetail/OwnerSameAsBeneValue');
    this.displayGrid =
      (this.beneInfoForm.get(key1).value.toLowerCase().indexOf('yes') >= 0
      && (this.beneInfoForm.get(dropDownKey).value || (this.gridAnswers && this.gridAnswers.length > 0)))
      || this.beneInfoForm.get(key1).value.toLowerCase().indexOf('no') >= 0;
    if (this.gridAnswers && this.gridAnswers.length > 0) {
      this.displayGrid = true;
    }
  }

  clearGrids(): void {
    // grids are hidden
    this.gridAnswers = [];
    this.isGridFormValid = true;
    this.gridHasNoErrors = true;
  }

  handleSelectionChange(data, ix?): any {
    this.updatedAnswersOnHidden(data);
  }

  updatedAnswersOnHidden(beneInfoData, uncheckedValue?): void {
    const form = this.beneInfoForm;
    const value = form.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(beneInfoData, value, uncheckedValue);
    if (updatedval) {
      for (const key in updatedval) {
        if (this.gridQuestionsObj.hasOwnProperty(key)) {
            this.clearGrids();
            delete updatedval[key];
        } else {
          form.get(key)?.markAsUntouched();
        }
      }
      form.patchValue(updatedval);
    }
  }

  checkFormStatus(): void {
    if (this.type === 'Primary') {
      this.setInputForPrimaryGrid();
    } else {
      this.setInputForContingentGrid();
    }
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid && !this.noOwnerError,
      formHasNoErrors: this.formHasNoErrors,
      form: this.beneInfoForm,
      data: null,
    };
    for (const data of this.beneInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  setInputForPrimaryGrid(): void {
    // hiding additional bene link
    const hideLinkAns1 = this.beneInfoForm.value[this.primaryHideLinkKey1[0].fieldId] || '';
    const hideLinkAns2 = this.beneInfoForm.value[this.primaryHideLinkKey1[1].fieldId] || '';
    if(hideLinkAns1!='' || hideLinkAns2!=''){
      this.hideAddLink = !(hideLinkAns1.toLowerCase().indexOf('yes') >= 0 || hideLinkAns2.toLowerCase().indexOf('yes') >= 0);
    }
    // share equally logic
    const shareRadioAns1 = this.beneInfoForm.value[this.primaryShareEquallyKey1[0].fieldId] || '';
    const shareRadioAns2 = this.beneInfoForm.value[this.primaryShareEquallyKey1[1].fieldId] || '';
    // tslint:disable-next-line:max-line-length
    this.shareEqually = shareRadioAns1.toLowerCase().indexOf('yes') >= 0 || shareRadioAns2.toLowerCase().indexOf('yes') >= 0 || this.hideAddLink;
    // show owners as primary bene condition
    const ownersAsBene = this.beneInfoForm.value[this.beneInfoData[0].fieldId] || '';
    this.showOwner = ownersAsBene.toLowerCase().indexOf('yes') >= 0;
    this.removeAdditionalOwner = hideLinkAns1 === '' && hideLinkAns2 === '' ? true : this.hideAddLink;
  }

  setInputForContingentGrid(): void {
    // hiding additional bene link for contingent
    const hideLinkAns = this.beneInfoForm.value[this.contingentHideLinkKey1] || '';
    this.hideAddLink = hideLinkAns.toLowerCase().indexOf('yes') === -1;
    // share equally logic for contingent
    const shareRadioAnswer = this.beneInfoForm.value[this.contingentShareEquallykey1] || '';
    this.shareEqually = shareRadioAnswer.toLowerCase().indexOf('yes') >= 0 || this.hideAddLink;
    this.removeAdditionalOwner = hideLinkAns === '' ? true : this.hideAddLink;
  }

  gridStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.isGridFormChange = event.isGridFormChange;
    this.emitStatus();
  }

  emitStatus(): void {
    const pageIdx = this.type === "Primary" ? 0 : 1;
    this.questions.pages[pageIdx].gridAnswers = this.gridAnswers;
    const beneInfoObj = {
      formValid: this.formValid && this.isGridFormValid && this.noOfTrustSelected < 2,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'beneInfoForm',
      gridForm: true,
      isFormChange: this.beneInfoForm.dirty || this.isGridFormChange || !this.isTabVisited || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.beneInfoForm) , 
    };
    this.formStatus.emit(beneInfoObj);
  }

  nestedQuestionCheckBene(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  addCorrectPlaceHolder(data, type): any {
    this.utilsService.updatePolicyRelatedData(data);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  generateFormKey(): void {
    this.primaryHideLinkKey1 = this.utilsService.getFieldIdForAll(this.beneInfoData, this.appConfig.beneKeys.primaryHideLink);
    this.primaryHideLinkKey2 = this.utilsService.getFieldId(this.beneInfoData, this.appConfig.beneKeys.primaryHideLink);

    this.primaryShareEquallyKey1 = this.utilsService.getFieldIdForAll(this.beneInfoData, this.appConfig.beneKeys.primaryShareEqually);
    this.primaryShareEquallyKey2 = this.utilsService.getFieldId(this.beneInfoData, this.appConfig.beneKeys.primaryShareEqually);

    this.contingentHideLinkKey1 = this.utilsService.getFieldId(this.beneInfoData, this.appConfig.beneKeys.contingentHideLink);
    this.contingentShareEquallykey1 = this.utilsService.getFieldId(this.beneInfoData, this.appConfig.beneKeys.contingentShareEqually);
  }

  getCheckedValues(obj: any): void {
    setTimeout(() => {
      if (obj.ev.optValue) {
        this.beneInfoForm.get(obj.data.fieldId).value = obj.ev.value;
      }
      if (!obj.ev.optChecked) {
        // optValue gives the value of chekbox which is unchecked
        this.updatedAnswersOnHidden(obj.data, obj.ev.optValue);
      } else {
        this.alldropDownOptions[obj.data.fieldId] = obj.ev.options;
        this.beneInfoForm.get(obj.data.fieldId)?.setErrors(null);
        this.beneInfoForm.get(obj.data.fieldId)?.markAsUntouched();
      }
      this.filterOwnersOnDropDownSelection(obj.ev.options, false, true);
      this.checkFormStatus()
    }, 100);
  }

  checkIfOwnersAvailableAndPopulate(): void {
    if (this.type === 'Primary') {
      const dropDownKey = this.utilsService.getFieldId(this.beneInfoData, this.appConfig.beneKeys.ownerSameAsBene);
      const ownersDropDownVal = this.beneInfoForm.get(dropDownKey).value;
      this.defaultValueForMultiSelect = this.beneInfoData[0].reflexiveQuestionAL[0].questionOption;
      if (ownersDropDownVal) {
        const ansArray = [];
        const dropDownVal = this.beneInfoData[0].reflexiveQuestionAL[0].question_answer;
        for (const optionKey of this.defaultValueForMultiSelect) {
          const dropDownAnsObj: any = {};
          dropDownAnsObj.checked = false;
          if (dropDownVal && dropDownVal.indexOf(optionKey.value) >= 0) {
            dropDownAnsObj.checked = true;
          }
          dropDownAnsObj.label = optionKey.description;
          dropDownAnsObj.value = optionKey.value;
          ansArray.push(dropDownAnsObj);
        }
        this.filterOwnersOnDropDownSelection(ansArray);
      } else {
        const dropDownAnsObj: any = {};
        dropDownAnsObj.checked = false;
        const defaultValArray = [];
        defaultValArray.push(dropDownAnsObj);
        this.filterOwnersOnDropDownSelection(defaultValArray, true);
      }
      this.noOwnerErrorDisplay(this.beneInfoData[0]);
    }
  }

  filterOwnersOnDropDownSelection(optionsArray, clearFlag?, updateOwner?): void {
    this.displayGrid = false
    const ownerAsBene = this.questions.pages[0].ownerAsBene;
    let gridsToRemove = []
    this.ownerAnswers = ownerAsBene.filter((owner,idx:number)=>{
      if(optionsArray[idx]?.checked){
        return true
      }else{
        gridsToRemove.push(owner)
      }
    })

    this.gridAnswers = _.differenceWith(this.gridAnswers,gridsToRemove,(g1,g2)=>this.compareGrids(g1,g2))

    if (
      this.ownerAnswers &&
      this.ownerAnswers.length < 1 &&
      this.gridAnswers &&
      this.gridAnswers.length < 1 &&
      !clearFlag
    ) {
      this.displayGrid = false;
    } else {
      setTimeout(() => {
        this.displayGridCondition();
      }, 10);
    }
  }

  compareGrids(grid1,grid2){
    grid1 = this.getObjForComparison(grid1)
    grid2 = this.getObjForComparison(grid2)
    return _.isEqual(grid1,grid2)
  }

  getOwnerNameFromGrid(gridObject): any {
    let ownerNameFromGrid = '';
    for (const key of this.nameFieldnameKeys) {
      if (gridObject.hasOwnProperty(key)) {
        ownerNameFromGrid += gridObject[key] + ' ';
      }
    }
    return ownerNameFromGrid;
  }

  noOwnerErrorDisplay(data): void {
    const childData = data.reflexiveQuestionAL[0];
    if (
      this.beneInfoForm
        .get(data.fieldId)
        ?.value.toLowerCase()
        .indexOf('yes') >= 0 &&
      childData &&
      childData.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX &&
      childData.notes === 'ITES_FORMAT_AS_DROPDOWN_CHECKBOX' &&
      childData.questionOption.length === 0
    ) {
      this.noOwnerError = true;
    } else {
      this.noOwnerError = false;
    }
  }

  getObjForComparison(gridAnsObj): void {
    const updatedObj = JSON.parse(JSON.stringify(gridAnsObj));
    for (const nameKeyForPercentage of this.sharePercentageNameKey) {
      if (gridAnsObj?.hasOwnProperty(nameKeyForPercentage)) {
        delete updatedObj[nameKeyForPercentage]
      }
    }
    for (const addressKey of this.addressFieldnameKeys){
      if (gridAnsObj?.hasOwnProperty(addressKey)) {
        delete updatedObj[addressKey]
      }
    }
    return updatedObj;
  }

  addLoader(isAddLoader: boolean): void {
    if (isAddLoader) {
      this.showLoader = true;
      this.loaderService.show();
    } else {
      setTimeout(()=>{
        this.showLoader = false;
        this.loaderService.hide();
      }, 0);
    }
  }

}
