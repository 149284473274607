<section class="consent-page page-content-container">
  <div *ngIf="showLoader">
    <lfg-loader></lfg-loader>
  </div>
  <!-- show consent page when consent & docusign status is not found -->

  <div class="questions--container" *ngIf="((consentStatus?.length === 0 || consentStatus === 'APPROVED') && docuSignState?.length === 0) || (userFunctionalityConfig?.accessType !== accessType.SUPERACCESS && consentStatus === 'READY_TO_SIGN' && docuSignState?.length === 0)">
    <h1 class="lfg-color">
      Terms of Use & Consent
    </h1>
    <lfg-alert-message *ngIf="!disableForm && isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <p [innerHTML]="welcomeMsg"></p><br>
    <p [innerHTML]="consentConstant.CONSENT_INFO"></p>

    <!-- Informational Text -->
    <div class="consent-info" aria-live="assertive">
      <lfg-alert-message type="info">{{ consentConstant.CONSENT_INFO_2 }}</lfg-alert-message>
    </div>

    <!-- Element for label -->
    <div class="information-container">
      <div class="information-header">
        <h2 class="info-heading">{{consentConstant.TERM_OF_USE_TITLE}}</h2>
        <a class="print-link" (click)="viewContent('term')"><i class="fas fa-print print-icon" aria-hidden="true"></i>PRINT</a>
      </div>
      <ng-container>
        <div class="information-content" [innerHtml]="consentConstant.TERM_OF_USE"></div>
      </ng-container>

      <div class="information-header">
        <h2 class="info-heading">{{consentConstant.CONSENT_ESIGN_TITLE}}</h2>
        <a class="print-link" (click)="viewContent('consent')"><i class="fas fa-print print-icon" aria-hidden="true"></i>PRINT</a>
      </div>
      <ng-container>
        <div class="information-content" [innerHtml]="consent"></div>
      </ng-container>
    </div>

    <!-- Element for instruction -->
    <div class="consent-instructions">
      <p class="inst-font">Instructions</p>
      <div [innerHTML]="consentConstant.CONSENT_INSTRUCTION"></div>
    </div>

    <form class="form-group" [formGroup]="consentForm" id="consentForm" method="post" *ngIf="consentPageData && consentPageData.length > 0">
      <ng-container class="form-control-parent" *ngFor="let data of consentPageData; let ind = index;">

        <!-- Form element for check box  -->
        <div class="form--control form--control__full-width consent-agree-block" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX" [ngClass]="{'consent-valid': consentForm.valid}">
          <div class="lfg-checkbox">
            <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'">
            <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
          </div>
        </div>

      </ng-container>
    </form>

    <!-- Informational Text -->
    <div class="question-info" aria-live="assertive" *ngIf="consentForm?.valid">
      <lfg-alert-message type="warning">{{ consentConstant.INFO_TEXT_1 }}</lfg-alert-message>
    </div>

    <!-- Element for Line -->
    <div class="section-divider" *ngIf="consentForm">
    </div>
    <section class="btn-conatiner" *ngIf="consentForm">
      <lfg-left-button-tray>
        <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="I Agree" [disabled]="!consentForm?.valid" (click)="agreeToContent()">I Agree</button>
      </lfg-left-button-tray>
      <lfg-left-button-tray>
        <button class="decline-btn" lfgBorderButton type="button" id="close" tabindex="0" aria-label="I decline" [disabled]="!consentForm.valid" (click)="openConfirmationModal()">I DECLINE</button>
      </lfg-left-button-tray>
    </section>

    <!-- Element for Line -->
    <div class="section-divider" *ngIf="consentForm">
    </div>

    <!-- For Safari users info text -->
    <div aria-live="assertive" class="safari-info" *ngIf="isSafariBrowser">
      <lfg-alert-message type="warning">{{consentConstant.SAFARI_USER_SETTING_MSG_1}}<br>{{consentConstant.SAFARI_USER_SETTING_MSG_2}}<br>{{consentConstant.SAFARI_USER_SETTING_MSG_3}}</lfg-alert-message>
    </div>

    <p class="pad-bot-20">{{consentConstant.START_SIGNATURE_INFO}}</p>

    <section class="btn-conatiner" *ngIf="consentForm">
      <lfg-left-button-tray>
        <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Start E Signature" [disabled]="(!userAccessDetails?.editCase) || (!consentForm?.valid || (userAccessDetails?.editCase && consentStatus !== 'APPROVED' && consentStatus !== 'READY_TO_SIGN'))" (click)="startESignature()">Start eSignature</button>
      </lfg-left-button-tray>
    </section>

  </div>



  <!-- show docuSignPage when consent is ready to sign and docuSign is not done-->
  <ng-container *ngIf="showDocusignPage && consentStatus === 'READY_TO_SIGN' && docuSignState?.length === 0">
    <app-docusign-iframe [docusignUrl]="docusignUrl" class="iframe-component-container" (docuSignStatus)="docuSignStatus($event)"></app-docusign-iframe>
  </ng-container>

  <!-- show thank you component when consent and docuSign both accepted -->
  <div class="thank-you-container" *ngIf="consentStatus === 'READY_TO_SIGN' && docuSignState === 'ACCEPTED'">
    <app-thankyou-page [thankyouPageType]="'consumerThankyouPage'" *ngIf="!isAgent"></app-thankyou-page>
    <app-thankyou-page [thankyouPageType]="'agentThankyouPage'" *ngIf="isAgent"></app-thankyou-page>
  </div>

  <!-- show declined page when consent or docuSign is declined -->
  <div class="decline-message-container" *ngIf="consentStatus === 'DECLINED' || docuSignState === 'DECLINED'">
    <app-thankyou-page [thankyouPageType]="'declineToSign'"></app-thankyou-page>
  </div>
</section>

<lfg-modal-popup *ngIf="declineConsentModal" [modalConfig]="declineConsentModal" (closeModalPopup)="closeDeclineModalPopup()">
  <div modal-header>
    <h1 class="lfg-color">{{ consentConstant.DECLINE_MODAL_HEADING }}</h1>
  </div>
  <div modal-body>
    <div class="info-label">{{consentConstant.DECLINE_INFO_LABEL}}</div>
    <div class="decline-info-msg" [innerHTML]="consentConstant.DECLINE_INFO"></div>
    <div class="decline-info-msg" [innerHTML]="consentConstant.DECLINE_INFO2"></div>
    <div class="confirmation-msg">{{consentConstant.DECLINE_CONFIRMATION_MSG}}</div>
  </div>
  <div modal-footer>
    <lfg-left-button-tray>
      <button type="button" class='btn' (click)="declineConsent()" [attr.aria-label]="consentConstant.CONFIRM_BUTTON" lfgDefaultButton>{{consentConstant.CONFIRM_BUTTON}}</button>
      <button type="button" class='btn' (click)="closeDeclineModalPopup()" [attr.aria-label]="consentConstant.DECLINE_BUTTON" lfgDefaultButton>{{consentConstant.DECLINE_BUTTON}}</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>
