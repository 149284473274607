<section class="page-content-container">
    <div class="questions--container">
        <h1 *ngIf="type === 'Primary'">
            Primary Beneficiary Information
        </h1>
        <h1 *ngIf="type === 'Contingent'">
            Contingent Beneficiary Information
        </h1>
        <div class="loader-container" *ngIf="showLoader">
          <lfg-loader></lfg-loader>
        </div>
        <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isGridFormValid)" type="error">{{errorMessage}}</lfg-alert-message>
        <section>
            <form class="form-group" [formGroup]="beneInfoForm" id="beneInfoForm" ngClass="beneInfoForm" method="post" *ngIf="beneInfoData && beneInfoData.length > 0">
                <ng-container class="form-control-parent" *ngFor="let data of beneInfoData; let ind = index;" >


                  <!-- Questions headers -->
                  <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information'"
                  [innerHTML]="data.questionText">
                  </div>

                  <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
                  </div>

                  <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                  <div class="questionText" *ngIf="addCorrectPlaceHolder(data, 'display')">
                    <span [innerHTML]="addCorrectPlaceHolder(data, 'question')"></span>
                  </div>

                  <!-- USA address -->
                  <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                    <h2>{{ data.questionText }}</h2>
                  </div>

                  <!-- Form element for text  -->
                  <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes === null || data.notes === '')"
                    [ngClass]="{'resetError': !beneInfoForm.get(data.fieldId).errors}">
                    <lfg-input-wrap>
                      <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text'
                      customAttr="text" formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols
                      [maxlength]="data.maxLength >= 999 ? 30 : data.maxLength" [required]="data.required == 'true'">

                      <lfg-error *ngIf="beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                   <!-- Form element for zip  -->
                  <div class="form--control" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" mask='00000' id="{{data.fieldId}}"
                        placeholder="{{ utilsService.getCorrectContent(data, 'placeHolder') }}" appNoSpecialSymbols
                        type='text' [formControl]='beneInfoForm.get(data.fieldId)'
                        autocomplete="off" appValidateOnBlur value="" maxlength='5'
                        [ngClass]="{'inp-txt--err': (beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid) }"
                        [required]="data.required === 'true'">

                      <lfg-error
                        *ngIf="beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid"
                        message="{{ getErrorMsg(data) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>


                  <!-- Form element for SSN -->
                  <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}"
                        mask='AAA-AA-0000' [formControl]='beneInfoForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9'
                        name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="data.required == 'true'">

                      <lfg-error *ngIf="(beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid) && ( beneInfoForm.get(data.fieldId).errors == null || (beneInfoForm.get(data.fieldId).errors != null && !beneInfoForm.get(data.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>

                      <lfg-error *ngIf="(beneInfoForm.get(data.fieldId).invalid && beneInfoForm.get(data.fieldId).touched) &&(beneInfoForm.get(data.fieldId).errors != null && beneInfoForm.get(data.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for radio question -->
                  <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO"
                    [ngClass]="{'radio--err': (beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid)}">
                    <span class="lfg-radio-section">
                      <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
                      <div class="custom-radio-button">
                        <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio"
                          formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}"
                          (change)="onRadioChange(data)"/>
                        <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                        <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio"
                          formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}"
                          (change)="onRadioChange(data)">
                        <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error *ngIf="beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
                  </div>

                  <!-- Form Element for date  -->
                  <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
                    <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')"
                      lfgCheckValidDate [formControl]="beneInfoForm.get(data.fieldId)" [config]="dateConfig"
                      [class.calendar--err]="(beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
                      <lfg-error *ngIf="beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
                    </lfg-calendar>
                  </div>

                  <!-- Form element for Drop Down -->
                  <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
                    <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]"
                      placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'"
                      [ngClass]="{'dropdown--err': (beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid)}">
                    </lfg-dropdown>
                    <lfg-error *ngIf="beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, beneInfoForm.get(data.fieldId)) }}"></lfg-error>

                  </div>

                  <!-- Form element for phone number -->
                  <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text'
                        formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
                      <lfg-error *ngIf="(beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for email  -->
                  <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                    <lfg-input-wrap>
                      <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text'
                        maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email"
                        [required]="data.required == 'true'">

                      <lfg-error *ngIf="beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for alphanumeric  -->
                  <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}"
                        appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value=""
                        [maxlength]="data.maxlength" [required]="data.required == 'true'">

                      <lfg-error *ngIf="beneInfoForm.get(data.fieldId).touched && beneInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Question Info -->
                  <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
                    <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
                  </div>

                  <!-- Nestion question Level 1 -->
                  <ng-container *ngIf="data.hasReflexive == 'true' && beneInfoForm.get(data.fieldId).value as parentAnswer">
                    <ng-container *ngFor="let benechildL1 of data.reflexiveQuestionAL; index as benechildL1ix">
                        <ng-container *ngIf="nestedQuestionCheckBene(data, benechildL1, parentAnswer)">

                            <div class="section-divider"></div>

                            <!-- Questions headers -->
                            <div class="questions-header" *ngIf="benechildL1.controlTypeDesc == appConfig.fieldTypes.HDR && benechildL1.questionText != 'Line' && benechildL1.subText != 'Information'"
                            [innerHTML]="benechildL1.questionText">
                            </div>

                            <div class="section-divider" *ngIf="benechildL1.controlTypeDesc == appConfig.fieldTypes.HDR && benechildL1.questionText == 'Line'">
                            </div>

                            <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                            <div class="questionText" *ngIf="addCorrectPlaceHolder(benechildL1, 'display')">
                              <span [innerHTML]="addCorrectPlaceHolder(benechildL1, 'question')"></span>
                            </div>

                            <!-- USA address -->
                            <div class="questions-header" *ngIf="benechildL1.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                              <h2>{{ benechildL1.questionText }}</h2>
                            </div>

                            <!-- Form element for text  -->
                            <div class="form--control" *ngIf="(benechildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && (benechildL1.notes == null || benechildL1.notes == '')) || benechildL1.controlTypeDesc == appConfig.fieldTypes.EMAIL || benechildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                            <lfg-input-wrap>
                                <input lfgInput aria-live="off" id="{{benechildL1.fieldId}}" name="{{formotivService.formotivTextLabel(benechildL1)}}" placeholder="{{ utilsService.getCorrectContent(benechildL1, 'placeHolder') }}" type='text' formControlName='{{ benechildL1.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="appConfig.customAttr[benechildL1.controlTypeDesc]"
                                [ngClass]="{'inp-txt--err': (beneInfoForm.get(benechildL1.fieldId).touched && beneInfoForm.get(benechildL1.fieldId).invalid) }" maxlength='{{benechildL1.controlTypeDesc == appConfig.fieldTypes.EMAIL ? 50 : 30}}' [required]="benechildL1.required == 'true'">

                                <lfg-error *ngIf="beneInfoForm.get(benechildL1.fieldId).touched && beneInfoForm.get(benechildL1.fieldId).invalid" message="{{ utilsService.getInlineErrorMessage(benechildL1) }}"></lfg-error>
                            </lfg-input-wrap>
                            </div>

                            <!-- Form element for Drop Down -->
                            <div class="form--control form-dropDown" *ngIf="benechildL1.controlTypeDesc == appConfig.fieldTypes.SELECT">
                            <lfg-dropdown formControlName="{{ benechildL1.fieldId }}" [options]="alldropDownOptions[benechildL1.fieldId]" placeholder="{{ utilsService.getCorrectContent(benechildL1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(benechildL1, ind)" [required]="benechildL1.required == 'true'" (keydown)="utilsService.preventBack($event)">
                            </lfg-dropdown>

                            <lfg-error *ngIf="beneInfoForm.get(benechildL1.fieldId).touched && beneInfoForm.get(benechildL1.fieldId).invalid" message="{{ utilsService.getInlineErrorMessage(benechildL1) }}"></lfg-error>
                            </div>

                            <!-- Form element for radio question -->
                            <div class="lfg-customRadio-field radio-btn-custom" *ngIf="benechildL1.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                [ngClass]="{'radio--err': (beneInfoForm.get(benechildL1.fieldId).touched && beneInfoForm.get(benechildL1.fieldId).invalid)}">
                                <span class="lfg-radio-section">
                                <p class="question-radio_text">{{ benechildL1.questionText + (benechildL1.required == 'true' ? '' : ' (optional)') }}</p>
                                <div class="custom-radio-button">
                                    <input [attr.aria-label]="benechildL1.questionOption[0].description" tabindex="0" id="yes_{{ benechildL1.fieldId }}" type="radio"
                                    formControlName="{{ benechildL1.fieldId }}" name="{{ benechildL1.fieldId }}" value="{{ benechildL1.questionOption[0].value }}" required="{{benechildL1.required}}"
                                    (change)="onRadioChange(benechildL1)"/>
                                    <label for="yes_{{ benechildL1.fieldId }}">{{ benechildL1.questionOption[0].description }}</label>
                                    <input [attr.aria-label]="benechildL1.questionOption[1].description" tabindex="0" id="no_{{ benechildL1.fieldId }}" type="radio"
                                    formControlName="{{ benechildL1.fieldId }}" name="{{ benechildL1.fieldId }}" value="{{ benechildL1.questionOption[1].value }}" required="{{benechildL1.required}}"
                                    (change)="onRadioChange(benechildL1)">
                                    <label for="no_{{ benechildL1.fieldId }}">{{ benechildL1.questionOption[1].description }}</label>
                                </div>
                                </span>
                                <lfg-error *ngIf="beneInfoForm.get(benechildL1.fieldId).touched && beneInfoForm.get(benechildL1.fieldId).invalid " message="{{ getErrorMsg(benechildL1) }}"></lfg-error>
                            </div>

                            <!-- Form element for Drop Down with checkbox -->
                            <div class="form--control form-dropDown-multi-checkbox" *ngIf="(benechildL1.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && benechildL1.notes == 'ITES_FORMAT_AS_DROPDOWN_CHECKBOX') && benechildL1.questionOption.length > 0 && alldropDownOptions[benechildL1.fieldId].length > 0"
                              [ngClass]="{'resetError': !beneInfoForm.get(benechildL1.fieldId).errors}">
                              <lfg-dropdown-multi-filter [options]="alldropDownOptions[benechildL1.fieldId]" [selectedOption]="null" [disableSearch]="true"
                                [multiSelect]="multiselectConfig" [id]="benechildL1.fieldId" (multiSelectChecked)="getCheckedValues({ev: $event, data: benechildL1})"
                                formControlName='{{ benechildL1.fieldId }}' placeholder="{{ benechildL1.questionText }}" required="benechildL1.required === 'true'"
                                [ngClass]="{'dropdown-multi--required': benechildL1.required === 'true'}">
                              </lfg-dropdown-multi-filter>
                              <lfg-error
                              *ngIf="beneInfoForm.get(benechildL1.fieldId).touched && beneInfoForm.get(benechildL1.fieldId).invalid"
                              message="{{ getErrorMsg(benechildL1) }}"></lfg-error>
                            </div>

                            <!-- error message if no options available for drop-down with checkbox -->
                            <div aria-live="assertive">
                              <div *ngIf="noOwnerError && benechildL1.notes == 'ITES_FORMAT_AS_DROPDOWN_CHECKBOX'">
                                <lfg-alert-message type="error" message='{{ beneError.noOwner }}'></lfg-alert-message>
                              </div>
                            </div>

                            <!-- Nestion question Level 2 -->
                            <ng-container *ngIf="benechildL1.hasReflexive == 'true' && beneInfoForm.get(benechildL1.fieldId).value as parentAnswer">
                              <ng-container *ngFor="let benechildL2 of benechildL1.reflexiveQuestionAL; index as benechildL2ix">
                                <ng-container *ngIf="nestedQuestionCheckBene(benechildL1, benechildL2, parentAnswer)">

                                  <div class="section-divider"></div>

                                  <!-- Questions headers -->
                                  <div class="questions-header" *ngIf="benechildL2.controlTypeDesc == appConfig.fieldTypes.HDR && benechildL2.questionText != 'Line' && benechildL2.subText != 'Information'"
                                  [innerHTML]="benechildL2.questionText">
                                  </div>

                                  <div class="section-divider" *ngIf="benechildL2.controlTypeDesc == appConfig.fieldTypes.HDR && benechildL2.questionText == 'Line'">
                                  </div>

                                  <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                                  <div class="questionText" *ngIf="addCorrectPlaceHolder(benechildL2, 'display')">
                                    <span [innerHTML]="addCorrectPlaceHolder(benechildL2, 'question')"></span>
                                  </div>

                                  <!-- USA address -->
                                  <div class="questions-header" *ngIf="benechildL2.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                                    <h2>{{ benechildL2.questionText }}</h2>
                                  </div>

                                  <!-- Form element for text  -->
                                  <div class="form--control" *ngIf="(benechildL2.controlTypeDesc == appConfig.fieldTypes.TEXT && (benechildL2.notes == null || benechildL2.notes == '')) || benechildL2.controlTypeDesc == appConfig.fieldTypes.EMAIL || benechildL2.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                                      <lfg-input-wrap>
                                      <input lfgInput aria-live="off" id="{{benechildL2.fieldId}}" name="{{formotivService.formotivTextLabel(benechildL2)}}" placeholder="{{ utilsService.getCorrectContent(benechildL2, 'placeHolder') }}" type='text' formControlName='{{ benechildL2.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="appConfig.customAttr[benechildL2.controlTypeDesc]"
                                      [ngClass]="{'inp-txt--err': (beneInfoForm.get(benechildL2.fieldId).touched && beneInfoForm.get(benechildL2.fieldId).invalid) }" maxlength='{{benechildL2.controlTypeDesc == appConfig.fieldTypes.EMAIL ? 50 : 30}}' [required]="benechildL2.required == 'true'">

                                      <lfg-error *ngIf="beneInfoForm.get(benechildL2.fieldId).touched && beneInfoForm.get(benechildL2.fieldId).invalid" message="{{ utilsService.getInlineErrorMessage(benechildL2) }}"></lfg-error>
                                      </lfg-input-wrap>
                                  </div>

                                  <!-- Form element for Drop Down -->
                                  <div class="form--control form-dropDown" *ngIf="benechildL2.controlTypeDesc == appConfig.fieldTypes.SELECT">
                                      <lfg-dropdown formControlName="{{ benechildL2.fieldId }}" [options]="alldropDownOptions[benechildL2.fieldId]" placeholder="{{ utilsService.getCorrectContent(benechildL2, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(benechildL2, ind)" [required]="benechildL2.required == 'true'" (keydown)="utilsService.preventBack($event)">
                                      </lfg-dropdown>

                                      <lfg-error *ngIf="beneInfoForm.get(benechildL2.fieldId).touched && beneInfoForm.get(benechildL2.fieldId).invalid" message="{{ utilsService.getInlineErrorMessage(benechildL2) }}"></lfg-error>
                                  </div>

                                  <!-- Form element for radio question -->
                                  <div class="lfg-customRadio-field radio-btn-custom" *ngIf="benechildL2.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                      [ngClass]="{'radio--err': (beneInfoForm.get(benechildL2.fieldId).touched && beneInfoForm.get(benechildL2.fieldId).invalid)}">
                                      <span class="lfg-radio-section">
                                      <p class="question-radio_text">{{ benechildL2.questionText + (benechildL2.required == 'true' ? '' : ' (optional)') }}</p>
                                      <div class="custom-radio-button">
                                          <input [attr.aria-label]="benechildL2.questionOption[0].description" tabindex="0" id="yes_{{ benechildL2.fieldId }}" type="radio"
                                          formControlName="{{ benechildL2.fieldId }}" name="{{ benechildL2.fieldId }}" value="{{ benechildL2.questionOption[0].value }}" required="{{benechildL2.required}}"
                                          (change)="onRadioChange(benechildL2)"/>
                                          <label for="yes_{{ benechildL2.fieldId }}">{{ benechildL2.questionOption[0].description }}</label>
                                          <input [attr.aria-label]="benechildL2.questionOption[1].description" tabindex="0" id="no_{{ benechildL2.fieldId }}" type="radio"
                                          formControlName="{{ benechildL2.fieldId }}" name="{{ benechildL2.fieldId }}" value="{{ benechildL2.questionOption[1].value }}" required="{{benechildL2.required}}"
                                          (change)="onRadioChange(benechildL2)">
                                          <label for="no_{{ benechildL2.fieldId }}">{{ benechildL2.questionOption[1].description }}</label>
                                      </div>
                                      </span>
                                      <lfg-error *ngIf="beneInfoForm.get(benechildL2.fieldId).touched && beneInfoForm.get(benechildL2.fieldId).invalid " message="{{ getErrorMsg(benechildL2) }}"></lfg-error>
                                  </div>


                                </ng-container>
                              </ng-container>
                            </ng-container>

                        </ng-container>
                    </ng-container>
                  </ng-container>
                  <div class="section-divider"></div>
                </ng-container>
            </form>
            <div *ngIf="showOwner && displayGrid">
                <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridStatus($event)" [hideAddLink]='hideAddLink' [showOwner]="true" [ownerAnswers]='ownerAnswers' [removeAdditionalOwner]='removeAdditionalOwner'
                  [isRevisit]="isTabVisited" [addAccordionText]="'Add Beneficiary'" [maxGridAvailable]="maxNoOfBene"
                  [accordionHeader]="type" [type]="'BENE'" [disableForm]="disableForm" [name]="type" [shareEqually]="shareEqually" [beneLoadFlag]="loadFlag"></app-grid-structure>
            </div>
            <div *ngIf="!showOwner && displayGrid">
              <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridStatus($event)" [hideAddLink]='hideAddLink' [showOwner]="false" [ownerAnswers]='ownerAnswers' [removeAdditionalOwner]='removeAdditionalOwner'
                [isRevisit]="isTabVisited" [addAccordionText]="'Add Beneficiary'" [maxGridAvailable]="maxNoOfBene"
                [accordionHeader]="type" [type]="'BENE'" [disableForm]="disableForm" [name]="type" [shareEqually]="shareEqually" [beneLoadFlag]="loadFlag"></app-grid-structure>
            </div>
        </section>
    </div>
</section>
