<section class="help-hint-slider help-content">
  <a (click)="closeHelpData()">
    <i class="fas fa-times fa-solid"></i>
  </a>
  <p class="help-hint--header">{{helpInfo[0].helpHeader}}</p>
  <div class="help-content-container">
    <div *ngFor="let content of helpInfo[0].helpContent">
      <p class="help-content--header">{{content.sectionHeading}}</p>
      <div class="help-content--info" [innerHtml]="content.sectionContent"></div>
    </div>
    <div>
      <p class="help-content--header">{{helpInfoData.needMoreInfo[0].sectionHeading}}</p>
      <div class="help-content--info" [innerHtml]="helpInfoData.needMoreInfo[0].sectionContent"></div>
    </div>
  </div>
</section>
