<section>
    <div class="eticket-search-body">
        <form [formGroup]="eticketSearchForm" (submit)="searchPolicies()">
            <ul class="eticket-search-container">
                <li class="eticket-search--input">
                    <lfg-input-wrap>
                        <input id="clientName" lfgInput appNoSpecialSymbols customAttr="alphanumericWithSingleQuote"
                            placeholder="Client Name (Last name, First name)" type="text" formControlName="clientName" name='' value=''
                            lfgValidateOnBlur aria-label="Search Text" autocomplete="off" autocorrect="off"
                            maxlength="50" />
                        <div aria-live="polite">
                            <lfg-error
                                [visible]="f['clientName'].touched && f['clientName'].invalid && f['clientName'].errors?.minlength"
                                message="Please enter a minimum of 3 characters when searching."></lfg-error>
                            <lfg-error *ngIf="f['clientName'].value?.length >= 3"
                                [visible]="f['clientName'].touched && f['clientName'].errors?.specialCharError"
                                message="Please enter a valid Client Name."></lfg-error>
                        </div>
                    </lfg-input-wrap>
                </li>
                <li class="eticket-search--input">
                    <lfg-input-wrap>
                        <input id="producerName" lfgInput appNoSpecialSymbols customAttr="alphanumericWithSingleQuote"
                            placeholder="Agent Name (Last name, First name)" type="text" formControlName="producerName" name='' value=''
                            lfgValidateOnBlur aria-label="Search Text" autocomplete="off" autocorrect="off"
                            maxlength="50" />
                        <div aria-live="polite">
                            <lfg-error
                                [visible]="f['producerName'].touched && f['producerName'].invalid && f['producerName'].errors?.minlength"
                                message="Please enter a minimum of 3 characters when searching."></lfg-error>
                            <lfg-error *ngIf="f['producerName'].value?.length >= 3"
                                [visible]="f['producerName'].touched && f['producerName'].errors?.specialCharError"
                                message="Please enter a valid Agent Name."></lfg-error>
                        </div>
                    </lfg-input-wrap>
                </li>
            </ul>
            <div class="form--control">
                <ul class="eticket-search-container">
                    <li>
                        <button class="btn btn--eticket-search" type="button"
                            [disabled]='eticketSearchForm.invalid || !isButtonDisabled' (click)="searchPolicies();"
                            #searchBtn> SEARCH</button>
                    </li>
                    <li>
                        <a class="btn-link" type="button" (click)="clearSearch()"> Clear </a>
                    </li>
                </ul>
            </div>
        </form>
    </div>
</section>