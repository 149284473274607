import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDetails } from '../models/casePage.model';
import { ServiceInvoker } from './service-invoker';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private serviceInvoker: ServiceInvoker) {}

  getFile(fileType: string, caseId: string): Observable<any> {
    return this.serviceInvoker.invokeService('GET_FILE_DETAILS', undefined, {
      fileType,
      caseId,
    });
  }

  uploadFile(fileDetails: FileDetails): Observable<any> {
    return this.serviceInvoker.invokeService(
      'UPLOAD_FILE',
      undefined,
      fileDetails
    );
  }

  deleteFile(fileType: string, caseId: string): Observable<any> {
    return this.serviceInvoker.invokeService('REMOVE_FILE', undefined, {
      fileType,
      caseId,
    });
  }
}
