import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';

import { LfgButtonTrayModule } from '@ng/lfg-button-tray';
import { LfgCommonUtilitiesModule } from '@ng/lfg-common-utilities';
import { LfgFormWidgetsModule } from '@ng/lfg-form-widgets';
import { LfgModalPopupModule } from '@ng/lfg-modal-popup';
import { LfgTabsModule } from '@ng/lfg-tabs';
import { LfgDirectivesModule } from '@ng/lfg-directives';
import { LfgCalendarModule } from '@ng/lfg-calendar';
import { LfgTooltipModule } from '@ng/lfg-tooltip';
import { LfgAccordionModule } from '@ng/lfg-accordion';
import { LfgTablesModule } from '@ng/lfg-tables';
import { LfgOverlayModule } from '@ng/lfg-overlay';
import { LfgAutocompleteModule } from '@ng/lfg-autocomplete';
import { ServiceInvoker } from './services/service-invoker';
import { ServiceConfig } from '../../config/service-config';
import { CombinePipe } from './pipes/combine.pipe';
import { DataFilterPipe } from './pipes/dataFilter.pipe';
import { RouterModule } from '@angular/router';
import { SearchPipe } from './pipes/search.pipe';
import { CurrencyFormatDirective } from './directives/currency-format.directive';
import { FormatPhoneNumberDirective } from './directives/format-phone-number.directive';
import { NoSpecialSymbolsDirective } from './directives/no-special-symbols.directive';
import { ValidateOnBlurDirective } from './directives/validate-on-blur.directive';
import { OnlyNumericDirective } from './directives/only-numeric.directive';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { HelpSliderComponent } from './components/help-slider/help-slider.component';
import { PercentageFormatDirective } from './directives/percentage-format.directive';
import { HelpHintComponent } from './components/help-hint/help-hint.component';
import { CapitalizeFirstLetterPipe} from './pipes/capitalize-first-letter.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';


@NgModule({
  imports: [
    CommonModule,
    LfgButtonTrayModule,
    LfgCommonUtilitiesModule,
    LfgFormWidgetsModule,
    LfgModalPopupModule,
    LfgTabsModule,
    LfgDirectivesModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    NgxMaskModule.forRoot(),
    LfgCalendarModule,
    LfgTooltipModule,
    LfgAccordionModule,
    LfgTablesModule,
    LfgOverlayModule,
    LfgAutocompleteModule
  ],
  declarations: [
    CombinePipe,
    DataFilterPipe,
    SearchPipe,
    CapitalizeFirstLetterPipe,
    CurrencyFormatDirective,
    FormatPhoneNumberDirective,
    NoSpecialSymbolsDirective,
    ValidateOnBlurDirective,
    OnlyNumericDirective,
    TruncatePipe,
    NumberFormatPipe,
    HelpSliderComponent,
    HelpHintComponent,
    PercentageFormatDirective,
    ConfirmationModalComponent,
    HeaderComponent,
    FooterComponent
  ],
  exports: [
    RouterModule,
    CommonModule,
    LfgButtonTrayModule,
    LfgCommonUtilitiesModule,
    LfgFormWidgetsModule,
    LfgModalPopupModule,
    LfgTabsModule,
    LfgDirectivesModule,
    NgxMaskModule,
    ReactiveFormsModule,
    LfgAccordionModule,
    LfgCalendarModule,
    LfgTooltipModule,
    LfgTablesModule,
    LfgOverlayModule,
    LfgAutocompleteModule,
    CombinePipe,
    DataFilterPipe,
    SearchPipe,
    TruncatePipe,
    CapitalizeFirstLetterPipe,
    NumberFormatPipe,
    CurrencyFormatDirective,
    FormatPhoneNumberDirective,
    NoSpecialSymbolsDirective,
    ValidateOnBlurDirective,
    OnlyNumericDirective,
    HelpSliderComponent,
    HelpHintComponent,
    PercentageFormatDirective,
    ConfirmationModalComponent,
    HeaderComponent,
    FooterComponent
  ],
  providers: [ServiceInvoker, ServiceConfig, SearchPipe, DatePipe],
})
export class SharedModule {}
