import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { LfgLoaderService } from '@ng/lfg-loader';

import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { GridInstance, Question, QuestionOption } from 'src/app/shared/models/casePage.model';
import { AgentInfoConstant, AgentValidationMessage, ErrorMessage } from 'src/config/constants';


@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.scss'],
})
export class AgentInfoComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  agentInfoData: Question[] = [];
  agentInfoForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  formHasNoErrors = true;
  gridHasNoErrors = false;
  isGridFormValid = true;
  isGridFormChange = false;
  placeholders = {};
  alldropDownOptions = {};
  isTerm = true;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  AgentValMsg = AgentValidationMessage;
  agentSSNValid = false;
  disableFirmDropDown = true;
  AgentConst = AgentInfoConstant;
  firmOptions: any;
  totalSplitPercentage = 100;
  selectedFirm: any;
  isAgentFormValid = false;

  action: {};
  ssnListFromGridAns: string[] = [];
  firmUpdatedForSSN: string[] = [];
  showLoader = 0;

  maxNoOfAgent: number;
  invalidAgents: any[] = [];
  agentList: any[] = [];
  isAgentValidationCalled = false;
  agentValidationErrorOccurred = false;
  pushFieldToNewLine: any[] = []
  validationPopupConfig: {
    main:string,
    additional:string,
    okButtonMessage: string
  }

  agentNpn: string;
  duplicateSSNExists: boolean;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private agentService: AgentAndFirmService,
    private loaderService: LfgLoaderService,
    private userDetailsService: UserDetailsService,
    private productService: ProductDetailsService,
    private tabService: TabDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.filterResultsBasedOnBusinessRules();
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
      this.updateFirmListAgents();
      this.buildFormData();
      this.updateAgentInfo();
      this.updateFirmRelatedValues();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.agentInfoForm);
      this.pushFieldToNewLine = ['search for firms','name of firm','license number']
      this.defaultPageLoadService.disableFormIfLocked(this.agentInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.agentInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.agentInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.agentInfoData,
      this.questionsData.page_group_name
    );
    this.agentInfoForm = this.fb.group(form);
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.agentInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.agentInfoForm,
      data: null,
    };
    for (const data of this.agentInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  onAgentRadioChange(data): any {
    this.updatedAgentAnswersOnHidden(data);
  }

  handleAgentSelectionChange(data, _ix?): any {
    this.updateagentNPNValue(data);
    this.updatedAgentAnswersOnHidden(data);
  }

  addAgentCorrectPlaceHolder(data, type): any {
    this.updateBusinessRules(data);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAgentAnswersOnHidden(data): any {
    const value = this.agentInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.agentInfoForm.get(key)?.markAsUntouched();
      }
      this.agentInfoForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.agentInfoForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckAgentInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  validateAgents(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.isAgentValidationCalled = true;
    this.invalidAgents = [];
    this.checkIfAgentsValid();
    this.defaultPageLoadService.logButtonClick('validate license and appointment');
  }

  updateModalAction(event): void {
    if (event) {
      const states = ['PA', 'GU', 'MP', 'VI'];
      const insuredState = this.userDetailsService.getInsuredState();
      if (states.indexOf(insuredState) === -1) {
        const tabConfig = this.tabService.getTabInfoByTabName('Agent Report');
        this.tabService.selectTab(tabConfig);
      }
    }
    this.action = 'closeModal';
  }

  gridformStatus(event): void {
    this.checkShouldAgentsRevalidate(event.gridAnswers)
    this.checkForPercentage();
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.isGridFormChange = event.isGridFormChange;
    this.checkFormStatus();
    this.emitStatus();
  }

  emitStatus(): void {
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    this.isAgentFormValid = this.formValid && this.isGridFormValid;
    const agentInfoObj = {
      formValid: this.isAgentFormValid && (this.disableForm ? true : this.agentService.getIsAgentValidated()),
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'agentInfoForm',
      gridForm: true,
      isFormChange: this.isGridFormChange || this.isAgentValidationCalled || !this.isTabVisited,
    };
    this.isAgentValidationCalled = false;
    this.formStatus.emit(agentInfoObj);
  }

  getAgentValidityStatus(): boolean {
    return this.agentService.getIsAgentValidated();
  }

  checkShouldAgentsRevalidate(newGridAns?): void {
    if(!newGridAns){
      return
    }
    if(this.gridAnswers.length != newGridAns.length){
      this.agentService.setIsAgentsValidated(false)
    }else{
      this.gridAnswers.forEach((obj,idx) =>{
        const ssnFieldName = this.getNameByXmlTag(this.AgentConst.ssnXmlTag)
        const firmIdFieldName = this.getNameByXmlTag(this.AgentConst.firmIdXmlTag)
        if(
          obj[ssnFieldName] != newGridAns[idx][ssnFieldName] ||
          obj[firmIdFieldName] != newGridAns[idx][firmIdFieldName]
        ){
          this.agentService.setIsAgentsValidated(false)
        }
      })
    }
  }

  checkForPercentage(): void {
    let gridPercent = 0;
    this.gridAnswers.forEach((gridAns) => {
      gridPercent = gridPercent + +gridAns[AgentInfoConstant.secSplitQuesName];
    });
    this.totalSplitPercentage = gridPercent

  }

  checkIfSSNIsValidAndResetValues(): void {
    this.resetFirmList();
    this.checkIfSSNIsValid();
  }

  private checkIfSSNIsValid(): void {
    const agentSSNQuesFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId);
    this.agentSSNValid = this.agentInfoForm?.get(agentSSNQuesFieldId)?.value &&
      this.agentInfoForm?.get(agentSSNQuesFieldId)?.status ===
      'VALID';
  }

  private resetFirmList(): void {
    this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.firmNameQuesId))?.reset();
    this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentNPNQuesId))?.reset();
    this.updateQuestionDataWithFirm([]);
    this.firmOptions = [];
    this.disableFirmDropDown = true;
  }

  loadFirmDetails(_data: Question): void {
    this.updateFirmList();
    this.defaultPageLoadService.logButtonClick('search');
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  private updateFirmList(): void {
    if (this.agentSSNValid) {
      this.getFirmDropdownOptions();
    }
  }


  private getFirmDropdownOptions(): void {
    this.showPageLoader(true);
    const value = 'Other';
    const description = 'Other';
    const label = 'Other';
    const quesOptions: QuestionOption[] = [];
    this.agentService
      .getFirmList(
        this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value,
        this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentLastNameQuesId))?.value
      )
      .subscribe(
        (response) => {
          this.agentService.createDropDownFromFirmList(response, quesOptions);
          quesOptions.push({ value, description, label });
          this.firmOptions = this.utilsService.formatOptions(quesOptions);
          this.updateQuestionDataWithFirm(quesOptions);
          this.agentNpn = response?.data?.agentNpn;
          this.agentService.saveAgentNpnResp(
            this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value,
            this.agentNpn);
          this.disableFirmDropDown = false;
          this.showPageLoader(false);
        },
        (_error) => {
          console.log('error occured');
          quesOptions.push({ value, description, label });
          this.firmOptions = this.utilsService.formatOptions(quesOptions);
          this.updateQuestionDataWithFirm(quesOptions);
          this.agentNpn = '';
          this.disableFirmDropDown = false;
          this.showPageLoader(false);
        }
      );
  }

  private updateQuestionDataWithFirm(quesOptions: QuestionOption[]): void {
    this.agentInfoData.forEach((question) => {
      if (question.xmlTag === AgentInfoConstant.firmNameQuesId) {
        question.questionOption = quesOptions;
      }
    });
  }

  private updateFirmRelatedValues(): void {
    this.checkIfSSNIsValid();
    this.agentInfoData.forEach((question) => {
      if (question.xmlTag === AgentInfoConstant.firmNameQuesId &&
        question.question_answer) {
        this.firmOptions = this.utilsService.formatOptions(
          question.questionOption
        );
        this.disableFirmDropDown = this.disableForm ? this.disableFirmDropDown : false;
      }
    });
  }

  private updateAgentInfo(): void {
    const agentProfile = this.agentService.getAgentProfile();
    const agentFirstNameFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentFirstNameQuesId);
    const agentLastNameFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentLastNameQuesId);
    const agentEmailFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentEmailQuesId);
    if (!this.agentInfoForm.get(agentFirstNameFieldId)?.value) {
      this.agentInfoForm.get(agentFirstNameFieldId)?.setValue(this.utilsService.getCamelCase(agentProfile?.firstName));
    }
    if (!this.agentInfoForm.get(agentLastNameFieldId)?.value) {
      this.agentInfoForm.get(agentLastNameFieldId)?.setValue(this.utilsService.getCamelCase(agentProfile?.lastName));
    }
    if (!this.agentInfoForm.get(agentEmailFieldId)?.value) {
      this.agentInfoForm.get(agentEmailFieldId)?.setValue(agentProfile?.email);
    }
    this.disableAgentNPN();
  }

  private updateBusinessRules(data: Question): void {
    if (data.xmlTag === AgentInfoConstant.agentNumberQuesId && !this.agentService.getAgentProfile().allStateFlag) {
      data.required = 'false';
    }
  }

  private updateRequiredField(xmlTag: string, isRequired: string): void {
    this.agentInfoData.forEach(question => {
      if (question.xmlTag === xmlTag) {
        question.required = isRequired;
      }
    });
  }

  private filterResultsBasedOnBusinessRules(): void {
    if (!this.agentService.getAgentProfile()?.allStateFlag) {
      const filteredQuesList = this.questionsData?.pages[0]?.gridInstances[0]?.gridQuestions.filter(ques => {
        return ques.notes !== 'ITES_FILTER_ALL_STATE';
      });
      this.questionsData.pages[0].gridInstances[0].gridQuestions = filteredQuesList;
    }
    this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
    this.maxNoOfAgent = +(this.gridInstance[0]?.maxGridIndex);
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private getNameByXmlTag(xmlTag: string): string {
    return this.utilsService.getNameByXmlTag(this.gridInstance[0].gridQuestions, xmlTag);
  }

  private checkIfAgentsValid(): void {
    const payload = this.createPayloadForValidateAgent();
    this.showPageLoader(true);
    this.defaultPageLoadService.validateAgent(payload).subscribe((res:any) => {
      if (res?.data) {
        if (res.data.length > 0) {
          this.updateAgentListWithResponse(res.data);
          this.invalidAgents = this.agentList.filter(agent => agent.agentValid === false);
        } else {
          this.invalidAgents = this.agentList.filter(agent => agent.agentSSN);
        }
        this.isAgentValidationCompleted();
        this.agentValidationErrorOccurred = false;
        this.emitStatus();
      } else if (res.error) {
        this.agentValidationErrorOccurred = true;
        if (res.error.errorCode === this.AgentConst.duplicateSSNErrorCode){
          this.duplicateSSNExists = true
        }
      }
    }, (_error) => {
      this.agentValidationErrorOccurred = true;
    }).add(() => {
      if(this.invalidAgents.length>0 || this.agentValidationErrorOccurred){
        this.showPageLoader(false);
      }
    });
  }

  private createPayloadForValidateAgent(): any {
    const clientState = this.userDetailsService.getInsuredState();
    return {
      solicitationState: clientState,
      clientState,
      productId: this.productService.getMarketName(),
      agentList: this.getAgentListToValidate(),
    };
  }

  private getAgentListToValidate(): any[] {
    this.agentList = [];
    this.updateAgentData();
    const agentListForPayload = []
    this.agentList.forEach(agent => {
      const agentData = {
        agentId: agent.agentId,
        agentLastName: agent.lastName,
        agentSSN: agent.agentSSN,
        firmId: agent.firmId,
      }
      agentListForPayload.push(agentData)
    })
    return agentListForPayload;
  }

  private isAgentValidationCompleted(): void {
    // implement hard stop for PA if the agent validation failed - only for eApp, not for eticket
    const states = ['PA', 'GU', 'MP', 'VI']
    const insuredState = this.userDetailsService.getInsuredState()?.toUpperCase();
    if (this.invalidAgents.length > 0) {
      this.action = 'openModal';
        this.validationPopupConfig = {
          main: insuredState !== 'PA' ? AgentValidationMessage.DEFAULT_FAILED_MESSAGE: AgentValidationMessage.PA_FAILED_MESSAGE,
          additional: insuredState !== 'PA' ? '' : AgentValidationMessage.PA_FAILED_MESSAGE_ADDITIONAL_TXT,
          okButtonMessage: insuredState !== 'PA' ? AgentValidationMessage.DEFAULT_FAILED_MESSAGE_ADDITIONAL_TXT_OK: AgentValidationMessage.DEFAULT_FAILED_MESSAGE_ADDITIONAL_TXT_OK_PA
        }
      this.agentService.setIsAgentsValidated(true);
      const nameList = []
      this.invalidAgents.forEach(agent => {
        nameList.push(agent.firstName + ' ' + agent.lastName)
      })
      this.validationPopupConfig.main = this.validationPopupConfig.main.replace('<AGENTNAME>', nameList.join(', '))
      this.validationPopupConfig.main = this.validationPopupConfig.main.replace('<STATENAME>', insuredState)
    } else {
      this.agentService.setIsAgentsValidated(true);
      this.checkFormStatus();
      const tabConfig = this.tabService.getTabInfoByTabName('Agent Report');
      this.tabService.selectTab(tabConfig)
    }
  }

  private updateAgentData(): void {
    let agentId = 1;
    this.gridAnswers.forEach(ans => {
      const agentData = {
        agentId: agentId.toString(),
        agentSSN: ans[this.getNameByXmlTag(AgentInfoConstant.agentSSNQuesId)],
        firmId: ans[AgentInfoConstant.agentFirmQuesName],
        firstName: ans[this.getNameByXmlTag(AgentInfoConstant.agentFirstNameQuesId)],
        lastName: ans[this.getNameByXmlTag(AgentInfoConstant.agentLastNameQuesId)]
      };
      this.agentList.push(agentData);
      agentId++;
    });
  }

  private updateAgentListWithResponse(agentValidationRes: any[]): void {
    this.agentList.forEach((agent) => {
      const temp = agentValidationRes.find(element => element.agentId === agent.agentId);
      agent.agentValid = temp?.agentValid ? true : false;
    });
  }

  private disableAgentNPN(): void {
    setTimeout(() => {
      this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentNPNQuesId))?.disable();
    }, 100);
  }

  private updateagentNPNValue(data?: Question): void {
    if (!data || data?.xmlTag === AgentInfoConstant.firmNameQuesId) {
      const agentNpnFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentNPNQuesId);
      const firmVal = this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.firmNameQuesId))?.value?.label;
      if (!this.agentNpn && this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value) {
        this.agentNpn = this.agentService.getAgentNpnResp(this.agentInfoForm.get(
          this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value);
      }
      if (this.agentNpn && firmVal !== 'Other') {
        this.agentInfoForm.get(agentNpnFieldId)?.setValue(this.agentNpn);
      } else {
        this.agentInfoForm.get(agentNpnFieldId)?.reset();
      }
    }
  }

  private updateFirmListAgents(): void {
    this.gridAnswers.forEach((gridAns) => {
      if (gridAns[AgentInfoConstant.agentFirmQuesName]) {
        this.ssnListFromGridAns.push(gridAns[AgentInfoConstant.secAgentSSNQuesName] + ':' + gridAns[AgentInfoConstant.secAgentLastNameId]);
      }
    });
    this.ssnListFromGridAns.forEach(ssnLN => {
      const [ssn, lastName] = ssnLN.split(':');
      if (ssn && lastName) {
        if (!this.agentService.getFirmResponse(ssnLN)) {
          this.showPageLoader(true);
          this.agentService.getFirmList(ssn, lastName).subscribe((res) => {
            const quesOptions = [];
            this.agentService.createDropDownFromFirmList(res, quesOptions);
            quesOptions.push({ value: 'Other', description: 'Other', label: 'Other' });
            this.agentService.saveFirmResponse(ssnLN, this.utilsService.formatOptions(quesOptions));
            this.agentService.saveAgentNpnResp(ssn, res?.data?.agentNpn);
            this.firmUpdatedForSSN.push(ssn);
          }).add(() => {
            this.showPageLoader(false)
          });
        } else {
          this.firmUpdatedForSSN.push(ssn);
        }
      }
    });
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      this.showLoader++;
      if (this.showLoader === 1) {
        this.loaderService.show();
      }
    } else {
      this.showLoader--;
      if (this.showLoader === 0) {
        this.loaderService.hide();
      }
    }
  }
}
