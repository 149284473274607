<div class="questions--container" *ngIf="illustrationForm">
	<form [formGroup]="illustrationForm" method="post" class="form-group">

		<section class="border-bottom padbot20">
			<div class="questions-header">
				<h2>Insured Information</h2>
			</div>

			<div class="form--control">
				<lfg-input-wrap>
					<input lfgInput type="text" name="firstName" formControlName="firstName" appValidateOnBlur
						placeholder="First Name" />
				</lfg-input-wrap>
			</div>

			<div class="form--control">
				<lfg-input-wrap>
					<input lfgInput type="text" name="lastName" formControlName="lastName" appValidateOnBlur
						placeholder="Last Name" />
				</lfg-input-wrap>
			</div>

			<div class="full-width-ele"></div>

			<div class="form--control lfg-cal" aria-live="assertive">
				<lfg-calendar (focusin)="validateOnFocus('DOB', 'in')" id="DOB" (onChange)="null"
					(focusout)="validateOnFocus('DOB', 'out')" lfgCheckValidDate
					[formControl]="illustrationForm.get('DOB')" [config]="dateConfig"
					[class.calendar--err]="illustrationForm.get('DOB').touched && illustrationForm.get('DOB').invalid"
					aria-hidden="true" [required]="true">
					<lfg-error *ngIf="illustrationForm.get('DOB').touched && illustrationForm.get('DOB').invalid"
						message="Please enter a valid date"></lfg-error>
				</lfg-calendar>
			</div>

			<div class="form--control">
				<lfg-input-wrap>
					<input lfgInput type="text" name="age" formControlName="age" appValidateOnBlur
						placeholder="Issue age" />
					<lfg-error *ngIf="ageErr" [message]="ageErrMsg"></lfg-error>
				</lfg-input-wrap>
			</div>

			<div class="lfg-customRadio-field form--control"
				[ngClass]="{'radio--err': (illustrationForm.get('gender').touched && (illustrationForm.get('gender').invalid))}">
				<span class="lfg-radio-section">
					<p class="question-radio_text">Gender</p>
					<div class="custom-radio-button">
						<input tabindex="0" id="radio_male" type="radio" formControlName="gender" name="gender"
							value="Male" required="true" (change)="onRadioChange($event)" />
						<label for="radio_male">Male</label>
						<input tabindex="0" id="radio_female" type="radio" formControlName="gender" name="gender"
							value="Female" required="true" (change)="onRadioChange($event)">
						<label for="radio_female">Female</label>
					</div>
				</span>
				<lfg-error *ngIf="illustrationForm.get('gender').touched && illustrationForm.get('gender').invalid"
					message="Please select a valid option"></lfg-error>
			</div>

			<div class="form--control form-dropDown">
				<lfg-dropdown formControlName="underwritingClass" id="underwriting_class"
					[options]="underwritingClassOptions" placeholder="Underwriting Class"
					(selectionChanged)="handleSelectionChange('underwritingClass', 0)" [required]="true"
					[ngClass]="{'dropdown--err': illustrationForm.get('underwritingClass').touched && illustrationForm.get('underwritingClass').value.value==='none'}">
				</lfg-dropdown>
				<lfg-error
					*ngIf="illustrationForm.get('underwritingClass').touched && illustrationForm.get('underwritingClass').value.value==='none'"
					message="Underwriting class required. Please select the Underwriting class that applies"></lfg-error>
			</div>
		</section>

		<section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
			<div class="questions-header">
				<h2>Backdating</h2>
			</div>

			<div class="lfg-radio"
				[ngClass]="{'radio--err': (illustrationForm.get('backdate').touched && (illustrationForm.get('backdate').invalid))}">
				<div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_none" type="radio" formControlName="backdate" name="backdate"
						value="none" required="true" (change)="onRadioChange($event)" />
					<label for="radio_none">Do not illustrate with backdating</label>
				</div>
				<div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_manual" type="radio" formControlName="backdate" name="backdate"
						value="manual" required="true" (change)="onRadioChange($event)">
					<label for="radio_manual" class="with-inline">
						<span class="text">Manual backdate using number of months</span>
						<span class="inline-input-wrap">
							<input class="inline-input" id="backdate_months" type="text" placeholder="0"
								allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly"
								maxlength='2' formControlName="backdateMonths">
						</span>
					</label>
					<lfg-error *ngIf="illustrationForm.get('backdateMonths').touched && illustrationForm.get('backdateMonths').invalid"
					message="Allowable Range 1-11"></lfg-error>
				</div>
				<lfg-error *ngIf="illustrationForm.get('backdate').touched && illustrationForm.get('backdate').invalid"
					message="Please select a valid option"></lfg-error>
			</div>

			<div class="form--control form--control__full-width">
				<div class="lfg-checkbox">
					<input id="inc_premium" type="checkbox" value="checked" name="inc_premium"
						formControlName='incPremium' [required]="false"
						(change)="onCheckboxChange('incPremium'); checkIfReasonSelectedErrorExists()">
					<label class="wrap wrap-check" for="inc_premium">Increase initial premium by missed modal premiums
						due to backdating</label>
				</div>
			</div>
		</section>

		<section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
			<div class="questions-header">
				<h2>Long-Term Care Benefits Rider</h2>
			</div>
			<div class="form--control form-dropDown">
				<lfg-dropdown formControlName="inflationElection" id="inflation_election"
					[options]="inflationElectionOptions" placeholder="Inflation election"
					(selectionChanged)="handleSelectionChange('inflationElection', 0)" [required]="true"
					[ngClass]="{'dropdown--err': illustrationForm.get('inflationElection').touched && illustrationForm.get('inflationElection').invalid}">
				</lfg-dropdown>
			</div>
		</section>

		<section class="border-bottom padbot20">
			<div class="questions-header">
				<h2>Report options</h2>
			</div>

			<section *ngIf="product===products.MGFA || product===products.MGFA25 || product===products.MGII">
				<div class="form--control form--control__full-width">
					<div class="lfg-checkbox">
						<input id="revised_projection" type="checkbox" value="checked" name="revised_projection"
							formControlName='revisedProjection' [required]="false"
							(change)="onCheckboxChange('revisedProjection'); checkIfReasonSelectedErrorExists()">
						<label class="wrap wrap-check" for="revised_projection">Revised Projection</label>
					</div>
				</div>

				<div class="form--control form--control__full-width">
					<div class="lfg-checkbox">
						<input id="cost_disclosure" type="checkbox" value="checked" name="cost_disclosure"
							formControlName='costDisclosure' [required]="false"
							(change)="onCheckboxChange('costDisclosure'); checkIfReasonSelectedErrorExists()">
						<label class="wrap wrap-check" for="cost_disclosure">Cost disclosure</label>
					</div>
				</div>

				<div class="form--control form--control__full-width">
					<div class="lfg-checkbox">
						<input id="print_irr" type="checkbox" value="checked" name="print_irr"
							formControlName='printIRR' [required]="false"
							(change)="onCheckboxChange('printIRR'); checkIfReasonSelectedErrorExists()">
						<label class="wrap wrap-check" for="print_irr">Print IRR values</label>
					</div>
				</div>

				<div class="form--control form--control__full-width">
					<div class="lfg-checkbox">
						<input id="print_signature" type="checkbox" value="checked" name="print_signature"
							formControlName='printSignature' [required]="false"
							(change)="onCheckboxChange('printSignature'); checkIfReasonSelectedErrorExists()">
						<label class="wrap wrap-check" for="print_signature">Print signature Section</label>
					</div>
				</div>
			</section>

			<div class="form--control form--control__full-width" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
				<div class="lfg-checkbox">
					<input id="revised_naic" type="checkbox" value="checked" name="revised_naic"
						formControlName='revisedNAIC' [required]="false"
						(change)="onCheckboxChange('revisedNAIC'); checkIfReasonSelectedErrorExists()">
					<label class="wrap wrap-check" for="revised_naic">Revised NAIC Illustration</label>
				</div>
			</div>

			<div>
				<div class="form--control">
					<lfg-input-wrap>
						<input lfgInput type="text" name="altAge" appOnlyNumeric allowDecimals="false" appValidateOnBlur
							appNoSpecialSymbols customAttr="numbersOnly" maxlength='3' formControlName="altAge"
							appValidateOnBlur placeholder="Alternate Age" required />
					</lfg-input-wrap>
					<lfg-error *ngIf="illustrationForm.get('altAge').touched && illustrationForm.get('altAge').invalid"
						message="Please enter a valid alternate age."></lfg-error>
				</div>
				<div class="form--control form-dropDown">
					<lfg-dropdown formControlName="additionalCostsState" id="additional_costs_state"
						[options]="stateOptions" placeholder="Additional What Care Costs state"
						(selectionChanged)="handleSelectionChange('additionalCostsState', 0)" [required]="true"
						[ngClass]="{'dropdown--err': illustrationForm.get('additionalCostsState').touched && illustrationForm.get('additionalCostsState').invalid}">
					</lfg-dropdown>
				</div>
			</div>

			<div class="form--control form--control__full-width-inline">
				<div class="lfg-checkbox">
					<input id="alternate_cost_amt" type="checkbox" value="checked" name="alternate_cost_amt"
						formControlName='alternateCost' [required]="false"
						(change)="onCheckboxChange('alternateCost'); checkIfReasonSelectedErrorExists()">
					<label class="wrap wrap-check with-inline" for="alternate_cost_amt">
						<span class="text">Use alternate cost of care amount of</span>
						<!-- Use alternate cost of care amount of -->
						<span class="inline-input-wrap">
							<input class="inline-input" id="alternateCostAmt" type="text" placeholder="$0"
								appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols
								customAttr="numbersOnly" maxlength='9' formControlName="alternateCostAmt">
						</span>
					</label>
				</div>
			</div>
		</section>

		<section *ngIf="product===products.MGMA22 || product===products.MGFA || product===products.MGMA24 || product===products.MGFA25">
			<div class="questions-header">
				<h2>Benefit Transfer Rider (BTR) funding</h2>
			</div>

			<div class="form--control form--control__full-width-inline">
				<div class="lfg-checkbox">
					<input id="fund_btr" type="checkbox" value="checked" name="fund_btr" appOnlyNumeric
						formControlName='fundBTR' [required]="false"
						(change)="onCheckboxChange('fundBTR'); checkIfReasonSelectedErrorExists()">
					<label class="wrap wrap-check with-inline" for="fund_btr">
						<span class="text">Illustrate amount to fund BTR of</span>
						<span class="inline-input-wrap text-input">
							<input class=" inline-input text-input" id="fund_btr_amt" type="text" placeholder="0"
								formControlName="fundBTRAmt" appCurrencyFormat allowDecimals="false" appValidateOnBlur
								appNoSpecialSymbols customAttr="numbersOnly" maxlength='9'>
						</span>
						<span class="text">at</span>
						<span class="inline-input-wrap dropdown">
							<div class="inline-input form-dropDown">
								<lfg-dropdown formControlName="btrDropdown" id="btr_dropdown" [options]="timePeriodOptions"
									(click)="false" placeholder=""
									(selectionChanged)="handleSelectionChange('btrDropdown', 0)" [required]="true"
									[ngClass]="{'dropdown--err': illustrationForm.get('btrDropdown').touched && illustrationForm.get('btrDropdown').invalid}">
								</lfg-dropdown>
							</div>
						</span>
						<span class="inline-input-wrap num-control-box">
							<lfg-numeric-control-box [incBoxConfig]="numInpBoxConfig"
								(incBoxObj)="numberBoxEvent($event, illustrationForm.get('fundBTRAge'))"
								class="inline-input">
								<input lfgInput type="text" formControlName="fundBTRAge" name="fund_btr_age"
									id="fund_btr_age" allowDecimals="false" appValidateOnBlur appNoSpecialSymbols
									customAttr="numbersOnly" maxlength='2' placeholder="0"
									(input)="onInputBTRAge($event)" [ngClass]="{'inp-txt--err': illustrationForm.get('fundBTRAge').invalid}" />
							</lfg-numeric-control-box>
						</span>
					</label>
				</div>
			</div>
		</section>
	</form>
</div>
