import { Component, Input, OnInit } from '@angular/core';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-base-page-header',
  templateUrl: './base-page-header.component.html',
  styleUrls: ['./base-page-header.component.scss'],
})
export class BasePageHeaderComponent implements OnInit {
  primaryInsuredName: string;
  productName: string;
  faceAmount: string;
  state: string;
  refCaseId: string;

  @Input()
  isWholesaler: boolean;

  constructor(
    private userService: UserDetailsService,
    public productService: ProductDetailsService
  ) {
    // Add code here
  }

  ngOnInit(): void {
    this.getInsuredAndProductName();
  }

  getInsuredAndProductName(): void {
    this.primaryInsuredName = this.userService.getInsuredName();
    this.productName = this.productService.getProductName();
    this.state = this.userService.getInsuredState();
    this.refCaseId = this.userService.getRefCaseId();
    this.faceAmount = this.userService.getProjectionSummaryData()?.['specifiedFaceAmount'] ?? '0';
    this.userService.projectionDataSubs$.subscribe((val) => {
      if (val) {
        const specifiedFaceAmount = this.userService.getProjectionSummaryData()?.['specifiedFaceAmount'];
        this.faceAmount = specifiedFaceAmount ?? '0';
      }
    })
  }
}
