export class PolicyProjectionPaymentTableConfig {
    tableData = {
        config: {
            tableId: 'eticket-dbResult-table',
            tableClass: 'eticket-dbResult-table',
            sorting: false,
            pagination: true,
            rowsPerPage: 50,
            defaultSortOrder: 'desc',
            clickSortOrder: 'asc',
            handleSubDetailRow: false,
            currentPage: 0,
            tableFoot: false,
            noRecordsText: 'No records found'
        },
        column: [
            {
                displayText: 'Issue age',
                isAction: 'noaction',
                columnKey: 'issueAge',
                columnFlag: 'issueAge',
                pipeName: null,
                sorting: false,
                align: 'left',
                type: 'htmlText',
                classNameTd: 'policy-projection-td_info',
                classNameTh: 'policy-projection-table-th_info'
            },
            {
                displayText: 'Longest available flex pay duration',
                isAction: 'noaction',
                columnKey: 'payDuration',
                columnFlag: 'payDuration',
                pipeName: null,
                align: 'left',
                type: 'htmlText',
                sorting: false,
                classNameTd: 'policy-projection-td_info',
                classNameTh: 'policy-projection-table-th_info'
            },
        ],
        data: null
    };
}
